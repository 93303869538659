import React, { useState, useEffect } from 'react';
import classNames from 'classnames';

import { CommonProps } from 'src/components/helpers/props';

import ArrowDropDownIcon from '../icons/ArrowDropDownIcon';
import './index.scss';
import ArrowDownIcon from '../icons/ArrowDownIcon';

export interface ExpansionDivProps extends CommonProps {
  title: string;
  force?: boolean;
  disabled?: boolean;
  defaultOpen?: boolean;
  front?: boolean;
  icon?: React.ReactNode;
  children: React.ReactNode;
}

const ExpansionDiv: React.FC<ExpansionDivProps> = ({
  title,
  force = true,
  disabled = false,
  defaultOpen = false,
  children,
  front = false,
  icon = null,
  classes,
}) => {
  const [open, setOpen] = useState(defaultOpen);

  useEffect(() => {
    if (disabled === true && open === true) setOpen(!open);
  }, [disabled, open]);

  useEffect(() => {
    setOpen(force)
  }, [force]);
  return (
    <div
      className={classNames(
        'expansion-div-root',
        {
          on: open,
          disabled: disabled,
          icon: icon !== null,
        },
        classes
      )}
    >
      <button onClick={() => setOpen(!open)} disabled={disabled}>
        {front ?
          (icon ? icon : <ArrowDownIcon />) : null}
        <span>{title}</span>
        {!front ?
          (icon ? icon : <ArrowDownIcon />) : null}
      </button>

      <div className="expansion-div-list">{children}</div>
    </div>
  );
};

export default ExpansionDiv;
