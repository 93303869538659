import React from 'react';
import classNames from 'classnames';

import { CommonProps } from 'src/components/helpers/props';

import './AbstractIcon.scss';

interface CircleNextIconProps extends CommonProps {
  disabled?: boolean;
  size?: 'md';
}

const CircleNextIcon: React.FC<CircleNextIconProps> = ({ classes, disabled = false, size = 'md' }) => {
  return (
    <svg
      className={classNames('icon-root', classes)}
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill='none'
      xmlns="http://www.w3.org/2000/svg"
    >
     <circle cx="16" cy="16" r="15.5" fill="white" stroke="#D1D6DB"/>
      <path d="M14 20L18 16L14 12" stroke="#6B7480" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
};

export default CircleNextIcon;
