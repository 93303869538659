import { isDoStatement } from "typescript";

export default class FileToUpload {
    static chunkSize = 1000000;
    readonly request: XMLHttpRequest;
    readonly file: File;
    currentChunkStartByte: number;
    currentChunkFinalByte: number;
    url: string;
    completeCallback: () => void;
    progressCallback?: (percent: number) => void;
    errorCallback?: (e: Error) => void;
    total_size = 0;

    constructor(url: string, file: File, name: string, completeCallback: () => void, progressCallback: (percent: number) => void, errorCallback: (e: Error) => void) {
        this.request = new XMLHttpRequest();
        this.request.overrideMimeType('application/octet-stream');

        this.url = url;
        this.file = file;
        this.currentChunkStartByte = 0;
        this.currentChunkFinalByte = FileToUpload.chunkSize > this.file.size ? this.file.size : FileToUpload.chunkSize;
        this.completeCallback = completeCallback;
        this.request.onerror = (e) => {
            console.log(e);
            errorCallback(new Error("파일 전송중 에러가 발생하였습니다."));
        }
        this.total_size = 0;
        this.request.upload.onprogress = (e) => {
            console.log(e.total, e.loaded, this.file.size);
            this.total_size = this.total_size + e.total;
            console.log("total = ", this.total_size)
            let percent = Math.floor((this.total_size * 100) / this.file.size)

            progressCallback(percent);
        }
    }

    uploadFile() {
        this.request.open('POST', this.url, true);
        let chunk: Blob = this.file.slice(this.currentChunkStartByte, this.currentChunkFinalByte);
        this.request.setRequestHeader('Content-Range', `bytes ${this.currentChunkStartByte}-${this.currentChunkFinalByte}/${this.file.size}`);
        this.request.onload = () => {
            const remainingBytes = this.file.size - this.currentChunkFinalByte;

            if (this.currentChunkFinalByte === this.file.size) {
                this.total_size = 0;
                this.completeCallback();
                return;
            } else if (remainingBytes < FileToUpload.chunkSize) {
                this.currentChunkStartByte = this.currentChunkFinalByte;
                this.currentChunkFinalByte = this.currentChunkStartByte + remainingBytes;
            }
            else {
                this.currentChunkStartByte = this.currentChunkFinalByte;
                this.currentChunkFinalByte = this.currentChunkStartByte + FileToUpload.chunkSize;
            }

            this.uploadFile();
        }


        const formData = new FormData();
        formData.append('file', chunk, this.file.name);
        this.request.send(formData);
    }
}
