import { SerializedError } from '@reduxjs/toolkit';
import Axios, { AxiosError } from 'axios';
import _ from 'lodash';

import i18n from 'src/i18n';
import store from 'src/store';
import auth, { startSignOut, thunkSignOut } from 'src/store/auth/auth';
import { startProgress, stopProgress } from 'src/store/progress';
import { getAdidManagerToken } from './sessionStorageManager';

export enum ApiErrorCode {
  UNKNOWN_ERROR = 'http.UNKNOWN_ERROR',
  INTERNAL_SERVER_ERROR = 'http.INTERNAL_SERVER_ERROR',
}
export const errorCodeByStatus: { [key: number]: ApiErrorCode } = {
  500: ApiErrorCode.INTERNAL_SERVER_ERROR,
};

// interface ApiErrorDto {
//   errorCode: string;
//   errorMessage?: string;
// }
// export interface ApiError extends ApiErrorDto {
//   status: number;
//   error?: AxiosError;
// }
// // eslint-disable-next-line @typescript-eslint/no-explicit-any
// export function isApiError(error: any): error is ApiError {
//   const apiError = error as ApiError;
//   return apiError.errorCode !== undefined && apiError.status !== undefined;
// }
export class ApiErrorHelper {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static isErrorCode(error: any, errorCode: string): boolean {
    return error && 'name' in error && error.name === errorCode;
  }
}

const axios = Axios.create({
  withCredentials: true,
});

// Add a request interceptor
axios.interceptors.request.use((config) => {
  store.dispatch(startProgress());

  // TODO X-HTTP-Method-Override 옵션. 서버 준비 되기 전가지 주석처리함
  // const method = config.method?.toUpperCase();
  // if (method === 'PUT' || method === 'DELETE' || method === 'PATCH') {
  //   config.headers = { ...config.headers, 'X-HTTP-Method-Override': method };
  //   config.method = 'POST';
  // }

  // 로그인을 요청할 때
  if (config.url?.startsWith('/manager')) {
    config.headers = {
      ...config.headers,
      'x-adid-manager-api-key': 'f19665ad-fdad-f111-ffff-fa8cdec9b803',
    };
  }

  // 로그인한 상태라면, authEntity의 token을 넣는다.
  const authEntity = store.getState().auth.authEntity;
  if (authEntity) {
    config.headers = {
      ...config.headers,
      Authorization: config.headers.Authorization // findWithToken에서 token에 직접 접근시, 해당 데이터를 셋팅해 줘야한다.
        ? config.headers.Authorization
        : `Bearer ${authEntity.tokenStr}`,
    };
  }

  if (i18n.language) {
    config.headers = {
      ...config.headers,
      'Accept-Language': `${i18n.language},en-US;q=0.9,en;q=0.8`,
    };
  }

  return config;
});

// Add a response interceptor
axios.interceptors.response.use(
  (response) => {
    store.dispatch(stopProgress());
    return response;
  },
  (error: AxiosError) => {
    store.dispatch(stopProgress());

    let se: SerializedError;
    if (!error.response) {
      se = {
        name: ApiErrorCode.UNKNOWN_ERROR,
        stack: error.stack,
      };
      return Promise.reject(se);
    }

    // TODO 특정 에러코드일 경우 강제 로그아웃. 일단은 401일 경우 무조건 로그아웃 하도록 처리.
    if (error.response.status === 401) {
      // store.dispatch(thunkSignOut());
      store.dispatch(startSignOut());
    }

    // 403 에러 이면서 message가 'Someone has logged in from another session with same user id. Your session is evicted.'이면, 로그아웃
    if (
      // error.response.status === 403 &&
      _.has(error.response.data, 'message') &&
      error.response.data.message ===
        'Someone has logged in from another session with same user id. Your session is evicted.'
    ) {
      store.dispatch(startSignOut());
    }

    if (
      _.has(error.response.data, 'message') &&
      error.response.data.message === 'The access token provided has expired.'
    ) {
      store.dispatch(startSignOut());
    }

    if (_.has(error.response.data, 'errorCode') && error.response.data.errorCode) {
      se = {
        name: error.response.data.errorCode,
        message: error.response.data.errorMessage,
      };
    } else if (
      _.has(error.response.data, 'error') &&
      error.response.data.error &&
      _.has(error.response.data, 'message') &&
      error.response.data.message
    ) {
      // spring exception
      se = {
        name: error.response.data.error,
        message: error.response.data.message,
      };
    } else {
      se = {
        name: error.response.data.error || errorCodeByStatus[error.response.status] || ApiErrorCode.INTERNAL_SERVER_ERROR,
      };
    }
    return Promise.reject(se);
  }
);

export default axios;
