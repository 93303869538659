import React, { useState } from 'react';
import classNames from 'classnames';

import Draggable, { DraggableData, DraggableEvent } from 'react-draggable';
import { CommonProps } from 'src/components/helpers/props';
import ModalPortal from 'src/shared/react-utils/ModalPortal';

import CloseIcon from 'src/components/atom/icons/CloseIcon';
import IconButton from 'src/components/atom/IconButton';

import './index.scss';
import ScrollBox from 'src/components/atom/ScrollBox';

export interface Popup2Props extends CommonProps {
  title: string;
  helpText?: string;
  footer?: React.ReactNode;
  open: boolean;
  onClose: () => void;
  width?: string;
  headerBorder?: boolean;
  overPopup?: boolean;
  height?: string;
  contentMaxHeight?: number;
}

interface Position {
  x: number;
  y: number;
}

const Popup2: React.FC<Popup2Props> = ({
  title,
  helpText,
  footer,
  open,
  onClose,
  width = '670px',
  overPopup = false,
  headerBorder,
  height,
  contentMaxHeight,
  children,
  classes,
}) => {
  const [position, setPosition] = useState<Position>({
    x: 0,
    y: 0,
  });

  const handleDrag = (e: DraggableEvent, ui: DraggableData): void | false => {
    const { x, y } = position;
    setPosition({
      x: x + ui.deltaX,
      y: y + ui.deltaY,
    });
  };

  const nodeRef = React.useRef(null);
  let headerClasses = "popup-head";
  if (headerBorder) {
    headerClasses = "popup-head popup-head-border-bottom";
  }
  return (
    <ModalPortal>
      <div className={classNames('popup2-backdrop-root', { on: open })} />
      <Draggable
        nodeRef={nodeRef}
        handle=".popup-head"
        defaultPosition={{ x: 0, y: 0 }}
        position={position}
        onDrag={handleDrag}
      >
        <div
          ref={nodeRef}
          className={classNames(
            'popup2-root',
            {
              on: open,
            },
            classes
          )}
          style={overPopup ? { zIndex: 2000 } : {}}
        >
          <div
            className="inner"
            style={{
              width: `${width}`,
              height: `${height}`,
            }}
          >
            <div className={headerClasses}>
              <p className="tit">{title}</p>
              <IconButton unfilled onClick={onClose}>
                <CloseIcon />
              </IconButton>
            </div>
            {helpText && <div className="popup-top-help">{helpText}</div>}
            {contentMaxHeight ? (
              <ScrollBox classes="popup-content" maxHeight={contentMaxHeight}>
                {children}
              </ScrollBox>
            ) : (
              <div className="popup-content no-scroll">{children}</div>
            )}
            <div className="popup-foot">{footer}</div>
          </div>
        </div>
      </Draggable>
    </ModalPortal>
  );
};

export default Popup2;
