import { getRefreshToken, getExpired } from './../../shared/utils/sessionStorageManager';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import _ from 'lodash';
import jwt from 'jsonwebtoken';
import moment, { Moment } from 'moment';

import adminApi from 'src/api/auth/adminApi';
import authApi, { LoginDto2 } from 'src/api/auth/authApi';
import productApi, { ProductDto } from 'src/api/auth/productApi';
import userSettingApi, { UserInfoEntity, emptyMemberDto, MemberDto } from 'src/api/userSettingApi';
import { MENU_PREFIX_FSP_UI, MENU_PREFIX_COMMON_UI } from 'src/shared/utils/menuUtils';

import { RootState } from 'src/store';
import {
  setToken,
  removeToken,
  setAdidManagerUser,
  removeAdidManagerUser,
  getAdidManagerUser,
  setRefreshToken,
  setExpired,
  removeRefreshToken,
  removeExpired,
} from 'src/shared/utils/sessionStorageManager';

// # types
export interface SignInReqEntity {
  userId: string;
  password: string;
  redirectUrl: string;
}

interface TokenObject {
  user_name: string;
  request_ip: string;
  scope: string[];
  authorities: string[];
  exp: number;
  jti: string;
  client_id: string;
  need_password_change: boolean;
  password_change_alert: boolean | null;
  managed_dept_codes: string[];
  permissions: string[];
  user_info: {
    userId: string;
    userName: string;
    userEmail: string;
    deptCode: string;
    deptName: string;
    positionCode: string;
    positionName: string;
    roleCode: string;
    roleName: string;
  };
}
interface TokenEntity {
  requestIp: string;
  scope: string[];
  authorities: string[];
  exp: number;
  jti: string;
  clientId: string;
  needPasswordChange: boolean;
  passwordChangeAlert: boolean | null;
  managedDeptCodes: string[];
  permissions: string[];
  userInfo: {
    userId: string;
    userName: string;
    userEmail: string;
    deptCode: string;
    deptName: string;
    positionCode: string;
    positionName: string;
    roleCode: string;
    roleName: string;
  };
}
function toTokenEntity(tokenObject: TokenObject): TokenEntity {
  return {
    requestIp: tokenObject.request_ip,
    scope: tokenObject.scope,
    authorities: tokenObject.authorities,
    exp: tokenObject.exp,
    jti: tokenObject.jti,
    clientId: tokenObject.client_id,
    needPasswordChange: tokenObject.need_password_change,
    passwordChangeAlert: tokenObject.password_change_alert,
    managedDeptCodes: tokenObject.managed_dept_codes,
    userInfo: tokenObject.user_info,
    permissions: tokenObject.permissions,
  };
}

type ProductEntity = ProductDto;

interface AuthEntity {
  tokenEntity: TokenEntity;
  tokenStr: string;
  menus: string[];
  productEntities: ProductEntity[];
  permissions: string[];
  userInfo: MemberDto;
  refreshTokenStr: string;
  expired: number;
}

interface AuthState {
  progressSignOut: boolean;
  authEntity: AuthEntity | undefined;
}

// # initial state
const initialState: AuthState = {
  progressSignOut: false,
  authEntity: undefined,
};
const initialDummyUserInfo: MemberDto = emptyMemberDto();

const initialDummyState: AuthState = {
  progressSignOut: false,
  authEntity: {
    tokenEntity: {
      requestIp: '192.168.21.171',
      scope: ['write', 'read'],
      authorities: ['USER', 'ADMIN'],
      exp: 1602232390,
      jti: 'e5acafeb-beac-4b71-b3e3-1e9158bb6bf1',
      clientId: '0d2019e6341145b0bdc7d7ad2b75a11f',
      needPasswordChange: false,
      passwordChangeAlert: false,
      managedDeptCodes: [],
      permissions: ['admin'],
      userInfo: {
        userId: 'admin',
        userName: 'Administrator',
        userEmail: 'pgkang@fasoo.com',
        deptCode: 'COMPANY',
        deptName: 'Fasoo',
        positionCode: 'P001',
        positionName: '사원',
        roleCode: 'R001',
        roleName: '일반',
      },
    },
    tokenStr:
      'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJjbGllbnRfdGltZSI6bnVsbCwiZXhwaXJlX3RpbWUiOjE2MDgyNjAxMzExNDcsInN0YXRlIjoiSzNDWENBPT0iLCJ1c2VyIjoiaml1IiwiYXBwX2lkIjpudWxsfQ==.8I1yIhTnd0XkJXvWcZYOcyzxj+MM6Bqr+Tj6MHWt/CU=',
    menus: [
      'fspui-home',
      'fspui-poltpl-basic',
      'fspui-poltpl-detection',
      'fspui-poltpl-wat',
      'fspui-policy-policies',
      'fspui-policy-review',
      'fspui-except-urls',
      'fspui-except-programs',
      'fspui-except-printers',
      'fspui-log-printings',
      'fspui-log-detections',
      'fspui-log-keywords',
      'fspui-statistics-user',
      'fspui-statistics-group',
      'fspui-statistics-program',
      'fspui-statistics-printerdriver',
      'fspui-statistics-pattern',
      'fspui-statistics-encryption',
      'fspui-etc-mgmt',
      'fspui-etc-setting',
      'commonui-policy-template',
      'commonui-policy-policies',
      'commonui-policy-file',
      'commonui-polreview',
      'commonui-mgmt-programs',
      'commonui-mgmt-printers',
      'commonui-mgmt-urls',
      'commonui-pattern-setting',
      'commonui-pattern-validation',
    ],
    productEntities: [
      {
        id: 0,
        desc: 'Fasoo Smart Printer UI',
        displayKey: 'uiframework.fspui',
        webUrl: '/fspui',
        context: 'fspui',
        enabled: true,
      },
      {
        id: 1,
        desc: 'Fasoo Manage Organ UI',
        displayKey: 'uiframework.organui',
        webUrl: '/organui',
        context: 'organui',
        enabled: false,
      },
      {
        id: 2,
        desc: 'Fasoo Manage Device UI',
        displayKey: 'uiframework.deviceui',
        webUrl: '/deviceui',
        context: 'deviceui',
        enabled: false,
      },
      {
        id: 3,
        desc: 'Fasoo Approval User UI',
        displayKey: 'uiframework.approvaluserui',
        webUrl: '/approvaluserui',
        context: 'approvaluserui',
        enabled: false,
      },
      {
        id: 4,
        desc: 'Fasoo Approval UI',
        displayKey: 'uiframework.approvalui',
        webUrl: '/approvalui',
        context: 'approvalui',
        enabled: false,
      },
      {
        id: 5,
        desc: 'Fasoo Audit UI',
        displayKey: 'uiframework.auditui',
        webUrl: '/auditui',
        context: 'auditui',
        enabled: false,
      },
      {
        id: 6,
        desc: 'Fasoo Manage Admin UI',
        displayKey: 'uiframework.adminui',
        webUrl: '/adminui',
        context: 'adminui',
        enabled: false,
      },
      {
        id: 7,
        desc: 'Fasoo Manage Keystore UI',
        displayKey: 'uiframework.keystoreui',
        webUrl: '/keystoreui',
        context: 'keystoreui',
        enabled: false,
      },
    ],
    permissions: ['admin'],
    userInfo: initialDummyUserInfo,
    refreshTokenStr: '',
    expired: moment().valueOf(),
  },
};

async function createAuthEntityDID(
  userid: string,
  token: string,
  beforeRefreshToken: string,
  expired: number
): Promise<AuthEntity> {
  console.log('HHL-DEBUG createAuthEntityDID:', token);
  console.log('_____:', beforeRefreshToken && expired && moment().valueOf() > expired, beforeRefreshToken, moment().valueOf(), expired, moment().valueOf() > expired);



  let updatedLoginDto: LoginDto2 | undefined = undefined;

  // expired
  if (beforeRefreshToken && expired && moment().valueOf() > expired) {
    // if (beforeRefreshToken && expired && moment().valueOf() > expired) {
    // console.log('expired!!!!');
    updatedLoginDto = await authApi.loginWithRefreshToken(beforeRefreshToken);
  }

  // 해당 user로 권한 가져오기.
  let adminDto: MemberDto;

  let permissions: string[] = [];

  // 2021.08.26 TODO : user 정보 조회해서 adminDto에 채워넣어야함.

  try {
    adminDto = await userSettingApi.find(
      userid,
      token
    );
    // console.log(adminDto);

    //   permissions = await userSettingApi.getResourceIdsWithToken(
    //     userid,
    //     updatedLoginDto ? updatedLoginDto.accessToken : token
    //   );
    //   console.log('logAPI permssion: ' + permissions);
  } catch (e) {
    //   console.log(e);
    return Promise.reject(e);
  }

  const tokenEntity: TokenEntity = {
    requestIp: '',
    scope: [],
    authorities: [],
    exp: 0,
    jti: '',
    clientId: '',
    needPasswordChange: false,
    passwordChangeAlert: null,
    managedDeptCodes: [],
    permissions: [],
    userInfo: {
      userId: userid,
      userName: '',
      userEmail: '',
      deptCode: '',
      deptName: '',
      positionCode: '',
      positionName: '',
      roleCode: '',
      roleName: '',
    },
  };

  const authEntity: AuthEntity = {
    tokenEntity,
    tokenStr: token,
    // tokenStr: updatedLoginDto ? updatedLoginDto.accessToken : token,
    menus: initialDummyState.authEntity ? initialDummyState.authEntity.menus : [],
    productEntities: [],
    permissions: permissions,
    userInfo: adminDto,
    refreshTokenStr: beforeRefreshToken,
    // refreshTokenStr: updatedLoginDto ? updatedLoginDto.refreshToken : beforeRefreshToken,
    expired: expired,
    // expired: updatedLoginDto
    // ? moment().add(updatedLoginDto.expiresIn, 'milliseconds').valueOf()
    // : expired,  
  };
  return authEntity;
}

// # thunks
async function createAuthEntity(token: string): Promise<AuthEntity> {
  const tokenDto = await authApi.getTokenKey();
  // let tokenEntity;
  // TODO api 연동 후 제거
  // try {
  //   tokenEntity = jwt.verify(accessToken, tokenDto.value) as TokenEntity;
  // } catch (e) {
  //   console.log('Failed to verify jwt token :');
  //   console.log(e);
  //   return Promise.reject('(미작업) 토큰이 유효하지 않습니다.'); // TODO resource
  // }
  const tokenEntity = toTokenEntity(jwt.decode(token) as TokenObject);

  const adminDto = await adminApi.assignments(tokenEntity.userInfo.userId);
  const allMenus = _.cloneDeep(adminDto.type.menus);
  const menus = adminDto.type.menus.filter(
    (menu) => menu.indexOf(MENU_PREFIX_FSP_UI) >= 0 || menu.indexOf(MENU_PREFIX_COMMON_UI) >= 0 // TODO MENU_PREFIX_COMMON_UI 제거
  );
  if (menus.length === 0) {
    return Promise.reject('(미작업) 접근 권한이 없습니다.'); // TODO resource
  }

  const usingProductSet = allMenus
    .map((menu) => menu.split('-')[0])
    .filter((prefix) => prefix)
    .reduce((acc, prefix) => acc.add(prefix), new Set<string>())
    .add('fxmui');

  const productDtos = await productApi.products();

  const productEntities = productDtos.map((dto) => ({
    ...dto,
    enabled: usingProductSet.has(dto.context),
  }));

  const authEntity: AuthEntity = {
    tokenEntity,
    tokenStr: token,
    menus,
    productEntities,
    permissions: [],
    userInfo: initialDummyUserInfo,
    refreshTokenStr: '',
    expired: moment().valueOf(),
  };
  return authEntity;
}
export const thunkSignIn = createAsyncThunk(
  'auth/signIn',
  async ({ userId, password, redirectUrl }: SignInReqEntity) => {
    const { accessToken, refreshToken, expiresIn } = await authApi.logIn(
      userId,
      password,
      redirectUrl
    );

    const expired = moment().add(expiresIn, 'milliseconds').valueOf();

    // return createAuthEntity(accessToken);
    console.log('HHL-DEBUG accessToken:', accessToken);
    return createAuthEntityDID(userId, accessToken, refreshToken, expired);
  }
);
export const thunkSignInWithToken = createAsyncThunk(
  'auth/signInWithToken',
  async (token: string) => {
    const userId = getAdidManagerUser();
    const refreshToken = getRefreshToken();
    const expired = getExpired();

    if (!userId || !refreshToken || !expired) {
      throw new Error('signInWithToken');
    }

    return createAuthEntityDID(userId, token, refreshToken, expired);
  }
);
export const thunkSignInWithRefreshToken = createAsyncThunk(
  'auth/signInWithRefreshToken',
  async () => {
    const userId = getAdidManagerUser();
    const curRefreshToken = getRefreshToken();

    if (!userId || !curRefreshToken) {
      throw new Error('refreshToken으로 로그인 할 수 없습니다.');
    }

    const { accessToken, refreshToken, expiresIn } = await authApi.loginWithRefreshToken(
      curRefreshToken
    );
    const expired = moment().add(expiresIn, 'milliseconds').valueOf();

    console.log('Update accessToken with refreshToken', accessToken);
    return createAuthEntityDID(userId, accessToken, refreshToken, expired);
  }
);
export const thunkSignOut = createAsyncThunk<void, void, { state: RootState }>(
  'auth/signOut',
  async (arg, thunkApi) => {
    const state = thunkApi.getState();
    const userId = state.auth.authEntity?.tokenEntity.userInfo.userId;
    await authApi.logOut(userId);
  }
);
const authSlice = createSlice({
  name: 'auth',
  initialState,
  // initialState: initialDummyState,
  reducers: {
    startSignOut: (state) => {
      state.progressSignOut = true;
    },
    finishSignOut: (state) => {
      state.progressSignOut = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(thunkSignIn.fulfilled, (state, { payload: authEntity }) => {
      setToken(authEntity.tokenStr);
      setRefreshToken(authEntity.refreshTokenStr);
      setExpired(authEntity.expired);
      setAdidManagerUser(authEntity.tokenEntity.userInfo.userId);
      state.authEntity = authEntity;
    });
    builder.addCase(thunkSignInWithToken.fulfilled, (state, { payload: authEntity }) => {
      setToken(authEntity.tokenStr);
      state.authEntity = authEntity;
    });
    builder.addCase(thunkSignInWithToken.rejected, (state, { payload: authEntity }) => {
      removeToken();
      removeAdidManagerUser();
      removeExpired();
      removeAdidManagerUser();
      state.authEntity = undefined;
    });
    builder.addCase(thunkSignInWithRefreshToken.fulfilled, (state, { payload: authEntity }) => {
      setToken(authEntity.tokenStr);
      setRefreshToken(authEntity.refreshTokenStr);
      setExpired(authEntity.expired);
      setAdidManagerUser(authEntity.tokenEntity.userInfo.userId);
      state.authEntity = authEntity;
    });
    builder.addCase(thunkSignOut.fulfilled, (state, { payload: authEntity }) => {
      removeToken();
      removeAdidManagerUser();
      removeExpired();
      removeAdidManagerUser();
      state.authEntity = undefined;
    });
    builder.addCase(thunkSignOut.rejected, (state, { payload: authEntity }) => {
      removeToken();
      removeAdidManagerUser();
      removeExpired();
      removeAdidManagerUser();
      state.authEntity = undefined;
    });
  },
});

// # selectors
export const selectAuthEntity = (state: RootState): AuthEntity | undefined => {
  return state.auth.authEntity;
};
export const selectIsAuthenticated = (state: RootState): boolean => {
  return state.auth.authEntity !== undefined;
};
export const selectIsProgressSignOut = (state: RootState): boolean => {
  return state.auth.progressSignOut;
};

export const { startSignOut, finishSignOut } = authSlice.actions;
export default authSlice.reducer;
