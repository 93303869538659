import React, { useState, useEffect, createContext, useMemo } from 'react';

import ProjectList, { ProjectListItemInfo } from './ProjectList';
import CreateProject from './CreateProject';
import ProjectDetail from './ProjectDetail';
import projectApi, { ProjectDto } from 'src/api/projectApi';

import './Project.scss';

const MENU = {
  LIST: 0,
  // CREATE: 1,
  EMPTY: 2,
}



const Project: React.FC = () => {
  const [page, setPage] = useState(MENU.LIST);
  const displayComponent = [
    ProjectList,
    CreateProject,
    ProjectDetail
  ]
  const DisplayComponent = displayComponent[page];


  // useEffect(() => {
  //   projectApi.getProjectList()
  //     .then((resp) => {
  //       if (resp.length > 0) {
  //         // setPage(MENU.LIST)
  //         ;
  //       } else {
  //         // setPage(MENU.EMPTY)
  //       }
  //     })
  // }, []);



  return (
    <div className='project-root'>
      {
        <DisplayComponent
          parentTitle={'프로젝트'}
          toCancel={() => setPage(MENU.LIST)}
        />
      }
    </div>
  )
}

export default Project;