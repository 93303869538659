import React, { useEffect, useState, useContext, Fragment } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { CommonProps } from 'src/components/helpers/props';
import Popup2 from 'src/components/molecules/Popup2';
import templateApi, { TemplateDto, TaskDto, DetailDto, createTemplate, createAudit, createDetail, AuditDto, } from 'src/api/templateApi';
import Button from 'src/components/atom/Button';
import TextField from 'src/components/atom/TextField';
import './Step1EditPopup.scss';
import AddCircle2Icon from 'src/components/atom/icons/AddCircle2Icon';
import TrashIcon from 'src/components/atom/icons/TrashIcon';
import IconButton from 'src/components/atom/IconButton';
import { useAlert } from 'src/contexts/AlertContext';
import { validateAll } from 'src/shared/utils/validations';
import _, { omit } from 'lodash';
import RadioGroup from 'src/components/atom/RadioGroup';
import Radio from 'src/components/atom/Radio';


interface Step1EditPopupProps extends CommonProps {
  open: boolean;
  data: any;
  onConfirm: (itAppData: any) => void;
  onClose: () => void;
}


const Step1EditPopup: React.FC<Step1EditPopupProps> = ({
  open,
  data,
  onClose,
  onConfirm,
  classes,
}) => {
  const { t } = useTranslation(['commons']);
  const { alert2 } = useAlert();
  const [itAppData, setItAppData] = useState<any>(data);
  const validationRules = {
    name: itAppData?.every((item: any) => item?.name?.length > 0)
  };

  const handleOk = (): void => {

    if (!validateAll(validationRules)) {
      alert2("IT Application", "IT Application 이름은 필수입니다.");
      return;
    }
    onConfirm(itAppData)
    onClose();
  };

  useEffect(() => {

  }, []);

  const handleClose = () => {
    onClose();
  }

  const handleDetailAdd = () => {
    let newItAppData = [..._.cloneDeep(itAppData)];
    newItAppData.push({ name: "", scope: "In scope" })
    setItAppData(newItAppData)
  }

  const handleDeleteAccount = (i: number) => {
    let newItAppData = [..._.cloneDeep(itAppData)];
    newItAppData.splice(i, 1)
    setItAppData(newItAppData)
  }

  const handleAccountNameChange = (e: React.ChangeEvent<HTMLInputElement>, i: number) => {
    e.stopPropagation();
    let newItAppData = [..._.cloneDeep(itAppData)];
    newItAppData[i].name = e.target.value;
    setItAppData(newItAppData)
  }
  const handleChangeScope = (i: number, value: string) => {
    let newItAppData = [..._.cloneDeep(itAppData)];
    newItAppData[i].scope = value;
    setItAppData(newItAppData)
  }


  return (
    <Popup2
      classes="audit-popup-root project-popup-root project-common"
      title={"IT Application 관리"}
      width="1112px"
      height="692px"
      open={open}
      onClose={handleClose}
      footer={
        <React.Fragment>
          <div className="detail-add">
            <div
              onClick={handleDetailAdd}
            >
              <AddCircle2Icon color="#295c9f" />
              <span>IT Application 추가</span>
            </div>
          </div>
          <Button text={t('commons:cancel')} onClick={handleClose} color="secondary" />
          <Button
            text={'확인'}
            onClick={handleOk}
          // disabled={!selectedUsers} 
          />
        </React.Fragment>
      }
    >

      <div className="task-header element">
        <div className="title-content title" style={{ paddingLeft: 15, width: "50%", color: "#a3a6b4" }}>IT Application</div>
        <div className="title-account account" style={{ width: "50%", color: "#a3a6b4" }}>업무 범위 포함 여부</div>
      </div>
      <div className="audit-process-content-csm-step1-root">
        {itAppData?.map((elem: any, i: number) => {
          return (
            <div className="audit-process-content-csm-step1" key={i.toString()}>
              <div className="audit-process-content-title">
                <TextField classes="audit-process-content-title-edit"
                  value={elem?.name || ""}
                  autoFocus={!elem?.name ? true : false}
                  placeholder="IT APP명을 입력하세요."
                  onChange={(e) => { handleAccountNameChange(e, i) }}
                />
              </div>
              <div className="audit-process-content-data">
                <RadioGroup value={elem?.scope}
                  onChange={(value) => handleChangeScope(i, value)}
                >
                  <Radio classes="radio-css" label="In scope" value={'In scope'} />
                  <Radio classes="radio-css" label="Out of scope" value={'Out of scope'} />
                </RadioGroup>
              </div>
              <div className="audit-process-content-delete">
                <IconButton onClick={() => handleDeleteAccount(i)}>
                  <TrashIcon></TrashIcon>
                </IconButton>

              </div>
            </div>
          )
        })

        }
      </div>
    </Popup2>
  );
};

export default Step1EditPopup;
