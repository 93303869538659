import config from 'src/shared/config';
import axios from 'src/shared/utils/axios';
import _ from 'lodash';

import DummyApi from '../dummyApi';
import { PositionDto } from './positionApi';
import { RoleDto } from './roleApi';

const FIDP_API_ENABLED = config.servers.fidp.enabled;
const FIDP_API_URL = config.servers.fidp.url;

export enum PasswordExpirationStatus {
  AVAILABLE = 'AVAILABLE', // 정상
  EXPIRED = 'EXPIRED', // 만료
  ALERT = 'ALERT', // 경고
}

interface CodeName {
  code: string;
  name: string;
}

export interface UserSearchDto {
  count: number;
  users: UserDto[];
}

export interface UserDto {
  id: string; // 사용자 ID
  name: string; // 사용자 이름
  password: string; // 비밀번호
  email: string; // 사용자 이메일
  phoneNumber: string; // 핸드폰 번호
  depts: {
    primary: CodeName; // 기본 부서
    others: CodeName[]; // 겸직 부서
  };
  position: PositionDto; // 직급
  role: RoleDto; // 직무
  validityPeriod: {
    // 유효기간
    start: number; // 시작 일시 (-1: 무제한)
    end: number; //  종료 일시 (-1: 무제한)
  };
  useYN: 'Y' | 'N'; // 사용 여부
  passwordUpdatedTime: number; // 비밀번호 갱신 시간
  passwordStatus: PasswordExpirationStatus; // 비밀번호 상태
  initialPasswordYN: 'Y' | 'N'; // 비밀번호 초기화 여부
  updatedTime: number; // 수정 일시
  autoPassword: boolean; // 비밀번호 자동 부여
  dsdCode: string; // 도메인 코드
  linkedYN: 'Y' | 'N'; // 연동 여부
}

export interface UserInfoDto {
  user: {
    // 사용자
    id: string; // ID
    name: string; // 이름
    email: string; // 이메일
  };
  position: CodeName; // 직급
  role: CodeName; // 직무
  depts: {
    // 부서
    primary: DeptWithParents; // 기본 부서 with 상위 부서
    others: DeptWithParents[]; // 겸직 부서 with 상위 부서
  };
  managedDepts: CodeName[]; // 사용자가 부서장인 부서
}

interface DeptWithParents extends CodeName {
  // 기본 부서의 상위 부서 정보들. (범위: 기본 부서와 가까운 순서로 최상위 부서까지.)
  parents: Array<{
    level: number; // 기본 부서로부터 떨어진 거리. 1부터 시작.
    code: string; // 기본 부서의 상위 부서 코드
    name: string; // 기본 부서의 상위 부서 이름
  }>;
}

// API 연동 후 삭제할 interface
interface UserInfoEntity {
  [code: string]: UserInfoDto;
}

const userInfos: UserInfoEntity = {
  admin: {
    user: {
      id: 'admin',
      name: 'Administrator',
      email: 'admin@fasoo.com',
    },
    position: {
      code: 'P001',
      name: '사원222',
    },
    role: {
      code: 'R001',
      name: '사원',
    },
    depts: {
      primary: {
        code: 'COMPANY',
        name: 'Fasoo.com',
        parents: [],
      },
      others: [
        {
          code: 'fidp_depttest517',
          name: 'fidp_depttest517',
          parents: [
            {
              level: 1,
              code: 'COMPANY',
              name: 'Fasoo.com',
            },
          ],
        },
        {
          code: 'fidp_depttest786',
          name: 'fidp_depttest786',
          parents: [
            {
              level: 1,
              code: 'COMPANY',
              name: 'Fasoo.com',
            },
          ],
        },
      ],
    },
    managedDepts: [
      {
        code: 'COMPANY',
        name: 'Fasoo.com',
      },
      {
        code: '9760000',
        name: '중앙선거관리위원회',
      },
      {
        code: '9760001',
        name: '위원장',
      },
      {
        code: '9760002',
        name: '상임위원',
      },
    ],
  },
  sryeon: {
    user: {
      id: 'sryeon',
      name: '연소라',
      email: 'sryeon@fasoo.com',
    },
    position: {
      code: 'P001',
      name: '사원222',
    },
    role: {
      code: 'R001',
      name: '사원',
    },
    depts: {
      primary: {
        code: 'COMPANY',
        name: 'Fasoo.com',
        parents: [],
      },
      others: [
        {
          code: 'fidp_depttest517',
          name: 'fidp_depttest517',
          parents: [
            {
              level: 1,
              code: 'COMPANY',
              name: 'Fasoo.com',
            },
          ],
        },
        {
          code: 'fidp_depttest786',
          name: 'fidp_depttest786',
          parents: [
            {
              level: 1,
              code: 'COMPANY',
              name: 'Fasoo.com',
            },
          ],
        },
      ],
    },
    managedDepts: [
      {
        code: 'COMPANY',
        name: 'Fasoo.com',
      },
      {
        code: '9760000',
        name: '중앙선거관리위원회',
      },
      {
        code: '9760001',
        name: '위원장',
      },
      {
        code: '9760002',
        name: '상임위원',
      },
    ],
  },
  iu: {
    user: {
      id: 'iu',
      name: '아이유',
      email: 'iu@fasoo.com',
    },
    position: {
      code: 'P001',
      name: '사원222',
    },
    role: {
      code: 'R001',
      name: '사원',
    },
    depts: {
      primary: {
        code: 'COMPANY',
        name: 'Fasoo.com',
        parents: [],
      },
      others: [
        {
          code: 'fidp_depttest517',
          name: 'fidp_depttest517',
          parents: [
            {
              level: 1,
              code: 'COMPANY',
              name: 'Fasoo.com',
            },
          ],
        },
        {
          code: 'fidp_depttest786',
          name: 'fidp_depttest786',
          parents: [
            {
              level: 1,
              code: 'COMPANY',
              name: 'Fasoo.com',
            },
          ],
        },
      ],
    },
    managedDepts: [
      {
        code: 'COMPANY',
        name: 'Fasoo.com',
      },
      {
        code: '9760000',
        name: '중앙선거관리위원회',
      },
      {
        code: '9760001',
        name: '위원장',
      },
      {
        code: '9760002',
        name: '상임위원',
      },
    ],
  },
  dhjang: {
    user: {
      id: 'dhjang',
      name: '장동혁',
      email: 'dhjang@fasoo.com',
    },
    position: {
      code: 'P001',
      name: '사원222',
    },
    role: {
      code: 'R001',
      name: '사원',
    },
    depts: {
      primary: {
        code: 'COMPANY',
        name: 'Fasoo.com',
        parents: [],
      },
      others: [
        {
          code: 'fidp_depttest517',
          name: 'fidp_depttest517',
          parents: [
            {
              level: 1,
              code: 'COMPANY',
              name: 'Fasoo.com',
            },
          ],
        },
        {
          code: 'fidp_depttest786',
          name: 'fidp_depttest786',
          parents: [
            {
              level: 1,
              code: 'COMPANY',
              name: 'Fasoo.com',
            },
          ],
        },
      ],
    },
    managedDepts: [
      {
        code: 'COMPANY',
        name: 'Fasoo.com',
      },
      {
        code: '9760000',
        name: '중앙선거관리위원회',
      },
      {
        code: '9760001',
        name: '위원장',
      },
      {
        code: '9760002',
        name: '상임위원',
      },
    ],
  },
  ksson: {
    user: {
      id: 'ksson',
      name: 'ks',
      email: 'ksson@fasoo.com',
    },
    position: {
      code: 'P001',
      name: '사원222',
    },
    role: {
      code: 'R001',
      name: '사원',
    },
    depts: {
      primary: {
        code: 'COMPANY',
        name: 'Fasoo.com',
        parents: [],
      },
      others: [
        {
          code: 'fidp_depttest517',
          name: 'fidp_depttest517',
          parents: [
            {
              level: 1,
              code: 'COMPANY',
              name: 'Fasoo.com',
            },
          ],
        },
        {
          code: 'fidp_depttest786',
          name: 'fidp_depttest786',
          parents: [
            {
              level: 1,
              code: 'COMPANY',
              name: 'Fasoo.com',
            },
          ],
        },
      ],
    },
    managedDepts: [
      {
        code: 'COMPANY',
        name: 'Fasoo.com',
      },
      {
        code: '9760000',
        name: '중앙선거관리위원회',
      },
      {
        code: '9760001',
        name: '위원장',
      },
      {
        code: '9760002',
        name: '상임위원',
      },
    ],
  },
  dain2: {
    user: {
      id: 'dain2',
      name: '다인2',
      email: 'dain2@fasoo.com',
    },
    position: {
      code: 'P001',
      name: '사원222',
    },
    role: {
      code: 'R001',
      name: '사원',
    },
    depts: {
      primary: {
        code: '587acdd296f54bd4bbbb43d95158ffc4',
        name: '다인부서',
        parents: [],
      },
      others: [
        {
          code: 'fidp_depttest517',
          name: 'fidp_depttest517',
          parents: [
            {
              level: 1,
              code: '587acdd296f54bd4bbbb43d95158ffc4',
              name: '다인부서',
            },
          ],
        },
      ],
    },
    managedDepts: [
      {
        code: 'COMPANY',
        name: 'Fasoo.com',
      },
      {
        code: '9760000',
        name: '중앙선거관리위원회',
      },
      {
        code: '9760001',
        name: '위원장',
      },
      {
        code: '9760002',
        name: '상임위원',
      },
    ],
  },
  jksall: {
    user: {
      id: 'jksall',
      name: '경선1',
      email: 'jksall@fasoo.com',
    },
    position: {
      code: 'P001',
      name: '사원222',
    },
    role: {
      code: 'R001',
      name: '사원',
    },
    depts: {
      primary: {
        code: '18ee01d6a1654c7581a54d470fb6e724',
        name: '경선부서',
        parents: [],
      },
      others: [
        {
          code: 'fidp_depttest517',
          name: 'fidp_depttest517',
          parents: [
            {
              level: 1,
              code: '18ee01d6a1654c7581a54d470fb6e724',
              name: '경선부서',
            },
          ],
        },
        {
          code: 'fidp_depttest786',
          name: 'fidp_depttest786',
          parents: [
            {
              level: 1,
              code: 'COMPANY',
              name: 'Fasoo.com',
            },
          ],
        },
      ],
    },
    managedDepts: [
      {
        code: 'COMPANY',
        name: 'Fasoo.com',
      },
      {
        code: '9760000',
        name: '중앙선거관리위원회',
      },
      {
        code: '9760001',
        name: '위원장',
      },
      {
        code: '9760002',
        name: '상임위원',
      },
    ],
  },
};

interface UserApi {
  userByIds(ids: string[]): Promise<UserSearchDto>;
  userById(id: string): Promise<UserSearchDto>;
  userByName(searchString: string): Promise<UserSearchDto>;
  userInfo(id: string): Promise<UserInfoDto>;
  removeUsers(ids: string[]): Promise<void>;
}

class UserServerApi implements UserApi {
  async userByIds(ids: string[]): Promise<UserSearchDto> {
    const resp = await axios.get(
      `${FIDP_API_URL}/api/organs/users?user_ids%5B%5D=${ids.join(',')}&limit=${ids.length}`
    );
    return resp.data;
  }
  async userById(id: string): Promise<UserSearchDto> {
    const resp = await axios.get(`${FIDP_API_URL}/api/organs/users?user_id=${id}`);
    return resp.data;
  }
  async userByName(searchString: string): Promise<UserSearchDto> {
    const resp = await axios.get(`${FIDP_API_URL}/api/organs/users?user_name=${searchString}`);
    return resp.data;
  }
  async userInfo(id: string): Promise<UserInfoDto> {
    const resp = await axios.get(`${FIDP_API_URL}/api/organs/users/${id}/info`);
    return resp.data;
  }
  async removeUsers(ids: string[]): Promise<void> {
    await axios.delete(`${FIDP_API_URL}/api/organs/users`, { data: ids });
  }
}

// TODO. 사용자 목록을 조회할 때 POST로 변경해야함. url길이
class UserDummyApi implements UserApi {
  private data: UserSearchDto;

  constructor() {
    this.data = {
      count: 1,
      users: [
        {
          id: 'admin',
          name: 'Administrator',
          password: '',
          email: 'admin@fasoo.com',
          phoneNumber: '',
          depts: {
            primary: {
              code: 'COMPANY',
              name: 'fasoo',
            },
            others: new Array<CodeName>(),
          },
          position: {
            dsdCode: '0100000000000301',
            code: 'P001',
            name: '사원',
            order: 1,
            linkedYN: 'N',
            updatedTime: 1598852285629,
          },
          role: {
            dsdCode: '0100000000000301',
            code: 'R001',
            name: '일반',
            order: 1,
            linkedYN: 'N',
            updatedTime: 1598852285629,
          },
          validityPeriod: {
            start: -1,
            end: -1,
          },
          useYN: 'Y',
          passwordUpdatedTime: 1598713200000,
          passwordStatus: PasswordExpirationStatus.AVAILABLE,
          initialPasswordYN: 'N',
          updatedTime: 1598852285629,
          autoPassword: false,
          dsdCode: '0100000000000301',
          linkedYN: 'N',
        },
        {
          id: 'sryeon',
          name: '연소라',
          password: '',
          email: 'sryeon@fasoo.com',
          phoneNumber: '',
          depts: {
            primary: {
              code: 'COMPANY',
              name: 'fasoo',
            },
            others: new Array<CodeName>(),
          },
          position: {
            dsdCode: '0100000000000301',
            code: 'P001',
            name: '사원',
            order: 1,
            linkedYN: 'N',
            updatedTime: 1598852285629,
          },
          role: {
            dsdCode: '0100000000000301',
            code: 'R001',
            name: '일반',
            order: 1,
            linkedYN: 'N',
            updatedTime: 1598852285629,
          },
          validityPeriod: {
            start: -1,
            end: -1,
          },
          useYN: 'Y',
          passwordUpdatedTime: 1598713200000,
          passwordStatus: PasswordExpirationStatus.AVAILABLE,
          initialPasswordYN: 'N',
          updatedTime: 1598852285629,
          autoPassword: false,
          dsdCode: '0100000000000301',
          linkedYN: 'N',
        },
        {
          id: 'iu',
          name: '아이유',
          password: '',
          email: 'sryeon@fasoo.com',
          phoneNumber: '',
          depts: {
            primary: {
              code: 'COMPANY',
              name: 'fasoo',
            },
            others: new Array<CodeName>(),
          },
          position: {
            dsdCode: '0100000000000301',
            code: 'P001',
            name: '사원',
            order: 1,
            linkedYN: 'N',
            updatedTime: 1598852285629,
          },
          role: {
            dsdCode: '0100000000000301',
            code: 'R001',
            name: '일반',
            order: 1,
            linkedYN: 'N',
            updatedTime: 1598852285629,
          },
          validityPeriod: {
            start: -1,
            end: -1,
          },
          useYN: 'Y',
          passwordUpdatedTime: 1598713200000,
          passwordStatus: PasswordExpirationStatus.AVAILABLE,
          initialPasswordYN: 'N',
          updatedTime: 1598852285629,
          autoPassword: false,
          dsdCode: '0100000000000301',
          linkedYN: 'N',
        },
        {
          id: 'dhjang',
          name: '장동혁',
          password: '',
          email: 'sryeon@fasoo.com',
          phoneNumber: '',
          depts: {
            primary: {
              code: 'COMPANY',
              name: 'fasoo',
            },
            others: new Array<CodeName>(),
          },
          position: {
            dsdCode: '0100000000000301',
            code: 'P001',
            name: '사원',
            order: 1,
            linkedYN: 'N',
            updatedTime: 1598852285629,
          },
          role: {
            dsdCode: '0100000000000301',
            code: 'R001',
            name: '일반',
            order: 1,
            linkedYN: 'N',
            updatedTime: 1598852285629,
          },
          validityPeriod: {
            start: -1,
            end: -1,
          },
          useYN: 'Y',
          passwordUpdatedTime: 1598713200000,
          passwordStatus: PasswordExpirationStatus.AVAILABLE,
          initialPasswordYN: 'N',
          updatedTime: 1598852285629,
          autoPassword: false,
          dsdCode: '0100000000000301',
          linkedYN: 'N',
        },
        {
          id: 'ksson',
          name: 'ks',
          password: '',
          email: 'ks@fasoo.com',
          phoneNumber: '',
          depts: {
            primary: {
              code: 'COMPANY',
              name: 'fasoo',
            },
            others: new Array<CodeName>(),
          },
          position: {
            dsdCode: '0100000000000301',
            code: 'P001',
            name: '사원',
            order: 1,
            linkedYN: 'N',
            updatedTime: 1598852285629,
          },
          role: {
            dsdCode: '0100000000000301',
            code: 'R001',
            name: '일반',
            order: 1,
            linkedYN: 'N',
            updatedTime: 1598852285629,
          },
          validityPeriod: {
            start: -1,
            end: -1,
          },
          useYN: 'Y',
          passwordUpdatedTime: 1598713200000,
          passwordStatus: PasswordExpirationStatus.AVAILABLE,
          initialPasswordYN: 'N',
          updatedTime: 1598852285629,
          autoPassword: false,
          dsdCode: '0100000000000301',
          linkedYN: 'N',
        },
        {
          id: 'dain2',
          name: '다인2',
          password: '',
          email: 'dain2@fasoo.com',
          phoneNumber: '',
          depts: {
            primary: {
              code: '587acdd296f54bd4bbbb43d95158ffc4',
              name: '다인부서',
            },
            others: new Array<CodeName>(),
          },
          position: {
            dsdCode: '0100000000000301',
            code: 'P001',
            name: '사원',
            order: 1,
            linkedYN: 'N',
            updatedTime: 1598852285629,
          },
          role: {
            dsdCode: '0100000000000301',
            code: 'R001',
            name: '일반',
            order: 1,
            linkedYN: 'N',
            updatedTime: 1598852285629,
          },
          validityPeriod: {
            start: -1,
            end: -1,
          },
          useYN: 'Y',
          passwordUpdatedTime: 1598713200000,
          passwordStatus: PasswordExpirationStatus.AVAILABLE,
          initialPasswordYN: 'N',
          updatedTime: 1598852285629,
          autoPassword: false,
          dsdCode: '0100000000000301',
          linkedYN: 'N',
        },
        {
          id: 'jksall',
          name: '경선1',
          password: '',
          email: 'jksall@fasoo.com',
          phoneNumber: '',
          depts: {
            primary: {
              code: '18ee01d6a1654c7581a54d470fb6e724',
              name: '경선부서',
            },
            others: new Array<CodeName>(),
          },
          position: {
            dsdCode: '0100000000000301',
            code: 'P001',
            name: '사원',
            order: 1,
            linkedYN: 'N',
            updatedTime: 1598852285629,
          },
          role: {
            dsdCode: '0100000000000301',
            code: 'R001',
            name: '일반',
            order: 1,
            linkedYN: 'N',
            updatedTime: 1598852285629,
          },
          validityPeriod: {
            start: -1,
            end: -1,
          },
          useYN: 'Y',
          passwordUpdatedTime: 1598713200000,
          passwordStatus: PasswordExpirationStatus.AVAILABLE,
          initialPasswordYN: 'N',
          updatedTime: 1598852285629,
          autoPassword: false,
          dsdCode: '0100000000000301',
          linkedYN: 'N',
        },
      ],
    };
  }

  userByIds(ids: string[]): Promise<UserSearchDto> {
    return Promise.resolve(_.cloneDeep(this.data));
  }
  userById(id: string): Promise<UserSearchDto> {
    return Promise.resolve(_.cloneDeep(this.data));
  }
  userByName(searchString: string): Promise<UserSearchDto> {
    return Promise.resolve(_.cloneDeep(this.data));
  }
  userInfo(id: string): Promise<UserInfoDto> {
    const returnObj = userInfos[id];
    return Promise.resolve(returnObj);
  }
  removeUsers(ids: string[]): Promise<void> {
    return Promise.resolve();
  }
}

const userApi: UserApi = FIDP_API_ENABLED ? new UserServerApi() : new UserDummyApi();

export default userApi;
