import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { Registration, UseYN } from 'src/api/infra';
import urlApi, { UrlDto, UrlReqDto } from 'src/api/infra/urlApi';
import { toLocalDateTimeStr } from 'src/shared/utils/localDateTime';
import { RootState } from 'src/store';

// # types
export type UrlEntity = Omit<UrlDto, 'useYN' | 'entryTime'> & {
  use: boolean;
  entryTimeStr: string;
};
export type UrlReqEntity = Omit<UrlEntity, 'code' | 'entryTime' | 'entryTimeStr'>;

function toEntity(dto: UrlDto): UrlEntity {
  return { ...dto, use: dto.useYN === UseYN.Y, entryTimeStr: toLocalDateTimeStr(dto.entryTime) };
}
function toReqDto(entity: UrlReqEntity): UrlReqDto {
  return { ...entity, useYN: entity.use ? UseYN.Y : UseYN.N };
}

interface UrlEntityByCode {
  [code: string]: UrlEntity;
}
export interface UrlEntities {
  byCode: UrlEntityByCode;
  allCodes: string[];
}
interface UrlState {
  initialized: boolean;
  entities: UrlEntities;
}

export interface UrlUpdateReq {
  code: string;
  reqEntity: UrlReqEntity;
}

// # initial state
const initialState: UrlState = {
  initialized: false,
  entities: { byCode: {}, allCodes: [] },
};

export const defaultUrlReqEntity: UrlReqEntity = {
  url: '',
  description: '',
  registration: Registration.M,
  use: true,
};

// # thunks
export const thunkLoadUrls = createAsyncThunk('url/load', async () => {
  const list = await urlApi.list();
  return list.map((dto) => toEntity(dto));
});
export const thunkCreateUrl = createAsyncThunk('url/create', async (reqEntity: UrlReqEntity) => {
  return toEntity(await urlApi.create(toReqDto(reqEntity)));
});
export const thunkUpdateUrl = createAsyncThunk(
  'url/update',
  async ({ code, reqEntity }: UrlUpdateReq) => {
    return toEntity(await urlApi.update(code, toReqDto(reqEntity)));
  }
);
export const thunkRemoveUrl = createAsyncThunk('url/remove', async (code: string) => {
  return urlApi.remove(code);
});

const urlSlice = createSlice({
  name: 'url',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(thunkLoadUrls.fulfilled, (state, { payload: urls }) => {
      state.entities.byCode = urls.reduce((acc, t) => {
        acc[t.code] = t;
        return acc;
      }, {} as UrlEntityByCode);
      state.entities.allCodes = urls.map((t) => t.code);
      state.initialized = true;
    });
    builder.addCase(thunkCreateUrl.fulfilled, (state, { payload: createdEntity }) => {
      state.entities.byCode[createdEntity.code] = createdEntity;
      state.entities.allCodes.push(createdEntity.code);
    });
    builder.addCase(thunkUpdateUrl.fulfilled, (state, { payload: updatedEntity }) => {
      state.entities.byCode[updatedEntity.code] = updatedEntity;
    });
    builder.addCase(thunkRemoveUrl.fulfilled, (state, { meta: { arg: removedCode } }) => {
      delete state.entities.byCode[removedCode];
      state.entities.allCodes = state.entities.allCodes.filter((code) => code !== removedCode);
    });
  },
});

// # selectors
export const selectInitialized = (state: RootState): boolean => {
  return state.url.initialized;
};
export const selectUrlEntities = (state: RootState): UrlEntities => state.url.entities;
export const selectUrlEntityList = (state: RootState): UrlEntity[] =>
  state.url.entities.allCodes.map((code) => state.url.entities.byCode[code]);

export default urlSlice.reducer;

// 참고중...
// https://orizens.com/blog/how-to-not-have-a-mess-with-react-hooks-and-redux/
