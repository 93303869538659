import _ from 'lodash';
import moment from 'moment';

export interface ApiDto {
  code: string;
}

const PREDEFINED_TIMES = [
  '2020-08-17 18:14:27',
  '2020-08-17 21:07:03',
  '2020-08-18 06:37:27',
  '2020-08-18 17:14:58',
  '2020-08-18 22:38:52',
  '2020-08-21 19:25:37',
  '2020-08-22 11:39:11',
  '2020-08-22 14:34:31',
  '2020-08-23 13:35:09',
  '2020-08-23 09:05:14',
  '2020-08-24 02:59:13',
].map((s) => new Date(`${s} +0900`).valueOf());

function generateRandomTime(): number {
  const now = moment();
  const upper = now.valueOf();
  now.subtract(7, 'days');
  const lower = now.valueOf();

  return _.random(lower, upper);
}

export function times(i: number): number {
  return i < PREDEFINED_TIMES.length ? PREDEFINED_TIMES[i] : generateRandomTime();
}
