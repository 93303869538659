import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import roleApi, { RoleDto } from 'src/api/fidp/roleApi';
import { RootState } from 'src/store';

// # types
export type RoleEntity = RoleDto;

function toEntity(dto: RoleDto): RoleEntity {
  return { ...dto };
}

interface RoleEntityByCode {
  [code: string]: RoleEntity;
}
export interface RoleEntities {
  byCode: RoleEntityByCode;
  allCodes: string[];
}
interface RoleState {
  initialized: boolean;
  entities: RoleEntities;
}

// # initial state
const initialState: RoleState = {
  initialized: false,
  entities: { byCode: {}, allCodes: [] },
};

// # thunks
export const thunkLoadRoles = createAsyncThunk('fidp/role/load', async () => {
  const list = await roleApi.list();
  return list.map((dto) => toEntity(dto));
});

const roleSlice = createSlice({
  name: 'role',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(thunkLoadRoles.fulfilled, (state, { payload: roles }) => {
      state.entities.byCode = roles.reduce((acc, t) => {
        acc[t.code] = t;
        return acc;
      }, {} as RoleEntityByCode);
      state.entities.allCodes = roles.map((t) => t.code);
      state.initialized = true;
    });
  },
});

// # selectors
export const selectInitialized = (state: RootState): boolean => {
  return state.role.initialized;
};
export const selectRoleEntities = (state: RootState): RoleEntities => state.role.entities;
export const selectRoleEntityList = (state: RootState): RoleEntity[] =>
  state.role.entities.allCodes.map((code) => state.role.entities.byCode[code]);

export default roleSlice.reducer;
