import React, { useRef, useState, useEffect } from 'react';

import PageTop from 'src/components/molecules/PageTop';
import ButtonGroup from 'src/components/atom/ButtonGroup';
import Button from 'src/components/atom/Button';
import SearchBar from 'src/components/molecules/SearchBar';
import SelectToggle, { SelectToggleItem } from 'src/components/atom/SelectToggle';
import Select, { SelectItem } from 'src/components/atom/Select';
import ProjectCard from './ProjectCard';
import AddCircleLineIcon from 'src/components/atom/icons/AddCircleLineIcon';
import projectApi, { ProjectDto } from 'src/api/projectApi';

import './index.scss';
import { useHistory, useParams } from 'react-router-dom';
import { useToast } from 'src/contexts/ToastContext';
import { useAlert } from 'src/contexts/AlertContext';
import jsUtils from 'src/shared/utils/jsUtils';
import useMyProjectResource from 'src/hooks/useMyProjectResource';
import ConfirmIcon from 'src/components/atom/icons/ConfirmIcon';
import useAuthStore from 'src/hooks/auth/useAuthStore';
import projectApi2, { ProjectJobStatusDtoDic } from 'src/api/projectApi2';
import jobApi from 'src/api/jobApi';
import templateApi, { } from 'src/api/templateApi';
import { startProgress, stopProgress } from 'src/store/progress';
import AuditFileUploadPopup from '../Category/AuditFileUploadPopup';
import ImportProjectPopup from './ImportProjectPopup';
import ImportProgressPopup from './ImportProgressPopup';
import usePopup from 'src/hooks/usePopup';
import FileToUpload from '../Category/FileToUpload';
import { getAdidManagerUser } from 'src/shared/utils/sessionStorageManager';

interface ProjectButtonGrpProps {
  projectCreate?: () => void,
  title: string,
  search: string,
  setSearch: (search: string) => void,
  hasResourceProject: boolean,
  handleChangeOrderBy: (value: string) => void,
  handleSelectProjectStatus: (value: string) => void,
}
const ProjectButtonGrp: React.FC<ProjectButtonGrpProps> = ({
  projectCreate,
  title,
  search,
  setSearch,
  hasResourceProject,
  handleChangeOrderBy,
  handleSelectProjectStatus,
}) => {

  return (
    <ButtonGroup spacing={10}>
      <div className='order-select-box' style={{ marginRight: 10 }}>
        <Select onClick={handleChangeOrderBy}
          autoWidth
        // border={false}
        >
          <SelectItem eventKey={'all'}>
            {'전체(프로젝트 타입)'}
          </SelectItem>
          <SelectItem eventKey={'audit'}>
            {'감사'}
          </SelectItem>
          <SelectItem eventKey={'service'}>
            {'용역'}
          </SelectItem>
        </Select>
      </div>
      <div className='order-select-box' style={{ marginRight: 10 }}>
        <Select onClick={handleSelectProjectStatus}
          autoWidth
        // border={false}
        >
          <SelectItem eventKey={'all'}>
            {'전체(프로젝트 상태)'}
          </SelectItem>
          <SelectItem eventKey={'ing'}>
            {'진행중'}
          </SelectItem>
          <SelectItem eventKey={'not-ing'}>
            {'그 외'}
          </SelectItem>
        </Select>
      </div>
      <SearchBar
        value={search}
        classes='search-bar'
        placeholder={'프로젝트 명을 입력해주세요.'}
        onChange={(e) => setSearch(e.currentTarget.value)}
      />




      {/* {hasResourceProject && ( */}
      <Button
        text={title}
        startIcon={<AddCircleLineIcon />}
        onClick={projectCreate}
        classes={'create-button'}
      />
      {/* )} */}
    </ButtonGroup>
  )
}


const useContainerDimensions = (myRef: React.RefObject<HTMLDivElement>) => {
  const getDimensions = () => ({
    width: myRef.current ? myRef.current.offsetWidth : 0,
    height: myRef.current ? myRef.current.offsetHeight : 0
  })

  const [dimensions, setDimensions] = useState({ width: 0, height: 0 })

  useEffect(() => {
    const handleResize = () => {
      setDimensions(getDimensions())
    }

    if (myRef) {
      setDimensions(getDimensions())
    }

    window.addEventListener("resize", handleResize)

    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [myRef])

  return dimensions;
};

export interface ProjectListItemInfo {
  title: string
  desc?: string
  date?: string
}
export interface ProjectListProps {
  toUpdate?: () => void
  deleteProject?: (index: number) => void
}
const ProjectList: React.FC<ProjectListProps> = ({
  toUpdate,
  deleteProject,
}) => {
  const [projectList, setProjectList] = useState<ProjectDto[]>([]);
  const history = useHistory();
  const [search, setSearch] = useState<string>('');
  const [reload, setReload] = useState<boolean>(false);
  const fileDownloadPopup = usePopup();
  const importProjectPopup = usePopup();
  const importProjectProgressPopup = usePopup();
  const listRef = useRef<HTMLDivElement>(null)
  const { width, height } = useContainerDimensions(listRef)
  const toast = useToast();
  const { alert, alert2, confirmWithWarn } = useAlert();
  const { authEntity } = useAuthStore();
  const [progressPercent, setProgressPercent] = useState(0);
  const [importProgressPercent, setImportProgressPercent] = useState(0);
  const [progressLevel, setProgressLevel] = useState(0);
  const [ctrlKey, setCtrlKey] = useState(false);
  const [projectJobStatusDtoDic, setProjectJobStatusDtoDic] = useState<ProjectJobStatusDtoDic>();
  const [projectTypeString, setProjectTypeString] = useState("all");
  const [projectStatusString, setProjectStatusString] = useState("all");
  const [abortFileDownloadObj, setAbortFilDownloadObj] = useState<{ obj: XMLHttpRequest, uriObj: any }>();
  const [abortFileUploadObj, setAbortFileUploadObj] = useState<{ obj: FileToUpload, uriObj: any }>();
  const hasResourceProject =
    authEntity !== undefined && authEntity.permissions !== undefined
      ? authEntity?.permissions.includes('admin') || authEntity?.permissions.includes('project')
      : false;

  useEffect(() => {
    if (progressPercent > 0 && !fileDownloadPopup.opened) {
      fileDownloadPopup.openPopup();
    }
    if (progressPercent === 100) {
      setTimeout(() => { fileDownloadPopup.closePopup() }, 500)
    }
  }, [progressPercent])

  useEffect(() => {
    projectApi.getProjectList()
      .then((resp) => {
        //console.log('getProjectList respData:', resp)
        if (resp?.length > 0) {
          setProjectList(resp)
        }
      })
      .catch((err) => alert2("에러발생", err));
  }, []);

  useEffect(() => {
    if (reload) {
      projectApi.getProjectList()
        .then((resp) => {
          //console.log('getProjectList respData:', resp)
          if (resp?.length > 0) {
            setProjectList(resp)
          }
        })
        .catch((err) => alert2("에러발생", err));
      setReload(false);
    }
  }, [reload]);

  useEffect(() => {
    projectApi.getProjectList()
      .then((resp) => {
        let newProjectList: any[] = [];
        if (projectTypeString === 'all') {
          newProjectList = resp || [];
        } else if (projectTypeString === 'audit') {
          newProjectList = (resp || []).filter(item => item.type !== "service");
        } else if (projectTypeString === 'service') {
          newProjectList = (resp || []).filter(item => item.type === "service");
        }
        if (projectStatusString === 'all') {

        } else if (projectStatusString === 'ing') {
          newProjectList = newProjectList?.filter(item => item.status !== "closed" && item.status !== "imported" && item.status !== "archived");
        } else if (projectStatusString === 'not-ing') {
          newProjectList = newProjectList?.filter(item => item.status === "closed" || item.status === "imported" || item.status === "archived");
        }
        setProjectList(newProjectList)

      })
      .catch((err) => alert2("에러발생", err));


  }, [projectTypeString, projectStatusString])

  const projectExist = async (projectId: string): Promise<any> => {
    try {
      const resp = await projectApi.getProjectList();
      const arr = (resp || []).filter(item => item.project_id === projectId);
      if (arr.length > 0) {
        return Promise.resolve(true);
      } else {
        return Promise.resolve(false);
      }
    } catch (e) {
      return Promise.reject(e);
    }
  }

  const onUpdate = () => {
    setReload(true);
  }

  const handleProgress = (percent: number): void => {
    console.log("handleProgress :", percent)
    if (percent < 100) {
      setProgressPercent(percent)
    } else {
      setProgressPercent(100)
    }
  }

  const handleImportProgress = (percent: number): void => {
    console.log("handleProgress :", percent)
    if (percent < 100) {
      setImportProgressPercent(percent)
    } else {
      setImportProgressPercent(100)
    }
  }

  const getCardWidth = (contentWidth: number) => {
    const defaultCardWidth = 338;
    contentWidth -= 28; // 카드간 space 크기
    let cardNum = Math.floor(contentWidth / (defaultCardWidth + 28));
    let remain = contentWidth % (defaultCardWidth + 28);
    let extra = 0;
    if (cardNum > 0) extra = remain / cardNum;

    return defaultCardWidth + extra;
  }

  const handleAddProject = (): void => {
    history.push(`projects/add`);
  }

  const handleImportProject = () => {
    importProjectPopup.openPopup();
  }


  const handleRemove = async (projectId: string) => {
    try {
      const resp = await projectExist(projectId)
      if (resp) {
        templateApi.hasAuditProject(projectId)
          .then(resp => {
            console.log("resp : ", resp)
            if (resp?.has_audit === true) {
              alert2("프로젝트 삭제", '조서가 남아 있는 "세부 TASK"가 존재합니다.\n조서가 모두 지워지지 않으면 삭제할 수 없습니다.')
            } else {
              projectApi.deleteProject(projectId)
                .then((res) => {
                  toast.info('프로젝트가 삭제되었습니다.');
                  onUpdate();
                })
                .catch((err) => alert2("프로젝트 삭제", err))
            }
          })
          .catch(err => alert2("프로젝트 삭제", err))
      } else {
        alert2("프로젝트 삭제", '이미 삭제된 프로젝트입니다.');
        onUpdate();
      }
    } catch (e) {
      alert2("프로젝트 삭제", e as Error)
    }

  }

  const handleSelectProjectType = (value: string) => {
    setProjectTypeString(value)
  }
  const handleSelectProjectStatus = (value: string) => {
    setProjectStatusString(value)
  }


  const handleDetail = async (projectId: string) => {
    try {
      const resp = await projectExist(projectId)
      if (resp) {
        history.push(`/projects/${projectId}/dashboard`);
      } else {
        alert2("프로젝트 조회", '이미 삭제된 프로젝트입니다.');
        onUpdate();
      }
    } catch (e) {
      alert2("프로젝트 조회", e as Error)
    }
  }

  const handleStatusChange = async (projectId: string, status: string) => {

    try {
      if (status === "closed") {
        const resp = await projectApi.getProjectTaskProgress(projectId)
        if (!resp) {
          alert2("프로젝트 상태변경", "프로젝트를 닫을 수 없습니다.\n프로젝트의 세부 태스크가 모두 완료되었는지 확인해 주세요.")
          return;
        }
      }
      if (status === "archived") {
        const resp = await projectApi.getProject(projectId)
        const currentUser = getAdidManagerUser();
        const currentUserInfo = resp.member_list?.filter((member) => member.account === currentUser)[0];
        const authority_name = currentUserInfo?.role.authority_name;
        if (authority_name !== "Admin") {
          alert2("프로젝트 상태변경", "프로젝트를 마감할 수 없습니다.\n프로젝트의 Admin 권한을 가진 사람만이 가능합니다.")
          return;
        }
      }
      const changeStatus = async () => {
        projectApi.updateProjectStatus(projectId, status)
          .then(resp => {
            toast.info('프로젝트의 상태가 변경되었습니다.');
            onUpdate();
          })
          .catch(e => {
            alert2("에러발생", e)
          })
      }
      const desc = status === "closed" ? "닫힘" : status === "archived" ? "마감" : "열림";
      confirmWithWarn("프로젝트 상태변경", `프로젝트의 상태를 "${desc}" 으로 변경하시겠습니까?`,
        changeStatus)
    } catch (e) {
      alert2("에러발생", e as Error)
    }
  }

  const handleFileDownloadComplete = (relative_path: string, file_name: string) => {
    projectApi.deleteExportedProject(file_name, relative_path)
      .then(resp => {
        toast.info('프로젝트 EXPORT가 완료되었습니다.');
      })
      .catch(e => {
        alert2("에러발생", e)
      })
  }

  const handleFileDownloadError = (relative_path: string, file_name: string) => {
    projectApi.deleteExportedProject(file_name, relative_path)
      .then(resp => {
        //toast.info('프로젝트 EXPORT 과정이 취소되거나 에러가 발생하였습니다.');
      })
      .catch(e => {
        alert2("에러발생", e)
      })
  }

  const handleExportProject = (projectId: string) => {

    const getFile = (url: string, fileName: string, relative_path: string, progressCallback?: (percent: number) => void, completeCallback?: () => void, errorCallback?: () => void,) => {
      if (!url) return
      if (!window.XMLHttpRequest || !window.Blob) {
        //Incompatible processing (below IE7)
        window.location.href = url
        return
      }

      const xhr = new XMLHttpRequest();
      xhr.open("GET", url, true);
      xhr.responseType = "blob";
      xhr.onprogress = (e) => {
        if (e.lengthComputable) {
          // 다운로드 진행률 계산
          var percentComplete = Math.floor(e.loaded / e.total * 100);
          progressCallback && progressCallback(percentComplete)
        } else {
          // 사이즈 측정 불가
        }
      }
      xhr.onerror = (ev) => {
        console.log("ERROR : ", ev)
        errorCallback && errorCallback();
      }
      xhr.onabort = (ev) => {
        console.log("ABORT : ", ev)
        errorCallback && errorCallback();
      }
      xhr.onloadend = (ev) => {
        console.log("END!!!!!!!!!!! : ", ev)
        if (ev.total > 0) {
          completeCallback && completeCallback();
        }
      }

      xhr.onload = () => {
        var a = document.createElement("a");
        a.download = fileName;
        a.href = window.URL.createObjectURL(new Blob([xhr.response]));;
        document.body.appendChild(a);
        a.click();
      };
      setAbortFilDownloadObj({ obj: xhr, uriObj: { file_name: fileName, path: url, relative_path: relative_path } });
      xhr.send();
    }

    const exportFunction = () => projectApi.exportProject(projectId)
      .then(resp => {
        console.log("resp : ", resp)
        function download(url: string) {
          const a = document.createElement('a')
          a.href = url
          a.download = url.split('/').pop() || ""
          document.body.appendChild(a)
          a.click()
          document.body.removeChild(a)
        }
        // download(resp?.uri?.path || "")
        getFile(resp?.uri?.path, resp?.uri?.path.split('/').pop() || "", resp?.uri?.relative_path, handleProgress, () => handleFileDownloadComplete(resp?.uri?.relative_path, resp?.uri?.path.split('/').pop()),
          () => handleFileDownloadError(resp?.uri?.relative_path, resp?.uri?.path.split('/').pop()))
      })
      .catch(e => {
        alert2("에러발생", e)
      })

    confirmWithWarn("프로젝트 EXPORT", "프로젝트를 EXPORT하시면 다운로드 폴더에 파일이 생성됩니다.\n계속 진행하시겠습니까?",
      exportFunction)
  }

  useEffect(() => {
    (() => {
      window.addEventListener("keydown", (e) => {
        if (e.ctrlKey) {
          setCtrlKey(true)
        }
      });
      window.addEventListener("keyup", (e) => {
        setCtrlKey(false)
      });
    })();
    return () => {
      window.removeEventListener("keydown", () => { });
      window.removeEventListener("keyup", () => { });
    };
  }, [])

  const importProject = async (file: File) => {
    importProjectProgressPopup.openPopup();

    try {
      //prepare
      setProgressLevel(1);
      const resp = await projectApi.prepareArchive(file.name);
      console.log("resp :", resp)
      const uriObj = resp.uri_list[0];
      //upload
      setProgressLevel(2);
      const uploader = new FileToUpload(uriObj.path, file, file.name, () => {
        setProgressLevel(3);
        projectApi.importProject(uriObj.file_name, uriObj.path, uriObj.relative_path)
          .then(resp => {
            importProjectPopup.closePopup();
            importProjectProgressPopup.closePopup()
            setProgressLevel(0);
            toast.info('프로젝트 RESTORE가 완료되었습니다.');
            onUpdate();
          })
      }, handleImportProgress, (e: Error) => {
        alert2("파일 업로드", e)
      });

      setAbortFileUploadObj({ obj: uploader, uriObj: uriObj })
      uploader.uploadFile();
      //import project
    } catch (e) {
      console.log("importProject error :", e)
    }

  }

  const abortFileDownload = () => {
    abortFileDownloadObj?.obj?.abort();
  }

  const abortFileUpload = () => {
    abortFileUploadObj?.obj?.request.abort();
    projectApi.cancelFileUpload(abortFileUploadObj?.uriObj.file_name, abortFileUploadObj?.uriObj.path, abortFileUploadObj?.uriObj.relative_path)
      .then()
      .catch(e => console.log(e))
  }

  return (
    <div className='project-list-root'>
      <PageTop title={'프로젝트'}
        action={<ProjectButtonGrp
          title={ctrlKey ? '프로젝트 Restore' : "프로젝트 생성"}
          projectCreate={ctrlKey ? handleImportProject : handleAddProject}
          setSearch={setSearch}
          search={search}
          hasResourceProject={hasResourceProject}
          handleChangeOrderBy={handleSelectProjectType}
          handleSelectProjectStatus={handleSelectProjectStatus}
        />}>
      </PageTop>
      <div className='project-list-content' ref={listRef}>
        {
          projectList
            .filter(p => search ? (p.name.toLowerCase().indexOf(search.toLowerCase()) >= 0) : true)
            .sort((a, b) => {
              if (a.name > b.name) return 1;
              if (a.name < b.name) return -1;
              return 0;
            }).map((project, i) => {
              let projectCardClass = 'project-card';
              if (project.status === 'closed') projectCardClass = 'project-card-closed';
              if (project.status === 'archived') projectCardClass = 'project-card-archived';
              if (project.status === 'imported') projectCardClass = 'project-card-imported';
              let desc = ""
              if (project.status === 'closed') desc = '(Closed)';
              if (project.status === 'imported') desc = '(Restored)';
              if (project.status === 'archived') desc = '(Archived)';
              return (
                <ProjectCard key={i}
                  projectId={project.project_id}
                  iconTitie={project.type === "service" ? "용역" : "감사"}
                  iconColor={project.type === "service" ? (project.status === 'imported' || project.status === 'closed' || project.status === 'archived' ? "#274e82" : "#295C9F") : (project.status === 'imported' || project.status === 'closed' || project.status === 'archived' ? "#c7685b" : "#F77e6e")}
                  projectStatus={project.status}
                  width={getCardWidth(width)}
                  classes={projectCardClass}
                  minWidth={338}
                  title={project.name}
                  date={project?.updated_at}
                  // desc={project.comments}
                  // date={project.created_at}
                  jobStatus={projectJobStatusDtoDic && projectJobStatusDtoDic[project.name]}
                  deleteAction={() => {
                    if (handleRemove) confirmWithWarn('프로젝트 삭제', '프로젝트를 삭제하면 다시 복구할 수 없습니다.\n정말 프로젝트를 삭제하시겠습니까?', () => handleRemove(project.project_id))
                  }}
                  detailAction={handleDetail}
                  changeStatusAction={handleStatusChange}
                  exportAction={handleExportProject}
                  desc={desc}
                  hasResourceProject={hasResourceProject} />
              )
            })
        }
      </div>
      {fileDownloadPopup.opened &&
        <AuditFileUploadPopup open={fileDownloadPopup.opened} progressPercent={progressPercent} type={"download"}
          onAbort={() => { abortFileDownload() }}
          onClose={() => { fileDownloadPopup.closePopup() }} />
      }
      {
        importProjectPopup.opened &&
        <ImportProjectPopup open={importProjectPopup.opened}
          onConfirm={importProject}
          onClose={() => { importProjectPopup.closePopup() }} />
      }
      {importProjectProgressPopup.opened &&
        <ImportProgressPopup open={importProjectProgressPopup.opened}
          level={progressLevel}
          progressPercent={importProgressPercent}
          onAbort={() => { abortFileUpload() }}
          onClose={() => { importProjectProgressPopup.closePopup() }} />
      }
    </div>
  )
}

export default ProjectList;