import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import DotIcon from 'src/components/atom/icons/DotIcon';
import ReactQuill, { Quill } from 'react-quill';
import Button from 'src/components/atom/Button';

import './DefaultRequirement.scss';


const DefaultRequirement: React.FC<{
  editMode: boolean,
  requirements: string,
  handleChange: (html: string) => void;
  handleCancelRequirement: () => void;
  handleConfirmRequirement: () => void;
}> = ({
  editMode,
  requirements,
  handleChange,
  handleCancelRequirement,
  handleConfirmRequirement,
}) => {

    return (
      <div className="sp-default-detail-body">
        <div className="sp-default-detail-body-title element">
          <div className="title" style={{ paddingLeft: 10 }}><DotIcon classes="dot-icon" color='#43425d' />요구사항</div>
        </div>
        <div className="sp-default-detail-body-requirements">
          <div className={classNames("requirements-editor")}>
            <ReactQuill
              modules={{
                toolbar: [
                  [
                    { list: "ordered" },
                    { list: "bullet" },
                    { indent: "-1" },
                    { indent: "+1" },
                  ],

                ]
              }}
              readOnly={!editMode}
              value={requirements || ''}
              style={{ fontSize: 50 }}
              onChange={handleChange}
            />
          </div>
        </div>
        {editMode && <div className="sp-default-detail-footer common-page-footer project-footer">
          <div className={classNames('right-button')}>
            <Button classes="footer-button" text="취소" color="secondary" onClick={handleCancelRequirement} />
            <Button classes="footer-button" text="확인" onClick={handleConfirmRequirement} />
          </div>
        </div>}
      </div>)
  }

export default DefaultRequirement;