import React, { useEffect, useState } from 'react';

import ToolTip from 'src/components/atom/ToolTip';

import ArrowDownIcon from 'src/components/atom/icons/ArrowDownIcon';
import ArrowUpIcon from 'src/components/atom/icons/ArrowUpIcon';
import RemoveIcon from 'src/components/atom/icons/RemoveIcon';
import './Step5.scss';

const DetailTaskElement4: React.FC<{
  detailContent: { name: string, frequency: string, control_type: string, it_app: string, key: string },
  detailVisible: boolean,
}> = ({
  detailContent,
  detailVisible,
}) => {
    // console.log("중간감사 스텝5의 4단계 : ", detailContent)

    return (
      <div>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div className="sp-ma-step5-detail-body-title element" style={{ paddingLeft: "35%", width: "70%", backgroundColor: "#fff" }}>
            <RemoveIcon fill="#bcbccb" classes='icon-down' />
            <div className="sp-ma-step5-detail-account">
              <ToolTip
                message={detailContent.name}
                maxWidth={500}
              >
                <div className="sp-ma-step5-detail-account-text">
                  {detailContent.name}
                </div>
              </ToolTip>
            </div>
          </div>
          <div className="sp-ma-step5-detail-account element" style={{ width: "10%", backgroundColor: "#fff" }}>{detailContent.frequency}</div>
          <div className="sp-ma-step5-detail-account element" style={{ width: "7%", backgroundColor: "#fff" }}>{detailContent.control_type}</div>
          <div className="sp-ma-step5-detail-account element" style={{ width: "7%", backgroundColor: "#fff" }}>{detailContent.it_app}</div>
          <div className="sp-ma-step5-detail-account element" style={{ width: "6%", backgroundColor: "#fff" }}>{detailContent.key}</div>
        </div>
      </div>

    )
  }

const DetailTaskElement3: React.FC<{
  detailContent: { name: string, transaction_type_list: any, checked: boolean },
  detailVisible: boolean,
}> = ({
  detailContent,
  detailVisible,
}) => {
    // console.log("중간감사 스텝5의 3단계 : ", detailContent)
    const [visible, setVisible] = useState<boolean>(detailVisible);
    if (!detailContent?.checked) {
      return <></>
    }

    return (
      <div>
        <div className="sp-ma-step5-detail-body-title element" style={{ paddingLeft: "20%", backgroundColor: "#fff" }}
          onClick={() => setVisible(!visible)}
        >
          {detailContent?.transaction_type_list?.length > 0 ?
            visible ? <ArrowUpIcon classes='icon-up' /> :
              <ArrowDownIcon fill="#bcbccb" classes='icon-down' />
            : <RemoveIcon fill="#bcbccb" classes='icon-down' />}
          <div className="sp-ma-step5-detail-account">
            <ToolTip
              message={detailContent.name}
              maxWidth={500}
            >
              <div className="sp-ma-step5-detail-account-text">
                {detailContent.name}
              </div>
            </ToolTip>
          </div>
        </div>
        {visible && detailContent.transaction_type_list?.map((elem: any, idx: number) => {
          return (
            <DetailTaskElement4 key={idx.toString()} detailContent={elem} detailVisible={true} />

          )
        })
        }
      </div>

    )
  }

const DetailTaskElement2: React.FC<{
  detailContent: { name: string, transaction_type_list: any, strategy_type: string, it_app: string, checked: boolean },
  detailVisible: boolean,
}> = ({
  detailContent,
  detailVisible,
}) => {
    // console.log("중간감사 스텝5의 2단계 : ", detailContent)

    const [visible, setVisible] = useState<boolean>(detailVisible);

    if (!detailContent?.checked) {
      return <></>
    }

    return (
      <div>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div className="sp-ma-step5-detail-body-title element" style={{ paddingLeft: "10%", backgroundColor: "#fff", width: "70%" }}
            onClick={() => setVisible(!visible)}
          >
            {detailContent?.transaction_type_list?.length > 0 ?
              visible ? <ArrowUpIcon classes='icon-up' /> :
                <ArrowDownIcon fill="#bcbccb" classes='icon-down' />
              : <RemoveIcon fill="#bcbccb" classes='icon-down' />}
            <div className="sp-ma-step5-detail-account">{detailContent.name}</div>
          </div>
          <div className="sp-ma-step5-detail-account element" style={{ width: "10%", backgroundColor: "#fff" }}>{detailContent.strategy_type}</div>
          <div className="sp-ma-step5-detail-account element" style={{ width: "20%", backgroundColor: "#fff" }}>{detailContent.it_app}</div>


        </div>
        {visible && detailContent.transaction_type_list?.map((elem: any, idx: number) => {
          return (
            <DetailTaskElement3 key={idx.toString()} detailContent={elem} detailVisible={false} />
          )
        })
        }
      </div>
    )
  }

const DetailTaskElement: React.FC<{
  detailContent: { name: string, transaction_type_list: any },
  detailVisible: boolean,
}> = ({
  detailContent,
  detailVisible,
}) => {
    // console.log("중간감사 스텝5의 1단계 : ", detailContent)
    const [visible, setVisible] = useState<boolean>(detailVisible);

    return (
      <div style={{ borderBottomWidth: 1, borderBottomColor: "#f1f1f3", borderBottomStyle: "solid" }}>
        <div className="sp-ma-step5-detail-body-title element"
          style={{ paddingLeft: 10, backgroundColor: "#fff", }}
          onClick={() => setVisible(!visible)}
        >
          {detailContent?.transaction_type_list?.length > 0 ?
            visible ? <ArrowUpIcon classes='icon-up' /> :
              <ArrowDownIcon fill="#bcbccb" classes='icon-down' />
            : <RemoveIcon fill="#bcbccb" classes='icon-down' />}
          <div className="sp-ma-step5-detail-account">{detailContent.name}</div>
        </div>
        {visible && detailContent.transaction_type_list?.map((elem: any, i: number) => {
          return (
            <DetailTaskElement2 key={i.toString()} detailContent={elem} detailVisible={false} />
          )
        })}
      </div >
    )
  }

const Step5: React.FC<{
  detailTaskName: string,
  editMode: boolean,
  detailSubList?: any,
  requirements: string,
  handleChange: (html: string) => void;
  handleCancelRequirement: () => void;
  handleConfirmRequirement: () => void;
  handleConfirmDetail?: (newDetailData: any) => void;
}> = ({
  detailTaskName,
  editMode,
  requirements,
  detailSubList,
  handleChange,
  handleCancelRequirement,
  handleConfirmRequirement,
  handleConfirmDetail = () => { }
}) => {

    const renderDetailBody = () => {
      return detailSubList?.map((elem: any, idx: number) => {
        return <DetailTaskElement key={'detailTaskElement' + idx}
          detailContent={elem}
          detailVisible={false}
        />
      })
    }

    return (
      <div className="sp-ma-step5-detail-body">
        <div className="sp-ma-step5-detail-body-requirements">
          <div className="sp-ma-step5-detail-body-title element" style={{ backgroundColor: "#F1F2F3" }}>
            <div className="header" style={{ paddingLeft: 10 }}>
              <div className="account">주요 계정 과목</div>
              <div className="tran">주요 거래 유형</div>
              <div style={{ width: "50%" }}></div>
              <div className="strategy">유형별 전략</div>
              <div className="it-app">IT App</div>
              <div style={{ width: "10%" }}></div>
            </div>
          </div>
          <div className="sp-ma-step5-detail-body-title element" >
            <div className="header" style={{ paddingLeft: 10 }}>
              <div style={{ width: "20%" }}></div>
              <div className="wcgw">중요왜곡표시위험</div>
              <div className="control">통제</div>
              <div style={{ width: "20%" }}></div>
              <div className="frequency">빈도</div>
              <div className="control-type">통제유형</div>
              <div className="it-app2">IT App</div>
              <div className="key">KEY</div>
            </div>
          </div>
          <div className="sp-ma-step5-detail-body-content">
            {renderDetailBody()}
          </div>
        </div>

      </div>)
  }

export default Step5;