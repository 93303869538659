import React from 'react';
import classNames from 'classnames';

import { CommonProps } from 'src/components/helpers/props';

import './AbstractIcon.scss';

interface DotIconProps extends CommonProps {
  color?: string;
  size?: 'md';
}

const DotIcon: React.FC<DotIconProps> = ({ color = '#EF5350', classes, size = 'md' }) => {
  return (
    <svg
      className={classNames('icon-root', classes)}
      width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="3" cy="3" r="3" fill={color} />
    </svg>
  );
};

export default DotIcon;
