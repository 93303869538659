import moment from 'moment';

import config from 'src/shared/config';
import axios from 'src/shared/utils/axios';

import DummyApi from '../dummyApi';
import { times } from '../api';

import { Registration, UseYN } from '.';

const INFRA_API_ENABLED = config.servers.infra.enabled;
const INFRA_API_URL = config.servers.infra.url;

export interface PrinterDto {
  code: string;
  driver: string;
  ip: string;
  description: string;
  registration: Registration;
  useYN: UseYN;
  entryTime: number;
}

export type PrinterReqDto = Omit<PrinterDto, 'code' | 'entryTime'>;

interface PrinterApi {
  list(): Promise<PrinterDto[]>;
  find(code: string): Promise<PrinterDto>;
  create(reqDto: PrinterReqDto): Promise<PrinterDto>;
  update(code: string, reqDto: PrinterReqDto): Promise<PrinterDto>;
  remove(code: string): Promise<void>;
}

class PrinterServerApi implements PrinterApi {
  async list(): Promise<PrinterDto[]> {
    const resp = await axios.get<PrinterDto[]>(`${INFRA_API_URL}/api/printers`);
    return resp.data;
  }
  async find(code: string): Promise<PrinterDto> {
    const resp = await axios.get<PrinterDto>(`${INFRA_API_URL}/api/printers/${code}`);
    return resp.data;
  }
  async create(reqDto: PrinterReqDto): Promise<PrinterDto> {
    const resp = await axios.post<PrinterDto>(`${INFRA_API_URL}/api/printers`, reqDto);
    return resp.data;
  }
  async update(code: string, reqDto: PrinterReqDto): Promise<PrinterDto> {
    const resp = await axios.put<PrinterDto>(`${INFRA_API_URL}/api/printers/${code}`, reqDto);
    return resp.data;
  }
  async remove(code: string): Promise<void> {
    await axios.delete<PrinterDto>(`${INFRA_API_URL}/api/printers/${code}`);
  }
}

class PrinterDummyApi implements PrinterApi {
  dummyApi: DummyApi<PrinterDto, PrinterReqDto>;
  constructor() {
    const data: PrinterDto[] = [
      {
        driver: 'FX ApeosPort-V C2275',
        ip: '192.168.20.1',
        description: 'FX ApeosPort-V C2275 설명',
        registration: Registration.A,
        useYN: UseYN.Y,
      },
      {
        driver: 'FX ApeosPort-VI C4471 PCL 6',
        ip: '192.168.20.2',
        description: 'FX ApeosPort-VI C4471 PCL 6 설명',
        registration: Registration.A,
        useYN: UseYN.Y,
      },
      {
        driver: 'OA7_ApeosPort-V C5576 PCL 6',
        ip: '192.168.20.3',
        description: 'OA7_ApeosPort-V C5576 PCL 6 설명',
        registration: Registration.A,
        useYN: UseYN.Y,
      },
      {
        driver: 'OA8_ApeosPort-V 4076 PCL 6',
        ip: '192.168.20.4',
        description: 'OA8_ApeosPort-V 4076 PCL 6 설명',
        registration: Registration.A,
        useYN: UseYN.Y,
      },
      {
        driver: 'FX ApeosPort-VI C4471 PCL 6',
        ip: '192.168.20.5',
        description: 'FX ApeosPort-VI C4471 PCL 6 설명 2',
        registration: Registration.A,
        useYN: UseYN.Y,
      },
      {
        driver: 'OA8_ApeosPort-V 4076 PCL 6',
        ip: '192.168.20.6',
        description: 'OA8_ApeosPort-V 4076 PCL 6 설명 2',
        registration: Registration.A,
        useYN: UseYN.Y,
      },
      {
        driver: 'Adobe PDF converter',
        ip: '192.168.20.1',
        description: 'OA8_ApeosPort-V 4076 PCL 6 설명',
        registration: Registration.M,
        useYN: UseYN.Y,
      },
      {
        driver: 'Send to Microsoft OneNote 15 Driver',
        ip: '192.168.20.1',
        description: 'OA8_ApeosPort-V 4076 PCL 6 설명',
        registration: Registration.M,
        useYN: UseYN.Y,
      },
    ].map((o, i) => ({ ...o, code: '', entryTime: times(i) }));

    this.dummyApi = DummyApi.of({
      entityName: 'Infra.Printer',
      createDto: (code, entity) => ({ ...entity, code, entryTime: Date.now() }),
      validation: (type, code, req, data) => {
        data.forEach((dto) => {
          if (
            (type === 'create' && dto.driver === req.driver && dto.ip === req.ip) ||
            (type === 'update' &&
              dto.code !== code &&
              dto.driver === req.driver &&
              dto.ip === req.ip)
          ) {
            throw new Error('이미 사용 중인 이름입니다.');
          }
        });
      },
      data,
    });
  }

  list(): Promise<PrinterDto[]> {
    return this.dummyApi.list();
  }
  find(code: string): Promise<PrinterDto> {
    return this.dummyApi.find(code);
  }
  create(reqDto: PrinterReqDto): Promise<PrinterDto> {
    return this.dummyApi.create(reqDto);
  }
  update(code: string, reqDto: PrinterReqDto): Promise<PrinterDto> {
    return this.dummyApi.update({ code, reqDto });
  }
  remove(code: string): Promise<void> {
    return this.dummyApi.remove(code);
  }
}

const printerApi: PrinterApi = INFRA_API_ENABLED ? new PrinterServerApi() : new PrinterDummyApi();

export default printerApi;
