import { useAlert } from 'src/contexts/AlertContext';
import React, { useEffect, useState } from 'react';
import projectApi2 from 'src/api/projectApi2';

export default function useMyProjectResource(
  projectId: string
): {
  loading: boolean;
  projectResourceIdSet: Set<string>;
} {
  const [loading, setLoading] = useState(false);
  const [resourceIds, setResourceIds] = useState<string[]>(['audit']);
  const projectResourceIdSet = new Set(resourceIds);
  const { alert } = useAlert();

  // const { authEntity } = useAuthStore();
  // roles: deid, join, eval, project, dataset, util, admin, upload, download, apply, eval_master, keygen
  // const roleIdSet = new Set(authEntity?.userInfo.userRole || []);

  // useEffect(() => {
  //   projectApi2
  //     .getMyResourceIds(projectId)
  //     .then((resourceIds) => {
  //       setLoading(false);
  //       setResourceIds(resourceIds);
  //     })
  //     .catch((err) => alert(err));
  // }, [alert, projectId]);

  return {
    loading,
    projectResourceIdSet,
  };
}
