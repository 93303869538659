export function validateAll(rules: { [key: string]: boolean | any }): boolean {
  return Object.values(rules).every((rule) => {
    // console.log('rule:', rule)
    return typeof rule === 'boolean' ? rule : true;
  });
}

export function numberWithCommas(x: number | undefined): string {
  return x ? x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '0';
}

export function validateAll2(rules: { [key: string]: boolean | any }): { retVal: boolean, key: string } {
  let retObj = { retVal: true, key: "" };
  Object.entries(rules).some(([key, value]) => {
    if (!value) {
      retObj = { retVal: value, key: key }
    }
  });
  return retObj;
}
