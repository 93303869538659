import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import Button from 'src/components/atom/Button';
import TextField from 'src/components/atom/TextField';
import FormGroup from 'src/components/atom/FormGroup';
import RadioGroup from 'src/components/atom/RadioGroup';
import Radio from 'src/components/atom/Radio';
import _, { create } from 'lodash';
import { CommonProps } from 'src/components/helpers/props';
import Popup2 from 'src/components/molecules/Popup2';

import userApi, { UserInfoDto, createUser } from 'src/api/userApi';
import useInputs from 'src/hooks/useInputs';
import { validateAll2 } from 'src/shared/utils/validations';
import { useAlert } from 'src/contexts/AlertContext';
import { useToast } from 'src/contexts/ToastContext';
import './AddUserPopup.scss';


interface AddUserPopupProps extends CommonProps {
  open: boolean;
  onClose: () => void;
  // selected: UserInfoEntity[];
}

const AddUserPopup: React.FC<AddUserPopupProps> = ({
  open,
  onClose,
  classes,
}) => {
  const { t } = useTranslation(['commons']);
  const [form, onChange, onChangeElem, reset] = useInputs<UserInfoDto>(createUser());
  const toast = useToast();
  const { alert, alert2, confirm2 } = useAlert();


  const validationRules = {
    // id: _.trim(project.id).length > 0 && validationId(project.id),
    account: _.trim(form.account).length > 0,
    name: _.trim(form.nickname).length > 0,
    employee_number: _.trim(form.employee_number).length > 0,
    // projectRole: selectedUsers.length > 0,
  };

  const handleOk = (): void => {
    console.log('userAddPopup', form)
    const r = validateAll2(validationRules)
    if (!r.retVal) {
      if (r.key === "account") {
        alert2("사용자 추가", "아이디는 필수입니다.");
      } else if (r.key === "name") {
        alert2("사용자 추가", "이름은 필수입니다.");
      } else if (r.key === "employee_number") {
        alert2("사용자 추가", "사번은 필수입니다.");
      } else {
        alert2("사용자 추가", "필수값을 확인해 주세요.");
      }
      return;
    }

    userApi
      .addUser(form)
      .then((resp) => {
        console.log("resp : ", resp);
        reset();
        toast.info('사용자가 추가되었습니다.');
        //confirm2('사용자 추가', "사용자 추가가 완료되었습니다.", reset);
        //onClose()
      })
      .catch((err) => alert2('사용자추가', err))
  };

  const handleCancel = (): void => {
    onClose();
  };

  const onChangeRole = (value: string): void => {
    onChangeElem("auth", value || "")
    if (value === "관리자") {
      onChangeElem("role", { role_list: ["Engagement Partner"], authority_name: "Admin" })
    } else if (value === "파트너") {
      onChangeElem("role", { role_list: ["Engagement Partner"], authority_name: "Staff" })
    } else { //일반
      onChangeElem("role", { role_list: ["Staff"], authority_name: "Staff" })
    }
  }

  return (
    <Popup2
      classes="adduser-popup-root"
      title="사용자 추가"
      width="1112px"
      height="692px"
      open={open}
      onClose={handleCancel}
      footer={
        <React.Fragment>
          <Button text={t('commons:cancel')} onClick={handleCancel} color="secondary" />
          <Button
            text={t('commons:ok')}
            onClick={handleOk}
          // disabled={!selectedUsers} 
          />
        </React.Fragment>
      }
    >
      <FormGroup spacing={6}>
        <span className='input-label'>아이디</span>
        <TextField name='account'
          placeholder="아이디를 입력하세요"
          value={form.account} fullWidth={true} onChange={(e) => { onChange(e); onChangeElem("password", e.target.value) }} />
      </FormGroup>
      <FormGroup spacing={6}>
        <span className='input-label'>이름</span>
        <TextField name='nickname'
          placeholder="이름을 입력하세요"
          value={form.nickname} fullWidth={true} onChange={onChange} />
      </FormGroup>
      <FormGroup spacing={6}>
        <span className='input-label'>사번</span>
        <TextField name='employee_number'
          placeholder="사번을 입력하세요"
          value={form.employee_number} fullWidth={true} onChange={onChange} />
      </FormGroup>
      <FormGroup spacing={6}>
        <span className='input-label'>이메일</span>
        <TextField name='email'
          placeholder="이메일주소를 입력하세요"
          value={form.email} fullWidth={true} onChange={onChange} />
      </FormGroup>
      <FormGroup spacing={6}>
        <span className='input-label'>전화번호</span>
        <TextField name='phone'
          placeholder="전화번호를 입력하세요"
          value={form.phone} fullWidth={true} onChange={onChange} />
      </FormGroup>
      <FormGroup spacing={6}>
        <span className='input-label'>업무성격</span>
        <RadioGroup
          name="auth" value={form.auth}
          onChange={(value) => onChangeRole(value)}
        >
          <Radio label="관리자" value={'관리자'} />
          <Radio label="일반" value={'일반'} />
        </RadioGroup>
      </FormGroup>
    </Popup2>
  );
};

export default AddUserPopup;
