import React from 'react';
import classNames from 'classnames';

import { CommonProps } from 'src/components/helpers/props';

import './AbstractIcon.scss';

interface DeleteIconProps extends CommonProps {
  size?: 'md' | 'sm';
}

const DeleteIcon: React.FC<DeleteIconProps> = ({ classes, size = 'md' }) => {


  if (size === 'sm') {
    return (
      <svg
        className={classNames('icon-root', classes)}
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M2 4H3.33333H14" stroke="currentColor" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M5.33301 4.00001V2.66668C5.33301 2.31306 5.47348 1.97392 5.72353 1.72387C5.97358 1.47382 6.31272 1.33334 6.66634 1.33334H9.33301C9.68663 1.33334 10.0258 1.47382 10.2758 1.72387C10.5259 1.97392 10.6663 2.31306 10.6663 2.66668V4.00001M12.6663 4.00001V13.3333C12.6663 13.687 12.5259 14.0261 12.2758 14.2762C12.0258 14.5262 11.6866 14.6667 11.333 14.6667H4.66634C4.31272 14.6667 3.97358 14.5262 3.72353 14.2762C3.47348 14.0261 3.33301 13.687 3.33301 13.3333V4.00001H12.6663Z" stroke="currentColor" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M9.33301 7.33334V11.3333" stroke="currentColor" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M6.66699 7.33334V11.3333" stroke="currentColor" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
    )
  }
  else {

    return (
      <svg
        className={classNames('icon-root', classes)}
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M2.5 5H4.16667H17.5" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M6.6665 5.00002V3.33335C6.6665 2.89133 6.8421 2.4674 7.15466 2.15484C7.46722 1.84228 7.89114 1.66669 8.33317 1.66669H11.6665C12.1085 1.66669 12.5325 1.84228 12.845 2.15484C13.1576 2.4674 13.3332 2.89133 13.3332 3.33335V5.00002M15.8332 5.00002V16.6667C15.8332 17.1087 15.6576 17.5326 15.345 17.8452C15.0325 18.1578 14.6085 18.3334 14.1665 18.3334H5.83317C5.39114 18.3334 4.96722 18.1578 4.65466 17.8452C4.3421 17.5326 4.1665 17.1087 4.1665 16.6667V5.00002H15.8332Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M11.6665 9.16669V14.1667" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M8.3335 9.16669V14.1667" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
    );
  }

};

export default DeleteIcon;
