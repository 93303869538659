// token get
export const getTokenFromSessionStorage = (): string | null => {
  return window.sessionStorage.getItem('token');
};

// 로그인시 token 저장
export const setTokenFromSessionStorage = (token: string): void => {
  return window.sessionStorage.setItem('token', token);
};

// 로그아웃시 token 지우기
export const removeTokenFromSessionStorage = (): void => {
  return window.sessionStorage.removeItem('token');
};

// 권한 가져오기
export const getPermissionsFromSessionStorage = (): string[] => {
  const menus = window.sessionStorage.getItem('menus');
  return menus ? JSON.parse(menus) : [];
};

// 권한 설정
export const setPermissionsFromSessionStorage = (menus: string[]): void => {
  return window.sessionStorage.setItem('menus', JSON.stringify(menus));
};

// 권한 지우기
export const removePermissionsFromSessionStorage = (): void => {
  return window.sessionStorage.removeItem('menus');
};

// 전사관리자 여부 가져오기
export const isSuperAdminFromSessionStorage = (): string | null => {
  return window.sessionStorage.getItem('superAdmin');
};

// 전사관리자 여부 설정
export const setSuperAdminFromSessionStorage = (superAdmin: boolean): void => {
  return window.sessionStorage.setItem('superAdmin', superAdmin === true ? 'true' : 'false');
};

// 전사관리자 여부 지우기
export const removeSuperAdminFromSessionStorage = (): void => {
  return window.sessionStorage.removeItem('superAdmin');
};

// 로그인시 id저장
export const setLoginIdFromSessionStorage = (loginId: string): void => {
  return window.sessionStorage.setItem('loginId', loginId);
};

export const getLoginIdFromSessionStorage = (): string | null => {
  return window.sessionStorage.getItem('loginId');
};

export const removeLoginIdFromSessionStorage = (): void => {
  return window.sessionStorage.removeItem('loginId');
};

//로그인시 계정의 uuid가 localstorage 에 있는지 확인
export const getUserUUIDLocalStorage = (loginId: string): string => {
  const res = window.localStorage.getItem('uuid_'+loginId);
  return res? res: '';
}
//로그인시 uuid localstorage 저장
export const setUserUUIDLocalStorage = (loginId: string, uuid: string): void => {
  return window.localStorage.setItem('uuid_'+loginId, uuid);
}