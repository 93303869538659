import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import basicTemplateApi, {
  BasicTemplateDto,
  BasicTemplateReqDto,
  PrintType,
  LogType,
  ColorType,
} from 'src/api/basicTemplateApi';
import { StoreStatus } from 'src/shared/types/StoreStatus';
import { toLocalDateTimeStr } from 'src/shared/utils/localDateTime';
import { RootState } from 'src/store';

// # types
export interface BasicTemplateEntity extends BasicTemplateDto {
  entryTsStr: string;
}
export type BasicTemplateReqEntity = Omit<BasicTemplateEntity, 'code' | 'entryTs' | 'entryTsStr'>;

function toEntity(dto: BasicTemplateDto): BasicTemplateEntity {
  return { ...dto, entryTsStr: toLocalDateTimeStr(dto.entryTs) };
}

function toReqDto(entity: BasicTemplateReqEntity): BasicTemplateReqDto {
  return { ...entity };
}

interface BasicTemplateEntityByCode {
  [code: string]: BasicTemplateEntity;
}
export interface BasicTemplateEntities {
  byCode: BasicTemplateEntityByCode;
  allCodes: string[];
}
interface BasicTemplateState {
  status: StoreStatus;
  entities: BasicTemplateEntities;
}

export interface BasicTemplateUpdateReq {
  code: string;
  reqEntity: BasicTemplateReqEntity;
}
export interface BasicTemplateUpdateUseReq {
  code: string;
  reqEntity: BasicTemplateReqEntity;
  use: boolean;
}

// # initial state
const initialState: BasicTemplateState = {
  status: StoreStatus.IDLE,
  entities: { byCode: {}, allCodes: [] },
};

export const defaultBasicTemplateReqEntity: BasicTemplateReqEntity = {
  name: '',
  description: '',
  use: true,

  docPrint: PrintType.WATPRINT,
  secDocPrint: PrintType.WATPRINT,
  approval: false, //인쇄 시 결재
  approvalWatExcept: false, //워터마크 예외 신청
  selectWat: false, //워터마크 종류 사용자 선택
  watPrintLogSend: true, //워터마크 인쇄 로그 저장
  printLogSend: false, //일반 인쇄 로그 저장
  txtLogType: LogType.NONE, //텍스트 로그 타입
  txtLogValue: 0, //텍스트 로그 값 (매 n 페이지마다 또는 첫 페이지부터 n 페이지까지)
  imgLogType: LogType.NONE, //이미지 로그 타입
  imgLogValue: 0, //이미지 로그 값 (매 n 페이지마다 또는 첫 페이지부터 n 페이지까지)
  pullPrinting: false, //pullprinting 사용
  tco: false, //인쇄 절감 사용
  txtSaving: 100, //텍스트 인쇄 농도
  imgSaving: 100, //이미지 인쇄 농도
  graphicSaving: 100, //그래프 인쇄 농도
  colorType: ColorType.COLOR, //흑백으로만 인쇄
  printLimit: false, //인쇄 매수 제한
  printLimitCount: 1, //인쇄 최대 허용 매수
  printLimitAlert: false, //최대 허용 매수 초과 시 사용자 알림
};

// # thunks
export const thunkLoadBasicTemplates = createAsyncThunk('basicTemplate/load', async () => {
  const list = await basicTemplateApi.list();
  return list.map((dto) => toEntity(dto));
});
export const thunkCreateBasicTemplate = createAsyncThunk(
  'basicTemplate/create',
  async (reqEntity: BasicTemplateReqEntity) => {
    return toEntity(await basicTemplateApi.create(toReqDto(reqEntity)));
  }
);
export const thunkUpdateBasicTemplate = createAsyncThunk(
  'basicTemplate/update',
  async ({ code, reqEntity }: BasicTemplateUpdateReq) => {
    return toEntity(await basicTemplateApi.update(code, toReqDto(reqEntity)));
  }
);
export const thunkUpdateBasicTemplateUse = createAsyncThunk(
  'basicTemplate/update/use',
  async ({ code, reqEntity, use }: BasicTemplateUpdateUseReq) => {
    const newEntity = { ...reqEntity, use };
    return toEntity(await basicTemplateApi.update(code, toReqDto(newEntity)));
  }
);
export const thunkRemoveBasicTemplate = createAsyncThunk(
  'basicTemplate/remove',
  async (code: string) => {
    return basicTemplateApi.remove(code);
  }
);

// # slice
const basicTemplateSlice = createSlice({
  name: 'basicTemplate',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(thunkLoadBasicTemplates.pending, (state) => {
      state.status = StoreStatus.LOADING;
    });
    builder.addCase(thunkLoadBasicTemplates.fulfilled, (state, { payload: basicTemplates }) => {
      state.entities.byCode = basicTemplates.reduce((acc, t) => {
        acc[t.code] = t;
        return acc;
      }, {} as BasicTemplateEntityByCode);
      state.entities.allCodes = basicTemplates.map((t) => t.code);
      state.status = StoreStatus.DONE;
    });
    builder.addCase(thunkLoadBasicTemplates.rejected, (state) => {
      state.status = StoreStatus.FAILED;
    });
    builder.addCase(thunkCreateBasicTemplate.fulfilled, (state, { payload: createdEntity }) => {
      state.entities.byCode[createdEntity.code] = createdEntity;
      state.entities.allCodes.push(createdEntity.code);
    });
    builder.addCase(thunkUpdateBasicTemplate.fulfilled, (state, { payload: updatedEntity }) => {
      state.entities.byCode[updatedEntity.code] = updatedEntity;
    });
    builder.addCase(thunkUpdateBasicTemplateUse.fulfilled, (state, { payload: updatedEntity }) => {
      state.entities.byCode[updatedEntity.code] = updatedEntity;
    });
    builder.addCase(thunkRemoveBasicTemplate.fulfilled, (state, { meta: { arg: removedCode } }) => {
      delete state.entities.byCode[removedCode];
      state.entities.allCodes = state.entities.allCodes.filter((code) => code !== removedCode);
    });
  },
});

// # selectors
export const selectStoreStatus = (state: RootState): StoreStatus => {
  return state.basicTemplate.status;
};
export const selectInitialized = (state: RootState): boolean => {
  return state.basicTemplate.status === StoreStatus.DONE;
};
export const selectBasicTemplateEntities = (state: RootState): BasicTemplateEntities =>
  state.basicTemplate.entities;
export const selectBasicTemplateEntityList = (state: RootState): BasicTemplateEntity[] =>
  state.basicTemplate.entities.allCodes.map((code) => state.basicTemplate.entities.byCode[code]);
export const selectUseBasicTemplateEntityList = (state: RootState): BasicTemplateEntity[] =>
  state.basicTemplate.entities.allCodes
    .filter((code) => state.basicTemplate.entities.byCode[code].use === true)
    .map((code) => state.basicTemplate.entities.byCode[code]);

export default basicTemplateSlice.reducer;

// 참고중...
// https://orizens.com/blog/how-to-not-have-a-mess-with-react-hooks-and-redux/
