import React from 'react';

import Dashboard from 'src/pages/dashboard/Dashboard';
import RouteIcons from 'src/components/atom/icons/RouteIcons';
import Project from 'src/pages/project/Project';
import ProjectDetail from 'src/pages/project/ProjectDetail';
import ProjectDashboard from 'src/pages/project/ProjectDashboard';
import AuditList from 'src/pages/project/AuditList';
import PolicyReviewList from 'src/pages/policy/pol-review';
import Login from 'src/pages/auth/Login';
import LoginWithToken from 'src/pages/auth/LoginWithToken';
import LogOut from 'src/pages/auth/Logout';
import SingleErrorPage from 'src/pages/error/SingleErrorPage';
import RootPage from 'src/pages/RootPage';
import JobList from 'src/pages/job/JobList';
import AccountShow from 'src/pages/myPage/UserShow';
import AccountForm from 'src/pages/myPage/UserForm';
import SystemLog from 'src/pages/systemLog';
import CreateProject from 'src/pages/project/CreateProject';
import PatternList from 'src/pages/pattern/PatternList';
import SettingShow from 'src/pages/setting/SettingShow';
import SettingForm from 'src/pages/setting/SettingForm';
import UserSettingList from 'src/pages/user/UserList';
import UserSettingShow from 'src/pages/user/UserShow';
import UserSettingForm from 'src/pages/user/UserForm';
import RequestList from 'src/pages/request/RequestList';
import Category from 'src/pages/project/Category';
import Redirect from 'src/pages/redirect';

import config from './config';


interface RT {
  path: string;
  title: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component?: React.FC<any>;
  redirect?: string;
  auth?: boolean;
  adminScopes?: string[];
}

export interface RouteTable extends RT {
  subs: RouteTable[];
  parents: RouteTable[];
  fullPath: string;
  menu?: boolean;
  exact?: boolean;
}
export interface MainRouteTable extends RouteTable {
  icon: React.FC<{}>;
  onlyName?: boolean;
}

function prependContextPath<T extends RouteTable>(routeTable: T): T {
  return {
    ...routeTable,
    path: config.url(routeTable.path),
    fullPath: config.url(routeTable.fullPath),
    redirect: routeTable.redirect ? config.url(routeTable.redirect) : routeTable.redirect,
  };
}

export function createMainMenu({
  path,
  fullPath,
  title,
  component,
  redirect,
  auth,
  adminScopes,
  icon,
  subs = [],
  onlyName,
}: RT & {
  fullPath?: string;
  subs?: RouteTable[];
  icon: React.FC<{}>;
  onlyName?: boolean;
}): MainRouteTable {
  return {
    ...createMenu({
      path: path,
      fullPath: fullPath,
      title,
      component,
      redirect,
      auth,
      adminScopes,
      subs,
    }),
    icon,
    onlyName,
  };
}
export function createMenu({
  path,
  fullPath,
  title,
  component,
  redirect,
  auth,
  adminScopes,
  subs = [],
}: RT & { fullPath?: string; subs?: RouteTable[] }): RouteTable {
  return {
    ...createRoute({ path, fullPath, title, component, redirect, auth, adminScopes, subs }),
    menu: true,
  };
}
export function createRoute({
  path,
  fullPath,
  title,
  component,
  redirect,
  auth,
  adminScopes,
  subs = [],
}: RT & { fullPath?: string; subs?: RouteTable[] }): RouteTable {
  return {
    path,
    title,
    component,
    redirect: redirect,
    auth: auth !== undefined ? auth : true,
    adminScopes: adminScopes !== undefined ? adminScopes : [],
    fullPath: fullPath ? fullPath : '',
    parents: [],
    subs,
  };
}

function createSinglePage({
  path,
  fullPath,
  title,
  component,
  auth,
  adminScopes,
  exact = true,
}: RT & { fullPath: string, exact?: boolean }): RouteTable {
  return {
    path,
    title,
    component,
    auth,
    adminScopes,
    subs: [],
    parents: [],
    fullPath,
    exact,
  };
}

const rootRouteTable: MainRouteTable = createMainMenu({
  path: '/',
  fullPath: config.url('/'),
  title: 'Stellar',
  icon: RouteIcons.Home,
  // redirect: '/dashboard',
  component: RootPage,
});

const routeTables: MainRouteTable[] = [
  // TODO 도공 23 주석
  createMainMenu({
    path: '/projects',
    title: 'menu:프로젝트',
    icon: RouteIcons.Project,
    adminScopes: ['fspui-home'],
    component: Project,
    // component: Pre,
    subs: [


      // createRoute({
      //   path: '/projects/:projectId/:category/:taskName/:detailName',
      //   title: 'category',
      //   component: Category,
      // }),
      createRoute({
        path: '/add',
        title: '프로젝트 추가',
        component: CreateProject,
      }),
      createRoute({
        path: '/:projectId/update',
        title: '프로젝트 수정',
        component: CreateProject,
      }),
      createRoute({
        path: '/:projectId/dashboard',
        title: 'DASHBORD',
        component: ProjectDashboard,
      }),
      createRoute({
        path: '/:projectId/detail',
        title: '프로젝트 상세',
        component: ProjectDetail,
      }),

      createRoute({
        path: '/:projectId/category/:category',
        title: 'category',
        component: Category,
        subs: [
          createRoute({
            path: '/:taskName/:detailName',
            title: 'category',
            component: Category,
          }),
          createRoute({
            path: '/:taskName/:detailName/:transType/:detailTransType',
            title: 'category',
            component: Category,
          }),
        ]
      }),
    ],
  }),

  // createMainMenu({
  //   path: '/projects/:projectId/:category',
  //   title: 'category',
  //   icon: RouteIcons.Dashboard,
  //   adminScopes: ['fspui-home'],
  //   component: Category,
  //   subs: [

  //   ],
  // }),

  // createMainMenu({
  //   path: '/projects/:projectId/:category/:taskName/:detailName',
  //   title: 'category',
  //   icon: RouteIcons.Dashboard,
  //   adminScopes: ['fspui-home'],
  //   component: Category,
  //   subs: [
  //   ],
  // }),

  createMainMenu({
    path: '/requests',
    title: '요청',
    icon: RouteIcons.UserSetting,
    adminScopes: ['fspui-home'],
    component: RequestList,
    subs: [
      // createRoute({
      //   path: '/add',
      //   title: '사용자 등록',
      //   component: UserSettingForm,
      // }),
      // createRoute({
      //   path: '/:id/show',
      //   title: 'menu:poltpl.basic.show',
      //   component: UserSettingShow,
      // }),
      // createRoute({
      //   path: '/:id/edit',
      //   title: '사용자 수정',
      //   component: UserSettingForm,
      // }),
    ],
  }),

  createMainMenu({
    path: '/admin',
    title: '사용자 설정',
    icon: RouteIcons.UserSetting,
    adminScopes: ['fspui-home'],
    component: UserSettingList,
    subs: [
      createRoute({
        path: '/add',
        title: '사용자 등록',
        component: UserSettingForm,
      }),
      createRoute({
        path: '/:id/show',
        title: 'menu:poltpl.basic.show',
        component: UserSettingShow,
      }),
      createRoute({
        path: '/:id/edit',
        title: '사용자 수정',
        component: UserSettingForm,
      }),
    ],
  }),

  createMainMenu({
    path: '/account',
    title: 'My Page',
    icon: RouteIcons.MyPage,
    adminScopes: ['fspui-home'],
    component: AccountShow,
    subs: [
      createRoute({
        path: '/edit',
        title: '사용자 정보 수정',
        component: AccountForm,
      }),
    ],
  }),


];

function spreadAndFillTheRest(routeTable: RouteTable, parentTable: RouteTable): RouteTable[] {
  const parentFullPath = parentTable.fullPath;
  const subPath = routeTable.path;
  routeTable.fullPath =
    parentFullPath.endsWith('/') && subPath.startsWith('/')
      ? parentFullPath.substring(0, parentFullPath.length - 1) + subPath
      : parentFullPath + subPath;
  if (
    parentTable.adminScopes &&
    parentTable.adminScopes.length > 0 &&
    (!routeTable.adminScopes || routeTable.adminScopes.length === 0)
  ) {
    routeTable.adminScopes = [...parentTable.adminScopes];
  }
  routeTable.parents = [...parentTable.parents, parentTable];
  if (!routeTable.component && !routeTable.redirect && routeTable.subs.length > 0) {
    routeTable.redirect = routeTable.fullPath + routeTable.subs[0].path;
  }
  const spreadSubRouteTables = routeTable.subs.flatMap((sub) =>
    spreadAndFillTheRest(sub, routeTable)
  );
  return [...spreadSubRouteTables, routeTable];
}

const spreadRouteTables = routeTables.flatMap((rt) => spreadAndFillTheRest(rt, rootRouteTable));
const mainPageRouteTables = [...spreadRouteTables, rootRouteTable];

const singleRouteTable: RouteTable[] = [
  createSinglePage({
    path: '/sign-in',
    fullPath: config.url('/sign-in'),
    title: 'Sign in',
    component: Login,
    auth: false,
  }),
  createSinglePage({
    path: '/sign-in-with-token',
    fullPath: config.url('/sign-in-with-token'),
    title: 'Sign in with token',
    component: LoginWithToken,
    auth: false,
  }),
  createSinglePage({
    path: '/sign-out',
    fullPath: config.url('/sign-out'),
    title: 'Sign out',
    component: LogOut,
    auth: false,
  }),
  createSinglePage({
    path: '/errors/not-found',
    fullPath: config.url('/errors/not-found'),
    title: 'Not Found',
    component: SingleErrorPage.NotFound,
    auth: false,
  }),
  createSinglePage({
    path: '/errors/forbidden',
    fullPath: config.url('/errors/forbidden'),
    title: 'Forbidden',
    component: SingleErrorPage.Forbidden,
    auth: false,
  }),
  createSinglePage({
    path: '/redirect',
    fullPath: config.url('/redirect/:result/auth/:user'),
    title: 'redirect',
    component: Redirect,
    auth: false,
    exact: false,
  }),
  createSinglePage({
    path: '/redirect',
    fullPath: config.url('/redirect/:result'),
    title: 'redirect',
    component: Redirect,
    auth: false,
    exact: false,
  }),


];

// const outRouteTables = routeTables.map((rt) => prependContextPath(rt));
// const outRootRouteTable = prependContextPath(rootRouteTable);
// const outMainPageRouteTables = mainPageRouteTables.map((rt) => prependContextPath(rt));
// const outSingleRouteTable = singleRouteTable.map((rt) => prependContextPath(rt));

// export {
//   outRouteTables as routeTables,
//   outRootRouteTable as rootRouteTable,
//   outMainPageRouteTables as mainPageRouteTables,
//   outSingleRouteTable as singleRouteTable,
// };
export { routeTables, rootRouteTable, mainPageRouteTables, singleRouteTable };
