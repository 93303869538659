import config from 'src/shared/config';
import axios from 'src/shared/utils/axios';
import _ from 'lodash';
import moment from 'moment';

import { TokenErrorHandling } from 'src/shared/errorHandling';
import { request } from 'http';
import { MemberDto } from './userSettingApi';

import { getToken } from 'src/shared/utils/sessionStorageManager';

const M_API_URL = config.servers.manager.url;

export interface ProjectRole {
  userId: string;
  roleId?: string | string[];
  userName?: string;
  roleName?: string;
}


export interface MemberRole {
  role_list: string[];
}

export interface MemberInfo {
  nickname: string;
  phone: string;
  account: string;
  email: string;
  password: string;
  role: MemberRole;
}


export interface AuditProcessInfo {
  member: MemberInfo;
  // created_at: string;
  updated_at: string;
  status: string;
  comment?: string;
}


export interface AuditPhase {
  role: MemberRole;
  written_by_history?: AuditProcessInfo[];

}

export interface Audit {
  audit_id: string;
  title: string;
  excel_file_name: string;
  // uploaded_by: AuditProcessInfo;
  // audited_by: AuditProcessInfo;
  // approved_by: AuditProcessInfo;
  uri: {
    excel_file: string;
  };
  phase_list: AuditPhase[];
  phase_index: number;
  // comment?: string;
  // status?: string;
}


export interface AuditSendDto extends Audit {
  comment?: string;
  reviewed_by?: {
    member: {
      account: string;
    }
  };
  approved_by?: {
    member: {
      account: string;
    }
  }
}
export const emptyAuditProcessInfo = (): AuditProcessInfo => ({
  member: {} as MemberInfo,
  updated_at: '',
  status: ''
})

export const emptyAudit = (): Audit => ({
  audit_id: '',
  title: '',
  excel_file_name: '',
  // uploaded_by: { member: {} as MemberInfo} as AuditProcessInfo,
  // audited_by: { member: {} as MemberInfo} as AuditProcessInfo,
  // approved_by: { member: {} as MemberInfo} as AuditProcessInfo,
  uri: { excel_file: '' },
  phase_list: [],
  phase_index: 0,
  // comment: ''
})


export interface ProjectDto {
  project_id: string;
  status?: string;
  name: string;
  type?: string;
  service_type?: string;
  client: {
    client_id: string;
    name: string;
  };
  report_period: {
    started_at: string;
    finished_at: string
  };
  report_standard: string;
  is_public: boolean;
  is_regulated: boolean;
  access_modifier: string;
  regulatory_country: string;
  template: {
    name: string;
  },
  member_list: MemberDto[];
  updated_at?: string;
}


export function emptyProject(): ProjectDto {
  const today = moment().format('YYYY.MM.DD');
  return {
    project_id: "",
    name: "",
    type: "",
    service_type: "",
    client: {
      client_id: "",
      name: "",
    },
    report_period: {
      started_at: today,
      finished_at: today,
    },
    report_standard: "K-IFRS",
    is_public: true,
    is_regulated: true,
    access_modifier: "private",
    regulatory_country: "",
    template: {
      name: "",
    },
    member_list: []
  };
}

interface ResponseDto {
  data: {
    project_list: ProjectDto[];
  }
}
interface ResponseDto1 {
  data: {
    project: ProjectDto
  }
}

interface ResponseDto2 {
  data: {
    audit_list: Audit[];
  }
}

interface ResponseDtoA<D> {
  data: D;
  error?: string;
}

interface ExcelFile {
  uri: {
    excel_file: string
  }
}

class projectServerApi {
  project_status = ""

  async getProjectTaskProgress(project_id: string): Promise<any> {
    const data = {
      data: {
        access_token: getToken(),
        project: {
          project_id: project_id,
        }
      }
    }
    const resp = await axios.post<any>(`/api/private/GetProjectTaskProgress`, data);
    if (resp.data !== null) {
      const retValue = resp.data.data.is_all_task_done;
      return Promise.resolve(_.cloneDeep(retValue));
    }
    return Promise.resolve(false);
  }

  async checkFileInProject(project_id: string, fileName: string): Promise<any> {
    const data = {
      data: {
        access_token: getToken(),
        project: {
          project_id: project_id
        }
      }
    }
    const resp = await axios.post<any>(`/api/private/GetAuditListByProject`, data);

    if (resp.data !== null
      // && resp.data.status === 200
    ) {
      // console.log('resp.data', resp.data);
      const _data = resp.data.data?.audit_file_with_info_list || [];
      const rValue = _data.filter((item: any) => item.file_name === fileName).length > 0;
      const retValue = {
        has_audit: rValue
      }

      return Promise.resolve(_.cloneDeep(retValue));
    }

    return [];
  }

  async getAuditLists(project_id: string): Promise<any> {
    const data = {
      data: {
        access_token: getToken(),
        project: {
          project_id: project_id
        }
      }
    }
    const resp = await axios.post<any>(`/api/private/GetAuditListByProject`, data);

    if (resp.data !== null
      // && resp.data.status === 200
    ) {
      // console.log('resp.data', resp.data);
      const data = resp.data.data;
      console.log("rrr : ", data)
      const retValue = data?.audit_file_with_info_list || []

      return Promise.resolve(_.cloneDeep(retValue));
    }

    return [];
  }

  async setProjectDashboardReport(project_id: string, findings: any): Promise<void> {
    const data = {
      data: {
        access_token: getToken(),
        dashboard: {
          project_id: project_id,
          findings: findings
        }
      }
    }
    const resp = await axios.post<any>(`/api/private/UpdateDashboardReport`, data);
  }

  async getProjectDashboardReport(project_id: string, type: string): Promise<any> {
    const data = {
      data: {
        access_token: getToken(),
        project: {
          project_id: project_id
        }
      }
    }
    const resp = await axios.post<any>(`/api/private/GetDashboardReport`, data);

    if (resp.data !== null
      // && resp.data.status === 200
    ) {
      // console.log('resp.data', resp.data);
      const data = resp.data.data;
      console.log("rrr : ", data)
      const retValue = type === "중요한 취약점" ? data.dashboard?.findings?.main_weakness_list || [] :
        data.dashboard?.findings?.incomplete_point_list || []

      return Promise.resolve(_.cloneDeep(retValue));
    }

    return [];
  }

  async getProjectDashboard(project_id: string): Promise<any> {
    const data = {
      data: {
        access_token: getToken(),
        project: {
          project_id: project_id
        }
      }
    }
    const resp = await axios.post<any>(`/api/private/GetDashboardByProject`, data);

    if (resp.data !== null
      // && resp.data.status === 200
    ) {
      // console.log('resp.data', resp.data);
      const data = resp.data.data;


      return Promise.resolve(_.cloneDeep(data.dashboard));
    }

    return [];
  }

  async prepareArchive(file_name: string): Promise<any> {
    const data = {
      data: {
        access_token: getToken(),
        uri: {
          file_name: file_name,
        }
      }
    }
    const resp = await axios.post<any>(`/api/private/PrepareArchive`, data);
    if (resp.data !== null
      // && resp.data.status === 200
    ) {
      // console.log('resp.data', resp.data);
      const data = resp.data.data;

      return Promise.resolve(_.cloneDeep(data));
    }
  }

  async importProject(file_name: string, path: string, relative_path: string): Promise<any> {
    const data = {
      data: {
        access_token: getToken(),
        uri: {
          file_name: file_name,
          path: path,
          relative_path: relative_path
        }
      }
    }
    const resp = await axios.post<any>(`/api/private/ImportProject`, data);
  }

  async cancelFileUpload(file_name: string, path: string, relative_path: string): Promise<any> {
    const data = {
      data: {
        access_token: getToken(),
        uri: {
          file_name: file_name,
          path: path,
          relative_path: relative_path
        }
      }
    }
    const resp = await axios.post<any>(`/api/private/CancelFileUpload`, data);
  }

  async deleteExportedProject(file_name: string, relative_path: string): Promise<any> {
    const data = {
      data: {
        access_token: getToken(),
        uri: {
          file_name: file_name,
          relative_path: relative_path
        }
      }
    }
    const resp = await axios.post<any>(`/api/private/DeleteExportedProject`, data);
  }

  async updateProjectStatus(project_id: string, status: string): Promise<any> {
    const data = {
      data: {
        access_token: getToken(),
        project: {
          project_id: project_id,
          status
        }
      }
    }
    const resp = await axios.post<any>(`/api/private/UpdateProjectStatus`, data);
  }

  async exportProject(project_id: string): Promise<any> {
    const data = {
      data: {
        access_token: getToken(),
        project: {
          project_id: project_id,
        }
      }
    }
    const resp = await axios.post<any>(`/api/private/ExportProject`, data);
    console.log("ExportProject resp :", resp);
    if (resp.data !== null
      // && resp.data.status === 200
    ) {
      // console.log('resp.data', resp.data);
      const data = resp.data.data;

      return Promise.resolve(_.cloneDeep(data));
    }
  }


  async getProjectList(): Promise<ProjectDto[]> {

    const data = {
      data: {
        access_token: getToken(),
        skip: 0,
        limit: 0,
      }
    }
    const resp = await axios.post<ResponseDto>(`/api/private/GetMyProjectList`, data);

    if (resp.data !== null
      // && resp.data.status === 200
    ) {
      // console.log('resp.data', resp.data);
      const data = resp.data.data;

      return Promise.resolve(_.cloneDeep(data.project_list));
    }

    return [];
  }

  async getProject(project_id: string): Promise<ProjectDto> {
    const data = {
      data: {
        access_token: getToken(),
        project: {
          project_id
        }
      }
    }
    try {
      const resp = await axios.post<ResponseDto1>(`/api/private/GetProject`, data);

      if (resp.data !== null) {
        // console.log('resp.data', resp.data);
        const data = resp.data.data;
        return Promise.resolve(_.cloneDeep(data.project));
      }
    } catch (e) {
      return Promise.reject(e)
    }

    return {} as ProjectDto;
  }

  async getAuditList(projectId: string): Promise<Audit[]> {
    const data = {
      data: {
        access_token: getToken(),
        project_id: projectId
        // project_name: '테스트 프로젝트'
      }
    }

    const resp = await axios.post<ResponseDto2>(`/api/private/GetAuditList`, data);

    if (resp.data !== null) {
      // console.log('resp.data', resp.data);
      const data = resp.data.data;
      return Promise.resolve(_.cloneDeep(data.audit_list));
    }

    return [] as Audit[];
  }

  async createProject(project: ProjectDto): Promise<void> {
    // const projectRole = [];
    // if (project.projectRole) {
    //   project.projectRole.forEach((role) => {
    //     if (typeof role.roleId !== 'string') {
    //       projectRole.push({
    //         userId: role.userId,
    //         roleId: role.roleId,
    //       });
    //     }
    //   });
    // }
    const data = {
      data: {
        access_token: getToken(),
        project
      }
    }
    const resp = await axios.post<ResponseDto1>(`/api/private/NewProject`, data);

    console.log('resp:', resp);
  }

  async addAudit(project: ProjectDto, audit: Audit): Promise<void> {

    const request = {
      data: {
        access_token: getToken(),
        project_id: project.project_id,
        audit
      }
    };

    const resp = await axios.post<ResponseDto1>(`/api/private/AddAudit`, request);

    // console.log('resp:', resp);
  }

  async updateAudit(project: ProjectDto, audit: AuditSendDto): Promise<void> {

    const request = {
      data: {
        access_token: getToken(),
        project_id: project.project_id,
        audit: {
          audit_id: audit.audit_id,
          comment: audit.comment
        }
      }
    };

    const resp = await axios.post<ResponseDto1>(`/api/private/UpdateComment`, request);

    // console.log('resp:', resp);
  }

  async confirmAudit(project: ProjectDto, audit: AuditSendDto): Promise<void> {

    const request = {
      data: {
        access_token: getToken(),
        project_id: project.project_id,
        audit: {
          audit_id: audit.audit_id,
          comment: audit.comment
        }
      }
    };

    const resp = await axios.post<ResponseDto1>(`/api/private/ConfirmAudit`, request);

    // console.log('resp:', resp);
  }

  async rejectAudit(project: ProjectDto, audit: AuditSendDto): Promise<void> {

    const request = {
      data: {
        access_token: getToken(),
        project_id: project.project_id,
        audit: {
          audit_id: audit.audit_id,
          comment: audit.comment
        }
      }
    };

    const resp = await axios.post<ResponseDto1>(`/api/private/RejectAudit`, request);

    // console.log('resp:', resp);
  }

  async deleteAudit(project: ProjectDto, audit: Audit): Promise<void> {

    const request = {
      data: {
        access_token: getToken(),
        project_id: project.project_id,
        audit: {
          audit_id: audit.audit_id,
        }
      }
    };

    const resp = await axios.post<ResponseDto1>(`/api/private/DeleteAudit`, request);

    // console.log('resp:', resp);
  }

  async updateProject(project: ProjectDto): Promise<void> {
    const data = {
      data: {
        access_token: getToken(),
        project
      }
    }
    const resp = await axios.post<ResponseDto1>(`/api/private/UpdateProject`, data);

    // console.log('resp:', resp);

  }

  async deleteProject(projectId: string): Promise<any> {
    const data = {
      data: {
        access_token: getToken(),
        project: {
          project_id: projectId
        }
      }
    }
    try {
      const resp = await axios.post<ResponseDto1>(`/api/private/DeleteProject`, data);
      console.log('resp:', resp);
      return Promise.resolve();
    } catch (e) {
      console.log("DeleteProject : ", e)
      if ((e as { name: string })?.name?.length > 0) {
        return Promise.reject((e as { name: string })?.name)
      }
      return Promise.reject(e);
    }

  }

  async prepareUpload(project: ProjectDto, excel_file_name: string): Promise<ResponseDtoA<ExcelFile>> {
    const request = {
      data: {
        access_token: getToken(),
        project_id: project.project_id,
        // project_name: '테스트 프로젝트',
        excel_file_name
      }
    };

    const resp = await axios.post<ResponseDtoA<ExcelFile>>("/api/private/PrepareExcelFile", request);

    if (resp.data !== null) {
      // console.log('resp.data', resp.data);
      const data = resp.data;
      return Promise.resolve(_.cloneDeep(data));
    }
    else {

    }

    return {} as ResponseDtoA<ExcelFile>
  }


  async saveFile(path: string, file: File): Promise<void> {
    var formData = new FormData();
    formData.append('file', file);

    const resp = await fetch(path, {
      method: "post",
      body: formData,
      mode: 'cors',
      headers: { "Content-Type": "multipart/form-data" }
    })
    console.log('resp:', resp);
  }

  setProjectStatus(status: string) {
    this.project_status = status;
  }
  isProjectDisabled() {
    return this.project_status === "closed" || this.project_status === "imported" || this.project_status === "archived" ? true : false;
  }
}

const projectApi = new projectServerApi();

export default projectApi;
