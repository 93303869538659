import _ from 'lodash';

import config from 'src/shared/config';
import axios from 'src/shared/utils/axios';

import DummyApi from '../dummyApi';
import { times } from '../api';

const INFRA_API_ENABLED = config.servers.infra.enabled;
const INFRA_API_URL = config.servers.infra.url;

// localStorage에 담아야함.
export interface ProductDto {
  id: number;
  desc: string;
  displayKey: string;
  webUrl: string;
  context: string;
  enabled: boolean;
}
interface ProductApi {
  products(): Promise<ProductDto[]>;
}

class ProductDummyApi implements ProductApi {
  products(): Promise<ProductDto[]> {
    const dtos: ProductDto[] = [
      {
        id: 0,
        desc: 'Fasoo Smart Printer UI',
        displayKey: 'uiframework.fspui',
        webUrl: '/fspui',
        context: 'fspui',
        enabled: true,
      },
      {
        id: 1,
        desc: 'Fasoo eXception Management UI',
        displayKey: 'uiframework.fxmui',
        webUrl: '/fast',
        context: 'fxmui',
        enabled: true,
      },
      {
        id: 2,
        desc: 'Fasoo Common UI',
        displayKey: 'uiframework.commonui',
        webUrl: '/commonui',
        context: 'commonui',
        enabled: true,
      },
      {
        id: 3,
        desc: 'Fasoo Manage Organ UI',
        displayKey: 'uiframework.organui',
        webUrl: '/organui',
        context: 'organui',
        enabled: true,
      },
      {
        id: 4,
        desc: 'Fasoo Manage Device UI',
        displayKey: 'uiframework.deviceui',
        webUrl: '/deviceui',
        context: 'deviceui',
        enabled: true,
      },
      {
        id: 5,
        desc: 'Fasoo Audit UI',
        displayKey: 'uiframework.auditui',
        webUrl: '/auditui',
        context: 'auditui',
        enabled: true,
      },
      {
        id: 6,
        desc: 'Fasoo Manage Admin UI',
        displayKey: 'uiframework.adminui',
        webUrl: '/adminui',
        context: 'adminui',
        enabled: true,
      },
      {
        id: 7,
        desc: 'Fasoo Manage Keystore UI',
        displayKey: 'uiframework.keystoreui',
        webUrl: '/keystoreui',
        context: 'keystoreui',
        enabled: true,
      },
    ];

    return Promise.resolve(dtos);
  }
}

const productApi: ProductApi = new ProductDummyApi();

export default productApi;
