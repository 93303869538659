import React, { useEffect, useState, useContext } from 'react';
import templateApi, {
  SpecialDetailDto,
  AuditDto,
  TaskDto,
} from 'src/api/templateApi';
import { useHistory, useParams } from 'react-router-dom';
import usePopup from 'src/hooks/usePopup';
import { useAlert } from 'src/contexts/AlertContext';
import classNames from 'classnames';
import EditIcon from 'src/components/atom/icons/EditIcon';
import IconButton from 'src/components/atom/IconButton';
import { statusColor, AuditElement } from 'src/pages/project/Category';

import Button from 'src/components/atom/Button';
import Select, { SelectItem } from 'src/components/atom/Select';
import AuditRegisterPopup from '../AuditRegisterPopup';
import AuditDetailPopup from '../AuditDetailPopup';
import AuditPreparePopup from '../AuditPreparePopup';
import AuditReviewPopup from '../AuditReviewPopup';

import './SpecialDetailView.scss';
import RiskAssessmentStep1 from './RiskAssessment/Step1'
import RiskAssessmentStep2 from './RiskAssessment/Step2'
import RiskAssessmentStep4 from './RiskAssessment/Step4'

import DefaultRequirement from './DefaultRequirement';
import ProcedureTask from './ProcedureTask';
import projectApi, { ProjectDto, emptyProject } from 'src/api/projectApi';
import MidtermAuditStep1 from './MidtermAudit/Step1';
import MidtermAuditStep2 from './MidtermAudit/Step2';
import MidtermAuditStep3 from './MidtermAudit/Step3';
import MidtermAuditStep4 from './MidtermAudit/Step4';
import MidtermAuditStep4GMTOC from './MidtermAudit/Step4GMTOC';
import MidtermAuditStep5 from './MidtermAudit/Step5';

import ComputerSystemManagementStep1 from './ComputerSystemManagement/Step1';
import ComputerSystemManagementStep2 from './ComputerSystemManagement/Step2';
import ComputerSystemManagementStep3 from './ComputerSystemManagement/Step3';
import ComputerSystemManagementStep4 from './ComputerSystemManagement/Step4';
import useAuthStore from 'src/hooks/auth/useAuthStore';
import { ProjectContext } from '../../Category';
const DetailBody = (props: any) => {
  // console.log("props :", props)

  if (props.condition === "RiskAssessment_1") {
    return <RiskAssessmentStep1 {...props} />
  } else if (props.condition === "RiskAssessment_2") {
    return <RiskAssessmentStep2 {...props} />
  } else if (props.condition === "RiskAssessment_3") {
    return <DefaultRequirement {...props} />
  } else if (props.condition === "RiskAssessment_4") {
    return <RiskAssessmentStep4 {...props} />
  } else if (props.condition === "DEFAULT_PROCEDURE_TASK") {
    return <ProcedureTask {...props} type="default" />
  } else if (props.condition === "CUSTOM_PROCEDURE_TASK") {
    return <ProcedureTask {...props} type="custom" />
  } else if (props.condition === "InterimAudit_1") {
    return <MidtermAuditStep1 {...props} />
  } else if (props.condition === "InterimAudit_2") {
    return <MidtermAuditStep2 {...props} />
  } else if (props.condition === "InterimAudit_3") {
    return <MidtermAuditStep3 {...props} />
  } else if (props.isGmToc) {
    return <MidtermAuditStep4GMTOC {...props} />
  } else if (props.condition === "InterimAudit_4") {
    return <MidtermAuditStep4 {...props} />
  } else if (props.condition === "InterimAudit_5") {
    return <MidtermAuditStep5 {...props} />
  } else if (props.condition === "ITManagement_1") {
    return <ComputerSystemManagementStep1 {...props} />
  } else if (props.condition === "ITManagement_2") {
    return <ComputerSystemManagementStep2 {...props} />
  } else if (props.condition === "ITManagement_3") {
    return <ComputerSystemManagementStep3 {...props} />
  } else if (props.condition === "ITManagement_4") {
    return <ComputerSystemManagementStep4 {...props} />
  }

  return <></>
}

const SpecialDetailView: React.FC<{
  categoryName: string,
  currentTask: TaskDto,
  detailInfo: SpecialDetailDto,
  detailList?: string[],
  onEditDetail: (dto: SpecialDetailDto) => void,
  onEditDetailItem: (dto: SpecialDetailDto) => void,
  onUpdateSubDetail: (dto: SpecialDetailDto) => void,
  onDeleteAudit: (dto: SpecialDetailDto) => void,
  onUpdate: () => void,
  onPrepare: (dto: SpecialDetailDto, isConfirm: boolean) => void,
  onReview: (dto: SpecialDetailDto, isConfirm: boolean) => void,
}> = ({
  categoryName,
  currentTask,
  detailInfo,
  detailList,
  onEditDetail,
  onEditDetailItem,
  onUpdateSubDetail,
  onDeleteAudit,
  onUpdate,
  onPrepare,
  onReview,
}) => {

    let { projectId, taskName, detailName } = useParams<{ projectId: string, taskName: string, detailName: string }>();
    detailName = detailName && decodeURIComponent(detailName);
    const [editMode, setEditMode] = useState<boolean>(false);
    const [requirements, setRequirements] = useState<string>(detailInfo?.requirements);
    const [description, setDescription] = useState<string>(detailInfo?.description || "");
    const [supplement, setSupplement] = useState<string>(detailInfo?.supplement || "");
    const [auditConclusion, setAuditConclusion] = useState<string>(detailInfo?.audit_conclusion || "");
    const [detailSubList, setDetailSubList] = useState<any>();
    const [audit, setAudit] = useState<AuditDto>({} as AuditDto);
    const [largeFile, setLargeFile] = useState<boolean>(false);

    const auditPopup = usePopup();
    const auditDetailPopup = usePopup();
    const auditPreparePopup = usePopup();
    const auditReviewPopup = usePopup();
    const history = useHistory();
    const { confirm, confirmWithWarn, alert2 } = useAlert();
    const auditList = detailInfo?.audit_list;
    const [scrollTop, setScrollTop] = useState<number>(0);
    const [scrollTop2, setScrollTop2] = useState<number>(0);
    const [listHeight, setListHeight] = useState<number>(0);
    const [listHeight2, setListHeight2] = useState<number>(0);
    const { authEntity, startSignOut } = useAuthStore();
    const { projectInfo } = useContext(ProjectContext);

    useEffect(() => {
      if (detailName && categoryName) {
        console.log("detailName : ", detailName)
        templateApi.checkTaskExist(projectInfo.name, categoryName.toUpperCase(), taskName, detailName)
          .then(resp => {
            console.log("resp : ", resp)
            if (resp.ret === true) {
            } else if (resp.ret === false) {
              if (resp.reason === "TASK_NAME") {
                alert2("에러 발생", "삭제된 태스크에 접근하였습니다.")
              } else if (resp.reason === "SUBTASK_NAME") {
                confirmWithWarn("에러 발생", "삭제된 세부 태스크에 접근하였습니다.", () => {
                  history.goBack();
                })
              }
            }
          })
          .catch(e => console.log("e : ", e))
      }
    }, [taskName, detailName, categoryName])

    useEffect(() => {
      detailInfo && setRequirements(detailInfo.requirements)
      detailInfo && setDescription(detailInfo.description || "")
      detailInfo && setAuditConclusion(detailInfo.audit_conclusion || "")
      detailInfo && setSupplement(detailInfo.supplement || "")
      if (detailInfo) {
        if (detailInfo.detail_stage_name === "RiskAssessment_1") {
          setDetailSubList(detailInfo.detail_sub_title_list)
        } else if (detailInfo.detail_stage_name === "RiskAssessment_2") {
          setDetailSubList(detailInfo.detail_sub_content_list)
        } else if (detailInfo.detail_stage_name === "RiskAssessment_4") {
          setDetailSubList(detailInfo.detail_sub_content_list)
        } else if (detailInfo.management_assertion_list && categoryName === "PROCEDURE") {
          setDetailSubList(detailInfo.management_assertion_list)
        } else if (detailInfo.detail_stage_name === "InterimAudit_1") {
          setDetailSubList(detailInfo.detail_sub_title_list)
        } else if (detailInfo.detail_stage_name === "InterimAudit_2") {
          setDetailSubList(detailInfo.detail_sub_title_list)
        } else if (detailInfo.detail_stage_name === "InterimAudit_3") {
          setDetailSubList(detailInfo.detail_sub_title_list)
        } else if (detailInfo.detail_stage_name === "InterimAudit_4") {
          setDetailSubList(detailInfo.detail_sub_title_list)
        } else if (detailInfo.detail_stage_name === "InterimAudit_5") {
          setDetailSubList(detailInfo.detail_sub_title_list)
        } else if (detailInfo.detail_stage_name === "ITManagement_1" || detailInfo.detail_stage_name === "ITManagement_2" || detailInfo.detail_stage_name === "ITManagement_3" || detailInfo.detail_stage_name === "ITManagement_4") {
          setDetailSubList(detailInfo.detail_sub_title_list)
        } else if (categoryName === "PROCEDURE") {
          setDetailSubList(detailInfo.management_assertion_list || [])
        }
      }

      //console.log("detailInfo : ", detailInfo)
    }, [detailInfo])


    useEffect(() => {
    }, [])

    // console.log("detailInfo:", detailInfo)
    if (!detailInfo) return null;

    const handleChange = (html: string) => {
      setRequirements(html)
    }
    const handleChange2 = (html: string, type: string) => {
      // console.log("html : ", html, type)
      if (type === "description") {
        setDescription(html)
      } else if (type === "auditConclusion") {
        setAuditConclusion(html)
      } else if (type === "supplement") {
        setSupplement(html)
      } else if (type === "requirements") {
        setRequirements(html)
      }
    }

    const handleConfirmRequirement = () => {
      // console.log("requirements : ", requirements)
      onEditDetail({
        ...detailInfo,
        requirements
      });
      setEditMode(false);
    }

    const handleCancelRequirement = () => {
      setRequirements(detailInfo.requirements);
      setEditMode(false);
    }

    const handleConfirmRequirement2 = () => {
      // console.log("handleConfirmRequirement2 : ", supplement)
      onEditDetail({
        ...detailInfo,
        description,
        requirements,
        audit_conclusion: auditConclusion,
        supplement
      });
      setEditMode(false);
    }

    const handleCancelRequirement2 = () => {
      setDescription(detailInfo.description || "");
      setAuditConclusion(detailInfo.audit_conclusion || "");
      setSupplement(detailInfo.supplement || "");
      setEditMode(false);
    }

    const handleClickAuditDetail = (file_name: string) => {
      setAudit(detailInfo.audit_list.filter((elem) => elem.file_name === file_name)[0]);
      auditDetailPopup.openPopup();
    }

    const handleClickAuditDetailLarge = (file_name: string) => {
      setLargeFile(true);
      setAudit(detailInfo.audit_list.filter((elem) => elem.file_name === file_name)[0]);
      auditDetailPopup.openPopup();
    }

    const handleClickAuditDelete = (file_name: string) => {
      confirmWithWarn("조서 삭제", "조서를 삭제하시겠습니까?", (result) => {
        if (result) {
          const newList = detailInfo.audit_list.filter((elem) => elem.file_name === file_name)
          const detail = { ...detailInfo };
          if ("detail_sub_title_list" in detail) {
            delete detail?.detail_sub_title_list
          }
          onDeleteAudit({
            ...detail,
            audit_list: newList
          })
        }

      })
    }

    const handleClosePopup = () => {
      setLargeFile(false);
      auditPopup.closePopup();

      onUpdate();
    }

    const handleCloseAuditDetailPopup = () => {
      setLargeFile(false);
      auditDetailPopup.closePopup();

    }
    const handleOpenLargeFile = () => {
      setLargeFile(true);
      auditPopup.openPopup()
    }

    const handlePrepare = (audit: AuditDto) => {
      const myAccount = authEntity?.userInfo?.account || "";
      const chkFlag = audit.preparer_list?.filter(item => item.account === myAccount).length > 0;
      if (audit.prepare_status === "초기화") {
        setAudit(detailInfo.audit_list.filter((elem) => elem.file_name === audit.file_name)[0]);
        auditPreparePopup.openPopup()
      } else if (audit.prepare_status === "프리페어" && !chkFlag) {
        setAudit(detailInfo.audit_list.filter((elem) => elem.file_name === audit.file_name)[0]);
        auditPreparePopup.openPopup()
      } else {
        const detail = {
          ...detailInfo,
          audit_list: [audit]
        }
        if ("detail_sub_title_list" in detail) {
          delete detail?.detail_sub_title_list
        }
        confirmWithWarn("프리페어 취소", "현재 해당 조서에 프리페어 하였습니다. 해제하시겠습니까?", (result) => { result && onPrepare(detail, false) })
      }
    }
    const handleConfirmPrepare = () => {
      const detail = {
        ...detailInfo,
        audit_list: [audit]
      }
      if ("detail_sub_title_list" in detail) {
        delete detail?.detail_sub_title_list
      }
      auditPreparePopup.closePopup();

      onPrepare(detail, true);
    }

    const handleConfirmReview = (audit: AuditDto) => {
      const detail = {
        ...detailInfo,
        audit_list: [audit]
      }
      if ("detail_sub_title_list" in detail) {
        delete detail?.detail_sub_title_list
      }
      auditReviewPopup.closePopup();
      onReview(detail, true);
    }

    const handleReview = (audit: AuditDto) => {
      // console.log("audio.review_status : ", audit.review_status === "리뷰요청");
      if (audit.review_status === "리뷰요청") {
        const detail = {
          ...detailInfo,
          audit_list: [audit]
        }
        if ("detail_sub_title_list" in detail) {
          delete detail?.detail_sub_title_list
        }
        confirmWithWarn("리뷰요청 취소", "현재 해당 조서는 리뷰 중입니다. 취소하시겠습니까?", (result) => { result && onReview(detail, false) })
      } else {
        setAudit(detailInfo.audit_list.filter((elem) => elem.file_name === audit.file_name)[0]);
        auditReviewPopup.openPopup()
      }
    }
    const handleUpdateSubDetail = (newDetailSubList: any) => {
      // 중간간사 4단계의 주용거래 유형 자체에 대한 수정 코드
      if (detailInfo.detail_stage_name === "InterimAudit_4") {
        // console.log("newDetailSubList : ", newDetailSubList)
        onUpdateSubDetail({
          ...detailInfo,
          detail_sub_title_list: newDetailSubList
        });
      }
    }

    const auditOnScroll = (e: any) => {
      setScrollTop(e.target.scrollTop)
    }
    const auditOnScroll2 = (e: any) => {
      setScrollTop2(e.target.scrollTop)
    }


    const handleConfirmDetail = (newDetailSubList: any) => {
      // console.log("detail_sub_title_list : ", newDetailSubList, detailInfo.detail_stage_name)
      if (detailInfo.detail_stage_name === "RiskAssessment_1") {
        onEditDetailItem({
          ...detailInfo,
          detail_sub_title_list: newDetailSubList
        });
      } else if (detailInfo.detail_stage_name === "RiskAssessment_2") {
        onEditDetailItem({
          ...detailInfo,
          detail_sub_content_list: newDetailSubList
        });
      } else if (detailInfo.detail_stage_name === "RiskAssessment_4") {
        onEditDetailItem({
          ...detailInfo,
          detail_sub_content_list: newDetailSubList
        });
      } else if (detailInfo.detail_stage_name === "InterimAudit_1" || detailInfo.detail_stage_name === "InterimAudit_2" || detailInfo.detail_stage_name === "InterimAudit_3") {
        if (newDetailSubList === null) {
          onUpdate();
        } else {
          onEditDetailItem({
            ...detailInfo,
            detail_sub_title_list: newDetailSubList
          });
        }
        // } else if (detailInfo.detail_stage_name === "InterimAudit_4") {
        //   console.log("InterimAudit_4 newDetailSubList : ", newDetailSubList);
        //   onEditDetailItem({
        //     ...detailInfo,
        //     detail_sub_title_list: newDetailSubList
        //   });
      } else if (detailInfo.detail_stage_name === "ITManagement_1" || detailInfo.detail_stage_name === "ITManagement_2" || detailInfo.detail_stage_name === "ITManagement_4") {
        onEditDetailItem({
          ...detailInfo,
          detail_sub_title_list: newDetailSubList
        });
      }
    }
    const isProjectDisabled = projectApi.isProjectDisabled();
    return (
      <div className={classNames("detail-view", { "read-only": !editMode })}>
        <div className='requirements-area'>
          <div className="detail-header element">
            <div className="title" style={{ paddingLeft: 10 }}>{detailInfo.name}</div>
            <div className="status" style={{ width: 150, display: "flex", flexDirection: "row", }}>
              <div className='label'>상태</div>
              {detailInfo.status ?
                <div className="status-root">
                  <div className="status-normal" style={{ backgroundColor: statusColor[`${detailInfo.status}`] }}>
                  </div>
                  <div>
                    {detailInfo.status}
                  </div>
                </div>
                :
                <div className="status-root">
                  <div className="status-none">
                  </div>
                  <div style={{ color: "#cecfd0" }}>
                    {"미진행"}
                  </div>
                </div>
              }
            </div>
            <div className="account">
              <span className='label'>담당자</span>
              <span>{detailInfo.responsibility.nickname}</span>
            </div>
            {(detailInfo?.detail_stage_name !== "InterimAudit_1" &&
              detailInfo?.detail_stage_name !== "InterimAudit_2" && detailInfo?.detail_stage_name !== "InterimAudit_3" &&
              detailInfo?.detail_stage_name !== "InterimAudit_4" && detailInfo?.detail_stage_name !== "InterimAudit_5") && <IconButton classes={classNames("icon-edit")}
                toggle={editMode}
                borderd={editMode} disabled={isProjectDisabled}
                onClick={() => setEditMode(!editMode)}>
                <EditIcon color="#292929" />
              </IconButton>}
          </div>
          <DetailBody
            taskName={taskName}
            currentTask={currentTask}
            detailInfo={detailInfo}
            detailTaskName={detailName}
            editMode={editMode}
            detailSubList={detailSubList}
            condition={categoryName === "PROCEDURE" ? (detailInfo?.sub_task_type === "default" ? "DEFAULT_PROCEDURE_TASK" : "CUSTOM_PROCEDURE_TASK") : detailInfo?.detail_stage_name}
            requirements={requirements}
            description={description}
            auditConclusion={auditConclusion}
            supplement={supplement}
            onUpdate={onUpdate}
            handleChange={categoryName === "PROCEDURE" || detailInfo?.detail_stage_name === "ITManagement_3" || detailInfo?.detail_stage_name === "ITManagement_4" ? handleChange2 : handleChange}
            handleConfirmDetail={handleConfirmDetail}
            handleUpdateSubDetail={handleUpdateSubDetail}
            handleConfirmRequirement={categoryName === "PROCEDURE" || detailInfo?.detail_stage_name === "ITManagement_3" || detailInfo?.detail_stage_name === "ITManagement_4" ? handleConfirmRequirement2 : handleConfirmRequirement}
            handleCancelRequirement={categoryName === "PROCEDURE" || detailInfo?.detail_stage_name === "ITManagement_3" || detailInfo?.detail_stage_name === "ITManagement_4" ? handleCancelRequirement2 : handleCancelRequirement}
          />
        </div>
        <div className='audit-area'>
          {!editMode && <div className="detail-list" >
            <div className="detail-list-title">
              세부 TASK 목록
            </div>
            <div className="detail-list-select">
              <Select
                autoWidth={true}
                selectFirstItem={false}
                selectedEventKey={detailName}
                textAlign='center'
                align='left'
                border={true}
                onClick={(detailName) => {
                  // console.log("value : ", detailName);
                  const _detailName = encodeURIComponent(detailName)
                  history.push(`/projects/${projectId}/category/${categoryName}/${taskName}/${_detailName}`);
                }}
              >
                {detailList && detailList.map((elem) => (
                  <SelectItem key={elem} eventKey={elem} >
                    {elem}
                  </SelectItem>
                ))
                }
              </Select >
            </div>

          </div>}
          <div className={classNames("file-list", { "read-only": !editMode })}>
            <div className="title">
              <span>조서</span>
              <Button classes="button" text="업로드" disabled={isProjectDisabled} onClick={auditPopup.openPopup} />
            </div>
            <div className="list" onScroll={auditOnScroll} ref={(el: any) => {
              const height = el?.getClientRects()[0].height;
              setListHeight(height)
            }}>
              {auditList && auditList.length > 0 &&
                auditList.filter(elem => elem.type === 'small').map((elem, index) => {
                  //console.log("AAAAAAAAAAAAAAAAA : " + index.toString(), ((index + 1) * 40) - (scrollTop), listHeight)
                  const lastItemFlag = ((index + 1) * 40) - (scrollTop) > (listHeight / 2) ? true : false;
                  return <AuditElement
                    key={"audit-small-elem-" + index}
                    lastItem={lastItemFlag}
                    auditDto={elem}
                    onDetail={handleClickAuditDetail}
                    onDelete={handleClickAuditDelete}
                    onPrepare={() => { handlePrepare(elem) }}
                    onReview={() => { handleReview(elem) }}
                  />
                })
              }
            </div>
          </div>
          {categoryName === "PROCEDURE" && <div className="file-list">
            <div className="title">
              <span>대용량 증빙</span>
              <Button classes="button" text="업로드" disabled={isProjectDisabled} onClick={handleOpenLargeFile} />
            </div>
            <div className="list" onScroll={auditOnScroll2} ref={(el: any) => {
              const height = el?.getClientRects()[0].height;
              setListHeight2(height)
            }}>
              {auditList && auditList.length > 0 &&
                auditList.filter(elem => elem.type === 'large').map((elem, index) => {
                  const lastItemFlag = ((index + 1) * 40) - (scrollTop2) > (listHeight2 / 2) ? true : false;
                  return <AuditElement
                    key={"audit-large-elem-" + index}
                    lastItem={lastItemFlag}
                    auditDto={elem}
                    onDetail={handleClickAuditDetailLarge}
                    onDelete={handleClickAuditDelete}
                    onPrepare={() => { handlePrepare(elem) }}
                    onReview={() => { handleReview(elem) }}
                  />
                })
              }
            </div>
          </div>}
        </div>

        {
          auditPopup.opened &&
          <AuditRegisterPopup open={auditPopup.opened}
            detailDto={detailInfo}
            largeFile={largeFile}
            onClose={handleClosePopup}
          />
        }
        {
          auditDetailPopup.opened &&
          <AuditDetailPopup open={auditDetailPopup.opened}
            auditDto={audit}
            largeFile={largeFile}
            onClose={handleCloseAuditDetailPopup}
          />
        }
        {
          auditPreparePopup.opened &&
          <AuditPreparePopup
            taskName={taskName}
            detailName={detailName}
            auditDto={audit}
            open={auditPreparePopup.opened}
            onClose={auditPreparePopup.closePopup}
            onConfirm={handleConfirmPrepare}
          />
        }
        {
          auditReviewPopup.opened &&
          <AuditReviewPopup
            categoryName={categoryName}
            taskName={taskName}
            detailName={detailName}
            auditDto={audit}
            open={auditReviewPopup.opened}
            onClose={auditReviewPopup.closePopup}
            onConfirm={handleConfirmReview}
          />
        }

      </div >
    )

  }






















export const SpecialDetailView2: React.FC<{
  categoryName: string,
  currentTask: TaskDto,
  isGmToc: boolean,
  detailInfo: SpecialDetailDto,
  detailList?: string[],
  onEditDetail: (dto: SpecialDetailDto) => void,
  onDeleteAudit: (dto: SpecialDetailDto) => void,
  onUpdate: () => void,
  onPrepare: (dto: SpecialDetailDto, isConfirm: boolean) => void,
  onReview: (dto: SpecialDetailDto, isConfirm: boolean) => void,
}> = ({
  categoryName,
  currentTask,
  isGmToc,
  detailInfo,
  detailList,
  onEditDetail,
  onDeleteAudit,
  onUpdate,
  onPrepare,
  onReview,
}) => {

    let { projectId, taskName, detailName, transType, detailTransType } = useParams<{ projectId: string, taskName: string, detailName: string, transType: string, detailTransType: string }>();
    detailName = detailName && decodeURIComponent(detailName);
    transType = transType && decodeURIComponent(transType);
    detailTransType = detailTransType && decodeURIComponent(detailTransType);
    const [editMode, setEditMode] = useState<boolean>(false);
    const [detailData, setDeatilData] = useState<SpecialDetailDto>();
    const [gmOrToc, setGmOrToc] = useState<string>("");
    const [description, setDescription] = useState<string>("");
    const [auditConclusion, setAuditConclusion] = useState<string>("");

    const [detailSubList, setDetailSubList] = useState<any>();
    const [auditList, setAuditList] = useState<AuditDto[]>([]);
    const [audit, setAudit] = useState<AuditDto>({} as AuditDto);
    const [largeFile, setLargeFile] = useState<boolean>(false);


    const auditPopup = usePopup();
    const auditDetailPopup = usePopup();
    const auditPreparePopup = usePopup();
    const auditReviewPopup = usePopup();
    const history = useHistory();
    const { confirm, confirmWithWarn, alert2 } = useAlert();
    const [scrollTop, setScrollTop] = useState<number>(0);
    const [scrollTop2, setScrollTop2] = useState<number>(0);
    const [listHeight, setListHeight] = useState<number>(0);
    const [listHeight2, setListHeight2] = useState<number>(0);
    const { authEntity, startSignOut } = useAuthStore();
    const { projectInfo } = useContext(ProjectContext);

    useEffect(() => {
      if (detailName && categoryName) {
        console.log("detailName : ", detailName)
        templateApi.checkTaskExist(projectInfo.name, categoryName.toUpperCase(), taskName, detailName, transType, detailTransType)
          .then(resp => {
            console.log("resp : ", resp)
            if (resp.ret === true) {
            } else if (resp.ret === false) {
              if (resp.reason === "TASK_NAME") {
                alert2("에러 발생", "삭제된 태스크에 접근하였습니다.")
              } else if (resp.reason === "SUBTASK_NAME") {
                confirmWithWarn("에러 발생", "삭제된 세부 태스크에 접근하였습니다.", () => {
                  history.goBack();
                })
              }
            }
          })
          .catch(e => console.log("e : ", e))
      }
    }, [taskName, detailName, categoryName, transType, detailTransType])
    useEffect(() => {
      const newDetail = detailInfo?.detail_sub_title_list?.filter(detail => detail.name === transType)[0];
      const newSubDetail = newDetail?.sub_detail_list?.filter((subDetail: any) => subDetail.name === detailTransType)[0];

      if (detailTransType.includes("GM") || newSubDetail?.sub_detail_type === "GM") {
        setGmOrToc("GM")
      } else if (detailTransType.includes("TOC") || newSubDetail?.sub_detail_type === "TOC") {
        setGmOrToc("TOC")
      } else {
        setGmOrToc("ELSE")
      }
      setDeatilData(newSubDetail);
      setAuditList(newSubDetail?.audit_list || []);
      setDescription(newSubDetail?.description || "")
      setAuditConclusion(newSubDetail?.audit_conclusion || "")


      //console.log("detailInfo : ", detailInfo)
    }, [detailInfo])



    useEffect(() => {

    }, [])

    // console.log("detailInfo:", detailInfo)
    if (!detailInfo) return null;

    const handleChange = (html: string, type: string) => {
      if (type === "description") {
        setDescription(html)
      } else if (type === "auditConclusion") {
        setAuditConclusion(html)
      }
    }

    const handleConfirmRequirement = () => {
      let newDetailInfo = { ...detailInfo };
      const newDetail = detailInfo?.detail_sub_title_list?.filter(detail => detail.name === transType)[0];
      const newSubDetail = newDetail?.sub_detail_list?.filter((subDetail: any) => subDetail.name === detailTransType)[0];

      newDetailInfo.detail_sub_title_list = [{
        ...newDetail,
        sub_detail_list: [{
          ...newSubDetail,
          description,
          audit_conclusion: auditConclusion
        }]
      }]

      onEditDetail(newDetailInfo);
      setEditMode(false);
    }

    const handleCancelRequirement = () => {
      const newDetail = detailInfo?.detail_sub_title_list?.filter(detail => detail.name === transType)[0];
      const newSubDetail = newDetail?.sub_detail_list?.filter((subDetail: any) => subDetail.name === detailTransType)[0];
      setDescription(newSubDetail.description || "");
      setAuditConclusion(newSubDetail.audit_conclusion || "");
      setEditMode(false);
    }

    const handleClickAuditDetail = (file_name: string) => {
      const newDetail = detailInfo?.detail_sub_title_list?.filter(detail => detail.name === transType)[0];
      const newSubDetail = newDetail?.sub_detail_list?.filter((subDetail: any) => subDetail.name === detailTransType)[0];
      setAudit(newSubDetail.audit_list?.filter((elem: any) => elem.file_name === file_name)[0]);
      auditDetailPopup.openPopup();
    }

    const handleClickAuditDetailLarge = (file_name: string) => {
      setLargeFile(true);
      const newDetail = detailInfo?.detail_sub_title_list?.filter(detail => detail.name === transType)[0];
      const newSubDetail = newDetail?.sub_detail_list?.filter((subDetail: any) => subDetail.name === detailTransType)[0];
      setAudit(newSubDetail.audit_list?.filter((elem: any) => elem.file_name === file_name)[0]);
      auditDetailPopup.openPopup();
    }

    const handleClickAuditDelete = (file_name: string) => {
      confirmWithWarn("조서삭제", "조서를 삭제하시겠습니까?", (result) => {
        if (result) {
          let newDetailInfo = { ...detailInfo };
          const newDetail = detailInfo?.detail_sub_title_list?.filter(detail => detail.name === transType)[0];
          const newSubDetail = newDetail?.sub_detail_list?.filter((subDetail: any) => subDetail.name === detailTransType)[0];
          const newList = newSubDetail.audit_list.filter((elem: any) => elem.file_name === file_name)
          newDetailInfo.detail_sub_title_list = [{
            ...newDetail,
            sub_detail_list: [{
              ...newSubDetail,
              audit_list: newList
            }]
          }]
          onDeleteAudit(newDetailInfo)
        }
      })
    }

    const handleClosePopup = () => {
      onUpdate();
      auditPopup.closePopup();
      setLargeFile(false);
    }

    const handleCloseAuditDetailPopup = () => {
      setLargeFile(false);
      auditDetailPopup.closePopup();
    }
    const handleOpenLargeFile = () => {
      setLargeFile(true);
      auditPopup.openPopup()
    }

    const handlePrepare = (audit: AuditDto) => {
      const newDetail = detailInfo?.detail_sub_title_list?.filter(detail => detail.name === transType)[0];
      const newSubDetail = newDetail?.sub_detail_list?.filter((subDetail: any) => subDetail.name === detailTransType)[0];
      const myAccount = authEntity?.userInfo?.account || "";
      const chkFlag = audit.preparer_list?.filter(item => item.account === myAccount).length > 0;
      if (audit.prepare_status === "초기화") {
        setAudit(newSubDetail.audit_list.filter((elem: any) => elem.file_name === audit.file_name)[0]);
        auditPreparePopup.openPopup()
      } else if (audit.prepare_status === "프리페어" && !chkFlag) {
        setAudit(newSubDetail.audit_list.filter((elem: any) => elem.file_name === audit.file_name)[0]);
        auditPreparePopup.openPopup()
      } else {
        let newDetailInfo = { ...detailInfo };
        newDetailInfo.detail_sub_title_list = [{
          ...newDetail,
          sub_detail_list: [{
            ...newSubDetail,
            audit_list: [audit]
          }]
        }]
        confirmWithWarn("프리페어 취소", "현재 해당 조서에 프리페어 하였습니다. 해제하시겠습니까?", (result) => { result && onPrepare(newDetailInfo, false) })
      }
    }
    const handleConfirmPrepare = () => {
      let newDetailInfo = { ...detailInfo };
      const newDetail = detailInfo?.detail_sub_title_list?.filter(detail => detail.name === transType)[0];
      const newSubDetail = newDetail?.sub_detail_list?.filter((subDetail: any) => subDetail.name === detailTransType)[0];
      newDetailInfo.detail_sub_title_list = [{
        ...newDetail,
        sub_detail_list: [{
          ...newSubDetail,
          audit_list: [audit]
        }]
      }]
      auditPreparePopup.closePopup();

      onPrepare(newDetailInfo, true);
    }

    const handleConfirmReview = (audit: AuditDto) => {
      let newDetailInfo = { ...detailInfo };
      const newDetail = detailInfo?.detail_sub_title_list?.filter(detail => detail.name === transType)[0];
      const newSubDetail = newDetail?.sub_detail_list?.filter((subDetail: any) => subDetail.name === detailTransType)[0];
      newDetailInfo.detail_sub_title_list = [{
        ...newDetail,
        sub_detail_list: [{
          ...newSubDetail,
          audit_list: [audit]
        }]
      }]
      auditReviewPopup.closePopup();
      // console.log("newDetailInfo : ", newDetailInfo)
      onReview(newDetailInfo, true);
    }

    const handleReview = (audit: AuditDto) => {
      // console.log("audio.review_status : ", audit.review_status === "리뷰요청");
      let newDetailInfo = { ...detailInfo };
      const newDetail = detailInfo?.detail_sub_title_list?.filter(detail => detail.name === transType)[0];
      const newSubDetail = newDetail?.sub_detail_list?.filter((subDetail: any) => subDetail.name === detailTransType)[0];

      if (audit.review_status === "리뷰요청") {
        newDetailInfo.detail_sub_title_list = [{
          ...newDetail,
          sub_detail_list: [{
            ...newSubDetail,
            audit_list: [audit]
          }]
        }]
        // console.log("newDetailInfo  :", newDetailInfo)
        confirmWithWarn("리뷰요청 취소", "현재 해당 조서는 리뷰 중입니다. 취소하시겠습니까?", (result) => { result && onReview(newDetailInfo, false) })
      } else {
        setAudit(newSubDetail.audit_list.filter((elem: any) => elem.file_name === audit.file_name)[0]);
        auditReviewPopup.openPopup()
      }
    }

    const auditOnScroll = (e: any) => {
      setScrollTop(e.target.scrollTop)
    }
    const auditOnScroll2 = (e: any) => {
      setScrollTop2(e.target.scrollTop)
    }
    const isProjectDisabled = projectApi.isProjectDisabled();
    return (
      <div className={classNames("detail-view", { "read-only": !editMode })}>
        <div className='requirements-area'>
          <div className="detail-header element">
            <div className="title" style={{ paddingLeft: 10 }}>{detailData?.name}</div>
            <div className="status" style={{ width: 150, display: "flex", flexDirection: "row", }}>
              <div className='label'>상태</div>
              {detailData?.status ?
                <div className="status-root">
                  <div className="status-normal" style={{ backgroundColor: statusColor[`${detailData?.status}`] }}>
                  </div>
                  <div>
                    {detailData?.status}
                  </div>
                </div>
                :
                <div className="status-root">
                  <div className="status-none">
                  </div>
                  <div style={{ color: "#cecfd0" }}>
                    {"미진행"}
                  </div>
                </div>
              }
            </div>
            <div className="account">
              <span className='label'>담당자</span>
              <span>{detailData?.responsibility.nickname}</span>
            </div>
            <IconButton classes={classNames("icon-edit")}
              toggle={editMode}
              borderd={editMode} disabled={isProjectDisabled}
              onClick={() => setEditMode(!editMode)}>
              <EditIcon color="#292929" />
            </IconButton>
          </div>
          <DetailBody
            taskName={taskName}
            currentTask={currentTask}
            isGmToc={isGmToc}
            gmOrToc={gmOrToc}
            detailInfo={detailData}
            detailTaskName={detailName}
            editMode={editMode}
            detailSubList={detailSubList}
            description={description}
            auditConclusion={auditConclusion}
            handleChange={handleChange}
            onUpdate={onUpdate}
            handleConfirmRequirement={handleConfirmRequirement}
            handleCancelRequirement={handleCancelRequirement}
          />
        </div>
        <div className='audit-area'>
          {!editMode && <div className="detail-list" >
            <div className="detail-list-title">
              세부 TASK 목록
            </div>
            <div className="detail-list-select">
              <Select
                autoWidth={true}
                selectFirstItem={false}
                selectedEventKey={detailName}
                textAlign='center'
                align='left'
                border={true}
                onClick={(detailName) => {
                  // console.log("value : ", detailName);
                  const _detailName = encodeURIComponent(detailName)
                  history.push(`/projects/${projectId}/category/${categoryName}/${taskName}/${_detailName}`);
                }}
              >
                {detailList && detailList.map((elem) => (
                  <SelectItem key={elem} eventKey={elem} >
                    {elem}
                  </SelectItem>
                ))
                }
              </Select >
            </div>

          </div>}
          <div className={classNames("file-list", { "read-only": !editMode })}>
            <div className="title">
              <span>조서</span>
              <Button classes="button" text="업로드" disabled={isProjectDisabled} onClick={auditPopup.openPopup} />
            </div>
            <div className="list" onScroll={auditOnScroll} ref={(el: any) => {
              const height = el?.getClientRects()[0].height;
              setListHeight(height)
            }}
            >
              {auditList && auditList.length > 0 &&
                auditList.filter(elem => elem.type === 'small').map((elem, index) => {
                  const lastItemFlag = ((index + 1) * 40) - (scrollTop) > (listHeight / 2) ? true : false;
                  return <AuditElement
                    key={"audit-small-elem-" + index}
                    lastItem={lastItemFlag}
                    auditDto={elem}
                    onDetail={handleClickAuditDetail}
                    onDelete={handleClickAuditDelete}
                    onPrepare={() => { handlePrepare(elem) }}
                    onReview={() => { handleReview(elem) }}
                  />
                })
              }
            </div>
          </div>
          <div className="file-list">
            <div className="title">
              <span>대용량 증빙</span>
              <Button classes="button" text="업로드" disabled={isProjectDisabled} onClick={handleOpenLargeFile} />
            </div>
            <div className="list" onScroll={auditOnScroll2} ref={(el: any) => {
              const height = el?.getClientRects()[0].height;
              setListHeight2(height)
            }}>
              {auditList && auditList.length > 0 &&
                auditList.filter(elem => elem.type === 'large').map((elem, index) => {
                  const lastItemFlag = ((index + 1) * 40) - (scrollTop2) > (listHeight2 / 2) ? true : false;
                  return <AuditElement
                    key={"audit-large-elem-" + index}
                    lastItem={lastItemFlag}
                    auditDto={elem}
                    onDetail={handleClickAuditDetailLarge}
                    onDelete={handleClickAuditDelete}
                    onPrepare={() => { handlePrepare(elem) }}
                    onReview={() => { handleReview(elem) }}
                  />
                })
              }
            </div>
          </div>
        </div>

        {auditPopup.opened &&
          <AuditRegisterPopup open={auditPopup.opened}
            detailDto={detailInfo}
            gmOrToc={gmOrToc}
            largeFile={largeFile}
            onClose={handleClosePopup}
          />
        }
        {auditDetailPopup.opened &&
          <AuditDetailPopup open={auditDetailPopup.opened}
            auditDto={audit}
            largeFile={largeFile}
            onClose={handleCloseAuditDetailPopup}
          />
        }
        {auditPreparePopup.opened &&
          <AuditPreparePopup
            taskName={transType}
            detailName={detailTransType}
            auditDto={audit}
            open={auditPreparePopup.opened}
            onClose={auditPreparePopup.closePopup}
            onConfirm={handleConfirmPrepare}
          />
        }
        {auditReviewPopup.opened &&
          <AuditReviewPopup
            categoryName={categoryName}
            taskName={transType}
            detailName={detailTransType}
            auditDto={audit}
            open={auditReviewPopup.opened}
            onClose={auditReviewPopup.closePopup}
            onConfirm={handleConfirmReview}
          />
        }

      </div>
    )

  }

export default SpecialDetailView;