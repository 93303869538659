import config from 'src/shared/config';
import axios from 'src/shared/utils/axios';
import _ from 'lodash';

const INFRA_API_ENABLED = config.servers.infra.enabled;
const INFRA_API_URL = config.servers.infra.url;

export interface DomainConfigDto {
  validDay: number; // 정책 파일 유효 기간 (일)
  policyUpdateHour: number; // DRM 클라이언트 정책 업데이트 주기 (시간)
  autoLogin: boolean; // 자동 로그인 표시 여부
  heartbeatMinute: number; // 하트비트 주기 (분)
}

export type DomainConfigReqDto = DomainConfigDto;

interface DomainConfigApi {
  get(): Promise<DomainConfigDto>;
  update(reqDto: DomainConfigReqDto): Promise<DomainConfigDto>;
}

class DomainConfigServerApi implements DomainConfigApi {
  async get(): Promise<DomainConfigDto> {
    const resp = await axios.get<DomainConfigDto>(`${INFRA_API_URL}/api/policy-file`);
    return resp.data;
  }
  async update(reqDto: DomainConfigReqDto): Promise<DomainConfigDto> {
    const resp = await axios.post<DomainConfigDto>(`${INFRA_API_URL}/api/policy-file`, reqDto);
    return resp.data;
  }
}

class DomainConfigDummyApi implements DomainConfigApi {
  data: DomainConfigDto;
  constructor() {
    this.data = {
      validDay: 7,
      policyUpdateHour: 2,
      autoLogin: false,
      heartbeatMinute: 10,
    };
  }

  get(): Promise<DomainConfigDto> {
    return Promise.resolve(_.cloneDeep(this.data));
  }
  update(reqDto: DomainConfigReqDto): Promise<DomainConfigDto> {
    this.data = _.cloneDeep(reqDto);
    return Promise.resolve(_.cloneDeep(this.data));
  }
}

const domainConfigApi: DomainConfigApi = INFRA_API_ENABLED
  ? new DomainConfigServerApi()
  : new DomainConfigDummyApi();

export default domainConfigApi;
