import config from 'src/shared/config';
import axios from 'src/shared/utils/axios';

import DummyApi from './dummyApi';
import { times } from './api';

const FSP_API_ENABLED = config.servers.fsp.enabled;
const FSP_API_URL = config.servers.fsp.url;

export interface WatermarkTargetDto {
  targetCode: string; // 분류 코드
  applyOption: '*' | string; // 워터마크 적용 조건 (사용하면 등급 코드 ,안 하면 '*')
  watFileCode: string; // 워터마크 파일 코드
}

export interface WatermarkTemplateDto {
  code: string;
  name: string;
  description: string;
  use: boolean;
  entryTs: number;

  targetList: WatermarkTargetDto[]; // 대상 워터마크 목록
}

export type WatermarkTemplateReqDto = Omit<WatermarkTemplateDto, 'code' | 'entryTs'>;

export const defaultWatermarkTemplateReqDto: WatermarkTemplateReqDto = {
  name: '',
  description: '',
  use: true,

  targetList: [],
};

interface WatermarkTemplateApi {
  list(): Promise<WatermarkTemplateDto[]>;
  find(code: string): Promise<WatermarkTemplateDto>;
  create(reqDto: WatermarkTemplateReqDto): Promise<WatermarkTemplateDto>;
  update(code: string, reqDto: WatermarkTemplateReqDto): Promise<WatermarkTemplateDto>;
  remove(code: string): Promise<void>;
}

class WatermarkTemplateServerApi implements WatermarkTemplateApi {
  async list(): Promise<WatermarkTemplateDto[]> {
    const resp = await axios.get<WatermarkTemplateDto[]>(`${FSP_API_URL}/api/wats/templates`);
    return resp.data;
  }
  async find(code: string): Promise<WatermarkTemplateDto> {
    const resp = await axios.get<WatermarkTemplateDto>(`${FSP_API_URL}/api/wats/templates/${code}`);
    return resp.data;
  }
  async create(reqDto: WatermarkTemplateReqDto): Promise<WatermarkTemplateDto> {
    const resp = await axios.post<WatermarkTemplateDto>(
      `${FSP_API_URL}/api/wats/templates`,
      reqDto
    );
    return resp.data;
  }
  async update(code: string, reqDto: WatermarkTemplateReqDto): Promise<WatermarkTemplateDto> {
    const resp = await axios.put<WatermarkTemplateDto>(
      `${FSP_API_URL}/api/wats/templates/${code}`,
      reqDto
    );
    return resp.data;
  }
  async remove(code: string): Promise<void> {
    await axios.delete<WatermarkTemplateDto>(`${FSP_API_URL}/api/wats/templates/${code}`);
  }
}

class WatermarkTemplateDummyApi implements WatermarkTemplateApi {
  dummyApi: DummyApi<WatermarkTemplateDto, WatermarkTemplateReqDto>;
  constructor() {
    const data: WatermarkTemplateDto[] = [
      {
        name: '일반 사원급 기본 정책A',
        description: '사내 테스트를 위한 기본 정책입니다.',
        use: true,
        targetList: [
          {
            watFileCode: 'code-0',
            targetCode: 'FSP 문서',
            applyOption: '*',
          },
          {
            watFileCode: 'code-1',
            targetCode: 'FED5-FSN 문서',
            applyOption: '5e2846e50c53420a9ab2cfea7864d048',
          },
        ],
      },
      {
        name: '테스트 정책',
        description: '테스트를 위한 기본 정책 입니다.',
        use: true,
        targetList: [
          {
            watFileCode: 'code-0',
            targetCode: 'FSP 문서',
            applyOption: '*',
          },
          {
            watFileCode: 'code-1',
            targetCode: 'FED5-FSN 문서',
            applyOption: '*',
          },
        ],
      },
    ].map((o, i) => ({ ...o, code: '', entryTs: times(i) }));

    this.dummyApi = DummyApi.of({
      entityName: 'WatermarkTemplate',
      createDto: (code, entity) => ({ ...entity, code, entryTs: Date.now() }),
      validation: (type, code, reqDto, data) => {
        data.forEach((dto) => {
          if (
            (type === 'create' && dto.name === reqDto.name) ||
            (type === 'update' && dto.code !== code && dto.name === reqDto.name)
          ) {
            throw new Error('이미 사용 중인 이름입니다.');
          }
        });
      },
      data,
    });
  }

  list(): Promise<WatermarkTemplateDto[]> {
    return this.dummyApi.list();
  }
  find(code: string): Promise<WatermarkTemplateDto> {
    return this.dummyApi.find(code);
  }
  create(reqDto: WatermarkTemplateReqDto): Promise<WatermarkTemplateDto> {
    return this.dummyApi.create(reqDto);
  }
  update(code: string, reqDto: WatermarkTemplateReqDto): Promise<WatermarkTemplateDto> {
    return this.dummyApi.update({ code, reqDto });
  }
  remove(code: string): Promise<void> {
    return this.dummyApi.remove(code);
  }
}

const watermarkTemplateApi: WatermarkTemplateApi = FSP_API_ENABLED
  ? new WatermarkTemplateServerApi()
  : new WatermarkTemplateDummyApi();

export default watermarkTemplateApi;
