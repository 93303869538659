import React, { Fragment, Children, useEffect, useState, useContext } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import update from 'immutability-helper';
import moment from 'moment';
import classNames from 'classnames';

import { CommonProps } from 'src/components/helpers/props';
import templateApi, { CategoryDto, TaskDto, DetailDto, createTemplate, SpecialDetailDto } from 'src/api/templateApi';
import { MemberDto } from 'src/api/userSettingApi';

import ArrowDownIcon from 'src/components/atom/icons/ArrowDownIcon';
import ArrowUpIcon from 'src/components/atom/icons/ArrowUpIcon';
import EditIcon from 'src/components/atom/icons/EditIcon';
import RemoveIcon from 'src/components/atom/icons/RemoveIcon';
import ValidationIcon from 'src/components/atom/icons/ValidationIcon';
import TrashIcon from 'src/components/atom/icons/TrashIcon';
import IconButton from 'src/components/atom/IconButton';
import DotIcon from 'src/components/atom/icons/DotIcon';
import Button from 'src/components/atom/Button';
import Select, { SelectItem } from 'src/components/atom/Select';
import { useAlert } from 'src/contexts/AlertContext';
import TextField from 'src/components/atom/TextField';
import projectApi, { ProjectDto, emptyProject } from 'src/api/projectApi';
import { ProjectContext, statusColor } from '../Category';
import './TaskElement.scss';
//import Xarrow from "react-xarrows";

interface TaskElementProps extends CommonProps {
  task: TaskDto,
  addList?: DetailDto[],
  showDetail?: boolean,
  taskEditable?: boolean,
  taskNameEditable?: boolean,
  detailEditable?: boolean,
  taskDeletable?: boolean,
  onEditTask?: (task: TaskDto) => void,
  onEditAddList?: (list: DetailDto[]) => void,
  onDeleteTask?: () => void,
  onTaskEditClick?: () => void,
  onRequestTask?: () => void,
  onDetailClick?: (taskName: string, detailName: string) => void,
  onMyFavoriteClick?: (task: TaskDto) => void;
}

//TODO
// TASK DETAIL component로 만들기
const TaskElement: React.FC<TaskElementProps> = ({
  task,
  addList = [],
  showDetail = false,
  taskEditable = false,
  taskDeletable = false,
  taskNameEditable = false,
  detailEditable = false,
  onEditTask,
  onEditAddList,
  onDeleteTask,
  onRequestTask,
  onTaskEditClick,
  onDetailClick,
  onMyFavoriteClick
}) => {

  const [detailVisible, setDetailVisible] = useState<boolean>(showDetail);
  const { projectId, category } = useParams<{ projectId: string, category: string }>();
  const history = useHistory();
  // const [ updateTask, setUpdateTask ] = useState<TaskDto>(task);
  const { projectInfo } = useContext(ProjectContext);

  // const { projectInfo } = useProjectInfo(projectId, "");
  const [memberList, setMemberList] = useState<MemberDto[]>([]);
  const [memberSelected, setMember] = useState<MemberDto>();
  const { confirmWithWarn, alert2 } = useAlert();


  useEffect(() => {
    if (projectInfo.member_list) {
      setMemberList(projectInfo.member_list);
    }
  }, [projectInfo])

  const handleTaskNameChange = (newName: string) => {
    onEditTask && onEditTask({
      ...task,
      new_name: newName
    })

  }


  const handleDetailChange = (detail: DetailDto, index: number) => {
    onEditTask && onEditTask(update(task, { detail_list: { [index]: { $set: detail } } }))
  }

  const handleDetailDelete = (index: number) => {
    const newList = task.detail_list.filter((elem, idx) => index !== idx)
    onEditTask && onEditTask({
      ...task,
      detail_list: newList
    })
  }

  const handleMyFavorite = (index: number) => {
    console.log("index :", index)
    const newList = task.detail_list.filter((elem, idx) => index === idx)
    newList[0].detail_sub_title_list && delete newList[0].detail_sub_title_list;
    onMyFavoriteClick && onMyFavoriteClick({
      ...task,
      detail_list: newList
    })
  }

  const handleDeleteTask = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    onDeleteTask && onDeleteTask();
  }

  const handleTaskEdit = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    onTaskEditClick && onTaskEditClick();
  };

  const handleTaskRequest = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    onRequestTask && onRequestTask();
  };

  const handleAccountAllChange = (member: MemberDto) => {
    if (!task.detail_list) {
      alert2("TASK 수정", "세부 TASK가 존재하지 않으면 담당자 지정은 의미가 없습니다.")
      return;
    }

    const onConfirm = () => {
      let newList: DetailDto[] = []
      task.detail_list.map((detail: DetailDto) => {
        newList.push({
          ...detail,
          responsibility: {
            account: member?.account,
            nickname: member?.nickname,
          }
        })
      })
      onEditTask && onEditTask({
        ...task,
        detail_list: newList
      })
    }
    confirmWithWarn('TASK 수정', '세부 TASK의 담당자들이 일괄로 수정됩니다.\n계속 진행하시겠습니까?', onConfirm);
  }
  const isProjectDisabled = projectApi.isProjectDisabled();

  return (
    <div className="task-element">

      <div className="task-title" >

        <div
          className={'task-title-button' + (task.detail_list?.length > 0 ? '' : ' cursor-default')
            +
            (task?.task_type === "IT Management" || task?.task_type === "Interim Audit" || task?.task_type === "Risk Assessment" ? ' background-color-change' : '')

          }
          // 디테일이 없다면 커서 포인터 안만들어지게 추가
          // style={task.detail_list?.length > 0 ? {} : { cursor: "default" }}
          onClick={() => {
            if (taskNameEditable) return;

            setDetailVisible(!detailVisible);
          }}
        >
          {task.detail_list?.length > 0 ? (detailVisible ?
            (detailEditable ?
              <div id="task-title-start" style={{
                display: "flex", alignItems: "center",
                justifyContent: "center", width: 50, height: 25, backgroundColor: "#295c9f", marginLeft: 10, borderRadius: 5
              }} >
                <div style={{ color: "white", fontSize: 13, fontWeight: "normal" }}>TASK</div>
              </div>
              :
              <ArrowUpIcon classes='icon-up' />) :
            <ArrowDownIcon fill="#bcbccb" classes='icon-down' />)
            :
            (detailEditable ?
              <div id="task-title-start" style={{
                display: "flex", alignItems: "center",
                justifyContent: "center", width: 50, height: 25, backgroundColor: "#295c9f", marginLeft: 10, borderRadius: 5
              }} >
                <div style={{ color: "white", fontSize: 13, fontWeight: "normal" }}>TASK</div>
              </div>
              :
              <RemoveIcon fill="#bcbccb" classes='icon-down' />)}

          {taskNameEditable ?
            <TextField classes="task-name-edit"
              value={task.new_name || task.name}
              readOnly={task.task_type ? true : false}
              placeholder="세부 TASK를 입력하세요."
              onChange={(e) => { handleTaskNameChange(e.target.value) }}
            />
            :
            <div className='task-name'>{task.name}</div>}
          {
            detailEditable &&
            <div /*style={{ width: 140, position: "relative", display: "flex", height: 45, }}*/
              className='task-select-all'
            >
              <SelectAccount
                memberList={memberList}
                selected={undefined}
                allFlag={true}
                onSelected={(index) => {
                  handleAccountAllChange(memberList[index])
                }}
              />
              {/* <div style={{ position: "absolute", backgroundColor: "#F5F6Fa", width: 80, height: 35, left: 0, top: 5, display: "flex", alignItems: "center" }}>
                <span style={{ padding: 7, color: "#c4c4c4", verticalAlign: "middle" }}>

                  일괄 선택
                </span>
              </div> */}
            </div>
          }
          {taskEditable && (
            <Fragment>

              <IconButton classes="icon-request" onClick={handleTaskRequest} disabled={task.detail_list?.length > 0 ? isProjectDisabled : true}
              >
                <ValidationIcon size="lg" color={task?.task_type === "IT Management" || task?.task_type === "Interim Audit" || task?.task_type === "Risk Assessment" ? "#ffffff" : "#292929"} />
              </IconButton>


              <IconButton classes="icon-edit" onClick={handleTaskEdit} disabled={isProjectDisabled}>
                <EditIcon color={task?.task_type === "IT Management" || task?.task_type === "Interim Audit" || task?.task_type === "Risk Assessment" ? "#ffffff" : "#292929"} />
              </IconButton>
              {!taskDeletable ?
                <div className="icon-trash" />
                : <IconButton classes="icon-trash" disabled={isProjectDisabled}
                  onClick={(e) => handleDeleteTask(e)}>
                  <TrashIcon fill="none" stroke={task?.task_type === "IT Management" || task?.task_type === "Interim Audit" || task?.task_type === "Risk Assessment" ? "#ffffff" : "#292929"} />
                </IconButton>}
            </Fragment>
          )
          }
        </div>
      </div>
      {detailVisible &&
        <div className="task-detail">
          {task.detail_list?.map((detail, index) => {
            return (
              <TaskDetail
                key={"taskElement" + index}
                id={"taskElement" + index}
                lastItem={task.detail_list.length === index + 1 || index > 3 ? true : false}
                detailDto={detail}
                taskName={task.name}
                memberList={memberList}
                editable={detailEditable}
                onDetailClick={() => onDetailClick && onDetailClick(task.name, detail.name)}
                onDetailChange={(value) => handleDetailChange(value, index)}
                onDetailDelete={() => handleDetailDelete(index)}
                onDetailMyFavorite={() => handleMyFavorite(index)}
              />
            )
          })}

        </div>

      }
      { // 위의 테스트 결과를 가지고 만들었음
        detailEditable && task.detail_list?.map((detail, index) => {
          let pos: number = 51.5 + (index * 45)
          let height: number = 67.5 + (index * 45)
          let d: string = "M 16 16 L  16 " + pos + " L 50.546875 " + pos + " 50.546875 " + pos
          let top: number = -74 - ((task.detail_list.length - 1) * 45)
          return (
            <div style={{ position: "absolute", zIndex: 0 }}>
              <svg width={111.40625} height={height} overflow={"auto"}
                style={{ position: "absolute", left: 17.4531, top: `${top}`, pointerEvents: "none" }}>
                <path d={d} stroke="#295c9f" strokeDasharray="0 0" strokeWidth="2" fill="transparent" pointerEvents="visibleStroke">
                </path>
              </svg>
            </div>
          )
        })

      }


    </div>
  )
}


export const TaskElementForMidtermAudit: React.FC<TaskElementProps> = ({
  task,
  addList = [],
  showDetail = false,
  taskEditable = false,
  taskDeletable = false,
  taskNameEditable = false,
  detailEditable = false,
  onEditTask,
  onEditAddList,
  onDeleteTask,
  onRequestTask,
  onTaskEditClick,
  onDetailClick,
  onMyFavoriteClick,
}) => {

  const [detailVisible, setDetailVisible] = useState<boolean>(showDetail);
  const { projectId, category } = useParams<{ projectId: string, category: string }>();
  const history = useHistory();
  // const [ updateTask, setUpdateTask ] = useState<TaskDto>(task);
  const { projectInfo } = useContext(ProjectContext);

  // const { projectInfo } = useProjectInfo(projectId, "");
  const [memberList, setMemberList] = useState<MemberDto[]>([]);
  const [memberSelected, setMember] = useState<MemberDto>();
  const { confirmWithWarn } = useAlert();


  useEffect(() => {
    if (projectInfo.member_list) {
      setMemberList(projectInfo.member_list);
    }
  }, [projectInfo])

  const handleTaskNameChange = (newName: string) => {
    onEditTask && onEditTask({
      ...task,
      new_name: newName
    })

  }


  const handleDetailChange = (detail: DetailDto, index: number) => {
    onEditTask && onEditTask(update(task, { detail_list: { [index]: { $set: detail } } }))
  }

  const handleDetailDelete = (index: number) => {
    const newList = task.detail_list.filter((elem, idx) => index !== idx)
    onEditTask && onEditTask({
      ...task,
      detail_list: newList
    })
  }

  const handleDeleteTask = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    onDeleteTask && onDeleteTask();
  }

  const handleTaskEdit = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    onTaskEditClick && onTaskEditClick();
  };

  const handleTaskRequest = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    onRequestTask && onRequestTask();
  };

  const handleAccountAllChange = (member: MemberDto) => {
    const onConfirm = () => {
      let newList: DetailDto[] = []
      task.detail_list.map((detail: DetailDto) => {
        newList.push({
          ...detail,
          responsibility: {
            account: member?.account,
            nickname: member?.nickname,
          }
        })
      })
      onEditTask && onEditTask({
        ...task,
        detail_list: newList
      })
    }
    confirmWithWarn('TASK 수정', '세부 TASK의 담당자들이 일괄로 수정됩니다.\n계속 진행하시겠습니까?', onConfirm);
  }
  // console.log("task : ", task)
  const handleMyFavorite = (index: number) => {
    console.log("index11 : ", index)
    console.log("index11 : ", task)
    const newList = task.sub_detail_list?.filter((elem, idx) => index === idx)
    console.log("index11 : ", newList)
    onMyFavoriteClick && onMyFavoriteClick({
      ...task,
      sub_detail_list: newList
    })

  }

  const isProjectDisabled = projectApi.isProjectDisabled();

  return (
    <div className="task-element">

      <div className="task-title" >

        <div
          className={'task-title-button' + (task.sub_detail_list ? '' : ' cursor-default')
          }
          // 디테일이 없다면 커서 포인터 안만들어지게 추가
          // style={task.detail_list?.length > 0 ? {} : { cursor: "default" }}
          onClick={() => {
            if (taskNameEditable) return;

            setDetailVisible(!detailVisible);
          }}
        >
          {task.sub_detail_list ? (detailVisible ?
            (detailEditable ?
              <div id="task-title-start" style={{
                display: "flex", alignItems: "center",
                justifyContent: "center", width: 50, height: 25, backgroundColor: "#295c9f", marginLeft: 10, borderRadius: 5
              }} >
                <div style={{ color: "white", fontSize: 13, fontWeight: "normal" }}>TASK</div>
              </div>
              :
              <ArrowUpIcon classes='icon-up' />) :
            <ArrowDownIcon fill="#bcbccb" classes='icon-down' />)
            :
            (detailEditable ?
              <div id="task-title-start" style={{
                display: "flex", alignItems: "center",
                justifyContent: "center", width: 50, height: 25, backgroundColor: "#295c9f", marginLeft: 10, borderRadius: 5
              }} >
                <div style={{ color: "white", fontSize: 13, fontWeight: "normal" }}>TASK</div>
              </div>
              :
              <RemoveIcon fill="#bcbccb" classes='icon-down' />)}

          {taskNameEditable ?
            <TextField classes="task-name-edit"
              value={task.new_name || task.name}
              placeholder="세부 TASK를 입력하세요."
              onChange={(e) => { handleTaskNameChange(e.target.value) }}
            />
            :
            <div className='task-name'>{task.name}</div>}
          {
            detailEditable &&
            <div /*style={{ width: 140, position: "relative", display: "flex", height: 45, }}*/
              className='task-select-all'
            >
              <SelectAccount
                memberList={memberList}
                selected={undefined}
                allFlag={true}
                onSelected={(index) => {
                  handleAccountAllChange(memberList[index])
                }}
              />
              {/* <div style={{ position: "absolute", backgroundColor: "#F5F6Fa", width: 80, height: 35, left: 0, top: 5, display: "flex", alignItems: "center" }}>
                <span style={{ padding: 7, color: "#c4c4c4", verticalAlign: "middle" }}>

                  일괄 선택
                </span>
              </div> */}
            </div>
          }
          {taskEditable && (
            <Fragment>
              <div className="icon-request" />
              <IconButton classes="icon-request" onClick={handleTaskRequest} disabled={task.sub_detail_list ? isProjectDisabled : true}
              >
                <ValidationIcon size="lg" color={"#292929"} />
              </IconButton>


              <IconButton classes="icon-edit" disabled={isProjectDisabled} onClick={handleTaskEdit}>
                <EditIcon color={"#292929"} />
              </IconButton>
              {!taskDeletable ?
                <div className="icon-trash" />
                : <IconButton classes="icon-trash"
                  onClick={(e) => handleDeleteTask(e)}>
                  <TrashIcon fill="none" stroke={"#292929"} />
                </IconButton>}
            </Fragment>
          )
          }
        </div>
      </div>
      {detailVisible &&
        <div className="task-detail">
          {task.sub_detail_list?.map((detail, index) => {
            return (
              <TaskDetail
                key={"taskElement" + index}
                id={"taskElement" + index}
                lastItem={task.sub_detail_list?.length === index + 1 || index > 3 ? true : false}
                detailDto={detail}
                memberList={memberList}
                editable={detailEditable}
                onDetailClick={() => onDetailClick && onDetailClick(task.name, detail.name)}
                onDetailChange={(value) => handleDetailChange(value, index)}
                onDetailDelete={() => handleDetailDelete(index)}
                onDetailMyFavorite={() => handleMyFavorite(index)}
              />
            )
          })}

        </div>
      }
      { // 위의 테스트 결과를 가지고 만들었음
        detailEditable && task.detail_list?.map((detail, index) => {
          let pos: number = 51.5 + (index * 45)
          let height: number = 67.5 + (index * 45)
          let d: string = "M 16 16 L  16 " + pos + " L 50.546875 " + pos + " 50.546875 " + pos
          let top: number = -74 - ((task.detail_list.length - 1) * 45)
          return (
            <div style={{ position: "absolute", zIndex: 0 }}>
              <svg width={111.40625} height={height} overflow={"auto"}
                style={{ position: "absolute", left: 17.4531, top: `${top}`, pointerEvents: "none" }}>
                <path d={d} stroke="#295c9f" stroke-dasharray="0 0" stroke-width="2" fill="transparent" pointer-events="visibleStroke">
                </path>
              </svg>
            </div>
          )
        })

      }


    </div>
  )
}


interface TaskDetailProps extends CommonProps {
  detailDto: DetailDto;
  memberList: MemberDto[];
  editable: boolean;
  lastItem?: boolean;
  taskName?: string;
  onDetailClick?: () => void,
  onDetailDelete?: () => void,
  onDetailMyFavorite?: () => void,
  onDetailChange?: (detail: DetailDto) => void,
  id?: string;
}

const TaskDetail: React.FC<TaskDetailProps> = ({
  detailDto,
  memberList,
  editable = false,
  lastItem = false,
  taskName,
  onDetailClick,
  onDetailDelete,
  onDetailChange,
  onDetailMyFavorite,
  id,
}) => {
  const { confirmWithWarn, alert2 } = useAlert();
  const { projectInfo } = useContext(ProjectContext);
  let { projectId, category } = useParams<{ projectId: string, category: string }>();
  const handleNameChange = (name: string) => {
    onDetailChange && onDetailChange({
      ...detailDto,
      name
    })
  }

  const handleAccountChange = (accountInfo: { account: string, nickname: string }) => {
    onDetailChange && onDetailChange({
      ...detailDto,
      responsibility: {
        account: accountInfo?.account,
        nickname: accountInfo?.nickname,
      }
    })
  }

  const handleDetailDelete = () => {
    if (detailDto.name?.length === 0) {
      onDetailDelete && onDetailDelete()
    } else {
      confirmWithWarn('세부 TASK삭제', '세부 TASK "' + detailDto.name +
        (detailDto.name?.length > 30 ? '" 을 \n 정말 삭제하시겠습니까?' : '" 을 정말 삭제하시겠습니까?'), (result) => {
          //audit 검사
          const requestData = createTemplate(projectInfo.name, category.toUpperCase(), taskName);
          requestData.category_list[0].task_list[0].detail_list = [detailDto];
          console.log("requestData : ", requestData)
          templateApi
            .hasAudit(requestData)
            .then(resp => {
              console.log("resp : ", resp)
              if (resp?.has_audit === true) {
                alert2("세부 TASK삭제", `세부 TASK [${detailDto.name}]에 조서가 존재합니다.\n조서가 모두 지워지지 않으면 삭제할 수 없습니다.`)
              } else {
                result && onDetailDelete && onDetailDelete();
              }
            })
            .catch(err => {
              alert2("세부 TASK삭제", err)
            })
        })
    }
  }

  const handleClickMyFavorite = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    //즐겨찾기 추가 및 제거
    onDetailMyFavorite && onDetailMyFavorite()
  }

  // console.log("detailDto : ", detailDto)
  let deletable = (detailDto as SpecialDetailDto).sub_task_type ? false : true;
  const isProjectDisabled = projectApi.isProjectDisabled();
  return (
    <div className="detail-element element"
      onClick={() => onDetailClick && onDetailClick()}>
      <div className="detail-content title" style={editable ? { width: 660 } : {}}>
        {editable || isProjectDisabled ? <DotIcon classes="dot-icon" color={'#295c9f'} />
          :
          <div style={{ marginRight: 10 }} onClick={handleClickMyFavorite}>
            <div >
              <img src={detailDto?.is_favorite ? require("src/resources/images/Icon_Star.png") : require("src/resources/images/Icon_Star_d.png")}
                style={{ width: 16, height: 16 }}
              />
            </div>
          </div>
        }
        <span id={id}></span>
        {editable ?
          <TextField
            value={detailDto.name}
            placeholder="세부 TASK를 입력하세요."
            autoFocus={!detailDto?.name ? true : false}
            readOnly={!deletable ? true : false}
            onChange={(e) => { handleNameChange(e.target.value) }}
          />
          :
          <span>{detailDto.name}</span>
        }
      </div>
      {editable ?
        <SelectAccount
          memberList={memberList}
          selected={detailDto.responsibility?.account}
          selectedNickName={detailDto.responsibility?.nickname}
          lastItem={lastItem}
          onSelected={(index) => {
            handleAccountChange(memberList[index])
          }}
        />
        :
        <div className="detail-account account" style={detailDto.responsibility?.account ? {} : { color: "grey" }}>
          {detailDto.responsibility?.account ?
            detailDto.responsibility?.nickname ? detailDto.responsibility?.nickname : detailDto.responsibility?.account
            : "미지정"
          }
        </div>
      }
      <div className="detail-status">
        {detailDto.status ?
          <div className="status-root">
            <div className="status-normal" style={{ backgroundColor: statusColor[`${detailDto.status}`] }}>
            </div>
            <div>
              {detailDto.status}
            </div>
          </div>
          :
          <div className="status-root">
            <div className="status-none">
            </div>
            <div style={{ color: "#cecfd0" }}>
              {"미진행"}
            </div>
          </div>
        }
      </div>
      {editable && deletable &&
        <IconButton classes="icon-trash"
          onClick={handleDetailDelete}
        >
          <TrashIcon fill="none" stroke="#292929" />
        </IconButton>
      }
    </div>
  )
}

interface SelectAccountProps extends CommonProps {
  memberList: MemberDto[];
  selected?: string;
  selectedNickName?: string;
  allFlag?: boolean;
  lastItem?: boolean;
  onSelected?: (value: number) => void;
}

export const SelectAccount: React.FC<SelectAccountProps> = ({
  memberList,
  selected,
  selectedNickName,
  allFlag = false,
  lastItem = false,
  onSelected,
}) => {
  let excludedMember = "";
  if (selectedNickName) {
    if (memberList.filter(item => item.nickname === selectedNickName).length === 0) {
      excludedMember = selectedNickName
    }
  }

  return (
    <Select
      vertical={lastItem ? "up" : "down"}
      width={120}
      selectFirstItem={false}
      selectedEventKey={selected}
      placeholder={allFlag ? '일괄선택' : '담당자선택'}
      placeholder2={excludedMember ? excludedMember : undefined}
      textAlign='center'
      align='left'
      border={false}
      onClick={(value) => {
        // value는 eventKey 값이다.
        let index = memberList.findIndex(i => i.account === value)
        onSelected && onSelected(index)
      }}
    >
      {memberList && memberList.map((elem) => (
        <SelectItem key={elem.account} eventKey={elem.account} >
          {elem.nickname}
        </SelectItem>
      ))
      }
    </Select >
  )
}
export default TaskElement;