import React, { useEffect, useState, useContext } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import Button from 'src/components/atom/Button';
import Table from 'src/components/atom/Table';
import TextField from 'src/components/atom/TextField';
import FormGroup from 'src/components/atom/FormGroup';
import RadioGroup from 'src/components/atom/RadioGroup';
import Radio from 'src/components/atom/Radio';
import EditableSelect, { EditableSelectItem } from 'src/components/atom/EditableSelect';
import update from 'immutability-helper';
import _, { template } from 'lodash';

import { CommonProps } from 'src/components/helpers/props';
import { useAlert } from 'src/contexts/AlertContext';
import Popup2 from 'src/components/molecules/Popup2';
import useSearchBar from 'src/hooks/useSearchBar';
import SearchBar from 'src/components/molecules/SearchBar';
import ScrollBox from 'src/components/atom/ScrollBox';
import { UserInfoEntity } from 'src/api/userSettingApi';
import Checkbox from 'src/components/atom/Checkbox';
import { RoleDto, PermissionDto } from 'src/api/roleApi';
import role from 'src/store/fidp/role';
import { boolean } from '@storybook/addon-knobs';
import templateApi, { TemplateDto, TaskDto, DetailDto, createDetail, createTemplate } from 'src/api/templateApi';
import useInputs from 'src/hooks/useInputs';
import AddCircle2Icon from 'src/components/atom/icons/AddCircle2Icon';

import { validateAll, validateAll2 } from 'src/shared/utils/validations';
import TaskElement from './TaskElement';
// import { ProjectContext } from '../Category';
import './TaskPopup.scss';

interface TaskPopupProps extends CommonProps {
  // dtoList: UserInfoEntity[];
  // roleList: RoleDto[];
  // permissionList: PermissionDto[];
  taskDto: TaskDto;
  open: boolean;
  addable?: boolean;
  onClose: (taskDto: TaskDto) => void;
  // selected: UserInfoEntity[];
}


const TaskPopup: React.FC<TaskPopupProps> = ({
  taskDto,
  open,
  onClose,
  addable = true,
  classes,
  // roleList,
  // permissionList,
}) => {
  const { t } = useTranslation(['commons']);
  let { projectId, category, taskName, detailName } = useParams<{ projectId: string, category: string, taskName: string, detailName: string }>();
  detailName = detailName && decodeURIComponent(detailName);
  const [updateTask, setUpdateTask] = useState<TaskDto>(taskDto);
  const { alert2 } = useAlert();
  // const [ addList, setAddList ] = useState<DetailDto[]>([]);

  // const { projectInfo } = useContext(ProjectContext);



  // console.log('projectId', projectId)

  const validationRules = {
    // id: _.trim(project.id).length > 0 && validationId(project.id),
    // name: _.trim(form.name).length > 0,
    // projectRole: selectedUsers.length > 0,
    name: updateTask?.detail_list?.every((item: any) => item?.name?.length > 0),
  };

  const handleOk = (): void => {
    //close할때 update된 데이터 넘겨서 서버에 저장함.
    const r = validateAll2(validationRules)
    if (!r.retVal) {
      if (r.key === "name") {
        alert2("TASK 수정", "TASK명은 필수입니다.");
      }
      return;
    }
    onClose(updateTask);
  };
  const handleCancel = (): void => {
    onClose({} as TaskDto);
  };

  const handleEditTask = (task: TaskDto) => {
    setUpdateTask(task);
  }

  // const handleEditAddList = (list: DetailDto[]) => {
  //   setAddList(list);
  // }

  const handleDeleteTask = () => {
    // templateApi.deleteTask

  }

  const handleDetailAdd = () => {
    setUpdateTask({
      ...updateTask,
      detail_list: updateTask.detail_list ? updateTask.detail_list.concat(createDetail()) : [createDetail()]
    })

    // setAddList(addList.concat(createDetail()));
  }

  // console.log("updateTask : ", updateTask)

  return (
    <Popup2
      classes="task-popup-root project-popup-root project-common"
      title="TASK 수정"
      width="1112px"
      height="692px"
      open={open}
      onClose={handleCancel}
      footer={
        <React.Fragment>
          {addable && <div className="detail-add">
            <div className="detail-add-button"
              onClick={handleDetailAdd}
            >
              <AddCircle2Icon color="#295c9f" />
              <span>세부 TASK 추가</span>
            </div>
          </div>}
          <Button text={t('commons:cancel')} onClick={handleCancel} color="secondary" />
          <Button
            text={t('commons:save')}
            onClick={handleOk}
          //disabled={updateTask.detail_list ? false : true}
          />



        </React.Fragment>
      }
    >
      <div className="task-header element">
        <div className="title-content title" style={{ width: 660, color: "#a3a6b4" }}>내용</div>
        <div className="title-account account" style={{ width: 130, color: "#a3a6b4" }}>담당자</div>
        <div className="title-status status" style={{ width: 270, color: "#a3a6b4" }}></div>
      </div>
      <TaskElement task={updateTask}
        showDetail={true}
        taskEditable={false}
        taskNameEditable={true}
        detailEditable={true}
        // addList={addList}
        onEditTask={handleEditTask}
        // onEditAddList={handleEditAddList}
        onDeleteTask={handleDeleteTask}
      />

    </Popup2>
  );
};

export default TaskPopup;
