import React from 'react';
import classNames from 'classnames';

import { CommonProps } from 'src/components/helpers/props';

import './AbstractIcon.scss';

interface ArrowLeftIconProps extends CommonProps {
  size?: 'md';
}

const ArrowLeftIcon: React.FC<ArrowLeftIconProps> = ({ classes, size = 'md' }) => {
  return (
    <svg
      className={classNames('icon-root', classes)}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="#666666"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M9 12L15 8L15 16L9 12Z" />
    </svg>
  );
};

export default ArrowLeftIcon;
