import React, { useEffect, useState } from 'react';

import { useAlert } from 'src/contexts/AlertContext';
import classNames from 'classnames';
import EditIcon from 'src/components/atom/icons/EditIcon';
import IconButton from 'src/components/atom/IconButton';
import usePopup from 'src/hooks/usePopup';
import Button from 'src/components/atom/Button';
import Checkbox from 'src/components/atom/Checkbox';
import _ from 'lodash';
import './Step2.scss';
import Step2EditPopup from './Step2EditPopup';
import projectApi, { ProjectDto, emptyProject } from 'src/api/projectApi';

export interface tranTypeList {
  name: string,
  checked: boolean,
}

interface detailSubListType {
  name: string,
  transaction_type_list: tranTypeList[]
}

const DetailTaskLeftElement: React.FC<{
  detailContent: detailSubListType,
  idx: number,
  checked: boolean,
  onHandleClick: (item: any) => void;
}> = ({
  detailContent,
  idx,
  checked,
  onHandleClick,
}) => {

    let checkCount = 0;
    detailContent?.transaction_type_list?.forEach((d: any) => { if (d.checked) checkCount++ })

    return (
      <div className={classNames("sp-ma-step2-account", { "sp-ma-step2-account-checked": checked })}
        onClick={() => onHandleClick(idx)}
      >
        <div>{`${detailContent.name}(${checkCount})`}</div>
      </div>
    )
  }


const DetailTaskRightElement: React.FC<{
  detailContent: detailSubListType,
  onHandleChange: (item: any) => void;
}> = ({
  detailContent,
  onHandleChange,
}) => {
    const onChange = (i: number) => {
      onHandleChange(i)
    }
    // console.log("detailContent :", detailContent)
    const isProjectDisabled = projectApi.isProjectDisabled();
    return <div className="sp-ma-step2-account-detail-root">
      {detailContent?.transaction_type_list?.map((elem: any, i: number) => {
        return (
          <div className="sp-ma-step2-account-detail" key={i.toString()}>
            <div className="select">
              <Checkbox classes="date-checkbox" disabled={isProjectDisabled} checked={elem.checked} onChange={() => onChange(i)} />
            </div>
            <div className="wcgw">
              <div style={{ marginLeft: 10 }}>{elem.name}</div>
            </div>
          </div>
        )
      })}
    </div>
  }

const Step2: React.FC<{
  detailTaskName: string,
  detailSubList: detailSubListType[],
  requirements: string,
  handleChange: (html: string) => void;
  handleCancelRequirement: () => void;
  handleConfirmRequirement: () => void;
  handleConfirmDetail?: (opinions: any) => void;
}> = ({
  detailTaskName,
  detailSubList,
  requirements,
  handleChange,
  handleCancelRequirement,
  handleConfirmRequirement,
  handleConfirmDetail = () => { }
}) => {
    const [accountIdx, setAccountIdx] = useState<number>(0);
    const [accountInfo, setAccountInfo] = useState<detailSubListType[]>([]);
    const step2EditPopup = usePopup();
    const onHandleClick = (idx: number) => {
      // props로 넘어온 데이터와 state 데이터가 다르면 state를 props 데이터로 원복??
      if (JSON.stringify(detailSubList) !== JSON.stringify(accountInfo)) {
        setAccountInfo(_.cloneDeep(detailSubList))
      }
      setAccountIdx(idx)
    }
    const onHandleChange = (i: number) => {
      let newAccountInfo: detailSubListType[] = _.cloneDeep(accountInfo);
      newAccountInfo[accountIdx].transaction_type_list[i].checked = !newAccountInfo[accountIdx].transaction_type_list[i].checked;
      setAccountInfo(newAccountInfo);
      console.log("newAccountInfo : ", newAccountInfo)
    }

    const handleClose = () => {
      handleConfirmDetail(null);
      step2EditPopup.closePopup();
    }

    const handleSave = () => {
      console.log("중간 감사 스텝2 저장 데이터 : ", accountInfo, accountIdx)
      handleConfirmDetail([accountInfo[accountIdx]])
    }

    useEffect(() => {
      setAccountInfo(detailSubList)
    }, [detailSubList]);

    const renderDetailLeftBody = () => {
      return accountInfo?.map((elem: detailSubListType, idx: number) => {
        return <DetailTaskLeftElement key={'detailTaskElement' + idx}
          detailContent={elem}
          idx={idx}
          checked={idx === accountIdx}
          onHandleClick={onHandleClick}
        />
      })
    }

    const renderDetailRightBody = () => {
      if (!accountInfo || accountInfo[accountIdx] === undefined) {
        return <></>
      }
      return <DetailTaskRightElement
        detailContent={accountInfo[accountIdx]}
        onHandleChange={onHandleChange}
      />
    }
    const isProjectDisabled = projectApi.isProjectDisabled();
    return (
      <div className="sp-ma-step2-detail-body">
        <div className="sp-ma-step2-detail-body-requirements">
          <div className="sp-ma-step2-detail-left-body">
            <div className="header element" style={{ paddingLeft: 10 }}>
              <div className="account">주요 거래 유형</div>
            </div>
            <div className="data-body">
              {renderDetailLeftBody()}
            </div>
          </div>
          <div className="sp-ma-step2-detail-right-body">
            <div className="header element" style={{ paddingLeft: 10 }}>
              <div className="account">중요왜곡표시위험</div>
              <div className="strategy"></div>
              <div className="it-app"></div>
              <div className="account-edit-button-root">
                <IconButton classes="account-edit-button" disabled={isProjectDisabled} onClick={(e) => { step2EditPopup.openPopup() }}>
                  <EditIcon size="sm" />
                </IconButton>
              </div>
            </div>

            <div className="data-body">
              {renderDetailRightBody()}
            </div>
          </div>
        </div>
        <div className="sp-ma-step2-detail-footer common-page-footer project-footer">
          <div className={classNames('right-button')} style={{ width: 200 }}>
            <Button disabled={isProjectDisabled} classes="footer-button" text="저장" onClick={handleSave} />
          </div>
        </div>
        {step2EditPopup.opened &&
          <Step2EditPopup
            open={step2EditPopup.opened}
            onClose={handleClose}
            onConfirm={() => { }}
          />
        }
      </div>)
  }

export default Step2;