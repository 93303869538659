import React from 'react';
import ReactDOM from 'react-dom';

const ModalPortal = ({ children }: { children: React.ReactNode }): React.ReactPortal | null => {
  const el = document.getElementById('modal') || null;
  if (el) return ReactDOM.createPortal(children, el);
  else return null;
};

export default ModalPortal;
