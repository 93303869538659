import React, { useState, useCallback } from 'react';
import _ from 'lodash';

export default function useSearchBar<E, KP extends keyof E>({
  entityList,
  keys,
}: {
  entityList: E[];
  keys: KP[];
}): {
  filteredEntityList: E[];
  searchText: string;
  searchTarget: 'all' | KP;
  handleChangeSearchText: (str: string) => void;
  handleChangeSearchTarget: (eventKey: string) => void;
} {
  const [searchText, setSearchText] = useState('');
  const [searchTarget, setSearchTarget] = useState<'all' | KP>('all');

  const filteredEntityList = searchText
    ? entityList.filter((entity) => {
        const targets = searchTarget === 'all' ? keys : [searchTarget];
        return targets.some((t) => {
          const value = entity[t];
          if (typeof value === 'string') {
            return value.includes(searchText);
          } else {
            return false;
          }
        });
      })
    : entityList;

  const debouncedHandleChangeSearchText = useCallback(
    _.debounce((str: string) => setSearchText(str), 500),
    []
  );

  const handleChangeSearchText = useCallback(
    (str: string): void => {
      debouncedHandleChangeSearchText(str);
    },
    [debouncedHandleChangeSearchText]
  );
  const handleChangeSearchTarget = useCallback(
    (eventKey: string): void => {
      if (eventKey === 'all') {
        setSearchTarget('all');
      } else if (keys.includes(eventKey as KP)) {
        setSearchTarget(eventKey as KP);
      }
    },
    [keys]
  );

  return {
    filteredEntityList,
    searchText,
    searchTarget,
    handleChangeSearchText,
    handleChangeSearchTarget,
  };
}
