import React, { useEffect, useState } from 'react';
import {
  Switch as RouterSwitch,
  Route,
  Redirect,
  NavLink as RouterLink,
  useHistory,
  useParams,
} from 'react-router-dom';
import projectApi2 from 'src/api/projectApi2';
import { CommonProps } from 'src/components/helpers/props';
import useProjectInfo from 'src/hooks/useProjectInfo';
import useMyProjectResource from 'src/hooks/useMyProjectResource';
import { RouteTable } from 'src/shared/routes';
import { getAdidManagerUser } from 'src/shared/utils/sessionStorageManager';
import ProjectBasePage from './ProjectBasePage';
import ProjectDetailPage from './ProjectDetailPage';
import useAuthStore from 'src/hooks/auth/useAuthStore';
import { ProjectDto } from 'src/api/projectApi';

interface ProjectPageProps extends CommonProps {
  routeTables: RouteTable[];
}
const ProjectPage: React.FC<ProjectPageProps> = ({ routeTables }) => {
  const { projectId } = useParams<{ projectId: string }>();
  const userId = getAdidManagerUser() || 'admin'; // TODO redux id
  const { authEntity } = useAuthStore();
  const [reload, setReload] = useState<boolean>(false)

  const { loading: loadingProjectDto, projectName, projectInfo } = useProjectInfo(projectId, userId, reload);
  const { loading: loadingProjectResource, projectResourceIdSet } = useMyProjectResource(projectId);

  return (
    <RouterSwitch>
      {routeTables.map((routeTable) => {
        return <Route key={routeTable.fullPath} path={routeTable.fullPath} exact>
          <ProjectPageInside
            loading={loadingProjectDto || loadingProjectResource}
            routeTable={routeTable}
            projectId={projectId}
            projectName={projectName}
            projectInfo={projectInfo}
            setReload={setReload}
            userPermissions={
              authEntity !== undefined && authEntity.permissions !== undefined
                ? authEntity.permissions
                : []
            }
            projectResourceIdSet={projectResourceIdSet}
          />
        </Route>
      })}
      <Route>TODO: ERROR 2</Route>
    </RouterSwitch>
  );
};

interface ProjectPageInsideProps extends CommonProps {
  loading: boolean;
  routeTable: RouteTable;
  projectId: string;
  projectName: string;
  projectInfo: ProjectDto;
  userPermissions: string[];
  projectResourceIdSet: Set<string>;
  setReload: (value: boolean) => void
}
const ProjectPageInside: React.FC<ProjectPageInsideProps> = ({
  loading,
  routeTable,
  projectId,
  projectName,
  projectInfo,
  userPermissions,
  projectResourceIdSet,
  setReload
}) => {
  if (!routeTable.component) {
    return <React.Fragment>TODO: ERROR 3</React.Fragment>;
  }
  // if (loading) {
  //   return null;
  // }

  const path = routeTable.fullPath; // currentUrlUtils.getPath();
  const isProjectAssociated = /^\/projects\/.+/.test(path);
  const isProjectDetailAssociated =
    /^\/projects\/.+?\/audit\/.+/.test(path);


  if (isProjectDetailAssociated) {
    return (
      <ProjectDetailPage projectId={projectId} projectName={projectName}>
        <routeTable.component />
      </ProjectDetailPage>
    );
  } else if (isProjectAssociated) {
    return (
      <ProjectBasePage
        projectId={projectId}
        projectName={projectName}
        projectInfo={projectInfo}
        userPermissions={userPermissions}
        projectResourceIdSet={projectResourceIdSet}
      >
        <routeTable.component projectInfo={projectInfo} setReload={setReload} />
      </ProjectBasePage>
    );
  } else {
    return <React.Fragment>TODO: ERROR 4</React.Fragment>;
  }

};

export default ProjectPage;
