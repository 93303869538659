import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
// import classNames from 'classnames';

import './index.scss';

const Redirect: React.FC = () => {
  const { result, user } = useParams<{ result: string; user: string }>();
  let msg = '';
  if (result === 'success') {
    msg = user + ' 승인 완료';
  } else if (result === 'failedAlreadyApproved') {
    msg = '이미 승인 되었습니다';
  } else {
    msg = '승인 실패';
  }
  // result === 'success' ? user + ' 승인 완료' : '승인 실패'
  return <div>{msg}</div>;
};

export default Redirect;
