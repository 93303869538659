import _ from 'lodash';

import config from 'src/shared/config';
import axios from 'src/shared/utils/axios';

import DummyApi from '../dummyApi';
import { times } from '../api';

const FIDP_API_ENABLED = config.servers.fidp.enabled;
const FIDP_API_URL = config.servers.fidp.url;

export interface RoleDto {
  dsdCode: string; // 도메인 코드
  code: string; // 직급 코드
  name: string; // 직금 이름
  order: number; // 직급 순서
  linkedYN: 'Y' | 'N'; // 연동 직급 여부
  updatedTime: number; // 직급 갱신 일시
}

export type RoleReqDto = Omit<RoleDto, 'code' | 'entryTs'>;

interface RoleApi {
  list(): Promise<RoleDto[]>;
}

class RoleServerApi implements RoleApi {
  async list(): Promise<RoleDto[]> {
    const resp = await axios.get<RoleDto[]>(`${FIDP_API_URL}/api/organs/roles`);
    return resp.data;
  }
}

class RoleDummyApi implements RoleApi {
  private data: RoleDto[];

  constructor() {
    this.data = [
      {
        dsdCode: '0000000000000932',
        code: 'R001',
        name: '일반',
        order: 1,
        linkedYN: 'N',
        updatedTime: 1598111171171,
      },
      {
        dsdCode: '0000000000000932',
        code: 'dd5ea21f87854166bc41f990fea062e0',
        name: 'sparrow8dast2text4',
        order: 2,
        linkedYN: 'N',
        updatedTime: 1597319299947,
      },
      {
        dsdCode: '0000000000000932',
        code: 'b87928b2982e44ba989cf3446413af2d',
        name: '-146009453',
        order: 3,
        linkedYN: 'N',
        updatedTime: 1598098692281,
      },
      {
        dsdCode: '0000000000000932',
        code: '82919fcf11f748afa92e2b8492fd4ec8',
        name: '/etc/passwd',
        order: 4,
        linkedYN: 'N',
        updatedTime: 1598099034972,
      },
      {
        dsdCode: '0000000000000932',
        code: 'bcbece6a1b1647398223617cbc200690',
        name: '../../../../../../../../../../../../../../../../etc/passwd',
        order: 5,
        linkedYN: 'N',
        updatedTime: 1598099041713,
      },
      {
        dsdCode: '0000000000000932',
        code: '990bc523f24349dc9034886c4e39b6bf',
        name: '/../../../../../../../../../../../../../../../../etc/passwd',
        order: 6,
        linkedYN: 'N',
        updatedTime: 1598099048456,
      },
      {
        dsdCode: '0000000000000932',
        code: 'b88e1152f33d4f9aa4556e2b0c3b97d6',
        name: 'etc/passwd',
        order: 7,
        linkedYN: 'N',
        updatedTime: 1598099054856,
      },
      {
        dsdCode: '0000000000000932',
        code: '4a689723dfb54b1ab77d313e0c763cfa',
        name: 'c:/Windows/system.ini',
        order: 8,
        linkedYN: 'N',
        updatedTime: 1598099061360,
      },
      {
        dsdCode: '0000000000000932',
        code: '6c309ea93ef14fdf9d13e76de72da4af',
        name: 'c:\\Windows\\system.ini',
        order: 9,
        linkedYN: 'N',
        updatedTime: 1598099067815,
      },
      {
        dsdCode: '0000000000000932',
        code: '25c2868af945416da8e6d6e07c7aeb74',
        name: '../../../../../../../../../../../../../../../../Windows/system.ini',
        order: 10,
        linkedYN: 'N',
        updatedTime: 1598099074608,
      },
      {
        dsdCode: '0000000000000932',
        code: '5e7e0c2d9c364a808c90ebb8c8d0e7e6',
        name: '..\\..\\..\\..\\..\\..\\..\\..\\..\\..\\..\\..\\..\\..\\..\\..\\Windows\\system.ini',
        order: 11,
        linkedYN: 'N',
        updatedTime: 1598099081439,
      },
      {
        dsdCode: '0000000000000932',
        code: 'bda4af43b2cb4ce781b97c215e90d1fa',
        name: '/../../../../../../../../../../../../../../../../Windows/system.ini',
        order: 12,
        linkedYN: 'N',
        updatedTime: 1598099088231,
      },
      {
        dsdCode: '0000000000000932',
        code: 'a8ff115843cd4321b5d589d7e8ddf4f0',
        name: '(',
        order: 13,
        linkedYN: 'N',
        updatedTime: 1598099241208,
      },
      {
        dsdCode: '0000000000000932',
        code: '72d48b4b38c94dd2bc60b0380d3c0301',
        name: '|',
        order: 14,
        linkedYN: 'N',
        updatedTime: 1598099247522,
      },
      {
        dsdCode: '0000000000000932',
        code: 'ab4772ef30e7485ba28d8bfc8350c732',
        name: '&',
        order: 15,
        linkedYN: 'N',
        updatedTime: 1598099253923,
      },
      {
        dsdCode: '0000000000000932',
        code: '6cac6b7903794b3aaa8a0d3adbea1791',
        name: '*',
        order: 16,
        linkedYN: 'N',
        updatedTime: 1598099260300,
      },
      {
        dsdCode: '0000000000000932',
        code: 'e758bde6b72042e09e145b0ab701e417',
        name: "'",
        order: 17,
        linkedYN: 'N',
        updatedTime: 1598099415419,
      },
      {
        dsdCode: '0000000000000932',
        code: '40438c2fdde041389b01ff50d6a4c091',
        name: '1\'"',
        order: 18,
        linkedYN: 'N',
        updatedTime: 1598099421902,
      },
      {
        dsdCode: '0000000000000932',
        code: '53bbe28cbcf24419a40896571186ff9a',
        name: '--',
        order: 19,
        linkedYN: 'N',
        updatedTime: 1598099428304,
      },
      {
        dsdCode: '0000000000000932',
        code: '851997bdc0f442f3a65b77b57d144f54',
        name: '=<>',
        order: 20,
        linkedYN: 'N',
        updatedTime: 1598099434693,
      },
      {
        dsdCode: '0000000000000932',
        code: '2e50474136ef45788addde585620794a',
        name: '/WEB-INF/web.xml',
        order: 21,
        linkedYN: 'N',
        updatedTime: 1598099568874,
      },
      {
        dsdCode: '0000000000000932',
        code: '0f65fabc15e347c0b393e8ae956d78af',
        name:
          "//--></SCRIPT>\\\">'>a1s2d3f4g5<sCrIpT>window.open('about:blank','-146009453');</ScRiPt>a1s2d3f4g5",
        order: 22,
        linkedYN: 'N',
        updatedTime: 1598099735524,
      },
      {
        dsdCode: '0000000000000932',
        code: '039594d3d3c9455d9864e514d540e7b6',
        name:
          "//--></SCRIPT>\\\">'>a1s2d3f4g5<IMG SRC=/ ONERROR=\"window.open('about:blank','-146009453')\"></IMG>a1s2d3f4g5",
        order: 23,
        linkedYN: 'N',
        updatedTime: 1598099742600,
      },
      {
        dsdCode: '0000000000000932',
        code: '2964d4a11d444b1e92207f05283a8995',
        name:
          "//--></SCRIPT>\\\">'>a1s2d3f4g5<IFRAME SRC=javascript:window.open('about:blank','-146009453')></IFRAME>a1s2d3f4g5",
        order: 24,
        linkedYN: 'N',
        updatedTime: 1598099749712,
      },
      {
        dsdCode: '0000000000000932',
        code: 'f7ad33c47d9f474393ecc2511b972e5b',
        name:
          "//--></SCRIPT>\\\">'>a1s2d3f4g5<BODY ONLOAD=window.open('about:blank','-146009453')>a1s2d3f4g5",
        order: 25,
        linkedYN: 'N',
        updatedTime: 1598099756668,
      },
      {
        dsdCode: '0000000000000932',
        code: 'bc95a98a8ceb405abc0fc354b6098d9e',
        name: '123CMDI456" | netstat -ano && "123CMDI456',
        order: 26,
        linkedYN: 'N',
        updatedTime: 1598099893915,
      },
      {
        dsdCode: '0000000000000932',
        code: '10c8586ed1bf42889ecd38f4d6789044',
        name: '123CMDI456" ; netstat -ntlp ; "123CMDI456',
        order: 27,
        linkedYN: 'N',
        updatedTime: 1598099901390,
      },
      {
        dsdCode: '0000000000000932',
        code: '60cb11a832594a5596a2486444accd4b',
        name: 'http://www.sparrowfasoo.com/?dastEventHash=-146009453',
        order: 28,
        linkedYN: 'N',
        updatedTime: 1598099994917,
      },
    ];
  }

  list(): Promise<RoleDto[]> {
    return Promise.resolve(_.cloneDeep(this.data));
  }
}

const roleApi: RoleApi = FIDP_API_ENABLED ? new RoleServerApi() : new RoleDummyApi();

export default roleApi;
