import config from 'src/shared/config';
import axios from 'src/shared/utils/axios';

import DummyApi from './dummyApi';
import { times } from './api';

const FSP_API_ENABLED = config.servers.fsp.enabled;
const FSP_API_URL = config.servers.fsp.url;

export enum LogType {
  NONE = 'NONE', //전송 안 함
  ALL = 'ALL', //전체
  FIRST = 'FIRST', //첫 페이지만
  ODD = 'ODD', //홀수 페이지만
  EVEN = 'EVEN', //짝수 페이지만
  PER = 'PER', //n페이지 마다
  TO = 'TO', //첫 페이지부터 n페이지 까지
}

export enum PrintApprovalType {
  BLOCK = 'BLOCK', //인쇄 차단
  ALLOW = 'ALLOW', //인쇄 허용
  APPROVAL = 'APPROVAL',
  PRE_APPROVAL = 'PRE_APPROVAL', //사전 승인
  POST_APPROVAL = 'POST_APPROVAL', //사후 승인
}

export interface DetectionTargetDto {
  detectionRuleCode: string; // 검출 기준 코드
  watFileCode: string; // 워터마크 파일 코드
  printType: PrintApprovalType; // 인쇄 제어 방식
  printMasking: boolean; // 출력물 마스킹 여부
  printMaskingExcept: boolean; // 출력물 마스킹 예외 신청 허용 여부
  logMasking: boolean; // 로그 마스킹 사용 여부
  watPrint: boolean; // 워터마크 인쇄 사용 여부
  offlinePrint: boolean; // 오프라인 인쇄 허용 여부
  printReason: boolean; // 인쇄 사유 입력 사용 여부
  priority: number; // 우선순위
}

export interface DetectionTemplateDto {
  code: string;
  name: string;
  description: string;
  use: boolean;
  entryTs: number;

  log: boolean; // 검출 로그 저장 여부
  txtLogType: LogType; // 텍스트 로그 형식
  txtLogValue: number; // 텍스트 로그 값
  imgLogType: LogType; // 이미지 로그 형식
  imgLogValue: number; // 이미지 로그 값
  mailNotice: boolean; // 검출 내역 알림 메일 전송 여부
  mailNoticeThreshold: number; // 검출 내역 알림 메일 전송 임계값
  mailReceipient: string[]; // 검출 내역 메일 수신자
  targetList: DetectionTargetDto[]; // 검출 템플릿 적용 대상 검출 기준 리스트
}

export type DetectionTemplateReqDto = Omit<DetectionTemplateDto, 'code' | 'entryTs'>;

export const defaultDetectionTemplateReqDto: DetectionTemplateReqDto = {
  name: '',
  description: '',
  use: true,

  log: true,
  txtLogType: LogType.NONE,
  txtLogValue: 0,
  imgLogType: LogType.NONE,
  imgLogValue: 0,
  mailNotice: false,
  mailNoticeThreshold: 1,
  mailReceipient: [],
  targetList: new Array<DetectionTargetDto>(),
};

interface DetectionTemplateApi {
  list(): Promise<DetectionTemplateDto[]>;
  find(code: string): Promise<DetectionTemplateDto>;
  create(reqDto: DetectionTemplateReqDto): Promise<DetectionTemplateDto>;
  update(code: string, reqDto: DetectionTemplateReqDto): Promise<DetectionTemplateDto>;
  remove(code: string): Promise<void>;
}
class DetectionTemplateServerApi implements DetectionTemplateApi {
  async list(): Promise<DetectionTemplateDto[]> {
    const resp = await axios.get<DetectionTemplateDto[]>(`${FSP_API_URL}/api/detections/templates`);
    return resp.data;
  }
  async find(code: string): Promise<DetectionTemplateDto> {
    const resp = await axios.get<DetectionTemplateDto>(
      `${FSP_API_URL}/api/detections/templates/${code}`
    );
    return resp.data;
  }
  async create(reqDto: DetectionTemplateReqDto): Promise<DetectionTemplateDto> {
    const resp = await axios.post<DetectionTemplateDto>(
      `${FSP_API_URL}/api/detections/templates`,
      reqDto
    );
    return resp.data;
  }
  async update(code: string, reqDto: DetectionTemplateReqDto): Promise<DetectionTemplateDto> {
    const resp = await axios.put<DetectionTemplateDto>(
      `${FSP_API_URL}/api/detections/templates/${code}`,
      reqDto
    );
    return resp.data;
  }
  async remove(code: string): Promise<void> {
    await axios.delete<DetectionTemplateDto>(`${FSP_API_URL}/api/detections/templates/${code}`);
  }
}

class DetectionTemplateDummyApi implements DetectionTemplateApi {
  dummyApi: DummyApi<DetectionTemplateDto, DetectionTemplateReqDto>;
  constructor() {
    const data = [
      {
        name: '자동화 FSP 검출 템플릿',
        description: '',
        use: true,
        log: true,
        txtLogType: LogType.ALL,
        txtLogValue: 0,
        imgLogType: LogType.ALL,
        imgLogValue: 0,
        mailNotice: false,
        mailNoticeThreshold: 0,
        mailReceipient: [],
        targetList: [
          {
            detectionRuleCode: 'code-1',
            watFileCode: 'code-1',
            printType: PrintApprovalType.ALLOW,
            printMasking: true,
            printMaskingExcept: false,
            logMasking: true,
            watPrint: false,
            offlinePrint: true,
            printReason: false,
            priority: 1,
          },
        ],
      },
      {
        name: '혜지_검출',
        description: '',
        use: true,
        log: true,
        txtLogType: LogType.ALL,
        txtLogValue: 0,
        imgLogType: LogType.NONE,
        imgLogValue: 0,
        mailNotice: true,
        mailNoticeThreshold: 2,
        mailReceipient: ['DEPT'],
        targetList: [
          {
            detectionRuleCode: 'code-1',
            watFileCode: 'code-1',
            printType: PrintApprovalType.ALLOW,
            printMasking: true,
            printMaskingExcept: false,
            logMasking: true,
            watPrint: false,
            offlinePrint: true,
            printReason: false,
            priority: 1,
          },
          {
            detectionRuleCode: 'code-2',
            watFileCode: 'code-2',
            printType: PrintApprovalType.ALLOW,
            printMasking: true,
            printMaskingExcept: false,
            logMasking: true,
            watPrint: false,
            offlinePrint: true,
            printReason: false,
            priority: 2,
          },
        ],
      },
      {
        name: '혜지_검출 2',
        description: '',
        use: false,
        log: true,
        txtLogType: LogType.ALL,
        txtLogValue: 0,
        imgLogType: LogType.ALL,
        imgLogValue: 0,
        mailNotice: true,
        mailNoticeThreshold: 10,
        mailReceipient: ['USER', 'DEPT', 'ADMIN'],
        targetList: [
          {
            detectionRuleCode: 'code-1',
            watFileCode: 'code-1',
            printType: PrintApprovalType.ALLOW,
            printMasking: true,
            printMaskingExcept: false,
            logMasking: true,
            watPrint: false,
            offlinePrint: true,
            printReason: false,
            priority: 1,
          },
          {
            detectionRuleCode: 'code-2',
            watFileCode: 'code-2',
            printType: PrintApprovalType.ALLOW,
            printMasking: true,
            printMaskingExcept: false,
            logMasking: true,
            watPrint: false,
            offlinePrint: true,
            printReason: false,
            priority: 3,
          },
          {
            detectionRuleCode: 'code-3',
            watFileCode: 'code-1',
            printType: PrintApprovalType.ALLOW,
            printMasking: true,
            printMaskingExcept: false,
            logMasking: true,
            watPrint: false,
            offlinePrint: true,
            printReason: false,
            priority: 2,
          },
          {
            detectionRuleCode: 'code-0',
            watFileCode: 'code-1',
            printType: PrintApprovalType.ALLOW,
            printMasking: true,
            printMaskingExcept: false,
            logMasking: true,
            watPrint: false,
            offlinePrint: true,
            printReason: false,
            priority: 4,
          },
          {
            detectionRuleCode: 'code-4',
            watFileCode: 'code-1',
            printType: PrintApprovalType.ALLOW,
            printMasking: true,
            printMaskingExcept: false,
            logMasking: true,
            watPrint: false,
            offlinePrint: true,
            printReason: false,
            priority: 5,
          },
        ],
      },
    ].map((o, i) => ({ ...o, code: '', entryTs: times(i) }));

    this.dummyApi = DummyApi.of({
      entityName: 'DetectionTemplate',
      createDto: (code, entity) => ({ ...entity, code, entryTs: Date.now() }),
      validation: (type, code, reqDto, data) => {
        data.forEach((dto) => {
          if (
            (type === 'create' && dto.name === reqDto.name) ||
            (type === 'update' && dto.code !== code && dto.name === reqDto.name)
          ) {
            throw new Error('이미 사용 중인 이름입니다.');
          }
        });
      },
      data,
    });
  }
  list(): Promise<DetectionTemplateDto[]> {
    return this.dummyApi.list();
  }
  find(code: string): Promise<DetectionTemplateDto> {
    return this.dummyApi.find(code);
  }
  create(reqDto: DetectionTemplateReqDto): Promise<DetectionTemplateDto> {
    return this.dummyApi.create(reqDto);
  }
  update(code: string, reqDto: DetectionTemplateReqDto): Promise<DetectionTemplateDto> {
    return this.dummyApi.update({ code, reqDto });
  }
  remove(code: string): Promise<void> {
    return this.dummyApi.remove(code);
  }
}

const detectionTemplateApi: DetectionTemplateApi = FSP_API_ENABLED
  ? new DetectionTemplateServerApi()
  : new DetectionTemplateDummyApi();

export default detectionTemplateApi;
