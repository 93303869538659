import React from 'react';
import classNames from 'classnames';

import { CommonProps } from 'src/components/helpers/props';

import './AbstractIcon.scss';

interface AddCircle2IconProps extends CommonProps {
  size?: 'md';
  color?: string;
}

const AddCircle2Icon: React.FC<AddCircle2IconProps> = ({ color, classes, size = 'md' }) => {
  let bgColor = '#E8F3FF';
  let strokeColor = '#295c9f';
  if (color) {
    bgColor = color;
    strokeColor = '#fff'
  }
  return (
    <svg
      className={classNames('icon-root', classes)}
      width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="18" cy="18" r="18" fill={bgColor}/>
      <path d="M18 11V25" stroke={strokeColor} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M25 18L11 18" stroke={strokeColor} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>

  );
};

export default AddCircle2Icon;
