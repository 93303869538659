import React, { useEffect } from 'react';
import {
  Switch as RouterSwitch,
  Route,
  Redirect,
  NavLink as RouterLink,
  useHistory,
} from 'react-router-dom';
import classNames from 'classnames';
import _ from 'lodash';
import jwt from 'jsonwebtoken';
import qs from 'query-string';

import { mainPageRouteTables, routeTables, RouteTable } from 'src/shared/routes';

import ArrowBackIcon from 'src/components/atom/icons/ArrowBackIcon';
import ArrowDropDownIcon from 'src/components/atom/icons/ArrowDropDownIcon';
import ArrowForwardIcon from 'src/components/atom/icons/ArrowForwardIcon';
import HomeIcon from 'src/components/atom/icons/HomeIcon';
import IconButton from 'src/components/atom/IconButton';

import './CommonPage.scss'; // TODO 추후 layout/Paper/CommonPaper로 해야 할 듯...
import './MainPage.scss';
import { useTranslation } from 'react-i18next';
import { getFirstAllowedMenuPath, isAllowedMenu } from 'src/shared/utils/menuUtils';

import useAuthStore from 'src/hooks/auth/useAuthStore';
import config from 'src/shared/config';
import currentUrlUtils from 'src/shared/utils/currentUrlUtils';
import ProjectPage from './ProjectPage';
// import autoLogout from 'src/shared/utils/autoLogout';

const MainPageSubMenu: React.FC<{
  depth2RouteTables: RouteTable[];
}> = ({ depth2RouteTables }) => {
  const { t } = useTranslation('menu');
  const { authEntity } = useAuthStore();

  if (!authEntity) {
    return null;
  }
  if (depth2RouteTables.length === 0) {
    return null;
  }

  return (
    <div className="tab-area">
      <ul>
        {depth2RouteTables.map((depth2, i) => {
          const depth3List = depth2.subs.filter(({ menu }) => menu);
          return (
            isAllowedMenu(depth2.adminScopes, authEntity.menus) && (
              <li key={i}>
                <RouterLink title={t(depth2.title)} to={depth2.fullPath}>
                  {t(depth2.title)}
                  {depth3List.length > 0 && <ArrowDropDownIcon />}
                </RouterLink>

                {depth3List.length > 0 && (
                  <ul className="dropdown-list">
                    {depth3List.map(
                      (depth3, j) =>
                        isAllowedMenu(depth3.adminScopes, authEntity.menus) && (
                          <li key={j}>
                            <RouterLink title={t(depth3.title)} to={depth3.fullPath}>
                              {t(depth3.title)}
                            </RouterLink>
                          </li>
                        )
                    )}
                  </ul>
                )}
              </li>
            )
          );
        })}
      </ul>
    </div>
  );
};

const MainPageHeader: React.FC<{
  routeTable: RouteTable;
  depth1RouteTable: RouteTable | null;
  depth2RouteTables: RouteTable[];
}> = ({ routeTable, depth1RouteTable, depth2RouteTables }) => {
  const { t } = useTranslation(['menu', 'commonsMenu']);
  const history = useHistory();
  const back = !routeTable.menu ? _.findLast(routeTable.parents, (prt) => prt.menu === true) : null;

  return (
    <div className="layout-contents-head">
      <div className="tit-area">
        {back ? (
          <React.Fragment>
            <IconButton onClick={() => history.push(back.fullPath)}>
              <ArrowBackIcon />
            </IconButton>
            <p>{t(routeTable.title)}</p>
          </React.Fragment>
        ) : (
          <p>{t((depth1RouteTable || routeTable).title)}</p>
        )}
      </div>
      <div className="path-area">
        <HomeIcon classes="path-home-icon" />
        {routeTable.parents.map((prt, i) => (
          <React.Fragment key={i}>
            <span>{t(prt.title)}</span>
            <ArrowForwardIcon classes="path-sep-icon" />
          </React.Fragment>
        ))}
        <span>{t(routeTable.title)}</span>
      </div>
      {routeTable.menu && <MainPageSubMenu depth2RouteTables={depth2RouteTables} />}
    </div>
  );
};

const MainPageBody: React.FC<{
  routeTable: RouteTable;
}> = ({ routeTable }) => {
  return (
    <React.Fragment>
      {routeTable.component ? (
        <div className="layout-contents-body">
          <routeTable.component />
        </div>
      ) : (
        <div className="layout-contents-body">TODO: ERROR</div>
      )}
    </React.Fragment>
  );
};

const MainPageWrapper: React.FC<{
  routeTable: RouteTable;
}> = ({ routeTable }) => {
  const { authEntity } = useAuthStore();

  if (routeTable.redirect) {
    return <Redirect to={routeTable.redirect} />;
  }
  if (routeTable.auth) {
    if (authEntity === undefined) {
      console.log('is not logged in?');
      return (
        <Redirect
          to={qs.stringifyUrl({
            url: config.url('/sign-in'),
            query: { redirectUrl: currentUrlUtils.getPath() },
          })}
        />
      );
    } else if (!isAllowedMenu(routeTable.adminScopes, authEntity.menus)) {
      console.log('is not allowedMenu');
      // return <Redirect to={getFirstAllowedMenuPath(authEntity.menus, routeTables)} />;
      return <Redirect to={config.url('/errors/forbidden')} />;
    }
  }

  let depth1RouteTable: RouteTable | null = null;
  const depth2RouteTables: RouteTable[] = [];
  if (routeTable.parents.length >= 2 && routeTable.parents[1].menu) {
    depth1RouteTable = routeTable.parents[1];
    depth2RouteTables.push(...routeTable.parents[1].subs.filter((rt) => rt.menu));
  }

  return (
    <div
      className={classNames('layout-contents-root', {
        'type-tab': depth2RouteTables.length > 0 && routeTable.menu,
      })}
    >
      <div className="inner">
        {/* <MainPageHeader
          routeTable={routeTable}
          depth1RouteTable={depth1RouteTable}
          depth2RouteTables={depth2RouteTables}
        /> */}
        <MainPageBody routeTable={routeTable} />
      </div>
    </div>
  );
};

const ProjectPageWrapper: React.FC<{ routeTables: RouteTable[] }> = ({ routeTables }) => {
  const { authEntity } = useAuthStore();

  if (authEntity === undefined) {
    console.log('is not logged in?');
    return (
      <Redirect
        to={qs.stringifyUrl({
          url: config.url('/sign-in'),
          query: { redirectUrl: currentUrlUtils.getPath() },
        })}
      />
    );
  }

  return (
    <div className="layout-contents-root">
      <div className="inner">
        <div className="layout-contents-body">
          <ProjectPage routeTables={routeTables} />
        </div>
      </div>
    </div>
  );
};

const MainPage: React.FC = () => {
  const projectAddRouteTable = findProjectAddRouteTable(mainPageRouteTables);

  return (
    <RouterSwitch>
      {/* {mainPageRouteTables
        .filter((routeTable) => isProjectAssiciated(routeTable.fullPath))
        .map((routeTable) => (
          // <Route key={routeTable.fullPath} path={routeTable.fullPath} exact>
          <Route path="/projects/:projectId">
            <MainPageWrapper routeTable={routeTable} />
          </Route>
        ))} */}
      {projectAddRouteTable && (
        <Route path="/projects/add" exact>
          <MainPageWrapper routeTable={projectAddRouteTable} />
        </Route>
      )}
      <Route path="/projects/:projectId">
        <ProjectPageWrapper
          routeTables={mainPageRouteTables.filter((routeTable) => {
            return isProjectAssiciated(routeTable.fullPath)
          }
          )}
        />
      </Route>
      {mainPageRouteTables
        .filter((routeTable) => !isProjectAssiciated(routeTable.fullPath))
        .map((routeTable) => {
          return <Route key={routeTable.fullPath} path={routeTable.fullPath} exact>
            <MainPageWrapper routeTable={routeTable} />
          </Route>
        })}
      <Route>
        <Redirect to={config.url('/errors/not-found')} />
      </Route>
    </RouterSwitch>
  );
};

function isProjectAssiciated(path: string): boolean {
  return /^\/projects\/.+/.test(path) && path !== '/projects/add';
}
function findProjectAddRouteTable(routeTables: RouteTable[]): RouteTable | undefined {
  return routeTables.find((rt) => rt.fullPath == '/projects/add');
}

export default MainPage;
