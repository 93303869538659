import React, { useEffect, useState } from 'react';
import templateApi, {
  DetailDto,
  AuditDto,
} from 'src/api/templateApi';
import { useHistory, useParams } from 'react-router-dom';
import usePopup from 'src/hooks/usePopup';
import { useAlert } from 'src/contexts/AlertContext';
import classNames from 'classnames';
import EditIcon from 'src/components/atom/icons/EditIcon';
import IconButton from 'src/components/atom/IconButton';
import DotIcon from 'src/components/atom/icons/DotIcon';
import ReactQuill, { Quill } from 'react-quill';
import Button from 'src/components/atom/Button';
import ToolTip from 'src/components/atom/ToolTip';
import './Step2.scss';
import projectApi, { ProjectDto, emptyProject } from 'src/api/projectApi';
import Step2EditPopup from './Step2EditPopup';


const DetailTaskElement: React.FC<{
  detailContent: { name: string, accounting: string[], risk_setting: string, management_assertion_list: any, is_default: boolean },
}> = ({
  detailContent,
}) => {

    return (
      <div>
        <div className="sp-step2-detail-body-title element" style={{ paddingLeft: 10, backgroundColor: "white" }}
        >
          <div className="sp-step2-detail-risk">{detailContent.name + (detailContent?.is_default ? "*" : "")}</div>

          <div className="sp-step2-detail-account">
            <ToolTip
              message={detailContent.accounting?.join("/")}
              maxWidth={500}

            >
              <div className="sp-step2-detail-account-text">
                {detailContent.accounting?.join("/")}
              </div>

            </ToolTip>
          </div>
          <div className="sp-step2-detail-riskIdentify-root" style={{ width: "10%" }}>
            {detailContent.risk_setting?.split(",").map((item: any, idx: number) => {
              return (
                <div key={idx.toString()} className="sp-step2-detail-body-table-item" style={{ borderWidth: item.length === 0 ? 0 : 1 }}>
                  <div>{item}</div>
                </div>
              )
            })}
          </div>
          <div className="sp-step2-detail-opinions-root">
            {detailContent.management_assertion_list?.map((item: any, idx: number) => {
              return (
                <div key={idx.toString()} className="sp-step2-detail-body-table-item" style={{ borderWidth: item.checked ? 1 : 0 }}>
                  <div>{item.checked ? item.key : ""}</div>
                </div>
              )
            })}
          </div>

        </div>


      </div >
    )
  }

const Step2: React.FC<{
  detailTaskName: string,
  editMode: boolean,
  requirements: string,
  detailSubList?: any,
  handleChange: (html: string) => void;
  handleCancelRequirement: () => void;
  handleConfirmRequirement: () => void;
  handleConfirmDetail?: (newData: any) => void;
}> = ({
  detailTaskName,
  editMode,
  requirements,
  detailSubList,
  handleChange,
  handleCancelRequirement,
  handleConfirmRequirement,
  handleConfirmDetail = () => { }
}) => {
    const step2EditPopup = usePopup();

    const handleDetailTaskElement = () => {
      step2EditPopup.openPopup();
    }
    const renderDetailBody = () => {
      return detailSubList?.map((elem: any, idx: number) => {
        return <DetailTaskElement key={'detailTaskElement' + idx}
          detailContent={elem}
        />
      })
    }

    const handleConfirmAccount = (newData: any) => {
      handleConfirmDetail(newData);
      step2EditPopup.closePopup();
    }
    const isProjectDisabled = projectApi.isProjectDisabled();
    return (
      <div className="sp-step2-detail-body">
        <div className="sp-step2-detail-body-title element">
          <div className="title" style={{ paddingLeft: 10 }}><DotIcon classes="dot-icon" color='#43425d' />요구사항</div>
        </div>
        <div className="sp-step2-detail-body-requirements">
          <div className={classNames("requirements-editor")}>
            <ReactQuill
              modules={{
                toolbar: [
                  [
                    { list: "ordered" },
                    { list: "bullet" },
                    { indent: "-1" },
                    { indent: "+1" },
                  ],

                ]
              }}
              readOnly={!editMode}
              value={requirements || ''}
              style={{ fontSize: 50 }}
              onChange={handleChange}
            />
          </div>
          {!editMode && <div className="sp-step2-detail-body-title element">
            <div className="header" style={{ paddingLeft: 10 }}>
              <div className="risk">
                위험
              </div>
              <div className="account">
                계정
              </div>
              <div className="riskIdentify">
                위험도
              </div>
              <div className="opinions">
                경영진 주장
              </div>
              <div className="edit-button">
                <IconButton disabled={isProjectDisabled} onClick={handleDetailTaskElement}>
                  <EditIcon size="sm" />
                </IconButton>
              </div>
            </div>
          </div>}
          {!editMode && <div className="sp-step2-detail-body-content">
            {renderDetailBody()}
          </div>}
        </div>
        {editMode && <div className="sp-step2-detail-footer common-page-footer project-footer">
          <div className={classNames('right-button')}>
            <Button classes="footer-button" text="취소" color="secondary" onClick={handleCancelRequirement} />
            <Button classes="footer-button" text="확인" onClick={handleConfirmRequirement} />
          </div>
        </div>}
        {step2EditPopup.opened &&
          <Step2EditPopup
            open={step2EditPopup.opened}
            data={detailSubList || []}
            onClose={step2EditPopup.closePopup}
            onConfirm={handleConfirmAccount}

          />
        }
      </div>)
  }

export default Step2;