import React, { useEffect, useState, useContext, Fragment } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { CommonProps } from 'src/components/helpers/props';
import { getAdidManagerUser } from 'src/shared/utils/sessionStorageManager';
import Popup2 from 'src/components/molecules/Popup2';
import templateApi, { SpecialDetailDto, TaskDto, DetailDto, createTemplate, createAudit, createDetail, AuditDto, } from 'src/api/templateApi';
import proejectApi from 'src/api/projectApi';
import Button from 'src/components/atom/Button';
import TextField from 'src/components/atom/TextField';
import FormLabel from 'src/components/atom/FormLabel';
import FormGroup from 'src/components/atom/FormGroup';
import FileInput from 'src/components/atom/FileInput';
import useProgressStore from 'src/hooks/useProgressStore';
import { useAlert } from 'src/contexts/AlertContext';
import RadioGroup from 'src/components/atom/RadioGroup';
import Radio from 'src/components/atom/Radio';
import { ProjectContext } from '.';
import FileToUpload from './FileToUpload';
import './AuditPopup.scss';
import { validateAll } from 'src/shared/utils/validations';
import AuditFileUploadPopup from './AuditFileUploadPopup';
import projectApi, { ProjectDto } from 'src/api/projectApi';
import usePopup from 'src/hooks/usePopup';

interface AuditPopupProps extends CommonProps {
  open: boolean;
  detailDto: SpecialDetailDto;
  gmOrToc?: string;
  largeFile?: boolean;
  onClose: () => void;
}


const AuditRegisterPopup: React.FC<AuditPopupProps> = ({
  open,
  detailDto,
  largeFile = false,
  gmOrToc,
  onClose,
  classes,
}) => {
  const { t } = useTranslation(['commons']);
  let { projectId, category, taskName, detailName, transType, detailTransType } = useParams<{ projectId: string, category: string, taskName: string, detailName: string, transType: string, detailTransType: string }>();
  detailName = detailName && decodeURIComponent(detailName);
  transType = transType && decodeURIComponent(transType);
  detailTransType = detailTransType && decodeURIComponent(detailTransType);
  const [updateAudit, setUpdateAudit] = useState<AuditDto>(createAudit());
  const [files, setFiles] = useState<File[]>([]);
  const [assertions, setAssertions] = useState("");
  const [sampleConut, setSampleCount] = useState("");
  const [subject, setSubject] = useState("");
  const [contents, setContents] = useState("");
  const [auditType, setAuditType] = useState<string>("file");
  const [abortFileUploadObj, setAbortFileUploadObj] = useState<{ obj: FileToUpload, uriObj: any }>();
  const userId = getAdidManagerUser();
  const { projectInfo } = useContext(ProjectContext);
  const { startProgress, stopProgress } = useProgressStore();
  const { confirmWithWarn, alert2 } = useAlert();
  const [progressPercent, setProgressPercent] = useState(0);
  const [detailData, setDetailData] = useState<any>();
  const fileUploadPopup = usePopup();
  const validationRules = {
    // id: _.trim(project.id).length > 0 && validationId(project.id),
    // name: _.trim(form.name).length > 0,
    // projectRole: selectedUsers.length > 0,
  };

  useEffect(() => {
    if (progressPercent > 0 && !fileUploadPopup.opened) {
      fileUploadPopup.openPopup();
    }
    if (progressPercent === 100) {
      setTimeout(() => { fileUploadPopup.closePopup() }, 500)
    }
  }, [progressPercent])

  const handleConfirm = (): void => {
    let reg = /[`~!@#$%^&*|\\\'\";:\/?]/gi
    let tmpObj;

    if (auditType === "text" && subject.length <= 0) {
      alert2("조서 등록", "파일 이름은 필수입니다.")
      return;
    } else if (auditType === "text" && contents.length <= 0) {
      alert2("조서 등록", "내용은 필수입니다.")
      return;
    } else if (auditType === "text" && reg.test(subject)) {
      const mat = subject.match(reg)?.join(",");
      alert2("조서 등록", `파일명에 사용할 수 없는 문자(${mat})가 포함되었습니다.`)
      return;
    } else if (auditType === "file" && files.length === 0) {
      alert2("조서 등록", "파일을 선택해 주세요.")
      return;
    }
    let tmpFileName = auditType === "text" ? subject : files[0].name;
    if (gmOrToc) {
      const newDetail = detailDto?.detail_sub_title_list?.filter(detail => detail.name === transType)[0];
      const newSubDetail = newDetail?.sub_detail_list?.filter((subDetail: any) => subDetail.name === detailTransType)[0];
      tmpObj = newSubDetail;
    } else {
      tmpObj = detailDto;
    }
    // console.log("tmpObj : ", tmpObj)
    //let checkFile = tmpObj?.audit_list?.filter((item: any) => item.file_name === tmpFileName) || [];

    const template = createTemplate(projectInfo.name, category.toUpperCase(), taskName);
    const detail = createDetail(detailName);


    if (gmOrToc) {
      let newDetailInfo = { ...detailDto };
      const newDetail = detailDto?.detail_sub_title_list?.filter(detail => detail.name === transType)[0];
      const newSubDetail = newDetail?.sub_detail_list?.filter((subDetail: any) => subDetail.name === detailTransType)[0];
      newDetailInfo.detail_sub_title_list = [{
        ...newDetail,
        sub_detail_list: [{
          ...newSubDetail,
          audit_list: [updateAudit]
        }]
      }]

      template.category_list[0].task_list[0].task_type = "Interim Audit";
      template._id = sessionStorage.getItem("templateId") || "";
      template.category_list[0].task_list[0].detail_list = [newDetailInfo];
    } else {
      template.category_list[0].task_list[0].detail_list = [detail];
    }




    templateApi.checkFileExist(template, auditType === "text" ? subject : updateAudit.file_name, largeFile ? 'large' : 'small')
      .then(resp => {
        // console.log("resp : ", resp);
        if (resp?.result === "duplicated") {
          confirmWithWarn(largeFile ? "대용량 증빙" : "조서 등록", `'${tmpFileName}'이(가) 이미 있습니다.\n 바꾸시겠습니까?`, (result) => {
            handleOk();
          });
        } else if (resp?.result === "duplicated but type is different") {
          const msg = !largeFile ? "대용량 증빙" : "조서"
          alert2(largeFile ? "대용량 증빙" : "조서 등록", `다른 카테고리(${msg})에 동일한 이름의 파일이 존재하여 등록할 수 없습니다.`);
        } else if (resp?.result === "ok") {
          proejectApi.checkFileInProject(projectId, auditType === "text" ? subject : updateAudit.file_name)
            .then(resp => {
              if (resp?.has_audit === true) {
                confirmWithWarn(largeFile ? "대용량 증빙" : "조서 등록", `다른 세부 태스크에 '${tmpFileName}'이(가) 이미 있습니다.\n바꾸시겠습니까?`, (result) => {
                  handleOk();
                });
              } else {
                handleOk();
              }
            })
            .catch(err => alert2(largeFile ? "대용량 증빙" : "조서 등록", err))
        }
      })
      .catch(err => alert2(largeFile ? "대용량 증빙" : "조서 등록", err))
  }

  const handleProgress = (percent: number): void => {
    if (percent < 100) {
      setProgressPercent(percent)
    } else {
      setProgressPercent(100)
    }
  }

  const handleOk = (): void => {


    //close할때 update된 데이터 넘겨서 서버에 저장함.
    const template = createTemplate(projectInfo.name, category.toUpperCase(), taskName);
    const detail = createDetail(detailName);


    if (gmOrToc) {
      let newDetailInfo = { ...detailDto };
      const newDetail = detailDto?.detail_sub_title_list?.filter(detail => detail.name === transType)[0];
      const newSubDetail = newDetail?.sub_detail_list?.filter((subDetail: any) => subDetail.name === detailTransType)[0];
      newDetailInfo.detail_sub_title_list = [{
        ...newDetail,
        sub_detail_list: [{
          ...newSubDetail,
          audit_list: [updateAudit]
        }]
      }]
      newDetailInfo.detail_sub_title_list[0].sub_detail_list[0].audit_list[0].type = largeFile ? 'large' : 'small';
      if (newDetailInfo.detail_sub_title_list[0].sub_detail_list[0].audit_list[0].type === 'large') {
        newDetailInfo.detail_sub_title_list[0].sub_detail_list[0].audit_list[0].management_assertions_string = assertions;
        newDetailInfo.detail_sub_title_list[0].sub_detail_list[0].audit_list[0].sample_count = sampleConut;
      }
      if (auditType === "text") {
        newDetailInfo.detail_sub_title_list[0].sub_detail_list[0].audit_list[0].file_name = subject;
        newDetailInfo.detail_sub_title_list[0].sub_detail_list[0].audit_list[0].file_contents = contents;
      }
      template.category_list[0].task_list[0].task_type = "Interim Audit";
      template._id = sessionStorage.getItem("templateId") || "";
      template.category_list[0].task_list[0].detail_list = [newDetailInfo];
    } else {
      template.category_list[0].task_list[0].detail_list = [detail];
      detail.audit_list = [updateAudit];
      detail.audit_list[0].type = largeFile ? 'large' : 'small';
      if (detail.audit_list[0].type === 'large') {
        detail.audit_list[0].management_assertions_string = assertions;
        detail.audit_list[0].sample_count = sampleConut;
      }
      if (auditType === "text") {
        detail.audit_list[0].file_name = subject;
        detail.audit_list[0].file_contents = contents;
      }
    }

    const addAudit = () => {
      // detail.audit_list[0].type = largeFile ? 'large' : 'small';

      templateApi
        .addAudit(template)
        .then((resp) => {
          console.log('AuditRegisterPopup > addAudit:', resp);
          stopProgress();
          onClose();
        })
        .catch(e => {
          console.log('AuditRegisterPopup > addAudit:', e);
          stopProgress();
          alert2(largeFile ? "대용량 증빙" : "조서 등록",
            e?.name === "already exist" ? "파일 이름이 중복되었습니다. 확인해 주세요."
              : e);
        })
    }
    //startProgress();
    console.log("template : ", template)
    if (auditType === 'text') {
      templateApi.makeAuditFromText(template).then((resp) => {
        console.log(1)
        if (gmOrToc) {
          template.category_list[0].task_list[0].detail_list[0].detail_sub_title_list[0].sub_detail_list[0].audit_list[0].uri = resp.data.uri_list[0];
        } else {
          detail.audit_list[0].uri = resp.data.uri_list[0];
        }
        addAudit()
      }).catch(e => {
        console.log(e);
        stopProgress();
        alert2(largeFile ? "대용량 증빙" : "조서 등록", e)
      })

    } else {
      templateApi.prepareUpload(template).then((resp) => {
        console.log(1)
        if (gmOrToc) {
          template.category_list[0].task_list[0].detail_list[0].detail_sub_title_list[0].sub_detail_list[0].audit_list[0].uri = resp.data.uri_list[0];
        } else {
          detail.audit_list[0].uri = resp.data.uri_list[0];
        }

        try {
          console.log("file url : ", resp.data.uri_list[0].path);
          const uploader = new FileToUpload(resp.data.uri_list[0].path, files[0], files[0].name, () => {
            setTimeout(() => {
              addAudit();
              setProgressPercent(0);
            }
              , 500);
          },
            handleProgress,
            (e: Error) => {
              stopProgress();
              alert2(largeFile ? "대용량 증빙" : "조서 등록", e)
            });
          const uriObj = resp.data.uri_list[0];
          setAbortFileUploadObj({ obj: uploader, uriObj: uriObj })
          uploader.uploadFile();

        } catch (e) {
          console.log("error :", e);
          stopProgress();
          alert2(largeFile ? "대용량 증빙" : "조서 등록", e as Error)
        }
      }).catch(e => {
        console.log(e);
        stopProgress();
        alert2(largeFile ? "대용량 증빙" : "조서 등록", e)
      })
    }


  }

  const handleCancel = (): void => {
    onClose();
  };

  const handleChangeFiles = (files: File[]): void => {
    if (!files) {
      return;
    }
    setFiles(files);
    setUpdateAudit((prevState) => (
      {
        ...prevState,
        file_name: files[0].name,
        uploader: {
          account: userId || '',
          nickname: ''
        }
      }
    ))
  };

  useEffect(() => {
    if (gmOrToc) {
      const newDetail = detailDto?.detail_sub_title_list?.filter(detail => detail.name === transType)[0];
      setDetailData(newDetail);
    }

  }, [])

  const onChangeAssertions = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAssertions(e.target.value)
  }

  const onChangeSampleCount = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSampleCount(e.target.value)
  }

  const onChangeContents = (value: string) => {
    setContents(value)
  }

  const onChangeSubject = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSubject(e.target.value);
  }

  const onChangeAuditType = (value: string) => {
    if (value === "text") {
      setUpdateAudit((prevState) => (
        {
          ...prevState,
          uploader: {
            account: userId || '',
            nickname: ''
          }
        }
      ))
      let reg = /[`~!@#$%^&*|\\\'\";:\/?]/gi
      if (detailTransType) {
        let _fileName = `${transType}_${detailTransType}.txt`;
        _fileName = _fileName.replace(reg, "")
        setSubject(_fileName)
      } else {
        let _fileName = `${taskName}_${detailName}.txt`
        _fileName = _fileName.replace(reg, "")
        setSubject(_fileName)
      }

    } else {
      setFiles([]);
      setContents("")
      setSubject("")
    }
    setAuditType(value)
  }

  const fileUploadAbort = () => {
    console.log("fileUpload Abort Called")
    abortFileUploadObj?.obj?.request?.abort();
    projectApi.cancelFileUpload(abortFileUploadObj?.uriObj.file_name, abortFileUploadObj?.uriObj.path, abortFileUploadObj?.uriObj.relative_path)
      .then()
      .catch(e => console.log(e))
  }

  return (
    <Popup2
      classes="audit-popup-root project-popup-root project-common"
      title={largeFile ? "대용량 증빙" : "조서 등록"}
      width="1112px"
      height="692px"
      open={open}
      onClose={handleCancel}
      footer={
        <React.Fragment>
          <Button text={t('commons:cancel')} onClick={handleCancel} color="secondary" />
          <Button
            text={'등록'}
            onClick={handleConfirm}
          // disabled={!selectedUsers} 
          />
        </React.Fragment>
      }
    >
      <div className="project-input-area">
        <FormGroup>
          <FormLabel classes='input-label'>세부 TASK</FormLabel>
          <TextField classes='input-area' value={gmOrToc ? detailData?.name : detailDto.name} disabled={true} />
        </FormGroup>
        {largeFile &&
          <Fragment>
            <FormGroup>
              <FormLabel classes='input-label'>경영진 주장</FormLabel>
              <TextField classes='input-area' value={assertions} onChange={onChangeAssertions} />
            </FormGroup>
            <FormGroup>
              <FormLabel classes='input-label'>샘플 수</FormLabel>
              <TextField classes='input-area' value={sampleConut} onChange={onChangeSampleCount} />
            </FormGroup>
          </Fragment>
        }
        {!largeFile &&
          <FormGroup>
            <FormLabel classes='input-label'>조서타입</FormLabel>
            <RadioGroup classes='input-area'
              value={auditType}
              onChange={(value) => {
                onChangeAuditType(value)
              }}
            >
              <Radio classes='audit-radio-css' label="파일" value={'file'} />
              <Radio classes='audit-radio-css' label="텍스트" value={'text'} />
            </RadioGroup>
          </FormGroup>
        }
        {auditType === 'file' ?
          <FormGroup>
            <FormLabel classes='input-label'>파일</FormLabel>
            <FileInput classes='input-area' files={files} onChange={handleChangeFiles} />
          </FormGroup>
          :
          <Fragment>
            <FormGroup>
              <FormLabel classes='input-label'>제목</FormLabel>
              <TextField classes='input-area' value={subject} onChange={onChangeSubject} />
            </FormGroup>
            <FormGroup classes="no-shrink comment" verticalAlign={"top"}>
              <FormLabel classes='input-label'><div>내용</div></FormLabel>
              <textarea className='audit-input-area' value={contents} onChange={(e) => onChangeContents(e.target.value)} />
            </FormGroup>
          </Fragment>
        }
        {fileUploadPopup.opened &&
          <AuditFileUploadPopup open={fileUploadPopup.opened} progressPercent={progressPercent}
            onAbort={() => { fileUploadAbort() }}
            onClose={() => { fileUploadPopup.closePopup() }} />
        }
      </div>

    </Popup2 >
  );
};

export default AuditRegisterPopup;



      // if (largeFile) {
      //   const uploader = new FileToUpload(resp.data.uri_list[0].path, files[0], files[0].name, () => { addAudit() },
      //     (e: Error) => { stopProgress(); alert2(largeFile ? "대용량 증빙" : "조서 등록", e) }
      //   );
      //   uploader.uploadFile();
      // }
      // else {
      //   templateApi.saveFile(resp.data.uri_list[0].path, files[0]).then(() => {
      //     console.log('AuditPopup > upload success');
      //     addAudit();
      //   }).catch(e => {
      //     console.log("AAA :", e);
      //     stopProgress();

      //     alert2(largeFile ? "대용량 증빙" : "조서 등록", e)
      //   })
      // }