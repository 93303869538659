import React, { useState } from 'react';
import classNames from 'classnames';

import { CommonProps } from 'src/components/helpers/props';
import useProgressStore from 'src/hooks/useProgressStore';
import ModalPortal from 'src/shared/react-utils/ModalPortal';
import imgProgress from 'src/resources/images/progress.gif';

import './index.scss';
import Button from '../Button';
import store from '../../../store';
import { resetProgress } from '../../../store/progress';

const Progress: React.FC<CommonProps> = ({ classes }) => {
  const { inProgress } = useProgressStore();

  return (
    <ModalPortal>
      {/* <div className={classNames('progress-backdrop-root', { on: inProgress })} /> */}
      <div className={classNames('progress-root', { on: inProgress }, classes)}>
        {inProgress && (
          <div className={'loading-container'}>
          <div className="loadingio-spinner-spin-3p9ce1185f9">
            <div className="ldio-j3o061p6lyr">
              <div>
                <div></div>
              </div>
              <div>
                <div></div>
              </div>
              <div>
                <div></div>
              </div>
              <div>
                <div></div>
              </div>
              <div>
                <div></div>
              </div>
              <div>
                <div></div>
              </div>
              <div>
                <div></div>
              </div>
              <div>
                <div></div>
              </div>
            </div>
          </div>
          {/*<Button*/}
          {/*  text={'취소'}*/}
          {/*  color={'secondary'}*/}
          {/*  onClick={() => {*/}
          {/*    store.dispatch(resetProgress());*/}
          {/*  }}*/}
          {/*/>*/}
          </div>
        )}
      </div>
    </ModalPortal>
  );
};

export default Progress;
