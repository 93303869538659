import React from 'react';
import classNames from 'classnames';

import { CommonProps } from 'src/components/helpers/props';

import './index.scss';

export interface ButtonProps extends CommonProps {
  disabled?: boolean;
  unfilled?: boolean;
  transparent?: boolean;
  hover?: boolean;
  borderd?: boolean;
  toggle?: boolean;
  display?: 'block' | 'none';
  size?: 'md' | 'sm';
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

const IconButton: React.FC<ButtonProps> = ({
  disabled = false,
  unfilled = false,
  transparent = false,
  hover = false,
  borderd = false,
  toggle = false,
  display = 'block',
  size = 'md',
  onClick,
  classes,
  children,
}) => {
  return (
    <button
      className={classNames(
        'icon-button-root',
        {
          'icon-button-unfilled': unfilled,
          'icon-button-transparent': transparent,
          'icon-button-borderd': borderd,
          'icon-button-hover': hover,
          'icon-button-toggle': toggle,
          'icon-button-size-sm': size === 'sm',
          display: display,
        },
        classes
      )}
      disabled={disabled}
      onClick={disabled ? undefined : onClick}
    >
      {children}
    </button>
  );
};

export default IconButton;
