import React, { useState, useContext } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import UserAuthorityIcon from 'src/components/atom/icons/UserAuthorityIcon';
import ArrowDownIcon from 'src/components/atom/icons/ArrowDownIcon';
// import ExpansionDiv from 'src/components/atom/ExpansionDiv';
import ExpansionDiv from 'src/components/atom/ExpansionDiv';
import CheckboxGroup, { CheckboxGroupChild } from 'src/components/atom/CheckboxGroup';
import Checkbox from 'src/components/atom/Checkbox';
import { ConfigContext } from 'src/pages/project/ProjectDetail';
import Select, { SelectItem } from 'src/components/atom/Select';

import './ProjectUserCard.scss';
import { useAlert } from 'src/contexts/AlertContext';

// const ROLE_LIST =   [  
//   {title: '등록자', eventKey: '등록자'},
//   {title: '검수자', eventKey: '검수자'},
//   {title: '승인자', eventKey: '승인자'},
// ]

export interface CreatRoleButtonProps {
  role: string[];
  last?: boolean;
  onChange: (role: string[]) => void;

}
const CreateRoleButton: React.FC<CreatRoleButtonProps> = ({ role, last, onChange }) => {

  const { t } = useTranslation(['commons']);
  const { config } = useContext(ConfigContext);

  if (!config.role_list) return <></>;

  const ROLE_LIST = config.role_list?.map(role => (
    { title: role, eventKey: role }
  ))

  return (
    <Select
      vertical={last ? "up" : "down"}
      width={240}
      selectFirstItem={true}
      selectedEventKey={role[0]}
      textAlign='right'
      onClick={(ev) => {
        onChange([ev])
      }
      }
    >
      {ROLE_LIST && ROLE_LIST.map((elem) => (
        <SelectItem key={elem.title} eventKey={elem.eventKey}>
          {elem.title}
        </SelectItem>
      ))}
    </Select>
  )
}


export interface CreatAuthButtonProps {
  auth: string;
  last?: boolean;
  onChange: (auth: string) => void;

}
const CreateAuthButton: React.FC<CreatAuthButtonProps> = ({ auth, last, onChange }) => {

  const { t } = useTranslation(['commons']);
  const { config } = useContext(ConfigContext);
  if (!config.authority_name_list) return <></>;

  const AUTH_LIST = config.authority_name_list?.map(elem => (
    { title: elem, eventKey: elem }
  ))

  return (
    <Select
      vertical={last ? "up" : "down"}
      selectFirstItem={true}
      selectedEventKey={auth}
      textAlign='right'
      onClick={(ev) => {
        onChange(ev)
      }
      }
    >
      {AUTH_LIST && AUTH_LIST.map((elem) => (
        <SelectItem key={elem.title} eventKey={elem.eventKey}>
          {elem.title}
        </SelectItem>
      ))}
    </Select>
  )
}

interface ProjectUserProps {
  image?: string;
  name: string;
  userAuth: string;
  userRole?: string[];
  modify?: boolean;
  last?: boolean;
  changeUserRole: (role: string[]) => void;
  changeUserAuth: (auth: string) => void;
}
const ProjectUserCard: React.FC<ProjectUserProps> = ({
  image,
  name,
  userAuth,
  userRole = [],
  modify,
  last = false,
  changeUserRole,
  changeUserAuth,
}) => {

  return (
    <div className='project-user-card-root'>
      <div className='user-left-info'>
        {
          // image ? <div></div> //todo user image
          //   : <div className={classNames('user-image-circle', 'user-default-icon')}></div>
        }
        <div className='user-info-text'>
          <div className='user-name'>
            {/* <span className='subname-blue'>{name.substring(0, 2)}</span>
            <span>{name.substring(2, name.length)}</span> */}
            <span>{name}</span>
          </div>
        </div>
      </div>
      <div className='user-auth'>
        {modify ? <CreateAuthButton last={last} auth={userAuth} onChange={changeUserAuth} /> : userAuth}
      </div>
      <div className='user-role'>
        {/* userRole !== undefined && userRole.map((role) => role).join(', ') */}
        {/* <UserAuthorityIcon /> */}
        {/* <span>{authority}</span> */}
        {
          modify ? <CreateRoleButton last={last} role={userRole} onChange={changeUserRole} /> : userRole && userRole.map((role) => role).join(', ')
        }
      </div>
    </div>
  )
}

export default ProjectUserCard;