import React from 'react';
import classNames from 'classnames';

import { CommonProps } from 'src/components/helpers/props';

interface LicenseIconProps extends CommonProps {
  size?: 'md' | 'sm';
}

const LicenseIcon: React.FC<LicenseIconProps> = ({ classes, size = 'md' }) => {
  if (size === 'md') {
    return (
      <svg
        className={classNames('icon-root', classes)}
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        color="#6B7480"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.9169 6.24996L15.8336 3.33329M17.5002 1.66663L15.8336 3.33329L17.5002 1.66663ZM9.4919 9.67496C9.92218 10.0995 10.2642 10.605 10.4984 11.1623C10.7325 11.7196 10.8541 12.3177 10.8561 12.9222C10.8581 13.5266 10.7405 14.1255 10.5102 14.6844C10.2798 15.2432 9.94111 15.751 9.51368 16.1784C9.08625 16.6058 8.5785 16.9445 8.01965 17.1749C7.4608 17.4053 6.8619 17.5228 6.25742 17.5208C5.65295 17.5188 5.05485 17.3972 4.49755 17.1631C3.94026 16.929 3.43478 16.5869 3.01023 16.1566C2.17534 15.2922 1.71336 14.1344 1.72381 12.9327C1.73425 11.731 2.21627 10.5814 3.06606 9.73162C3.91585 8.88184 5.0654 8.39981 6.26714 8.38937C7.46888 8.37893 8.62664 8.8409 9.49106 9.67579L9.4919 9.67496ZM9.4919 9.67496L12.9169 6.24996L9.4919 9.67496ZM12.9169 6.24996L15.4169 8.74996L18.3336 5.83329L15.8336 3.33329L12.9169 6.24996Z"
          stroke="#currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  } else if (size === 'sm') {
    return (
      <svg
        className={classNames('icon-root', classes)}
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        color="#6B7480"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.9169 6.24996L15.8336 3.33329M17.5002 1.66663L15.8336 3.33329L17.5002 1.66663ZM9.4919 9.67496C9.92218 10.0995 10.2642 10.605 10.4984 11.1623C10.7325 11.7196 10.8541 12.3177 10.8561 12.9222C10.8581 13.5266 10.7405 14.1255 10.5102 14.6844C10.2798 15.2432 9.94111 15.751 9.51368 16.1784C9.08625 16.6058 8.5785 16.9445 8.01965 17.1749C7.4608 17.4053 6.8619 17.5228 6.25742 17.5208C5.65295 17.5188 5.05485 17.3972 4.49755 17.1631C3.94026 16.929 3.43478 16.5869 3.01023 16.1566C2.17534 15.2922 1.71336 14.1344 1.72381 12.9327C1.73425 11.731 2.21627 10.5814 3.06606 9.73162C3.91585 8.88184 5.0654 8.39981 6.26714 8.38937C7.46888 8.37893 8.62664 8.8409 9.49106 9.67579L9.4919 9.67496ZM9.4919 9.67496L12.9169 6.24996L9.4919 9.67496ZM12.9169 6.24996L15.4169 8.74996L18.3336 5.83329L15.8336 3.33329L12.9169 6.24996Z"
          stroke="#currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  } else return null;
};

export default LicenseIcon;
