import { useAlert } from 'src/contexts/AlertContext';
import React, { useEffect, useState } from 'react';
import projectApi, { ProjectDto } from 'src/api/projectApi';
import { useHistory } from 'react-router-dom';

export default function useProjectInfo(
  projectId: string,
  userId: string,
  reload?: boolean,
): {
  loading: boolean;
  projectName: string;
  roleIdSet: Set<string>;
  projectInfo: ProjectDto;
} {
  const [loading, setLoading] = useState(true);
  const [projectName, setProjectName] = useState('');
  const [roleIds, setRoleIds] = useState<string[]>([]);
  const [projectInfo, setProjectInfo] = useState<ProjectDto>({} as ProjectDto);
  const roleIdSet = new Set(roleIds);
  const { alert2, confirmWithWarn } = useAlert();
  const history = useHistory();

  // const { authEntity } = useAuthStore();
  // roles: deid, join, eval, project, dataset, util, admin, upload, download, apply, eval_master, keygen
  // const roleIdSet = new Set(authEntity?.userInfo.userRole || []);

  useEffect(() => {
    projectApi
      .getProject(projectId)
      .then((ProjectDto) => {
        setLoading(false);
        setProjectName(ProjectDto.name);
        setProjectInfo(ProjectDto);
        // setRoleIds(
        //   ProjectDto.projectRole?.flatMap((pr) =>
        //     pr.roleId && pr.userId === userId ? pr.roleId : []
        //   ) || []
        // );
      })
      .catch((err) => {
        alert2("프로젝트", err);
      });

  }, [alert, projectId, userId]);

  useEffect(() => {
    if (reload) {
      projectApi
        .getProject(projectId)
        .then((ProjectDto) => {
          setLoading(false);
          setProjectName(ProjectDto.name);
          setProjectInfo(ProjectDto);
          // setRoleIds(
          //   ProjectDto.projectRole?.flatMap((pr) =>
          //     pr.roleId && pr.userId === userId ? pr.roleId : []
          //   ) || []
          // );
        })
        .catch((err) => {
          alert2("프로젝트", err)
        });
    }

  }, [reload]);

  return {
    loading,
    projectName,
    roleIdSet,
    projectInfo,
  };
}
