import React, { useContext, Children, useEffect, useState, useMemo, createContext } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import classNames from 'classnames';
import Paper, { PaperBody, PaperHeader } from 'src/layout/Paper';
import ProjectIcon from 'src/components/atom/icons/ProjectIcon';
import TrashIcon from 'src/components/atom/icons/TrashIcon';
import projectApi, { ProjectDto, emptyProject } from 'src/api/projectApi';
//start Popup User
import usePopup from 'src/hooks/usePopup';
import _ from 'lodash';
// end Popup User
import ArrowForwardIcon from 'src/components/atom/icons/ArrowForwardIcon';
import configApi, { ConfigInfo } from 'src/api/configApi';
import ToolTip from 'src/components/atom/ToolTip';
import './index.scss';
import { validateAll2 } from 'src/shared/utils/validations';
import { useToast } from 'src/contexts/ToastContext';
import { useAlert } from 'src/contexts/AlertContext';
import Chart from "react-google-charts";
import { ProgressBar } from './ProgressBar';
import DashboardPopup from './DashboardPopup';
import AuditDownload from './AuditDownload';
import templateApi, {
  createTask,
  createTemplate,
  createDetail
} from 'src/api/templateApi';
import DotIcon from 'src/components/atom/icons/DotIcon';

interface CustomChartProps {
  title?: string;
  size?: string;
  data: any;
}

const CustomChart: React.FC<CustomChartProps> = ({
  title = "",
  size = "lg",
  data,

}) => {
  let h = '200px';
  let _top = 90;
  if (size === "md") {
    h = '160px';
    _top = 70;
  }

  useEffect(() => {

  }, []);

  const d = data?.done_count / (title === "OVERVIEW" ? data?.total_task_count : data?.task_count);
  //console.log("d :", d)
  const donePercent = Math.floor(d * 100) + "%"

  return (
    <div style={{ position: "relative" }}>
      {title !== "OVERVIEW" && <div style={{ height: 40 }} />}
      {(title === "OVERVIEW" ? data?.total_task_count : data?.task_count) !== 0 ? <Chart
        width={h}
        height={h}
        chartType="PieChart"
        //loader={<div>Loading Chart</div>}
        data={[
          ['select', 'Count',],
          ['작업중', data?.in_progress_count,],
          ['리뷰중', data?.under_review_count,],
          ['완료', data?.done_count,],
          ['미진행', data?.not_in_progress_count],
        ]}
        options={{
          pieHole: 0.6,
          chartArea: {
            width: "100%",
            height: "90%"
          },
          colors: ['#ff6565', '#feb409', '#4ad991', '#cecfd0'],
          pieSliceText: 'none',
          legend: "none",

        }}
        className="chart-css"
        rootProps={{ 'data-testid': '1' }}
        chartActions={
          [{
            id: 'action', text: 'click me',
            action: function () {
              console.log("AAAA")
            }
          }]
        }
      /> :
        <div style={{ height: h, width: h, display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", lineHeight: "1.5" }}>
          <div>{"세부태스크 없음"}</div>
        </div>
      }
      <div style={{ textAlign: "center" }}>{title}</div>
      <div className={title === "OVERVIEW" ? "chart-percent" : "chart-percent2"} >
        {donePercent === "NaN%" ? "" : donePercent}
      </div>
    </div>
  );
};


export const ConfigContext = createContext({
  config: {} as ConfigInfo
})


interface ProjectDashboardProps {
  parentTitle?: string;
  toCancel?: () => void;
}
const ProjectDashboard: React.FC<ProjectDashboardProps> = ({ parentTitle, toCancel }) => {
  const [config, setConfig] = useState<ConfigInfo>({} as ConfigInfo);
  const { projectId } = useParams<{ projectId: string }>();
  //start Popup User
  const history = useHistory();
  const [project, setProject] = useState<ProjectDto>(emptyProject());
  const toast = useToast();
  const { alert, alert2 } = useAlert();
  const value = useMemo(() => ({ config }), [config]);
  const [dashboardInfo, setDashboardInfo] = useState<any>({});
  const [reload, setReload] = useState<boolean>(false);
  const [dashboardType, setDashboardType] = useState<string>("");
  const dashboardPopup = usePopup();
  const [audit, setAudit] = useState<boolean>(false);

  const validationId = (id: string): boolean => {
    const idRegEx = RegExp('^[\\_A-Za-z0-9]*$');
    if (!idRegEx.test(id)) {
      return false;
    }
    return true;
  };

  const validationRules = {

  };
  useEffect(() => {
    // 데이터 가져오기..
    if (reload) {
      projectApi.getProjectDashboard(projectId)
        .then(resp => {
          console.log("resp :", resp)
          setDashboardInfo(resp);
        })
        .catch(e => {
          alert2("에러 발생", e);
        })
      setReload(false)
    }
  }, [reload]);

  useEffect(() => {
    projectApi.getProject(projectId).then((resp) => {
      projectApi.setProjectStatus(resp.status || "");
    })
    // 데이터 가져오기..
    projectApi.getProjectDashboard(projectId)
      .then(resp => {
        console.log("resp :", resp)
        setDashboardInfo(resp);
      })
      .catch(e => {
        alert2("에러 발생", e);
      })
  }, [projectId]);

  useEffect(() => {
    return history.listen((location) => {
      console.log(`You changed the page to: ${location.pathname}`)
      setReload(true);
      setAudit(false);
    })

  }, [history])


  useEffect(() => {
  }, []);

  const handleClose = () => {
    setReload(true)
    dashboardPopup.closePopup();
  }

  const handleOpenPopup = (type: string) => {
    setDashboardType(type)
    dashboardPopup.openPopup()
  }

  const handleClickShortCut = (item: any) => {
    if (item === "조서 다운로드") {
      setAudit(true);
    } else {
      if (item.sub_detail_name && item.sub_title_name) {
        const _detailName = encodeURIComponent(item.detail_name)
        const _subDetailName = encodeURIComponent(item.sub_detail_name)
        history.push(`/projects/${projectId}/category/${item.category_name}/${item.task_name}/${_detailName}/${item.sub_title_name}/${_subDetailName}`);
      } else {
        const _detailName = encodeURIComponent(item.detail_name)
        history.push(`/projects/${projectId}/category/${item.category_name}/${item.task_name}/${_detailName}`);
      }
    }
  }

  const hadnleClickDeleteMyFavorite = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, i: number) => {

    e.stopPropagation();
    const item = dashboardInfo?.my_favorite_menus[i];
    const requestData = createTemplate(dashboardInfo.project_name, item.category_name.toUpperCase());


    requestData.category_list[0].task_list[0] = { ...createTask(item.task_name) };
    requestData.category_list[0].task_list[0].detail_list = [{ ...createDetail(item.detail_name), _id: item.detail_id }]

    if (item.sub_detail_name && item.sub_title_name) {
      requestData.category_list[0].task_list[0].detail_list[0].detail_sub_title_list = [{ name: item.sub_title_name, _id: item.sub_title_id }]
      requestData.category_list[0].task_list[0].detail_list[0].detail_sub_title_list[0].sub_detail_list = [{ name: item.sub_detail_name, _id: item.sub_detail_id }]
    }
    const proejectData = { name: dashboardInfo.project_name, project_id: dashboardInfo.project_id };

    templateApi
      .setMyFavorite(proejectData, requestData, false)
      .then((resp) => {
        // console.log('request TASK', resp);
        setReload(true);
      }).catch((e) => {
        alert2("에러 발생", e);
      })
  }

  if (audit) {
    return <AuditDownload />
  }
  const taskPercent = Math.floor(dashboardInfo?.my_project_info?.my_responsible_task_done_count / dashboardInfo?.my_project_info?.my_responsible_task_count === Infinity
    || isNaN(dashboardInfo?.my_project_info?.my_responsible_task_done_count / dashboardInfo?.my_project_info?.my_responsible_task_count) ?
    0 : dashboardInfo?.my_project_info?.my_responsible_task_done_count / dashboardInfo?.my_project_info?.my_responsible_task_count * 100);

  const reviewPercent = Math.floor(dashboardInfo?.my_project_info?.my_confirm_review_count / dashboardInfo?.my_project_info?.my_total_review_count === Infinity
    || isNaN(dashboardInfo?.my_project_info?.my_confirm_review_count / dashboardInfo?.my_project_info?.my_total_review_count) ?
    0 : dashboardInfo?.my_project_info?.my_confirm_review_count / dashboardInfo?.my_project_info?.my_total_review_count * 100);

  const isProjectDisabled = projectApi.isProjectDisabled();

  return (
    <div
      className={classNames('common-page-body project-page-body project-detail-root project-common', {
      })} style={{ padding: 0 }}
    >

      <ConfigContext.Provider value={value}>
        <Paper classes="project-dashboard-left" noScroll={true}>
          <div className="client-text">
            <ProjectIcon />
            <div style={{ marginLeft: 10 }}>
              {dashboardInfo?.client?.name}
            </div>
          </div>
          <PaperBody classes="left-content-body">
            <div className="short-cut-label">
              {"바로가기"}
            </div>
            <div className="short-cut-root">
              <div className="short-cut" onClick={() => handleClickShortCut("조서 다운로드")}>
                <DotIcon color={'#295c9f'} />
                <div className="short-cut-text">조서 다운로드</div>
              </div>
              {dashboardInfo?.my_favorite_menus?.map((elem: any, i: number) => {
                return <div className="short-cut" key={i.toString()} onClick={() => handleClickShortCut(elem)}>
                  <DotIcon color={'#295c9f'} />
                  <div className="short-cut-text">
                    {/* <ToolTip message={elem.sub_detail_name ? `${elem.category_name}/${elem.task_name}/${elem.detail_name}/${elem.sub_title_name}/${elem.sub_detail_name}` : */}
                    {/* `${elem.category_name}/${elem.task_name}/${elem.detail_name}`}> */}
                    {elem.sub_detail_name ? elem.sub_detail_name : elem.detail_name}
                    {/* </ToolTip> */}
                  </div>
                  {!elem.is_default && !isProjectDisabled && <div className="short-cut-trash" onClick={(e) => hadnleClickDeleteMyFavorite(e, i)}>
                    <ToolTip message="즐겨찾기 삭제">
                      <TrashIcon stroke="#295c9f" />
                    </ToolTip>
                  </div>}
                </div>
              })}
            </div>
          </PaperBody>
        </Paper>
        <Paper classes="project-dashboard-right" noScroll={true}>
          <div className="empty-area" />
          <PaperBody classes="right-content-body">
            <div className="dashboard-overview">
              <div className="dashboard-progress-area">
                <div className="dashboard-progress-info">
                  <div className="dashboard-progress-info-desc">
                    <div className="dashboard-area-label">
                      {"진행률"}
                    </div>
                    <div className="chart-description-root">
                      <div className="chart-description">
                        <div className="chart-description-item">
                          <div className="chart-description-item-circle" style={{ backgroundColor: "#cecfd0" }} >
                            <div className="chart-description-item-circle-inner" />
                          </div>
                          {"미진행"}</div>
                        <div className="chart-description-item">
                          <div className="chart-description-item-circle" style={{ backgroundColor: "#ff6565" }} >
                            <div className="chart-description-item-circle-inner" />
                          </div>
                          {"작업중"}</div>
                        <div className="chart-description-item">
                          <div className="chart-description-item-circle" style={{ backgroundColor: "#feb409" }} >
                            <div className="chart-description-item-circle-inner" />
                          </div>
                          {"리뷰중"}</div>
                        <div className="chart-description-item">
                          <div className="chart-description-item-circle" style={{ backgroundColor: "#4ad991" }} >
                            <div className="chart-description-item-circle-inner" />
                          </div>
                          {"완료"}</div>
                      </div>
                    </div>
                  </div>
                  <div className="chart-overview">
                    <CustomChart title="OVERVIEW" size="lg" data={dashboardInfo?.progress} />
                    {dashboardInfo?.progress?.static_list?.map((item: any, i: number) => {
                      return <CustomChart key={i.toString()} title={item.category_type} size="md" data={item} />
                    })}

                    {/* <CustomChart title="PROCEDURE" size="md" />
                    <CustomChart title="CONCLUSION" size="md" /> */}

                  </div>


                </div>
              </div>
              <div className="dashboard-project-area">
                <div className="dashboard-project-info">
                  <div className="dashboard-area-label">{"프로젝트"}</div>
                  <div className="dashboard-project-info-root">
                    <div className="dashboard-project-info-err">
                      <div className="dashboard-area-label-inner">{"오류리포트"}</div>
                      <div className="dashboard-project-info-err-count" >
                        <div className="dashboard-project-info-err-count-text" >
                          {dashboardInfo?.project_general_info?.total_defect_count}
                        </div>
                      </div>
                      <div className="dashboard-project-info-err-count-desc" >
                        <div className="dashboard-project-info-err-count-desc-text-root">
                          <div className="dashboard-project-info-err-count-desc-text-label">{"STRATEGY"}</div>
                          <div>{" : "}</div>
                          <div className="dashboard-project-info-err-count-desc-text-value">{dashboardInfo?.project_general_info?.strategy_defect_count}</div>
                        </div>
                        <div className="dashboard-project-info-err-count-desc-text-root">
                          <div className="dashboard-project-info-err-count-desc-text-label">{"PROCEDURE"}</div>
                          <div>{" : "}</div>
                          <div className="dashboard-project-info-err-count-desc-text-value">{dashboardInfo?.project_general_info?.procedure_defect_count}</div>
                        </div>
                        <div className="dashboard-project-info-err-count-desc-text-root">
                          <div className="dashboard-project-info-err-count-desc-text-label">{"CONCLUSION"}</div>
                          <div>{" : "}</div>
                          <div className="dashboard-project-info-err-count-desc-text-value">{dashboardInfo?.project_general_info?.conclusion_defect_count}</div>
                        </div>
                      </div>
                    </div>
                    <div className="dashboard-project-info-task">
                      <div className="dashboard-area-label-inner">{"TASK"}</div>
                      <div className="dashboard-project-info-task-count" >
                        <div className="dashboard-project-info-task-count-text" >
                          {dashboardInfo?.project_general_info?.total_task_count}
                        </div>
                      </div>
                      <div className="dashboard-project-info-task-count-desc" >
                        <div className="dashboard-project-info-task-count-desc-text-root">
                          <div className="dashboard-project-info-task-count-desc-text-label">{"담당자 배정"}</div>
                          <div>{" : "}</div>
                          <div className="dashboard-project-info-task-count-desc-text-value">{dashboardInfo?.project_general_info?.total_assign_count}</div>
                        </div>
                        <div className="dashboard-project-info-task-count-desc-text-root">
                          <div className="dashboard-project-info-task-count-desc-text-label">{"미배정"}</div>
                          <div>{" : "}</div>
                          <div className="dashboard-project-info-task-count-desc-text-value">{dashboardInfo?.project_general_info?.total_unassigned_count}</div>
                        </div>
                      </div>



                    </div>
                  </div>
                </div>
                <div className="dashboard-project-important">
                  <div className="dashboard-area-label">{"발견사항"}</div>
                  <div className="dashboard-project-important-root">
                    <div className="dashboard-project-important-err">
                      <div className="dashboard-area-label-inner">{"중요한 취약점"}</div>
                      <div className="dashboard-project-important-err-count" >
                        <div className="dashboard-project-important-err-count-text" >
                          {dashboardInfo?.findings?.total_main_weakness_count}
                        </div>
                      </div>
                      <div className="dashboard-project-important-err-arrow" onClick={() => isProjectDisabled ? {} : handleOpenPopup("중요한 취약점")}>
                        <ArrowForwardIcon />
                      </div>
                    </div>
                    <div className="dashboard-project-important-task">
                      <div className="dashboard-area-label-inner">{"유의적인 미비점"}</div>
                      <div className="dashboard-project-important-task-count" >
                        <div className="dashboard-project-important-task-count-text" >
                          {dashboardInfo?.findings?.total_incomplete_point_count}
                        </div>
                      </div>
                      <div className="dashboard-project-important-task-arrow" onClick={() => isProjectDisabled ? {} : handleOpenPopup("유의적인 미비점")}>
                        <ArrowForwardIcon />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="my-project-area">
              <div className="my-project-info">
                <div className="dashboard-area-label">{"나의 프로젝트"}</div>
                <div className="my-project-info-root">
                  <div className="my-project-info-task">
                    <div className="dashboard-area-label-inner">{"TASK"}</div>
                    <div className="my-project-info-task-count" >
                      <div className="my-project-info-task-count-text" >
                        {dashboardInfo?.my_project_info?.my_responsible_task_count}
                      </div>
                    </div>
                    <div className="my-project-info-task-progress">
                      <ProgressBar bgcolor="#4ad991" progress={taskPercent + "%"} height={13} />
                    </div>
                    <div className="my-project-info-task-detail" >
                      <div className="my-project-info-task-detail-text-root">
                        <div className="my-project-info-task-detail-text-label">{"완료"}</div>
                        <div>{" : "}</div>
                        <div className="my-project-info-task-detail-text-value">{dashboardInfo?.my_project_info?.my_responsible_task_done_count}</div>
                      </div>
                      <div className="my-project-info-task-detail-text-root">
                        <div className="my-project-info-task-detail-text-label">{"미완료"}</div>
                        <div>{" : "}</div>
                        <div className="my-project-info-task-detail-text-value">{dashboardInfo?.my_project_info?.my_responsible_task_incomplete_count}</div>
                      </div>
                    </div>
                  </div>
                  <div className="my-project-info-review">
                    <div className="dashboard-area-label-inner">{"Review"}</div>
                    <div className="my-project-info-review-count" >
                      <div className="my-project-info-review-count-text" >
                        {dashboardInfo?.my_project_info?.my_total_review_count}
                      </div>
                    </div>
                    <div className="my-project-info-review-progress">
                      <ProgressBar bgcolor="#4ad991" progress={reviewPercent + "%"} height={13} />
                    </div>
                    <div className="my-project-info-review-detail" >
                      <div className="my-project-info-review-detail-text-root">
                        <div className="my-project-info-review-detail-text-label">{"처리 완료"}</div>
                        <div>{" : "}</div>
                        <div className="my-project-info-review-detail-text-value">{dashboardInfo?.my_project_info?.my_confirm_review_count}</div>
                      </div>
                      <div className="my-project-info-review-detail-text-root">
                        <div className="my-project-info-review-detail-text-label">{"요청 중"}</div>
                        <div>{" : "}</div>
                        <div className="my-project-info-review-detail-text-value">{dashboardInfo?.my_project_info?.my_request_review_count}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {dashboardPopup.opened &&
              <DashboardPopup
                open={dashboardPopup.opened}
                type={dashboardType}
                projectId={projectId}
                onClose={handleClose}
                onConfirm={() => { }}
              />
            }
          </PaperBody>
        </Paper>

      </ConfigContext.Provider>

    </div >

  );
};

export default ProjectDashboard;
