import React from 'react';
import classNames from 'classnames';

import { CommonProps } from 'src/components/helpers/props';

import './AbstractIcon.scss';

interface ValidationIconProps extends CommonProps {
  disabled?: boolean;
  color?: string;
  size?: 'md' | 'lg';
}

const ValidationIcon: React.FC<ValidationIconProps> = ({
  classes,
  disabled = false,
  color = "white",
  size = 'md',
}) => {
  if (size === 'md') {
    return (
      <svg
        className={classNames('icon-root', classes)}
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill={color}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M5.6 7.63636V18.4545H16.8V20H5.6C4.72 20 4 19.3045 4 18.4545V7.63636H5.6ZM12.64 14.0886L10 11.5386L11.12 10.4568L12.64 11.925L16.08 8.60227L17.2 9.68409L12.64 14.0886ZM18.4 4.54545C19.28 4.54545 20 5.24091 20 6.09091V15.3636C20 16.2136 19.28 16.9091 18.4 16.9091H8.8C7.92 16.9091 7.2 16.2136 7.2 15.3636V6.09091C7.2 5.24091 7.92 4.54545 8.8 4.54545H11.344C11.68 3.64909 12.56 3 13.6 3C14.64 3 15.52 3.64909 15.856 4.54545H18.4ZM13.6 4.54545C13.16 4.54545 12.8 4.89318 12.8 5.31818C12.8 5.74318 13.16 6.09091 13.6 6.09091C14.04 6.09091 14.4 5.74318 14.4 5.31818C14.4 4.89318 14.04 4.54545 13.6 4.54545ZM10.4 7.63636V6.09091H8.8V15.3636H18.4V6.09091H16.8V7.63636H10.4Z" />
      </svg>
    );
  } else if (size === 'lg') {
    return (
      <svg
        className={classNames('icon-root', classes)}
        width="30"
        height="30"
        viewBox="0 0 30 30"
        fill={color}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M7 10V24H21V26H7C5.9 26 5 25.1 5 24V10H7ZM15.8 18.35L12.5 15.05L13.9 13.65L15.8 15.55L20.1 11.25L21.5 12.65L15.8 18.35ZM23 6C24.1 6 25 6.9 25 8V20C25 21.1 24.1 22 23 22H11C9.9 22 9 21.1 9 20V8C9 6.9 9.9 6 11 6H14.18C14.6 4.84 15.7 4 17 4C18.3 4 19.4 4.84 19.82 6H23ZM17 6C16.45 6 16 6.45 16 7C16 7.55 16.45 8 17 8C17.55 8 18 7.55 18 7C18 6.45 17.55 6 17 6ZM13 10V8H11V20H23V8H21V10H13Z" />
      </svg>
    );
  } else return null;
};

export default ValidationIcon;
