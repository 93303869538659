import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from 'src/store'; // redux store 구성 모듈
import _ from 'lodash';

import OrganApi, { OrganDto } from 'src/api/fidp/organApi';

export interface OrganEntity {
  key: string;
  label: string;
  type: 'dept' | 'user' | 'dummy' | 'dummyRoot'; // 트리 노드 종류
  nodes: OrganEntity[];
}

class Organutils {
  public static fromOrganDto(organDto: OrganDto): OrganEntity {
    let children: OrganEntity[] = [];
    if (organDto.children.length > 0) {
      children = organDto.children
        .filter((children) => children.id !== null)
        .map((children) => Organutils.fromOrganDto(children));
    }
    return {
      key: organDto.id,
      label: organDto.name,
      type: organDto.type,
      nodes: children,
    };
  }
}
// Reducers
export interface OrganState {
  initialized: boolean;
  organs: OrganEntity[];
}

const initialState: OrganState = {
  initialized: false,
  organs: [],
};

export const thunkTree = createAsyncThunk('organ/tree', async () => {
  return OrganApi.tree();
});

export const thunkTreeById = createAsyncThunk('organ/treeById', async (code: string) => {
  return OrganApi.treeById(code);
});

const organSlice = createSlice({
  name: 'organ',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(thunkTree.fulfilled, (state, { payload: organ }) => {
      state.organs = organ.map((o) => Organutils.fromOrganDto(o));
      state.initialized = true;
    });
    builder.addCase(thunkTreeById.fulfilled, (state, { payload: organ, meta }) => {
      const key = meta.arg;
      const copyOrgans = _.cloneDeep(state.organs);
      const paramOrgans = organ.map((o) => Organutils.fromOrganDto(o));

      state.organs = recursiveSearchById(copyOrgans, key, paramOrgans);
    });
  },
});

function recursiveSearchById(
  organs: OrganEntity[],
  key: string,
  targetOrgans: OrganEntity[]
): OrganEntity[] {
  organs.forEach((organ) => {
    if (organ.key === key) organ.nodes = targetOrgans;
    else if (organ.nodes.length > 0) {
      organ.nodes = recursiveSearchById(organ.nodes, key, targetOrgans);
    }
  });
  return organs;
}
// # selectors
export const selectInitialized = (state: RootState): boolean => {
  return state.organ.initialized;
};
export const selectOrgans = (state: RootState): OrganEntity[] => state.organ.organs;

export default organSlice.reducer;
