import React from 'react';
import classNames from 'classnames';

import { CommonProps } from 'src/components/helpers/props';

import './AbstractIcon.scss';

interface ArrowBackIconProps extends CommonProps {
  size?: 'md';
}

const ArrowBackIcon: React.FC<ArrowBackIconProps> = ({ classes, size = 'md' }) => {
  return (
    <svg
      className={classNames('icon-root', classes)}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="#2e353c"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M14.7998 18C14.5998 18 14.2998 17.9 14.1998 17.8L8.1998 12.8C7.8998 12.6 7.7998 12.3 7.7998 12C7.7998 11.7 7.8998 11.4 8.1998 11.2L14.1998 6.19995C14.5998 5.79995 15.2998 5.89995 15.5998 6.29995C15.9998 6.69995 15.8998 7.39995 15.4998 7.69995L10.3998 12L15.4998 16.2C15.8998 16.6 15.9998 17.2 15.5998 17.6C15.3998 17.9 15.0998 18 14.7998 18Z" />
    </svg>
  );
};

export default ArrowBackIcon;
