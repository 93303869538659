import React, { useContext, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useAlert } from 'src/contexts/AlertContext';
import classNames from 'classnames';
import EditIcon from 'src/components/atom/icons/EditIcon';
import IconButton from 'src/components/atom/IconButton';
import usePopup from 'src/hooks/usePopup';
import Button from 'src/components/atom/Button';
import _ from 'lodash';
import './Step4.scss';
import { TaskElementForMidtermAudit } from '../../TaskElement';
import Step4EditPopup from './Step4EditPopup';
import RequestTaskPopup from '../../RequestTaskPopup';
import templateApi, {
  TaskDto, RequestTaskDto, createTemplate, SpecialDetailDto
} from 'src/api/templateApi';
import { ProjectContext } from '../../'
import { useToast } from 'src/contexts/ToastContext';

const Step4: React.FC<{
  currentTask: TaskDto,
  detailInfo: SpecialDetailDto,
  detailTaskName: string,
  detailSubList: any[],
  onUpdate: () => void;
  handleConfirmDetail: (data: any) => void;
  handleUpdateSubDetail: (data: any) => void;
}> = ({
  currentTask,
  detailInfo,
  detailTaskName,
  detailSubList,
  onUpdate,
  handleConfirmDetail,
  handleUpdateSubDetail,
}) => {
    const [popupTask, setPopupTask] = useState<TaskDto>();
    const toast = useToast();
    const { projectInfo } = useContext(ProjectContext);
    const { projectId, category } = useParams<{ projectId: string, category: string }>();
    const { alert, alert2, confirmWithWarn } = useAlert();
    const [popupRequestTask, setPopupRequestTask] = useState<TaskDto>();
    const history = useHistory();
    const taskPopup = usePopup();
    const taskRequestPopup = usePopup();
    const handleTaskEdit = (task: TaskDto) => {
      // console.log("task : ", task)
      setPopupTask(task);
      taskPopup.openPopup();
    }

    const handleCloseTaskPopup = (task: TaskDto) => {
      let newDetailSubTitleList = [];
      if (task) {
        newDetailSubTitleList = [task]
        // console.log("newDetailSubTitleList : ", newDetailSubTitleList)
        handleUpdateSubDetail(newDetailSubTitleList)
      }

      taskPopup.closePopup();
    }
    const handleCloseRequestTaskPopup = () => {
      taskRequestPopup.closePopup();
    }


    const handleRequestTask = (task: TaskDto) => {
      setPopupRequestTask(task);
      taskRequestPopup.openPopup();
    }

    const handleDetailClick = (transType: string, detailTransType: string) => {
      // console.log("taskName1 : ", transType)
      // console.log("taskName2 : ", detailTransType)
      const _detailName = encodeURIComponent(detailTaskName)
      transType = transType && encodeURIComponent(transType);
      detailTransType = detailTransType && encodeURIComponent(detailTransType);
      history.push(`/projects/${projectId}/category/${category}/중간감사/${_detailName}/${transType}/${detailTransType}`);
    }

    const handleConfirmRequestTask = (requestTask: RequestTaskDto) => {

      // console.log("requestTask : ", requestTask, detailInfo)
      const requestData = createTemplate(projectInfo.name, category.toUpperCase());


      requestData.category_list[0].task_list[0] = { ...currentTask };
      requestData.category_list[0].task_list[0].detail_list = [{ ...detailInfo }];

      let newDetailDto = (requestData.category_list[0].task_list[0].detail_list[0] as SpecialDetailDto).detail_sub_title_list?.filter((i: any) => i._id === requestTask._id) || [];
      newDetailDto[0] = { ...newDetailDto[0], ...requestTask };

      requestData.category_list[0].task_list[0].detail_list[0] =
        {
          ...requestData.category_list[0].task_list[0].detail_list[0],
          detail_sub_title_list: newDetailDto

        } as SpecialDetailDto;

      // console.log("requestData : ", requestData)


      templateApi
        .requestReviewByGMTOC(requestData)
        .then((resp) => {
          // console.log('request TASK', resp);
          taskRequestPopup.closePopup();
          toast.info('일괄 리뷰 요청이 완료되었습니다.');
          // console.log("onUpdate() : ", onUpdate)
          onUpdate();
        }).catch((e) => {
          console.log("EEE : ", e)
          alert2("리뷰요청", e);
        })
    }

    const handleMyFavorite = (task: TaskDto) => {
      console.log("requestTask : ", task)
      const requestData = createTemplate(projectInfo.name, category.toUpperCase());


      requestData.category_list[0].task_list[0] = { ...currentTask };
      requestData.category_list[0].task_list[0].detail_list = [{ ...detailInfo }];

      let newDetailDto = (requestData.category_list[0].task_list[0].detail_list[0] as SpecialDetailDto).detail_sub_title_list?.filter((i: any) => i._id === task._id) || [];
      newDetailDto[0] = { ...newDetailDto[0], ...task };

      requestData.category_list[0].task_list[0].detail_list[0] =
        {
          ...requestData.category_list[0].task_list[0].detail_list[0],
          detail_sub_title_list: newDetailDto

        } as SpecialDetailDto;

      // console.log("requestData : ", requestData)
      const proejectData = { name: projectInfo.name, project_id: projectId };
      const setFlag: boolean = task && task?.sub_detail_list && task?.sub_detail_list[0].is_favorite || false;
      console.log("requestData  : ", requestData)
      templateApi
        .setMyFavorite(proejectData, requestData, !setFlag)
        .then((resp) => {
          // console.log('request TASK', resp);
          onUpdate();
        }).catch((e) => {
          alert2("에러 발생", e);
        })

    }

    const handleHasAudit = async (requestTask: TaskDto): Promise<any> => {
      // console.log("requestTask : ", requestTask, detailInfo)
      const requestData = createTemplate(projectInfo.name, category.toUpperCase());


      requestData.category_list[0].task_list[0] = { ...currentTask };
      requestData.category_list[0].task_list[0].detail_list = [{ ...detailInfo }];

      let newDetailDto = (requestData.category_list[0].task_list[0].detail_list[0] as SpecialDetailDto).detail_sub_title_list?.filter((i: any) => i._id === requestTask._id) || [];
      newDetailDto[0] = { ...newDetailDto[0], ...requestTask };

      requestData.category_list[0].task_list[0].detail_list[0] =
        {
          ...requestData.category_list[0].task_list[0].detail_list[0],
          detail_sub_title_list: newDetailDto

        } as SpecialDetailDto;

      // console.log("requestData : ", requestData)

      try {
        const resp = templateApi.hasAudit(requestData);
        return Promise.resolve(resp)
      } catch (e) {
        return Promise.reject(e)
      }
    }

    // console.log("detailSubList :", detailSubList)

    return (
      <div className="sp-ma-step4-detail-body">
        <div className="sp-ma-step4-detail-body">
          <div className="template-task-header element" style={{ color: "#a3a6b4", minHeight: 45 }}>
            <div className="title-content title">주요 거래 유형</div>
            <div className="title-account account">담당자</div>
            <div className="title-status status">상태</div>
          </div>
          <div className="data-body">
            {detailSubList?.map((task: TaskDto, idx) => {
              return <TaskElementForMidtermAudit key={'taskElement' + idx}
                task={task}
                taskDeletable={false}
                taskEditable={true}
                detailEditable={false}
                onTaskEditClick={() => handleTaskEdit(task)}
                onDeleteTask={() => { }}
                onRequestTask={() => handleRequestTask(task)}
                onDetailClick={handleDetailClick}
                onMyFavoriteClick={handleMyFavorite}
              />
            })}
          </div>
        </div>
        {taskPopup.opened && popupTask
          &&
          <Step4EditPopup
            taskDto={popupTask}
            handleHasAudit={handleHasAudit}
            open={taskPopup.opened}
            onClose={handleCloseTaskPopup} />
        }
        {taskRequestPopup.opened && popupRequestTask
          &&
          <RequestTaskPopup
            categoryName=""
            taskName=""
            onConfirm={handleConfirmRequestTask}
            requestTaskDto={popupRequestTask}
            open={taskRequestPopup.opened}
            onClose={handleCloseRequestTaskPopup} />}
      </div>)
  }

export default Step4;