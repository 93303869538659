import React, { useEffect, useState } from 'react';
import classNames from 'classnames';

import { title } from 'process';
import { CommonProps } from 'src/components/helpers/props';
import ThreeDotIcon from 'src/components/atom/icons/ThreeDotIcon';
import InfoOutline from 'src/components/atom/icons/InfoOutline';
import ModifyDocIcon from 'src/components/atom/icons/ModifyDocIcon';
import DeleteLineIcon from 'src/components/atom/icons/DeleteLineIcon';
import './ProjectCard.scss';
import { action } from '@storybook/addon-actions';
import { useHistory } from 'react-router-dom';
import TextLink from 'src/components/atom/TextLink';
import SettingButton2, { DropdownItem } from 'src/components/atom/SettingButton2';
import InfoOutlineIcon from 'src/components/atom/icons/InfoOutline';
import EditIcon from 'src/components/atom/icons/EditIcon';
import DeleteIcon from 'src/components/atom/icons/DeleteIcon';
import CloseIcon from 'src/components/atom/icons/CloseIcon';
import ResetIcon from 'src/components/atom/icons/ResetIcon';
import DownloadIcon from 'src/components/atom/icons/DownloadIcon';
import { JobStatus, jobStatusMappingObj } from 'src/api/jobApi';
import { JobStatusDto } from 'src/api/projectApi2';
import { toLocalDateTimeStr, dateToStringByFormat, stringToDate } from 'src/shared/utils/localDateTime';


interface CardIconProps {
  bgColor?: string;
  children: React.ReactNode;
}
const CardIcon: React.FC<CardIconProps> = ({
  bgColor = '#F77E6E',
  children,
}) => {
  return (
    <div className={'card-icon'} style={{ backgroundColor: bgColor }}>{children}</div>
  )
}

interface ActionItemProps extends CommonProps {
  name: string
  icon?: React.ReactNode
  action?: () => void
}
const ActionItem: React.FC<ActionItemProps> = ({
  name,
  icon,
  action,
  classes
}) => {
  return (
    <div className={classNames('action-item', classes)}
      onClick={action}>
      {icon ? <div className='action-icon'>{icon}</div> : null
      }
      <span>{name}</span>
    </div>
  )
}

interface ProjectCardProps extends CommonProps {
  projectId: string;
  projectStatus?: string;
  iconTitie?: string;
  title?: string;
  desc?: string;
  width?: number;
  minWidth?: number;
  iconColor?: string;
  date?: string;
  hasResourceProject: boolean;
  jobStatus?: JobStatusDto;
  deleteAction?: () => void;
  changeStatusAction?: (projectId: string, status: string) => void;
  detailAction?: (projectId: string) => void;
  exportAction?: (projectId: string) => void;
}
const ProjectCard: React.FC<ProjectCardProps> = ({
  projectId,
  projectStatus,
  iconTitie,
  width = 338,
  minWidth,
  iconColor,
  title,
  desc,
  date,
  hasResourceProject,
  classes,
  jobStatus,
  deleteAction,
  changeStatusAction,
  detailAction,
  exportAction,
}) => {
  const [action, setAction] = useState(false)
  const history = useHistory();

  const getTextWidth = (text: string, font: string) => {
    let elm = document.createElement('canvas');
    let context = elm.getContext('2d');
    if (context) {
      context.font = font;
      let width = context.measureText(text).width
      return width
    }

    return 0;
  }
  const projectDesc = (text: string) => {
    let font = '13px Noto Sans CJK KR';
    const ellipsis = '...'
    if (text) {
      let width = getTextWidth(text, font)
      if (width > 280) {
        text = text.slice(0, 23) // 설명이 너무 길 경우 23글자로 제한
        text = text.concat(ellipsis)
      }
    }

    return text

  }
  if (desc) {
    desc = projectDesc(desc);
  }

  const handleUpdate = () => {
    history.push(`/projects/${projectId}/update`);
  }
  const handleDetail = () => {
    detailAction && detailAction(projectId);
  }

  const handleStatusChange = (status: string) => {
    changeStatusAction && changeStatusAction(projectId, status)
  }

  const handleExportProject = () => {
    exportAction && exportAction(projectId);
  }

  return (
    <div className={classNames('project-card-root', classes)} onClick={handleDetail}
      style={{ width: width, minWidth: minWidth }}>
      <CardIcon bgColor={iconColor} >{iconTitie ? iconTitie : title?.substr(0, 2)}</CardIcon>
      <div className='card-title'>
        <span>
          <TextLink v={2} to={`/projects/${projectId}/detail`}>{title}</TextLink>
        </span>
      </div>
      <div className='card-action-button'>
        <SettingButton2 hover>
          <DropdownItem onClick={(e) => {
            e.stopPropagation();
            handleDetail()
          }
          }>
            <InfoOutlineIcon size="sm" />
            {'상세정보'}
          </DropdownItem>

          {(projectStatus === undefined || projectStatus === "open") &&
            <DropdownItem onClick={(e) => {
              e.stopPropagation();
              handleStatusChange("closed");
            }
            }>
              <CloseIcon size="sm" />
              {'프로젝트 닫기'}
            </DropdownItem>
          }
          {projectStatus === "closed" &&
            <DropdownItem onClick={(e) => {
              e.stopPropagation();
              handleStatusChange("open");
            }
            }>
              <ResetIcon size="sm" />
              {'프로젝트 오픈'}
            </DropdownItem>
          }

          {projectStatus === 'closed' &&
            <DropdownItem onClick={(e) => {
              e.stopPropagation();
              handleExportProject();
            }
            }>
              <DownloadIcon size="sm" />
              {'프로젝트 EXPORT'}
            </DropdownItem>
          }
          {projectStatus === "closed" &&
            <DropdownItem onClick={(e) => {
              e.stopPropagation();
              handleStatusChange("archived");
            }
            }>
              <ResetIcon size="sm" />
              {'프로젝트 마감(ARCHIVE)'}
            </DropdownItem>
          }
          {projectStatus !== "closed" &&
            <DropdownItem severity="error" onClick={(e) => {
              e.stopPropagation();
              deleteAction && deleteAction();
            }
            }>
              <DeleteIcon size="sm" />
              {'프로젝트 삭제'}
            </DropdownItem>
          }

          {hasResourceProject && (
            <React.Fragment>
              <DropdownItem onClick={handleUpdate}>
                <EditIcon size="sm" />
                {'수정'}
              </DropdownItem>

            </React.Fragment>
          )}
        </SettingButton2>
      </div>
      <div>
        <span className='card-description'>{desc}</span>
      </div>
      <div className='card-date'>
        {(projectStatus === undefined || projectStatus === "open") && "last modified at : " + dateToStringByFormat(stringToDate(date!), 'YYYY-MM-DD HH:mm:ss')}
        {projectStatus === "closed" && "closed at : " + dateToStringByFormat(stringToDate(date!), 'YYYY-MM-DD HH:mm:ss')}
        {projectStatus === "imported" && "restored at : " + dateToStringByFormat(stringToDate(date!), 'YYYY-MM-DD HH:mm:ss')}
        {projectStatus === "archived" && "archived at : " + dateToStringByFormat(stringToDate(date!), 'YYYY-MM-DD HH:mm:ss')}
      </div>
    </div >
  )
}

export default ProjectCard;
