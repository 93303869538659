import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import licenseAccountApi from 'src/api/licenseAccountApi';
import licenseApi, { LicenseInfoDto } from 'src/api/licenseApi';
import { toLocalDateWithFormat } from 'src/shared/utils/localDateTime';
import { RootState } from 'src/store';

// # types
export interface LicenseEntity extends LicenseInfoDto {
  currentUserCount: number;
  validStartTimeStr: string;
  validEndTimeStr: string;
}

function toEntity(dto: LicenseInfoDto, userCount: number): LicenseEntity {
  return {
    ...dto,
    currentUserCount: userCount,
    validStartTimeStr: toLocalDateWithFormat(dto.validStartTime, 'YYYY.MM.DD'),
    validEndTimeStr: toLocalDateWithFormat(dto.validEndTime, 'YYYY.MM.DD'),
  };
}

interface LicenseInfoState {
  initialized: boolean;
  entity: LicenseEntity;
}

// # initial state
const initialState: LicenseInfoState = {
  initialized: false,
  entity: {
    maxUserCount: 0,
    maxServerCount: 0,
    currentUserCount: 0,
    validStartTime: 0,
    validEndTime: 0,
    validStartTimeStr: '',
    validEndTimeStr: '',
  },
};

// # thunks
export const thunkLoadLicenseInfo = createAsyncThunk('license/load', async () => {
  const dto = await licenseApi.get();
  const currentUserCount = await licenseAccountApi.totalCount();
  return toEntity(dto, currentUserCount);
});

export const thunkUpdateLicenseFile = createAsyncThunk('license/update', async (file: File) => {
  const dto = await licenseApi.updateLicFile(file);
  const currentUserCount = await licenseAccountApi.totalCount();
  return toEntity(dto, currentUserCount);
});

const licenseSlice = createSlice({
  name: 'license',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(thunkLoadLicenseInfo.fulfilled, (state, { payload: licenses }) => {
      state.entity = licenses;
      state.initialized = true;
    });
    builder.addCase(thunkUpdateLicenseFile.fulfilled, (state, { payload: updatedEntity }) => {
      state.entity = updatedEntity;
    });
  },
});

// # selectors
export const selectInitialized = (state: RootState): boolean => {
  return state.license.initialized;
};
export const selectLicenseEntity = (state: RootState): LicenseEntity => state.license.entity;

export default licenseSlice.reducer;
