import config from 'src/shared/config';
import axios from 'src/shared/utils/axios';

import DummyApi from '../dummyApi';
import { times } from '../api';
import { UseYN } from '.';

const INFRA_API_ENABLED = config.servers.infra.enabled;
const INFRA_API_URL = config.servers.infra.url;

export const NO_PATTERN_GROUP_CODE = 'zzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzz';

export interface PatternDto {
  code: string;
  name: string;
  description: string;
  useYN: UseYN;
  entryTime: number;

  color: string; // 패턴 색상
  moduleCode: string | null; //유효성 검사 모듈 코드
  exceptionUseYN: UseYN; // 예외 사용 여부
  maskingUseYN: UseYN; // 마스킹 사용 여부
  maskingStart1: number; //첫번째 마스킹 시작 위치
  maskingLength1: number; // 첫번째 마스킹 개수
  maskingStart2: number; // 두번째 마스킹 시작 위치
  maskingLength2: number; // 두번째 마스킹 개수
  maskingStart3: number; // 세번째 마스킹 시작 위치
  maskingLength3: number; // 세번째 마스킹 개수
  groupCode: typeof NO_PATTERN_GROUP_CODE | string; // 패턴 그룹 코드
  uid: string; //
  regexpCount: number; // 정규 표현식 개수
  exceptRegexpCount: number; // 예외 정규 표현식 개수

  regexps: Regexp[]; // 정규 표현식
  exceptRegexps: Regexp[]; // 예외 정규 표현식
}

export interface Regexp {
  expression: string; // 표현식
  description: string; // 설명
  useYN: UseYN; // 사용여부
}

export type PatternReqDto = Omit<PatternDto, 'code' | 'entryTime'>;

interface PatternApi {
  list(): Promise<PatternDto[]>;
  find(code: string): Promise<PatternDto>;
  create(reqDto: PatternReqDto): Promise<PatternDto>;
  update(code: string, reqDto: PatternReqDto): Promise<PatternDto>;
  remove(code: string): Promise<void>;
}

class PatternServerApi implements PatternApi {
  async list(): Promise<PatternDto[]> {
    const resp = await axios.get<PatternDto[]>(`${INFRA_API_URL}/api/patterns`);
    return resp.data;
  }
  async find(code: string): Promise<PatternDto> {
    const resp = await axios.get<PatternDto>(`${INFRA_API_URL}/api/patterns/${code}`);
    return resp.data;
  }
  async create(reqDto: PatternReqDto): Promise<PatternDto> {
    const resp = await axios.post<PatternDto>(`${INFRA_API_URL}/api/patterns`, reqDto);
    return resp.data;
  }
  async update(code: string, reqDto: PatternReqDto): Promise<PatternDto> {
    const resp = await axios.put<PatternDto>(`${INFRA_API_URL}/api/patterns/${code}`, reqDto);
    return resp.data;
  }
  async remove(code: string): Promise<void> {
    await axios.delete<PatternDto>(`${INFRA_API_URL}/api/patterns/${code}`);
  }
}

export class PatternDummyApi implements PatternApi {
  dummyApi: DummyApi<PatternDto, PatternReqDto>;
  constructor() {
    const data: PatternDto[] = [
      {
        name: '기본 고정 주민등록번호',
        description: '기본 고정 주민등록번호',
        useYN: UseYN.Y,
        color: '#25C16F',
        moduleCode: 'code-0',
        exceptionUseYN: UseYN.N, // 마스킹 예외 사용안함
        maskingUseYN: UseYN.N, // 마스킹 사용 안함
        maskingStart1: 0,
        maskingLength1: 0,
        maskingStart2: 0,
        maskingLength2: 0,
        maskingStart3: 0,
        maskingLength3: 0,
        groupCode: 'code-0',
        uid: '',
        regexpCount: 0,
        exceptRegexpCount: 0,
        regexps: new Array<Regexp>(),
        exceptRegexps: new Array<Regexp>(),
      },
      {
        name: '기본 고정 카드번호',
        description: '기본 고정 카드번호',
        useYN: UseYN.Y,
        color: '#25C16F',
        moduleCode: null,
        exceptionUseYN: UseYN.N, // 마스킹 예외 사용안함
        maskingUseYN: UseYN.N, // 마스킹 사용 안함
        maskingStart1: 0,
        maskingLength1: 0,
        maskingStart2: 0,
        maskingLength2: 0,
        maskingStart3: 0,
        maskingLength3: 0,
        groupCode: 'code-0',
        uid: '',
        regexpCount: 0,
        exceptRegexpCount: 0,
        regexps: new Array<Regexp>(),
        exceptRegexps: new Array<Regexp>(),
      },
      {
        name: '기본 고정 외국인등록번호',
        description: '기본 고정 외국인등록번호',
        useYN: UseYN.Y,
        color: '#25C16F',
        moduleCode: null,
        exceptionUseYN: UseYN.N, // 마스킹 예외 사용안함
        maskingUseYN: UseYN.N, // 마스킹 사용 안함
        maskingStart1: 0,
        maskingLength1: 0,
        maskingStart2: 0,
        maskingLength2: 0,
        maskingStart3: 0,
        maskingLength3: 0,
        groupCode: 'code-0',
        uid: '',
        regexpCount: 0,
        exceptRegexpCount: 0,
        regexps: new Array<Regexp>(),
        exceptRegexps: new Array<Regexp>(),
      },

      {
        name: '기본 고정 여권번호',
        description: '기본 고정 여권번호',
        useYN: UseYN.Y,
        color: '#25C16F',
        moduleCode: null,
        exceptionUseYN: UseYN.N, // 마스킹 예외 사용안함
        maskingUseYN: UseYN.N, // 마스킹 사용 안함
        maskingStart1: 0,
        maskingLength1: 0,
        maskingStart2: 0,
        maskingLength2: 0,
        maskingStart3: 0,
        maskingLength3: 0,
        groupCode: 'code-0',
        uid: '',
        regexpCount: 0,
        exceptRegexpCount: 0,
        regexps: new Array<Regexp>(),
        exceptRegexps: new Array<Regexp>(),
      },
      {
        name: '기본 고정 카드번호 22',
        description: '기본 고정 카드번호 22',
        useYN: UseYN.Y,
        color: '#25C16F',
        moduleCode: null,
        exceptionUseYN: UseYN.N, // 마스킹 예외 사용안함
        maskingUseYN: UseYN.N, // 마스킹 사용 안함
        maskingStart1: 0,
        maskingLength1: 0,
        maskingStart2: 0,
        maskingLength2: 0,
        maskingStart3: 0,
        maskingLength3: 0,
        groupCode: 'code-1',
        uid: '',
        regexpCount: 0,
        exceptRegexpCount: 0,
        regexps: new Array<Regexp>(),
        exceptRegexps: new Array<Regexp>(),
      },
      {
        name: '기본 고정 카드번호 33',
        description: '기본 고정 카드번호 33',
        useYN: UseYN.Y,
        color: '#25C16F',
        moduleCode: null,
        exceptionUseYN: UseYN.N, // 마스킹 예외 사용안함
        maskingUseYN: UseYN.N, // 마스킹 사용 안함
        maskingStart1: 0,
        maskingLength1: 0,
        maskingStart2: 0,
        maskingLength2: 0,
        maskingStart3: 0,
        maskingLength3: 0,
        groupCode: NO_PATTERN_GROUP_CODE,
        uid: '',
        regexpCount: 2,
        exceptRegexpCount: 0,
        regexps: new Array<Regexp>(),
        exceptRegexps: new Array<Regexp>(),
      },
      {
        name: '기본 고정 카드번호 44',
        description: '기본 고정 카드번호 44',
        useYN: UseYN.Y,
        color: '#25C16F',
        moduleCode: 'code-0',
        exceptionUseYN: UseYN.N, // 마스킹 예외 사용안함
        maskingUseYN: UseYN.Y, // 마스킹 사용 안함
        maskingStart1: 1,
        maskingLength1: 2,
        maskingStart2: 3,
        maskingLength2: 4,
        maskingStart3: 5,
        maskingLength3: 6,
        groupCode: NO_PATTERN_GROUP_CODE,
        uid: '',
        regexpCount: 2,
        exceptRegexpCount: 0,
        regexps: [
          { expression: 'regexp-1', description: '정규식 1', useYN: UseYN.Y },
          { expression: 'regexp-2', description: '정규식 2', useYN: UseYN.Y },
        ],
        exceptRegexps: new Array<Regexp>(),
      },
    ].map((o, i) => ({ ...o, code: '', entryTime: times(i) }));

    this.dummyApi = DummyApi.of({
      entityName: 'Pattern',
      createDto: (code, entity) => ({ ...entity, code, entryTime: Date.now() }),
      validation: (type, code, reqDto, data) => {
        data.forEach((dto) => {
          if (
            (type === 'create' && dto.groupCode === reqDto.groupCode && dto.name === reqDto.name) ||
            (type === 'update' &&
              dto.groupCode === reqDto.groupCode &&
              dto.code !== code &&
              dto.name === reqDto.name)
          ) {
            throw new Error('이미 사용 중인 이름입니다.');
          }
        });
      },
      data,
    });
  }
  list(): Promise<PatternDto[]> {
    return this.dummyApi.list();
  }
  find(code: string): Promise<PatternDto> {
    return this.dummyApi.find(code);
  }
  create(reqDto: PatternReqDto): Promise<PatternDto> {
    return this.dummyApi.create(reqDto);
  }
  update(code: string, reqDto: PatternReqDto): Promise<PatternDto> {
    return this.dummyApi.update({ code, reqDto });
  }
  remove(code: string): Promise<void> {
    return this.dummyApi.remove(code);
  }
  async removeByGroupCode(groupCode: string): Promise<void> {
    const list = await this.dummyApi.list();
    for (const dto of list) {
      if (dto.groupCode === groupCode) {
        await this.dummyApi.remove(dto.code);
      }
    }
  }
}

const patternApi: PatternApi = INFRA_API_ENABLED ? new PatternServerApi() : new PatternDummyApi();

export default patternApi;
