import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import productSpecApi, { ProductSpecDto } from 'src/api/productSpecApi';
import { RootState } from 'src/store';

// # types
export type ProductSpecEntity = Omit<ProductSpecDto, 'documentClasses'> & {
  documentClasses: {
    byCode: { [code: string]: string };
    allCodes: string[];
  };
};
function toEntity(dto: ProductSpecDto): ProductSpecEntity {
  const documentClassesDto = dto.documentClasses ? dto.documentClasses : [];
  return {
    type: dto.type,
    dsdCode: dto.dsdCode,
    targetCode: dto.targetCode,
    description: dto.description,
    documentClasses: {
      byCode: documentClassesDto.reduce((acc, { code, name }) => ({ ...acc, [code]: name }), {}),
      allCodes: documentClassesDto.map(({ code }) => code),
    },
  };
}

// function toDto(entity: ProductSpecEntity): ProductSpecDto {
//   return { ...entity };
// }

interface ProductSpecEntityByCode {
  [code: string]: ProductSpecEntity;
}
export interface ProductSpecEntities {
  byCode: ProductSpecEntityByCode;
  allCodes: string[];
}
interface ProductSpecState {
  initialized: boolean;
  entities: ProductSpecEntities;
}

// # initial state
const initialState: ProductSpecState = {
  initialized: false,
  entities: { byCode: {}, allCodes: [] },
};

// # thunks
export const thunkLoadProductSpecs = createAsyncThunk('productSpec/load', async () => {
  const list = await productSpecApi.list();
  return list.map((dto) => toEntity(dto));
});

const productSpecSlice = createSlice({
  name: 'productSpec',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(thunkLoadProductSpecs.fulfilled, (state, { payload: productSpecs }) => {
      state.entities.byCode = productSpecs.reduce((acc, t) => {
        acc[t.targetCode] = t;
        return acc;
      }, {} as ProductSpecEntityByCode);
      state.entities.allCodes = productSpecs.map((t) => t.targetCode);
      state.initialized = true;
    });
  },
});

// # selectors
export const selectInitialized = (state: RootState): boolean => {
  return state.productSpec.initialized;
};
export const selectProductSpecEntities = (state: RootState): ProductSpecEntities =>
  state.productSpec.entities;
export const selectProductSpecEntityList = (state: RootState): ProductSpecEntity[] =>
  state.productSpec.entities.allCodes.map((code) => state.productSpec.entities.byCode[code]);

export default productSpecSlice.reducer;
