import React, { useEffect, useState, useContext } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import Button from 'src/components/atom/Button';
import _, { template, update } from 'lodash';

import { CommonProps } from 'src/components/helpers/props';
import TrashIcon from 'src/components/atom/icons/TrashIcon';
import IconButton from 'src/components/atom/IconButton';
import Popup2 from 'src/components/molecules/Popup2';
import { boolean } from '@storybook/addon-knobs';
import templateApi, { TemplateDto, TaskDto, DetailDto, createDetail, RequestTaskDto } from 'src/api/templateApi';
import TextField from 'src/components/atom/TextField';
import AddCircle2Icon from 'src/components/atom/icons/AddCircle2Icon';
import { MemberDto } from 'src/api/userSettingApi';
import { SelectAccount } from '../../TaskElement';
import { ProjectContext, statusColor } from '../../../Category';
// import { ProjectContext } from '../Category';
import './Step4EditPopup.scss';
import { useAlert } from 'src/contexts/AlertContext';
import { validateAll } from 'src/shared/utils/validations';

interface Step4EditPopupProps extends CommonProps {
  // dtoList: UserInfoEntity[];
  // roleList: RoleDto[];
  // permissionList: PermissionDto[];
  taskDto: TaskDto;
  open: boolean;
  handleHasAudit?: (taskDto: TaskDto) => Promise<any>;
  onClose: (taskDto: TaskDto) => void;
  // selected: UserInfoEntity[];
}


const Step4EditPopup: React.FC<Step4EditPopupProps> = ({
  taskDto,
  open,
  onClose,
  handleHasAudit,
  classes,
  // roleList,
  // permissionList,
}) => {
  const { t } = useTranslation(['commons']);
  const [updateTask, setUpdateTask] = useState<TaskDto>(_.cloneDeep(taskDto));
  const { projectInfo } = useContext(ProjectContext);
  const [memberList, setMemberList] = useState<MemberDto[]>([]);
  const { confirmWithWarn, alert2 } = useAlert();
  const history = useHistory();
  const validationRules = {
    // id: _.trim(project.id).length > 0 && validationId(project.id),
    // name: _.trim(form.name).length > 0,
    // projectRole: selectedUsers.length > 0,
    name: updateTask?.sub_detail_list?.every((item: any) => item?.name?.length > 0),
  };

  useEffect(() => {
    if (projectInfo.member_list) {
      setMemberList(projectInfo.member_list);
    }
  }, [projectInfo])

  const handleOk = (): void => {
    //close할때 update된 데이터 넘겨서 서버에 저장함.
    if (!validateAll(validationRules)) {
      alert2("주요 거래 유형 담당자 지정", "세부 TASK명은 필수입니다.");
      return;
    }
    onClose(updateTask);
  };
  const handleCancel = (): void => {
    onClose({} as TaskDto);
  };

  const handleAccountAllChange = (member: MemberDto) => {
    const onConfirm = () => {
      let newList: DetailDto[] = []
      updateTask?.sub_detail_list?.map(subDetail => {
        newList.push({
          ...subDetail,
          responsibility: {
            account: member?.account,
            nickname: member?.nickname,
          }
        })
      })
      setUpdateTask({
        ...updateTask,
        sub_detail_list: newList
      })
    }
    confirmWithWarn('주요 거래 유형 담당자 지정', '주요 거래 유형의 담당자들이 일괄로 수정됩니다.\n계속 진행하시겠습니까?', onConfirm);
  }

  const handleAccountChange = (accountInfo: { account: string, nickname: string }, i: number) => {

    let newList = [...(updateTask?.sub_detail_list || [])];
    newList[i] = {
      ...newList[i],
      responsibility: {
        account: accountInfo?.account,
        nickname: accountInfo?.nickname,
      }
    }
    setUpdateTask({
      ...updateTask,
      sub_detail_list: newList
    })
  }

  const handleDetailAdd = () => {
    setUpdateTask({
      ...updateTask,
      sub_detail_list: updateTask.sub_detail_list ? updateTask.sub_detail_list.concat(createDetail()) : [createDetail()]
    })

    // setAddList(addList.concat(createDetail()));
  }

  const handleTaskNameChange = (value: string, i: number) => {
    let newList = [...(updateTask?.sub_detail_list || [])];
    newList[i] = {
      ...newList[i],
      name: value
    }
    setUpdateTask({
      ...updateTask,
      sub_detail_list: newList
    })
  }

  const handleDeleteTask = (e: React.MouseEvent<HTMLButtonElement>, i: number) => {
    e.stopPropagation();
    if (updateTask?.sub_detail_list) {
      if (!updateTask?.sub_detail_list[i]?.name) {
        let newList = [...(updateTask?.sub_detail_list || [])];
        newList.splice(i, 1)
        setUpdateTask({
          ...updateTask,
          sub_detail_list: newList
        })
        return;
      }
      confirmWithWarn('세부 TASK삭제', '세부 TASK "' + updateTask?.sub_detail_list[i]?.name +
        (updateTask?.sub_detail_list[i]?.name?.length > 30 ? '" 을 \n 정말 삭제하시겠습니까?' : '" 을 정말 삭제하시겠습니까?'), (result) => {
          let newList = [...(updateTask?.sub_detail_list || [])];
          newList = [newList[i]]
          let newUpdateTask = {
            ...updateTask,
            sub_detail_list: newList
          }
          if (handleHasAudit) {
            handleHasAudit(newUpdateTask)
              .then(resp => {
                if (resp?.has_audit === true) {
                  alert2("세부 TASK삭제", `세부 TASK [${updateTask?.sub_detail_list && updateTask?.sub_detail_list[i]?.name}]에 조서가 존재합니다.\n조서가 모두 지워지지 않으면 삭제할 수 없습니다.`)
                } else {
                  let newList = [...(updateTask?.sub_detail_list || [])];
                  newList.splice(i, 1)
                  setUpdateTask({
                    ...updateTask,
                    sub_detail_list: newList
                  })
                }
              })
              .catch(err => {
                alert2("세부 TASK삭제", err)
              })
          }
        })
    }
  }

  // console.log("updateTask : ", updateTask)
  return (
    <Popup2
      classes="task-popup-root project-popup-root project-common"
      title="주요 거래 유형 담당자 지정"
      width="1112px"
      height="692px"
      open={open}
      onClose={handleCancel}
      footer={
        <React.Fragment>
          {<div className="detail-add">
            <div className="detail-add-button"
              onClick={handleDetailAdd}
            >
              <AddCircle2Icon color="#295c9f" />
              <span>세부 TASK 추가</span>
            </div>
          </div>}
          <Button text={t('commons:cancel')} onClick={handleCancel} color="secondary" />
          <Button
            text={t('commons:ok')}
            onClick={handleOk}
          // disabled={!selectedUsers} 
          />



        </React.Fragment>
      }
    >
      <div className="task-header element">
        <div className="title-content title" style={{ width: 660, color: "#a3a6b4" }}>내용</div>
        <div className="title-account account" style={{ width: 130, color: "#a3a6b4" }}>담당자</div>
        <div className="title-status status" style={{ width: 270, color: "#a3a6b4" }}></div>
      </div>

      <div className="task-header element">

        <div className="title-content title" style={{ width: 660, color: "#a3a6b4" }}>

          {updateTask.name}</div>
        <div className="title-account account" style={{ width: 130, color: "#a3a6b4" }}>
          <SelectAccount
            memberList={memberList}
            selected={undefined}
            allFlag={true}
            onSelected={(index) => {
              handleAccountAllChange(memberList[index])
            }}
          />
        </div>
        <div className="title-status status" style={{ width: 270, color: "#a3a6b4" }}></div>
      </div>

      <div className="task-detail">
        {updateTask?.sub_detail_list?.map((subDetail, i) => {
          return (
            <div className="detail-element element">
              {subDetail?.is_default ? <div className="detail-content title" style={{ width: 660, paddingLeft: 87 }}>
                {subDetail?.name}
              </div>
                :
                <div className="detail-content title" style={{ width: 660, paddingLeft: 80 }}>
                  <TextField classes="ma-step4-task-name-edit"
                    value={subDetail.name}
                    autoFocus={!subDetail?.name ? true : false}
                    fullWidth={true}
                    placeholder="세부 TASK를 입력하세요."
                    onChange={(e) => { handleTaskNameChange(e.target.value, i) }}
                  />
                </div>
              }
              <SelectAccount
                lastItem={i > 3 ? true : false}
                memberList={memberList}
                selected={subDetail?.responsibility?.account}
                selectedNickName={subDetail?.responsibility?.nickname}
                onSelected={(index) => {
                  handleAccountChange(memberList[index], i)
                }}
              />
              <div style={{ width: 100 }}>
                {subDetail?.is_default ?
                  <div className="icon-trash" />
                  : <IconButton classes="icon-trash"
                    onClick={(e) => handleDeleteTask(e, i)}>
                    <TrashIcon fill="none" stroke={"#292929"} />
                  </IconButton>}
              </div>
            </div>
          )
        })}
        {/* { // 위의 테스트 결과를 가지고 만들었음
          updateTask && updateTask?.sub_detail_list?.map((detail, index) => {
            let pos: number = 51.5 + (index * 45)
            let height: number = 67.5 + (index * 45)
            let d: string = "M 16 16 L  16 " + pos + " L 50.546875 " + pos + " 50.546875 " + pos
            let top: number = -74 - (((updateTask?.sub_detail_list || []).length - 1) * 45)
            return (
              <div style={{ position: "absolute", zIndex: 0 }}>
                <svg width={111.40625} height={height} overflow={"auto"}
                  style={{ position: "absolute", left: 17.4531, top: `${top}`, pointerEvents: "none" }}>
                  <path d={d} stroke="#295c9f" strokeDasharray="0 0" strokeWidth="2" fill="transparent" pointerEvents="visibleStroke">
                  </path>
                </svg>
              </div>
            )
          })

        } */}

      </div>

    </Popup2 >
  );
};

export default Step4EditPopup;
