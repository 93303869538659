import React from 'react';

import AppBar from './AppBar';
import LeftMenu from './LeftMenu';
import MainPage from './MainPage';
import TopBar from './TopBar';
import LeftMenuBox from './LeftMenuBox';

import classNames from 'classnames';

import './BaseLayout.scss';

const BaseLayout: React.FC = () => {
  return (
    <div className="base-layout-root">
      <div className="inner">
        {/* <LeftMenu /> */}
        <TopBar />
        <div className="layout-container">
          {/* <div className="inner"> */}
          <div className={classNames("inner", 'flex-layout')}>
            {/* <LeftMenuBox /> */}

            {/*<AppBar />*/}
            <div className="layout-main-container">
              <MainPage />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BaseLayout;
