import React from 'react';
import classNames from 'classnames';

import { CommonProps } from 'src/components/helpers/props';

import './AbstractIcon.scss';

interface ArrowUpIconProps extends CommonProps {
  received?: boolean;
  size?: 'md';
}

const ArrowUpIcon: React.FC<ArrowUpIconProps> = ({ classes, received = false, size = 'md' }) => {
  return (
    <svg
      className={classNames('icon-root', classes)}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="#4d4f5c"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M17 14.4877C17 14.6872 16.9001 14.9866 16.7004 15.0864C16.301 15.3858 15.8017 15.286 15.5021 14.9866L12.0069 10.7954L8.51175 14.9866C8.21217 15.3858 7.71286 15.3858 7.31341 15.0864C6.91396 14.787 6.91396 14.2881 7.21355 13.9887L11.4077 8.99918C11.6075 8.7996 11.8072 8.6998 12.0069 8.6998C12.2066 8.6998 12.5062 8.7996 12.6061 8.99918L16.8003 13.9887C16.9001 14.0885 17 14.2881 17 14.4877Z" />
    </svg>
  );
};

export default ArrowUpIcon;
