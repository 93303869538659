import config from 'src/shared/config';
import axios from 'src/shared/utils/axios';
import _ from 'lodash';
import { ManagerServerResDto, unwrapResult } from './managerServerApi';

const M_API_ENABLED = config.servers.manager.enabled;
const M_API_URL = config.servers.manager.url2;

export interface PermissionDto {
  resourceId: string;
  resourceName: string;
}

export interface RoleServerDto {
  id: string;
  name: string;
  comments: string;
  permission: PermissionDto[];
}

export interface ResourcDto {
  id: string;
  name: string;
  comments: string;
}

export type RoleDto = RoleServerDto;

export type RoleReqDto = RoleDto;

export interface ServerRspDto {
  status: number;
  result: any;
}

interface RoleApi {
  list(): Promise<RoleDto[]>;
  listResource(): Promise<PermissionDto[]>;
  find(code: string): Promise<RoleDto>;
  remove(code: string): Promise<void>;
  create(reqDto: RoleDto): Promise<void>;
  update(code: string, reqDto: RoleDto): Promise<void>;
}

export function toPermission(dto: ResourcDto): PermissionDto {
  return {
    resourceId: dto.id,
    resourceName: dto.name,
  };
}

class RoleServerApi implements RoleApi {
  async list(): Promise<RoleDto[]> {
    const resp = await axios.get<ManagerServerResDto<RoleDto[]>>(`${M_API_URL}/v1/roles`);

    return unwrapResult(resp.data);
  }

  async listResource(): Promise<PermissionDto[]> {
    const resp = await axios.get<ManagerServerResDto<ResourcDto[]>>(`${M_API_URL}/v1/resources`);

    const result = unwrapResult(resp.data);
    return (await result).map((serverDto) => toPermission(serverDto));
  }

  async find(code: string): Promise<RoleDto> {
    const resp = await axios.get<ServerRspDto>(`${M_API_URL}/v1/roles/${code}`);

    return unwrapResult(resp.data);
  }

  async remove(code: string): Promise<void> {
    const resp = await axios.delete<ManagerServerResDto<void>>(`${M_API_URL}/v1/roles/${code}`);
    return unwrapResult(resp.data);
  }

  async create(reqDto: RoleDto): Promise<void> {
    const resp = await axios.post<ManagerServerResDto<void>>(`${M_API_URL}/v1/roles`, reqDto);
    return unwrapResult(resp.data);
  }

  async update(code: string, reqDto: RoleDto): Promise<void> {
    const resp = await axios.put<ManagerServerResDto<void>>(
      `${M_API_URL}/v1/roles/${code}`,
      reqDto
    );
    return unwrapResult(resp.data);
  }
}

const roleApi: RoleApi = new RoleServerApi();

export default roleApi;
