import React from 'react';
import classNames from 'classnames';

import { CommonProps } from 'src/components/helpers/props';

import './AbstractIcon.scss';

interface ArrowRightIconProps extends CommonProps {
  size?: 'md';
}

const ArrowRightIcon: React.FC<ArrowRightIconProps> = ({ classes, size = 'md' }) => {
  return (
    <svg
      className={classNames('icon-root', classes)}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="#666666"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M15 12L9 16L9 8L15 12Z" />
    </svg>
  );
};

export default ArrowRightIcon;
