import config from 'src/shared/config';
import axios from 'src/shared/utils/axios';
import _, { indexOf } from 'lodash';

import { TokenErrorHandling } from 'src/shared/errorHandling';
import { request } from 'http';
import { UserInfoEntity, MemberDto } from 'src/api/userSettingApi';
import { getToken } from 'src/shared/utils/sessionStorageManager';

// const M_API_URL = config.servers.manager.url;

export interface NewClientDto {
  name: string;
}
export interface ClientDto {
  client_id: string;
  name: string;
  engagement_code?: string;
  client_code?: string;
  engagement_partner?: MemberDto[];
  task_type?: string;
  created_at?: string;
}

// export interface ClientFormDto {
//   name: string;
//   engagementCode: string;
//   clientCode: string;
//   engagementPartner: string[];
//   taskType: string;
// }

export function createClient(): ClientDto {
  return {
    client_id: '',
    name: '',
    engagement_code: '',
    client_code: '',
    engagement_partner: [],
    task_type: ''
  };
}


interface ResponseDto {
  data: {
    client_list: ClientDto[];
  },
  err?: string;
}

class clientServerApi {
  async getClientList(): Promise<any> {
    const data = {
      data: {
        access_token: getToken(),
      }
    }
    try {
      const resp = await axios.post<ResponseDto>(`/api/private/GetClientList`, data);

      if (resp.data !== null) {
        // console.log('resp.data', resp.data);
        // const data = resp.data.data;
        if (resp.data.err) {
          return Promise.reject(_.cloneDeep(resp.data.err));
        }
        return Promise.resolve(_.cloneDeep(resp.data.data.client_list || []));
      }
    } catch (e) {
      return Promise.reject(_.cloneDeep(e));
    }


    return [] as ClientDto[];
  }

  async addClient(client: NewClientDto): Promise<any> {

    const request = {
      data: {
        access_token: getToken(),
        client
      }
    };
    try {
      const resp = await axios.post<ResponseDto>(`/api/private/NewClient`, request);
      console.log('resp:', resp);
      if (resp.data !== null) {
        // console.log('resp.data', resp.data);
        // const data = resp.data.data;
        if (resp.data.err) {
          return Promise.reject(_.cloneDeep(resp.data.err));
        }
        return Promise.resolve();
      }
    } catch (e) {
      return Promise.reject(_.cloneDeep(e));
    }
  }

  async deleteClient(client: ClientDto): Promise<any> {
    const request = {
      data: {
        access_token: getToken(),
        client
      }
    };
    try {
      const resp = await axios.post<ResponseDto>(`/api/private/DeleteClient`, request);

      if (resp.data !== null) {
        // console.log('resp.data', resp.data);
        // const data = resp.data.data;
        if (resp.data.err) {
          return Promise.reject(_.cloneDeep(resp.data.err));
        }
        return Promise.resolve(_.cloneDeep(resp.data.data));
      }
    } catch (e) {
      return Promise.reject(_.cloneDeep(e));
    }
  }

  async updateClient(client: ClientDto): Promise<any> {
    const request = {
      data: {
        access_token: getToken(),
        client
      }
    };
    try {
      const resp = await axios.post<ResponseDto>(`/api/private/UpdateClient`, request);

      if (resp.data !== null) {
        // console.log('resp.data', resp.data);
        // const data = resp.data.data;
        if (resp.data.err) {
          return Promise.reject(_.cloneDeep(resp.data.err));
        }
        return Promise.resolve(_.cloneDeep(resp.data.data));
      }
    } catch (e) {
      return Promise.reject(_.cloneDeep(e));
    }
  }

}

const clientApi = new clientServerApi();

export default clientApi;
