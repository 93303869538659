import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import exceptApi, { ExceptDto, ExceptType, ExceptReqDto } from 'src/api/exceptApi';
import { toLocalDateTimeStr } from 'src/shared/utils/localDateTime';
import { RootState } from 'src/store';

// # types
export interface ExceptEntity extends ExceptDto {
  entryTsStr: string;
}
function toEntity(dto: ExceptDto): ExceptEntity {
  return { ...dto, entryTsStr: toLocalDateTimeStr(dto.entryTs) };
}
function toDto(entity: ExceptEntity): ExceptDto {
  return { ...entity };
}
export function toReqDto(entity: ExceptEntity): ExceptReqDto {
  return { ...entity, contentCodes: [entity.contentCode] };
}

interface ExceptEntityByCode {
  [code: string]: ExceptEntity;
}
interface ExceptEntities {
  byCode: ExceptEntityByCode;
  allCodes: string[];
}
interface ExceptStateByType {
  initialized: boolean;
  entities: ExceptEntities;
}
interface ExceptState {
  stateByType: {
    [ExceptType.URL]: ExceptStateByType;
    [ExceptType.PROGRAM]: ExceptStateByType;
    [ExceptType.PRINT_DRIVER]: ExceptStateByType;
    [ExceptType.PRINT_IP]: ExceptStateByType;
  };
}

export interface ExceptUpdateReq {
  code: string;
  reqDto: ExceptReqDto;
}
export interface ExceptUpdateUseReq {
  code: string;
  reqDto: ExceptReqDto;
  use: boolean;
}
export interface ExceptRemoveReq {
  type: ExceptType;
  code: string;
}

// # initial state
const initialState: ExceptState = {
  stateByType: {
    [ExceptType.URL]: {
      initialized: false,
      entities: { byCode: {}, allCodes: [] },
    },
    [ExceptType.PROGRAM]: {
      initialized: false,
      entities: { byCode: {}, allCodes: [] },
    },
    [ExceptType.PRINT_DRIVER]: {
      initialized: false,
      entities: { byCode: {}, allCodes: [] },
    },
    [ExceptType.PRINT_IP]: {
      initialized: false,
      entities: { byCode: {}, allCodes: [] },
    },
  },
};

// # thunks
export const thunkLoadExcepts = createAsyncThunk('except/load', async (type: ExceptType) => {
  const list = await exceptApi.listByType(type);
  return list.map((dto) => toEntity(dto));
});
export const thunkCreateExcept = createAsyncThunk('except/create', async (reqDto: ExceptReqDto) => {
  return toEntity(await exceptApi.create(reqDto));
});
export const thunkUpdateExcept = createAsyncThunk(
  'except/update',
  async ({ code, reqDto }: ExceptUpdateReq) => {
    return toEntity(await exceptApi.update(code, reqDto));
  }
);
export const thunkUpdateExceptUse = createAsyncThunk(
  'except/update/use',
  async ({ code, reqDto, use }: ExceptUpdateUseReq) => {
    const newDto = { ...reqDto, use };
    return toEntity(await exceptApi.update(code, newDto));
  }
);
export const thunkRemoveExcept = createAsyncThunk(
  'except/remove',
  async ({ code }: ExceptRemoveReq) => {
    return exceptApi.remove(code);
  }
);

const exceptSlice = createSlice({
  name: 'except',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      thunkLoadExcepts.fulfilled,
      (state, { payload: excepts, meta: { arg: type } }) => {
        state.stateByType[type].entities.byCode = excepts.reduce((acc, t) => {
          acc[t.code] = t;
          return acc;
        }, {} as ExceptEntityByCode);
        state.stateByType[type].entities.allCodes = excepts.map((t) => t.code);
        state.stateByType[type].initialized = true;
      }
    );
    builder.addCase(thunkCreateExcept.fulfilled, (state, { payload: createdEntity }) => {
      state.stateByType[createdEntity.type].entities.byCode[createdEntity.code] = createdEntity;
      state.stateByType[createdEntity.type].entities.allCodes.push(createdEntity.code);
    });
    builder.addCase(thunkUpdateExcept.fulfilled, (state, { payload: updatedEntity }) => {
      state.stateByType[updatedEntity.type].entities.byCode[updatedEntity.code] = updatedEntity;
    });
    builder.addCase(thunkUpdateExceptUse.fulfilled, (state, { payload: updatedEntity }) => {
      state.stateByType[updatedEntity.type].entities.byCode[updatedEntity.code] = updatedEntity;
    });
    builder.addCase(thunkRemoveExcept.fulfilled, (state, { meta: { arg: removeReq } }) => {
      delete state.stateByType[removeReq.type].entities.byCode[removeReq.code];
      state.stateByType[removeReq.type].entities.allCodes = state.stateByType[
        removeReq.type
      ].entities.allCodes.filter((code) => code !== removeReq.code);
    });
  },
});

// # selectors
export const selectInitialized = (type: ExceptType) => (state: RootState): boolean =>
  state.except.stateByType[type].initialized;
export const selectExceptEntities = (type: ExceptType) => (state: RootState): ExceptEntities =>
  state.except.stateByType[type].entities;
export const selectExceptEntityList = (type: ExceptType) => (state: RootState): ExceptEntity[] =>
  state.except.stateByType[type].entities.allCodes.map(
    (code) => state.except.stateByType[type].entities.byCode[code]
  );

export default exceptSlice.reducer;

// 참고중...
// https://orizens.com/blog/how-to-not-have-a-mess-with-react-hooks-and-redux/
