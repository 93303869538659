import React from 'react';
import classNames from 'classnames';
import { NavLink as RouterLink } from 'react-router-dom';

import { CommonProps } from 'src/components/helpers/props';

import './index.scss';

interface FormLabelProps extends CommonProps {
  fullWidth?: boolean;
}

const FormLabel: React.FC<FormLabelProps> = ({ fullWidth, classes, children }) => {
  return (
    <label
      className={classNames(
        'form-label-root',
        {
          'form-label-full-width': fullWidth,
        },
        classes
      )}
    >
      {children}
    </label>
  );
};

export default FormLabel;
