import React, { useEffect, useState, useContext, Fragment } from 'react';

import { CommonProps } from 'src/components/helpers/props';
import Popup2 from 'src/components/molecules/Popup2';
import Button from 'src/components/atom/Button';
import './AuditFileUploadPopup.scss';
import { FileUploadProgressBar } from './FileUploadProgressBar';

interface AuditPopupProps extends CommonProps {
  open: boolean;
  progressPercent: number;
  type?: string;
  onClose: () => void;
  onAbort: () => void;
}


const AuditFileUploadPopup: React.FC<AuditPopupProps> = ({
  open,
  progressPercent,
  type = "upload",
  onClose,
  onAbort,
  classes,
}) => {

  const [status, setStatus] = useState<string>("준비");

  useEffect(() => {
  }, [progressPercent])

  const handleAbort = () => {
    onAbort();
    setStatus("취소")
    //onClose();
  }

  const title = type === "upload" ? "업로드" : "다운로드";

  return (
    <Popup2
      classes="audit-file-upload-popup-root project-popup-root project-common"
      title={`파일 ${title}`}
      width="600px"
      height="400px"
      overPopup={true}
      open={open}
      onClose={status !== "취소" ? () => { } : onClose}
      footer={
        <React.Fragment>
          {type ? <Button
            text={status !== "취소" ? `${title} 취소` : '닫기'}
            onClick={status !== "취소" ? handleAbort : onClose}
          // disabled={!selectedUsers} 
          /> :
            <div></div>
          }
        </React.Fragment>
      }
    >
      <div className="project-input-area">
        <div style={{ position: "relative", height: "100%", width: "100%" }}>
          {status !== "취소" ?
            <div style={{ width: "100%", height: 60, display: "flex", position: "absolute", bottom: 40, alignItems: "center", flexDirection: "column" }}>
              <FileUploadProgressBar height={20} bgcolor={"red"} progress={progressPercent + "%"} />
              <div style={{ marginTop: 10 }}> {"진행률 : " + progressPercent + "%"}</div>
            </div> :
            <div style={{ width: "100%", height: 60, display: "flex", position: "absolute", bottom: 40, alignItems: "center", flexDirection: "column" }}>
              {`파일 ${title}가 취소 되었습니다.`}</div>}
        </div>

      </div>

    </Popup2 >
  );
};

export default AuditFileUploadPopup;