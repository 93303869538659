import React from 'react';
import ReactDOM from 'react-dom';
import './i18n';
import * as serviceWorker from './serviceWorker';
import App from './App';

import './index.css';
import { Font } from '@react-pdf/renderer';

Font.register({
  family: 'Noto Sans KR',
  fonts: [
    { src: '/resources/fonts/notosans/NotoSansKR-Regular.ttf' },
    { src: '/resources/fonts/notosans/NotoSansKR-Bold.ttf', fontWeight: 'bold' },
  ],
});

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
