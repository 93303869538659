import config from 'src/shared/config';
import axios from 'src/shared/utils/axios';
import _ from 'lodash';

import { TokenErrorHandling } from 'src/shared/errorHandling';
import { request } from 'http';

import { getToken } from 'src/shared/utils/sessionStorageManager';

import { MemberDto } from './templateApi';


export interface RequestResponseDto {
  member: {
    nickname: string;
    account: string;
    request_list: ReviewRequestDto[];
  }
}

export interface RequestResponseDto2 {
  member: {
    nickname: string;
    account: string;
    request_confirm_list: ReviewRequestDto[];
    request_reject_list: ReviewRequestDto[];
  }
}

export interface ReviewRequestDto {
  template_name: string;
  category_name: string;
  task_name: string;
  detail_name: string;
  audit_name: string;
  detail_sub_title_name?: string;
  detail_sub_title_detail_name?: string;
  history?: {
    at: string;
    by: MemberDto;
    comment: string;
    exec_command: string;
  },
  uri?: {
    path: string;
  },
  request_status?: string;
}


interface ResponseDtoA<D> {
  data: D;
  error?: string;
}

class clientServerApi {
  async getRequestList(): Promise<ReviewRequestDto[]> {
    const data = {
      data: {
        access_token: getToken(),
        keyword: '',
        skip: 0,
        limit: 0,
      }
    }

    const resp = await axios.post<ResponseDtoA<RequestResponseDto>>(`/api/private/GetRequestList`, data);

    if (resp.data !== null) {
      // console.log('resp.data', resp.data);
      // const data = resp.data.data;
      return Promise.resolve(_.cloneDeep(resp.data.data.member.request_list || []));
    }

    return [] as ReviewRequestDto[];
  }

  async getRequestDoneList(): Promise<ReviewRequestDto[]> {
    const data = {
      data: {
        access_token: getToken(),
        keyword: '',
        skip: 0,
        limit: 0,
      }
    }

    const resp = await axios.post<ResponseDtoA<RequestResponseDto2>>(`/api/private/GetRequestDoneList`, data);

    if (resp.data !== null) {
      // console.log('resp.data', resp.data);
      // const data = resp.data.data;
      let newArr: any = [];
      newArr = [...newArr, ...(resp.data.data.member.request_confirm_list || []), ...(resp.data.data.member.request_reject_list || [])]
      return Promise.resolve(_.cloneDeep(newArr));
    }

    return [] as ReviewRequestDto[];
  }


}

const requestApi = new clientServerApi();

export default requestApi;
