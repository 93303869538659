import config from 'src/shared/config';
import axios from 'src/shared/utils/axios';
import _ from 'lodash';
import { toLocalDateTimeStr } from 'src/shared/utils/localDateTime';
import { ManagerServerResDto, unwrapResult } from './managerServerApi';

import DummyApi from './dummyApi';
import { times } from './api';
import { getToken } from 'src/shared/utils/sessionStorageManager';

export interface UserInfoDto {
  account: string;
  nickname: string;
  email: string;
  password?: string;
  phone: string;
  employee_number: string;
  role: {
    role_list: string[];
    authority_name: string;
  }
  auth: string;
}

export function createUser(): UserInfoDto {
  return {
    account: '',
    nickname: '',
    email: '',
    password: '',
    phone: '',
    employee_number: '',
    role: {
      role_list: ['Staff'],
      authority_name: 'Staff'
    },
    auth: '일반',
  };
}

interface ResponseDto {
  data: object;
  err?: string;
}


class UserInfoServerApi {

  async addUser(userInfo: UserInfoDto, token?: string): Promise<any> {
    console.log("userInfo:", userInfo)
    const request = {
      data: {
        access_token: token || getToken(),
        member: {
          ...userInfo
        }
      }
    }
    try {
      const resp = await axios.post<ResponseDto>(`/api/private/AddMember`, request);
      if (resp.data !== null) {
        // console.log('resp.data', resp.data);
        if (resp.data.err) {
          return Promise.reject(_.cloneDeep(resp.data.err));
        }
        return Promise.resolve(_.cloneDeep(resp.data.data));
      }
    } catch (e) {
      return Promise.reject(_.cloneDeep(e));
    }
  }

  async editUser(userInfo: UserInfoDto, token?: string): Promise<any> {
    console.log("userInfo:", userInfo)
    const request = {
      data: {
        access_token: token || getToken(),
        member: {
          ...userInfo
        }
      }
    }
    try {
      const resp = await axios.post<ResponseDto>(`/api/private/UpdateMember`, request);
      if (resp.data !== null) {
        // console.log('resp.data', resp.data);
        if (resp.data.err) {
          return Promise.reject(_.cloneDeep(resp.data.err));
        }
        return Promise.resolve(_.cloneDeep(resp.data.data));
      }
    } catch (e) {
      return Promise.reject(_.cloneDeep(e));
    }
  }
  async getUser(userAccount: string, token?: string): Promise<any> {
    console.log("userAccount:", userAccount)
    const request = {
      data: {
        access_token: token || getToken(),
        member: {
          account: userAccount
        }
      }
    }
    try {
      const resp = await axios.post<ResponseDto>(`/api/private/GetMember`, request);
      if (resp.data !== null) {
        // console.log('resp.data', resp.data);
        if (resp.data.err) {
          return Promise.reject(_.cloneDeep(resp.data.err));
        }
        return Promise.resolve(_.cloneDeep(resp.data.data));
      }
    } catch (e) {
      return Promise.reject(_.cloneDeep(e));
    }

  }

  async changePassword(userAccount: string, password: string, token?: string): Promise<any> {
    console.log("userAccount:", userAccount)
    const request = {
      data: {
        access_token: token || getToken(),
        member: {
          account: userAccount,
          password
        }
      }
    }
    try {
      const resp = await axios.post<ResponseDto>(`/api/private/ChangePassword`, request);
      if (resp.data !== null) {
        // console.log('resp.data', resp.data);
        if (resp.data.err) {
          return Promise.reject(_.cloneDeep(resp.data.err));
        }
        return Promise.resolve(_.cloneDeep(resp.data.data));
      }
    } catch (e) {
      return Promise.reject(_.cloneDeep(e));
    }

  }


}

const userTemplateApi = new UserInfoServerApi();

export default userTemplateApi;
