import React from 'react';
import classNames from 'classnames';

import { CommonProps } from 'src/components/helpers/props';

import './AbstractIcon.scss';

interface ArrowForwardIcon extends CommonProps {
  size?: 'md';
}

const ArrowForwardIcon: React.FC<ArrowForwardIcon> = ({ classes, size = 'md' }) => {
  return (
    <svg
      className={classNames('icon-root', classes)}
      width="36"
      height="36"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M9.5 8L14.5 12L9.5 16" stroke="#333333" />
    </svg>
  );
};

export default ArrowForwardIcon;
