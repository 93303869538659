import React, { useEffect } from 'react';
import { Route, Switch as RouterSwitch, useHistory } from 'react-router-dom';

import productApi from 'src/api/auth/productApi';
import useAuthStore from 'src/hooks/auth/useAuthStore';
import { useAlert } from 'src/contexts/AlertContext';
import config from 'src/shared/config';
import { singleRouteTable } from 'src/shared/routes';
import { setProductLocalStorage, setProductRegisteredLocalStorage } from 'src/shared/utils/product';
import { getToken } from 'src/shared/utils/sessionStorageManager';

import BaseLayout from './BaseLayout';
import SingleLayout from './SingleLayout';

// menus 가져오기
const Layout: React.FC = () => {
  const { alert } = useAlert();


  useEffect(() => {
    // productApi
    //   .products()
    //   .then((result) => {
    //     setProductLocalStorage(result);
    //     setProductRegisteredLocalStorage(true);
    //   })
    //   .catch((err) => alert('product 목록을 가져올 수 없습니다!'));
  }, [alert]);

  return (
    <RouterSwitch>
      {singleRouteTable.map((rt) => {
        return <Route key={rt.fullPath} path={rt.fullPath} exact={rt.exact}>
          <SingleLayout>
            {rt.component ? <rt.component /> : <React.Fragment>TODO : ERROR</React.Fragment>}
          </SingleLayout>
        </Route>
      }

      )}
      <Route component={BaseLayout} />
    </RouterSwitch>
  );
};

export default Layout;
