import React, { useCallback, useState, createContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { SerializedError } from '@reduxjs/toolkit';

import { ApiErrorCode } from 'src/shared/utils/axios';

import Alert from '../components/molecules/Alert';
import Button from '../components/atom/Button';
import useAuthStore from 'src/hooks/auth/useAuthStore';




interface AlertContextResult {
  alert: (error: string | Error | SerializedError) => void;
  alert2: (title: string, error: string | Error | SerializedError) => void;
  // alert3은 alert2와 같지만 느낌표아이콘이 나오지 않는 info성 메시지 출력용, 그리고 onConfirm
  // alert3: (title: string, error: string | Error | SerializedError, onConfirm: (result: boolean) => void) => void;
  confirm: (message: string, onConfirm: (result: boolean) => void) => void;
  confirm2: (title: string, message: string, onConfirm: (result: boolean) => void) => void;
  confirmWithWarn: (title: string, message: string, onConfirm: (result: boolean) => void) => void;
  confirmWithWarn2: (title: string, message: string, onConfirm: (result: boolean) => void, onCancel: () => void) => void;
}

interface AlertProps {
  open: boolean;
  title?: string;
  message: string;
  classes?: string;
  type: 'alert' | 'alert2' | 'alert3' | 'confirm' | 'confirm2' | 'confirmWithWarn' | 'confirmWithWarn2';
  onConfirm?: (bool: boolean) => void;
  onCancel?: () => void;
}

const AlertContext = createContext<AlertContextResult>({
  alert: (error: string | Error | SerializedError) => console.log(error),
  alert2: (title: string, error: string | Error | SerializedError) => console.log(error),
  // alert3: (title: string, error: string | Error | SerializedError, onConfirm: (result: boolean) => void) => console.log(error),
  confirm: (message: string, onConfirm: (result: boolean) => void) => console.log(message),
  confirm2: (title: string, message: string, onConfirm: (result: boolean) => void) => console.log(message),
  confirmWithWarn: (title: string, message: string, onConfirm: (result: boolean) => void) => console.log(message),
  confirmWithWarn2: (title: string, message: string, onConfirm: (result: boolean) => void, onCancel: () => void) => console.log(message)
});

const initAlert: AlertProps = {
  open: false,
  message: '',
  title: '',
  type: 'alert',
  onConfirm: (bool: boolean) => console.log(bool),
  onCancel: () => console.log(),
};



const AlertProvider = ({ children }: { children: React.ReactNode }): JSX.Element => {
  const { t } = useTranslation(['commons', 'error']);
  const [state, setState] = useState<AlertProps>(initAlert);
  const { authEntity, startSignOut } = useAuthStore();
  const unSetAlert = useCallback(() => {
    setState(initAlert);
  }, [setState]);

  const makeMessage = (err: string) => {
    switch (err) {
      // LOGIN
      case "login-id not found":
        return "아이디가 존재하지 않습니다."
      case "password is incorrect":
        return "패스워드가 정확하지 않습니다."
      case "on a new device":
        return "새로운 디바이스에서 로그인을 시도하는 경우 운영자에게 확인 메일을 발송합니다.\n운영자 승인 후 재시도 바랍니다."
      case "no permission":
        return "권한이 없습니다."
      case "user-id duplicated":
        return "사용자 아이디 중복입니다."
      // INVALID TOKEN
      case "invalid token":
        return "다른 기기에 연결되어 현재의 서버 연결이 종료되었습니다.\n로그아웃 됩니다. 다시 로그인 해주세요."
      default:
        return t(`error:api.${err}`, t(`error:api.${ApiErrorCode.UNKNOWN_ERROR}`)) + ` (${err})`
    }

  }

  const alert = useCallback(
    (error: string | Error | SerializedError) => {
      let message;
      if (!error) {
        message = t(`error:api.${ApiErrorCode.UNKNOWN_ERROR}`);
      } else if (typeof error === 'string') {
        message = error;
      } else if ('message' in error && error.message) {
        message = error.message;
      } else if ('name' in error && error.name) {
        message = makeMessage(error.name);
        if (error.name === "invalid token") {
          startSignOut();
        }
      } else {
        message = t(`error:api.${ApiErrorCode.UNKNOWN_ERROR}`);
      }
      setState({
        open: true,
        type: 'alert',
        message,
      });
    },
    [t]
  );
  const alert2 = useCallback(
    (title: string, error: string | Error | SerializedError) => {
      let message;
      if (!error) {
        message = t(`error:api.${ApiErrorCode.UNKNOWN_ERROR}`);
      } else if (typeof error === 'string') {
        message = error;
      } else if ('message' in error && error.message) {
        message = error.message;
      } else if ('name' in error && error.name) {
        message = makeMessage(error.name);
        if (error.name === "invalid token") {
          startSignOut();
        }
      } else {
        message = t(`error:api.${ApiErrorCode.UNKNOWN_ERROR}`);
      }
      setState({
        open: true,
        type: 'alert2',
        message,
        title,
      });
    },
    [t]
  );
  // const alert3 = useCallback(
  //   (title: string, error: string | Error | SerializedError, onConfirm) => {
  //     let message;
  //     if (!error) {
  //       message = t(`error:api.${ApiErrorCode.UNKNOWN_ERROR}`);
  //     } else if (typeof error === 'string') {
  //       message = error;
  //     } else if ('message' in error && error.message) {
  //       message = error.message;
  //     } else if ('name' in error && error.name) {
  //       message = t(`error:api.${error.name}`, t(`error:api.${ApiErrorCode.UNKNOWN_ERROR}`));
  //     } else {
  //       message = t(`error:api.${ApiErrorCode.UNKNOWN_ERROR}`);
  //     }
  //     setState({
  //       open: true,
  //       type: 'alert3',
  //       message,
  //       onConfirm,
  //       title,
  //     });
  //   },
  //   [t]
  // );
  const confirm = useCallback((message, onConfirm) => {
    setState({
      open: true,
      type: 'confirm',
      message: message,
      onConfirm: onConfirm,
    });
  }, []);


  const confirm2 = useCallback((title, message, onConfirm) => {
    setState({
      open: true,
      type: 'confirm2',
      message: message,
      onConfirm: onConfirm,
      title: title,
    });
  }, []);

  const confirmWithWarn = useCallback((title, message, onConfirm) => {
    setState({
      open: true,
      type: 'confirmWithWarn',
      message: message,
      onConfirm: onConfirm,
      title: title,
    });
  }, []);

  const confirmWithWarn2 = useCallback((title, message, onConfirm, onCancel) => {
    setState({
      open: true,
      type: 'confirmWithWarn',
      message: message,
      onConfirm: onConfirm,
      onCancel: onCancel,
      title: title,
    });
  }, []);

  const { type, open, message, title, onConfirm, onCancel } = state;

  useEffect(() => {
    return () => setState(initAlert); // cleanup function을 이용
  }, []);

  return (
    <AlertContext.Provider value={{ alert, alert2, confirm, confirm2, confirmWithWarn, confirmWithWarn2 }}>
      {children}
      <Alert
        footer={
          type === 'alert' ? (
            <React.Fragment>
              <Button text={t('commons:ok')} onClick={unSetAlert} />
            </React.Fragment>
          ) : type === 'alert2' ? (
            <React.Fragment>
              <Button text={t('commons:ok')} onClick={unSetAlert} />
            </React.Fragment>
            // ) : type === 'alert3' ? (
            //   <React.Fragment>
            //     <Button text={t('commons:ok')}
            //       onClick={() => {
            //         if (onConfirm) onConfirm(true);
            //         unSetAlert();
            //       }} />
            //   </React.Fragment>
          ) : (
            <React.Fragment>
              <Button color="secondary" text={t('commons:cancel')} onClick={() => {
                onCancel && onCancel();
                unSetAlert();
              }} />
              <Button
                text={t('commons:ok')}
                onClick={() => {
                  if (onConfirm) onConfirm(true);
                  unSetAlert();
                }}
              />
            </React.Fragment>
          )
        }
        onClose={() => {
          onCancel && onCancel();
          unSetAlert();
        }}
        open={open}
        title={title}
        type={type}
      >
        {(type !== 'confirm' && type !== 'confirm2') && <div style={{
          width: 50, height: 50, borderRadius: 25, backgroundColor: "#ff6565",
          color: "white", justifyContent: "center", alignItems: "center", display: "flex"
        }}>
          <div style={{ fontSize: 40, textAlign: "center", fontWeight: "bolder" }}>{"!"}
          </div>
        </div>}
        <div style={{ paddingLeft: 20, lineHeight: 1.5, color: "#4d4f5c" }}>{message}</div>
      </Alert>
    </AlertContext.Provider>
  );
};

const useAlert = (): AlertContextResult => {
  const context = React.useContext(AlertContext);
  if (context === undefined) {
    throw new Error('useAlert must be used within a UserProvider');
  }

  return context;
};

export { AlertProvider, useAlert };
