import React from 'react';
import classNames from 'classnames';
import { NavLink as RouterLink } from 'react-router-dom';

import { CommonProps } from 'src/components/helpers/props';

import './index.scss';
interface TextLinkProps extends CommonProps {
  to: string;
  v?: 1 | 2;
}

const TextLink: React.FC<TextLinkProps> = ({ to, v, classes, children }) => {
  return (
    <RouterLink
      to={to}
      className={classNames(
        'text-link-root',
        {
          'text-link-v2': v === 2,
        },
        classes
      )}
    >
      {children}
    </RouterLink>
  );
};

export default TextLink;
