import { RouteTable } from 'src/shared/routes';
import _ from 'lodash';

export const MENU_PREFIX_FSP_UI = 'fspui-';
export const MENU_PREFIX_COMMON_UI = 'commonui-';

/**
 * 로그인 후 첫번째 권한 페이지로 이동 & 권한 없는 페이지 접근 시 첫번째 권한 페이지로 이동
 */

const menus = [
  {
    menu: 'fspui-home',
    page: '/dashboard',
  },
  {
    menu: 'fspui-poltpl',
    page: '/pol-tpl',
  },
  {
    menu: 'fspui-poltpl-basic',
    page: '/pol-tpl/basic',
  },
  {
    menu: 'fspui-poltpl-detection',
    page: '/pol-tpl/detection',
  },
  {
    menu: 'fspui-poltpl-wat',
    page: '/pol-tpl/wat',
  },
  {
    menu: 'fspui-policy',
    page: '/policy',
  },
  {
    menu: 'fspui-policy-policies',
    page: '/policy/policies',
  },
  {
    menu: 'fspui-policy-review',
    page: '/policy/pol-review',
  },
  {
    menu: 'fspui-except',
    page: '/except',
  },
  {
    menu: 'fspui-except-urls',
    page: '/except/urls',
  },
  {
    menu: 'fspui-except-programs',
    page: '/except/programs',
  },
  {
    menu: 'fspui-except-printers',
    page: '/except/printers',
  },
  {
    menu: 'fspui-log',
    page: '/log',
  },
  {
    menu: 'fspui-log-printings',
    page: '/log/printings',
  },
  {
    menu: 'fspui-log-detections',
    page: '/log/detections',
  },
  {
    menu: 'fspui-statistics',
    page: '/statistics',
  },
  {
    menu: 'fspui-statistics-user',
    page: '/statistics/user',
  },
  {
    menu: 'fspui-statistics-group',
    page: '/statistics/group',
  },
  {
    menu: 'fspui-statistics-program',
    page: '/statistics/program',
  },
  {
    menu: 'fspui-statistics-printerdriver',
    page: '/statistics/printer-driver',
  },
  {
    menu: 'fspui-statistics-pattern',
    page: '/statistics/pattern',
  },
  {
    menu: 'fspui-statistics-encryption',
    page: '/statistics/encryption',
  },
  {
    menu: 'fspui-etc',
    page: '/etc',
  },
  {
    menu: 'fspui-etc-mgmt',
    page: '/etc/mgmt',
  },
  {
    menu: 'fspui-etc-setting',
    page: '/etc/setting',
  },
  {
    menu: 'commonui-policy',
    page: '/common/policy',
  },
  {
    menu: 'commonui-policy-template',
    page: '/common/policy/template',
  },
  {
    menu: 'commonui-policy-policies',
    page: '/common/policy/policies',
  },
  {
    menu: 'commonui-policy-file',
    page: '/common/policy/file',
  },
  {
    menu: 'commonui-pol-review',
    page: '/common/pol-review',
  },
  {
    menu: 'commonui-mgmt',
    page: '/common/mgmt',
  },
  {
    menu: 'commonui-mgmt-programs',
    page: '/common/mgmt/programs',
  },
  {
    menu: 'commonui-mgmt-printers',
    page: '/common/mgmt/printers',
  },
  {
    menu: 'commonui-mgmt-urls',
    page: '/common/mgmt/urls',
  },
  {
    menu: 'commonui-pattern',
    page: '/common/pattern',
  },
  {
    menu: 'commonui-pattern-setting',
    page: '/common/pattern/setting',
  },
  {
    menu: 'commonui-pattern-validation',
    page: '/common/pattern/validation',
  },
];

// 메뉴로 라우팅 page주소 확인
export const moveToPageAfterLogin = (menu: string): string => {
  const index = menus.findIndex((m) => m.menu === menu);
  if (index > -1) return menus[index].page;
  else return '';
};

// 라우팅 page주소로 메뉴 확인
export const getMenuByPage = (page: string): string => {
  const index = menus.findIndex((m) => m.page === page);
  if (index > -1) return menus[index].menu;
  else return '';
};

export const isAllowedMenu = (
  requiredMenus: string[] | undefined,
  userAllowedMenus: string[]
): boolean => {
  // console.log(userAllowedMenus);
  if (requiredMenus === undefined || requiredMenus.length === 0) {
    return true;
  }
  if (userAllowedMenus.length === 0) {
    return false;
  }
  return _.intersection(requiredMenus, userAllowedMenus).length > 0;
};

export const getFirstAllowedMenuPath = (
  menus: string[],
  mainPageRouteTables: RouteTable[]
): string | undefined => {
  // const unit =
  //   process.env.REACT_APP_TYPE === 'fsp'
  //     ? 'fspui-'
  //     : process.env.REACT_APP_TYPE === 'common'
  //     ? 'commonui-'
  //     : 'fspui-';

  // const findMenu =
  //   menus.filter((m) => m.split(unit).length > 1).length > 0
  //     ? menus.filter((m) => m.split(unit).length > 1)[0]
  //     : '';

  // const findRouteTables = routeTables
  //   .filter(({ menu }) => menu)
  //   .filter((depth) => depth.adminScopes?.includes(findMenu))
  //   .reduce((acc, curDepth) => {
  //     return acc.concat(curDepth.subs);
  //   }, [] as RouteTable[])
  //   .filter((sub) => sub.adminScopes?.includes(findMenu));

  // return findRouteTables.length > 0 ? findRouteTables[0].fullPath : config.url('/');
  const units = ['fspui-'];

  const relatedMenus = menus.filter((menu) => units.some((unit) => menu.includes(unit)));
  if (relatedMenus.length === 0) {
    return undefined;
  }

  // 접속 가능한 첫번째 depth의 URL 찾기
  const findRouteTables = mainPageRouteTables
    // 페이지 컴포넌트가 정의된 것을 필터링
    // 권한이 정의되지 않은(없어도 되는) 페이지이거나, 권한이 있는 페이지를 필터링
    .filter(
      ({ menu, component, adminScopes }) =>
        menu &&
        component &&
        (!adminScopes ||
          adminScopes.length === 0 ||
          _.intersection(adminScopes, relatedMenus).length > 0)
    );

  return findRouteTables.length > 0 ? findRouteTables[0].fullPath : undefined;
};
