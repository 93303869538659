import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import DetectionTemplateApi, {
  DetectionTemplateDto,
  DetectionTemplateReqDto,
} from 'src/api/detectionTemplateApi';
import { toLocalDateTimeStr } from 'src/shared/utils/localDateTime';
import { RootState } from 'src/store';

// # types
export interface DetectionTemplateEntity extends DetectionTemplateDto {
  entryTsStr: string;
}

function toEntity(dto: DetectionTemplateDto): DetectionTemplateEntity {
  return { ...dto, entryTsStr: toLocalDateTimeStr(dto.entryTs) };
}

function toDto(entity: DetectionTemplateEntity): DetectionTemplateDto {
  return { ...entity };
}

interface DetectionTemplateEntityByCode {
  [code: string]: DetectionTemplateEntity;
}

export interface DetectionTemplateEntities {
  byCode: DetectionTemplateEntityByCode;
  allCodes: string[];
}

interface DetectionTemplateState {
  initialized: boolean;
  entities: DetectionTemplateEntities;
}

export interface DetectionTemplateUpdateReq {
  code: string;
  reqDto: DetectionTemplateReqDto;
}
export interface DetectionTemplateUpdateUseReq {
  code: string;
  reqDto: DetectionTemplateDto;
  use: boolean;
}

// # initial state
const initialState: DetectionTemplateState = {
  initialized: false,
  entities: { byCode: {}, allCodes: [] },
};

// # thunks
export const thunkLoadDetectionTemplates = createAsyncThunk('detectionTemplate/load', async () => {
  const list = await DetectionTemplateApi.list();
  return list.map((dto) => toEntity(dto));
});
export const thunkCreateDetectionTemplate = createAsyncThunk(
  'detectionTemplate/create',
  async (reqDto: DetectionTemplateReqDto) => {
    return toEntity(await DetectionTemplateApi.create(reqDto));
  }
);
export const thunkUpdateDetectionTemplate = createAsyncThunk(
  'detectionTemplate/update',
  async ({ code, reqDto }: DetectionTemplateUpdateReq) => {
    return toEntity(await DetectionTemplateApi.update(code, reqDto));
  }
);
export const thunkUpdateDetectionTemplateUse = createAsyncThunk(
  'detectionTemplate/update/use',
  async ({ code, reqDto, use }: DetectionTemplateUpdateUseReq) => {
    const newDto = { ...reqDto, use };
    return toEntity(await DetectionTemplateApi.update(code, newDto));
  }
);
export const thunkRemoveDetectionTemplate = createAsyncThunk(
  'detectionTemplate/remove',
  async (code: string) => {
    return DetectionTemplateApi.remove(code);
  }
);

const detectionTemplate = createSlice({
  name: 'detectionTemplate',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(thunkLoadDetectionTemplates.fulfilled, (state, { payload: detectionTemplates }) => {
        state.entities.byCode = detectionTemplates.reduce((acc, t) => {
          acc[t.code] = t;
          return acc;
        }, {} as DetectionTemplateEntityByCode);
        state.entities.allCodes = detectionTemplates.map((t) => t.code);
        state.initialized = true;
      })
      .addCase(thunkCreateDetectionTemplate.fulfilled, (state, { payload: createdEntity }) => {
        state.entities.byCode[createdEntity.code] = createdEntity;
        state.entities.allCodes.push(createdEntity.code);
      })
      .addCase(thunkUpdateDetectionTemplate.fulfilled, (state, { payload: updateEntity }) => {
        state.entities.byCode[updateEntity.code] = updateEntity;
      })
      .addCase(thunkUpdateDetectionTemplateUse.fulfilled, (state, { payload: updateEntity }) => {
        state.entities.byCode[updateEntity.code] = updateEntity;
      })
      .addCase(thunkRemoveDetectionTemplate.fulfilled, (state, { meta: { arg: removedCode } }) => {
        delete state.entities.byCode[removedCode];
        state.entities.allCodes = state.entities.allCodes.filter((code) => code !== removedCode);
      });
  },
});

// # selectors
export const selectInitialized = (state: RootState): boolean => {
  return state.detectionTemplate.initialized;
};
export const selectDetectionTemplateEntities = (state: RootState): DetectionTemplateEntities =>
  state.detectionTemplate.entities;
export const selectDetectionTemplateEntityList = (state: RootState): DetectionTemplateEntity[] =>
  state.detectionTemplate.entities.allCodes.map(
    (code) => state.detectionTemplate.entities.byCode[code]
  );
export const selectUseDetectionTemplateEntityList = (state: RootState): DetectionTemplateEntity[] =>
  state.detectionTemplate.entities.allCodes
    .filter((code) => state.detectionTemplate.entities.byCode[code].use === true)
    .map((code) => state.detectionTemplate.entities.byCode[code]);

export default detectionTemplate.reducer;
