import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import DotIcon from 'src/components/atom/icons/DotIcon';
import ReactQuill, { Quill } from 'react-quill';
import Button from 'src/components/atom/Button';

import './ProcedureTask.scss';


const ProcedureTask: React.FC<{
  editMode: boolean,
  description: string,
  auditConclusion: string,
  detailSubList: any,
  type?: string;
  handleChange: (html: string, type: string) => void;
  handleCancelRequirement: () => void;
  handleConfirmRequirement: () => void;
}> = ({
  editMode,
  description,
  auditConclusion,
  detailSubList,
  type = "custom",
  handleChange,
  handleCancelRequirement,
  handleConfirmRequirement,
}) => {

    return (
      <div className="sp-procedure-detail-body">
        <div className="sp-procedure-detail-body-title element">
          <div className="title" style={{ paddingLeft: 10 }}><DotIcon classes="dot-icon" color='#43425d' />절차설명</div>
        </div>
        <div className="sp-procedure-detail-body-requirements2">
          <div className={classNames("requirements-editor")}>
            <ReactQuill
              modules={{
                toolbar: [
                  [
                    { list: "ordered" },
                    { list: "bullet" },
                    { indent: "-1" },
                    { indent: "+1" },
                  ],

                ]
              }}
              readOnly={!editMode}
              value={description || ''}
              style={{ fontSize: 50 }}
              onChange={(value) => handleChange(value, "description")}
            />
          </div>
        </div>
        {type === "default" && !editMode &&
          <div>
            <div className="sp-procedure-detail-body-title element">
              <div className="title" style={{ paddingLeft: 10 }}><DotIcon classes="dot-icon" color='#43425d' />경영진 주장</div>
            </div>
            <div className="sp-procedure-detail-ceo-insists">
              {detailSubList?.map((item: any, i: number) => {
                if (item.checked) {
                  return (
                    <div className="sp-procedure-detail-ceo-insists-item" key={i.toString()}>
                      {item.key}
                    </div>
                  )
                }
              })}
            </div>
          </div>
        }
        <div className="sp-procedure-detail-body-title element">
          <div className="title" style={{ paddingLeft: 10 }}><DotIcon classes="dot-icon" color='#43425d' />감사결론</div>
        </div>
        <div className="sp-procedure-detail-body-requirements">
          <div className={classNames("requirements-editor")}>
            <ReactQuill
              modules={{
                toolbar: [
                  [
                    { list: "ordered" },
                    { list: "bullet" },
                    { indent: "-1" },
                    { indent: "+1" },
                  ],

                ]
              }}
              readOnly={!editMode}
              value={auditConclusion || ''}
              style={{ fontSize: 50 }}
              onChange={(value) => handleChange(value, "auditConclusion")}
            />
          </div>
        </div>

        {editMode && <div className="sp-procedure-detail-footer common-page-footer project-footer">
          <div className={classNames('right-button')}>
            <Button classes="footer-button" text="취소" color="secondary" onClick={handleCancelRequirement} />
            <Button classes="footer-button" text="확인" onClick={handleConfirmRequirement} />
          </div>
        </div>}
      </div>)
  }

export default ProcedureTask;