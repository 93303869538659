import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import positionApi, { PositionDto } from 'src/api/fidp/positionApi';
import { RootState } from 'src/store';

// # types
export type PositionEntity = PositionDto;

function toEntity(dto: PositionDto): PositionEntity {
  return { ...dto };
}

interface PositionEntityByCode {
  [code: string]: PositionEntity;
}
export interface PositionEntities {
  byCode: PositionEntityByCode;
  allCodes: string[];
}
interface PositionState {
  initialized: boolean;
  entities: PositionEntities;
}

// # initial state
const initialState: PositionState = {
  initialized: false,
  entities: { byCode: {}, allCodes: [] },
};

// # thunks
export const thunkLoadPositions = createAsyncThunk('fidp/position/load', async () => {
  const list = await positionApi.list();
  return list.map((dto) => toEntity(dto));
});

const positionSlice = createSlice({
  name: 'position',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(thunkLoadPositions.fulfilled, (state, { payload: positions }) => {
      state.entities.byCode = positions.reduce((acc, t) => {
        acc[t.code] = t;
        return acc;
      }, {} as PositionEntityByCode);
      state.entities.allCodes = positions.map((t) => t.code);
      state.initialized = true;
    });
  },
});

// # selectors
export const selectInitialized = (state: RootState): boolean => {
  return state.position.initialized;
};
export const selectPositionEntities = (state: RootState): PositionEntities =>
  state.position.entities;
export const selectPositionEntityList = (state: RootState): PositionEntity[] =>
  state.position.entities.allCodes.map((code) => state.position.entities.byCode[code]);

export default positionSlice.reducer;
