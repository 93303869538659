import React, { useRef, useState, useEffect } from 'react'
import classNames from 'classnames';

import { CommonProps } from 'src/components/helpers/props';
import ModalPortal from 'src/shared/react-utils/ModalPortal';
import useWindowSize from 'src/hooks/useWindowSize';
import './ProgressBar.scss';
interface ProgessBarProps {
  bgcolor?: string;
  progress?: string;
  height?: number;
}


export const ProgressBar: React.FC<ProgessBarProps> = ({ bgcolor, progress, height }) => {

  const Parentdiv = {
    height: height,
    width: '80%',
    backgroundColor: '#707070',
    borderRadius: 40,
  }

  const Childdiv = {
    height: '100%',
    width: `${progress}`,
    backgroundColor: bgcolor,
    borderRadius: 10,
  }

  const Childdiv2 = {
    height: '100%',
    width: '100%',
    backgroundColor: '#707070',
    borderRadius: 10,
  }

  const progresstext = {
    color: 'black',
    visibility: 'hidden',
    "&:hover": {
      visibility: 'visible'
    },
  }

  return (

    <div style={Parentdiv}>
      <div style={progress === "0%" ? Childdiv2 : Childdiv}>
        {/* <span style={progresstext}>{`${progress}`}</span> */}
        <div style={{ height: "100%", width: "100%", position: "relative" }}>
          <ToolTip message={progress} classes="progress-tooltip" tooltipClasses="progress-tooltip-class" relative_position={progress === "0%" ? 0 : 100}>
          </ToolTip>
        </div>
      </div>
    </div >

  )
}





interface ToolTipProps extends CommonProps {
  position?: 'top';
  maxWidth?: number;
  message: string | React.ReactNode;
  children: React.ReactNode;
  tooltipClasses?: string;
  adjTop?: number;
  relative_position?: number
}

interface Position {
  left: number;
  top: number;
}

const ToolTip: React.FC<ToolTipProps> = ({
  message,
  maxWidth = 400,
  classes,
  children,
  position = 'top',
  tooltipClasses,
  adjTop = 0,
  relative_position = 0,
}) => {
  const targetRef = useRef<HTMLSpanElement>(null);
  const toolTipRef = useRef<HTMLParagraphElement>(null);
  const [windowInnerWidth, windowInnerHeight] = useWindowSize();

  const [state, setState] = useState<Position>({
    left: 0,
    top: 0,
  });
  const [hover, setHover] = useState(false);

  useEffect(() => {
    // 상대 위치
    const targetClientRect = (targetRef.current as Element).getBoundingClientRect();
    const relativeTop = targetClientRect.top;
    // 스크롤된 길이
    const scrolledTopLength = window.pageYOffset;
    // 절대 좌표
    const absoluteTop = scrolledTopLength + relativeTop;

    const absoluteLeft = window.pageXOffset + targetClientRect.left;

    const left = absoluteLeft - (toolTipRef.current as HTMLElement).offsetWidth / 2 + (targetClientRect.width * (relative_position / 100)) - 5;
    const top = absoluteTop - ((toolTipRef.current as HTMLElement).offsetHeight + 12) + adjTop;

    setState({
      left:
        left < 0
          ? 0
          : left,
      top:
        top < 0
          ? 0
          : top + (targetRef.current as HTMLElement).offsetHeight > windowInnerHeight
            ? windowInnerHeight - (targetRef.current as HTMLElement).offsetHeight
            : top,
    });
  }, [hover, windowInnerHeight, windowInnerWidth]);

  return (
    <React.Fragment>
      <span
        className={classNames(classes)}
        onMouseEnter={(e: React.MouseEvent<HTMLSpanElement>) => {
          setHover(true);
        }}
        onMouseLeave={(e) => {
          setHover(false);
        }}
        ref={targetRef}
        style={{
          display: 'inline-block',
        }}
      >
        {children}
      </span>
      <ModalPortal>
        <div
          className={classNames('tool-tip-root', {
            on: hover,
          })}
        >
          <p
            ref={toolTipRef}
            style={{ left: `${state.left}px`, top: `${state.top}px`, maxWidth: `${maxWidth}px` }}
            className={tooltipClasses}
          >
            <span>{message}</span>
          </p>
        </div>
      </ModalPortal>
    </React.Fragment>
  );
};

export default ToolTip;
