import React from 'react';
import classNames from 'classnames';

import { CommonProps } from 'src/components/helpers/props';

import './index.scss';

interface GridItemProps extends CommonProps {
  xs: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
}

export const GridItem: React.FC<GridItemProps> = ({ xs, classes, children }) => {
  return (
    <div className={classNames('grid-item-root', `grid-item-xs-${xs}`, classes)}>{children}</div>
  );
};

interface GridProps extends CommonProps {
  spacing: 0 | 5 | 10;
  direction?: 'row' | 'column';
}

const Grid: React.FC<GridProps> = ({ spacing, direction = 'row', classes, children }) => {
  return (
    <div
      className={classNames(
        'grid-root',
        `grid-spacing-${spacing}`,
        `grid-direction-${direction}`,
        classes
      )}
    >
      {children}
    </div>
  );
};

export default Grid;
