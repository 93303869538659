/* eslint-disable @typescript-eslint/camelcase */
import _ from 'lodash';
import qs from 'query-string';
import { v4 as uuidv4 } from 'uuid';

import config from 'src/shared/config';
import axios, { errorCodeByStatus } from 'src/shared/utils/axios';
import DummyApi from '../dummyApi';
import { times } from '../api';
import i18n, { DEFAULT_LANGUAGE, getLanguage } from 'src/i18n';
import { getUserUUIDLocalStorage, setUserUUIDLocalStorage } from 'src/shared/utils/auth';

const GATEWAY_API_ENABLED = config.servers.gateway.enabled;
// const GATEWAY_API_URL = config.servers.gateway.url;
const GATEWAY_API_URL = config.servers.manager.url2;
const AUTH_API_ENABLED = config.servers.auth.enabled;
const AUTH_API_URL = config.servers.auth.url;
const FXM_API_ENABLED = config.servers.fxm.enabled;
const FXM_API_URL = config.servers.fxm.url;

interface LoginServerDto {
  access_token: string;
  token_type: string;
  expires_in: number;
  scope: string;
  need_password_change: boolean;
  password_change_alert: boolean;
  managed_dept_codes: string[];
  jti: string;
}
interface LoginServerErrorDto {
  status: number;
  message: string;
}

export interface LoginDto {
  accessToken: string;
  tokenType: string;
  expiresIn: number;
  scope: string;
  needPasswordChange: boolean;
  passwordChangeAlert: boolean;
  managedDeptCodes: string[];
  jti: string;
}

interface LoginServerDto2 {
  data: {
    access_token: string;
    refresh_token: string;
    expires_in: number;
    status: number;
  }
}

export interface LoginDto2 {
  accessToken: string;
  refreshToken: string;
  expiresIn: number;
  status: number;
}

export interface TokenDto {
  alg: string;
  value: string;
}

interface SessionInitReqDto {
  userId: string;
  deviceId: string;
  timestamp: number;
  url: string;
}
interface SessionInitResDto {
  timestamp: number;
  url: string;
}

// export interface TokenValidDto {
//   msg: string;
//   data: string;
//   type: string;
// }

interface AuthApi {
  logIn(username: string, password: string, redirectUrl: string): Promise<LoginDto2>;
  logInWithToken(token: string): Promise<LoginDto>;
  logOut(username?: string): Promise<void>;
  getTokenKey(): Promise<TokenDto>;
  // hasTokenKey(): Promise<TokenValidDto>;
  sessionInit(reqDto: SessionInitReqDto): Promise<SessionInitResDto>;
  fxmSessionDestroy(): Promise<void>;
  loginWithRefreshToken(refreshToken: string): Promise<LoginDto2>;
}

class AuthServerApi implements AuthApi {
  async logIn(username: string, password: string, redirectUrl: string): Promise<LoginDto2> {
    // const params = new URLSearchParams();
    // params.append('user', username);
    // params.append('password', password);
    // params.append('redirectUrl', redirectUrl);
    // params.append('lang', getLanguage());
    console.log('apiAuth > logIn', username, password)
    let uuid = getUserUUIDLocalStorage(username);
    console.log('apiAuth > getUserUUIDLocalStorage: ' + uuid);

    if (!uuid) {
      uuid = uuidv4();
      console.log('Your UUID is: ' + uuid);
      setUserUUIDLocalStorage(username, uuid);
    }


    const data = {
      data: {
        member: {
          account: username,
          password: password,
        },
        device_id: uuid
      }
    };

    const resp = await axios.post<LoginServerDto2 & LoginServerErrorDto>(
      '/api/public/Login',
      data
    );
    // const login = await axios.post<{ access_token: string }>(GATEWAY_API_URL + 'v1/login', { user: 'jiu', password: 'aml1' });

    if (resp.data.status < 200 || resp.data.status >= 300) {
      const err: LoginServerErrorDto = { status: resp.data.status, message: resp.data.message };
      throw err;
    }

    const loginDto = this.toLoginDto2(resp.data);
    return Promise.resolve(loginDto);
  }

  async logInWithToken(token: string): Promise<LoginDto> {
    const params = new URLSearchParams();
    params.append('bearer', token);

    const resp = await axios.post<LoginServerDto>(
      `${GATEWAY_API_URL}/session/login-with-token`,
      params
    );
    const loginDto = this.toLoginDto(resp.data);
    return Promise.resolve(loginDto);
  }
  async logOut(username?: string): Promise<void> {
    await Promise.resolve();
    // await axios.post<void>(`${GATEWAY_API_URL}/v1/logout`, { timeout: 3000 });
  }
  async getTokenKey(): Promise<TokenDto> {
    const resp = await axios.get<TokenDto>(`${AUTH_API_URL}/oauth/token_key`);
    return resp.data;
  }
  // hasTokenKey(): Promise<TokenValidDto>;
  async sessionInit(reqDto: SessionInitReqDto): Promise<SessionInitResDto> {
    const resp = await axios.post<SessionInitResDto>(`${AUTH_API_URL}/idp/session-init`, reqDto);
    return resp.data;
  }
  async fxmSessionDestroy(): Promise<void> {
    if (!FXM_API_ENABLED) return;
    await axios.delete<void>(`${FXM_API_URL}/sessions/destroy`);
  }

  private toLoginDto(serverDto: LoginServerDto): LoginDto {
    const loginDto: LoginDto = {
      accessToken: serverDto.access_token,
      tokenType: serverDto.token_type,
      expiresIn: serverDto.expires_in,
      scope: serverDto.scope,
      needPasswordChange: serverDto.need_password_change,
      passwordChangeAlert: serverDto.password_change_alert,
      managedDeptCodes: serverDto.managed_dept_codes,
      jti: serverDto.jti,
    };

    return loginDto;
  }

  private toLoginDto2(serverDto: LoginServerDto2): LoginDto2 {
    const loginDto: LoginDto2 = {
      accessToken: serverDto.data.access_token,
      refreshToken: serverDto.data.refresh_token,
      expiresIn: serverDto.data.expires_in,
      status: serverDto.data.status,
    };
    return loginDto;
  }

  async loginWithRefreshToken(refreshToken: string): Promise<LoginDto2> {
    // const data = {
    //   refresh_token: refreshToken,
    // };

    // const resp = await axios.post<LoginServerDto2 & LoginServerErrorDto>(
    //   `${GATEWAY_API_URL}/v1/login`,
    //   data
    // );

    // if (resp.data.status < 200 || resp.data.status >= 300) {
    //   const err: LoginServerErrorDto = { status: resp.data.status, message: resp.data.message };
    //   throw err;
    // }

    const loginDto = {} as LoginDto2;
    return Promise.resolve(loginDto);
  }
}

class AuthDummyApi implements AuthApi {
  logIn(username: string, password: string, redirectUrl: string): Promise<LoginDto2> {
    console.log('HHL-DEBUG DummayAPI LOGIN');
    return this.dummyLogin2();
  }

  async logInWithToken(token: string): Promise<LoginDto> {
    return this.dummyLogin();
  }

  logOut(username?: string): Promise<void> {
    return Promise.resolve();
  }

  getTokenKey(): Promise<TokenDto> {
    const dto: TokenDto = {
      alg: 'SHA256withRSA',
      value:
        '-----BEGIN PUBLIC KEY-----\nMIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAhDuFOAuVOdM3/xGWPt/RBAy8/1JwswfV3JmQjapa5K8UuOtSFdV09Ix/2CsYZerta0Eor3X7froqq9rdgv3zffnAHJyIFOOo/jBWDR9iyIZhl2U347miIi6Z6WzQecOhX3ZKLJgEAtEiumDsaGlWMQl5AQwao0dBDRV0RYAh+ZyPw0wR977LIH66sKtEWFh18BC3GSnuBJF7yymu6hSWji851KNrCAAHeuhn0Te4VNWQasfkzIweB1dhMCqszT3pnOC5I0gQtfjeL1MJS1nYiWtEKX1ig3Ni/ykcXdw2NvNA61A2DN2/QphetIJjz+QVtKm3CICGPjZsWxaW+CtSkQIDAQAB\n-----END PUBLIC KEY-----',
    };

    // GET auth/oauth/token_key
    return Promise.resolve(dto);
  }

  // hasTokenKey(): Promise<TokenValidDto> {
  //   const dto: TokenValidDto = {
  //     msg: 'OAuth token in session.',
  //     data:
  //       '{"access_token":"eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJuZWVkX3Bhc3N3b3JkX2NoYW5nZSI6ZmFsc2UsInJlcXVlc3RfaXAiOiIxOTIuMTY4LjIxLjE3MSIsInVzZXJfaW5mbyI6eyJ1c2VySWQiOiJhZG1pbiIsInVzZXJOYW1lIjoiQWRtaW5pc3RyYXRvciIsInVzZXJFbWFpbCI6InBna2FuZ0BmYXNvby5jb20iLCJkZXB0Q29kZSI6IkNPTVBBTlkiLCJkZXB0TmFtZSI6IkZhc29vIiwicG9zaXRpb25Db2RlIjoiUDAwMSIsInBvc2l0aW9uTmFtZSI6IuyCrOybkCIsInJvbGVDb2RlIjoiUjAwMSIsInJvbGVOYW1lIjoi7J2867CYIn0sInVzZXJfbmFtZSI6ImFkbWluIiwic2NvcGUiOlsid3JpdGUiLCJyZWFkIl0sInBhc3N3b3JkX2NoYW5nZV9hbGVydCI6bnVsbCwibWFuYWdlZF9kZXB0X2NvZGVzIjpbXSwiZXhwIjoxNjAyMjIwNzk4LCJhdXRob3JpdGllcyI6WyJVU0VSIiwiQURNSU4iXSwianRpIjoiYmVjYjdiMzEtMGY5Ny00YWE0LWE4NzgtYTAxZjAwN2MyMTg1IiwiY2xpZW50X2lkIjoiMGQyMDE5ZTYzNDExNDViMGJkYzdkN2FkMmI3NWExMWYifQ.fBnkTOWaFhsWbS28Ej_vrcZbq7ltXc1cFvc_qmi5nV3zpitUNgLtnLEtZxNr2QpXyfkHWGpKpO4KQU8mkx_YjcL8QxDUWCuY_EaPL6rEW4ffk2XlzOgQy7D0-RLvir_pSNLZIPe4z-ziANQlxoSjHkYnN4hSdeVBRydrP5Z_UdbAWqvF-5tyJXWnMhfDi5_uvKtovC7okVB0x3JIBSdZmMqh-2uA7OUTQ-7WIez4UlokzFHj5f2LJHg3f6Uiu9c-UWZi-NP5fG7ToOpIUi9v8tHYVOsa2Dym_UlQ_rLEQ26MuS92KZrLW7g6P90NnsjgfPcGkApMJNh2dM9wnr9GQQ","token_type":"bearer","expires_in":86399,"scope":"write read","request_ip":"192.168.21.171","need_password_change":false,"password_change_alert":null,"managed_dept_codes":[],"jti":"becb7b31-0f97-4aa4-a878-a01f007c2185"}',
  //     type: 'oauth_token',
  //   };

  //   // GET auth/hastoken API ?redirectUrl=+encodeURI(window.self.url)
  //   return Promise.resolve(dto);
  // }

  async sessionInit(reqDto: SessionInitReqDto): Promise<SessionInitResDto> {
    const resDto: SessionInitResDto = {
      timestamp: reqDto.timestamp,
      url: qs.stringifyUrl({
        url: reqDto.url,
        query: {
          bearer:
            'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoiYWRtaW4iLCJ1c2VyX25hbWUiOiJBZG1pbmlzdHJhdG9yIiwic2NvcGUiOlsicmVhZCJdLCJleHAiOjE2MDUwODgwNzksImF1dGhvcml0aWVzIjpbIlRFTVAiXSwianRpIjoiZDdhOTllYWQtZWI2MC00MTE4LTk4NzctYzE2NTQxZGFmODk3IiwiY2xpZW50X2lkIjoiYTk0YjNlMDY5ZmE5NGZlZmE1YjNhZjcwYjQ1M2M3ZWQifQ.OnR_B659CKtrBrRjUfNAO14ePQzdV8ecsonWpTEBUh7uvDHfRQC4KmJnWvMongizH_-UAYtNnjTh_hRuAfSnOMeCuRHLdSXPTf0_sdLQ7nvyUY3moI16DoaC4fDf5zvcVUdRnxhI5FGu9j8nihrgitEg54Ztjowe7qdZGOhrOS-z_sOJ14h7hj94SM7nsj44iippOFyJZAt9FTMuJXNuz1IH3en2SpGo8fhqZLLwmFHxZGboxLyGU79e7QuH1KHSJgxbhlvOwT2ZHLqaVTLLSY4fVYeJhkTxn6Wk3o5_RaETx1EHPxjijEUcDwz1zYh7PWnVr_BBm1X1YxoNZ7pEpg',
        },
      }),
    };

    return Promise.resolve(resDto);
  }

  async fxmSessionDestroy(): Promise<void> {
    return Promise.resolve();
  }

  private dummyLogin(): Promise<LoginDto> {
    const serviceDto: LoginServerDto = {
      access_token:
        'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJuZWVkX3Bhc3N3b3JkX2NoYW5nZSI6ZmFsc2UsInJlcXVlc3RfaXAiOiIxOTIuMTY4LjIxLjE3MSIsInVzZXJfaW5mbyI6eyJ1c2VySWQiOiJhZG1pbiIsInVzZXJOYW1lIjoiQWRtaW5pc3RyYXRvciIsInVzZXJFbWFpbCI6InBna2FuZ0BmYXNvby5jb20iLCJkZXB0Q29kZSI6IkNPTVBBTlkiLCJkZXB0TmFtZSI6IkZhc29vIiwicG9zaXRpb25Db2RlIjoiUDAwMSIsInBvc2l0aW9uTmFtZSI6IuyCrOybkCIsInJvbGVDb2RlIjoiUjAwMSIsInJvbGVOYW1lIjoi7J2867CYIn0sInVzZXJfbmFtZSI6ImFkbWluIiwic2NvcGUiOlsid3JpdGUiLCJyZWFkIl0sInBhc3N3b3JkX2NoYW5nZV9hbGVydCI6bnVsbCwibWFuYWdlZF9kZXB0X2NvZGVzIjpbXSwiZXhwIjoxNjAyMjMyMzkwLCJhdXRob3JpdGllcyI6WyJVU0VSIiwiQURNSU4iXSwianRpIjoiZTVhY2FmZWItYmVhYy00YjcxLWIzZTMtMWU5MTU4YmI2YmYxIiwiY2xpZW50X2lkIjoiMGQyMDE5ZTYzNDExNDViMGJkYzdkN2FkMmI3NWExMWYifQ.ccYVxdIwvEAkGiuF5YC-qfOGPX5cl6zC07wniwgHJ1Ubh-P7nkpG8ePRO9_4poWYlu3cAgx6jdC-v1S3v-eDD2HZ7nNd7saSTmYk6QuSzaKZbOEiDw9MA4V8P6ajh6FyeLRXSZ2LEgzurRQWDAE3tuO3P23NBBUwQh9_lpRHLw7ULynulv2sRV7yRifWtJaFRMQ0OSUSTWMltRNloeGKFOvINX-BXNM83QeYQv8sMhiVicnNvcJbVVnjcuoWPd4eVTVtMc7ZdAb3eXtyqajfj3KW1A0FGXEB1LjCu7wIYvclEIpdxctirENX0AhPF7lILmu_52H8Ur8JzmmlARtKEA',
      token_type: 'bearer',
      expires_in: 0,
      scope: 'write read',
      need_password_change: false,
      password_change_alert: false,
      managed_dept_codes: [],
      jti: 'dd785282-9032-474f-8e06-1d16c87f9fe0',
    };

    const dto: LoginDto = {
      accessToken: serviceDto.access_token,
      tokenType: serviceDto.token_type,
      expiresIn: serviceDto.expires_in,
      scope: serviceDto.scope,
      needPasswordChange: serviceDto.need_password_change,
      passwordChangeAlert: serviceDto.password_change_alert,
      managedDeptCodes: serviceDto.managed_dept_codes,
      jti: serviceDto.jti,
    };

    // POST auth/oauth/fasoo-token API
    return Promise.resolve(dto);
  }

  private dummyLogin2(): Promise<LoginDto2> {
    const serviceDto: LoginServerDto2 = {
      data: {
        access_token:
          'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJjbGllbnRfdGltZSI6bnVsbCwiZXhwaXJlX3RpbWUiOjE2MDkxNDY0MjgyOTIsInN0YXRlIjoiRkx1c0lnPT0iLCJ1c2VyIjoiZXZhbF91c2VyIiwiYXBwX2lkIjpudWxsfQ==.Wgpi8pB5NKjWs/dRf7xaz5yjkSeEjwrcLS5NhiHGAM0=',
        refresh_token:
          'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJjbGllbnRfdGltZSI6bnVsbCwiZXhwaXJlX3RpbWUiOjE2MDkyMzEwMjgyOTIsInN0YXRlIjoiUkw4ZFNBPT0iLCJ1c2VyIjoiZXZhbF91c2VyIiwiYXBwX2lkIjpudWxsfQ==.YMBD/3ONr84vaGSeVsZ0GDgG+UhKv8jMlQRNXn1vDZc=',
        expires_in: 1800000,
        status: 200,
      }

    };

    const dto: LoginDto2 = {
      accessToken: serviceDto.data.access_token,
      refreshToken: serviceDto.data.refresh_token,
      expiresIn: serviceDto.data.expires_in,
      status: serviceDto.data.status,
    };

    // POST auth/oauth/fasoo-token API
    return Promise.resolve(dto);
  }

  loginWithRefreshToken(refreshToken: string): Promise<LoginDto2> {
    console.log('HHL-DEBUG DummayAPI LOGIN');
    return this.dummyLogin2();
  }
}

// TODO auth api 분리필요할 듯 (gateway/auth)
const authApi: AuthApi =
  GATEWAY_API_ENABLED && AUTH_API_ENABLED ? new AuthServerApi() : new AuthDummyApi();

export default authApi;
