import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import Button from 'src/components/atom/Button';
import FormGroup from 'src/components/atom/FormGroup';
import TextField from 'src/components/atom/TextField';
import RadioGroup from 'src/components/atom/RadioGroup';
import Radio from 'src/components/atom/Radio';
import _ from 'lodash';

import useInputs from 'src/hooks/useInputs';
import { CommonProps } from 'src/components/helpers/props';
import Popup2 from 'src/components/molecules/Popup2';
import { validateAll2 } from 'src/shared/utils/validations';
import { useAlert } from 'src/contexts/AlertContext';
import userApi, { UserInfoDto, createUser } from 'src/api/userApi'

import './EditUserPopup.scss';
import { useToast } from 'src/contexts/ToastContext';

interface EditUserPopupProps extends CommonProps {

  userAccount: string;
  open: boolean;
  onClose: () => void;
  // selected: UserInfoEntity[];
}

const EditUserPopup: React.FC<EditUserPopupProps> = ({
  userAccount,
  open,
  onClose,
  classes,
  // roleList,
  // permissionList,
}) => {
  const { t } = useTranslation(['commons']);
  const { alert, alert2, confirmWithWarn } = useAlert();
  const [form, onChange, onChangeElem, reset] = useInputs<UserInfoDto>(createUser());
  const validationRules = {
    // id: _.trim(project.id).length > 0 && validationId(project.id),
    // name: _.trim(form.name).length > 0,
    // projectRole: selectedUsers.length > 0,
    account: _.trim(form.account).length > 0,
    name: _.trim(form.nickname).length > 0,
    employee_number: _.trim(form.employee_number).length > 0,
  };
  const toast = useToast();

  const handleOk = (): void => {
    const r = validateAll2(validationRules)
    if (!r.retVal) {
      if (r.key === "account") {
        alert2("사용자 추가", "아이디는 필수입니다.");
      } else if (r.key === "name") {
        alert2("사용자 추가", "이름은 필수입니다.");
      } else if (r.key === "employee_number") {
        alert2("사용자 추가", "사번은 필수입니다.");
      } else {
        alert2("사용자 추가", "필수값을 확인해 주세요.");
      }
      return;
    }
    const onConfirm = () => {
      console.log("form :", form)
      userApi.editUser(form)
        .then(data => {
          toast.info('사용자 정보가 수정되었습니다.');
          onClose();
        })
        .catch(err => {
          alert2("사용자 수정", err);
        })
    }
    confirmWithWarn("사용자 수정", `사용자(${form.nickname}) 정보를 수정하시겠습니까?`, onConfirm)
  };
  const handleCancel = (): void => {
    onClose();
  };

  const onResetPassword = (): void => {
    const onConfirm = () => {
      userApi.changePassword(userAccount, userAccount)
        .then(data => {
        })
        .catch(err => {
          alert2("비밀번호 재설정", err);
        })
    }
    confirmWithWarn("비밀번호 재설정", `사용자(${form.nickname})의 비밀번호를 초기화하시겠습니까?`, onConfirm)
  }


  const onChangeRole = (value: string): void => {
    onChangeElem("auth", value || "")
    if (value === "관리자") {
      onChangeElem("role", { role_list: ["Engagement Partner"], authority_name: "Admin" })
    } else if (value === "파트너") {
      onChangeElem("role", { role_list: ["Engagement Partner"], authority_name: "Staff" })
    } else { //일반
      onChangeElem("role", { role_list: ["Staff"], authority_name: "Staff" })
    }
  }

  useEffect(() => {
    userApi.getUser(userAccount)
      .then(resp => {
        console.log("EditUserPopup : ", resp)
        let auth = "일반"
        if (resp.member.role.authority_name === "Admin") {
          auth = "관리자"
        } else if (resp.member.role.authority_name === "Staff") {
          auth = "일반"
        }
        // setUserInfo({
        //   ...resp.member, auth
        // });
        onChangeElem("account", resp.member.account)
        onChangeElem("nickname", resp.member.nickname)
        onChangeElem("employee_number", resp.member.employee_number)
        onChangeElem("email", resp.member.email)
        onChangeElem("phone", resp.member.phone)
        onChangeElem("auth", auth)
        if (auth === "관리자") {
          onChangeElem("role", { role_list: ["Engagement Partner"], authority_name: "Admin" })
        } else if (auth === "파트너") {
          onChangeElem("role", { role_list: ["Engagement Partner"], authority_name: "Staff" })
        } else { //일반
          onChangeElem("role", { role_list: ["Staff"], authority_name: "Staff" })
        }
      })
      .catch(err => {
        console.log("EditUserPopup error : ", err)
      })

  }, [])

  return (
    <Popup2
      classes="userInfo-popup-root"
      title="사용자 수정"
      width="1112px"
      height="692px"
      open={open}
      onClose={handleCancel}
      footer={
        <React.Fragment>
          <Button text={t('commons:cancel')} onClick={handleCancel} color="secondary" />
          <Button
            text={t('commons:ok')}
            onClick={handleOk}
          // disabled={!selectedUsers} 
          />
        </React.Fragment>
      }
    >
      <FormGroup spacing={6}>
        <span className='input-label'>아이디</span>
        <TextField name='account' disabled
          //placeholder="아이디를 입력하세요"
          value={form?.account} fullWidth={true} />
      </FormGroup>
      <FormGroup spacing={6}>
        <span className='input-label'>이름</span>
        <TextField name='nickname'
          //placeholder="이름을 입력하세요"
          value={form?.nickname} fullWidth={true} onChange={onChange} />
      </FormGroup>
      <FormGroup spacing={6}>
        <span className='input-label'>사번</span>
        <TextField name='employee_number'
          //placeholder="사번을 입력하세요"
          value={form?.employee_number} fullWidth={true} onChange={onChange} />
      </FormGroup>
      <FormGroup spacing={6}>
        <span className='input-label'>이메일</span>
        <TextField name='email'
          //placeholder="이메일주소를 입력하세요"
          value={form?.email} fullWidth={true} onChange={onChange} />
      </FormGroup>
      <FormGroup spacing={6}>
        <span className='input-label'>전화번호</span>
        <TextField name='phone'
          //placeholder="전화번호를 입력하세요"
          value={form?.phone} fullWidth={true} onChange={onChange} />
      </FormGroup>
      <FormGroup spacing={6}>
        <span className='input-label'>업무성격</span>
        <RadioGroup
          name="auth" value={form?.auth}
          onChange={(value) => onChangeRole(value)}
        >
          <Radio label="관리자" value={'관리자'} />
          <Radio label="일반" value={'일반'} />
        </RadioGroup>
      </FormGroup>
      <FormGroup spacing={6}>
        <span className='input-label'>비밀번호 재설정</span>
        <Button text={'초기화'} color="fourth" autoWidth
          onClick={onResetPassword}
        />
      </FormGroup>
      {/* <FormGroup spacing={6}>
        <span className='input-label'>비밀번호 수정</span>
        <TextField name='password1' classes="password1" type="password"
          placeholder="변경 할 비밀번호를 입력하세요."
          value={password.first} fullWidth={true} onChange={onChange} />
        <TextField name='password2' classes="password2" type="password"
          placeholder="다시 비밀번호를 입력하세요."
          value={password.second} fullWidth={true} onChange={onChange} />
      </FormGroup>
      {password.first !== password.second && <FormGroup spacing={6}>
        <span className='input-label'></span>
        <div className="password-error">* 입력한 비밀번호가 동일하지 않습니다 </div>
      </FormGroup>} */}
    </Popup2>
  );
};

export default EditUserPopup;
