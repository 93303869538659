import React from 'react';
import classNames from 'classnames';

import { CommonProps } from 'src/components/helpers/props';

import './AbstractIcon.scss';

interface CircleBeforeIconProps extends CommonProps {
  disabled?: boolean;
  size?: 'md';
}

const CircleBeforeIcon: React.FC<CircleBeforeIconProps> = ({ classes, disabled = false, size = 'md' }) => {
  return (
    <svg
      className={classNames('icon-root', 'icon-circle-before', classes)}
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill='white'
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="16" cy="16" r="15.5" transform="rotate(-180 16 16)" fill="white" stroke="#D1D6DB"/>
      <path d="M18 12L14 16L18 20" stroke="#6B7480" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
};

export default CircleBeforeIcon;
