import React from 'react';
import classNames from 'classnames';

import { CommonProps } from 'src/components/helpers/props';

import './AbstractIcon.scss';

interface DeleteLineIconProps extends CommonProps {
  size?: 'md' | 'sm';
}

const DeleteLineIcon: React.FC<DeleteLineIconProps> = ({ classes, size = 'md' }) => {
  if (size === 'md') {
    return (
      <svg
        className={classNames('icon-root', classes)}
        width="20"
        height="20"
        viewBox="0 0 20 20"
        color="#6B7480"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2.5 5H4.16667H17.5"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.66663 4.99984V3.33317C6.66663 2.89114 6.84222 2.46722 7.15478 2.15466C7.46734 1.8421 7.89127 1.6665 8.33329 1.6665H11.6666C12.1087 1.6665 12.5326 1.8421 12.8451 2.15466C13.1577 2.46722 13.3333 2.89114 13.3333 3.33317V4.99984M15.8333 4.99984V16.6665C15.8333 17.1085 15.6577 17.5325 15.3451 17.845C15.0326 18.1576 14.6087 18.3332 14.1666 18.3332H5.83329C5.39127 18.3332 4.96734 18.1576 4.65478 17.845C4.34222 17.5325 4.16663 17.1085 4.16663 16.6665V4.99984H15.8333Z"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.6666 9.1665V14.1665"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.33337 9.1665V14.1665"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  } else if (size === 'sm') {
    return (
      <svg
        className={classNames('icon-root', classes)}
        width="16"
        height="16"
        viewBox="0 0 16 16"
        color="#6B7480"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2 4H3.33333H14"
          stroke="currentColor"
          strokeWidth="1.6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.3335 3.99998V2.66665C5.3335 2.31302 5.47397 1.97389 5.72402 1.72384C5.97407 1.47379 6.31321 1.33331 6.66683 1.33331H9.3335C9.68712 1.33331 10.0263 1.47379 10.2763 1.72384C10.5264 1.97389 10.6668 2.31302 10.6668 2.66665V3.99998M12.6668 3.99998V13.3333C12.6668 13.6869 12.5264 14.0261 12.2763 14.2761C12.0263 14.5262 11.6871 14.6666 11.3335 14.6666H4.66683C4.31321 14.6666 3.97407 14.5262 3.72402 14.2761C3.47397 14.0261 3.3335 13.6869 3.3335 13.3333V3.99998H12.6668Z"
          stroke="currentColor"
          strokeWidth="1.6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.3335 7.33331V11.3333"
          stroke="currentColor"
          strokeWidth="1.6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.6665 7.33331V11.3333"
          stroke="currentColor"
          strokeWidth="1.6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  } else return null;
};

export default DeleteLineIcon;
