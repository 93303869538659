import React, { useState, useEffect } from 'react';
import { NavLink as RouterLink, useHistory, useParams, useLocation, } from 'react-router-dom';

import AnalyticDID from 'src/components/atom/icons/AnalyticDID';
import Button from 'src/components/atom/Button';
import config from 'src/shared/config';
import { getAdidManagerUser, getLoginWarningFlag, setLoginWarningFlag } from 'src/shared/utils/sessionStorageManager';
import BNHLogo from 'src/components/atom/icons/BNHLogo';
import { useAlert } from 'src/contexts/AlertContext';
import './TopBar.scss';
import useAuthStore from 'src/hooks/auth/useAuthStore';
import ProjectIcon from 'src/components/atom/icons/ProjectIcon';
import IconButton from 'src/components/atom/IconButton';
import SettingsIcon from 'src/components/atom/icons/SettingsIcon';
import UserInfoPopup from './UserInfoPopup';
import classNames from 'classnames';
import usePopup from 'src/hooks/usePopup';

const TopBar: React.FC = () => {
  const { authEntity, startSignOut } = useAuthStore();
  const history = useHistory();
  const userId = getAdidManagerUser();
  const location = useLocation();
  const userInfoPopup = usePopup();
  const { confirmWithWarn2 } = useAlert();
  const [initPass, setInitPass] = useState<boolean>(false);
  const LogOutClick = (): void => {
    // history.push(config.url('/sign-out'));
    startSignOut();
  };

  const handleRouteProject = () => {
    history.push(config.url('/projects'));

  }

  const handleRouteRequest = () => {
    history.push(config.url('/requests'));

  }

  const handleRouteSettings = () => {
    history.push(config.url('/admin'));

  }

  const handleClosePopup = () => {
    setInitPass(false);
    userInfoPopup.closePopup();
  }

  const handleOpenPopup = () => {
    userInfoPopup.openPopup()
  }

  useEffect(() => {
    if (getLoginWarningFlag() === "need") {
      confirmWithWarn2("경고", "초기 비밀번호를 변경하지 않은 경우 로그아웃됩니다.\n확인을 누르면 비밀번호 수정 페이지로 이동합니다.", () => {
        setInitPass(true);
        handleOpenPopup();
      }, () => LogOutClick())
      setLoginWarningFlag("not need");
    }
  }, [])

  // currentUrlUtils.getPath();
  const pathname = location.pathname;
  const isProject = /^\/projects*/.test(pathname);
  const isRequest = /^\/requests*/.test(pathname);
  const isSetting = /^\/admin*/.test(pathname);

  //TODO. user role에 admin이 있는 경우 setting 아이콘 활성화

  // console.log('TOPBAR > authEntity', authEntity)
  const isAdmin = authEntity && authEntity.userInfo.role.authority_name === "Admin" ? true : false;

  return (
    <div className={'layout-topbar-root'}>
      <div className={'layout-topbar-leftside'}>
        {/* <MenuIcon /> */}
        {/* <AnalyticDID /> */}
        {/* TODO. logo 로고 */}
        <span className="logo">
          {/* <em className="txt-hide">logo</em> */}
          {/* <em>BnH Stella Project</em> */}
          {/* <BNHLogo size='sm' /> */}
          <img className="logoImg" src={require("src/resources/images/bnhLogo3.png")} onClick={handleRouteProject}>
          </img>
        </span>
      </div>
      <div className={'layout-topbar-rightside'}>
        {isAdmin && <IconButton classes={classNames("setting-button menu-button", { 'selected': isSetting })}
          onClick={handleRouteSettings}>
          <SettingsIcon />
        </IconButton>}
        <div className={classNames("request-button menu-button", { 'selected': isRequest })}
          onClick={handleRouteRequest}>R</div>
        <IconButton classes={classNames("project-button menu-button", { 'selected': isProject })}
          onClick={handleRouteProject}>
          <ProjectIcon />
        </IconButton>
        <div className="name-root" onClick={handleOpenPopup}>
          <span className="name">{authEntity?.userInfo?.nickname}</span>
        </div>
        <Button
          classes={'report-action-button'}
          text="로그아웃"
          onClick={LogOutClick}
          color="secondary"
        />
      </div>
      {userInfoPopup.opened && <UserInfoPopup
        open={userInfoPopup.opened}
        userAccount={authEntity?.userInfo?.account || ""}
        onClose={initPass ? () => { handleClosePopup(); LogOutClick(); } : handleClosePopup}
      />}
    </div>
  );
};

export default TopBar;
