import React, { useEffect, useState } from 'react';
import templateApi, {
  DetailDto,
  AuditDto,
  SpecialDetailDto,
} from 'src/api/templateApi';
import { useHistory, useParams } from 'react-router-dom';
import usePopup from 'src/hooks/usePopup';
import { useAlert } from 'src/contexts/AlertContext';
import classNames from 'classnames';
import EditIcon from 'src/components/atom/icons/EditIcon';
import IconButton from 'src/components/atom/IconButton';
import DotIcon from 'src/components/atom/icons/DotIcon';
import ReactQuill, { Quill } from 'react-quill';
import Button from 'src/components/atom/Button';
import projectApi, { ProjectDto, emptyProject } from 'src/api/projectApi';
import ArrowDownIcon from 'src/components/atom/icons/ArrowDownIcon';
import ArrowUpIcon from 'src/components/atom/icons/ArrowUpIcon';
import RemoveIcon from 'src/components/atom/icons/RemoveIcon';
import Step1EditPopup from './Step1EditPopup';
import './Step1.scss';

const DetailTaskElement: React.FC<{
  detailContent: { name: string, detail_sub_content_list: any },
  detailVisible: boolean,
  onHandleEdit: (item: any) => void;
}> = ({
  detailContent,
  detailVisible,
  onHandleEdit,
}) => {
    // console.log("detailContent : ", detailContent)
    const [visible, setVisible] = useState<boolean>(detailVisible);

    const handleEdit = (e: React.MouseEvent<HTMLButtonElement>, item: any) => {
      e.stopPropagation();
      onHandleEdit && onHandleEdit(item);
    }
    const isProjectDisabled = projectApi.isProjectDisabled();
    return (
      <div>
        <div className="sp-step1-detail-body-title element" style={{ paddingLeft: 10 }}
          onClick={() => setVisible(!visible)}
        >
          {
            detailContent?.detail_sub_content_list?.length > 0 ?
              visible ? <ArrowUpIcon classes='icon-up' /> :
                <ArrowDownIcon fill="#bcbccb" classes='icon-down' />
              :
              <RemoveIcon fill="#bcbccb" classes='icon-down' />

          }
          <div className="sp-step1-detail-account">{detailContent.name}</div>
          <div className="sp-step1-detail-account-edit-button">
            <IconButton disabled={isProjectDisabled} onClick={(e) => handleEdit(e, detailContent)}>
              <EditIcon size="sm" />
            </IconButton>
          </div>
        </div>
        {visible && detailContent.detail_sub_content_list?.map((elem: any, i: number) => {
          return (
            <div className="sp-step1-detail-body-table" key={i.toString()}>
              <div className="sp-step1-detail-body-table-title">
                <DotIcon color="#4d4f5c" />
                <div style={{ marginLeft: 10 }}>{elem.name}</div>
              </div>
              <div className="sp-step1-detail-body-table-data">
                {elem.management_assertion_list?.map((item: any, idx: number) => {
                  return (
                    <div key={idx.toString()} className="sp-step1-detail-body-table-item" style={{ borderWidth: item.checked ? 1 : 0 }}>
                      <div>{item.checked ? item.key : ""}</div>
                    </div>
                  )
                })
                }
              </div>
            </div>
          )
        })}
      </div >
    )
  }

const Step1: React.FC<{
  detailTaskName: string,
  editMode: boolean,
  detailSubList?: any,
  requirements: string,
  handleChange: (html: string) => void;
  handleCancelRequirement: () => void;
  handleConfirmRequirement: () => void;
  handleConfirmDetail?: (newDetailData: any) => void;
}> = ({
  detailTaskName,
  editMode,
  requirements,
  detailSubList,
  handleChange,
  handleCancelRequirement,
  handleConfirmRequirement,
  handleConfirmDetail = () => { }
}) => {
    const step1EditPopup = usePopup();
    const [account, setAccount] = useState();

    const handleDetailTaskElement = (item: any) => {
      setAccount(item)
      step1EditPopup.openPopup();
    }
    const renderDetailBody = () => {
      return detailSubList?.map((elem: any, idx: number) => {
        return <DetailTaskElement key={'detailTaskElement' + idx}
          detailContent={elem}
          detailVisible={false}
          onHandleEdit={handleDetailTaskElement}
        />
      })
    }

    const handleConfirm = (opinions: any) => {
      let newTestData = [...detailSubList];
      let idx = newTestData.findIndex(obj => obj.name === opinions.name);
      if (idx !== -1) {
        newTestData[idx] = opinions
      }
      // console.log("newTestData : ", newTestData)
      handleConfirmDetail([newTestData[idx]]);
      step1EditPopup.closePopup();
    }

    const handleClose = () => {
      step1EditPopup.closePopup()
    }

    return (
      <div className="sp-step1-detail-body">
        <div className="sp-step1-detail-body-title element">
          <div className="title" style={{ paddingLeft: 10 }}><DotIcon classes="dot-icon" color='#43425d' />요구사항</div>
        </div>
        <div className="sp-step1-detail-body-requirements">
          <div className={classNames("requirements-editor")}>
            <ReactQuill
              modules={{
                toolbar: [
                  [
                    { list: "ordered" },
                    { list: "bullet" },
                    { indent: "-1" },
                    { indent: "+1" },
                  ],

                ]
              }}
              readOnly={!editMode}
              value={requirements || ''}
              style={{ fontSize: 50 }}
              onChange={handleChange}
            />
          </div>
          {!editMode && <div className="sp-step1-detail-body-title element">
            <div className="header" style={{ paddingLeft: 10 }}>
              <div className="account">
                계정
              </div>
              <div className="insist">
                경영진 주장
              </div>
            </div>
          </div>}
          {!editMode && <div className="sp-step1-detail-body-content">
            {renderDetailBody()}
          </div>}
        </div>
        {editMode && <div className="sp-step1-detail-footer common-page-footer project-footer">
          <div className={classNames('right-button')}>
            <Button classes="footer-button" text="취소" color="secondary" onClick={handleCancelRequirement} />
            <Button classes="footer-button" text="확인" onClick={handleConfirmRequirement} />
          </div>
        </div>}
        {step1EditPopup.opened &&
          <Step1EditPopup
            open={step1EditPopup.opened}
            account={account || {}}
            onClose={handleClose}
            onConfirm={handleConfirm}

          />
        }
      </div>)
  }

export default Step1;