import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';

import { CommonProps } from 'src/components/helpers/props';
import ErrorPageIcons, { ErrorPageIconComponent } from 'src/components/atom/icons/ErrorPageIcons';
import Button from 'src/components/atom/Button';
import config from 'src/shared/config';

import './index.scss';
import useAuthStore from 'src/hooks/auth/useAuthStore';

interface SingleErrorPageProps extends CommonProps {
  iconComponent: ErrorPageIconComponent;
  text: string | React.ReactNode;
}

const SingleErrorPage: React.FC<SingleErrorPageProps> = ({
  iconComponent: IconComponent,
  text,
  classes,
}) => {
  const { t } = useTranslation(['commons']);

  const { startSignOut } = useAuthStore();

  const handleClickSignOut = (): void => {
    // signOut();
    // history.push(config.url('/sign-out'));
    startSignOut();
  };

  return (
    <div className={classNames('single-error-page-root', classes)}>
      <span className="single-error-page-icon-area">
        <IconComponent />
      </span>
      <div className="single-error-page-message-area">{text}</div>
      <div className="single-error-page-button-area">
        <Button text={t('commons:error.goBack')} onClick={handleClickSignOut} />
      </div>
    </div>
  );
};

const NotFound: React.FC<CommonProps> = ({ classes }) => {
  const { t } = useTranslation(['commons']);
  return (
    <SingleErrorPage
      classes={classes}
      iconComponent={ErrorPageIcons.NotFound}
      text={t('commons:error.notFound')}
    />
  );
};
const Forbidden: React.FC<CommonProps> = ({ classes }) => {
  return (
    <SingleErrorPage
      classes={classes}
      iconComponent={ErrorPageIcons.Forbidden}
      text={<Trans i18nKey="commons:error.forbidden" />}
    />
  );
};

export default { NotFound, Forbidden };
