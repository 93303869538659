import React, { useRef } from 'react';
const useComponentWillMount = (func: any): void => {
  const willMount = useRef(true);

  if (willMount.current) {
    func();
  }

  willMount.current = false;
};

export default useComponentWillMount;
