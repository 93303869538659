import React from 'react';
import classNames from 'classnames';

import { CommonProps } from 'src/components/helpers/props';

import './index.scss';

interface TextFieldProps extends CommonProps {
  type?: 'text' | 'password' | 'number' | 'positive-integer';
  id?: string;
  value?: string;
  name?: string;
  placeholder?: string;
  disabled?: boolean;
  readOnly?: boolean;
  startLabel?: React.ReactNode;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  minWidth?: number;
  fullWidth?: boolean;
  textAlign?: 'left' | 'right' | 'center';
  size?: 'md' | 'sm';
  inputRef?: React.RefObject<HTMLInputElement>;
  autoComplete?: 'on' | 'off' | 'new-password';
  error?: boolean;
  maxLength?: number;
  autoFocus?: boolean;
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyPress?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
}

const TextField: React.FC<TextFieldProps> = ({
  id,
  type: typeProp = 'text',
  value = '',
  name = '',
  placeholder = '',
  disabled = false,
  readOnly = false,
  startLabel,
  startIcon,
  endIcon,
  minWidth = 928,
  fullWidth = true,
  textAlign = 'left',
  size = 'md',
  inputRef,
  autoComplete = 'off',
  error,
  maxLength,
  autoFocus = false,
  onClick,
  onChange,
  onKeyPress,
  onBlur,
  classes,
}) => {
  const inputPropType = typeProp === 'positive-integer' ? 'number' : typeProp;
  if (typeProp === 'positive-integer') {
    if (maxLength === undefined) {
      maxLength = 9; // integer max인 2147483647보다 한 자릿수 작은 최대값인 999999999까지 받을 수 있게.
    } else if (maxLength > 9) {
      maxLength = 9;
    }
  }
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const text = e.target.value;
    if (inputPropType === 'number' && maxLength !== undefined && text.length > maxLength) {
      return;
    }
    if (typeProp === 'positive-integer' && text !== '') {
      if (!/^\d+$/.test(text)) {
        return;
      }
    }
    if (onChange) {
      onChange(e);
    }
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>): void => {
    onBlur && onBlur(e);
  }

  return (
    <div
      className={classNames(
        'text-field-root',
        {
          'text-field-size-sm': size === 'sm',
          'text-field-error': error,
        },
        classes
      )}
      style={{
        width: fullWidth === false ? minWidth : '',
      }}
      onClick={onClick && onClick}
    >
      <div className="text-field-input-inner">
        {startLabel !== undefined && (
          <div
            className={classNames('text-field-label-area', {
              'text-field-label-area-disabled': disabled,
              //'text-field-label-area-readonly': readOnly,
            })}
          >
            {startLabel}
          </div>
        )}
        {startIcon !== undefined && (
          <div
            className={classNames('text-field-btn-area', {
              'text-field-btn-area-disabled': disabled,
              //'text-field-btn-area-readonly': readOnly,
            })}
          >
            {startIcon}
          </div>
        )}
        <div className="text-field-input-area">
          <input
            autoFocus={autoFocus}
            type={inputPropType}
            id={id}
            name={name}
            className={classNames('text-field', {
              'text-field-disabled': disabled,
              //'text-field-readonly': readOnly,
            })}
            placeholder={placeholder}
            value={value}
            // value속성은 readonly에서만 사용한다.
            disabled={disabled}
            readOnly={readOnly}
            ref={inputRef}
            autoComplete={autoComplete}
            maxLength={maxLength}
            onChange={handleChange}
            onKeyPress={onKeyPress}
            onBlur={handleBlur}
            style={{
              textAlign: textAlign,
            }}
          />
        </div>
        {endIcon !== undefined && (
          <div
            className={classNames('text-field-btn-area', {
              'text-field-btn-area-disabled': disabled,
              //'text-field-btn-area-readonly': readOnly,
            })}
          >
            {endIcon}
          </div>
        )}
      </div>
    </div>
  );
};

export default TextField;
