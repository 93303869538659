import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import policyApi, { PolicyDto, PolicyReqDto } from 'src/api/policyApi';
import { toLocalDateTimeStr } from 'src/shared/utils/localDateTime';
import { RootState } from 'src/store';

// # types
export interface PolicyEntity extends PolicyDto {
  entryTsStr: string;
}
function toEntity(dto: PolicyDto): PolicyEntity {
  return { ...dto, entryTsStr: toLocalDateTimeStr(dto.entryTs) };
}

function toDto(entity: PolicyEntity): PolicyDto {
  return { ...entity };
}

interface PolicyEntityByCode {
  [code: string]: PolicyEntity;
}
interface PolicyEntities {
  byCode: PolicyEntityByCode;
  allCodes: string[];
}
interface PolicyState {
  initialized: boolean;
  entities: PolicyEntities;
}

export interface PolicyUpdateReq {
  code: string;
  reqDto: PolicyReqDto;
}

// # initial state
const initialState: PolicyState = {
  initialized: false,
  entities: { byCode: {}, allCodes: [] },
};

// # thunks
export const thunkLoadPolicys = createAsyncThunk('policy/load', async () => {
  const list = await policyApi.list();
  return list.map((dto) => toEntity(dto));
});
export const thunkCreatePolicy = createAsyncThunk('policy/create', async (reqDto: PolicyReqDto) => {
  return toEntity(await policyApi.create(reqDto));
});
export const thunkUpdatePolicy = createAsyncThunk(
  'policy/update',
  async ({ code, reqDto }: PolicyUpdateReq) => {
    return toEntity(await policyApi.update(code, reqDto));
  }
);
export const thunkRemovePolicy = createAsyncThunk('policy/remove', async (code: string) => {
  return policyApi.remove(code);
});

const policySlice = createSlice({
  name: 'policy',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(thunkLoadPolicys.fulfilled, (state, { payload: policies }) => {
      state.entities.byCode = policies.reduce((acc, t) => {
        acc[t.code] = t;
        return acc;
      }, {} as PolicyEntityByCode);
      state.entities.allCodes = policies.map((t) => t.code);
      state.initialized = true;
    });
    builder.addCase(thunkCreatePolicy.fulfilled, (state, { payload: createdEntity }) => {
      state.entities.byCode[createdEntity.code] = createdEntity;
      state.entities.allCodes.push(createdEntity.code);
    });
    builder.addCase(thunkUpdatePolicy.fulfilled, (state, { payload: updatedEntity }) => {
      state.entities.byCode[updatedEntity.code] = updatedEntity;
    });
    builder.addCase(thunkRemovePolicy.fulfilled, (state, { meta: { arg: removedCode } }) => {
      delete state.entities.byCode[removedCode];
      state.entities.allCodes = state.entities.allCodes.filter((code) => code !== removedCode);
    });
  },
});

// # selectors
export const selectInitialized = (state: RootState): boolean => {
  return state.policy.initialized;
};
export const selectPolicyEntities = (state: RootState): PolicyEntities => state.policy.entities;
export const selectPolicyEntityList = (state: RootState): PolicyEntity[] =>
  state.policy.entities.allCodes.map((code) => state.policy.entities.byCode[code]);

export default policySlice.reducer;
