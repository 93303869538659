import React, { useEffect, useState, useContext, Fragment } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { CommonProps } from 'src/components/helpers/props';
import Popup2 from 'src/components/molecules/Popup2';
import proejectApi from 'src/api/projectApi';
import Button from 'src/components/atom/Button';
import FormLabel from 'src/components/atom/FormLabel';
import FormGroup from 'src/components/atom/FormGroup';
import FileInput from 'src/components/atom/FileInput';
import { useAlert } from 'src/contexts/AlertContext';

import './ImportProjectPopup.scss';



interface ImportProjectPopupProps extends CommonProps {

  open: boolean;
  onConfirm: (fileName: File) => void;
  onClose: () => void;
}


const ImportProjectPopup: React.FC<ImportProjectPopupProps> = ({
  open,
  onConfirm,
  onClose,
  classes,
}) => {
  const { t } = useTranslation(['commons']);
  const [files, setFiles] = useState<File[]>([]);
  const { confirmWithWarn, alert2 } = useAlert();
  const handleCancel = (): void => {
    onClose();
  };

  const handleChangeFiles = (files: File[]): void => {
    if (files.length === 0) {
      return;
    }
    setFiles(files);
  };
  const handleConfirm = () => {
    if (files.length === 0) {
      return;
    }
    onConfirm && onConfirm(files[0]);
  }

  return (
    <Popup2
      classes="audit-popup-root project-popup-root project-common"
      title={"프로젝트 RESTORE"}
      width="1112px"
      height="692px"
      open={open}
      onClose={handleCancel}
      footer={
        <React.Fragment>
          <Button text={t('commons:cancel')} onClick={handleCancel} color="secondary" />
          <Button
            text={'RESTORE'}
            onClick={handleConfirm}
          // disabled={!selectedUsers} 
          />
        </React.Fragment>
      }
    >
      <div className="project-input-area">
        <FormGroup>
          <FormLabel classes='input-label'>파일</FormLabel>
          <FileInput classes='input-area' files={files} onChange={handleChangeFiles} />
        </FormGroup>
      </div>

    </Popup2 >
  );
};

export default ImportProjectPopup;