import moment from 'moment';

export function toLocalDateTimeStr(l: number): string {
  return moment(l).format('YYYY-MM-DD HH:mm:ss');
}

export function toLocalDateStr(l: number): string {
  return moment(l).format('YYYYMMDD');
}

export function dateToStringByFormat(date: Date, format: string): string {
  return moment(date).format(format);
}

export function stringToDate(str: string): Date {
  return moment(str).toDate();
}

export function toLocalDateWithFormat(l: number, format: string): string {
  return moment(l).format(format);
}
