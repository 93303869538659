import React, { useEffect, useState, useContext, Fragment } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { CommonProps } from 'src/components/helpers/props';
import Popup2 from 'src/components/molecules/Popup2';
import templateApi, { TemplateDto, TaskDto, DetailDto, createTemplate, createAudit, createDetail, AuditDto, } from 'src/api/templateApi';
import Button from 'src/components/atom/Button';
import TextField from 'src/components/atom/TextField';
import './Step1EditPopup.scss';
import AddCircle2Icon from 'src/components/atom/icons/AddCircle2Icon';
import TrashIcon from 'src/components/atom/icons/TrashIcon';
import IconButton from 'src/components/atom/IconButton';
import { useAlert } from 'src/contexts/AlertContext';
import { validateAll } from 'src/shared/utils/validations';
import Select, { SelectItem } from 'src/components/atom/Select';
import _, { omit } from 'lodash';
import configApi from 'src/api/configApi';
import { ProjectContext } from '../../';
import { useHistory, useParams } from 'react-router-dom';
import { tranTypeList } from './Step1';

interface Step1EditPopupProps extends CommonProps {
  open: boolean;
  onConfirm: (opinions: any) => void;
  onClose: () => void;
}


const Step1EditPopup: React.FC<Step1EditPopupProps> = ({
  open,

  onClose,
  onConfirm,
  classes,
}) => {
  const { t } = useTranslation(['commons']);
  const { alert2 } = useAlert();
  const [trnData, setTrnData] = useState<tranTypeList[]>([]);
  const { projectInfo } = useContext(ProjectContext);
  let { projectId, category, taskName, detailName } = useParams<{ projectId: string, category: string, taskName: string, detailName: string }>();
  detailName = detailName && decodeURIComponent(detailName);
  const validationRules = {
    // id: _.trim(project.id).length > 0 && validationId(project.id),
    // name: _.trim(form.name).length > 0,
    // projectRole: selectedUsers.length > 0,
    name: trnData?.every((item: any) => item?.name?.length > 0)
  };
  const accountList = ["실증", "통제"]


  const handleOk = (): void => {
    if (!validateAll(validationRules)) {
      alert2("주요 거래 유형 관리", "주요 거래 유형 명은 필수 입력값입니다.");
      return;
    }

    console.log("trnData : ", trnData)
    onConfirm(trnData)
    const template = createTemplate(projectInfo.name, "STRATEGY", taskName);
    const detail = createDetail(detailName);
    template.category_list[0].task_list[0].detail_list = [detail];
    const data = {
      tag_name: "주요거래유형",
      template: { ...template, _id: sessionStorage.getItem("templateId") },
      transaction_type_list: [...trnData]
    }

    configApi.setMainTransactionTypeList(data, "주요거래유형").then(resp => {
      console.log("resp : ", resp)
      onClose();
    }).catch(e => {
      console.log("e : ", e)
    })


  };

  useEffect(() => {
    console.log("")
    configApi.getMainAccoutList({ _id: sessionStorage.getItem("templateId"), name: sessionStorage.getItem("templateName") }).then(
      (resp) => { setTrnData(resp?.transaction_type_list || []) }
    ).catch(e => {
      console.log("E:", e);
    });
  }, []);


  const handleDetailAdd = () => {
    let newRiskData = [...trnData];
    newRiskData.push({ name: "", strategy_type: "", it_app: "", checked: false })
    setTrnData(newRiskData)
  }

  const handleDeleteAccount = (i: number) => {
    let newRiskData = [...trnData];
    newRiskData.splice(i, 1)
    setTrnData(newRiskData)
  }

  const handleAccountNameChange = (e: React.ChangeEvent<HTMLInputElement>, i: number) => {
    e.stopPropagation();
    let newRiskData = [...trnData];
    newRiskData[i].name = e.target.value;
    setTrnData(newRiskData)
  }

  const onSelectedAccount = (value: string, i: number) => {
    console.log("value: ", value)
    console.log("i: ", i)
    let newRiskData = [...trnData];
    newRiskData[i].strategy_type = value;
    console.log("newRiskData : ", newRiskData)
    setTrnData(newRiskData)
  }
  const handleITAppNameChange = (e: React.ChangeEvent<HTMLInputElement>, i: number) => {
    e.stopPropagation();
    let newRiskData = [...trnData];
    newRiskData[i].it_app = e.target.value;
    setTrnData(newRiskData)
  }

  return (
    <Popup2
      classes="audit-popup-root project-popup-root project-common"
      title={"주요 거래 유형 관리"}
      width="1112px"
      height="692px"
      open={open}
      onClose={onClose}
      footer={
        <React.Fragment>
          <div className="detail-add">
            <div
              onClick={handleDetailAdd}
            >
              <AddCircle2Icon color="#295c9f" />
              <span>주요 거래 유형 추가</span>
            </div>
          </div>
          <Button text={t('commons:cancel')} onClick={onClose} color="secondary" />
          <Button
            text={'확인'}
            onClick={handleOk}
          // disabled={!selectedUsers} 
          />
        </React.Fragment>
      }
    >
      <div className="task-header element">
        <div style={{ paddingLeft: 15, width: "40%", color: "#a3a6b4" }}>주요 거래 유형</div>
        <div style={{ width: "20%", color: "#a3a6b4" }}>유형별 전략</div>
        <div style={{ width: "40%", color: "#a3a6b4" }}>IT App</div>
      </div>
      <div className="midterm-process-content-step1-root">
        {trnData?.map((elem: any, i: number) => {
          return (
            <div className="midterm-process-content-step1" key={i.toString()}>
              <div className="midterm-process-content-type-root">
                <TextField classes="midterm-process-content-type"
                  value={elem.name || ""}
                  autoFocus={!elem?.name ? true : false}
                  placeholder="주요 거래 유형명을 입력하세요."
                  onChange={(e) => { handleAccountNameChange(e, i) }}
                />
              </div>
              <div className="midterm-process-content-strategy">
                <Select
                  vertical={i > 3 ? "up" : "down"}
                  width={120}
                  selectFirstItem={false}
                  selectedEventKey={elem.strategy_type}
                  textAlign='center'
                  align='left'
                  placeholder="전략 선택"
                  border={false}
                  onClick={(value) => {
                    onSelectedAccount && onSelectedAccount(value, i)
                  }}
                >
                  {accountList && accountList.map(elem => (
                    <SelectItem key={elem} eventKey={elem} >
                      {elem}
                    </SelectItem>
                  ))
                  }
                </Select >
              </div>
              <div className="midterm-process-content-it-app-root">
                <TextField classes="midterm-process-content-it-app"
                  value={elem.it_app || ""}
                  placeholder="IT Application을 입력해주세요."
                  onChange={(e) => { handleITAppNameChange(e, i) }}
                />
              </div>
              <div className="midterm-process-content-type-delete">
                <IconButton onClick={() => handleDeleteAccount(i)}>
                  <TrashIcon></TrashIcon>
                </IconButton>

              </div>
            </div>
          )
        })}
      </div>
    </Popup2>
  );
};

export default Step1EditPopup;
