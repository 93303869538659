import config from 'src/shared/config';
import axios from 'src/shared/utils/axios';

import DummyApi from './dummyApi';
import { times } from './api';

const FSP_API_ENABLED = config.servers.fsp.enabled;
const FSP_API_URL = config.servers.fsp.url;

export enum RuleType {
  OR = 'OR', //검출 조건 하나 이상 충족 시
  AND = 'AND', //검출 조건 모두 충족 시
}

export enum ThresholdType {
  RANGE = 'RANGE', // 범위
  MORE = 'MORE', // 이상
  LESS = 'LESS', // 이하
}

export interface DetectionRuleDto {
  code: string;
  name: string;
  description: string;
  use: boolean;
  entryTs: number;

  type: RuleType; // 검출 방식
  targetList: TargetDto[]; //검출 조건 리스트
}

export interface TargetDto {
  patternCode: string; // 패턴 코드 (공통 서버에서 받아오는 정보)
  thresholdType: ThresholdType; // 임계값 타입 (enum ThresholdType)
  threshold1: number; // thresholdType이 LESS / MORE인 경우 해당 값만 사용, RANGE인 경우 시작 값
  threshold2: number; // thresholdType이 LESS / MORE인 경우 -1, RANGE인 경우 끝 값
}

export type DetectionRuleReqDto = Omit<DetectionRuleDto, 'code' | 'entryTs'>;

export const defaultDetectionRuleReqDto: DetectionRuleReqDto = {
  name: '',
  description: '',
  use: true,
  type: RuleType.AND,
  targetList: new Array<TargetDto>(),
};

interface DetectionRuleApi {
  list(): Promise<DetectionRuleDto[]>;
  find(code: string): Promise<DetectionRuleDto>;
  create(reqDto: DetectionRuleReqDto): Promise<DetectionRuleDto>;
  update(code: string, reqDto: DetectionRuleReqDto): Promise<DetectionRuleDto>;
  remove(code: string): Promise<void>;
}
class DetectionRuleServerApi implements DetectionRuleApi {
  async list(): Promise<DetectionRuleDto[]> {
    const resp = await axios.get<DetectionRuleDto[]>(`${FSP_API_URL}/api/detections/rules`);
    return resp.data;
  }
  async find(code: string): Promise<DetectionRuleDto> {
    const resp = await axios.get<DetectionRuleDto>(`${FSP_API_URL}/api/detections/rules/${code}`);
    return resp.data;
  }
  async create(reqDto: DetectionRuleReqDto): Promise<DetectionRuleDto> {
    const resp = await axios.post<DetectionRuleDto>(`${FSP_API_URL}/api/detections/rules`, reqDto);
    return resp.data;
  }
  async update(code: string, reqDto: DetectionRuleReqDto): Promise<DetectionRuleDto> {
    const resp = await axios.put<DetectionRuleDto>(
      `${FSP_API_URL}/api/detections/rules/${code}`,
      reqDto
    );
    return resp.data;
  }
  async remove(code: string): Promise<void> {
    await axios.delete<DetectionRuleDto>(`${FSP_API_URL}/api/detections/rules/${code}`);
  }
}

class DetectionRuleDummyApi implements DetectionRuleApi {
  dummyApi: DummyApi<DetectionRuleDto, DetectionRuleReqDto>;
  constructor() {
    const data: DetectionRuleDto[] = [
      {
        name: '주민등록번호 50개',
        description: '주민등록번호에 대한 설명입니다.',
        use: true,
        type: RuleType.AND,
        targetList: [
          {
            patternCode: 'code-1',
            thresholdType: ThresholdType.RANGE,
            threshold1: 2,
            threshold2: 500,
          },
        ],
      },
      {
        name: '여권번호 50개',
        description: '여권번호에 대한 설명입니다.',
        use: true,
        type: RuleType.AND,
        targetList: [
          {
            patternCode: 'code-1',
            thresholdType: ThresholdType.RANGE,
            threshold1: 2,
            threshold2: 500,
          },
        ],
      },
      {
        name: '사업자등록번호 10개',
        description: '사업자등록번호에 대한 설명입니다.',
        use: true,
        type: RuleType.AND,
        targetList: [
          {
            patternCode: 'code-0',
            thresholdType: ThresholdType.RANGE,
            threshold1: 2,
            threshold2: 500,
          },
          {
            patternCode: 'code-1',
            thresholdType: ThresholdType.MORE,
            threshold1: 2,
            threshold2: -1,
          },
          {
            patternCode: 'code-2',
            thresholdType: ThresholdType.LESS,
            threshold1: 2,
            threshold2: -1,
          },
        ],
      },
      {
        name: '혜지_검출 기준',
        description: '혜지_검출 기준에 대한 설명입니다.',
        use: true,
        type: RuleType.AND,
        targetList: [
          {
            patternCode: 'code-0',
            thresholdType: ThresholdType.RANGE,
            threshold1: 2,
            threshold2: 100,
          },
          {
            patternCode: 'code-3',
            thresholdType: ThresholdType.MORE,
            threshold1: 2,
            threshold2: -1,
          },
        ],
      },
      {
        name: '소라_검출 기준',
        description: '소라_검출 기준에 대한 설명입니다.',
        use: true,
        type: RuleType.AND,
        targetList: [
          {
            patternCode: 'code-0',
            thresholdType: ThresholdType.RANGE,
            threshold1: 2,
            threshold2: 100,
          },
          {
            patternCode: 'code-1',
            thresholdType: ThresholdType.MORE,
            threshold1: 5,
            threshold2: -1,
          },
          {
            patternCode: 'code-2',
            thresholdType: ThresholdType.MORE,
            threshold1: 2,
            threshold2: -1,
          },
          {
            patternCode: 'code-3',
            thresholdType: ThresholdType.MORE,
            threshold1: 2,
            threshold2: -1,
          },
        ],
      },
    ].map((o, i) => ({ ...o, code: '', entryTs: times(i) }));

    this.dummyApi = DummyApi.of({
      entityName: 'DetectionRule',
      createDto: (code, entity) => ({ ...entity, code, entryTs: Date.now() }),
      validation: (type, code, reqDto, data) => {
        data.forEach((dto) => {
          if (
            (type === 'create' && dto.name === reqDto.name) ||
            (type === 'update' && dto.code !== code && dto.name === reqDto.name)
          ) {
            throw new Error('이미 사용 중인 이름입니다.');
          }
        });
      },
      data,
    });
  }
  list(): Promise<DetectionRuleDto[]> {
    return this.dummyApi.list();
  }
  find(code: string): Promise<DetectionRuleDto> {
    return this.dummyApi.find(code);
  }
  create(reqDto: DetectionRuleReqDto): Promise<DetectionRuleDto> {
    return this.dummyApi.create(reqDto);
  }
  update(code: string, reqDto: DetectionRuleReqDto): Promise<DetectionRuleDto> {
    return this.dummyApi.update({ code, reqDto });
  }
  remove(code: string): Promise<void> {
    return this.dummyApi.remove(code);
  }
}

const detectionRuleApi: DetectionRuleApi = FSP_API_ENABLED
  ? new DetectionRuleServerApi()
  : new DetectionRuleDummyApi();

export default detectionRuleApi;
