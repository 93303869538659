import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import _ from 'lodash';
import qs from 'query-string';

import { CommonProps } from 'src/components/helpers/props';
import TextField from 'src/components/atom/TextField';
import Button from 'src/components/atom/Button';

import { Redirect, useHistory } from 'react-router-dom';
import useAuthStore from 'src/hooks/auth/useAuthStore';
import config from 'src/shared/config';
import { useAlert } from 'src/contexts/AlertContext';
import currentUrlUtils from 'src/shared/utils/currentUrlUtils';
import authApi from 'src/api/auth/authApi';

const LoginWithToken: React.FC = () => {
  const history = useHistory();
  const { alert } = useAlert();
  const { isAuthenticated, authEntity } = useAuthStore();

  const { bearer: token, redirectUrl, logoutUrl } = currentUrlUtils.getAllFirstQuery();

  useEffect(() => {
    if (!isAuthenticated && token) {
      authApi
        .logInWithToken(token)
        .then(() => {
          // TODO
        })
        .catch((err) => alert(err));
    }
  }, [alert, isAuthenticated, token]);

  if (!token) {
    return (
      <Redirect
        to={qs.stringifyUrl({
          url: config.url('/sign-in'),
          query: { redirectUrl, logoutUrl },
        })}
      />
    );
  }

  return null;
};

export default LoginWithToken;
