import React from 'react';
import classNames from 'classnames';

import { CommonProps } from 'src/components/helpers/props';

import './AbstractIcon.scss';

interface CloseIconProps extends CommonProps {
  size?: 'md' | 'sm';
}

const CloseIcon: React.FC<CloseIconProps> = ({ classes, size = 'md' }) => {
  let ret;
  if (size === 'sm') {
    ret = (
      <svg
        className={classNames('icon-root', classes)}
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.5999 4.40002L4.3999 11.6"
          stroke="#6B7480"
          strokeWidth="1.6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4.3999 4.40002L11.5999 11.6"
          stroke="#6B7480"
          strokeWidth="1.6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  } else {
    ret = (
      <svg
        className={classNames('icon-root', classes)}
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        color="#6B7480"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16 4L4 16"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4 4L16 16"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }

  return ret;
};

export default CloseIcon;
