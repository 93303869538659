import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import Button from 'src/components/atom/Button';
import FormGroup from 'src/components/atom/FormGroup';
import TextField from 'src/components/atom/TextField';
import RadioGroup from 'src/components/atom/RadioGroup';
import Radio from 'src/components/atom/Radio';
import _ from 'lodash';


import { CommonProps } from 'src/components/helpers/props';
import Popup2 from 'src/components/molecules/Popup2';
import { validateAll } from 'src/shared/utils/validations';
import { useAlert } from 'src/contexts/AlertContext';
import userApi, { UserInfoDto, createUser } from 'src/api/userApi'
import useAuthStore from 'src/hooks/auth/useAuthStore';
import './UserInfoPopup.scss';

interface UserInfoPopupProps extends CommonProps {

  userAccount: string;
  open: boolean;
  onClose: () => void;
  // selected: UserInfoEntity[];
}

const UserInfoPopup: React.FC<UserInfoPopupProps> = ({
  userAccount,
  open,
  onClose,
  classes,
  // roleList,
  // permissionList,
}) => {
  const { t } = useTranslation(['commons']);
  const { alert, alert2, confirmWithWarn } = useAlert();
  const [userInfo, setUserInfo] = useState<UserInfoDto>(createUser());
  const [password, setPassword] = useState({ first: "", second: "" });
  const { authEntity, startSignOut } = useAuthStore();

  const validationRules = {
    // id: _.trim(project.id).length > 0 && validationId(project.id),
    // name: _.trim(form.name).length > 0,
    // projectRole: selectedUsers.length > 0,
    password: _.trim(password.first).length > 0,
  };

  const handleOk = (): void => {
    if (!validateAll(validationRules)) {
      alert('필수 값을 입력해주세요.');
      return;
    }
    const onConfirm = () => {
      userApi.changePassword(userAccount, password.first)
        .then(data => {
          console.log("data : ", data);
          startSignOut();
        })
        .catch(err => {
          alert2("비밀번호 수정", err);
        })
      onClose();
    }
    confirmWithWarn("비밀번호 수정", "정말 비밀번호를 수정하시겠습니까?\n비밀번호 수정이 완료되면 다시 로그인 하셔야 합니다.", onConfirm)
  };
  const handleCancel = (): void => {
    onClose();
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (name === "password1") {
      setPassword({ ...password, first: value })
    } else if (name === "password2") {
      setPassword({ ...password, second: value })
    }
  }

  useEffect(() => {
    userApi.getUser(userAccount)
      .then(resp => {
        console.log("UserInfoPopup : ", resp)
        let auth = "일반"
        if (resp.member.role.authority_name === "Admin") {
          auth = "관리자"
        } else if (resp.member.role.authority_name === "Staff") {
          auth = "일반"
        }
        setUserInfo({
          ...resp.member, auth
        });
      })
      .catch(err => {
        console.log("UserInfoPopup error : ", err)
      })

  }, []);

  return (
    <Popup2
      classes="userInfo-popup-root"
      title="사용자 수정"
      width="1112px"
      height="692px"
      open={open}
      onClose={handleCancel}
      footer={
        <React.Fragment>
          <Button text={t('commons:cancel')} onClick={handleCancel} color="secondary" />
          <Button
            text={t('commons:ok')} disabled={password.first.length > 0 && password.first === password.second && userInfo?.account !== password.first ? false : true}
            onClick={handleOk}
          // disabled={!selectedUsers} 
          />
        </React.Fragment>
      }
    >
      <FormGroup spacing={6}>
        <span className='input-label'>아이디</span>
        <TextField name='account' disabled
          //placeholder="아이디를 입력하세요"
          value={userInfo?.account} fullWidth={true} onChange={() => { }} />
      </FormGroup>
      <FormGroup spacing={6}>
        <span className='input-label'>이름</span>
        <TextField name='nickname' disabled
          //placeholder="이름을 입력하세요"
          value={userInfo?.nickname} fullWidth={true} onChange={() => { }} />
      </FormGroup>
      <FormGroup spacing={6}>
        <span className='input-label'>사번</span>
        <TextField name='employee_number' disabled
          //placeholder="사번을 입력하세요"
          value={userInfo?.employee_number} fullWidth={true} onChange={() => { }} />
      </FormGroup>
      <FormGroup spacing={6}>
        <span className='input-label'>이메일</span>
        <TextField name='email' disabled
          //placeholder="이메일주소를 입력하세요"
          value={userInfo?.email} fullWidth={true} onChange={() => { }} />
      </FormGroup>
      <FormGroup spacing={6}>
        <span className='input-label'>전화번호</span>
        <TextField name='phone' disabled
          //placeholder="전화번호를 입력하세요"
          value={userInfo?.phone} fullWidth={true} onChange={() => { }} />
      </FormGroup>
      <FormGroup spacing={6}>
        <span className='input-label'>업무성격</span>
        <RadioGroup disabled
          name="auth" value={userInfo?.auth}
        //onChange={onChangeAuth}
        >
          <Radio label="관리자" value={'관리자'} />
          <Radio label="일반" value={'일반'} />
        </RadioGroup>
      </FormGroup>
      <FormGroup spacing={6}>
        <span className='input-label'>비밀번호 수정</span>
        <TextField name='password1' classes="password1" type="password"
          placeholder="변경 할 비밀번호를 입력하세요."
          value={password.first} fullWidth={true} onChange={onChange} />
        <TextField name='password2' classes="password2" type="password"
          placeholder="다시 비밀번호를 입력하세요."
          value={password.second} fullWidth={true} onChange={onChange} />
      </FormGroup>
      {password.first !== password.second && <FormGroup spacing={6}>
        <span className='input-label'></span>
        <div className="password-error">* 입력한 비밀번호가 동일하지 않습니다. </div>
      </FormGroup>}
      {password.first === password.second && userInfo?.account === password.first && <FormGroup spacing={6}>
        <span className='input-label'></span>
        <div className="password-error">* 초기 비밀번호는 사용할 수 없습니다. </div>
      </FormGroup>}
    </Popup2>
  );
};

export default UserInfoPopup;
