import { ApiErrorCode, errorCodeByStatus } from 'src/shared/utils/axios';

export interface ManagerServerResDto<T> {
  status: number;
  result: T;
}

export interface ManagerServerError {
  name: string;
  message: string;
}

export function unwrapResult<T>(resDto: ManagerServerResDto<T>): Promise<T> {
  if (resDto.status >= 200 && resDto.status < 300) {
    return Promise.resolve(resDto.result);
  } else {
    // 발생 안 해야...
    console.log(`Http Status Code is 200 but resDto.status is ${resDto.status}`);
    console.log(`  Response:`);
    console.log(resDto);
    const mse: ManagerServerError = {
      name: errorCodeByStatus[resDto.status] || ApiErrorCode.INTERNAL_SERVER_ERROR,
      message:
        (resDto as any).message || `Http Status Code is 200 but resDto.status is ${resDto.status}`,
    };
    throw mse;
  }
}
