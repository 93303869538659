import React from 'react';
import classNames from 'classnames';

import { CommonProps } from 'src/components/helpers/props';

interface UserSettingIconProps extends CommonProps {
  size?: 'md' | 'sm';
}

const UserSettingIcon: React.FC<UserSettingIconProps> = ({ classes, size = 'md' }) => {
  if (size === 'md') {
    return (
      <svg
        className={classNames('icon-root', classes)}
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        color="#6B7480"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M18.5555 17V15.4444C18.555 14.7551 18.3256 14.0855 17.9032 13.5407C17.4809 12.9959 16.8896 12.6068 16.2222 12.4344"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.8893 17V15.4445C13.8893 14.6194 13.5615 13.828 12.978 13.2446C12.3946 12.6612 11.6033 12.3334 10.7782 12.3334H4.55594C3.73082 12.3334 2.93949 12.6612 2.35605 13.2446C1.7726 13.828 1.44482 14.6194 1.44482 15.4445V17"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.1113 3.1012C13.7805 3.27254 14.3737 3.66174 14.7973 4.20744C15.2208 4.75313 15.4508 5.42429 15.4508 6.11509C15.4508 6.80589 15.2208 7.47704 14.7973 8.02273C14.3737 8.56843 13.7805 8.95763 13.1113 9.12898"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.66678 9.22222C9.385 9.22222 10.7779 7.82933 10.7779 6.11111C10.7779 4.39289 9.385 3 7.66678 3C5.94856 3 4.55566 4.39289 4.55566 6.11111C4.55566 7.82933 5.94856 9.22222 7.66678 9.22222Z"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  } else if (size === 'sm') {
    return (
      <svg
        className={classNames('icon-root', classes)}
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        color="#6B7480"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M18.5555 17V15.4444C18.555 14.7551 18.3256 14.0855 17.9032 13.5407C17.4809 12.9959 16.8896 12.6068 16.2222 12.4344"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.8893 17V15.4445C13.8893 14.6194 13.5615 13.828 12.978 13.2446C12.3946 12.6612 11.6033 12.3334 10.7782 12.3334H4.55594C3.73082 12.3334 2.93949 12.6612 2.35605 13.2446C1.7726 13.828 1.44482 14.6194 1.44482 15.4445V17"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.1113 3.1012C13.7805 3.27254 14.3737 3.66174 14.7973 4.20744C15.2208 4.75313 15.4508 5.42429 15.4508 6.11509C15.4508 6.80589 15.2208 7.47704 14.7973 8.02273C14.3737 8.56843 13.7805 8.95763 13.1113 9.12898"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.66678 9.22222C9.385 9.22222 10.7779 7.82933 10.7779 6.11111C10.7779 4.39289 9.385 3 7.66678 3C5.94856 3 4.55566 4.39289 4.55566 6.11111C4.55566 7.82933 5.94856 9.22222 7.66678 9.22222Z"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  } else return null;
};

export default UserSettingIcon;
