import React, { Fragment, Children, useEffect, useState, useMemo, createContext } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import update from 'immutability-helper';
import classNames from 'classnames';
import moment from 'moment';

import Button from 'src/components/atom/Button';
import TextField from 'src/components/atom/TextField';
import ProjectUserCard from '../ProjectUserCard';
import ProjectUserList, { ProjectUserInfo } from '../ProjectUserList';
import PageTop from 'src/components/molecules/PageTop';
import Paper, { PaperBody, PaperHeader } from 'src/layout/Paper';
import RadioGroup from 'src/components/atom/RadioGroup';
import Radio from 'src/components/atom/Radio';
import AddCircleLineIcon from 'src/components/atom/icons/AddCircleLineIcon';
import Calendar from 'src/components/organisms/Calendar';
import CalendarIcon from 'src/components/atom/icons/CalendarIcon';
import FormGroup from 'src/components/atom/FormGroup';
import Select, { SelectItem } from 'src/components/atom/Select';

import projectApi, { ProjectDto, emptyProject } from 'src/api/projectApi';
import { ClientDto } from 'src/api/clientApi';
//start Popup User
import usePopup from 'src/hooks/usePopup';
import UserListPopup from './UserListPopup';
import ClientPopup from './ClientPopup';
import userSettingApi, { UserInfoEntity, MemberDto } from 'src/api/userSettingApi';
import templateApi, { CategoryDto, TemplateDto } from 'src/api/templateApi';
import roleApi, { RoleDto, PermissionDto } from 'src/api/roleApi';
import _ from 'lodash';
import useSearchBar from 'src/hooks/useSearchBar';
// end Popup User
import useComponentVisible from 'src/hooks/useComponentVisible';
import configApi, { ConfigInfo } from 'src/api/configApi';

import ChipInput from 'src/components/atom/ChipInput';

import './index.scss';
import { validateAll2 } from 'src/shared/utils/validations';
import { useToast } from 'src/contexts/ToastContext';
import { useAlert } from 'src/contexts/AlertContext';
import SearchIcon from 'src/components/atom/icons/SearchIcon';
import SearchBarIcon from 'src/components/atom/icons/SearchBarIcon';
import IconButton from 'src/components/atom/IconButton';
import user from 'src/store/fidp/user';

interface CreateInputElementProps {
  title: string;
  placeholder?: string;
  change?: (value: string) => void;
  disabled?: boolean;
  initValue?: string;
  error?: boolean;
  useValidation?: boolean;
  warningText?: string;
  endIcon?: React.ReactNode;
}

const CreateInputElement: React.FC<CreateInputElementProps> = ({
  title,
  placeholder,
  change,
  disabled,
  initValue,
  children,
  error,
  useValidation,
  warningText,
  endIcon,
}) => {
  const [value, setValue] = useState(initValue);

  useEffect(() => {
    setValue(initValue);
  }, [initValue]);
  return (
    <div className="input-box">
      <div className="input-title">
        <span>
          {title} {useValidation && <span className="warning">*</span>}
        </span>
      </div>
      <TextField
        classes="project-text-field"
        placeholder={placeholder}
        value={value}
        disabled={disabled}
        endIcon={endIcon}
        onChange={(e) => {
          setValue(e.target.value);
          if (change) change(e.target.value);
        }}
        error={error}
      />
      <div className="project-warning">
        <span className="warning txt-word-break-all ">
          {warningText !== undefined ? warningText : ''}
        </span>
      </div>
      {children}
    </div>
  );
};

interface ProjectUserSearchBarProps {
  title: string;
  placeholder?: string;
  dtoList: MemberDto[];
}
const ProjectUserSearchBar: React.FC<ProjectUserSearchBarProps> = ({
  title,
  placeholder,
  dtoList,
  children,
}) => {
  const [value, setValue] = useState('');

  const { filteredEntityList: filteredDtoList, searchText, handleChangeSearchText } = useSearchBar({
    entityList: dtoList,
    keys: ['account', 'nickname', 'email'],
  });

  const [searchUser, setSearchUser] = useState<string[]>([]);

  // const FindUserList = (): void => {
  //   // todo: find user from server with input value

  //   const users: UserInfoEntity[] = [];
  //   setSearchUser(users);
  // };

  const updateSearchUser = (email: string, type: string): void => {
    const findIndex = searchUser.findIndex((data) => data === email)

    console.log('updateSearchUser!!!', email, type, searchUser, findIndex);

    if (findIndex < 0) {
      if (type === 'add') {
        setSearchUser(searchUser.concat(email))
      }
    }
    else {
      if (type === 'delete') {
        setSearchUser(update(searchUser, { $splice: [[findIndex, 1]] }))
      }
    }
  }

  return (
    <div className="input-box">
      <div className="input-title">
        <span>{title}</span>
      </div>


      {/* {placeholder !== undefined ? (
        <TextField
          classes="project-text-field"
          placeholder={placeholder}
          value={value}
          onChange={(e) => {
            setValue(e.target.value);
            FindUserList();
          }}
        />
      ) : (
        ''
      )} */}
      <div className='search-area'>
        {/* <div className='chip-input'>
          <ChipInput 
            fullWidth={true}
            // blurBehavior='clear'
            value={searchUser}
            // onChange={(chips) => {console.log('onChange: chips', chips);setSearchUser(chips);} }
            onAdd={(chip) => { updateSearchUser(chip, 'add')}}
            onDelete={(chip) => { console.log('YYYY'); updateSearchUser(chip, 'delete')}}
            onUpdateInput={(e) => { handleChangeSearchText(e.currentTarget.value) }}
          />
          <div className={'search-result-list-wrapper ' + (searchText? 'on': 'off')}>
              {searchText && filteredDtoList.map((dto) => (
                <div className='item' onClick={() => {
                  updateSearchUser(dto.email, 'add');
                  handleChangeSearchText('');
                }}>
                  <div className='avatar'>
                    <div className='image'></div>
                  </div>
                  <div className='info'>
                    <div className='name'>{dto.name}</div>
                    <div className='email'>{dto.email}</div>
                  </div>
                </div> 
              ))}
          </div>
        </div> */}

        {/* <div className={classNames('user-list-box', { on: value.length > 0 })}>
          {searchUser.map((user, i) => {
            return (
              <ProjectUserCard
                key={i}
                name={`${user.name} (${user.userId})`}
                userRole={user.authId}
                email={user.mail}
              />
            );
          })}
        </div> */}

        <div className='userlist-button'>
          {children}
        </div>
      </div>
    </div>
  );
};

interface ProjectFooterProps {
  cancel?: () => void;
  confirm?: () => void;
  isProjectDetail?: boolean;
  disabled?: boolean;
}
const ProjectFooter: React.FC<ProjectFooterProps> = ({ cancel, confirm, isProjectDetail = false, disabled = false }) => {
  return (
    <div className={classNames('project-footer', 'common-page-footer')} style={isProjectDetail ? { borderTopWidth: 0 } : {}}>
      <div className={classNames('right-button')}>
        {/* <Button classes="footer-button" text="취소" color="secondary" onClick={cancel} /> */}
        <Button disabled={disabled} classes="footer-button2" text="저장" onClick={confirm} />
      </div>
    </div>
  );
};


export const ConfigContext = createContext({
  config: {} as ConfigInfo
})


interface ProjectDetailProps {
  parentTitle?: string;
  toCancel?: () => void;
  setReload?: (value: boolean) => void;
}
const ProjectDetail: React.FC<ProjectDetailProps> = ({ parentTitle, toCancel, setReload }) => {
  const [config, setConfig] = useState<ConfigInfo>({} as ConfigInfo);

  const { projectId } = useParams<{ projectId: string }>();
  //start Popup User
  const selectUserListPopup = usePopup();
  const clientPopup = usePopup();
  const history = useHistory();

  const [userList, setUserList] = useState<MemberDto[]>([]);
  const [selectedUsers, setSelectedUsers] = useState<MemberDto[]>([]);
  const [project, setProject] = useState<ProjectDto>(emptyProject());
  const [projectUsers, setProjectUsers] = useState<ProjectUserInfo[]>([]);
  const [submit, setSubmit] = useState<boolean>(false);

  const [templateList, setTemplateList] = useState<TemplateDto[]>([]);
  const [template, setTemplate] = useState<TemplateDto>({} as TemplateDto);
  const [checked, setCheck] = useState<boolean>(false);

  const toast = useToast();
  const { alert, alert2 } = useAlert();
  const value = useMemo(() => ({ config }), [config])

  const validationId = (id: string): boolean => {
    const idRegEx = RegExp('^[\\_A-Za-z0-9]*$');
    if (!idRegEx.test(id)) {
      return false;
    }
    return true;
  };

  const validationRules = {
    // id: _.trim(project.id).length > 0 && validationId(project.id),
    name: _.trim(project.name).length > 0,
    client: _.trim(project.client?.name).length > 0,
    template: _.trim(project.template?.name).length > 0,
    started_at: _.trim(project.report_period.started_at).length > 0,
    finished_at: _.trim(project.report_period.finished_at).length > 0,
    regulatory_country: project.type === "service" ? true : (!project.is_regulated || project.regulatory_country?.length > 0),
    projectRole: projectUsers.length > 0,
  };

  useEffect(() => {
    if (!userList.length) {
      userSettingApi
        .list()
        .then((resp) => {
          setUserList(resp);
        })
        .catch((err) => {
          setUserList([])
        });
    }

    if (projectId) {
      projectApi.getProject(projectId).then((resp) => {
        if (resp) {
          setProject(resp);
          projectApi.setProjectStatus(resp.status || "")
          setProjectUsers(resp.member_list);

          // if (resp.projectRole) {
          //   const projectUserList = resp.projectRole.map((user) => {
          //     if (typeof user.roleId === 'string') {
          //       user.roleId = [user.roleId];
          //     }
          //     return {
          //       userId: user.userId,
          //       name: user.userName ? user.userName : '',
          //       authority: user.roleName,
          //       authId: user.roleId,
          //     };
          //   });
          //   // setProjectUsers(projectUserList);
          // }
        }
      })
        .catch(e => {
        });
    }

    if (projectId && projectUsers.length > 0 && userList && userList.length > 0) {
      const projectUserId = _.uniqBy(projectUsers, 'userId');
      // const userIds = projectUserId.map((user) => user.userId);
      // const selected = userList.filter((user) => userIds.includes(user.code));
      // setSelectedUsers(selected);
    }
  }, [projectId]);
  // }, [projectId, projectUsers, userList, permissionList, roleList]);

  useEffect(() => {
    setProjectUsers(selectedUsers.map((user) => {
      return {
        account: user.account,
        nickname: user.nickname,
        employee_number: user.employee_number,
        role: {
          role_list: user.role.role_list || 'Staff',
          authority_name: user.role.authority_name || 'Staff'
        }
      }
    }))
  }, [selectedUsers])

  useEffect(() => {
    let newProjectUsers = [...projectUsers];
    newProjectUsers.forEach((user, i) => {
      userList.forEach(elem => {
        if (elem.account === user.account) {
          newProjectUsers[i].employee_number = elem.employee_number
        }
      })
    })
    setProjectUsers(newProjectUsers)
  }, [userList])

  useEffect(() => {
    const member_list = projectUsers?.map(user => ({
      account: user.account,
      nickname: '',
      employee_number: user.employee_number,
      role: user.role
    }))
    setProject(project => (update(project, { member_list: { $set: member_list } })))
  }, [projectUsers])

  useEffect(() => {
    templateApi
      .getTemplateList(project?.type === undefined ? "" : project.type)
      .then((resp) => {
        if (resp) {
          setTemplateList(resp)
        }
      })
      .catch(e => {
        alert2("에러 발생", e)
      })
  }, [project.type]);

  useEffect(() => {
    setTemplate(templateList.filter((elem) => elem.name === project.name)[0]);

  }, [templateList, project])


  useEffect(() => {
    configApi.getConfig()
      .then((resp) => {
        if (resp)
          setConfig(resp);
      })
      .catch(e => {
        alert2("에러 발생", e)
      })
  }, []);

  const handleConfirm = (): void => {
    setSubmit(true);

    // check project name
    if (project.name === "exported" || project.name === "imported") {
      alert2("프로젝트 " + (projectId ? "수정" : "생성"), `해당 이름(${project.name})은 프로젝트 이름으로 사용할 수 없습니다.`);
      return;
    }

    const r = validateAll2(validationRules)
    if (!r.retVal) {
      if (r.key === "name") {
        alert2("프로젝트 " + (projectId ? "수정" : "생성"), "프로젝트 이름은 필수입니다.");
      } else if (r.key === "client") {
        alert2("프로젝트 " + (projectId ? "수정" : "생성"), "클라이언트는 필수입니다.");
      } else if (r.key === "regulatory_country") {
        alert2("프로젝트 " + (projectId ? "수정" : "생성"), "국가명은 필수입니다.");
      } else if (r.key === "projectRole") {
        alert2("프로젝트 " + (projectId ? "수정" : "생성"), "멤버 1명이상은 필수입니다.");
      } else if (r.key === "template") {
        alert2("프로젝트 " + (projectId ? "수정" : "생성"), "템플릿은 꼭 선택하셔야 합니다.");
      }
      return;
    }
    // const roleList = selectedUsers.map((user) => {
    //   return {
    //     userId: user.code,
    //     roleId: user.userRole,
    //   };
    // });

    const updateData: ProjectDto = project;

    if (projectId) {
      projectApi
        .updateProject(updateData)
        .then((res) => {
          toast.info('프로젝트 수정을 성공했습니다.');
          setReload && setReload(true)
          history.push(`/projects/${updateData.project_id}/detail`);
        })
        .catch((err) => {
          alert2("프로젝트 수정", err)
        });
    } else {
      projectApi
        .createProject(updateData)
        .then((res) => {
          toast.info('프로젝트 생성을 성공했습니다.');
          setReload && setReload(true)
          history.push(`/projects`);
        })
        .catch((err) => {
          alert2("프로젝트 생성", err)
        });
    }
  };

  const handleCancle = (): void => {
    if (projectId) {
      history.push(`/projects/${projectId}/detail`);
    } else {
      history.push(`/projects`);
    }
  };

  //start Popup User
  const handleCloseUserListPopup = (selected?: MemberDto[]): void => {
    if (selected !== undefined && selected !== null && selected.length > 0) {
      setSelectedUsers(selected);
    } else {
      setSelectedUsers([]);
    }
    selectUserListPopup.closePopup();
  };

  const handleCloseClientPopup = (client: ClientDto) => {
    clientPopup.closePopup();
    if (client?.name?.length > 0) {
      setProject(prj => ({
        ...prj,
        client
      }))
    }

  }

  if (!userList) {
    // if (!userList || !roleList || !permissionList) {
    return null;
  }
  //end Popup User

  // const toProjectUserInfo = (userEntity: UserInfoEntity): ProjectUserInfo => {
  //   const projectUserInfo: ProjectUserInfo = {
  //     account: userEntity.account,
  //     nickname: userEntity.nickname,
  //     mail: userEntity.email,
  //     authId: userEntity.userRole,
  //     authName: userEntity.userRoleName,
  //   };

  //   return projectUserInfo;
  // };

  const handleChangeUserRole = (user: ProjectUserInfo, roleList: string[]) => {
    const idx = projectUsers.findIndex((member) => member.account === user.account);

    console.log(`handleChangeUserRole ${idx}, `, user, roleList);


    idx !== undefined && setProjectUsers((prevState) => update(prevState, { [idx]: { role: { role_list: { $set: roleList } } } }))
  }

  const handleChangeUserAuth = (user: ProjectUserInfo, auth: string) => {
    const idx = projectUsers.findIndex((member) => member.account === user.account);

    console.log(`handleChangeUserAuth ${idx}, `, user, auth);


    idx !== undefined && setProjectUsers((prevState) => update(prevState, { [idx]: { role: { authority_name: { $set: auth } } } }))
  }

  const handleClickCategory = (name: string) => {
    history.push(`/projects/${projectId}/category/${name.toLowerCase()}`);
  }

  const isUpdate = projectId ? true : false;

  const templateSummary: { name: string, info: string }[] = [];

  if (isUpdate && template) {
    // const template = templateList.filter((elem) => elem.name === project.name)[0];
    if (template.category_list?.length > 0) {
      const s = getDetailCount(template?.category_list[0]);
      const p = getDetailCount(template?.category_list[1]);
      const c = getDetailCount(template?.category_list[2]);
      templateSummary.push({
        name: "STRATEGY",
        info: `${s.done} / ${s.count}`
      });

      templateSummary.push({
        name: "PROCEDURE",
        info: `${p.done} / ${p.count}`
      });

      templateSummary.push({
        name: "CONCLUSION",
        info: `${c.done} / ${c.count}`
      });

    }
  }

  function getDetailCount(category: CategoryDto) {
    if (!category.task_list) {
      return { count: 0, done: 0 }
    }
    else {
      return category.task_list.reduce((acc, cur) => {
        acc.count += cur.detail_list ? cur.detail_list.length : 0;
        acc.done += cur.detail_list ? cur.detail_list.filter((elem) => elem.status === "완료").length : 0;

        return acc;
      }, { count: 0, done: 0 })
    }

  }

  const onChangeCheckbox = (checked: boolean) => {
    setCheck(checked)
  }
  if (projectId && !project.name) {
    return <></>
  }

  const projectType = project?.type === undefined || project?.type.length === 0 || project?.type === 'audit' ? "audit" : "service"
  const isProjectDisabled = projectApi.isProjectDisabled();
  return (
    <div
      className={classNames('common-page-body project-page-body project-detail-root project-common', {
        'bottom-margin': !isUpdate,
      })}
    >
      <ConfigContext.Provider value={value}>
        <Paper classes="project-detail-body" noScroll={false}>
          {/* <PaperHeader
            leftArea={
              <div className="detail-header-left">
                <span>{pageName}</span>
              </div>
            }
            classes={'content-header'}
          /> */}
          <PaperBody classes="content-body">
            <div className="project-input-area">
              <div className="input-project-title">
                <CreateInputElement
                  title="프로젝트 이름"
                  disabled={isProjectDisabled}
                  placeholder="프로젝트 이름을 입력해주세요."
                  error={submit && !validationRules.name}
                  initValue={project.name}
                  change={(value) =>
                    setProject((prevSate) => ({
                      ...prevSate,
                      name: value,
                    }))
                  }
                //useValidation={true}
                />
              </div>
              <div className="input-project-title">
                <div className="input-box">
                  <div className="input-title">
                    <span>
                      프로젝트 타입
                    </span>
                  </div>
                  <RadioGroup
                    name="reportStandard"
                    disabled={isUpdate}
                    value={projectType === 'audit' ? 'audit' : 'service'}
                    onChange={(value) => {
                      setProject(project => ({
                        ...project,
                        type: value === 'audit' ? "" : "service"
                      }))
                    }}
                  >
                    <Radio classes='radio-css' label="감사" value={'audit'} />
                    <Radio classes='radio-css' label="용역" value={'service'} />
                  </RadioGroup>
                </div>
              </div>
              <div className="input-project-title">
                <CreateInputElement
                  title="클라이언트"
                  placeholder="클라이언트를 선택해주세요"
                  error={submit && !validationRules.client}
                  initValue={project.client?.name}
                  change={(value) => { }
                  }
                  disabled={true}
                  //useValidation={true}
                  endIcon={<IconButton
                    transparent={true}
                    //disabled={isUpdate}
                    onClick={() => isProjectDisabled ? {} : clientPopup.openPopup()}>
                    <SearchBarIcon />
                  </IconButton>}
                />

              </div>
              <div className="input-project-title">
                <div className="input-box">
                  <div className="input-title">
                    <span>
                      보고 기간
                    </span>
                  </div>
                  <Calendar
                    // disabled={!!project.info?.schedule.freq || useStart === 'false'}
                    locale={'ko'}
                    disabled={isUpdate}
                    selected={moment(project.report_period.started_at).toDate()}
                    onSubmit={(date) => {
                      setProject(project => (update(project, { report_period: { started_at: { $set: moment(date).format('YYYY.MM.DD') } } })))
                      if (checked) {
                        setProject(project => (update(project, { report_period: { finished_at: { $set: moment(date).add("1", "y").add("-1", "d").format('YYYY.MM.DD') } } })))
                        setCheck(false)
                      }

                      // handleChangeSchedule('start', moment(date).format('YYYY-MM-DD HH:mm:ss'))
                    }}
                    // timeInputLabel='시작 시간'
                    customInput={<TextField minWidth={145} endIcon={<CalendarIcon />} />}
                    dateFormat="yyyy.MM.dd"
                    // submitText="확인"
                    minDate={new Date('1970-01-01Z00:00:00:000')}
                    enableTime={false}
                    first
                    checked={checked}
                    onChangeCheckbox={onChangeCheckbox}
                  />
                  <div className='date-sep'>~</div>
                  <Calendar
                    // disabled={!!project.info?.schedule.freq || useUntil === 'false'}
                    locale={'ko'}
                    disabled={isUpdate}
                    selected={moment(project.report_period.finished_at).toDate()}
                    onSubmit={(date) => {
                      setProject(project => (update(project, { report_period: { finished_at: { $set: moment(date).format('YYYY.MM.DD') } } })))

                      // setProject('until', moment(date).format('YYYY-MM-DD HH:mm:ss'))
                    }}
                    // timeInputLabel='종료 시간'
                    customInput={<TextField minWidth={145} endIcon={<CalendarIcon />} />}
                    dateFormat="yyyy.MM.dd"
                    // submitText="확인"
                    minDate={new Date('1970-01-01Z00:00:00:000')}
                    enableTime={false}
                  />
                </div>
              </div>
              <div className="input-project-title">
                {projectType === 'audit' ? <div className="input-box">
                  <div className="input-title">
                    <span>
                      보고 기준
                    </span>
                  </div>
                  <RadioGroup
                    name="reportStandard"
                    disabled={isUpdate}
                    // disabled={!!project.info?.schedule.freq}
                    value={project.report_standard}
                    onChange={(value) => {
                      setProject(project => ({
                        ...project,
                        report_standard: value
                      }))

                    }}
                  >
                    <Radio classes='radio-css' label="K-IFRS" value={'K-IFRS'} />
                    <Radio classes='radio-css' label="K-GAAP" value={'K-GAAP'} />
                  </RadioGroup>
                </div> :
                  <CreateInputElement
                    title="용역 유형"
                    placeholder={"용역 유형을 입력해 주세요"}
                    initValue={project.service_type}
                    change={(value) => {
                      setProject(project => ({
                        ...project,
                        service_type: value
                      }))
                    }
                    }
                  //useValidation={false}
                  />
                }
              </div>
              <div className="input-project-title">
                {projectType === 'audit' ?
                  <div className="input-box">
                    <div className="input-title">
                      <span>
                        상장 여부
                      </span>
                    </div>
                    <RadioGroup
                      name="reportStandard"
                      disabled={isUpdate}
                      // disabled={!!project.info?.schedule.freq}
                      value={project.is_public ? '상장' : '비상장'}
                      onChange={(value) => {
                        setProject(project => ({
                          ...project,
                          is_public: value === '상장' ? true : false
                        }))
                      }}
                    >
                      <Radio classes='radio-css' label="상장" value={'상장'} />
                      <Radio classes='radio-css' label="비상장" value={'비상장'} />
                    </RadioGroup>
                  </div> :
                  <div className="input-box">
                  </div>
                }
              </div>
              <div className="input-project-title">
                {projectType === 'audit' ? <div className="input-box">
                  <div className="input-title">
                    <span>
                      타 국가의 규제 여부
                    </span>
                  </div>
                  <RadioGroup
                    name="reportStandard"
                    disabled={isUpdate}
                    // disabled={!!project.info?.schedule.freq}
                    value={project.is_regulated ? 'true' : 'false'}
                    onChange={(value) => {
                      const regulatory_country = value === 'true' ? project.regulatory_country : '';
                      setProject(project => ({
                        ...project,
                        regulatory_country,
                        is_regulated: value === 'true' ? true : false
                      }))
                    }}
                  >
                    <Radio classes='radio-css' label="예" value={'true'} />
                    <Radio classes='radio-css' label="아니오" value={'false'} />
                  </RadioGroup>
                </div>
                  :
                  <div className="input-box">
                  </div>
                }
              </div>
              <div className="input-project-title">
                {projectType === 'audit' ? <CreateInputElement
                  disabled={!project.is_regulated}
                  title=""
                  placeholder={isUpdate ? "" : "국가명"}
                  error={submit && !validationRules.regulatory_country}
                  initValue={project.regulatory_country}
                  change={(value) => {
                    setProject(project => ({
                      ...project,
                      regulatory_country: value
                    }))
                  }
                  }
                  useValidation={false}
                /> :
                  <div className="input-box">
                  </div>
                }
              </div>
              <div className="input-project-title">
                <div className="input-box">
                  <div className="input-title">
                    <span>
                      프로젝트 템플릿 공개여부
                    </span>
                  </div>
                  <RadioGroup
                    name="reportStandard"
                    disabled={isUpdate}
                    // disabled={!!project.info?.schedule.freq}
                    value={project.access_modifier || 'private'}
                    onChange={(value) => {
                      setProject(project => ({
                        ...project,
                        access_modifier: value
                      }))
                    }}
                  >
                    <Radio classes='radio-css' label="공개" value={'public'} />
                    <Radio classes='radio-css' label="비공개" value={'private'} />
                  </RadioGroup>
                </div>
              </div>
              <div className="input-project-template">
                <div className="input-box">
                  <div className="input-title">
                    <span>템플릿</span>
                  </div>
                  <Select
                    classes='project-text-field'
                    align={'left'}
                    autoWidth={true}
                    selectFirstItem={false}
                    selectedEventKey={project.template?.name}
                    disabled={!!projectId}
                    border={false}
                    onClick={(eventKey) => {
                      setProject(project => ({
                        ...project,
                        template: {
                          name: eventKey
                        }
                      }))
                    }}
                  >
                    {templateList && templateList.map((elem, index) => (
                      <SelectItem key={'templateSelect' + index} eventKey={elem.name}>
                        {elem.name}
                      </SelectItem>
                    ))}
                  </Select>
                </div>
              </div>
            </div>
          </PaperBody>
        </Paper>
        <div className="project-template-right">
          <div className="project-template-user-info">
            <div className="project-input-area">
              <div className="project-template-user-info-header">

                <ProjectUserSearchBar
                  title="멤버"
                  dtoList={userList}
                >
                </ProjectUserSearchBar>
                <Button
                  text={'멤버 변경'}
                  disabled={isProjectDisabled}
                  classes="member-add-button"
                  startIcon={<AddCircleLineIcon />}
                  onClick={() => {
                    console.log('open user popup')
                    selectUserListPopup.openPopup();
                  }}
                />
              </div>
              <div className="input-box member-area">
                {/* <div className="input-title"></div> */}
                <div className="member-list">
                  <ProjectUserList
                    userList={projectUsers}
                    readOnly={false}
                    changeUserRole={handleChangeUserRole}
                    changeUserAuth={handleChangeUserAuth}
                  />
                </div>
              </div>
            </div>
          </div>
          <ProjectFooter disabled={isProjectDisabled} isProjectDetail={true} cancel={handleCancle} confirm={handleConfirm} />
        </div>
        {selectUserListPopup.opened && <UserListPopup
          dtoList={userList.filter(({ account: account1 }) => !projectUsers.some(({ account: account2 }) => account1 === account2))}
          dtoList2={projectUsers}
          open={selectUserListPopup.opened}
          onClose={handleCloseUserListPopup}
          selected={selectedUsers}
        // roleList={roleList}
        // permissionList={permissionList}
        />
        }
        {
          clientPopup.opened &&
          <ClientPopup
            clientDto={project.client}
            userList={userList}
            open={clientPopup.opened}
            onClose={handleCloseClientPopup}
          />
        }
      </ConfigContext.Provider>
    </div >

  );
};

export default ProjectDetail;
