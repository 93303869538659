import React, { useEffect, useState, useContext, Fragment } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { CommonProps } from 'src/components/helpers/props';
import Popup2 from 'src/components/molecules/Popup2';
import templateApi, { TemplateDto, TaskDto, DetailDto, createTemplate, createAudit, createDetail, AuditDto, } from 'src/api/templateApi';
import Button from 'src/components/atom/Button';
import TextField from 'src/components/atom/TextField';
import './Step4EditPopup.scss';
import Select, { SelectItem } from 'src/components/atom/Select';
import { useAlert } from 'src/contexts/AlertContext';
import { validateAll } from 'src/shared/utils/validations';
import _, { omit } from 'lodash';
import ArrowDownIcon from 'src/components/atom/icons/ArrowDownIcon';
import ArrowUpIcon from 'src/components/atom/icons/ArrowUpIcon';
import RemoveIcon from 'src/components/atom/icons/RemoveIcon';

interface Step4EditPopupProps extends CommonProps {
  open: boolean;
  data: any;
  onConfirm: (itAppData: any) => void;
  onClose: () => void;
}


const Step4EditPopup: React.FC<Step4EditPopupProps> = ({
  open,
  data,
  onClose,
  onConfirm,
  classes,
}) => {
  const { t } = useTranslation(['commons']);
  const { alert2 } = useAlert();
  const [itAppData, setItAppData] = useState<any>(data);
  const [subCount, setSubCount] = useState<number[]>([0]);
  const [loc, setLoc] = useState<number[]>([-1]);
  const [visible, setVisible] = useState<boolean[]>([false]);
  const validationRules = {
    // id: _.trim(project.id).length > 0 && validationId(project.id),
    // name: _.trim(form.name).length > 0,
    // projectRole: selectedUsers.length > 0,
    // name: itAppData?.data.every((item: any) => item.title.length > 0)
  };

  const handleOk = (): void => {


    if (!validateAll(validationRules)) {
      alert2("감사절차 수정", "계정명은 필수 입력값입니다.");
      return;
    }
    onConfirm(itAppData)
    onClose();
  };

  useEffect(() => {
    // console.log("itAppData : ", itAppData)
    let newSubCount: number[] = []
    let loc: number[] = [-1,]
    itAppData?.forEach((item: any, i: number) => {
      let subCount = 0;
      loc[i] = -1;
      item.transaction_type_list?.forEach((ii: any, index: number) => {
        if (ii.transaction_type_list && Array.isArray(ii.transaction_type_list)) {
          if (loc[i] === -1) {
            loc[i] = index;
          }
          subCount = subCount + ii.transaction_type_list.length;
        }
      })
      newSubCount[i] = subCount;

    })
    setSubCount(newSubCount)
    setLoc(loc)

  }, [itAppData]);

  const handleClose = () => {
    onClose();
  }

  const onSelectEffective = (value: any, idx: number, i: number, j: number) => {
    // console.log("dddd", value, idx, i, j)
    const newItAppData = _.cloneDeep(itAppData);
    newItAppData[idx].transaction_type_list[i].transaction_type_list[j].effectiveness = value;
    setItAppData(newItAppData)
  }
  const handleClickVisible = (idx: number) => {
    const newVisible = _.cloneDeep(visible);
    newVisible[idx] = !newVisible[idx]
    setVisible(newVisible)

  }

  // console.log("subCount : ", subCount)
  // console.log("subCountLoc : ", loc)

  return (
    <Popup2
      classes="audit-popup-root project-popup-root project-common"
      title={"IT Application 관리"}
      width="1112px"
      height="692px"
      open={open}
      onClose={handleClose}
      footer={
        <React.Fragment>
          <Button text={t('commons:cancel')} onClick={handleClose} color="secondary" />
          <Button
            text={'확인'}
            onClick={handleOk}
          // disabled={!selectedUsers} 
          />
        </React.Fragment>
      }
    >
      <div className="task-header element">
        <div className="title-content title" style={{ paddingLeft: 15, width: "20%", color: "#a3a6b4" }}>IT Application</div>
        <div className="title-account account" style={{ paddingLeft: 10, width: "20%", color: "#a3a6b4" }}>ITGC</div>
        <div className="title-account account" style={{ width: "60%", color: "#a3a6b4" }}>내용</div>
        <div className="title-account account" style={{ paddingLeft: 10, width: "20%", color: "#a3a6b4" }}>효과성 결론</div>
      </div>
      <div className="sp-csm-step4-popup-detail-body-content">
        {itAppData?.map((elem: any, idx: number) => {
          return (
            <div className="sp-csm-step4-popup-detail-data" key={idx.toString()}>
              <div className="sp-csm-step4-popup-detail-it-app" onClick={() => {
                subCount[idx] && handleClickVisible(idx)
              }
              }>
                {
                  subCount[idx] > 1 ?
                    visible[idx] ? <ArrowUpIcon classes='icon-up' /> :
                      <ArrowDownIcon fill="#bcbccb" classes='icon-down' />
                    :
                    <RemoveIcon fill="#bcbccb" classes='icon-down' />
                }
                {elem.name}
              </div>
              <div className="sp-csm-step4-popup-detail-itgc-root">
                {elem.transaction_type_list?.map((item: any, i: number) => {
                  if (!visible[idx] && loc[idx] !== i) {
                    return <div key={"t" + i.toString()}></div>
                  }
                  return item?.transaction_type_list?.map((elem: any, j: number) => {
                    if (!visible[idx] && j > 0) {
                      return <div key={"tt" + j.toString()}></div>
                    }
                    return (
                      <div key={i.toString() + j.toString()} className="sp-csm-step4-popup-detail-itgc" style={{ borderBottomWidth: visible[idx] ? 1 : 0 }}>
                        <div className="sp-csm-step4-popup-detail-itgc-name">{item.name}</div>
                        <div className="sp-csm-step4-popup-detail-itgc-contents">{elem.name}</div>
                        <div className="sp-csm-step4-popup-detail-itgc-select">
                          <Select
                            vertical={idx > 5 || (idx > 1 && j + 1 === item?.transaction_type_list?.length) ? "up" : "down"}
                            width={140}
                            selectFirstItem={false}
                            selectedEventKey={elem.effectiveness || ""}
                            textAlign='center'
                            align='left'
                            placeholder="미지정"
                            border={false}
                            onClick={(value) => {
                              onSelectEffective && onSelectEffective(value, idx, i, j)
                            }}
                          >
                            {["효과적", "비효과적"].map(elem => (
                              <SelectItem key={elem} eventKey={elem} >
                                {elem}
                              </SelectItem>
                            ))
                            }
                          </Select >
                        </div>

                      </div>
                    )
                  })
                })}
              </div>

            </div>
          )
        })

        }
      </div>
      <div style={{ height: 60 }}></div>
    </Popup2>
  );
};

export default Step4EditPopup;
