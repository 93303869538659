import React from 'react';
import classNames from 'classnames';
import _ from 'lodash';

import { CommonProps } from 'src/components/helpers/props';

import './index.scss';

export interface CheckboxProps extends CommonProps {
  id?: string;
  name?: string;
  label?: string;
  value?: string;
  checked?: boolean;
  disabled?: boolean;
  onChange?: (name: string, value: string, checked: boolean) => void;
}

const Checkbox: React.FC<CheckboxProps> = ({
  id,
  name,
  label,
  value,
  checked = false,
  disabled = false,
  onChange,
  classes,
}) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (onChange) {
      onChange(e.target.name, e.target.value, e.target.checked);
    }
  };

  return (
    <div
      className={classNames(
        'checkbox-root',
        {
          checked: checked,
          disabled: disabled,
        },
        classes
      )}
    >
      <label>
        <span
          className={classNames('checkbox-check', {
            'checkbox-check-with-label': label,
          })}
        ></span>
        <input
          type="checkbox"
          name={name}
          value={value}
          checked={checked}
          disabled={disabled}
          onChange={handleChange}
        />
        {label && <span className="checkbox-label">{label}</span>}
      </label>
    </div>
  );
};

export default Checkbox;
