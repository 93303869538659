import { v4 as uuidv4 } from 'uuid';

enum SessionStorageKey {
  FASOO_UI_TOKEN = 'fasoo-ui-token',
  ADID_MANAGER_TOKEN = 'adid-manager-token',
  DEVICE_ID = 'device-id',
  LOGOUT_CALLBACK_ENTITIES = 'logout-callback-entities',
  ADID_MANAGER_USER = 'adid-manager-user',
  ADID_UPDOWNLOAD_SCHEME = 'adid-updownload-scheme',
  FASOO_UI_REFRESH_TOKEN = 'fasoo-ui-refresh-token',
  FASOO_UI_TOKEN_EXPIRED = 'fasoo-ui-token-expired',
  TOOL_DOWNLOAD_URL = 'tool-download-url',
  AUDIT_LIST = 'audit-list',
  LOGIN_WARN = 'login-warning',
}

interface LogoutCallbackEntity {
  logoutUrl: string;
  token: string;
  error?: string;
}

export const getToken = (): string | null => {
  return get(SessionStorageKey.FASOO_UI_TOKEN);
};

export const setToken = (token: string): void => {
  return set(SessionStorageKey.FASOO_UI_TOKEN, token);
};

export const removeToken = (): void => {
  return remove(SessionStorageKey.FASOO_UI_REFRESH_TOKEN);
};

export const getRefreshToken = (): string | null => {
  return get(SessionStorageKey.FASOO_UI_REFRESH_TOKEN);
};

export const setRefreshToken = (token: string): void => {
  return set(SessionStorageKey.FASOO_UI_REFRESH_TOKEN, token);
};

export const removeRefreshToken = (): void => {
  return remove(SessionStorageKey.FASOO_UI_TOKEN_EXPIRED);
};

export const getExpired = (): number => {
  return parseInt(get(SessionStorageKey.FASOO_UI_TOKEN_EXPIRED) || '0');
};

export const setExpired = (expired: number): void => {
  return set(SessionStorageKey.FASOO_UI_TOKEN_EXPIRED, expired.toString());
};

export const removeExpired = (): void => {
  return remove(SessionStorageKey.FASOO_UI_TOKEN);
};

export const getAdidManagerToken = (): string | null => {
  return get(SessionStorageKey.ADID_MANAGER_TOKEN);
};

export const setAdidManagerToken = (token: string): void => {
  return set(SessionStorageKey.ADID_MANAGER_TOKEN, token);
};

export const removeAdidManagerToken = (): void => {
  return remove(SessionStorageKey.ADID_MANAGER_TOKEN);
};

export const getAdidManagerUser = (): string | null => {
  return get(SessionStorageKey.ADID_MANAGER_USER);
};

export const setAdidManagerUser = (userId: string): void => {
  return set(SessionStorageKey.ADID_MANAGER_USER, userId);
};

export const removeAdidManagerUser = (): void => {
  return remove(SessionStorageKey.ADID_MANAGER_USER);
};

export const getAdidUpDownloadScheme = (): string | null => {
  return get(SessionStorageKey.ADID_UPDOWNLOAD_SCHEME);
};

export const getToolDownloadUrl = (): string | null => {
  return get(SessionStorageKey.TOOL_DOWNLOAD_URL);
};

export const getDeviceId = (): string => {
  let deviceId = get(SessionStorageKey.DEVICE_ID);
  if (!deviceId) {
    deviceId = uuidv4();
    setDeviceId(deviceId);
  }
  return deviceId;
};

export const setDeviceId = (deviceId: string): void => {
  return set(SessionStorageKey.DEVICE_ID, deviceId);
};

export const removeDeviceId = (): void => {
  return remove(SessionStorageKey.DEVICE_ID);
};

export const appendLogoutCallbackEntity = (entity: LogoutCallbackEntity): void => {
  const prevEntities = getLogoutCallbackEntities();
  const entities = [...prevEntities, entity];
  set(SessionStorageKey.LOGOUT_CALLBACK_ENTITIES, JSON.stringify(entities));
};

export const removeAndGetLogoutCallbackEntities = (): LogoutCallbackEntity[] => {
  const entities = getLogoutCallbackEntities();
  remove(SessionStorageKey.LOGOUT_CALLBACK_ENTITIES);
  return entities;
};

export const getAuditList = (): string | null => {
  return get(SessionStorageKey.AUDIT_LIST);
};

export const setAuditList = (token: string): void => {
  return set(SessionStorageKey.AUDIT_LIST, token);
};

export const getLoginWarningFlag = (): string | null => {
  return get(SessionStorageKey.LOGIN_WARN)
}

export const setLoginWarningFlag = (key: string): void => {
  return set(SessionStorageKey.LOGIN_WARN, key)
}

const getLogoutCallbackEntities = (): LogoutCallbackEntity[] => {
  try {
    const entitiesStr = get(SessionStorageKey.LOGOUT_CALLBACK_ENTITIES);
    if (entitiesStr) {
      const entities = JSON.parse(entitiesStr);
      if (Array.isArray(entities)) {
        return entities;
      } else {
        console.log(
          `Invalid data of key '${SessionStorageKey.LOGOUT_CALLBACK_ENTITIES}' : ${entitiesStr}`
        );
      }
    }
  } catch (e) {
    console.log(e);
  }
  return [];
};

const get = (key: SessionStorageKey): string | null => {
  return window.sessionStorage.getItem(key);
};

const set = (key: SessionStorageKey, value: string): void => {
  return window.sessionStorage.setItem(key, value);
};

const remove = (key: SessionStorageKey): void => {
  return window.sessionStorage.removeItem(key);
};
