import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import Button from 'src/components/atom/Button';
import TextField from 'src/components/atom/TextField';
import FormGroup from 'src/components/atom/FormGroup';
import FormLabel from 'src/components/atom/FormLabel';
import RadioGroup from 'src/components/atom/RadioGroup';
import Radio from 'src/components/atom/Radio';
import _, { create } from 'lodash';
import { CommonProps } from 'src/components/helpers/props';
import Popup2 from 'src/components/molecules/Popup2';
import templateApi, { TemplateDto, TaskDto, DetailDto, createTemplate, createAudit, createDetail, AuditDto, } from 'src/api/templateApi';
import { validateAll } from 'src/shared/utils/validations';
import { useAlert } from 'src/contexts/AlertContext';
import requestApi, { ReviewRequestDto } from 'src/api/requestApi';
import './RequestAllPopup.scss';
import { useToast } from 'src/contexts/ToastContext';

interface RequestAllPopupProps extends CommonProps {
  open: boolean;
  dto: ReviewRequestDto[];
  type: string;
  indexes: number[];
  onClose: () => void;
  onConfirmClose: () => void;
  // selected: UserInfoEntity[];
}

const RequestAllPopup: React.FC<RequestAllPopupProps> = ({
  open,
  dto,
  type,
  indexes,
  onClose,
  onConfirmClose,
  classes,
}) => {
  const { t } = useTranslation(['commons']);
  const [comment, setComment] = useState<string>('');
  const toast = useToast();
  const { alert, alert2, confirm2 } = useAlert();


  const validationRules = {
    // id: _.trim(project.id).length > 0 && validationId(project.id),
    // account: _.trim(form.account).length > 0,
    // name: _.trim(form.nickname).length > 0,
    // projectRole: selectedUsers.length > 0,
  };

  const handleOk = (): void => {
    if (!validateAll(validationRules)) {
      alert2('일괄 ' + type + '하기', '필수 값을 입력해주세요.');
      return;
    }
    console.log("requestList :", dto)
    console.log("indexes :", indexes)
    let requestArr: TemplateDto[] = [];
    let result: string = "Reject";
    result = type === '반려' ? "Reject" : "Confirm"

    indexes.forEach(idx => {
      if (dto[idx].detail_sub_title_name) {
        const requestData = createTemplate(dto[idx].template_name, dto[idx].category_name, dto[idx].task_name);
        const detail = createDetail(dto[idx].detail_name);
        const audit = createAudit(dto[idx].audit_name);
        audit.comment = comment;
        requestData.category_list[0].task_list[0].detail_list.push(detail);
        requestData.category_list[0].task_list[0].detail_list[0].detail_stage_name = "InterimAudit_4";
        requestData.category_list[0].task_list[0].detail_list[0].detail_sub_title_list = [{
          name: dto[idx].detail_sub_title_name,
          sub_detail_list: [{
            name: dto[idx].detail_sub_title_detail_name,
            audit_list: [audit]
          }]
        }]
        requestArr.push(requestData)
      } else {
        const requestData = createTemplate(dto[idx].template_name, dto[idx].category_name, dto[idx].task_name);
        const detail = createDetail(dto[idx].detail_name);
        const audit = createAudit(dto[idx].audit_name);
        audit.comment = comment;
        detail.audit_list.push(audit);
        requestData.category_list[0].task_list[0].detail_list.push(detail);
        requestArr.push(requestData)
      }

    })
    console.log("requestArr : ", requestArr)

    templateApi
      .handleReviews(requestArr, result)
      .then((resp) => {
        console.log(`${result} review`, resp);
        toast.info('일괄 ' + type + " 처리가 완료되었습니다.");
        //confirm2('일괄 ' + type + '하기', type + " 처리가 완료되었습니다.", onConfirmClose);
        onConfirmClose();
      })
      .catch(err => {
        alert2('일괄 ' + type + '하기', err);
      })
  };

  const handleCancel = (): void => {
    onClose();
  };

  useEffect(() => {
    console.log("dto : ", dto);
    console.log("indexes : ", indexes);
  }, [])

  return (
    <Popup2
      classes="request-all-popup-root project-popup-root"
      title={`일괄 ${type}하기`}
      width="1112px"
      height="692px"
      open={open}
      onClose={handleCancel}
      footer={
        <React.Fragment>
          <Button text={t('commons:cancel')} onClick={handleCancel} color="secondary" />
          <Button
            color={type === "반려" ? "fourth" : "primary"}
            text={type}
            onClick={handleOk}
          // disabled={!selectedUsers} 
          />
        </React.Fragment>
      }
    >
      <div className="project-input-area">
        <FormGroup classes="no-shrink comment">
          <FormLabel classes='input-label'><div>리뷰어 의견</div></FormLabel>
          <textarea className='input-area' value={comment} onChange={(e) => setComment(e.target.value)} autoFocus />
        </FormGroup>
        <div className="warn-message-root">
          <div className="warn-icon-background">
            <div style={{ color: "#ffffff", fontSize: 40, textAlign: "center", fontWeight: "bolder" }}>{"!"}
            </div>
          </div>
          <div className="warn-message">
            {"선택한 요청이 모두 " + type + "처리 됩니다."}
          </div>
        </div>


      </div>
    </Popup2>
  );
};

export default RequestAllPopup;
