import React, { useRef, useState, useEffect } from 'react';
import moment from 'moment';
import classNames from 'classnames';
import Button from 'src/components/atom/Button';
import { useHistory, useParams } from 'react-router-dom';
import Grid, { GridItem } from 'src/components/atom/Grid';
import Table from 'src/components/atom/Table';
import Checkbox from 'src/components/atom/Checkbox';
import requestApi, { ReviewRequestDto } from 'src/api/requestApi';
import SettingButton2, { DropdownItem } from 'src/components/atom/SettingButton2';
import useCheckboxGroup from 'src/hooks/useCheckboxGroup';
import templateApi, { TemplateDto, TaskDto, DetailDto, createTemplate, createAudit, createDetail, AuditDto2, } from 'src/api/templateApi';
import usePopup from 'src/hooks/usePopup';
import Select, { SelectItem } from 'src/components/atom/Select';
import { CommonProps } from 'src/components/helpers/props';
import './AuditDownload.scss';
import TextField from 'src/components/atom/TextField';
import SearchBarIcon from 'src/components/atom/icons/SearchBarIcon';
import { useAlert } from 'src/contexts/AlertContext';
import projectApi from 'src/api/projectApi';
import IconButton from 'src/components/atom/IconButton';
import ToolTip from 'src/components/atom/ToolTip';

const INIT_ROW_COUNT = 10;


const AuditDownload: React.FC = () => {
  const [auditList, setAuditList] = useState<AuditDto2[]>([]);
  const [reqFilteredList, setReqFilteredList] = useState<AuditDto2[]>([]);
  const [rowCount, setRowCount] = useState<number>(INIT_ROW_COUNT);
  const [offset, setOffset] = useState<number>(1);
  const [req, setReq] = useState<AuditDto2>({} as AuditDto2);
  const [reload, setReload] = useState<boolean>(false);
  const [type, setType] = useState<string>("")
  const [projectList, setProjectList] = useState<string[]>([]);
  const [selectedProject, setProject] = useState<string>("");
  const [searchText, setSearchText] = useState<string>("");
  const [doneChkbox, setDoneChkbox] = useState<boolean>(false);
  const { alert2 } = useAlert();
  const chkboxIndexes = useCheckboxGroup<number>();

  const { projectId } = useParams<{ projectId: string }>();
  useEffect(() => {
    projectApi
      .getAuditLists(projectId)
      .then((resp) => {
        setAuditList(resp)
        setReqFilteredList(resp)
        console.log("resp : ", resp)
      })
      .catch(e => alert2("에러발생", e))
  }, [])

  useEffect(() => {
    if (reload) {
      projectApi
        .getAuditLists(projectId)
        .then((resp) => {
          setAuditList(resp)
          setReqFilteredList(resp)
          setReload(false)
        })
        .catch(e => alert2("에러발생", e))
    }
  }, [reload])

  useEffect(() => {
    chkboxIndexes.handleAllClick([]);
  }, [offset, rowCount])

  useEffect(() => {
    // reqFilteredList 만들기
    let newFilteredList: AuditDto2[] = [];
    auditList.forEach((elem, index) => {

      let retFlag = true;
      // 검색어 검색(task, detail, audit)
      if (searchText.length !== 0 &&
        (!elem.file_name.includes(searchText)
        )
      ) {
        retFlag = false;
      }

      if (retFlag) {
        newFilteredList.push(elem)
      }
    })
    console.log("newFilteredList :", newFilteredList)
    setReqFilteredList(newFilteredList)
  }, [searchText])

  useEffect(() => {
    console.log("doneChkbox : ", doneChkbox);
    setReload(true);
  }, [doneChkbox])


  const onChangeText = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value)
    chkboxIndexes.initSelected([]);
  }

  const handleClickDownLoad = () => {
    function download(url: string) {
      const a = document.createElement('a')
      a.href = url
      a.download = url.split('/').pop() || ""
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
    }

    chkboxIndexes.selected?.forEach(selIdx => {
      download(auditList[selIdx]?.uri?.path || "")
    })
  }

  return (
    <div className="filedownload-list-root common-page-root">
      <div className="common-page-header">
        <Grid spacing={0}>
          <GridItem classes="common-page-grid-left" xs={12}>
            <ol className="common-page-header-breadcrumbs">
              <li>조서 다운로드</li>
            </ol>
          </GridItem>
        </Grid>
      </div>
      <div className="common-page-subheader">
        <Grid spacing={0}>
          <GridItem classes="common-page-grid-left" xs={7}>
            <div style={{ width: 360, height: "100%" }}>
              <TextField
                classes="filedownload-search-bar"
                placeholder="검색할 내용을 입력하세요.(파일명)"
                endIcon={<SearchBarIcon />}
                name={"seachText"}
                value={searchText} onChange={onChangeText}
              />
            </div>
            {/* <div className='check-box'>
              <Checkbox label={"완료된 요청 보여주기"}
                checked={doneChkbox}
                onChange={(name, value, checked) =>
                  setDoneChkbox(checked)
                }
              />
            </div> */}
          </GridItem>
          <GridItem classes="common-page-grid-right" xs={5}>
            {!doneChkbox && <Button
              text="선택 다운로드"
              disabled={chkboxIndexes?.selected?.length > 0 ? false : true}
              onClick={() => handleClickDownLoad()}
            />}
          </GridItem>
        </Grid>
      </div>

      <div className="common-page-body">
        <Table
          // horizontal
          // border={true}
          // headerFixed={true}
          headerColor={'lightgray'}
          headerBackgroundColor={'gray'}
          classes={'column-content-table'}
        >
          <colgroup>
            <col className='col-checkbox'></col>
            <col style={{ width: '400px' }}></col>
            <col style={{ width: '50px' }}></col>
            <col style={{ width: '60px' }}></col>
            <col style={{ width: '100px' }}></col>
            <col style={{ width: '50px' }}></col>

          </colgroup>

          <thead>
            <tr>
              <th className='cell-checkbox'>
                <Checkbox
                  checked={reqFilteredList.length > 0 &&
                    chkboxIndexes.selected.length === reqFilteredList.length}
                  onChange={(name, value, checked) =>
                    chkboxIndexes.handleAllClick(
                      checked ? reqFilteredList.map((o, i) => i) : []
                    )
                  }
                />
              </th>
              <th>파일 이름</th>
              <th>타입</th>
              <th>등록자</th>
              <th>등록일</th>
              <th className="col-settings"></th>
            </tr>
          </thead>
          <tbody>
            {reqFilteredList.map((elem, index) => {
              return (
                <tr key={"reqElem" + index}>
                  <td className="cell-checkbox">
                    <Checkbox
                      checked={chkboxIndexes.isSelected(index)}
                      onChange={(name, value, checked) =>
                        chkboxIndexes.handleClick(index)
                      }
                    />
                  </td>
                  <td>{elem.file_name}</td>
                  <td>{(elem?.informations && elem?.informations[0]?.type) === "small" ? "조서" : "대용량"}</td>
                  <td>{elem?.informations && elem?.informations[0].uploader?.nickname || ""}</td>
                  <td>{moment(elem?.informations && elem?.informations[0]?.at).format('YYYY.MM.DD')}</td>
                  <td className="col-settings">
                    <ToolTip message="다운로드">
                      <a href={elem.uri?.path} download target="_blank">
                        <IconButton>
                          <div className="down-load">
                            <img src={require("src/resources/images/downLoad.png")}
                              style={{ width: 11, height: 16 }}
                            />
                          </div>
                        </IconButton>
                      </a>
                    </ToolTip>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </Table>

      </div>
    </div>
  )
}

export default AuditDownload;
