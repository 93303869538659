import config from 'src/shared/config';
import axios from 'src/shared/utils/axios';

import DummyApi from '../dummyApi';
import { times } from '../api';

import { UseYN } from '.';

const INFRA_API_ENABLED = config.servers.infra.enabled;
const INFRA_API_URL = config.servers.infra.url;

export interface CommonTemplateDto {
  code: string;
  name: string;
  description: string;
  useYN: UseYN;
  entryTime: number;

  autoLogoutUseYN: UseYN; // 자동 로그아웃 사용여부 (Y/N)
  autoLogoutMinute: number; // 자동 로그아웃 시간 (분)
  offlineLoginUseYN: UseYN; //오프라인 로그인 사용여부 (Y/N)
  offlineLoginMinute: number; //오프라인 로그인 시간 (분)
}

export type CommonTemplateReqDto = Omit<CommonTemplateDto, 'code' | 'entryTime'>;

interface CommonTemplateApi {
  list(): Promise<CommonTemplateDto[]>;
  find(code: string): Promise<CommonTemplateDto>;
  create(reqDto: CommonTemplateReqDto): Promise<CommonTemplateDto>;
  update(code: string, reqDto: CommonTemplateReqDto): Promise<CommonTemplateDto>;
  remove(code: string): Promise<void>;
}

class CommonTemplateServerApi implements CommonTemplateApi {
  async list(): Promise<CommonTemplateDto[]> {
    const resp = await axios.get<CommonTemplateDto[]>(`${INFRA_API_URL}/api/templates`);
    return resp.data;
  }
  async find(code: string): Promise<CommonTemplateDto> {
    const resp = await axios.get<CommonTemplateDto>(`${INFRA_API_URL}/api/templates/${code}`);
    return resp.data;
  }
  async create(reqDto: CommonTemplateReqDto): Promise<CommonTemplateDto> {
    const resp = await axios.post<CommonTemplateDto>(`${INFRA_API_URL}/api/templates`, reqDto);
    return resp.data;
  }
  async update(code: string, reqDto: CommonTemplateReqDto): Promise<CommonTemplateDto> {
    const resp = await axios.put<CommonTemplateDto>(
      `${INFRA_API_URL}/api/templates/${code}`,
      reqDto
    );
    return resp.data;
  }
  async remove(code: string): Promise<void> {
    await axios.delete<CommonTemplateDto>(`${INFRA_API_URL}/api/templates/${code}`);
  }
}

class CommonTemplateDummyApi implements CommonTemplateApi {
  dummyApi: DummyApi<CommonTemplateDto, CommonTemplateReqDto>;
  constructor() {
    const data: CommonTemplateDto[] = [
      {
        code: '',
        name: '기본 템플릿',
        description: '기본 템플릿 설정',
        useYN: UseYN.Y,
        autoLogoutUseYN: UseYN.N,
        autoLogoutMinute: 1,
        offlineLoginUseYN: UseYN.Y,
        offlineLoginMinute: 2 * 24 * 60,
        entryTime: 0,
      },
      {
        code: '',
        name: '예외 템플릿',
        description: '예외 템플릿 설명',
        useYN: UseYN.Y,
        autoLogoutUseYN: UseYN.Y,
        autoLogoutMinute: 3 * 60,
        offlineLoginUseYN: UseYN.N,
        offlineLoginMinute: 1,
        entryTime: 1,
      },
    ].map((o, i) => ({ ...o, code: '', entryTime: times(i) }));

    this.dummyApi = DummyApi.of({
      entityName: 'Infra.CommonTemplate',
      createDto: (code, entity) => ({ ...entity, code, entryTime: Date.now() }),
      validation: (type, code, reqDto, data) => {
        data.forEach((dto) => {
          if (
            (type === 'create' && dto.name === reqDto.name) ||
            (type === 'update' && dto.code !== code && dto.name === reqDto.name)
          ) {
            throw new Error('이미 사용 중인 이름입니다.');
          }
        });
      },
      data,
    });
  }

  list(): Promise<CommonTemplateDto[]> {
    return this.dummyApi.list();
  }
  find(code: string): Promise<CommonTemplateDto> {
    return this.dummyApi.find(code);
  }
  create(reqDto: CommonTemplateReqDto): Promise<CommonTemplateDto> {
    return this.dummyApi.create(reqDto);
  }
  update(code: string, reqDto: CommonTemplateReqDto): Promise<CommonTemplateDto> {
    return this.dummyApi.update({ code, reqDto });
  }
  remove(code: string): Promise<void> {
    return this.dummyApi.remove(code);
  }
}

const commonTemplateApi: CommonTemplateApi = INFRA_API_ENABLED
  ? new CommonTemplateServerApi()
  : new CommonTemplateDummyApi();

export default commonTemplateApi;
