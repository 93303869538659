import React, { useState, useRef, useEffect } from 'react';
import classNames from 'classnames';

import { CommonProps } from 'src/components/helpers/props';
import ModalPortal from 'src/shared/react-utils/ModalPortal';

import './index.scss';
import useWindowSize from 'src/hooks/useWindowSize';

export interface ToolTipProps extends CommonProps {
  position?: 'top';
  maxWidth?: number;
  message: string | React.ReactNode;
  children: React.ReactNode;
  tooltipClasses?: string;
  adjTop?: number;
}

interface Position {
  left: number;
  top: number;
}

const ToolTip: React.FC<ToolTipProps> = ({
  message,
  maxWidth = 400,
  classes,
  children,
  position = 'top',
  tooltipClasses,
  adjTop = 0,
}) => {
  const targetRef = useRef<HTMLSpanElement>(null);
  const toolTipRef = useRef<HTMLParagraphElement>(null);
  const [windowInnerWidth, windowInnerHeight] = useWindowSize();

  const [state, setState] = useState<Position>({
    left: 0,
    top: 0,
  });
  const [hover, setHover] = useState(false);

  useEffect(() => {
    // 상대 위치
    const targetClientRect = (targetRef.current as Element).getBoundingClientRect();
    const relativeTop = targetClientRect.top;
    // 스크롤된 길이
    const scrolledTopLength = window.pageYOffset;
    // 절대 좌표
    const absoluteTop = scrolledTopLength + relativeTop;

    const absoluteLeft = window.pageXOffset + targetClientRect.left;

    const left = absoluteLeft - (toolTipRef.current as HTMLElement).offsetWidth / 2 + 11;
    const top = absoluteTop - ((toolTipRef.current as HTMLElement).offsetHeight + 12) + adjTop;

    setState({
      left:
        left < 0
          ? 0
          : left + (targetRef.current as HTMLElement).offsetWidth > windowInnerWidth
            ? windowInnerWidth - (targetRef.current as HTMLElement).offsetWidth
            : left,
      top:
        top < 0
          ? 0
          : top + (targetRef.current as HTMLElement).offsetHeight > windowInnerHeight
            ? windowInnerHeight - (targetRef.current as HTMLElement).offsetHeight
            : top,
    });
  }, [hover, windowInnerHeight, windowInnerWidth]);

  return (
    <React.Fragment>
      <span
        className={classNames(classes)}
        onMouseEnter={(e: React.MouseEvent<HTMLSpanElement>) => {
          setHover(true);
        }}
        onMouseLeave={(e) => {
          setHover(false);
        }}
        ref={targetRef}
        style={{
          display: 'inline-block',
        }}
      >
        {children}
      </span>
      <ModalPortal>
        <div
          className={classNames('tool-tip-root', {
            on: hover,
          })}
        >
          <p
            ref={toolTipRef}
            style={{ left: `${state.left}px`, top: `${state.top}px`, maxWidth: `${maxWidth}px` }}
            className={tooltipClasses}
          >
            <span>{message}</span>
          </p>
        </div>
      </ModalPortal>
    </React.Fragment>
  );
};

export default ToolTip;
