import React from 'react';
import classNames from 'classnames';

import { CommonProps } from 'src/components/helpers/props';
import './AbstractIcon.scss';

interface CalendarIconProps extends CommonProps {
  selected?: boolean;
  size?: 'md';
}

const CalendarIcon: React.FC<CalendarIconProps> = ({ classes, selected = false, size = 'md' }) => {
  return (
    <svg
      className={classNames('icon-root', classes)}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill={selected ? '#2e353c' : '#666666'}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.85 12.809H12V16.764H15.85V12.809ZM15.0917 4V5.61798H8.85V4H7.33333V5.61798H6.51667C5.7 5.61798 5 6.33708 5 7.17603V18.382C5 19.2809 5.7 20 6.51667 20H17.425C18.3 20 19 19.2809 19 18.382V7.17603C19 6.33708 18.3 5.61798 17.425 5.61798H16.6667V4H15.0917ZM17.425 18.382H6.51667V9.57303H17.425V18.382Z"
      />
    </svg>
  );
};

export default CalendarIcon;
