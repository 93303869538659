import React, { useEffect, useState } from 'react';

import usePopup from 'src/hooks/usePopup';
import { useAlert } from 'src/contexts/AlertContext';
import classNames from 'classnames';
import EditIcon from 'src/components/atom/icons/EditIcon';
import IconButton from 'src/components/atom/IconButton';
import DotIcon from 'src/components/atom/icons/DotIcon';
import ReactQuill, { Quill } from 'react-quill';
import Button from 'src/components/atom/Button';
import _ from 'lodash';

import './Step3.scss';

const Step3: React.FC<{
  detailTaskName: string,
  editMode: boolean,
  description: string,
  auditConclusion: string,
  supplement: string,
  handleChange: (html: string, type: string) => void;
  handleCancelRequirement: () => void;
  handleConfirmRequirement: () => void;
  handleDetailConfirm?: (opinions: any) => void;
}> = ({
  detailTaskName,
  editMode,
  description,
  auditConclusion,
  supplement,
  handleChange,
  handleCancelRequirement,
  handleConfirmRequirement,
  handleDetailConfirm = () => { }
}) => {

    return (
      <div className="sp-csm-step3-detail-body">
        <div className="sp-csm-step3-detail-body-title element">
          <div className="title" style={{ paddingLeft: 10 }}><DotIcon classes="dot-icon" color='#43425d' />절차설명</div>
        </div>
        <div className="sp-csm-step3-detail-body-requirements">
          <div className={classNames("requirements-editor")}>
            <ReactQuill
              modules={{
                toolbar: [
                  [
                    { list: "ordered" },
                    { list: "bullet" },
                    { indent: "-1" },
                    { indent: "+1" },
                  ],

                ]
              }}
              readOnly={!editMode}
              value={description || ''}
              style={{ fontSize: 50 }}
              onChange={(html) => handleChange(html, "description")}
            />
          </div>
        </div>
        <div className="sp-csm-step3-detail-body-title element">
          <div className="title" style={{ paddingLeft: 10 }}><DotIcon classes="dot-icon" color='#43425d' />감사결론</div>
        </div>
        <div className="sp-csm-step3-detail-body-requirements">

          <div className={classNames("requirements-editor")}>
            <ReactQuill
              modules={{
                toolbar: [
                  [
                    { list: "ordered" },
                    { list: "bullet" },
                    { indent: "-1" },
                    { indent: "+1" },
                  ],

                ]
              }}
              readOnly={!editMode}
              value={auditConclusion || ''}
              style={{ fontSize: 50 }}
              onChange={(html) => handleChange(html, "auditConclusion")}
            />
          </div>
        </div>
        <div className="sp-csm-step3-detail-body-title element">
          <div className="title" style={{ paddingLeft: 10 }}><DotIcon classes="dot-icon" color='#43425d' />보완사항</div>
        </div>
        <div className="sp-csm-step3-detail-body-requirements">

          <div className={classNames("requirements-editor")}>
            <ReactQuill
              modules={{
                toolbar: [
                  [
                    { list: "ordered" },
                    { list: "bullet" },
                    { indent: "-1" },
                    { indent: "+1" },
                  ],

                ]
              }}
              readOnly={!editMode}
              value={supplement || ''}
              style={{ fontSize: 50 }}
              onChange={(html) => handleChange(html, "supplement")}
            />
          </div>
        </div>
        {editMode && <div className="sp-csm-step3-detail-footer common-page-footer project-footer">
          <div className={classNames('right-button')}>
            <Button classes="footer-button" text="취소" color="secondary" onClick={handleCancelRequirement} />
            <Button classes="footer-button" text="확인" onClick={handleConfirmRequirement} />
          </div>
        </div>}
      </div>)
  }

export default Step3;