/* eslint-disable @typescript-eslint/camelcase */
import _ from 'lodash';

import { toLocalDateTimeStr } from 'src/shared/utils/localDateTime';
import config from 'src/shared/config';
import axios from 'src/shared/utils/axios';

const POLICY_URL = config.servers.issuance.url;
const POLICY_ENABLED = config.servers.issuance.enabled;
const URL_FORMAT = '/api/users';
const FSP_PRODUCT = 'FSP';

export interface LicenseAccountDto {
  product: string;
  userId: string;
  userName: string;
  deptName: string;
  positionName: string;
  roleName: string;
  entryTime: number; // 직급 갱신 일시
}

export const defaultLicenseAccountDto = (): LicenseAccountDto => {
  return {
    product: '',
    userId: '',
    userName: '',
    deptName: '',
    positionName: '',
    roleName: '',
    entryTime: 0,
  };
};

export interface LicenseAccountEntity extends LicenseAccountDto {
  entryTimeToStr: string;
}
export const defaultLicenseAccountEntity = (): LicenseAccountEntity => {
  return {
    ...defaultLicenseAccountDto(),
    entryTimeToStr: '',
  };
};

export function toEntity(dto: LicenseAccountDto): LicenseAccountEntity {
  return {
    ...dto,
    entryTimeToStr: toLocalDateTimeStr(dto.entryTime),
  };
}

const users = ([
  ['경영지원본부', '재무경영팀', 'kngang', '강용권', '상무'],
  ['경영지원본부', '재무경영팀', 'jieun.oh', '오지은', '부장'],
  ['경영지원본부', '재무경영팀', 'axkaliver', '박창민', '차장'],
  ['경영지원본부', '재무경영팀', 'dmsal339', '진은미', '차장'],
  ['경영지원본부', '재무경영팀', 'ckwoo', '우창국', '과장'],
  ['경영지원본부', '재무경영팀', 'snk', '손남규', '대리'],
  ['경영지원본부', '재무경영팀', 'hwang', '최수황', '대리'],
  ['경영지원본부', '재무경영팀', 'ycho11', '조윤정', '사원'],
  ['GS본부', 'GS사업팀', 'jason', '손종곤', '부장'],
  ['GS본부', 'GS사업팀', 'applseed', '양동식', '수석'],
  ['GS본부', 'GS사업팀', 'davidkwag', '곽정재', '차장'],
  ['GS본부', 'GS사업팀', 'yhgam', '감영하', '책임'],
  ['GS본부', 'GS사업팀', 'hsjang', '장형수', '과장'],
  ['GS본부', 'GS사업팀', 'gchoi9', '최채윤', '과장'],
  ['GS본부', 'GS사업팀', 'eternal206', '김종규', '선임'],
  ['GS본부', 'GS사업팀', 'yoojoi', '박유정', '선임'],
  ['GS본부', 'GS사업팀', 'eit88', '신민정', '선임'],
  ['GS본부', 'GS사업팀', 'dhan', '한다솜', '선임'],
  ['GS본부', 'GS사업팀', 'yoot', '유태영', '대리'],
  ['GS본부', 'GS사업팀', 'lucchan', '이찬', '전임'],
  ['GS본부', 'GS사업팀', 'hjkang', '강혜지', '사원'],
  ['GS본부', 'GS사업팀', 'sunghyun.park', '박성현', '사원'],
  ['GS본부', 'GS사업팀', 'jung_ah', '윤정아', '사원'],
  ['GS본부', 'GS사업팀', 'hyuk.jo', '조혁', '사원'],
  ['GS본부', 'GS사업팀', 'scha', '차성하', '사원'],
  ['GS본부', 'GS컨설팅팀', 'sbyun', '윤석범', '수석'],
  ['GS본부', 'GS컨설팅팀', 'yty', '이태양', '선임'],
  ['GS본부', 'GS컨설팅팀', 'ssb.jackson0227', '손승범', '사원'],
  ['GS본부', 'GS컨설팅팀', 'jylim070', '임지윤', '사원'],
  ['NS2본부', 'AE2팀', 'kspark', '박광수', '부장'],
  ['NS2본부', 'AE2팀', 'sunghoon.lee', '이성훈', '차장'],
  ['NS2본부', 'AE2팀', 'yg.jeon', '전용국', '차장'],
  ['NS2본부', 'AE2팀', 'minhoj', '정민호', '과장'],
  ['NS2본부', 'AE2팀', 'hjan', '안현진', '선임'],
  ['NS2본부', 'AE2팀', 'dhpark', '박동현', '대리'],
  ['NS2본부', 'AM2팀', 'juhyeok', '김주혁', '수석'],
  ['NS2본부', 'AM2팀', 'ysize', '김지수', '수석'],
  ['NS2본부', 'AM2팀', 'tskim', '김태상', '수석'],
  ['NS2본부', 'AM2팀', 'nowpds', '박상신', '수석'],
  ['NS2본부', 'AM2팀', 'jhshin', '신주환', '수석'],
  ['NS2본부', 'AM2팀', 'jpyang', '양재필', '수석'],
  ['NS2본부', 'AM2팀', 'sky8106', '이병권', '수석'],
  ['NS2본부', 'AM2팀', 'yslee', '이영석', '수석'],
  ['NS2본부', 'AM2팀', 'swcha', '차승원', '수석'],
  ['NS2본부', 'AM2팀', 'kjh', '국진호', '책임'],
  ['NS2본부', 'AM2팀', 'pk3350', '박기원', '책임'],
  ['NS2본부', 'AM2팀', 'igwtmin', '김광민', '과장'],
  ['NS2본부', 'AM2팀', 'twahn', '안태우', '선임'],
  ['NS2본부', 'AM2팀', 'simaris11', '이동엽', '선임'],
  ['NS2본부', 'AM2팀', 'dirino', '이은정', '선임'],
  ['NS2본부', 'AM2팀', 'chlee', '이춘호', '선임'],
  ['NS2본부', 'AM2팀', 'pigcha', '차주현', '선임'],
  ['NS2본부', 'AM2팀', 'hana7648', '최하나', '선임'],
  ['NS2본부', 'AM2팀', 'kmk6262', '강민구', '전임'],
  ['NS2본부', 'AM2팀', 'overs2002', '강성현', '전임'],
  ['NS2본부', 'AM2팀', 'hhmin', '민학희', '전임'],
  ['NS2본부', 'AM2팀', 'tyyoon', '윤태윤', '전임'],
  ['NS2본부', 'AM2팀', 'sung4727', '김성현', '사원'],
  ['NS2본부', 'AM2팀', 'khpollzu', '김현', '사원'],
  ['NS2본부', 'AM2팀', 'beommoyang', '양범모', '사원'],
  ['NS2본부', 'AM2팀', 'jimin.lee', '이지민', '사원'],
  ['NS2본부', 'AM2팀', 'jyj0687', '조윤정B', '사원'],
  ['NS3본부', 'AE3팀', 'mkkim', '김문기', '부장'],
  ['NS3본부', 'AE3팀', 'chhan', '한창희', '부장'],
  ['NS3본부', 'AE3팀', 'pjchoi', '최필준', '수석'],
  ['NS3본부', 'AE3팀', 'yhj2921', '양현준', '차장'],
  ['NS3본부', 'AE3팀', 'thlee', '이태희', '차장'],
  ['NS3본부', 'AE3팀', 'sis83', '손인산', '과장'],
  ['NS3본부', 'AE3팀', 'cusoon', '박용순', '선임'],
  ['NS3본부', 'AM3팀', 'junois', '손준호', '수석'],
  ['NS3본부', 'AM3팀', 'youks', '유경수', '수석'],
  ['NS3본부', 'AM3팀', 'mklee', '이민경', '수석'],
  ['NS3본부', 'AM3팀', 'w-tarzan', '이성영', '수석'],
  ['NS3본부', 'AM3팀', 'csj8121', '조성중', '수석'],
  ['NS3본부', 'AM3팀', 'jkyoo', '유재권', '책임'],
  ['NS3본부', 'AM3팀', 'youngmi_lee', '이영미', '책임'],
  ['NS3본부', 'AM3팀', 'tommy43', '장효섭', '책임'],
  ['NS3본부', 'AM3팀', 'kjy5345', '김재윤', '선임'],
  ['NS3본부', 'AM3팀', 'kth3286', '김태현', '선임'],
  ['NS3본부', 'AM3팀', 'hsroh', '노혜수', '선임'],
  ['NS3본부', 'AM3팀', 'pmy9879', '박민영', '선임'],
  ['NS3본부', 'AM3팀', 'parkwanwoo', '박완우', '선임'],
  ['NS3본부', 'AM3팀', 'bkyoo', '유병규', '선임'],
  ['NS3본부', 'AM3팀', 'silee', '이상인', '선임'],
  ['NS3본부', 'AM3팀', 'jinnylee0425', '이진희', '선임'],
  ['NS3본부', 'AM3팀', 'holemanv', '한아름', '선임'],
  ['NS3본부', 'AM3팀', 'dyhong', '홍두영', '대리'],
  ['NS3본부', 'AM3팀', 'jyk3489', '김재용', '전임'],
  ['NS3본부', 'AM3팀', 'jiyunpark', '박지윤', '전임'],
  ['NS3본부', 'AM3팀', 'duk_ki', '강덕기', '사원'],
  ['NS3본부', 'AM3팀', 'khjs93', '김훈재', '사원'],
  ['NS3본부', 'AM3팀', 'gyubean', '박규빈', '사원'],
  ['NS3본부', 'AM3팀', 'bae9110', '배진호', '사원'],
  ['NS3본부', 'AM3팀', 'yyoung', '홍보영', '사원'],
  ['NS1본부', '사업전략팀', 'ycjkoo', '구창진', '상무보'],
  ['NS1본부', '사업전략팀', 'cwsgood', '최우선', '수석'],
  ['NS1본부', '사업전략팀', 'jhchoi', '최재호', '수석'],
  ['NS1본부', '사업전략팀', 'soen', '서용석', '책임'],
  ['NS1본부', '사업전략팀', 'jalee1220', '이지애', '책임'],
  ['NS1본부', '사업전략팀', 'daehojang', '장대호', '대리'],
  ['NS1본부', '사업전략팀', 'jhseong', '성주현', '사원'],
  ['NS1본부', '사업전략팀', 'shjang', '장세환', '사원'],
  ['NS1본부', '컨설팅사업팀', 'wschoi', '최우성', '상무'],
  ['NS1본부', '컨설팅사업팀', 'binson90', '박성수', '수석'],
  ['NS1본부', '컨설팅사업팀', 'kh.yoo', '유경환', '수석'],
  ['NS1본부', '컨설팅사업팀', 'sh.kim', '김상해', '책임'],
  ['NS1본부', '컨설팅사업팀', 'jjkhk', '권현구', '선임'],
  ['NS1본부', '컨설팅사업팀', 's.kim', '김설', '선임'],
  ['NS1본부', '컨설팅사업팀', 'aykim', '김아영', '전임'],
  ['NS1본부', '컨설팅사업팀', 'hwnoh831', '노형욱', '전임'],
  ['NS1본부', '컨설팅사업팀', '0xe82de', '김경석', '사원'],
  ['NS1본부', '컨설팅사업팀', 'gahyun', '서가현', '사원'],
  ['NS1본부', '컨설팅사업팀', 'jaeuns1', '신자은', '사원'],
  ['NS1본부', '컨설팅사업팀', 'sincwhale7', '정유진', '사원'],
  ['NS1본부', '컨설팅사업팀', 'naun.chae', '채나운', '사원'],
  ['개발1본부', '개발센터', 'pgkang', '강필구', '수석'],
  ['개발1본부', '개발센터', 'kns2505', '김남식', '수석'],
  ['개발1본부', '개발센터', 'jhkim', '김재환', '수석'],
  ['개발1본부', '개발센터', 'jhuman', '김지홍', '수석'],
  ['개발1본부', '개발센터', 'vision', '김훈', '수석'],
  ['개발1본부', '개발센터', 'glukjeoluk', '송승호', '수석'],
  ['개발1본부', '개발센터', 'djatmxk', '엄진석', '수석'],
  ['개발1본부', '개발센터', 'hdyoon', '윤형동', '수석'],
  ['개발1본부', '개발센터', 'jinwonlee', '이진원', '수석'],
  ['개발1본부', '개발센터', 'culate', '이형주', '수석'],
  ['개발1본부', '개발센터', 'yjcho', '조용주', '수석'],
  ['개발1본부', '개발센터', 'kyo0280', '진교영', '수석'],
  ['개발1본부', '개발센터', 'minsak5', '오민석', '책임'],
  ['개발1본부', '개발센터', 'cloud9', '이재익', '책임'],
  ['개발1본부', '개발센터', 'chajunho', '차준호', '책임'],
  ['개발1본부', '개발센터', 'hyhan', '한호영', '책임'],
  ['개발1본부', '개발센터', 'oshomku', '강현구', '선임'],
  ['개발1본부', '개발센터', 'ty2', '이태양B', '선임'],
  ['개발1본부', '개발센터', 'chlyddul', '최윤영', '선임'],
  ['개발1본부', '개발센터', 'choijh', '최준호', '선임'],
  ['개발1본부', '개발센터', 'alstjq0426', '소민섭', '전임'],
  ['개발1본부', '개발센터', 'ksson', '손기수', '전임'],
  ['개발1본부', '개발센터', 'sryeon', '연소라', '전임'],
  ['개발1본부', '개발센터', 'hb__v', '연한별', '전임'],
  ['개발1본부', '개발센터', 'joo6392', '이주영', '전임'],
  ['개발1본부', '개발센터', 'dhjang', '장동혁', '전임'],
  ['개발1본부', '개발센터', 'jangyoowon', '장유원', '전임'],
  ['개발1본부', '개발센터', 'gyjung', '정기영', '전임'],
  ['개발1본부', '개발센터', 'yoonjoo544', '지윤주', '전임'],
  ['개발1본부', '개발센터', 'sr.tachin', 'Srisombat Tachinta', '전임'],
  ['개발1본부', '개발센터', 'ty.kim', '김태영', '사원'],
  ['개발1본부', '개발센터', 'hskim3', '김희수', '사원'],
  ['개발1본부', '개발센터', 'sssukho', '임석호', '사원'],
  ['개발1본부', '개발센터', 'jcy91', '조찬영', '사원'],
  ['개발1본부', '개발센터', 'newlcb0813', '황영식', '사원'],
  ['개발3본부', '개발3본부', 'dohyun', '김도현', '수석'],
  ['개발3본부', '개발3본부', 'shsong', '송성호', '수석'],
  ['개발3본부', '개발3본부', 'jppark', '박준필', '선임'],
  ['개발3본부', '개발3본부', 'booguni', '박부건', '전임'],
  ['개발3본부', '개발3본부', 'jingyu', '김진규', '사원'],
  ['개발3본부', '개발3본부', '2weeks0', '이주영B', '사원'],
  ['개발3본부', '개발3본부', 'ashley1021', '김세은', '인턴'],
  ['개발2본부', '품질팀', 'jongkkeun', '김종근', '수석'],
  ['개발2본부', '품질팀', 'mjh0214', '문지혜', '수석'],
  ['개발2본부', '품질팀', 'maverick', '윤영민', '수석'],
  ['개발2본부', '품질팀', 'junsh019', '전세한', '수석'],
  ['개발2본부', '품질팀', 'alcava', '강혜경', '책임'],
  ['개발2본부', '품질팀', 'pskij08', '김인정', '책임'],
  ['개발2본부', '품질팀', 'odeun', '오동은', '전임'],
  ['개발2본부', '품질팀', 'jsryu', '유진선', '전임'],
  ['개발2본부', '품질팀', 'dain.lee', '이다인', '전임'],
  ['개발2본부', '품질팀', 'ksjeon', '전경선', '전임'],
  ['개발2본부', '품질팀', 'sjpark90', '박상진', '사원'],
  ['개발2본부', 'CS팀', 'vicevan', '김철중', '수석'],
  ['개발2본부', 'CS팀', 'springk', '김보미', '선임'],
  ['개발2본부', 'CS팀', 'saus111', '이효주', '선임'],
  ['개발2본부', 'CS팀', 'hylim88', '임혜연', '선임'],
  ['개발2본부', 'CS팀', 'bigpet', '주승룡', '선임'],
  ['개발2본부', 'CS팀', 'jyhuh', '허진영', '선임'],
  ['개발2본부', 'CS팀', 'syoni0126', '박수연', '전임'],
  ['개발2본부', 'CS팀', 'hjyang', '양현진', '전임'],
  ['개발2본부', 'CS팀', 'minkyu.lee', '이민규', '전임'],
  ['개발2본부', 'CS팀', 'eunjin', '조은진', '전임'],
  ['개발2본부', 'CS팀', 'bh.gu', '구본혁', '파견사원'],
  ['개발2본부', '정보관리팀', 'hspark', '박현수', '수석'],
  ['개발2본부', '정보관리팀', 'oscdream', '오상철', '수석'],
  ['개발2본부', '정보관리팀', 'livise', '임채현', '수석'],
  ['개발2본부', '정보관리팀', 'clairej', '정가영', '선임'],
  ['개발2본부', '정보관리팀', 'yih0123', '여인혁', '사원'],
  ['개발2본부', '정보관리팀', 'yongkeun', '이용근', '인턴'],
  ['개발2본부', '정보관리팀', 'jungdahee0108', '정다희', '인턴'],
  ['개발2본부', '랩소디개발팀', 'jeongmoon', '오정문', '수석'],
  ['개발2본부', '랩소디개발팀', 'eureka', '조정현', '수석'],
  ['개발2본부', '랩소디개발팀', 'lunarcell9', '김주훈', '책임'],
  ['개발2본부', '랩소디개발팀', 'lbj', '이병주', '책임'],
  ['개발2본부', '랩소디개발팀', 'vizza', '박준식', '선임'],
  ['개발2본부', '랩소디개발팀', 'minji', '김민지', '전임'],
  ['개발2본부', '랩소디개발팀', 'sangwon.jo', '조상원', '전임'],
  ['개발2본부', '랩소디개발팀', 'jhpark0912', '박재현', '사원'],
  ['개발2본부', '랩소디개발팀', 'kyungjae.cha', '차경재', '사원'],
  ['개발2본부', '클라우드개발팀', 'iris', '김광훈', '수석'],
  ['개발2본부', '클라우드개발팀', 'ahnjc', '안종철', '수석'],
  ['개발2본부', '클라우드개발팀', 'bluegenii', '이수용', '책임'],
  ['개발2본부', '클라우드개발팀', 'chuhs', '추화성', '책임'],
  ['개발2본부', '클라우드개발팀', 'oukkang', '강명환', '선임'],
  ['개발2본부', '클라우드개발팀', 'hongjin', '안홍진', '선임'],
  ['개발2본부', '클라우드개발팀', 'hj.yang', '양현준B', '사원'],
  ['개발2본부', '클라우드개발팀', 'yerin8888', '이예린', '사원'],
] as Array<[string, string, string, string, string]>).map(
  ([parentDeptName, deptName, userId, userName, positionName]) => ({
    userId,
    userName,
    deptName,
    parentDeptName,
    positionName,
  })
);

const dummyData: {
  accounts: LicenseAccountDto[];
} = {
  accounts: _.times(30).map((i) => {
    const account = users[_.random(users.length - 1)];
    return {
      userId: `${i}-${account.userId}`,
      product: 'FSP',
      userName: account.userName,
      deptName: account.deptName,
      positionName: account.positionName,
      roleName: 'UX 디자인??',
      entryTime: 1598965200001,
    };
  }),
};

export interface LicenseAccountReqParamDto {
  product: string;
  searchTarget?: string;
  searchText?: string;
}
export const defaultLicenseAccountReqParamDto = (): LicenseAccountReqParamDto => {
  return {
    product: FSP_PRODUCT,
    searchTarget: 'all',
  };
};

const convertParamToURL = (
  param: LicenseAccountReqParamDto,
  offset: number,
  limit: number
): URLSearchParams => {
  const params = new URLSearchParams();
  params.append('product', param.product);
  params.append('page', String(offset));
  params.append('limit', String(limit));

  if (param.searchTarget != null && param.searchText != null && param.searchText !== '') {
    params.append('search-text', param.searchText);
    params.append('search-target', param.searchTarget);
  }

  return params;
};

export interface LicenseAccountApiDto {
  count: number;
  list: LicenseAccountEntity[];
}

interface LicenseAccountApi {
  list(
    param: LicenseAccountReqParamDto,
    offset: number,
    limit: number
  ): Promise<LicenseAccountApiDto>;

  totalCount(): Promise<number>;
}

class LicenseAccountServerApi implements LicenseAccountApi {
  async list(
    param: LicenseAccountReqParamDto,
    offset: number,
    limit: number
  ): Promise<LicenseAccountApiDto> {
    const params = convertParamToURL(param, offset + 1, limit);
    const resp = await axios.get<LicenseAccountApiDto>(`${POLICY_URL}` + URL_FORMAT + '?', {
      params,
    });
    return Promise.resolve(resp.data);
  }

  async totalCount(): Promise<number> {
    const params = convertParamToURL(defaultLicenseAccountReqParamDto(), 1, 1);
    const resp = await axios.get<LicenseAccountApiDto>(`${POLICY_URL}` + URL_FORMAT + '?', {
      params,
    });
    if (resp.data !== null && resp.data.count > 0) {
      return Promise.resolve(resp.data.count);
    } else {
      return Promise.resolve(0);
    }
  }
}

class LicenseAccountDummyApi implements LicenseAccountApi {
  list(
    param: LicenseAccountReqParamDto,
    offset: number,
    limit: number
  ): Promise<LicenseAccountApiDto> {
    const url = convertParamToURL(param, offset, limit);
    const serverDtos = _.cloneDeep(dummyData.accounts);
    const dtos = serverDtos.map((serverDto) => toEntity(serverDto)).slice(offset, offset + limit);

    return Promise.resolve({
      count: dtos.length,
      list: dtos,
    });
  }

  totalCount(): Promise<number> {
    return Promise.resolve(dummyData.accounts.length);
  }
}

const licenseAccountApi: LicenseAccountApi = POLICY_ENABLED
  ? new LicenseAccountServerApi()
  : new LicenseAccountDummyApi();

export default licenseAccountApi;
