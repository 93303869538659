import qs from 'query-string';
import _, { Dictionary } from 'lodash';

function getFirstQuery(key: string): string | undefined {
  const queries = qs.parse(window.location.search);
  const values = queries[key];
  return getFirstQueryValue(values);
}

function getAllFirstQuery(): Dictionary<string | undefined> {
  const queries = qs.parse(window.location.search);
  const firstQueries = Object.keys(queries).reduce((acc, key) => {
    acc[key] = getFirstQueryValue(queries[key]);
    return acc;
  }, {} as Dictionary<string | undefined>);
  return firstQueries;
}

function getFirstQueryValue(values: string | string[] | null): string | undefined {
  if (values === null) {
    return undefined;
  }
  if (Array.isArray(values)) {
    return _.head(values);
  } else {
    return values;
  }
}

function getPath(): string {
  return window.location.pathname + window.location.search;
}

// TODO 파일명을 locationUtils.ts로 바꿔야 하나....
function setHref(href: string): void {
  window.location.href = href;
}

export default {
  getFirstQuery,
  getAllFirstQuery,
  getPath,
  setHref,
};
