import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import patternGroupApi, {
  PatternGroupDto,
  PatternGroupReqDto,
  PatternGroupReplaceReqDto,
} from 'src/api/infra/patternGroupApi';
import { toLocalDateTimeStr } from 'src/shared/utils/localDateTime';
import { RootState } from 'src/store';

// # types
export interface PatternGroupEntity {
  code: string;
  name: string;
  entryTsStr: string;

  count: number;
}
export type PatternGroupReqEntity = Omit<PatternGroupEntity, 'code' | 'entryTsStr' | 'count'>;
export type PatternGroupReplaceReqEntity = Omit<PatternGroupEntity, 'entryTsStr' | 'count'>;

function toEntity(dto: PatternGroupDto): PatternGroupEntity {
  return {
    code: dto.code,
    name: dto.name,
    entryTsStr: toLocalDateTimeStr(dto.entryTime),

    count: dto.count,
  };
}
function toReqDto(entity: PatternGroupReqEntity): PatternGroupReqDto {
  return {
    name: entity.name,
  };
}
function toReplaceReqDto(entity: PatternGroupReplaceReqEntity): PatternGroupReplaceReqDto {
  return {
    code: entity.code,
    name: entity.name,
  };
}

interface PatternGroupEntityByCode {
  [code: string]: PatternGroupEntity;
}
interface PatternGroupEntities {
  byCode: PatternGroupEntityByCode;
  allCodes: string[];
}
interface PatternGroupState {
  initialized: boolean;
  entities: PatternGroupEntities;
}

// # initial state
const initialState: PatternGroupState = {
  initialized: false,
  entities: { byCode: {}, allCodes: [] },
};

export const defaultPatternGroupReqEntity: PatternGroupReqEntity = {
  name: '',

  // ...props
};

// # thunks
export const thunkLoadPatternGroups = createAsyncThunk('infra/patternGroup/load', async () => {
  const list = await patternGroupApi.list();
  return list.map((dto) => toEntity(dto));
});
export const thunkCreatePatternGroup = createAsyncThunk(
  'infra/patternGroup/create',
  async (reqEntity: PatternGroupReqEntity) => {
    return toEntity(await patternGroupApi.create(toReqDto(reqEntity)));
  }
);
export const thunkReplacePatternGroup = createAsyncThunk(
  'infra/patternGroup/replace',
  async (replaceReqEntities: PatternGroupReplaceReqEntity[]) => {
    const list = await patternGroupApi.replace(
      replaceReqEntities.map((reqEntity) => toReplaceReqDto(reqEntity))
    );
    return list.map((dto) => toEntity(dto));
  }
);

const patternGroupSlice = createSlice({
  name: 'patternGroup',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(thunkLoadPatternGroups.fulfilled, (state, { payload: patternGroups }) => {
      state.entities.byCode = patternGroups.reduce((acc, t) => {
        acc[t.code] = t;
        return acc;
      }, {} as PatternGroupEntityByCode);
      state.entities.allCodes = patternGroups.map((t) => t.code);
      state.initialized = true;
    });
    builder.addCase(thunkCreatePatternGroup.fulfilled, (state, { payload: createdEntity }) => {
      state.entities.byCode[createdEntity.code] = createdEntity;
      state.entities.allCodes.push(createdEntity.code);
    });
    builder.addCase(thunkReplacePatternGroup.fulfilled, (state, { payload: patternGroups }) => {
      state.entities.byCode = patternGroups.reduce((acc, t) => {
        acc[t.code] = t;
        return acc;
      }, {} as PatternGroupEntityByCode);
      state.entities.allCodes = patternGroups.map((t) => t.code);
    });
  },
});

// # selectors
export const selectInitialized = (state: RootState): boolean => {
  return state.patternGroup.initialized;
};
export const selectPatternGroupEntities = (state: RootState): PatternGroupEntities =>
  state.patternGroup.entities;
export const selectPatternGroupEntityList = (state: RootState): PatternGroupEntity[] =>
  state.patternGroup.entities.allCodes.map((code) => state.patternGroup.entities.byCode[code]);

export default patternGroupSlice.reducer;
