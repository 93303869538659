import config from 'src/shared/config';
import axios from 'src/shared/utils/axios';

import DummyApi from './dummyApi';
import { times } from './api';

const FSP_API_ENABLED = config.servers.fsp.enabled;
const FSP_API_URL = config.servers.fsp.url;

export interface WatermarkFileDto {
  code: string;
  name: string;
  description: string;
  use: boolean;
  entryTs: number;

  thumbnail: string; // 썸네일 (base64 인코딩 된 이미지 바이너리)
  fileName: string;
}

export type WatermarkFileReqDto = Omit<
  WatermarkFileDto,
  'code' | 'entryTs' | 'thumbnail' | 'fileName'
>;

export const defaultWatermarkFileReqDto: WatermarkFileReqDto = {
  name: '',
  description: '',
  use: true,
};

interface WatermarkFileApi {
  list(): Promise<WatermarkFileDto[]>;
  find(code: string): Promise<WatermarkFileDto>;
  download(code: string): Promise<Blob>;
  create(reqDto: WatermarkFileReqDto, file: File): Promise<WatermarkFileDto>;
  update(code: string, reqDto: WatermarkFileReqDto, file?: File): Promise<WatermarkFileDto>;
  remove(code: string): Promise<void>;
}

class WatermarkFileServerApi implements WatermarkFileApi {
  async list(): Promise<WatermarkFileDto[]> {
    const resp = await axios.get<WatermarkFileDto[]>(`${FSP_API_URL}/api/wats/files`);
    return resp.data;
  }
  async find(code: string): Promise<WatermarkFileDto> {
    const resp = await axios.get<WatermarkFileDto>(`${FSP_API_URL}/api/wats/files/${code}`);
    return resp.data;
  }
  async download(code: string): Promise<Blob> {
    const resp = await axios.get(`${FSP_API_URL}/api/wats/files/${code}/data`, {
      responseType: 'blob',
    });
    return new Blob([resp.data]);
  }
  async create(reqDto: WatermarkFileReqDto, file: File): Promise<WatermarkFileDto> {
    const formData = new FormData();
    formData.append('watFile', JSON.stringify(reqDto));
    formData.append('data', file);

    const resp = await axios.post<WatermarkFileDto>(`${FSP_API_URL}/api/wats/files`, formData);
    return resp.data;
  }
  async update(code: string, reqDto: WatermarkFileReqDto, file?: File): Promise<WatermarkFileDto> {
    const formData = new FormData();
    formData.append('watFile', JSON.stringify(reqDto));
    if (file) {
      formData.append('data', file);
    }

    const resp = await axios.put<WatermarkFileDto>(
      `${FSP_API_URL}/api/wats/files/${code}`,
      formData
    );
    return resp.data;
  }
  async remove(code: string): Promise<void> {
    await axios.delete<WatermarkFileDto>(`${FSP_API_URL}/api/wats/files/${code}`);
  }
}

class WatermarkFileDummyApi implements WatermarkFileApi {
  dummyApi: DummyApi<WatermarkFileDto, WatermarkFileReqDto>;
  constructor() {
    const data: WatermarkFileDto[] = [
      {
        name: '일반 워터마크',
        description: '일반용 워터마크',
        use: true,
        thumbnail: '',
        fileName: '일반_워터마크.pwm',
      },
      {
        name: '기밀문서 워터마크',
        description: '기밀문서용 워터마크',
        use: true,
        thumbnail: '',
        fileName: '기밀문서_워터마크.pwm',
      },
      {
        name: '개인정보문서 워터마크',
        description: '개인정보문서용 워터마크',
        use: true,
        thumbnail: '',
        fileName: '개인정보문서_워터마크.pwm',
      },
    ].map((o, i) => ({ ...o, code: '', entryTs: times(i) }));

    this.dummyApi = DummyApi.of({
      entityName: 'WatermarkFile',
      createDto: (code, entity) => ({
        ...entity,
        code,
        thumbnail: '',
        fileName: `${entity.name.replace(/ /g, '_')}.pwm`,
        entryTs: Date.now(),
      }),
      validation: (type, code, reqDto, data) => {
        data.forEach((dto) => {
          if (
            (type === 'create' && dto.name === reqDto.name) ||
            (type === 'update' && dto.code !== code && dto.name === reqDto.name)
          ) {
            throw new Error('이미 사용 중인 이름입니다.');
          }
        });
      },
      data,
    });
  }

  list(): Promise<WatermarkFileDto[]> {
    return this.dummyApi.list();
  }
  find(code: string): Promise<WatermarkFileDto> {
    return this.dummyApi.find(code);
  }
  download(code: string): Promise<Blob> {
    throw new Error(`Dummy API에서는 다운로드 지원 안 함 (code: ${code})`);
  }
  create(reqDto: WatermarkFileReqDto, file: File): Promise<WatermarkFileDto> {
    return this.dummyApi.create(reqDto);
  }
  update(code: string, reqDto: WatermarkFileReqDto, file?: File): Promise<WatermarkFileDto> {
    return this.dummyApi.update({ code, reqDto });
  }
  remove(code: string): Promise<void> {
    return this.dummyApi.remove(code);
  }
}

const watermarkFileApi: WatermarkFileApi = FSP_API_ENABLED
  ? new WatermarkFileServerApi()
  : new WatermarkFileDummyApi();

export default watermarkFileApi;
