import React, { useEffect, useState, useContext, Fragment } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { CommonProps } from 'src/components/helpers/props';
import Popup2 from 'src/components/molecules/Popup2';
import Button from 'src/components/atom/Button';
import './Step4EditPopup.scss';
import { useAlert } from 'src/contexts/AlertContext';
import { validateAll, validateAll2 } from 'src/shared/utils/validations';
import _, { omit } from 'lodash';
import Select, { SelectItem } from 'src/components/atom/Select';

interface Step4EditPopupProps extends CommonProps {
  open: boolean;
  riskData: any;
  onConfirm: (riskState: any) => void;
  onClose: () => void;
}


const Step4EditPopup: React.FC<Step4EditPopupProps> = ({
  open,
  riskData,
  onClose,
  onConfirm,
  classes,
}) => {
  const { t } = useTranslation(['commons']);
  const { alert2 } = useAlert();
  const [riskState, setRiskState] = useState<any>(riskData);
  const risk1List = ["미지정", "높음", "낮음"]
  const risk2List = ["미지정", "의존", "의존안함"]
  const validationRules = {
    // id: _.trim(project.id).length > 0 && validationId(project.id),
    // name: _.trim(form.name).length > 0,
    // projectRole: selectedUsers.length > 0,
    // name: riskState?.data.every((item: any) => item.title.length > 0)
    // unique_risk: riskState?.management_assertion_list?.every((item: any) => item.unique_risk?.length > 0),
    // control_risk: riskState?.management_assertion_list?.every((item: any) => item.control_risk?.length > 0),
    combination_risk: riskState?.management_assertion_list?.every((item: any) => {
      if (item.unique_risk?.length > 0) {
        if (item.control_risk?.length > 0) {
          return true;
        }
        return false;
      } else if (item.control_risk?.length > 0) {
        if (item.unique_risk?.length > 0) {
          return true;
        }
        return false;
      }
      return true;
    })
  };

  const handleOk = (): void => {
    const r = validateAll2(validationRules)
    if (!r.retVal) {
      if (r.key === "combination_risk") {
        alert2("위험평가 관리", "고유 위험과 통제 위험은 둥 중 하나만 선택할 수 없습니다.");
      }
      return;
    }
    // if (!validateAll(validationRules)) {
    //   alert2("위험평가 관리", "계정명은 필수 입력값입니다.");
    //   return;
    // }

    onConfirm(riskState)
    onClose();
  };

  useEffect(() => {
  }, []);

  const getCombinationRisk = (risk1: string, risk2: string): string => {
    if (risk1 === "높음" && risk2 === "의존") {
      return "중간"
    } else if (risk1 === "높음" && risk2 === "의존안함") {
      return "높음"
    } else if (risk1 === "낮음" && risk2 === "의존") {
      return "낮음"
    } else if (risk1 === "낮음" && risk2 === "의존안함") {
      return "중간"
    }
    return ""
  }

  const handleClose = () => {
    // console.log("riskState : ", JSON.stringify(riskData));
    onClose();
  }

  const handleChangeRisk1 = (value: string, index: number) => {
    let newRiskState = { ..._.cloneDeep(riskState) };
    // console.log("value : ", value)
    newRiskState.management_assertion_list[index].unique_risk = value === "미지정" ? "" : value;
    newRiskState.management_assertion_list[index].combination_risk = getCombinationRisk(newRiskState.management_assertion_list[index].unique_risk, newRiskState.management_assertion_list[index].control_risk);
    // console.log("newRiskState : ", newRiskState)
    setRiskState(newRiskState)
  }
  const handleChangeRisk2 = (value: string, index: number) => {
    let newRiskState = { ..._.cloneDeep(riskState) };
    newRiskState.management_assertion_list[index].control_risk = value === "미지정" ? "" : value;
    newRiskState.management_assertion_list[index].combination_risk = getCombinationRisk(newRiskState.management_assertion_list[index].unique_risk, newRiskState.management_assertion_list[index].control_risk);
    // console.log("newRiskState : ", newRiskState)
    setRiskState(newRiskState)
  }

  return (
    <Popup2
      classes="audit-popup-root project-popup-root project-common"
      title={`위험평가 관리(${riskState.name})`}
      width="1112px"
      height="692px"
      open={open}
      onClose={handleClose}
      footer={
        <React.Fragment>
          <Button text={t('commons:cancel')} onClick={handleClose} color="secondary" />
          <Button
            text={'확인'}
            onClick={handleOk}
          // disabled={!selectedUsers} 
          />
        </React.Fragment>
      }
    >
      <div className="task-header element" style={{ padding: 5 }}>
        <div style={{ width: "30%", color: "#a3a6b4", textAlign: "center" }}>{riskData.name}</div>
        <div style={{ width: "15%", color: "#a3a6b4", textAlign: "center" }}>고유 위험</div>
        <div style={{ width: "15%", color: "#a3a6b4", textAlign: "center" }}>통제 위험</div>
        <div style={{ width: "15%", color: "#a3a6b4", textAlign: "center" }}>결합 위험</div>
        <div style={{ width: "15%", color: "#a3a6b4", textAlign: "center" }}>유의적인 위험</div>
        <div style={{ width: "15%", color: "#a3a6b4", textAlign: "center" }}>부정 위험</div>
      </div>
      <div className="audit-process-content-step4-root">
        {riskState?.management_assertion_list?.map((elem: any, i: number) => {
          return (
            <div className="audit-process-content-step4" key={i.toString()}>
              <div className="audit-process-content-risk">
                <div className="audit-process-content-risk-text">{elem.key}</div>
              </div>
              <div className="audit-process-content-risk1">
                <Select
                  vertical={riskState?.management_assertion_list?.length === i + 1 && i > 5 ? "up" : "down"}
                  width={100}
                  classes={"audit-process-content-risk1-select"}
                  selectFirstItem={false}
                  selectedEventKey={elem.unique_risk}
                  textAlign='right'
                  align='left'
                  placeholder="미지정"
                  border={false}
                  onClick={(value) => { handleChangeRisk1(value, i) }}
                >
                  {risk1List && risk1List.map(elem => (
                    <SelectItem key={elem} eventKey={elem} >
                      {elem}
                    </SelectItem>
                  ))
                  }
                </Select >

              </div>
              <div className="audit-process-content-risk2">
                <Select
                  vertical={riskState?.management_assertion_list?.length === i + 1 && i > 5 ? "up" : "down"}
                  width={100}
                  classes={"audit-process-content-risk2-select"}
                  selectFirstItem={false}
                  selectedEventKey={elem.control_risk}
                  textAlign='right'
                  align='left'
                  placeholder="미지정"
                  border={false}
                  onClick={(value) => { handleChangeRisk2(value, i) }}
                >
                  {risk2List && risk2List.map(elem => (
                    <SelectItem key={elem} eventKey={elem} >
                      {elem}
                    </SelectItem>
                  ))
                  }
                </Select >
              </div>
              <div className="audit-process-content-risk3">
                {elem.combination_risk}
              </div>
              <div className="audit-process-content-risk4">
                {elem.significant_risk}
              </div>
              <div className="audit-process-content-risk5">
                {elem.fraud_risk}
              </div>
            </div>
          )
        })

        }
      </div>

    </Popup2>
  );
};

export default Step4EditPopup;
