import React from 'react';
import classNames from 'classnames';

import { CommonProps } from 'src/components/helpers/props';

import './index.scss';

interface ButtonGroupProps extends CommonProps {
  spacing?: boolean | 4 | 10;
}

const ButtonGroup: React.FC<ButtonGroupProps> = ({ spacing: spacingProp, classes, children }) => {
  const spacing = typeof spacingProp === 'boolean' ? 10 : spacingProp;
  return (
    <div
      className={classNames(
        `button-group-root`,
        {
          'button-group-spacing': spacing,
          'button-group-spacing-4': spacing === 4,
          'button-group-spacing-10': spacing === 10,
        },
        classes
      )}
    >
      {children}
    </div>
  );
};

export default ButtonGroup;
