import React from 'react';
import classNames from 'classnames';

import { CommonProps } from 'src/components/helpers/props';

import './AbstractIcon.scss';

interface RemoveIconProps extends CommonProps {
  disabled?: boolean;
  size?: 'md';
  fill?: string;
}

const RemoveIcon: React.FC<RemoveIconProps> = ({ classes, disabled = false, size = 'md', fill = '#2e353c', }) => {
  return (
    <svg
      className={classNames('icon-root', classes)}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill={disabled ? '#c4c4c4' : fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="6" y="11" width="12" height="2" />
    </svg>
  );
};

export default RemoveIcon;
