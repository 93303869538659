import React, { useEffect, useRef } from 'react';
import classNames from 'classnames';
import _ from 'lodash';

import { CommonProps } from 'src/components/helpers/props';
import makeChildrenWithProps from 'src/shared/react-utils/makeChildrenWithProps';

import './index.scss';
import { RadioProps } from '../Radio';

export interface RadioGroupProps extends CommonProps {
  name?: string;
  value?: string;
  disabled?: boolean;
  horizontal?: boolean;
  space?: 'xs' | 'sm';
  onChange?: (value: string) => void;
  onChangeEvent?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const RadioGroup: React.FC<RadioGroupProps> = ({
  name,
  value,
  disabled,
  horizontal,
  space = 'sm',
  onChange,
  onChangeEvent,
  children,
  classes,
}) => {
  const refName = useRef(name || `radio-group-name-${_.uniqueId()}`);

  const childProps: RadioProps = {
    name: refName.current,
    selectedValue: value,
    onChange,
    onChangeEvent,
  };
  if (disabled !== undefined) {
    childProps.disabled = disabled;
  }

  const childrenWithProps = makeChildrenWithProps(children, childProps);

  return (
    <div
      className={classNames(
        'radio-group-root',
        {
          'radio-group-horizontal': horizontal,
          'radio-group-horizontal-space-xs': space === 'xs' && horizontal, //기본 sm
          'radio-group-space-xs': space === 'xs' && !horizontal, //기본 sm
        },
        classes
      )}
    >
      {childrenWithProps}
    </div>
  );
};

export default RadioGroup;
