import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import watermarkTemplateApi, {
  WatermarkTemplateDto,
  WatermarkTemplateReqDto,
} from 'src/api/watermarkTemplateApi';
import { toLocalDateTimeStr } from 'src/shared/utils/localDateTime';
import { RootState } from 'src/store';

// # types
export interface WatermarkTemplateEntity extends WatermarkTemplateDto {
  entryTsStr: string;
}
function toEntity(dto: WatermarkTemplateDto): WatermarkTemplateEntity {
  return { ...dto, entryTsStr: toLocalDateTimeStr(dto.entryTs) };
}

function toDto(entity: WatermarkTemplateEntity): WatermarkTemplateDto {
  return { ...entity };
}

interface WatermarkTemplateEntityByCode {
  [code: string]: WatermarkTemplateEntity;
}
export interface WatermarkTemplateEntities {
  byCode: WatermarkTemplateEntityByCode;
  allCodes: string[];
}
interface WatermarkTemplateState {
  initialized: boolean;
  entities: WatermarkTemplateEntities;
}

export interface WatermarkTemplateUpdateReq {
  code: string;
  reqDto: WatermarkTemplateReqDto;
}
export interface WatermarkTemplateUpdateUseReq {
  code: string;
  reqDto: WatermarkTemplateReqDto;
  use: boolean;
}

// # initial state
const initialState: WatermarkTemplateState = {
  initialized: false,
  entities: { byCode: {}, allCodes: [] },
};

// # thunks
export const thunkLoadWatermarkTemplates = createAsyncThunk('watermarkTemplate/load', async () => {
  const list = await watermarkTemplateApi.list();
  return list.map((dto) => toEntity(dto));
});
export const thunkCreateWatermarkTemplate = createAsyncThunk(
  'watermarkTemplate/create',
  async (reqDto: WatermarkTemplateReqDto) => {
    return toEntity(await watermarkTemplateApi.create(reqDto));
  }
);
export const thunkUpdateWatermarkTemplate = createAsyncThunk(
  'watermarkTemplate/update',
  async ({ code, reqDto }: WatermarkTemplateUpdateReq) => {
    return toEntity(await watermarkTemplateApi.update(code, reqDto));
  }
);
export const thunkUpdateWatermarkTemplateUse = createAsyncThunk(
  'watermarkTemplate/update/use',
  async ({ code, reqDto, use }: WatermarkTemplateUpdateUseReq) => {
    const newDto = { ...reqDto, use };
    return toEntity(await watermarkTemplateApi.update(code, newDto));
  }
);
export const thunkRemoveWatermarkTemplate = createAsyncThunk(
  'watermarkTemplate/remove',
  async (code: string) => {
    return watermarkTemplateApi.remove(code);
  }
);

const watermarkTemplateSlice = createSlice({
  name: 'watermarkTemplate',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      thunkLoadWatermarkTemplates.fulfilled,
      (state, { payload: watermarkTemplates }) => {
        state.entities.byCode = watermarkTemplates.reduce((acc, t) => {
          acc[t.code] = t;
          return acc;
        }, {} as WatermarkTemplateEntityByCode);
        state.entities.allCodes = watermarkTemplates.map((t) => t.code);
        state.initialized = true;
      }
    );
    builder.addCase(thunkCreateWatermarkTemplate.fulfilled, (state, { payload: createdEntity }) => {
      state.entities.byCode[createdEntity.code] = createdEntity;
      state.entities.allCodes.push(createdEntity.code);
    });
    builder.addCase(thunkUpdateWatermarkTemplate.fulfilled, (state, { payload: updatedEntity }) => {
      state.entities.byCode[updatedEntity.code] = updatedEntity;
    });
    builder.addCase(
      thunkUpdateWatermarkTemplateUse.fulfilled,
      (state, { payload: updatedEntity }) => {
        state.entities.byCode[updatedEntity.code] = updatedEntity;
      }
    );
    builder.addCase(
      thunkRemoveWatermarkTemplate.fulfilled,
      (state, { meta: { arg: removedCode } }) => {
        delete state.entities.byCode[removedCode];
        state.entities.allCodes = state.entities.allCodes.filter((code) => code !== removedCode);
      }
    );
  },
});

// # selectors
export const selectInitialized = (state: RootState): boolean => {
  return state.watermarkTemplate.initialized;
};
export const selectWatermarkTemplateEntities = (state: RootState): WatermarkTemplateEntities =>
  state.watermarkTemplate.entities;
export const selectWatermarkTemplateEntityList = (state: RootState): WatermarkTemplateEntity[] =>
  state.watermarkTemplate.entities.allCodes.map(
    (code) => state.watermarkTemplate.entities.byCode[code]
  );
export const selectUseWatermarkTemplateEntityList = (state: RootState): WatermarkTemplateEntity[] =>
  state.watermarkTemplate.entities.allCodes
    .filter((code) => state.watermarkTemplate.entities.byCode[code].use === true)
    .map((code) => state.watermarkTemplate.entities.byCode[code]);

export default watermarkTemplateSlice.reducer;
