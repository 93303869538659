import config from 'src/shared/config';
import axios from 'src/shared/utils/axios';
import _ from 'lodash';

import DummyApi from '../dummyApi';
import { times } from '../api';
import patternApi, { PatternDto, PatternDummyApi } from './patternApi';
import { UseYN } from '.';

const INFRA_API_ENABLED = config.servers.infra.enabled;
const INFRA_API_URL = config.servers.infra.url;

export interface PatternGroupDto {
  code: string;
  name: string;
  entryTime: number;

  count: number; // 패턴 수
}

export type PatternGroupReqDto = Omit<PatternGroupDto, 'code' | 'entryTime' | 'count'>;
export type PatternGroupReplaceReqDto = Omit<PatternGroupDto, 'entryTime' | 'count'>;

interface PatternGroupApi {
  list(): Promise<PatternGroupDto[]>;
  create(reqDto: PatternGroupReqDto): Promise<PatternGroupDto>;
  replace(reqDtos: PatternGroupReplaceReqDto[]): Promise<PatternGroupDto[]>;
  importFile(code: string, file: File): Promise<void>;
}

class PatternGroupServerApi implements PatternGroupApi {
  async list(): Promise<PatternGroupDto[]> {
    const resp = await axios.get<PatternGroupDto[]>(`${INFRA_API_URL}/api/pattern-groups`);
    return resp.data;
  }
  async create(reqDto: PatternGroupReqDto): Promise<PatternGroupDto> {
    const resp = await axios.post<PatternGroupDto>(`${INFRA_API_URL}/api/pattern-groups`, reqDto);
    return resp.data;
  }
  async replace(reqDtos: PatternGroupReplaceReqDto[]): Promise<PatternGroupDto[]> {
    const resp = await axios.put<PatternGroupDto[]>(`${INFRA_API_URL}/api/pattern-groups`, reqDtos);
    return resp.data;
  }
  async importFile(code: string, file: File): Promise<void> {
    const formData = new FormData();
    formData.append('importFile', file);

    await axios.post<PatternDto[]>(`${INFRA_API_URL}/api/pattern-groups/${code}/import`, formData);
  }
}

class PatternGroupDummyApi implements PatternGroupApi {
  dummyApi: DummyApi<PatternGroupDto, PatternGroupReqDto>;
  constructor() {
    const data: PatternGroupDto[] = [
      {
        name: `개인정보보호법`,
        count: 4,
      },
      {
        name: `GDPR`,
        count: 1,
      },
      ..._.times(11).map((i) => ({
        name: `패턴 그룹 ${i}`,
        count: 0,
      })),
    ].map((o, i) => ({ ...o, code: '', entryTime: times(i) }));

    this.dummyApi = DummyApi.of({
      entityName: 'Infra/.PatternGroup',
      createDto: (code, entity) => ({ ...entity, code, count: 2, entryTime: Date.now() }),
      validation: (type, code, reqDto, data) => {
        data.forEach((dto) => {
          if (
            (type === 'create' && dto.name === reqDto.name) ||
            (type === 'update' && dto.code !== code && dto.name === reqDto.name)
          ) {
            throw new Error('이미 사용 중인 이름입니다.');
          }
        });
      },
      data,
    });
  }

  list(): Promise<PatternGroupDto[]> {
    return this.dummyApi.list();
  }
  create(reqDto: PatternGroupReqDto): Promise<PatternGroupDto> {
    return this.dummyApi.create(reqDto);
  }
  async replace(replaceReqDtos: PatternGroupReplaceReqDto[]): Promise<PatternGroupDto[]> {
    const prevDtoMap = (await this.dummyApi.list()).reduce((acc, dto) => {
      acc[dto.code] = dto;
      return acc;
    }, {} as { [code: string]: PatternGroupDto });

    const newDtos = replaceReqDtos.map((newDto) => {
      const prevDto = prevDtoMap[newDto.code];
      if (prevDto && prevDto.name === newDto.name) {
        return { ...newDto, count: prevDto.count, entryTime: prevDto.entryTime };
      } else {
        return { ...newDto, count: 0, entryTime: Date.now() };
      }
    });
    return this.dummyApi.replace(newDtos);
  }
  async importFile(code: string, file: File): Promise<void> {
    // TODO 구현이 애매해서 뺌...
    // if (patternApi instanceof PatternDummyApi) {
    //   await patternApi.removeByGroupCode(code);
    // }
    // const list: PatternDto[] = [];
    // list.push(
    //   await patternApi.create({
    //     name: '가져온 주민등록번호',
    //     description: '가져온 주민등록번호',
    //     useYN: UseYN.Y,
    //     color: '#25C16F',
    //     moduleCode: 'code-0',
    //     exceptionUseYN: UseYN.N,
    //     maskingUseYN: UseYN.N,
    //     maskingStart1: 0,
    //     maskingLength1: 0,
    //     maskingStart2: 0,
    //     maskingLength2: 0,
    //     maskingStart3: 0,
    //     maskingLength3: 0,
    //     groupCode: code,
    //     uid: '',
    //     regexpCount: 0,
    //     exceptRegexpCount: 0,
    //     regexps: [],
    //     exceptRegexps: [],
    //   })
    // );
    // list.push(
    //   await patternApi.create({
    //     name: '가져온 카드번호',
    //     description: '가져온 카드번호',
    //     useYN: UseYN.Y,
    //     color: '#25C16F',
    //     moduleCode: null,
    //     exceptionUseYN: UseYN.N,
    //     maskingUseYN: UseYN.N,
    //     maskingStart1: 0,
    //     maskingLength1: 0,
    //     maskingStart2: 0,
    //     maskingLength2: 0,
    //     maskingStart3: 0,
    //     maskingLength3: 0,
    //     groupCode: code,
    //     uid: '',
    //     regexpCount: 0,
    //     exceptRegexpCount: 0,
    //     regexps: [],
    //     exceptRegexps: [],
    //   })
    // );
    // return list;
  }
}

const patternGroupApi: PatternGroupApi = INFRA_API_ENABLED
  ? new PatternGroupServerApi()
  : new PatternGroupDummyApi();

export default patternGroupApi;
