import React, { useState, useEffect } from 'react';
import { Link as RouterLink, useHistory } from 'react-router-dom';

import Button from 'src/components/atom/Button';
import AddCircleLineIcon from 'src/components/atom/icons/AddCircleLineIcon';
import Table from 'src/components/atom/Table';
import Grid, { GridItem } from 'src/components/atom/Grid';
import './index.scss';
import SettingButton2, { DropdownItem } from 'src/components/atom/SettingButton2';
import InfoOutlineIcon from 'src/components/atom/icons/InfoOutline';
import EditIcon from 'src/components/atom/icons/EditIcon';
import DeleteLineIcon from 'src/components/atom/icons/DeleteLineIcon';
import { useTranslation } from 'react-i18next';
import { useToast } from 'src/contexts/ToastContext';
import { useAlert } from 'src/contexts/AlertContext';
import SearchBar from 'src/components/molecules/SearchBar';
import useSearchBar from 'src/hooks/useSearchBar';
import Paging from 'src/components/molecules/Paging';
import moment from 'moment';
import { dateToStringByFormat } from 'src/shared/utils/localDateTime';
import useAuthStore from 'src/hooks/auth/useAuthStore';

import userSettingApi, { UserInfoEntity, MemberDto } from 'src/api/userSettingApi';
import ResetIcon from 'src/components/atom/icons/ResetIcon';
import AddUserPopup from './AddUserPopup';
import EditUserPopup from './EditUserPopup';
import usePopup from 'src/hooks/usePopup';
import IconButton from 'src/components/atom/IconButton';

const INIT_ROW_COUNT = 10;
const UserSettingList: React.FC = () => {
  const history = useHistory();
  const { alert, confirmWithWarn, alert2 } = useAlert();
  const toast = useToast();
  // const chkboxes = useCheckboxGroup<string>([]);
  const adduserPopup = usePopup();
  const edituserPopup = usePopup();
  const { t } = useTranslation(['commons']);
  const [dtoList, setDtoList] = useState<MemberDto[]>([]);
  const { filteredEntityList: filteredDtoList, handleChangeSearchText } = useSearchBar({
    entityList: dtoList,
    keys: ['account', 'nickname', 'email'],
  });
  const [reload, setReload] = useState<boolean>(false);
  const [rowCount, setRowCount] = useState<number>(INIT_ROW_COUNT);
  const [offset, setOffset] = useState<number>(1);
  const [selectedUserAccount, setSelectedUserAccount] = useState("")
  const onClickPageNumber = (num: number): void => {
    setOffset(num);
  };
  const onClickRowCount = (num: string): void => {
    setRowCount(parseInt(num));
    setOffset(1);
  };

  const handleClickAdd = (): void => {
    // history.push('/admin/add');
    adduserPopup.openPopup();
  };
  const handleClickShow = (id: string): void => {
    // history.push(`/admin/${id}/show`);
  };
  const handleClickEdit = (id: string): void => {
    // history.push(`/admin/${id}/edit`);
  };
  const handleClickDelete = (id: string): void => {
    // confirm(t('commons:message.delete.confirm'), (result) => {
    //   if (result) {
    //     userSettingApi
    //       .remove(id)
    //       .then(() => {
    //         toast.info(t('commons:message.delete.success'));
    //         return userSettingApi.list();
    //       })
    //       .then((resp) => setDtoList(resp))
    //       .catch((err) => alert(err));
    //   }
    // });
  };
  const handleClickPasswordReset = (id: string): void => {
    // confirm(`[${id}] 비밀번호를 초기화 하시겠습니까?`, (result) => {
    //   if (result) {
    //     // const resetPw: string = id + dateToStringByFormat(moment().toDate(), 'YYYYMMDD');
    //     userSettingApi
    //       .passwordReset(id)
    //       .then(() => {
    //         toast.info(`비밀번호 초기화에 성공했습니다.[ID:${id}]`); //, Password:${resetPw}]`);
    //         return userSettingApi.list();
    //       })
    //       .then((resp) => setDtoList(resp))
    //       .catch((err) => alert(err));
    //   }
    // });
  };

  // const handleClickDeleteChecked = (): void => {
  //   // TODO
  // };

  useEffect(() => {
    console.log("tttt :")
    userSettingApi
      .list()
      .then((resp) =>
        setDtoList(resp)
      )
      .catch((err) => alert(err));
  }, [alert]);

  useEffect(() => {
    if (reload) {
      userSettingApi
        .list()
        .then((resp) => {
          setDtoList(resp);
          setReload(false);
        })
        .catch((err) => alert(err));
    }

  }, [reload]);


  const { authEntity } = useAuthStore();
  const isAdmin = authEntity && authEntity.userInfo.role.authority_name === "Admin" ? true : false;

  if (!isAdmin) {
    history.push('/errors/not-found');
  }

  const clickEditUserInfo = (account: string) => {
    setSelectedUserAccount(account);
    edituserPopup.openPopup();
  }

  const clickDeleteUserInfo = (account: string) => {
    const deleteUser = () => {
      userSettingApi.removeUser(account)
        .then(resp => {
          toast.info(`사용자가 삭제되었습니다.`);
          setReload(true);
        })
        .catch(e => {
          alert2("에러 발생", e)
        })
    }
    confirmWithWarn("사용자 삭제", `사용자(${account})를 정말 삭제하시겠습니까?`,
      deleteUser)
  }


  return (
    <div className="user-list-root common-page-root">
      <div className="common-page-header">
        <Grid spacing={0}>
          <GridItem classes="common-page-grid-left" xs={6}>
            <div className="common-page-header-title">사용자 설정</div>
          </GridItem>
          <GridItem classes="common-page-grid-right" xs={6}>
            {/* {chkboxes.selected.length > 0 ? (
              <Button
                color="secondary"
                severity="error"
                startIcon={<DeleteLineIcon />}
                text="삭제"
                onClick={handleClickDeleteChecked}
              />
            ) : ( */}
            <React.Fragment>
              <SearchBar
                placeholder="검색어를 입력하세요."
                onChange={(e) => { onClickPageNumber(1); handleChangeSearchText(e.currentTarget.value) }}
              ></SearchBar>
              <Button
                startIcon={<AddCircleLineIcon />}
                text="사용자 추가"
                //disabled
                onClick={handleClickAdd}
              />
            </React.Fragment>
            {/* )} */}
          </GridItem>
        </Grid>
      </div>
      <Table headerBackgroundColor="gray">
        <colgroup>
          <col style={{ width: '25%' }} />
          <col style={{ width: '25%' }} />
          <col style={{ width: '25%' }} />
          <col style={{ width: '20%' }} />
          <col style={{ width: '5%' }} />
        </colgroup>
        <thead>
          <tr>
            <th>아이디</th>
            <th>이름</th>
            <th>이메일</th>
            <th>권한</th>
            <th className="col-settings"></th>
          </tr>
        </thead>
        <tbody>
          {filteredDtoList.slice((offset - 1) * rowCount, offset * rowCount).map((entity) => (
            <tr
              key={entity.account}
            // className={classNames({ checked: chkboxes.isSelected(entity.code) })}
            >
              <td>{entity.account}</td>
              <td>{entity.nickname}</td>
              <td>{entity.email}</td>
              <td>
                {entity.role.authority_name}
              </td>
              <td className="col-settings">
                <SettingButton2>
                  {/* <DropdownItem onClick={() => { }}>
                    <InfoOutlineIcon size="sm" />
                    {'상세 보기'}
                  </DropdownItem> */}
                  <DropdownItem onClick={() => clickEditUserInfo(entity.account)}>
                    <EditIcon size="sm" />
                    {'수정'}
                  </DropdownItem>
                  {/* <DropdownItem onClick={() => { }}>
                    <ResetIcon size="sm" />
                    {'비밀번호 초기화'}
                  </DropdownItem> */}
                  <DropdownItem severity="error" onClick={() => clickDeleteUserInfo(entity.account)}>
                    <DeleteLineIcon size="sm" />
                    {'삭제'}
                  </DropdownItem>

                </SettingButton2>
                {/* <div style={{ display: "flex", flexDirection: "row" }}>
                  <IconButton onClick={() => clickEditUserInfo(entity.account)}>
                    <EditIcon size="sm"></EditIcon>
                  </IconButton>
                  <div style={{ marginLeft: 10 }}>
                    <IconButton onClick={() => clickEditUserInfo(entity.account)}>
                      <TrashIcon></TrashIcon>
                    </IconButton>
                  </div>
                </div> */}

              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Paging
        offset={offset}
        totalCount={filteredDtoList.length}
        rowCount={rowCount}
        rowNumbers={['5', '10', '20', '40', '60', '80']}
        onClickPageNumber={onClickPageNumber}
        onClickRowCount={onClickRowCount}
      />
      {
        adduserPopup.opened &&
        <AddUserPopup
          open={adduserPopup.opened}
          onClose={() => { adduserPopup.closePopup(); setReload(true); }}
        />
      }
      {
        edituserPopup.opened &&
        <EditUserPopup
          userAccount={selectedUserAccount}
          open={edituserPopup.opened}
          onClose={() => { edituserPopup.closePopup(); setReload(true); }}
        />
      }
    </div >
  );
};

export default UserSettingList;
