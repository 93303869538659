import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import RadioGroup from 'src/components/atom/RadioGroup';
import Radio from 'src/components/atom/Radio';
import DotIcon from 'src/components/atom/icons/DotIcon';
import ReactQuill, { Quill } from 'react-quill';
import Button from 'src/components/atom/Button';
import './Step4GMTOC.scss';

const Step4GMTOC: React.FC<{
  detailTaskName: string,
  editMode: boolean,
  gmOrToc: string,
  detailSubList: any,
  description: string,
  auditConclusion: string,
  auditResult: string,
  handleChange: (html: string, type: string) => void;
  handleCancelRequirement: () => void;
  handleConfirmRequirement: () => void;
  handleConfirmDetail?: (opinions: any) => void;
}> = ({
  detailTaskName,
  editMode,
  detailSubList,
  gmOrToc,
  description,
  auditConclusion,
  auditResult,
  handleChange,
  handleCancelRequirement,
  handleConfirmRequirement,
  handleConfirmDetail = () => { }
}) => {
    // console.log("detailSubList :", detailSubList)

    return (
      <div className="sp-ma-step4gmtoc-detail-body">
        <div className="sp-ma-step4gmtoc-detail-body-title element">
          <div className="title" style={{ paddingLeft: 10 }}><DotIcon classes="dot-icon" color='#43425d' />절차설명</div>
        </div>
        <div className="sp-ma-step4gmtoc-detail-body-requirements">
          <div className={classNames("requirements-editor")}>
            <ReactQuill
              modules={{
                toolbar: [
                  [
                    { list: "ordered" },
                    { list: "bullet" },
                    { indent: "-1" },
                    { indent: "+1" },
                  ],

                ]
              }}
              readOnly={!editMode}
              value={description || ''}
              style={{ fontSize: 50 }}
              onChange={(html) => handleChange(html, "description")}
            />
          </div>
        </div>
        <div className="sp-ma-step4gmtoc-detail-body-title element">
          <div className="title" style={{ paddingLeft: 10 }}><DotIcon classes="dot-icon" color='#43425d' />{gmOrToc !== "TOC" ? "감사결론" : "통제테스트 결론"}</div>
        </div>
        <div className="sp-ma-step4gmtoc-detail-body-requirements">
          {gmOrToc !== "TOC" ? <div className={classNames("requirements-editor")}>
            <ReactQuill
              modules={{
                toolbar: [
                  [
                    { list: "ordered" },
                    { list: "bullet" },
                    { indent: "-1" },
                    { indent: "+1" },
                  ],

                ]
              }}
              readOnly={!editMode}
              value={auditConclusion || ''}
              style={{ fontSize: 50 }}
              onChange={(html) => handleChange(html, "auditConclusion")}
            />
          </div>
            :
            <div className={classNames("audit-conclusion")}>
              <RadioGroup
                name="reportStandard"
                disabled={!editMode}
                value={auditConclusion || ""}
                onChange={(value) => handleChange(value, "auditConclusion")}
              >
                <Radio classes='radio-css' label="효과적" value={'효과적'} />
                <Radio classes='radio-css' label="비효과적" value={'비효과적'} />
              </RadioGroup>
            </div>
          }
        </div>
        {
          editMode && <div className="sp-ma-step4gmtoc-detail-footer common-page-footer project-footer">
            <div className={classNames('right-button')}>
              <Button classes="footer-button" text="취소" color="secondary" onClick={handleCancelRequirement} />
              <Button classes="footer-button" text="확인" onClick={handleConfirmRequirement} />
            </div>
          </div>
        }
      </div >)
  }

export default Step4GMTOC;