import { useState, useCallback, ChangeEvent } from 'react'

const useInputs = <T>(initialForm: T)
  : [T, (e?: React.ChangeEvent<HTMLInputElement>) => void, (name: string, value: any) => void, () => void] => {
  const [form, setForm] = useState<T>(initialForm)

  const onChange = useCallback(e => {
    const { name, value } = e.target;
    setForm(form => ({ ...form, [name]: value }));
  }, []);

  const onChangeElem = useCallback((name, value) => {
    setForm(form => ({ ...form, [name]: value }));
  }, [])

  const reset = useCallback(() => setForm(initialForm), [initialForm]);
  return [form, onChange, onChangeElem, reset];
}

export default useInputs;