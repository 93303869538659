import React, {useEffect, useState} from 'react';
import Button from 'src/components/atom/Button';
import Table from 'src/components/atom/Table';
import CardList from 'src/components/atom/CardList';
import SelectStatus, { SelectStatusItem } from 'src/components/atom/SelectStatus';
import TemplateIcon from 'src/components/atom/icons/TemplateIcon';
import InitializeIcon from 'src/components/atom/icons/InitializeIcon';
import EditIcon from 'src/components/atom/icons/EditIcon';
import DeleteIcon from 'src/components/atom/icons/DeleteIcon';
import CardPaper, { CardPaperHeader, CardPaperBody } from 'src/layout/CardPaper';
import NameTagGroup, { NameTag } from 'src/components/atom/NameTagGroup';
import config from 'src/shared/config';
import SortIcon from 'src/components/atom/icons/SortIcon';
import Select, { SelectItem } from 'src/components/atom/Select';
import SelectToggle, { SelectToggleItem } from 'src/components/atom/SelectToggle';
import SearchBar from 'src/components/molecules/SearchBar';
import { useToast } from 'src/contexts/ToastContext';
import { useAlert } from 'src/contexts/AlertContext';
import useCardOrder from 'src/hooks/useCardOrder';
import useSearchBar from 'src/hooks/useSearchBar';
import Tabs from 'src/components/atom/Tabs';
import DropdownCheckbox from 'src/components/atom/DropdownCheckbox';
import Checkbox from 'src/components/atom/Checkbox';
import ButtonGroup from 'src/components/atom/ButtonGroup';
import useCheckboxGroup from 'src/hooks/useCheckboxGroup';


interface SelectGroupProps {
  items: string[];
  selected?: string;
  vertical?: 'up' | 'down';
  placeholder?: string;
  selectFirstItem?: boolean;
  onClick?: (eventKey: string) => void;
}

const SelectGroup: React.FC<SelectGroupProps> = ({items, selected, vertical, placeholder, selectFirstItem, onClick}) => {

  return (
  <Select vertical={vertical}
    autoWidth={true}
    selectedEventKey={selected}
    placeholder={placeholder}
    selectFirstItem={selectFirstItem}
    onClick={onClick}>
    {items.map((item, i) => (
      <SelectItem key={'select-item-'+item} eventKey={item}>{item}</SelectItem>
    ))}
  </Select>
  )
}


export default SelectGroup;
