import { useState } from 'react';

export default function useCheckboxGroup<T>(
  initialSelected: T[] = [],
  equals: (o1: T, o2: T) => boolean = (o1: T, o2: T) => o1 === o2
): {
  selected: T[];
  handleClick: (id: T) => void;
  handleAllClick: (allIds: T[]) => void;
  isSelected: (id: T) => boolean;
  initSelected: (initIds: T[]) => void;
} {
  const [selected, setSelected] = useState(initialSelected);

  const handleAllClick = (allIds: T[]): void => {
    setSelected(allIds);
  };
  const handleClick = (id: T): void => {
    // const selectedIndex = selected.indexOf(id);
    const selectedIndex = selected.findIndex((o) => equals(o, id));
    let newSelected: T[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };
  const isSelected = (id: T): boolean => selected.findIndex((o) => equals(o, id)) >= 0;

  const initSelected = (initIds: T[]): void => {
    setSelected(initIds);
  };

  return { selected, handleClick, handleAllClick, isSelected, initSelected };
}
