import React, { useEffect, useState, useContext, Fragment } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { CommonProps } from 'src/components/helpers/props';
import Popup2 from 'src/components/molecules/Popup2';
import projectApi, { ProjectDto, emptyProject } from 'src/api/projectApi';
import Button from 'src/components/atom/Button';
import TextField from 'src/components/atom/TextField';
import './DashboardPopup.scss';
import AddCircle2Icon from 'src/components/atom/icons/AddCircle2Icon';
import TrashIcon from 'src/components/atom/icons/TrashIcon';
import IconButton from 'src/components/atom/IconButton';
import { useAlert } from 'src/contexts/AlertContext';
import { validateAll } from 'src/shared/utils/validations';
import _, { omit } from 'lodash';



interface DashboardPopupProps extends CommonProps {
  open: boolean;
  type: string;
  projectId: string;
  onConfirm: (opinions: any) => void;
  onClose: () => void;
}


const DashboardPopup: React.FC<DashboardPopupProps> = ({
  open,
  type,
  projectId,
  onClose,
  onConfirm,
  classes,
}) => {
  const { t } = useTranslation(['commons']);
  const { alert2 } = useAlert();
  const [findings, setFindings] = useState<any>([]);
  const validationRules = {
    // id: _.trim(project.id).length > 0 && validationId(project.id),
    // name: _.trim(form.name).length > 0,
    // projectRole: selectedUsers.length > 0,
    name: findings?.every((item: any) => item?.content?.length > 0)
  };


  const handleOk = (): void => {
    if (!validateAll(validationRules)) {
      alert2(`${type}`, "내용은은 필수입니다.");
      return;
    }
    const d = type === "중요한 취약점" ? { "main_weakness_list": findings } : { "incomplete_point_list": findings };
    projectApi.setProjectDashboardReport(projectId, d)
      .then(resp => {
        onClose();
      })
      .catch(err => {
        alert2("에러 발생", err)
      })

  };

  useEffect(() => {
    projectApi.getProjectDashboardReport(projectId, type)
      .then(resp => {
        setFindings(resp);

      })
      .catch(err => {
        alert2("에러 발생", err)
      })
  }, []);


  const handleDetailAdd = () => {
    let newFindings = [...findings, { content: "" }];
    setFindings(newFindings)
  }

  const handleDeleteAccount = (i: number) => {
    let newFindings = [...findings];
    newFindings.splice(i, 1)
    setFindings(newFindings)
  }

  const handleAccountNameChange = (e: React.ChangeEvent<HTMLInputElement>, i: number) => {
    e.stopPropagation();
    let newFindings = [...findings];
    newFindings[i].content = e.target.value;
    setFindings(newFindings)
  }


  return (
    <Popup2
      classes="audit-popup-root project-popup-root project-common"
      title={type}
      width="1112px"
      height="692px"
      open={open}
      onClose={onClose}
      footer={
        <React.Fragment>
          <div className="detail-add">
            <div
              onClick={handleDetailAdd}
            >
              <AddCircle2Icon color="#295c9f" />
              <span>{`${type} 추가`}</span>
            </div>
          </div>
          <Button text={t('commons:cancel')} onClick={onClose} color="secondary" />
          <Button
            text={'확인'}
            onClick={handleOk}
          // disabled={!selectedUsers} 
          />
        </React.Fragment>
      }
    >
      <div className="task-header element">
        <div style={{ paddingLeft: 15, width: "90%", color: "#a3a6b4" }}>{type}</div>
      </div>
      <div className="dashboard-process-content-root">
        {findings?.map((elem: any, i: number) => {
          return (
            <div className="dashboard-process-content" key={i.toString()}>
              <div className="dashboard-process-content-type-root">
                <TextField classes="dashboard-process-content-type"
                  value={elem.content || ""}
                  autoFocus={!elem?.content ? true : false}
                  placeholder="내용을 입력하세요."
                  onChange={(e) => { handleAccountNameChange(e, i) }}
                />
              </div>
              <div className="dashboard-process-content-type-delete">
                <IconButton onClick={() => handleDeleteAccount(i)}>
                  <TrashIcon></TrashIcon>
                </IconButton>

              </div>
            </div>
          )
        })}
      </div>
    </Popup2>
  );
};

export default DashboardPopup;
