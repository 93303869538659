import config from 'src/shared/config';
import axios from 'src/shared/utils/axios';
import _ from 'lodash';

import { TokenErrorHandling } from 'src/shared/errorHandling';
import { request } from 'http';

import { getToken } from 'src/shared/utils/sessionStorageManager';

// const M_API_URL = config.servers.manager.url;

export interface NewClientDto {
  name: string;
}
export interface TemplateDto {
  name: string;
  _id?: string;
  source_project_name: string;
  category_list: CategoryDto[];
}

export interface CategoryDto { //strategy, procedure, conclusion
  name: string;
  task_list: TaskDto[];
}

export interface TaskDto {
  name: string;
  new_name?: string;
  detail_list: DetailDto[];
  task_type?: string;
  sub_detail_list?: DetailDto[];
  _id?: string,
}

export interface RequestTaskDto {
  name: string;
  _id?: string,
  new_name?: string;
  preparer_list: MemberDto[];
  reviewer_list: ReviewMemberDto[];
  review_comment?: string;
  prepare_comment?: string;
}

export interface DetailDto {
  _id?: string,
  detail_sub_title_list?: any;
  detail_stage_name?: any;
  name: string;
  requirements: string;
  responsibility: {
    nickname: string;
    account: string;
  },
  status: string;
  due_date: string;
  is_default?: boolean;
  audit_list: AuditDto[];
  is_favorite?: boolean;
}

export interface SpecialDetailDto {
  name: string;
  requirements: string;
  responsibility: {
    nickname: string;
    account: string;
  },
  status: string;
  due_date: string;
  audit_list: AuditDto[];
  is_default?: boolean;
  detail_stage_name?: string;
  detail_sub_title_list?: any[];
  detail_sub_content_list?: any[];
  sub_task_type?: string;
  description?: string;
  audit_conclusion?: string;
  supplement?: string;
  management_assertion_list?: string[];
}

export interface AuditDto {
  file_name: string;
  type: string;
  uploader: MemberDto;
  preparer_list: MemberDto[];
  reviewer_list: ReviewMemberDto[];
  prepare_status: string;
  review_status: string;
  status?: string;
  comment?: string;
  management_assertions_string?: string;
  sample_count?: string;
  uri?: {
    file_name: string;
    path: string;
    relative_path: string;
  },
  history?: [{
    at: string;
    by: MemberDto;
    cc: ReviewMemberDto[];
    comment: string;
    exec_command: string;
  }];
  file_contents?: string;
}

export interface AuditDto2 {
  file_name: string;
  informations?: [{
    type: string;
    uploader: MemberDto;
    project_name: string;
    category_name: string;
    task_name: string;
    detail_name: string;
    at: string;
  }];

  uri?: {
    file_name: string;
    path?: string;
    relative_path: string;
  },
}

export interface ReviewMemberDto {
  nickname: string;
  account: string;
  review_stage: string;
}

export interface MemberDto {
  nickname: string;
  account: string;

}
// export interface ClientFormDto {
//   name: string;
//   engagementCode: string;
//   clientCode: string;
//   engagementPartner: string[];
//   taskType: string;
// }

export function createTemplate(name: string, category: string, task?: string): TemplateDto {
  return {
    name: name || '',
    source_project_name: '',
    category_list: [
      {
        name: category || '',
        task_list: [
          createTask(task)
        ]
      }
    ]
  };
}

export function createRequestDto(task: TaskDto): RequestTaskDto {
  return {
    name: task.name || '',
    _id: task._id || '',
    preparer_list: [],
    reviewer_list: [],
  }
}

export function createTask(name?: string): TaskDto {
  return {
    name: name || '',
    detail_list: [],
  }
}

export function createDetail(name?: string): DetailDto {
  return {
    name: name || '',
    requirements: '',
    responsibility: {
      nickname: '',
      account: '',
    },
    status: '',
    due_date: '',
    audit_list: []
  }
}

export function createAudit(name?: string): AuditDto {
  return {
    file_name: name || '',
    type: '',
    uploader: createMember(),
    preparer_list: [],
    reviewer_list: [],
    prepare_status: '',
    review_status: '',
  }
}

function createMember(): MemberDto {
  return {
    account: '',
    nickname: '',
  }
}


interface ResponseDto {
  data: {
    template_list: TemplateDto[];
  },
  err?: string;
}

interface ResponseDto2 {
  data: {
    template: TemplateDto;
  }
  err?: string;
}

interface ResponseDto3 {
  data: {
    audit_template_list: TemplateDto[];
    service_template_list: TemplateDto[];
  },
  err?: string;
}

interface FilePath {
  uri_list: [
    {
      file_name: string;
      path: string;
      relative_path: string;
    }
  ]
}

interface ResponseDtoA<D> {
  data: D;
  err?: string;
}

class clientServerApi {
  async hasAudit(requestData: any): Promise<any> {
    const data = {
      data: {
        access_token: getToken(),
        template: requestData
      }
    }
    try {
      const resp = await axios.post<ResponseDtoA<{ has_audit?: boolean }>>(`/api/private/HasAudit`, data);

      if (resp.data !== null) {
        // console.log('resp.data', resp.data);
        // const data = resp.data.data;
        return Promise.resolve(_.cloneDeep(resp.data.data));
      }
    } catch (e) {
      console.log('resp error : ', e);
      return Promise.reject(_.cloneDeep(e));
    }

  }

  async hasAuditProject(requestData: any): Promise<any> {
    const data = {
      data: {
        access_token: getToken(),
        project_id: requestData
      }
    }
    try {
      const resp = await axios.post<ResponseDtoA<{ has_audit?: boolean }>>(`/api/private/HasAudit`, data);

      if (resp.data !== null) {
        // console.log('resp.data', resp.data);
        // const data = resp.data.data;
        return Promise.resolve(_.cloneDeep(resp.data.data));
      }
    } catch (e) {
      console.log('resp error : ', e);
      return Promise.reject(_.cloneDeep(e));
    }

  }


  async requestReviewByTask(requestData: any) {
    const data = {
      data: {
        access_token: getToken(),
        template: requestData
      }
    }
    console.log("data : ", data)
    try {
      const resp = await axios.post<ResponseDto>(`/api/private/RequestReviewByTask`, data);

      if (resp.data !== null) {
        // console.log('resp.data', resp.data);
        // const data = resp.data.data;
        return Promise.resolve(_.cloneDeep(resp.data.data));
      }
    } catch (e) {
      console.log(e);
      return Promise.reject(_.cloneDeep(e));
    }

  }

  async requestReviewByGMTOC(requestData: any) {
    const data = {
      data: {
        access_token: getToken(),
        template: requestData
      }
    }
    console.log("data : ", data)
    try {
      const resp = await axios.post<ResponseDto>(`/api/private/RequestReviewByGMTOC`, data);

      if (resp.data !== null) {
        // console.log('resp.data', resp.data);
        // const data = resp.data.data;
        return Promise.resolve(_.cloneDeep(resp.data.data));
      }
    } catch (e) {
      console.log(e);
      return Promise.reject(_.cloneDeep(e));
    }

  }

  async getTemplateList(type: string): Promise<TemplateDto[]> {
    const data = {
      data: {
        access_token: getToken(),
        keyword: '',
        skip: 0,
        limit: 0,
      }
    }

    const resp = await axios.post<ResponseDto3>(`/api/private/GetTemplateList`, data);

    if (resp.data !== null) {
      console.log('resp.data', resp.data);
      // const data = resp.data.data;
      if (type && type === "service") {
        return Promise.resolve(_.cloneDeep(resp.data.data.service_template_list || []));
      } else {
        return Promise.resolve(_.cloneDeep(resp.data.data.audit_template_list));
      }

    }

    return [] as TemplateDto[];
  }

  async getTemplate(name: string): Promise<TemplateDto> {
    const data = {
      data: {
        access_token: getToken(),
        template: {
          name
        }
      }
    }

    const resp = await axios.post<ResponseDto2>(`/api/private/GetTemplate`, data);

    if (resp.data !== null) {
      // console.log('getTemplate resp.data', resp.data);
      return Promise.resolve(_.cloneDeep(resp.data.data.template));
    }

    return {} as TemplateDto;
  }

  async getTemplatebyDTO(template: TemplateDto): Promise<TemplateDto> {
    const data = {
      data: {
        access_token: getToken(),
        template
      }
    }

    const resp = await axios.post<ResponseDto2>(`/api/private/GetTemplate`, data);

    if (resp.data !== null) {
      // console.log('getTemplate resp.data', resp.data);
      return Promise.resolve(_.cloneDeep(resp.data.data.template));
    }

    return {} as TemplateDto;
  }

  async addTask(template: TemplateDto): Promise<void> {
    const request = {
      data: {
        access_token: getToken(),
        template
      }
    };

    const resp = await axios.post<ResponseDto>(`/api/private/NewTask`, request);

    // console.log('resp:', resp);
  }

  async deleteTask(template: TemplateDto): Promise<void> {
    const request = {
      data: {
        access_token: getToken(),
        template
      }
    };
    try {
      const resp = await axios.post<ResponseDto>(`/api/private/DeleteTask`, request);
      // console.log('resp:', resp);
      if (resp.data !== null) {
        // console.log('resp.data', resp.data);
        const data = resp.data;
        return Promise.resolve();
      } else {
        // console.log('resp.err', resp);
        return Promise.reject();
      }
    } catch (e) {
      return Promise.reject(e);

    }




  }

  async setMyFavorite(project: { name: string, project_id: string }, template: TemplateDto, is_favorite_add: boolean): Promise<void> {

    const request = {
      data: {
        access_token: getToken(),
        project,
        is_favorite_add,
        template
      }
    };

    const resp = await axios.post<ResponseDto>(`/api/private/SetMyFavorite`, request);

    // console.log('resp:', resp);
  }

  async editDetailList(template: TemplateDto): Promise<void> {

    const request = {
      data: {
        access_token: getToken(),
        template
      }
    };

    const resp = await axios.post<ResponseDto>(`/api/private/UpdateTask`, request);

    // console.log('resp:', resp);
  }

  async editDetail(template: TemplateDto): Promise<void> {

    const request = {
      data: {
        access_token: getToken(),
        template
      }
    };

    const resp = await axios.post<ResponseDto>(`/api/private/UpdateDetail`, request);

    // console.log('resp:', resp);
  }

  async updateDetailTask(template: TemplateDto): Promise<void> {

    const request = {
      data: {
        access_token: getToken(),
        template
      }
    };
    try {
      const resp = await axios.post<ResponseDto>(`/api/private/UpdateDetailTask`, request);
      // console.log('resp:', resp);
      if (resp.data !== null) {
        return Promise.resolve();
      }
      return Promise.reject();
    } catch (e) {
      console.log("e: ", e)
      if ((e as { name: string })?.name?.length > 0) {
        return Promise.reject((e as { name: string })?.name)
      }
      return Promise.reject(e);
    }
  }

  async editDetailSpecial(template: TemplateDto, service: string): Promise<void> {

    const request = {
      data: {
        access_token: getToken(),
        template
      }
    };
    try {
      const resp = await axios.post<ResponseDto>(`/api/private/${service}`, request);
      // console.log('resp:', resp);
      if (resp.data !== null) {
        return Promise.resolve();
      }
      return Promise.reject();
    } catch (e) {
      return Promise.reject(e);
    }
  }

  async prepareUpload(template: TemplateDto): Promise<ResponseDtoA<FilePath>> {
    const request = {
      data: {
        access_token: getToken(),
        template
      }
    };

    try {
      const resp = await axios.post<ResponseDtoA<FilePath>>("/api/private/PrepareFile", request);
      if (resp.data !== null) {
        // console.log('resp.data', resp.data);
        const data = resp.data;
        return Promise.resolve(_.cloneDeep(data));
      } else {
        console.log('resp.err', resp);
      }
    } catch (e) {
      console.log('DeleteAudit error:', e);
      if ((e as { name: string })?.name === "not found") {
        return Promise.reject("이미 삭제된 조서 파일입니다.");
      }
      return Promise.reject(_.cloneDeep(e));
    }

    return {} as ResponseDtoA<FilePath>
  }

  async makeAuditFromText(template: TemplateDto): Promise<ResponseDtoA<FilePath>> {
    const request = {
      data: {
        access_token: getToken(),
        template
      }
    };
    try {
      const resp = await axios.post<ResponseDtoA<FilePath>>("/api/private/MakeAuditFromText", request);

      if (resp.data !== null) {
        // console.log('resp.data', resp.data);
        return Promise.resolve(_.cloneDeep(resp.data));
      } else {
        console.log('resp.err', resp);
      }
    } catch (e) {
      return Promise.reject(e);
    }


    return {} as ResponseDtoA<FilePath>
  }

  async saveFile(path: string, file: File): Promise<void> {
    var formData = new FormData();
    formData.append('file', file);

    const resp = await fetch(path, {
      method: "post",
      body: formData,
      // mode: 'cors',
      // headers: { "Content-Type": "multipart/form-data" }
    })
    console.log('resp:', resp);
  }

  async addAudit(template: TemplateDto): Promise<any> {
    const request = {
      data: {
        access_token: getToken(),
        template
      }
    };
    try {
      const resp = await axios.post<ResponseDto>(`/api/private/AddAudit`, request);
      console.log('resp:', resp.data);
      if (resp.data.err) {
        return Promise.reject(_.cloneDeep(resp.data.err));
      }
      return Promise.resolve(_.cloneDeep(resp.data));
    } catch (e) {
      return Promise.reject(_.cloneDeep(e));
    }

  }

  async deleteAudit(template: TemplateDto): Promise<any> {
    const request = {
      data: {
        access_token: getToken(),
        template
      }
    };
    try {
      const resp = await axios.post<ResponseDto>(`/api/private/DeleteAudit`, request);
      console.log('resp:', resp.data);
      if (resp.data.err) {
        return Promise.reject(_.cloneDeep(resp.data.err));
      }
      return Promise.resolve(_.cloneDeep(resp.data));
    } catch (e) {
      console.log('DeleteAudit error:', e);
      if ((e as { name: string })?.name === "not found") {
        return Promise.reject("이미 삭제된 조서 파일입니다.");
      }
      return Promise.reject(_.cloneDeep(e));
    }

  }


  async handlePrepare(template: TemplateDto, isConfirm: boolean): Promise<any> {
    const request = {
      data: {
        access_token: getToken(),
        template
      }
    };

    const service = isConfirm ? 'ConfirmPrepare' : 'CancelPrepare';
    try {
      const resp = await axios.post<ResponseDto>(`/api/private/${service}`, request);
      console.log('resp:', resp);
      if (resp.data.err) {
        return Promise.reject(_.cloneDeep(resp.data.err));
      }
      return Promise.resolve(_.cloneDeep(resp.data));
    } catch (e) {
      console.log(service + ' error:', e);
      if ((e as { name: string })?.name === "not found") {
        return Promise.reject("이미 삭제된 조서 파일입니다.");
      }
      return Promise.reject(_.cloneDeep(e));
    }
  }


  async handleReview(template: TemplateDto, service: string): Promise<any> {
    const request = {
      data: {
        access_token: getToken(),
        template
      }
    };
    console.log("request : ", request)
    try {
      const resp = await axios.post<ResponseDto>(`/api/private/${service}Review`, request);
      console.log('resp:', resp);
      if (resp.data.err) {
        return Promise.reject(_.cloneDeep(resp.data.err));
      }
      return Promise.resolve(_.cloneDeep(resp.data));
    } catch (e) {
      console.log(service + ' error:', e);
      if ((e as { name: string })?.name === "not found") {
        return Promise.reject("이미 삭제된 조서 파일입니다.");
      }
      return Promise.reject(_.cloneDeep(e));
    }
  }

  async handleReviews(templates: TemplateDto[], service: string): Promise<any> {
    let promiseList: any[] = [];
    templates.forEach(async template => {
      const request = {
        data: {
          access_token: getToken(),
          template
        }
      };
      console.log("request : ", request)
      promiseList.push(axios.post<ResponseDto>(`/api/private/${service}Review`, request))
    })

    console.log("promiseList : ", promiseList)
    const respArr = await Promise.all(promiseList);
    console.log("respArr : ", respArr)
    for (let i = 0; i < respArr.length; i++) {
      if (respArr[i].status === 'rejected') {
        return Promise.reject(_.cloneDeep(respArr[i].reason));
      }
    }
    return Promise.resolve(_.cloneDeep(respArr));
  }

  async checkFileExist(template: TemplateDto, fileName: string, type: string): Promise<any> {
    const request = {
      data: {
        access_token: getToken(),
        template
      }
    };
    // console.log("request : ", request)
    try {
      const resp = await axios.post<any>(`/api/private/GetAuditListBySubTask`, request);
      if (resp.data.err) {
        return Promise.reject(_.cloneDeep(resp.data.err));
      } else if (resp.data.data) {
        const arr = (resp.data.data.audit_list || []).filter((item: any) => {
          return item.file_name === fileName && item.type === type
        });
        const arr2 = (resp.data.data.audit_list || []).filter((item: any) => {
          return item.file_name === fileName && item.type !== type
        });
        if (arr.length > 0) {
          return Promise.resolve({ result: "duplicated" });
        } else if (arr2.length > 0) {
          return Promise.resolve({ result: "duplicated but type is different" });
        } else {
          return Promise.resolve({ result: "ok" });
        }
      }
    } catch (e) {
      return Promise.reject(_.cloneDeep(e));
    }
  }

  async checkTaskExist(projectName: string, categoryName: string, taskName: string, subTaskName: string, transType?: string, detailTransType?: string): Promise<any> {
    try {
      const resp = await this.getTemplate(projectName);

      const chk1 = resp.category_list.filter(item => item.name === categoryName);
      if (chk1.length === 0) {
        return Promise.resolve({ ret: false, reason: "CATEGORY_NAME" })
      }
      const chk2 = chk1[0]?.task_list?.filter(item => item.name === taskName) || [];
      if (chk2.length === 0) {
        return Promise.resolve({ ret: false, reason: "TASK_NAME" })
      }
      const chk3 = chk2[0]?.detail_list?.filter(item => item.name === subTaskName) || [];
      if (chk3.length === 0) {
        return Promise.resolve({ ret: false, reason: "SUBTASK_NAME" })
      }
      if (transType) {
        const chk4 = chk3[0]?.detail_sub_title_list?.filter((item: any) => item.name === transType) || [];
        if (chk4.length === 0) {
          return Promise.resolve({ ret: false, reason: "SUBTASK_NAME" })
        }
        if (detailTransType) {
          const chk5 = chk4[0]?.sub_detail_list?.filter((item: any) => item.name === detailTransType) || [];
          if (chk5.length === 0) {
            return Promise.resolve({ ret: false, reason: "SUBTASK_NAME" })
          }
        }
      }

      return Promise.resolve({ ret: true, reason: "Get it!" })
    } catch (e) {
      return Promise.reject(_.cloneDeep(e));
    }
  }
}

const templateApi = new clientServerApi();

export default templateApi;
