import { CommonPolicyAddUserReqDto } from './../../api/infra/commonPolicyApi';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import commonPolicyApi, {
  CommonPolicyDto,
  CommonPolicyReqDto,
} from 'src/api/infra/commonPolicyApi';
import { toLocalDateTimeStr } from 'src/shared/utils/localDateTime';
import { RootState } from 'src/store';

// # types
export type CommonPolicyEntity = Omit<CommonPolicyDto, 'entryTs'> & {
  entryTsStr: string;
};

function toEntity(dto: CommonPolicyDto): CommonPolicyEntity {
  return { ...dto, entryTsStr: toLocalDateTimeStr(dto.entryTime) };
}
function toDto(entity: CommonPolicyEntity): CommonPolicyDto {
  return { ...entity };
}

interface CommonPolicyEntityByCode {
  [code: string]: CommonPolicyEntity;
}
interface CommonPolicyEntities {
  byCode: CommonPolicyEntityByCode;
  allCodes: string[];
}
interface CommonPolicyState {
  initialized: boolean;
  entities: CommonPolicyEntities;
}

export interface CommonPolicyUpdateReq {
  code: string;
  reqDto: CommonPolicyReqDto;
}

export interface CommonPolicyAddUserReq {
  code: string;
  reqDto: CommonPolicyAddUserReqDto;
}

// # initial state
const initialState: CommonPolicyState = {
  initialized: false,
  entities: { byCode: {}, allCodes: [] },
};

// # thunks
export const thunkLoadCommonPolicys = createAsyncThunk('infra/commonPolicy/load', async () => {
  const list = await commonPolicyApi.list();
  return list.map((dto) => toEntity(dto));
});
export const thunkCreateCommonPolicy = createAsyncThunk(
  'infra/commonPolicy/create',
  async (reqDto: CommonPolicyReqDto) => {
    return toEntity(await commonPolicyApi.create(reqDto));
  }
);
export const thunkUpdateCommonPolicy = createAsyncThunk(
  'infra/commonPolicy/update',
  async ({ code, reqDto }: CommonPolicyUpdateReq) => {
    return toEntity(await commonPolicyApi.update(code, reqDto));
  }
);
export const thunkRemoveCommonPolicy = createAsyncThunk(
  'infra/commonPolicy/remove',
  async (code: string) => {
    return commonPolicyApi.remove(code);
  }
);
export const thunkAddUserCommonPolicy = createAsyncThunk(
  'infra/commonPolicy/addUser',
  async ({ code, reqDto }: CommonPolicyAddUserReq) => {
    return toEntity(await commonPolicyApi.addUser(code, reqDto));
  }
);

const commonPolicySlice = createSlice({
  name: 'commonPolicy',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(thunkLoadCommonPolicys.fulfilled, (state, { payload: commonPolicys }) => {
      state.entities.byCode = commonPolicys.reduce((acc, t) => {
        acc[t.code] = t;
        return acc;
      }, {} as CommonPolicyEntityByCode);
      state.entities.allCodes = commonPolicys.map((t) => t.code);
      state.initialized = true;
    });
    builder.addCase(thunkCreateCommonPolicy.fulfilled, (state, { payload: createdEntity }) => {
      state.entities.byCode[createdEntity.code] = createdEntity;
      state.entities.allCodes.push(createdEntity.code);
    });
    builder.addCase(thunkUpdateCommonPolicy.fulfilled, (state, { payload: updatedEntity }) => {
      state.entities.byCode[updatedEntity.code] = updatedEntity;
    });
    builder.addCase(thunkRemoveCommonPolicy.fulfilled, (state, { meta: { arg: removedCode } }) => {
      delete state.entities.byCode[removedCode];
      state.entities.allCodes = state.entities.allCodes.filter((code) => code !== removedCode);
    });
    builder.addCase(thunkAddUserCommonPolicy.fulfilled, (state, { payload: updatedEntity }) => {
      state.entities.byCode[updatedEntity.code] = updatedEntity;
    });
  },
});

// # selectors
export const selectInitialized = (state: RootState): boolean => {
  return state.commonPolicy.initialized;
};
export const selectCommonPolicyEntities = (state: RootState): CommonPolicyEntities =>
  state.commonPolicy.entities;
export const selectCommonPolicyEntityList = (state: RootState): CommonPolicyEntity[] =>
  state.commonPolicy.entities.allCodes.map((code) => state.commonPolicy.entities.byCode[code]);

export default commonPolicySlice.reducer;
