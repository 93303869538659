// import { IntlConfig } from 'react-intl';

// import messages from './messages';

// const i18nConfig: IntlConfig = {
//   defaultLocale: 'en',
//   messages,
// };

// export default i18nConfig;

import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import config from 'src/shared/config';

export const supportLanguages = [
  { key: 'ko', title: 'KR' },
  { key: 'en', title: 'EN' },
];
export const DEFAULT_LANGUAGE = 'en';

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    backend: {
      loadPath: config.url('/locales/{{lng}}/{{ns}}.json'),
    },
    ns: [
      'basicTemplate',
      'commons',
      'commonsMenu',
      'dashboard',
      'detectionRule',
      'detectionTemplate',
      'error',
      'except',
      'fidp',
      'hamburger',
      'log',
      'manage',
      'menu',
      'pagination',
      'pattern',
      'policyFile',
      'policyReview',
      'policyTarget',
      'policyTemplate',
      'printPolicy',
      'setting',
      'stats',
      'watermark',
    ],
    fallbackLng: DEFAULT_LANGUAGE,
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    supportedLngs: supportLanguages.map((key) => key.key),
    react: {
      transSupportBasicHtmlNodes: true,
      transKeepBasicHtmlNodesFor: ['br', 'strong', 'i'],
    },
  });

export default i18n;

export function getLanguage(defaultLang?: string | null): string {
  return i18n.language || defaultLang || DEFAULT_LANGUAGE;
}
