import React, { Suspense, useEffect, useState } from 'react';
import { BrowserRouter, HashRouter, useHistory } from 'react-router-dom';
import { Provider } from 'react-redux';
import qs from 'query-string';
import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import Progress from './components/atom/Progress';
import { AlertProvider } from 'src/contexts/AlertContext';
import { ToastProvider } from 'src/contexts/ToastContext';
// import { ConfigProvider } from 'src/contexts/ConfigContext';
import Layout from './layout/Layout';
import store from './store';

import './App.scss';
import useComponentWillMount from './hooks/useComponentWillMount';
import { useTranslation } from 'react-i18next';
import { getLanguage } from './i18n';
import {
  appendLogoutCallbackEntity,
  getToken,
  setDeviceId,
} from './shared/utils/sessionStorageManager';
import useAuthStore from './hooks/auth/useAuthStore';
import config from './shared/config';
import authRefreshToken from './shared/utils/authRefreshToken';

const AppInside: React.FC = () => {
  const history = useHistory();
  const { i18n } = useTranslation();
  const { isAuthenticated, isProgressSignOut, signInWithToken } = useAuthStore();
  const token = getToken();
  const [tokenInitialized, setTokenInitialized] = useState(isAuthenticated || !token);

  useComponentWillMount(() => {
    // 최초 1번만 실행
    // 이전 앱에서 선택한 언어로 설정
    const { lang: paramLang } = qs.parse(history.location.search);
    const language = getLanguage(Array.isArray(paramLang) ? _.head(paramLang) : paramLang);
    i18n.changeLanguage(language);
    document.getElementById('root')?.setAttribute('lang', language);

    // 기동 시 uuid 하나 만들어 device_id 생성
    setDeviceId(uuidv4());
  });

  // session storage에 token이 있으면 이를 사용해 로그인 처리
  // TODO 로그인 된 상황에도 순간적으로 tokenInitialized && isAuthenticated === false인 상황이 나올 수 있을 듯..
  useEffect(() => {
    if (!tokenInitialized && token) {
      signInWithToken(token)
        .then(() => {
          setTokenInitialized(true);
          authRefreshToken();
        })
        .catch((e) => {
          console.log(e);
          history.push(config.url('/sign-out'));
        });
    }
    if (isProgressSignOut) {
      history.push(config.url('/sign-out'));
    }
  }, [history, isProgressSignOut, signInWithToken, token, tokenInitialized]);

  return tokenInitialized ? (
    <AlertProvider>
      <ToastProvider>
        <Progress />
        <Layout />
      </ToastProvider>
    </AlertProvider>
  ) : null;
};

const App: React.FC = () => {
  return (
    <Suspense fallback="loading">
      <Provider store={store}>
        <BrowserRouter>
          <AppInside />
        </BrowserRouter>
      </Provider>
    </Suspense>
  );
};

export default App;
