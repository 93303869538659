import moment from 'moment';

import config from 'src/shared/config';
import axios from 'src/shared/utils/axios';

import DummyApi from '../dummyApi';
import { times } from '../api';

import { Registration, UseYN } from '.';

const INFRA_API_ENABLED = config.servers.infra.enabled;
const INFRA_API_URL = config.servers.infra.url;

export interface ProgramDto {
  code: string;
  process: string;
  description: string;
  registration: Registration;
  useYN: UseYN;
  entryTime: number;
}

export type ProgramReqDto = Omit<ProgramDto, 'code' | 'entryTime'>;

interface ProgramApi {
  list(): Promise<ProgramDto[]>;
  find(code: string): Promise<ProgramDto>;
  create(reqDto: ProgramReqDto): Promise<ProgramDto>;
  update(code: string, reqDto: ProgramReqDto): Promise<ProgramDto>;
  remove(code: string): Promise<void>;
}

class ProgramServerApi implements ProgramApi {
  async list(): Promise<ProgramDto[]> {
    const resp = await axios.get<ProgramDto[]>(`${INFRA_API_URL}/api/programs`);
    return resp.data;
  }
  async find(code: string): Promise<ProgramDto> {
    const resp = await axios.get<ProgramDto>(`${INFRA_API_URL}/api/programs/${code}`);
    return resp.data;
  }
  async create(reqDto: ProgramReqDto): Promise<ProgramDto> {
    const resp = await axios.post<ProgramDto>(`${INFRA_API_URL}/api/programs`, reqDto);
    return resp.data;
  }
  async update(code: string, reqDto: ProgramReqDto): Promise<ProgramDto> {
    const resp = await axios.put<ProgramDto>(`${INFRA_API_URL}/api/programs/${code}`, reqDto);
    return resp.data;
  }
  async remove(code: string): Promise<void> {
    await axios.delete<ProgramDto>(`${INFRA_API_URL}/api/programs/${code}`);
  }
}

class ProgramDummyApi implements ProgramApi {
  dummyApi: DummyApi<ProgramDto, ProgramReqDto>;
  constructor() {
    const data: ProgramDto[] = [
      {
        process: 'EXCEL.exe',
        description: 'Excel',
        registration: Registration.A,
        useYN: UseYN.Y,
      },
      {
        process: 'WINWORD.exe',
        description: 'Word',
        registration: Registration.A,
        useYN: UseYN.Y,
      },
      {
        process: 'POWERPNT.exe',
        description: 'PowerPoint',
        registration: Registration.A,
        useYN: UseYN.Y,
      },
      {
        process: 'photoshop.exe',
        description: 'Adobe Photoshop CC 2020',
        registration: Registration.M,
        useYN: UseYN.Y,
      },
      {
        process: 'notepad.exe',
        description: 'Adobe Photoshop CC 2020',
        registration: Registration.M,
        useYN: UseYN.Y,
      },
    ].map((o, i) => ({ ...o, code: '', entryTime: times(i) }));

    this.dummyApi = DummyApi.of({
      entityName: 'Infra.Program',
      createDto: (code, entity) => ({ ...entity, code, entryTime: Date.now() }),
      validation: (type, code, req, data) => {
        data.forEach((dto) => {
          if (
            (type === 'create' && dto.process === req.process) ||
            (type === 'update' && dto.code !== code && dto.process === req.process)
          ) {
            throw new Error('이미 사용 중인 이름입니다.');
          }
        });
      },
      data,
    });
  }

  list(): Promise<ProgramDto[]> {
    return this.dummyApi.list();
  }
  find(code: string): Promise<ProgramDto> {
    return this.dummyApi.find(code);
  }
  create(reqDto: ProgramReqDto): Promise<ProgramDto> {
    return this.dummyApi.create(reqDto);
  }
  update(code: string, reqDto: ProgramReqDto): Promise<ProgramDto> {
    return this.dummyApi.update({ code, reqDto });
  }
  remove(code: string): Promise<void> {
    return this.dummyApi.remove(code);
  }
}

const programApi: ProgramApi = INFRA_API_ENABLED ? new ProgramServerApi() : new ProgramDummyApi();

export default programApi;
