import React from 'react';
import classNames from 'classnames';

import { CommonProps } from 'src/components/helpers/props';
import DashboardIcon from 'src/components/atom/icons/DashboardIcon';
import ProjectIcon from 'src/components/atom/icons/ProjectIcon';
import UserSettingIcon from 'src/components/atom/icons/UserSettingIcon';
import PermissionIcon from 'src/components/atom/icons/PermissionIcon';
import SystemIcon from 'src/components/atom/icons/SystemIcon';
import LicenseIcon from 'src/components/atom/icons/LicenseIcon';

import './AbstractIcon.scss';
import SettingLineIcon from './SettingLineIcon';
import TemplateIcon from './TemplateIcon';

interface RouteIconsProps extends CommonProps {
  size?: 'lg';
}

const Home: React.FC<RouteIconsProps> = ({ classes, size = 'lg' }) => {
  return (
    <svg
      className={classNames('icon-root', classes)}
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="white"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M15 5L3 16H7V25H13V18H17V25H23V16H27L15 5Z" />
    </svg>
  );
};
const Template: React.FC<RouteIconsProps> = ({ classes, size = 'lg' }) => {
  return (
    <svg
      className={classNames('icon-root', classes)}
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="white"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M10 27H4C3.4 27 3 26.6 3 26V4C3 3.4 3.4 3 4 3H10C10.6 3 11 3.4 11 4V26C11 26.6 10.6 27 10 27Z" />
      <path d="M26 13.5H15C14.4 13.5 14 13.1 14 12.5V4C14 3.4 14.4 3 15 3H26C26.6 3 27 3.4 27 4V12.5C27 13.1 26.6 13.5 26 13.5Z" />
      <path d="M26 27H15C14.4 27 14 26.6 14 26V17.5C14 16.9 14.4 16.5 15 16.5H26C26.6 16.5 27 16.9 27 17.5V26C27 26.6 26.6 27 26 27Z" />
    </svg>
  );
};

const Policy: React.FC<RouteIconsProps> = ({ classes, size = 'lg' }) => {
  return (
    <svg
      className={classNames('icon-root', classes)}
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="white"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M3.2002 10.1992V21.5992C3.2002 21.8992 3.4002 22.0992 3.7002 22.0992H7.1002V19.7992C7.1002 18.6992 8.0002 17.8992 9.0002 17.8992H20.9002C22.0002 17.8992 22.8002 18.7992 22.8002 19.7992V22.0992H26.2002C26.5002 22.0992 26.7002 21.8992 26.7002 21.5992V10.1992C26.7002 9.89922 26.5002 9.69922 26.2002 9.69922H20.8002H9.1002H3.7002C3.4002 9.69922 3.2002 9.89922 3.2002 10.1992ZM22.4002 11.6992C23.2002 11.6992 23.8002 12.2992 23.8002 13.0992C23.8002 13.8992 23.2002 14.4992 22.4002 14.4992C21.6002 14.4992 21.0002 13.8992 21.0002 13.0992C21.0002 12.2992 21.7002 11.6992 22.4002 11.6992Z" />
      <path d="M20.4006 20H9.60059C9.30059 20 9.10059 20.2 9.10059 20.5V22.8V23.5V24.2V26.5C9.10059 26.8 9.30059 27 9.60059 27H20.5006C20.8006 27 21.0006 26.8 21.0006 26.5V24.2V23.5V22.8V20.5C20.9006 20.2 20.7006 20 20.4006 20Z" />
      <path d="M21.4006 7V3.5C21.4006 3.2 21.2006 3 20.9006 3H9.10059C8.80059 3 8.60059 3.2 8.60059 3.5V7C8.60059 7.3 8.80059 7.5 9.10059 7.5H20.9006C21.2006 7.5 21.4006 7.2 21.4006 7Z" />
    </svg>
  );
};

const Exception: React.FC<RouteIconsProps> = ({ classes, size = 'lg' }) => {
  return (
    <svg
      className={classNames('icon-root', classes)}
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="white"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M12.3998 5.5H17.4998C17.7998 5.5 18.0998 5.2 18.0998 4.9V4.3V3.6C18.0998 3.3 17.7998 3 17.4998 3H12.3998C12.0998 3 11.7998 3.3 11.7998 3.6V4.2V4.8C11.7998 5.2 12.0998 5.5 12.3998 5.5Z" />
      <path d="M22.7 4.30078H21.1H19.4V4.90078C19.4 5.90078 18.6 6.80078 17.5 6.80078H12.4C11.4 6.80078 10.5 6.00078 10.5 4.90078V4.30078H8.9H7.3C6.6 4.30078 6 4.80078 6 5.50078V25.7008C6 26.4008 6.6 27.0008 7.3 27.0008H22.7C23.4 27.0008 24 26.4008 24 25.7008V5.50078C24 4.80078 23.4 4.30078 22.7 4.30078ZM15 23.0008C11.7 23.0008 9 20.3008 9 17.0008C9 13.7008 11.7 11.0008 15 11.0008C18.3 11.0008 21 13.7008 21 17.0008C21 20.3008 18.3 23.0008 15 23.0008Z" />
      <path d="M17.4001 20.0999L11.7001 14.8999C11.5001 14.6999 11.4001 14.1999 11.7001 13.9999C11.9001 13.7999 12.4001 13.6999 12.6001 13.9999L18.3001 19.1999C18.5001 19.3999 18.6001 19.8999 18.3001 20.0999C18.2001 20.2999 17.7001 20.3999 17.4001 20.0999Z" />
    </svg>
  );
};

const Log: React.FC<RouteIconsProps> = ({ classes, size = 'lg' }) => {
  return (
    <svg
      className={classNames('icon-root', classes)}
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="white"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M22.7 4.30078H21.1H19.4V4.90078C19.4 5.90078 18.6 6.80078 17.5 6.80078H12.4C11.4 6.80078 10.5 6.00078 10.5 4.90078V4.30078H8.9H7.3C6.6 4.30078 6 4.80078 6 5.50078V25.7008C6 26.4008 6.6 27.0008 7.3 27.0008H22.7C23.4 27.0008 24 26.4008 24 25.7008V5.50078C24 4.80078 23.4 4.30078 22.7 4.30078ZM12.4 21.8008L10.3 24.4008C10.2 24.5008 10 24.6008 9.8 24.6008C9.6 24.6008 9.5 24.5008 9.4 24.4008L8.4 23.4008C8.1 23.2008 8.1 22.8008 8.4 22.5008C8.6 22.2008 9 22.2008 9.3 22.5008L9.8 23.0008L11.4 21.0008C11.6 20.7008 12 20.7008 12.3 20.9008C12.6 21.1008 12.6 21.5008 12.4 21.8008ZM12.4 15.9008L10.3 18.5008C10.2 18.6008 10 18.7008 9.8 18.7008C9.6 18.7008 9.5 18.6008 9.4 18.5008L8.4 17.5008C8 17.4008 8 17.0008 8.3 16.8008C8.5 16.5008 8.9 16.5008 9.2 16.8008L9.7 17.3008L11.4 15.2008C11.6 14.9008 12 14.8008 12.3 15.0008C12.6 15.3008 12.6 15.7008 12.4 15.9008ZM12.4 10.1008L10.2 12.7008C10.1 12.9008 10 12.9008 9.8 12.9008C9.6 12.9008 9.5 12.8008 9.4 12.7008L8.4 11.7008C8 11.5008 8 11.1008 8.3 10.9008C8.5 10.6008 8.9 10.6008 9.2 10.9008L9.7 11.4008L11.4 9.30078C11.6 9.00078 12 9.00078 12.3 9.20078C12.6 9.40078 12.6 9.80078 12.4 10.1008ZM21.2 23.7008H14.1C13.8 23.7008 13.5 23.4008 13.5 23.1008C13.5 22.8008 13.8 22.5008 14.1 22.5008H21.2C21.5 22.5008 21.8 22.8008 21.8 23.1008C21.9 23.4008 21.6 23.7008 21.2 23.7008ZM21.2 17.8008H14.1C13.8 17.8008 13.5 17.5008 13.5 17.2008C13.5 16.9008 13.8 16.6008 14.1 16.6008H21.2C21.5 16.6008 21.8 16.9008 21.8 17.2008C21.8 17.5008 21.6 17.8008 21.2 17.8008ZM21.2 12.0008H14.1C13.8 12.0008 13.5 11.7008 13.5 11.4008C13.5 11.1008 13.8 10.8008 14.1 10.8008H21.2C21.5 10.8008 21.8 11.1008 21.8 11.4008C21.9 11.7008 21.6 12.0008 21.2 12.0008Z" />
      <path d="M12.3998 5.5H17.4998C17.7998 5.5 18.0998 5.2 18.0998 4.9V4.3V3.6C18.0998 3.3 17.7998 3 17.4998 3H12.3998C12.0998 3 11.7998 3.3 11.7998 3.6V4.2V4.8C11.7998 5.2 12.0998 5.5 12.3998 5.5Z" />
    </svg>
  );
};

const Statistics: React.FC<RouteIconsProps> = ({ classes, size = 'lg' }) => {
  return (
    <svg
      className={classNames('icon-root', classes)}
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="white"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.70039 21.1996H5.40039C5.10039 21.1996 4.90039 20.9996 4.90039 20.6996V13.5996C4.90039 13.2996 5.10039 13.0996 5.40039 13.0996H8.70039C9.00039 13.0996 9.20039 13.2996 9.20039 13.5996V20.6996C9.20039 20.9996 9.00039 21.1996 8.70039 21.1996Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.6008 21.2H13.3008C13.0008 21.2 12.8008 21 12.8008 20.7V4.5C12.8008 4.2 13.0008 4 13.3008 4H16.6008C16.9008 4 17.1008 4.2 17.1008 4.5V20.7C17.1008 21 16.9008 21.2 16.6008 21.2Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.6008 21.1996H21.3008C21.0008 21.1996 20.8008 20.9996 20.8008 20.6996V9.59961C20.8008 9.29961 21.0008 9.09961 21.3008 9.09961H24.6008C24.9008 9.09961 25.1008 9.29961 25.1008 9.59961V20.6996C25.1008 20.9996 24.9008 21.1996 24.6008 21.1996Z"
      />
      <path d="M26.2 26.0004H3.8C3.4 26.0004 3 25.6004 3 25.2004C3 24.8004 3.4 24.4004 3.8 24.4004H26.2C26.6 24.4004 27 24.8004 27 25.2004C27 25.6004 26.6 26.0004 26.2 26.0004Z" />
    </svg>
  );
};

const Setting: React.FC<RouteIconsProps> = ({ classes, size = 'lg' }) => {
  return (
    <svg
      className={classNames('icon-root', classes)}
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="white"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M24.0004 15.9C24.0004 15.6 24.0004 15.3 24.0004 15C24.0004 14.7 24.0004 14.4 24.0004 14.1L26.7004 12.1C27.2004 11.6 26.9004 11 26.9004 11L25.7004 8.8L24.5004 6.6C24.5004 6.6 24.2004 6.1 23.4004 6.2L20.0004 7.5C19.6004 7.3 19.2004 7 18.8004 6.8L18.4004 3.7C18.2004 3 17.5004 3 17.5004 3H15.0004H12.5004C12.5004 3 11.9004 3 11.7004 3.8L11.2004 6.9C10.8004 7 10.4004 7.3 10.0004 7.5L6.60039 6.2C5.80039 6 5.50039 6.6 5.50039 6.6L4.30039 8.8L3.10039 11C3.10039 11 2.80039 11.6 3.40039 12.1L6.10039 14.1C6.10039 14.4 6.10039 14.7 6.10039 15C6.10039 15.3 6.10039 15.6 6.10039 15.9L3.40039 17.9C2.80039 18.4 3.10039 19 3.10039 19L4.30039 21.2L5.50039 23.4C5.50039 23.4 5.80039 23.9 6.60039 23.8L10.0004 22.5C10.4004 22.7 10.8004 23 11.2004 23.2L11.6004 26.3C11.8004 27 12.5004 27 12.5004 27H15.0004H17.5004C17.5004 27 18.1004 27 18.3004 26.2L18.7004 23.1C19.1004 22.9 19.5004 22.7 19.9004 22.4L23.4004 23.7C24.2004 23.9 24.5004 23.3 24.5004 23.3L25.7004 21.1L26.9004 18.9C26.9004 18.9 27.2004 18.3 26.6004 17.8L24.0004 15.9ZM15.0004 19C12.8004 19 11.0004 17.2 11.0004 15C11.0004 12.8 12.8004 11 15.0004 11C17.2004 11 19.0004 12.8 19.0004 15C19.0004 17.2 17.2004 19 15.0004 19Z" />
    </svg>
  );
};

const CommonPolicy: React.FC<RouteIconsProps> = ({ classes, size = 'lg' }) => {
  return (
    <svg
      className={classNames('icon-root', classes)}
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="white"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M15 3L5 7.36364V13.9091C5 19.9636 9.26667 25.6255 15 27C20.7333 25.6255 25 19.9636 25 13.9091V7.36364L15 3ZM18.4222 19.3636L15 17.3455L11.5889 19.3636L12.4889 15.5455L9.47778 12.9927L13.4556 12.6545L15 9.05455L16.5444 12.6436L20.5222 12.9818L17.5111 15.5455L18.4222 19.3636Z" />
    </svg>
  );
};

const PolicyView: React.FC<RouteIconsProps> = ({ classes, size = 'lg' }) => {
  return (
    <svg
      className={classNames('icon-root', classes)}
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="white"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M18.8901 12.902C21.8328 12.902 24.1869 15.3775 24.1869 18.4719C24.1869 19.5611 23.8926 20.5884 23.3747 21.4425L27 25.2795L25.3639 27L21.6915 23.2001C20.8794 23.7323 19.9142 24.0418 18.8901 24.0418C15.9475 24.0418 13.5934 21.5663 13.5934 18.4719C13.5934 15.3775 15.9475 12.902 18.8901 12.902ZM18.8901 15.3775C18.1097 15.3775 17.3612 15.7035 16.8094 16.2838C16.2575 16.8641 15.9475 17.6512 15.9475 18.4719C15.9475 19.2926 16.2575 20.0796 16.8094 20.66C17.3612 21.2403 18.1097 21.5663 18.8901 21.5663C19.6706 21.5663 20.419 21.2403 20.9709 20.66C21.5227 20.0796 21.8328 19.2926 21.8328 18.4719C21.8328 17.6512 21.5227 16.8641 20.9709 16.2838C20.419 15.7035 19.6706 15.3775 18.8901 15.3775ZM12.4164 3C13.6651 3 14.8626 3.52162 15.7456 4.45012C16.6285 5.37861 17.1246 6.63792 17.1246 7.95101C17.1246 9.07736 16.7597 10.1171 16.1594 10.9587C15.1471 11.3548 14.2408 11.9861 13.4875 12.7782L12.4164 12.902C11.1677 12.902 9.97014 12.3804 9.08719 11.4519C8.20423 10.5234 7.70819 9.26409 7.70819 7.95101C7.70819 6.63792 8.20423 5.37861 9.08719 4.45012C9.97014 3.52162 11.1677 3 12.4164 3ZM3 22.804V20.3285C3 17.7045 6.89603 15.5508 11.8279 15.3775C11.4512 16.343 11.2393 17.3827 11.2393 18.4719C11.2393 20.0686 11.6866 21.5663 12.4164 22.804H3Z" />
    </svg>
  );
};

const Management: React.FC<RouteIconsProps> = ({ classes, size = 'lg' }) => {
  return (
    <svg
      className={classNames('icon-root', classes)}
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="white"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M7.8 3C6.468 3 5.4 4.068 5.4 5.4V15C5.4 16.332 6.468 17.4 7.8 17.4H22.2C23.532 17.4 24.6 16.332 24.6 15V5.4C24.6 4.068 23.532 3 22.2 3H7.8ZM7.8 5.4H22.2V15H7.8V5.4ZM5.4 18.6C4.068 18.6 3 19.668 3 21V24.6C3 25.932 4.068 27 5.4 27H24.6C25.932 27 27 25.932 27 24.6V21C27 19.668 25.932 18.6 24.6 18.6H5.4ZM10.2 21H24.6V24.6H10.2V21ZM11.4 21.9V23.7H16.2V21.9H11.4ZM18.6 21.9V23.7H23.4V21.9H18.6Z" />
    </svg>
  );
};

const Pattern: React.FC<RouteIconsProps> = ({ classes, size = 'lg' }) => {
  return (
    <svg
      className={classNames('icon-root', classes)}
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="white"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.5 3H7.5C6.83696 3 6.20107 3.25286 5.73223 3.70294C5.26339 4.15303 5 4.76348 5 5.4V24.6C5 25.2365 5.26339 25.847 5.73223 26.2971C6.20107 26.7471 6.83696 27 7.5 27H22.5C23.163 27 23.7989 26.7471 24.2678 26.2971C24.7366 25.847 25 25.2365 25 24.6V10.2L17.5 3ZM16.25 11.4V4.8L23.125 11.4H16.25ZM12.7778 18.8182C13.7 18.8182 14.4445 18.0873 14.4445 17.1818C14.4445 16.2764 13.7 15.5455 12.7778 15.5455C11.8556 15.5455 11.1111 16.2764 11.1111 17.1818C11.1111 18.0873 11.8556 18.8182 12.7778 18.8182ZM9.44446 22.0909V23.1818H16.1111V22.0909C16.1111 20.8855 14.6167 19.9091 12.7778 19.9091C10.9389 19.9091 9.44446 20.8855 9.44446 22.0909ZM20.5556 19.9091V18.8182H15.5556V19.9091H20.5556ZM15.5556 17.7273H20.5556V16.6364H15.5556V17.7273ZM20.5556 15.5455V14.4545H15.5556V15.5455H20.5556Z"
      />
    </svg>
  );
};

const Dashboard: React.FC<RouteIconsProps> = () => {
  return <DashboardIcon />;
};

const Project: React.FC<RouteIconsProps> = () => {
  return <ProjectIcon />;
};

const UserSetting: React.FC<RouteIconsProps> = () => {
  return <UserSettingIcon />;
};

const Permission: React.FC<RouteIconsProps> = () => {
  return <PermissionIcon />;
};

const System: React.FC<RouteIconsProps> = () => {
  return <SystemIcon />;
};

const License: React.FC<RouteIconsProps> = () => {
  return <LicenseIcon />;
};

const MyPage: React.FC<RouteIconsProps> = () => {
  return <SettingLineIcon />;
};

const Template2: React.FC<RouteIconsProps> = () => {
  return <TemplateIcon size="sm" />;
};

export default {
  Home,
  Template,
  Policy,
  Exception,
  Log,
  Statistics,
  Setting,
  CommonPolicy,
  PolicyView,
  Management,
  Pattern,
  Permission,

  Dashboard, // 대시보드
  System, // 시스템
  License, // 라이선스
  Project, // 프로젝트
  UserSetting, // 사용자 설정
  MyPage, // 세팅
  Template2, // 템플릿
};
