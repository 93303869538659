import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { Registration, UseYN } from 'src/api/infra';
import programApi, { ProgramDto, ProgramReqDto } from 'src/api/infra/programApi';
import { toLocalDateTimeStr } from 'src/shared/utils/localDateTime';
import { RootState } from 'src/store';

// # types
export type ProgramEntity = Omit<ProgramDto, 'useYN' | 'entryTime'> & {
  use: boolean;
  entryTimeStr: string;
};
export type ProgramReqEntity = Omit<ProgramEntity, 'code' | 'entryTime' | 'entryTimeStr'>;

function toEntity(dto: ProgramDto): ProgramEntity {
  return { ...dto, use: dto.useYN === UseYN.Y, entryTimeStr: toLocalDateTimeStr(dto.entryTime) };
}
function toReqDto(entity: ProgramReqEntity): ProgramReqDto {
  return { ...entity, useYN: entity.use ? UseYN.Y : UseYN.N };
}

interface ProgramEntityByCode {
  [code: string]: ProgramEntity;
}
interface ProgramEntities {
  byCode: ProgramEntityByCode;
  allCodes: string[];
}
interface ProgramState {
  initialized: boolean;
  entities: ProgramEntities;
}

export interface ProgramUpdateReq {
  code: string;
  reqEntity: ProgramReqEntity;
}

// # initial state
const initialState: ProgramState = {
  initialized: false,
  entities: { byCode: {}, allCodes: [] },
};

export const defaultProgramReqEntity: ProgramReqEntity = {
  process: '',
  description: '',
  registration: Registration.M,
  use: true,
};

// # thunks
export const thunkLoadPrograms = createAsyncThunk('program/load', async () => {
  const list = await programApi.list();
  return list.map((dto) => toEntity(dto));
});
export const thunkCreateProgram = createAsyncThunk(
  'program/create',
  async (reqEntity: ProgramReqEntity) => {
    return toEntity(await programApi.create(toReqDto(reqEntity)));
  }
);
export const thunkUpdateProgram = createAsyncThunk(
  'program/update',
  async ({ code, reqEntity }: ProgramUpdateReq) => {
    return toEntity(await programApi.update(code, toReqDto(reqEntity)));
  }
);
export const thunkRemoveProgram = createAsyncThunk('program/remove', async (code: string) => {
  return programApi.remove(code);
});

const programSlice = createSlice({
  name: 'program',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(thunkLoadPrograms.fulfilled, (state, { payload: programs }) => {
      state.entities.byCode = programs.reduce((acc, t) => {
        acc[t.code] = t;
        return acc;
      }, {} as ProgramEntityByCode);
      state.entities.allCodes = programs.map((t) => t.code);
      state.initialized = true;
    });
    builder.addCase(thunkCreateProgram.fulfilled, (state, { payload: createdEntity }) => {
      state.entities.byCode[createdEntity.code] = createdEntity;
      state.entities.allCodes.push(createdEntity.code);
    });
    builder.addCase(thunkUpdateProgram.fulfilled, (state, { payload: updatedEntity }) => {
      state.entities.byCode[updatedEntity.code] = updatedEntity;
    });
    builder.addCase(thunkRemoveProgram.fulfilled, (state, { meta: { arg: removedCode } }) => {
      delete state.entities.byCode[removedCode];
      state.entities.allCodes = state.entities.allCodes.filter((code) => code !== removedCode);
    });
  },
});

// # selectors
export const selectInitialized = (state: RootState): boolean => {
  return state.program.initialized;
};
export const selectProgramEntities = (state: RootState): ProgramEntities => state.program.entities;
export const selectProgramEntityList = (state: RootState): ProgramEntity[] =>
  state.program.entities.allCodes.map((code) => state.program.entities.byCode[code]);

export default programSlice.reducer;

// 참고중...
// https://orizens.com/blog/how-to-not-have-a-mess-with-react-hooks-and-redux/
