import _ from 'lodash';

import config from 'src/shared/config';
import axios from 'src/shared/utils/axios';

const FSP_API_ENABLED = config.servers.fsp.enabled;
const FSP_API_URL = config.servers.fsp.url;

export interface LicenseInfoDto {
  maxUserCount: number;
  maxServerCount: number;
  validStartTime: number;
  validEndTime: number;
}

interface LicenseApi {
  get(): Promise<LicenseInfoDto>;
  updateLicFile(file: File): Promise<LicenseInfoDto>;
}

class LicenseServerApi implements LicenseApi {
  async get(): Promise<LicenseInfoDto> {
    const resp = await axios.get<LicenseInfoDto>(`${FSP_API_URL}/api/license`);
    return resp.data;
  }
  async updateLicFile(file: File): Promise<LicenseInfoDto> {
    const formData = new FormData();
    formData.append('data', file);
    const resp = await axios.post<LicenseInfoDto>(`${FSP_API_URL}/api/license`, formData);
    return Promise.resolve(resp.data);
  }
}

class LicenseDummyApi implements LicenseApi {
  data: LicenseInfoDto;

  constructor() {
    this.data = {
      maxUserCount: 500,
      maxServerCount: 0,
      validStartTime: 1214665200000,
      validEndTime: 4117878000000,
    };
  }

  get(): Promise<LicenseInfoDto> {
    return Promise.resolve(_.cloneDeep(this.data));
  }

  updateLicFile(file: File): Promise<LicenseInfoDto> {
    return Promise.resolve(_.cloneDeep(this.data));
  }
}

const licenseApi: LicenseApi = FSP_API_ENABLED ? new LicenseServerApi() : new LicenseDummyApi();
export default licenseApi;
