import React from 'react';
import classNames from 'classnames';
import _ from 'lodash';

import { CommonProps } from 'src/components/helpers/props';

import './index.scss';

interface CheckboxGroupChildProps extends CommonProps {
  depth?: 1 | 2;
  children: React.ReactNode;
}
export const CheckboxGroupChild: React.FC<CheckboxGroupChildProps> = ({ depth, children }) => {
  return <li className={`depth-${depth}`}>{children}</li>;
};

export interface CheckboxGroupProps extends CommonProps {
  children: React.ReactNode;
}

const CheckboxGroup: React.FC<CheckboxGroupProps> = ({ children, classes }) => {
  return <ul className={classNames('depth-layout-group-root', classes)}>{children}</ul>;
};

export default CheckboxGroup;
