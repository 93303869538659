import moment from 'moment';

import config from 'src/shared/config';
import axios from 'src/shared/utils/axios';

import DummyApi from '../dummyApi';
import { times } from '../api';

import { Registration, UseYN } from '.';

const INFRA_API_ENABLED = config.servers.infra.enabled;
const INFRA_API_URL = config.servers.infra.url;

export interface UrlDto {
  code: string;
  url: string;
  description: string;
  registration: Registration;
  useYN: UseYN;
  entryTime: number;
}

export type UrlReqDto = Omit<UrlDto, 'code' | 'entryTime'>;

interface UrlApi {
  list(): Promise<UrlDto[]>;
  find(code: string): Promise<UrlDto>;
  create(reqDto: UrlReqDto): Promise<UrlDto>;
  update(code: string, reqDto: UrlReqDto): Promise<UrlDto>;
  remove(code: string): Promise<void>;
}

class UrlServerApi implements UrlApi {
  async list(): Promise<UrlDto[]> {
    const resp = await axios.get<UrlDto[]>(`${INFRA_API_URL}/api/urls`);
    return resp.data;
  }
  async find(code: string): Promise<UrlDto> {
    const resp = await axios.get<UrlDto>(`${INFRA_API_URL}/api/urls/${code}`);
    return resp.data;
  }
  async create(reqDto: UrlReqDto): Promise<UrlDto> {
    const resp = await axios.post<UrlDto>(`${INFRA_API_URL}/api/urls`, reqDto);
    return resp.data;
  }
  async update(code: string, reqDto: UrlReqDto): Promise<UrlDto> {
    const resp = await axios.put<UrlDto>(`${INFRA_API_URL}/api/urls/${code}`, reqDto);
    return resp.data;
  }
  async remove(code: string): Promise<void> {
    await axios.delete<UrlDto>(`${INFRA_API_URL}/api/urls/${code}`);
  }
}

class UrlDummyApi implements UrlApi {
  dummyApi: DummyApi<UrlDto, UrlReqDto>;
  constructor() {
    const data: UrlDto[] = [
      {
        url: 'http://filenet2.fasoo.com:9080/navigator',
        description: 'FileNet',
        registration: Registration.M,
        useYN: UseYN.Y,
      },
      {
        url: 'https://www.fasoo.com/services/data-security-consulting',
        description: '파수 컨설팅',
        registration: Registration.M,
        useYN: UseYN.Y,
      },
      {
        url: 'https://luke30.fasoo.com',
        description: 'Luke30',
        registration: Registration.M,
        useYN: UseYN.Y,
      },
      {
        url: 'https://www.figma.com',
        description: 'Figma',
        registration: Registration.M,
        useYN: UseYN.Y,
      },
    ].map((o, i) => ({ ...o, code: '', entryTime: times(i) }));

    this.dummyApi = DummyApi.of({
      entityName: 'Infra.Url',
      createDto: (code, entity) => ({ ...entity, code, entryTime: Date.now() }),
      validation: (type, code, req, data) => {
        data.forEach((dto) => {
          if (
            (type === 'create' && dto.url === req.url) ||
            (type === 'update' && dto.code !== code && dto.url === req.url)
          ) {
            throw new Error('이미 사용 중인 이름입니다.');
          }
        });
      },
      data,
    });
  }

  list(): Promise<UrlDto[]> {
    return this.dummyApi.list();
  }
  find(code: string): Promise<UrlDto> {
    return this.dummyApi.find(code);
  }
  create(reqDto: UrlReqDto): Promise<UrlDto> {
    return this.dummyApi.create(reqDto);
  }
  update(code: string, reqDto: UrlReqDto): Promise<UrlDto> {
    return this.dummyApi.update({ code, reqDto });
  }
  remove(code: string): Promise<void> {
    return this.dummyApi.remove(code);
  }
}

const urlApi: UrlApi = INFRA_API_ENABLED ? new UrlServerApi() : new UrlDummyApi();

export default urlApi;
