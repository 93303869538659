import devConfig from './config.dev';
import prodConfig from './config.prod';

interface Config {
  contextPath: string;
  url: (subUrl: string) => string;
  autoLogin?: {
    auto: boolean;
    userId: string;
    password: string;
  };
  servers: {
    gateway: {
      enabled: boolean;
      url: string;
    };
    policy: {
      enabled: boolean;
      url: string;
    };
    fsp: {
      enabled: boolean;
      url: string;
    };
    infra: {
      enabled: boolean;
      url: string;
    };
    fidp: {
      enabled: boolean;
      url: string;
    };
    flas: {
      enabled: boolean;
      url: string;
    };
    log: {
      enabled: boolean;
      version: number;
      periodDays: number;
      url: string;
      url2: string;
    };
    auth: {
      enabled: boolean;
      url: string;
    };
    fxm: {
      enabled: boolean;
      url: string;
    };
    issuance: {
      enabled: boolean;
      url: string;
    };
    pre: {
      enabled: boolean;
      url: string;
      version: number;
    };
    join: {
      enabled: boolean;
      url: string;
      version: number;
    };
    manager: {
      enabled: boolean;
      url: string;
      url2: string;
    };
    app: {
      enable: boolean;
      url: string;
      url2: string;
    };
    systemLogAPI: {
      enabled: boolean;
      url: string;
      url2: string;
    };
    systemLogFT: {
      enabled: boolean;
      url: string;
      url2: string;
    };
    systemLogManager: {
      enabled: boolean;
      url: string;
      url2: string;
    };
  };
}

// const config: Config = process.env.NODE_ENV === 'production' ? prodConfig : devConfig;
const config: Config = devConfig;
export default config;
