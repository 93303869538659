import React, { useRef, useState, useEffect } from 'react';
import moment from 'moment';
import classNames from 'classnames';
import Button from 'src/components/atom/Button';
import Grid, { GridItem } from 'src/components/atom/Grid';
import Table from 'src/components/atom/Table';
import Checkbox from 'src/components/atom/Checkbox';
import requestApi, { ReviewRequestDto } from 'src/api/requestApi';
import SettingButton2, { DropdownItem } from 'src/components/atom/SettingButton2';
import useCheckboxGroup from 'src/hooks/useCheckboxGroup';
import templateApi, { TemplateDto, TaskDto, DetailDto, createTemplate, createAudit, createDetail, AuditDto, } from 'src/api/templateApi';
import usePopup from 'src/hooks/usePopup';
import Select, { SelectItem } from 'src/components/atom/Select';
import { CommonProps } from 'src/components/helpers/props';
import './index.scss';
import RequestDetailPopup from './RequestDetailPopup';
import RequestHistoryPopup from './RequestHistoryPopup';
import RequestAllPopup from './RequestAllPopup';
import TextField from 'src/components/atom/TextField';
import SearchBarIcon from 'src/components/atom/icons/SearchBarIcon';
import { useAlert } from 'src/contexts/AlertContext';
const INIT_ROW_COUNT = 10;


const RequestList: React.FC = () => {
  const [requestList, setRequestList] = useState<ReviewRequestDto[]>([]);
  const [reqFilteredList, setReqFilteredList] = useState<ReviewRequestDto[]>([]);
  const [rowCount, setRowCount] = useState<number>(INIT_ROW_COUNT);
  const [offset, setOffset] = useState<number>(1);
  const [req, setReq] = useState<ReviewRequestDto>({} as ReviewRequestDto);
  const [reload, setReload] = useState<boolean>(false);
  const [type, setType] = useState<string>("")
  const [projectList, setProjectList] = useState<string[]>([]);
  const [selectedProject, setProject] = useState<string>("");
  const [searchText, setSearchText] = useState<string>("");
  const [doneChkbox, setDoneChkbox] = useState<boolean>(false);
  const { alert2 } = useAlert();
  const chkboxIndexes = useCheckboxGroup<number>();
  const requestDetailPopup = usePopup();
  const requestHistoryPopup = usePopup();
  const requestAllPopup = usePopup();

  useEffect(() => {
    requestApi
      .getRequestList()
      .then((resp) => {
        setRequestList(resp)
        setReqFilteredList(resp)
        let projectList: any = ["*전체리스트"]
        resp?.forEach(item => {
          projectList.push(item.template_name)
        })
        projectList = [...new Set(projectList)]
        setProjectList(projectList)
      })
      .catch(e => alert2("에러발생", e))
  }, [])

  useEffect(() => {
    if (reload && doneChkbox === false) {
      requestApi
        .getRequestList()
        .then((resp) => {
          setRequestList(resp)
          setReqFilteredList(resp)
          let projectList: any = ["*전체리스트"]
          resp?.forEach(item => {
            projectList.push(item.template_name)
          })
          projectList = [...new Set(projectList)]
          setProjectList(projectList)
          setReload(false)
        })
        .catch(e => alert2("에러발생", e))
    } else if (reload && doneChkbox === true) {
      requestApi
        .getRequestDoneList()
        .then((resp) => {
          setRequestList(resp)
          setReqFilteredList(resp)
          let projectList: any = ["*전체리스트"]
          resp?.forEach(item => {
            projectList.push(item.template_name)
          })
          projectList = [...new Set(projectList)]
          setProjectList(projectList)
          setReload(false)
        })
        .catch(e => alert2("에러발생", e))
    }
  }, [reload])

  useEffect(() => {
    chkboxIndexes.handleAllClick([]);
  }, [offset, rowCount])

  useEffect(() => {
    // reqFilteredList 만들기
    let newFilteredList: ReviewRequestDto[] = [];
    requestList.forEach((elem, index) => {
      // 프로젝트 검색
      let retFlag = true;
      if (selectedProject !== "*전체리스트" && selectedProject.length !== 0 && selectedProject !== elem.template_name) {
        retFlag = false;
      }
      // 검색어 검색(task, detail, audit)
      if (searchText.length !== 0 &&
        (!elem.task_name.includes(searchText) &&
          !elem.detail_name.includes(searchText) &&
          !elem.audit_name.includes(searchText)
        )
      ) {
        retFlag = false;
      }

      if (retFlag) {
        newFilteredList.push(elem)
      }
    })
    console.log("newFilteredList :", newFilteredList)
    setReqFilteredList(newFilteredList)
  }, [selectedProject, searchText])

  useEffect(() => {
    console.log("doneChkbox : ", doneChkbox);
    setReload(true);
  }, [doneChkbox])

  const confirmClose = () => {
    setReload(true);
    chkboxIndexes.initSelected([]);
    requestAllPopup.closePopup()
  }

  const handleClickReject = () => {
    setType("반려")
    requestAllPopup.openPopup()
  }

  const handleClickConfirm = () => {
    setType("승인")
    requestAllPopup.openPopup()
  }

  const handleDetail = (req: ReviewRequestDto) => {
    setReq(req);
    requestDetailPopup.openPopup()
  }

  const handleHistory = (req: ReviewRequestDto) => {
    setReq(req);
    requestHistoryPopup.openPopup()
  }

  const handleReview = (comment: string, result: string) => {
    const requestData = createTemplate(req.template_name, req.category_name, req.task_name);
    if (req.detail_sub_title_name) {
      const detail = createDetail(req.detail_name);
      const audit = createAudit(req.audit_name);
      audit.comment = comment;
      requestData.category_list[0].task_list[0].detail_list.push(detail);
      requestData.category_list[0].task_list[0].detail_list[0].detail_stage_name = "InterimAudit_4";
      requestData.category_list[0].task_list[0].detail_list[0].detail_sub_title_list = [{
        name: req.detail_sub_title_name,
        sub_detail_list: [{
          name: req.detail_sub_title_detail_name,
          audit_list: [audit]
        }]
      }]

    } else {
      const detail = createDetail(req.detail_name);
      const audit = createAudit(req.audit_name);
      audit.comment = comment;
      detail.audit_list.push(audit);
      requestData.category_list[0].task_list[0].detail_list.push(detail);
    }

    templateApi
      .handleReview(requestData, result)
      .then((resp) => {
        console.log(`${result} review`, resp);
        setReload(true);
      }).catch(e => {
        alert2((result === "Reject" ? "반려" : "승인") + "하기", e);
      })
    requestDetailPopup.closePopup();
  }

  const onChangeText = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value)
    chkboxIndexes.initSelected([]);
  }
  const onSelectProject = (index: number) => {
    setProject(projectList[index])
    chkboxIndexes.initSelected([]);
  }

  return (
    <div className="request-list-root common-page-root">
      <div className="common-page-header">
        <Grid spacing={0}>
          <GridItem classes="common-page-grid-left" xs={12}>
            <ol className="common-page-header-breadcrumbs">
              <li>요청(Requests)</li>
            </ol>
          </GridItem>
        </Grid>
      </div>
      <div className="common-page-subheader">
        <Grid spacing={0}>
          <GridItem classes="common-page-grid-left" xs={7}>
            <div style={{ width: 360, height: "100%" }}>
              <SelectProject
                projectList={projectList}
                selected={selectedProject}
                onSelected={onSelectProject}
              />
            </div>
            <div style={{ width: 360, marginLeft: 20, height: "100%" }}>
              <TextField
                classes="request-search-bar"
                placeholder="검색할 내용을 입력하세요.(TASK, 파일명)"
                endIcon={<SearchBarIcon />}
                name={"seachText"}
                value={searchText} onChange={onChangeText}
              />
            </div>
            <div className='check-box'>
              <Checkbox label={"완료된 요청 보여주기"}
                checked={doneChkbox}
                onChange={(name, value, checked) =>
                  setDoneChkbox(checked)
                }
              />
            </div>
          </GridItem>
          <GridItem classes="common-page-grid-right" xs={5}>
            {!doneChkbox && <Button
              color="fourth"
              disabled={chkboxIndexes?.selected?.length > 0 ? false : true}
              text="반려"
              onClick={() => handleClickReject()}
            />}
            {!doneChkbox && <Button
              text="승인"
              disabled={chkboxIndexes?.selected?.length > 0 ? false : true}
              onClick={() => handleClickConfirm()}
            />}
          </GridItem>
        </Grid>
      </div>

      <div className="common-page-body">
        <Table
          // horizontal
          // border={true}
          // headerFixed={true}
          headerColor={'lightgray'}
          headerBackgroundColor={'gray'}
          classes={'column-content-table'}
        >
          <colgroup>
            <col className='col-checkbox'></col>
            <col style={{ width: '200px' }}></col>
            <col style={{ width: 'auto' }}></col>
            <col style={{ width: 'auto' }}></col>
            <col style={{ width: 'auto' }}></col>
            <col style={{ width: '80px' }}></col>
            <col style={{ width: '100px' }}></col>
            <col style={{ width: '50px' }}></col>

          </colgroup>

          <thead>
            <tr>
              <th className='cell-checkbox'>
                {!doneChkbox && <Checkbox
                  checked={reqFilteredList.length > 0 &&
                    chkboxIndexes.selected.length === reqFilteredList.length}
                  onChange={(name, value, checked) =>
                    chkboxIndexes.handleAllClick(
                      checked ? reqFilteredList.map((o, i) => i) : []
                    )
                  }
                />}
              </th>
              <th>프로젝트</th>
              <th>Task</th>
              <th>세부 Task</th>
              <th>조서</th>
              <th>{doneChkbox ? "결제자" : "요청자"}</th>
              <th>{doneChkbox ? "결제일" : "요청일"}</th>
              <th className="col-settings"></th>
            </tr>
          </thead>
          <tbody>
            {reqFilteredList.map((elem, index) => {
              return (
                <tr key={"reqElem" + index}>
                  <td>
                    {!doneChkbox ? <Checkbox
                      checked={chkboxIndexes.isSelected(index)}
                      onChange={(name, value, checked) =>
                        chkboxIndexes.handleClick(index)
                      }
                    /> :
                      <div style={{
                        width: 30, height: 30, backgroundColor: elem.request_status === "리뷰승인" ? "#295c9f" : "#ff6565",
                        display: "flex", alignItems: "center", justifyContent: "center", borderRadius: 5,
                      }}>
                        <div style={{ color: "#ffffff", fontSize: 11 }}>{elem.request_status === "리뷰승인" ? "승인" : "반려"}</div>
                      </div>}
                  </td>
                  <td className="request-audit-text">{elem.template_name}</td>
                  <td className="request-audit-text">{elem.detail_sub_title_name || elem.task_name}</td>
                  <td className="request-audit-text">{elem.detail_sub_title_detail_name || elem.detail_name}</td>
                  <td className="request-audit-text">{elem.audit_name}</td>
                  <td>{elem.history?.by.nickname}</td>
                  <td>{moment(elem.history?.at).format('YYYY.MM.DD')}</td>
                  <td className="col-settings">
                    <SettingButton2 classes="icon-more icon-root">
                      {!doneChkbox && <DropdownItem onClick={() => { handleDetail(elem); }} classes={'setting-edit'}>
                        <span>상세보기</span>
                      </DropdownItem>}
                      <DropdownItem onClick={() => { handleHistory(elem); }} classes={'setting-history'}>
                        <span>히스토리</span>
                      </DropdownItem>
                    </SettingButton2>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </Table>

      </div>
      {requestDetailPopup.opened &&
        <RequestDetailPopup open={requestDetailPopup.opened}
          dto={req}
          onClose={requestDetailPopup.closePopup}
          onSubmit={handleReview}
        />
      }
      {requestHistoryPopup.opened &&
        <RequestHistoryPopup open={requestHistoryPopup.opened}
          dto={req}
          onClose={requestHistoryPopup.closePopup}
        />
      }
      {requestAllPopup.opened &&
        <RequestAllPopup open={requestAllPopup.opened}
          dto={reqFilteredList}
          type={type}
          indexes={chkboxIndexes.selected}
          onClose={requestAllPopup.closePopup}
          onConfirmClose={confirmClose}
        />
      }
    </div>
  )
}

interface SelectProjectProps extends CommonProps {
  projectList: string[];
  selected?: string;
  allFlag?: boolean;
  onSelected?: (value: number) => void;
}

const SelectProject: React.FC<SelectProjectProps> = ({
  projectList,
  selected,
  allFlag = false,
  onSelected,
}) => {
  return (
    <Select
      width={360}
      selectFirstItem={false}
      selectedEventKey={selected}
      placeholder={'프로젝트 이름을 선택하세요.'}
      textAlign='center'
      align='left'
      border={true}
      onClick={(value) => {
        // value는 eventKey 값이다.
        let index = projectList.findIndex(i => i === value)
        onSelected && onSelected(index)
      }}
    >
      {projectList && projectList.map((elem) => (
        <SelectItem key={elem} eventKey={elem} >
          {elem}
        </SelectItem>
      ))
      }
    </Select >
  )
}



export default RequestList;
