import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import watermarkFileApi, { WatermarkFileDto, WatermarkFileReqDto } from 'src/api/watermarkFileApi';
import { toLocalDateTimeStr } from 'src/shared/utils/localDateTime';
import { RootState } from 'src/store';

// # types
export interface WatermarkFileEntity extends WatermarkFileDto {
  entryTsStr: string;
}
function toEntity(dto: WatermarkFileDto): WatermarkFileEntity {
  return { ...dto, entryTsStr: toLocalDateTimeStr(dto.entryTs) };
}

function toDto(entity: WatermarkFileEntity): WatermarkFileDto {
  return { ...entity };
}

interface WatermarkFileEntityByCode {
  [code: string]: WatermarkFileEntity;
}
export interface WatermarkFileEntities {
  byCode: WatermarkFileEntityByCode;
  allCodes: string[];
}
interface WatermarkFileState {
  initialized: boolean;
  entities: WatermarkFileEntities;
}

export interface WatermarkFileCreateReq {
  reqDto: WatermarkFileReqDto;
  file: File;
}
export interface WatermarkFileUpdateReq {
  code: string;
  reqDto: WatermarkFileReqDto;
  file?: File;
}
export interface WatermarkFileUpdateUseReq {
  code: string;
  reqDto: WatermarkFileReqDto;
  use: boolean;
}

// # initial state
const initialState: WatermarkFileState = {
  initialized: false,
  entities: { byCode: {}, allCodes: [] },
};

// # thunks
export const thunkLoadWatermarkFiles = createAsyncThunk('watermarkFile/load', async () => {
  const list = await watermarkFileApi.list();
  return list.map((dto) => toEntity(dto));
});
export const thunkCreateWatermarkFile = createAsyncThunk(
  'watermarkFile/create',
  async ({ reqDto, file }: WatermarkFileCreateReq) => {
    return toEntity(await watermarkFileApi.create(reqDto, file));
  }
);
export const thunkUpdateWatermarkFile = createAsyncThunk(
  'watermarkFile/update',
  async ({ code, reqDto, file }: WatermarkFileUpdateReq) => {
    return toEntity(await watermarkFileApi.update(code, reqDto, file));
  }
);
export const thunkUpdateWatermarkFileUse = createAsyncThunk(
  'watermarkFile/update/use',
  async ({ code, reqDto, use }: WatermarkFileUpdateUseReq) => {
    const newDto = { ...reqDto, use };
    return toEntity(await watermarkFileApi.update(code, newDto));
  }
);
export const thunkRemoveWatermarkFile = createAsyncThunk(
  'watermarkFile/remove',
  async (code: string) => {
    return watermarkFileApi.remove(code);
  }
);

const watermarkFileSlice = createSlice({
  name: 'watermarkFile',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(thunkLoadWatermarkFiles.fulfilled, (state, { payload: watermarkFiles }) => {
      state.entities.byCode = watermarkFiles.reduce((acc, t) => {
        acc[t.code] = t;
        return acc;
      }, {} as WatermarkFileEntityByCode);
      state.entities.allCodes = watermarkFiles.map((t) => t.code);
      state.initialized = true;
    });
    builder.addCase(thunkCreateWatermarkFile.fulfilled, (state, { payload: createdEntity }) => {
      state.entities.byCode[createdEntity.code] = createdEntity;
      state.entities.allCodes.push(createdEntity.code);
    });
    builder.addCase(thunkUpdateWatermarkFile.fulfilled, (state, { payload: updatedEntity }) => {
      state.entities.byCode[updatedEntity.code] = updatedEntity;
    });
    builder.addCase(thunkUpdateWatermarkFileUse.fulfilled, (state, { payload: updatedEntity }) => {
      state.entities.byCode[updatedEntity.code] = updatedEntity;
    });
    builder.addCase(thunkRemoveWatermarkFile.fulfilled, (state, { meta: { arg: removedCode } }) => {
      delete state.entities.byCode[removedCode];
      state.entities.allCodes = state.entities.allCodes.filter((code) => code !== removedCode);
    });
  },
});

// # selectors
export const selectInitialized = (state: RootState): boolean => {
  return state.watermarkFile.initialized;
};
export const selectWatermarkFileEntities = (state: RootState): WatermarkFileEntities =>
  state.watermarkFile.entities;
export const selectWatermarkFileEntityList = (state: RootState): WatermarkFileEntity[] =>
  state.watermarkFile.entities.allCodes.map((code) => state.watermarkFile.entities.byCode[code]);
export const selectUseWatermarkFileEntityList = (state: RootState): WatermarkFileEntity[] =>
  state.watermarkFile.entities.allCodes
    .filter((code) => state.watermarkFile.entities.byCode[code].use === true)
    .map((code) => state.watermarkFile.entities.byCode[code]);

export default watermarkFileSlice.reducer;
