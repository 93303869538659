import React, { useEffect, useState } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import qs from 'query-string';

import authApi from 'src/api/auth/authApi';
import useAuthStore from 'src/hooks/auth/useAuthStore';
import config from 'src/shared/config';
import { removeAndGetLogoutCallbackEntities } from 'src/shared/utils/sessionStorageManager';
import axios from 'src/shared/utils/axios';
import currentUrlUtils from 'src/shared/utils/currentUrlUtils';

const Logout: React.FC = () => {
  const history = useHistory();

  const { isAuthenticated, isProgressSignOut, signOut, finishSignOut } = useAuthStore();
  const [localProgressSignOut, setLocalProgressSignOut] = useState(true);
  console.log(`Logout called with ${isAuthenticated}`);

  useEffect(() => {
    if (isAuthenticated) {
      const promiseSignOutIdP = signOut().catch((e) => {
        // do nothing
      });
      // call other SP logout callbacks
      const logoutCallbackEntities = removeAndGetLogoutCallbackEntities();
      const promiseSignOutSPs = logoutCallbackEntities.map(({ logoutUrl, token }) => {
        const params = new URLSearchParams();
        params.append('bearer', token);
        return axios
          .delete<void>(logoutUrl, { data: params })
          .catch((e) => {
            // do nothing;
          });
      });
      Promise.all([promiseSignOutIdP, ...promiseSignOutSPs]).then(() => {
        finishSignOut();
        setLocalProgressSignOut(false);
      });
    } else {
      finishSignOut();
      setLocalProgressSignOut(false);
    }
  }, [history, isAuthenticated, finishSignOut, signOut, isProgressSignOut, localProgressSignOut]);

  if (!isProgressSignOut && !localProgressSignOut) {
    const redirectUrl = currentUrlUtils.getFirstQuery('redirectUrl') || config.url('/');

    return (
      <Redirect
        to={qs.stringifyUrl({
          url: config.url('/sign-in'),
          query: { redirectUrl },
        })}
      />
    );
  }

  return null;
};

export default Logout;
