import React from 'react';
import classNames from 'classnames';

import { CommonProps } from 'src/components/helpers/props';

import './AbstractIcon.scss';
import { findLastIndex } from 'lodash';
import ReactHighcharts from 'react-highcharts';

interface BNHLogoProps extends CommonProps {
  size?: 'md' | 'sm';
  width?: number;
  height?: number;
}

const BNHLogo: React.FC<BNHLogoProps> = ({
  classes,
  size = 'md',
}) => {

  const config = {
    height: '32px',
    width: '340px',
    padding: 10,
    left: {
      fontSize: '24px'
    },
    right: {
      fontSize: '14px'
    }
  }
  if (size === 'sm') {
    config.height = '28px';
    config.width = '255px';
    config.padding = 6;
    config.left.fontSize = '18px';
    config.right.fontSize = '12px';

  }

  const { left, right } = config;

  return (
    <img style={{ width: 300, height: "auto", objectFit: "contain" }} src={require("src/resources/images/bnhLogo3.png")}></img>
    //<div style={{ display: 'flex', flexDirection: 'row', width: config.width, height: config.height, color: '#295c9f' }}>

    //   <div style={{display: 'flex', flex: 1, alignItems: 'center', padding: `0 ${config.padding}px`}}>
    //     <div style={{fontSize: left.fontSize, fontWeight: 'bold', display: 'flex', flex: 3}}>
    //     BnH 보현
    //     </div>
    //     <div style={{display: 'flex', flex: 1, flexDirection: 'column', fontSize: right.fontSize, lineHeight: right.fontSize}}>
    //       <div style={{marginBottom: '3px'}}>회계</div>
    //       <div>법인</div>
    //     </div>
    //   </div>

    //   <div style={{display: 'flex', fontSize: right.fontSize, lineHeight: right.fontSize, flex: 1, flexDirection: 'column', borderLeft: 'solid 1px #295c9f', paddingLeft: config.padding*2 + 'px', justifyContent: 'space-between'}}>
    //     <div>Beyond Expectation</div>
    //     <div>Highest Satisfaction</div>
    //   </div> 
    //</div>
    // <svg
    //   className={classNames('icon-root', classes)}
    //   width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    //   <circle cx="18" cy="18" r="18" fill="#E8F3FF"/>
    //   <path d="M18 11V25" stroke="#295c9f" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    //   <path d="M25 18L11 18" stroke="#295c9f" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    // </svg>

  );
};

export default BNHLogo;
