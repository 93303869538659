import React, { useEffect, useState, useContext, Fragment } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { CommonProps } from 'src/components/helpers/props';
import Popup2 from 'src/components/molecules/Popup2';
import configApi from 'src/api/configApi';
import Button from 'src/components/atom/Button';
import TextField from 'src/components/atom/TextField';
import './Step2EditPopup.scss';
import AddCircle2Icon from 'src/components/atom/icons/AddCircle2Icon';
import TrashIcon from 'src/components/atom/icons/TrashIcon';
import IconButton from 'src/components/atom/IconButton';
import { useAlert } from 'src/contexts/AlertContext';
import { validateAll, validateAll2 } from 'src/shared/utils/validations';
import Select, { SelectItem } from 'src/components/atom/Select';
import _, { omit } from 'lodash';

interface Step2EditPopupProps extends CommonProps {
  open: boolean;
  data: any;
  onConfirm: (opinions: any) => void;
  onClose: () => void;
}

const Step2EditPopup: React.FC<Step2EditPopupProps> = ({
  open,
  data,
  onClose,
  onConfirm,
  classes,
}) => {
  const { t } = useTranslation(['commons']);
  const { alert2 } = useAlert();
  const [riskData, setRiskData] = useState(data);
  const ceoInsists = [{ key: "A", order: 1, }, { key: "C", order: 2, }, { key: "CO", order: 3, }, { key: "E", order: 4, },
  { key: "O", order: 5, }, { key: "V", order: 6, }, { key: "RO", order: 7, }, { key: "CL", order: 8, }, { key: "U", order: 9, }, { key: "P", order: 10, }];
  const validationRules = {
    name: riskData?.every((item: any) => item.name.length > 0),
    opinions: riskData?.every((item: any) => item.management_assertion_list?.some((elem: any) => elem.checked)),
    account: riskData?.every((item: any) => item.accounting?.length > 0),
    risk: riskData?.every((item: any) => item.risk_setting?.length > 0),
  };

  const riskIdentityList = ["S", "S,F", "F"]
  const [accountList, setAccountList] = useState<string[]>([]);


  const handleOk = (): void => {
    const r = validateAll2(validationRules)
    if (!r.retVal) {
      if (r.key === "name") {
        alert2("위험 관리", "위험명은 필수 입력값입니다.");
      } else if (r.key === "opinions") {
        alert2("위험 관리", "경영진 주장은 한 개 이상 선택 해주세요.");
      } else if (r.key === "account") {
        alert2("위험 관리", "계정명는 한 개 이상 선택 해주세요.");
      } else if (r.key === "risk") {
        alert2("위험 관리", "위험 설정은 필수입니다.");
      }
      return;
    }
    // if (!validateAll(validationRules)) {
    //   alert2("위험 관리", "위험명은 필수 입력값입니다.");
    //   return;
    // }
    onConfirm(riskData)
    onClose();
  };

  useEffect(() => {
    configApi.getMainAccoutList({ _id: sessionStorage.getItem("templateId"), name: sessionStorage.getItem("templateName") }).then(
      (resp) => { setAccountList(resp?.accounting_list) }
    ).catch(e => {
      console.log("E:", e);
    });
  }, []);



  const onClickItem = (i: number, j: number) => {
    let newRiskData = [..._.cloneDeep(riskData)];
    newRiskData[i].management_assertion_list[j].checked = !newRiskData[i].management_assertion_list[j].checked;
    setRiskData(newRiskData)
  }

  const handleDetailAdd = () => {
    let newRiskData = [..._.cloneDeep(riskData)];
    newRiskData.push({ name: "", accounting: [], risk_setting: "", management_assertion_list: ceoInsists })
    setRiskData(newRiskData)
  }

  const handleDeleteAccount = (i: number) => {
    let newRiskData = [..._.cloneDeep(riskData)];
    newRiskData.splice(i, 1)
    setRiskData(newRiskData)
  }

  const handleAccountNameChange = (e: React.ChangeEvent<HTMLInputElement>, i: number) => {
    e.stopPropagation();
    let newRiskData = [..._.cloneDeep(riskData)];
    newRiskData[i].name = e.target.value;
    setRiskData(newRiskData)
  }

  const onSelectedAccount = (value: string, i: number) => {

    // console.log("value: ", value)
    // console.log("i: ", i)
    // 같은거 두번 고르면 무시
    let newRiskData = [..._.cloneDeep(riskData)];
    if (newRiskData[i].accounting?.filter((account: string) => account == value).length > 0) {
      return;
    }
    newRiskData[i].accounting = [...(newRiskData[i]?.accounting || []), value];
    //console.log("newRiskData : ", newRiskData)
    setRiskData(newRiskData)
  }

  const onDeletedAccount = (value: string, i: number) => {
    // console.log("value: ", value)
    // console.log("i: ", i)
    let newRiskData = [..._.cloneDeep(riskData)];
    newRiskData[i].accounting = newRiskData[i].accounting.filter((account: string) => account !== value)
    // console.log("newRiskData : ", newRiskData)
    setRiskData(newRiskData)
  }
  const onSelectedRiskIdentity = (value: string, i: number) => {
    // console.log("value: ", value)
    // console.log("i: ", i)
    let newRiskData = [..._.cloneDeep(riskData)];
    newRiskData[i].risk_setting = value;
    // console.log("newRiskData : ", newRiskData)
    setRiskData(newRiskData)
  }

  return (
    <Popup2
      classes="audit-popup-root project-popup-root project-common"
      title={"위험 관리"}
      width="1112px"
      height="692px"
      open={open}
      onClose={onClose}
      footer={
        <React.Fragment>
          <div className="detail-add">
            <div
              onClick={handleDetailAdd}
            >
              <AddCircle2Icon color="#295c9f" />
              <span>위험 추가</span>
            </div>
          </div>
          <Button text={t('commons:cancel')} onClick={onClose} color="secondary" />
          <Button
            text={'확인'}
            onClick={handleOk}
          // disabled={!selectedUsers} 
          />
        </React.Fragment>
      }
    >
      <div className="task-header element">
        <div style={{ paddingLeft: 15, width: "25%", color: "#a3a6b4" }}>위험</div>
        <div style={{ width: "40%", color: "#a3a6b4" }}>계정</div>
        <div style={{ width: "10%", color: "#a3a6b4" }}>위험 설정</div>
        <div style={{ width: "25%", color: "#a3a6b4" }}>경영진 주장</div>
      </div>
      <div className="audit-process-content-step2-root">
        {riskData?.map((elem: any, i: number) => {
          return (
            <div className="audit-process-content-step2" key={"riskData" + i.toString()}>
              <div className="audit-process-content-risk">
                <TextField classes="audit-process-content-risk-text"
                  value={elem.name + (elem?.is_default ? "*" : "") || ""} disabled={elem?.is_default ? true : false}
                  placeholder="위험명을 입력하세요."
                  autoFocus={!elem?.name ? true : false}
                  onChange={(e) => { handleAccountNameChange(e, i) }}
                />
              </div>
              <div className="audit-process-content-account">
                <Select
                  vertical={i > 2 ? "up" : "down"}
                  width={390}
                  selectFirstItem={false}
                  selectedEventKey={""}
                  textAlign='center'
                  align='left'
                  placeholder="계정 선택"
                  enableMultiSelect={true}
                  multiSelectList={elem?.accounting?.map((acc: string) => (acc))}
                  border={false}
                  onClick={(value) => {
                    onSelectedAccount && onSelectedAccount(value, i)
                  }}
                  onDelete={(value) => onDeletedAccount(value, i)}
                >
                  {accountList && accountList.map((elem, i) => (
                    <SelectItem key={"select-item" + i.toString()} eventKey={elem} >
                      {elem}
                    </SelectItem>
                  ))
                  }
                </Select >
              </div>
              <div className="audit-process-content-risk-identify">
                <Select
                  vertical={i > 2 ? "up" : "down"}
                  width={80}
                  selectFirstItem={false}
                  selectedEventKey={elem.risk_setting}

                  textAlign='center'
                  align='left'
                  placeholder="위험 선택"
                  border={false}
                  onClick={(value) => {
                    onSelectedRiskIdentity && onSelectedRiskIdentity(value, i)
                  }}
                >
                  {riskIdentityList && riskIdentityList.map((elem, i) => (
                    <SelectItem key={i.toString()} eventKey={elem}>
                      {elem}
                    </SelectItem>
                  ))
                  }
                </Select >
              </div>
              <div className="audit-process-content-opinions">
                {elem?.management_assertion_list?.map((item: any, j: number) => {
                  return (
                    <div key={j.toString()} className="audit-process-content-opinions-item" style={{ backgroundColor: item.checked ? "#295c9f" : "white", color: item.checked ? "#ffffff" : "#4d4f5c" }}
                      onClick={() => onClickItem(i, j)}
                    >
                      <div>{item.key}</div>
                    </div>
                  )
                })
                }
              </div>
              {!elem?.is_default && <div className="audit-process-content-risk-delete">
                <IconButton onClick={() => handleDeleteAccount(i)}>
                  <TrashIcon></TrashIcon>
                </IconButton>

              </div>}
            </div>
          )
        })

        }
      </div>

    </Popup2>
  );
};

export default Step2EditPopup;
