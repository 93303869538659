import React, { useState } from 'react';
import classNames from 'classnames';

import Draggable, { DraggableData, DraggableEvent } from 'react-draggable';
import { CommonProps } from 'src/components/helpers/props';
import ModalPortal from 'src/shared/react-utils/ModalPortal';

import CloseIcon from 'src/components/atom/icons/CloseIcon';
import IconButton from 'src/components/atom/IconButton';

import './index.scss';

export interface AlertProps extends CommonProps {
  title?: string;
  children: React.ReactNode;
  footer: React.ReactNode;
  onClose: () => void;
  open: boolean;
  type: string;
}

interface Position {
  x: number;
  y: number;
}

const Alert: React.FC<AlertProps> = ({ title, children, footer, open, onClose, classes, type }: AlertProps) => {
  const [position, setPosition] = useState<Position>({
    x: 0,
    y: 0
  });

  const handleDrag = (e: DraggableEvent, ui: DraggableData): void | false => {
    const { x, y } = position;
    setPosition({
      x: x + ui.deltaX,
      y: y + ui.deltaY
    });
  };

  const nodeRef = React.useRef(null);
  return (
    <ModalPortal>
      <div className={classNames('alert-backdrop-root', { on: open })} />
      <Draggable
        nodeRef={nodeRef}
        handle=".alert-head"
        defaultPosition={{ x: 0, y: 0 }}
        position={position}
        onDrag={handleDrag}
      >
        <div
          ref={nodeRef}
          className={classNames(
            //(type === 'alert' || type === 'alert2' || type === 'alert3') ? 'alert-root' : 'confirm-root',
            'confirm-root',
            {
              on: open
            },
            classes
          )}

        >
          <div className="inner">
            <div className="alert-head">
              <p className="tit">
                {title && title.length > 0 ?
                  <span className="title">
                    {title}
                  </span>
                  :
                  <span className="logo">
                    <em className="txt-hide">로고</em>
                  </span>
                }
              </p>
              <IconButton unfilled onClick={onClose}>
                <CloseIcon />
              </IconButton>
            </div>
            <div className="alert-content txt-word-break-all">{children}</div>
            <div className="alert-foot">{footer}</div>
          </div>
        </div>
      </Draggable>
    </ModalPortal>
  );
};

export default Alert;
