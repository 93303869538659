import React, { useEffect, useState, useContext, Fragment } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import { CommonProps } from 'src/components/helpers/props';
import { getAdidManagerUser } from 'src/shared/utils/sessionStorageManager';
import Popup2 from 'src/components/molecules/Popup2';
import templateApi, { TemplateDto, TaskDto, DetailDto, createTemplate, createAudit, createDetail, AuditDto, } from 'src/api/templateApi';
import Button from 'src/components/atom/Button';
import TextField from 'src/components/atom/TextField';
import FormLabel from 'src/components/atom/FormLabel';
import FormGroup from 'src/components/atom/FormGroup';
import './AuditPopup.scss';

interface AuditPreparePopupProps extends CommonProps {
  open: boolean;
  taskName: string;
  detailName: string;
  auditDto: AuditDto;
  onClose: () => void;
  onConfirm: () => void;
}


const AuditPreparePopup: React.FC<AuditPreparePopupProps> = ({
  open,
  taskName,
  detailName,
  auditDto,
  onClose,
  onConfirm,
  classes,
}) => {
  const { t } = useTranslation(['commons']);
  
  const handleOk = (): void => {
    onConfirm();
  };

  return (
    <Popup2
      classes="audit-popup-root project-popup-root project-common"
      title={"프리페어"}
      width="1112px"
      height="692px"
      open={open}
      onClose={onClose}
      footer={
        <React.Fragment>
          {/* <Button text={t('commons:cancel')} onClick={handleCancel} color="secondary" /> */}
          <Button 
            text={'확인'} 
            onClick={handleOk} 
            // disabled={!selectedUsers} 
            />
        </React.Fragment>
      }
    >
      <div className="project-input-area">
        <FormGroup classes="no-shrink">
          <FormLabel classes='input-label'>TASK</FormLabel>
          <TextField classes='input-area' value={taskName} disabled={true} />
        </FormGroup>
        <FormGroup classes="no-shrink">
          <FormLabel classes='input-label'>세부 TASK</FormLabel>
          <TextField classes='input-area' value={detailName} disabled={true}/>
        </FormGroup>
        <FormGroup classes="no-shrink">
          <FormLabel classes='input-label'>조서 이름</FormLabel>
          <TextField classes='input-area' value={auditDto.file_name} disabled={true}/>
        </FormGroup>
      </div>
    </Popup2>
  );
};

export default AuditPreparePopup;
