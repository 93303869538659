import React from 'react';
import classNames from 'classnames';

import { CommonProps } from 'src/components/helpers/props';

import './AbstractIcon.scss';

interface SettingsIconProps extends CommonProps {
  size?: 'md';
}

const SettingsIcon: React.FC<SettingsIconProps> = ({ classes, size = 'md' }) => {
  return (
    <svg
      className={classNames('icon-root', classes)}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M17.9503 12.78C17.9803 12.53 18.0003 12.27 18.0003 12C18.0003 11.73 17.9803 11.47 17.9403 11.22L19.6303 9.9C19.7803 9.78 19.8203 9.56 19.7303 9.39L18.1303 6.62C18.0303 6.44 17.8203 6.38 17.6403 6.44L15.6503 7.24C15.2303 6.92 14.7903 6.66 14.3003 6.46L14.0003 4.34C13.9703 4.14 13.8003 4 13.6003 4H10.4003C10.2003 4 10.0403 4.14 10.0103 4.34L9.71025 6.46C9.22025 6.66 8.77025 6.93 8.36025 7.24L6.37025 6.44C6.19025 6.37 5.98025 6.44 5.88025 6.62L4.28025 9.39C4.18025 9.57 4.22025 9.78 4.38025 9.9L6.07025 11.22C6.03025 11.47 6.00025 11.74 6.00025 12C6.00025 12.26 6.02025 12.53 6.06025 12.78L4.37025 14.1C4.22025 14.22 4.18025 14.44 4.27025 14.61L5.87025 17.38C5.97025 17.56 6.18025 17.62 6.36025 17.56L8.35025 16.76C8.77025 17.08 9.21025 17.34 9.70025 17.54L10.0003 19.66C10.0403 19.86 10.2003 20 10.4003 20H13.6003C13.8003 20 13.9703 19.86 13.9903 19.66L14.2903 17.54C14.7803 17.34 15.2303 17.07 15.6403 16.76L17.6303 17.56C17.8103 17.63 18.0203 17.56 18.1203 17.38L19.7203 14.61C19.8203 14.43 19.7803 14.22 19.6203 14.1L17.9503 12.78ZM12.0003 15C10.3503 15 9.00025 13.65 9.00025 12C9.00025 10.35 10.3503 9 12.0003 9C13.6503 9 15.0003 10.35 15.0003 12C15.0003 13.65 13.6503 15 12.0003 15Z" />
    </svg>
  );
};

export default SettingsIcon;
