import React, { useEffect, useState } from 'react';
import { Link as RouterLink, useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import TextField from 'src/components/atom/TextField';
import Grid, { GridItem } from 'src/components/atom/Grid';
import BreadcrumbArrowIcon from 'src/components/atom/icons/BreadcrumbArrowIcon';
import { useAlert } from 'src/contexts/AlertContext';
import { useToast } from 'src/contexts/ToastContext';
import FormGroup from 'src/components/atom/FormGroup';
import FormLabel from 'src/components/atom/FormLabel';
import Button from 'src/components/atom/Button';
import ExpansionDiv from 'src/components/atom/ExpansionDiv';
import CheckboxGroup, { CheckboxGroupChild } from 'src/components/atom/CheckboxGroup';
import Checkbox from 'src/components/atom/Checkbox';
import { validateAll } from 'src/shared/utils/validations';
import userSettingApi, { UserInfoEntity, emptyUserInfoEntity } from 'src/api/userSettingApi';
import roleApi, { RoleDto, PermissionDto } from 'src/api/roleApi';
import settingApi from 'src/api/settingApi';

import './index.scss';

const UserSettingForm: React.FC = () => {
  const { alert, confirm } = useAlert();
  const toast = useToast();
  const history = useHistory();
  const { t } = useTranslation(['commons']);
  const { id } = useParams<{ id: string }>();
  const editForm = Boolean(id);
  const [submit, setSubmit] = useState<boolean>(false);
  const [dto, setDto] = useState<UserInfoEntity | undefined>(undefined);
  const [roleList, setRoleList] = useState<RoleDto[] | undefined>(undefined);
  const [permissionList, setPermissionList] = useState<PermissionDto[] | undefined>(undefined);
  const [useComplexity, setUseComplexity] = useState<boolean | undefined>(undefined);
  const [reqDto, setReqDto] = useState<UserInfoEntity>(emptyUserInfoEntity());

  const [passwordCheck, setPasswordCheck] = useState<{ password: string; verifyPassword: string }>({
    password: '',
    verifyPassword: '',
  });

  const validationId = (id: string): boolean => {
    // settingList.id

    const idRegEx = RegExp('^[\\_A-Za-z0-9]*$');
    if (!idRegEx.test(id)) {
      return false;
    }
    return true;
  };

  const validationPassWord = (password: string): string => {
    if (_.trim(password).length < 1) {
      return 'success';
    }

    if (!useComplexity) {
      return 'success';
    }

    const minLength = 12;
    const maxLength = 30;
    const repeatedChars = 3;

    const pwRegEx = RegExp('^.*(?=^.{12,30}$)(?=.*\\d)(?=.*[a-zA-Z])(?=.*[!@#$%^&+=]).*$');
    if (!pwRegEx.test(password)) {
      return (
        '비밀번호는 영문자, 숫자, 특수문자를 각각 하나 이상 포함한 ' +
        minLength +
        '자~' +
        maxLength +
        '자로 생성해야 합니다.'
      );
    }

    const repeatedRegex = RegExp('(.)\\1{' + (repeatedChars - 1) + ',}');
    if (repeatedRegex.test(password)) {
      return '동일한 문자를 ' + repeatedChars + '번 이상 입력할 수 없습니다.';
    }

    return 'success';
  };

  const checkRole = (selectRoleIds: string[]): boolean => {
    if (selectRoleIds.length < 1) {
      return true;
    }

    if (
      permissionList === undefined ||
      permissionList.length < 1 ||
      roleList === undefined ||
      roleList.length < 1
    ) {
      return true;
    }

    const permissionsIds = roleList
      .filter((role) => selectRoleIds.includes(role.id))
      .flatMap((role) => role.permission)
      .map((per) => per.resourceId);

    if (permissionsIds.includes('eval') && permissionsIds.includes('eval_master')) {
      return false;
    }

    return true;
  };

  const validationRules = {
    code: _.trim(reqDto.code).length > 0 && validationId(reqDto.code),
    name: _.trim(reqDto.name).length > 0,
    email: _.trim(reqDto.email).length > 0,
    userRole: reqDto.userRole.length > 0 && checkRole(reqDto.userRole),
    password:
      (editForm && _.trim(passwordCheck.password).length < 1) ||
      (_.trim(passwordCheck.password).length > 0 &&
        validationPassWord(_.trim(passwordCheck.password)) === 'success'),
    verifyPassword:
      (editForm && _.trim(passwordCheck.verifyPassword).length < 1) ||
      (_.trim(passwordCheck.verifyPassword).length > 0 &&
        passwordCheck.password === passwordCheck.verifyPassword),
  };

  const handleChangeInputEvt = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setReqDto({ ...reqDto, [e.target.name]: e.target.value });
  };

  const handleChangePassWordEvt = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setPasswordCheck({ ...passwordCheck, [e.target.name]: e.target.value });
    setReqDto({ ...reqDto, password: e.target.value });
  };

  const handleSubmit = (): void => {
    setSubmit(true);

    if (!validateAll(validationRules)) {
      alert('필수 값을 입력해주세요.');
      return;
    }

    if (editForm) {
      userSettingApi
        .update(id, reqDto)
        .then((resp) => {
          toast.info(t('commons:message.edit.success'));
          history.push(`/admin-user/${id}/show`);
        })
        .catch((err) => alert(err));
    } else {
      userSettingApi
        .create(reqDto)
        .then((resp) => {
          toast.info(t('commons:message.add.success'));
          history.push(`/admin-user/${reqDto.code}/show`);
        })
        .catch((err) => alert(err));
    }
  };
  const handleCancel = (): void => {
    if (editForm) {
      history.push(`/admin-user/${id}/show`);
    } else {
      history.push(`/admin-user`);
    }
  };

  useEffect(() => {
    if (roleList === undefined) {
      roleApi
        .list()
        .then((resp) => {
          setRoleList(resp);
          if (!editForm) {
            setReqDto({ ...reqDto, userRole: [resp[0].id] });
          }
        })
        .catch((error) => {
          setRoleList([]);
          if (!editForm) {
            confirm(`${error.message}`, (result) => history.push(`/admin-user`));
          }
        });
    }

    if (permissionList === undefined) {
      roleApi
        .listResource()
        .then((resp) => {
          setPermissionList(resp);
        })
        .catch((error) => {
          setRoleList([]);
        });
    }

    if (useComplexity === undefined) {
      settingApi.managerSettingApi
        .list()
        .then((resp) => {
          const reqManagerDic = Object.fromEntries(resp.map((dto) => [dto.id, dto.val]));
          const complexity = reqManagerDic['password.complexity'];
          setUseComplexity(complexity !== undefined && complexity === 'false' ? false : true);
        })
        .catch((error) => {
          setUseComplexity(true);
        });
    }

    if (editForm && dto === undefined) {
      userSettingApi
        .find(id)
        .then((resp) => {
          // setDto(resp);
          // setReqDto({ ...resp, password: '' });
        })
        .catch((err) => alert(err));
    }
  }, [alert, confirm, dto, editForm, history, id, permissionList, reqDto, roleList, useComplexity]);

  if ((editForm && !dto) || !roleList || !permissionList) {
    return null;
  }

  return (
    <div className="user-form-root common-page-root">
      <div className="common-page-header">
        <Grid spacing={0}>
          <GridItem classes="common-page-grid-left" xs={12}>
            <ol className="common-page-header-breadcrumbs">
              <li>
                <RouterLink to="/admin-user">사용자 설정</RouterLink>
              </li>
              <li className="sep">
                <BreadcrumbArrowIcon />
              </li>
            </ol>
            <div className="common-page-header-title">{editForm ? dto?.name : '사용자 등록'}</div>
          </GridItem>
        </Grid>
      </div>
      <div className="common-page-body">
        <div className="form-page-root">
          <FormGroup spacing={20}>
            <FormLabel>
              아이디 <span className="warning">*</span>
            </FormLabel>
            <TextField
              name="code"
              placeholder="사용자 아이디를 입력해주세요."
              maxLength={128}
              error={submit && !validationRules.code}
              value={reqDto.code}
              onChange={handleChangeInputEvt}
              disabled={editForm}
            />
            {submit && reqDto.code.length > 0 && !validationId(reqDto.code) ? (
              <span className="warning txt-word-break-all ">
                아이디는 영문, 숫자, _ 으로만 작성해주세요.
              </span>
            ) : (
              ''
            )}
          </FormGroup>
          <FormGroup spacing={20}>
            <FormLabel>
              이메일 <span className="warning">*</span>
            </FormLabel>
            <TextField
              name="email"
              placeholder="사용자 이메일을 입력해주세요."
              maxLength={128}
              error={submit && !validationRules.email}
              value={reqDto.email}
              onChange={handleChangeInputEvt}
            />
          </FormGroup>
          <FormGroup spacing={20}>
            <FormLabel>
              이름 <span className="warning">*</span>
            </FormLabel>
            <TextField
              name="name"
              placeholder="사용자 이름을 입력해주세요."
              maxLength={128}
              error={submit && !validationRules.name}
              value={reqDto.name}
              onChange={handleChangeInputEvt}
            />
          </FormGroup>

          <FormGroup spacing={20}>
            <FormLabel>
              비밀번호 <span className="warning">*</span>
            </FormLabel>
            <TextField
              name="password"
              type="password"
              placeholder="비밀번호를 입력해주세요."
              maxLength={128}
              error={submit && !validationRules.password}
              value={passwordCheck.password}
              onChange={handleChangePassWordEvt}
            />
            {!validationRules.password &&
            validationPassWord(passwordCheck.password) !== 'success' ? (
              <span className="warning txt-word-break-all">
                {validationPassWord(passwordCheck.password)}
              </span>
            ) : (
              ''
            )}
          </FormGroup>
          <FormGroup spacing={20}>
            <FormLabel>
              비밀번호 재확인 <span className="warning">*</span>
            </FormLabel>
            <TextField
              name="verifyPassword"
              type="password"
              placeholder="비밀번호를 재확인해주세요."
              maxLength={128}
              error={submit && !validationRules.verifyPassword}
              value={passwordCheck.verifyPassword}
              onChange={handleChangePassWordEvt}
            />
            {!validationRules.verifyPassword && passwordCheck.verifyPassword.length > 0 ? (
              <span className="warning txt-word-break-all ">비밀번호가 동일하지 않습니다.</span>
            ) : (
              ''
            )}
          </FormGroup>

          <FormGroup spacing={20}>
            <FormLabel>
              권한 템플릿 <span className="warning">*</span>
            </FormLabel>
            {editForm && roleList.length < 1 ? (
              <FormLabel>
                {reqDto.userRoleName !== undefined &&
                reqDto.userRoleName !== null &&
                reqDto.userRoleName.length > 0
                  ? reqDto.userRoleName.map((name) => name).join(', ')
                  : ''}
              </FormLabel>
            ) : (
              <React.Fragment>
                <ExpansionDiv
                  force={false}
                  title={
                    reqDto.userRole.length === roleList.length
                      ? t('commons:all')
                      : roleList
                          .filter((role) => reqDto.userRole.includes(role.id))
                          .map((selected) => selected.name)
                          .join(', ')
                  }
                >
                  <CheckboxGroup>
                    <CheckboxGroupChild depth={1}>
                      <Checkbox
                        label={t('commons:all')}
                        checked={reqDto.userRole.length === roleList.length}
                        onChange={(name, value, checked) => {
                          checked === true
                            ? setReqDto({ ...reqDto, userRole: roleList.map((role) => role.id) })
                            : setReqDto({ ...reqDto, userRole: [] });
                        }}
                      />
                    </CheckboxGroupChild>
                    {roleList.map((role) => (
                      <CheckboxGroupChild depth={2} key={role.name}>
                        <Checkbox
                          name={role.name}
                          label={role.name}
                          checked={reqDto.userRole.includes(role.id)}
                          onChange={(name, value, checked) => {
                            checked === true
                              ? setReqDto({ ...reqDto, userRole: reqDto.userRole.concat(role.id) })
                              : setReqDto({
                                  ...reqDto,
                                  userRole: reqDto.userRole.filter((roleId) => roleId !== role.id),
                                });
                          }}
                        />
                      </CheckboxGroupChild>
                    ))}
                  </CheckboxGroup>
                </ExpansionDiv>

                {submit &&
                  (reqDto.userRole.length < 1 ? (
                    <span className="warning txt-word-break-all ">
                      권한을 1개 이상 선택해주세요.
                    </span>
                  ) : !checkRole(reqDto.userRole) ? (
                    <span className="warning txt-word-break-all ">
                      최종 적정성과 적정성 권한을 모두 가질 수 없습니다.
                    </span>
                  ) : (
                    ''
                  ))}
              </React.Fragment>
            )}
          </FormGroup>
          <FormGroup spacing={20}>
            <FormLabel>소속</FormLabel>
            <TextField
              name="company"
              placeholder="소속을 입력해주세요."
              maxLength={128}
              value={reqDto.company}
              onChange={handleChangeInputEvt}
            />
          </FormGroup>
          <FormGroup spacing={20}>
            <FormLabel>부서</FormLabel>
            <TextField
              name="dept"
              placeholder="부서를 입력해주세요."
              maxLength={128}
              value={reqDto.dept}
              onChange={handleChangeInputEvt}
            />
          </FormGroup>
          <FormGroup spacing={20}>
            <FormLabel>전화번호</FormLabel>
            <TextField
              name="phone"
              placeholder="010-0000-0000"
              maxLength={128}
              value={reqDto.phone}
              onChange={handleChangeInputEvt}
            />
          </FormGroup>
          <FormGroup spacing={20}>
            <FormLabel>설명</FormLabel>
            <TextField
              name="comments"
              placeholder="설명을 입력해주세요."
              maxLength={128}
              value={reqDto.comments}
              onChange={handleChangeInputEvt}
            />
          </FormGroup>
        </div>
      </div>
      <div className="common-page-footer">
        <Grid spacing={0}>
          <GridItem xs={12} classes="common-page-grid-right">
            <Button color="secondary" text="취소" onClick={handleCancel} />
            <Button text="확인" onClick={handleSubmit} />
          </GridItem>
        </Grid>
      </div>
    </div>
  );
};

export default UserSettingForm;
