import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';

import { useAppDispatch } from 'src/store';
import {
  SignInReqEntity,
  selectAuthEntity,
  selectIsAuthenticated,
  thunkSignIn,
  thunkSignOut,
  thunkSignInWithToken,
  selectIsProgressSignOut,
  startSignOut,
  finishSignOut,
} from 'src/store/auth/auth';

// # hooks
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const useAuthStore = () => {
  const authEntity = useSelector(selectAuthEntity);
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const isProgressSignOut = useSelector(selectIsProgressSignOut);

  const dispatch = useAppDispatch();

  const boundSignIn = useCallback(
    (reqEntity: SignInReqEntity) => dispatch(thunkSignIn(reqEntity)).then(unwrapResult),
    [dispatch]
  );
  const boundSignInWithToken = useCallback(
    (token: string) => dispatch(thunkSignInWithToken(token)).then(unwrapResult),
    [dispatch]
  );
  const boundSignOut = useCallback(() => dispatch(thunkSignOut()).then(unwrapResult), [dispatch]);
  const boundStartSignOut = useCallback(() => dispatch(startSignOut()), [dispatch]);
  const boundFinishSignOut = useCallback(() => dispatch(finishSignOut()), [dispatch]);

  return {
    authEntity,
    isAuthenticated,
    isProgressSignOut,
    signIn: boundSignIn,
    signInWithToken: boundSignInWithToken,
    signOut: boundSignOut,
    startSignOut: boundStartSignOut,
    finishSignOut: boundFinishSignOut,
  };
};

export default useAuthStore;
