import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { CommonProps } from 'src/components/helpers/props';

import SearchBarIcon from 'src/components/atom/icons/SearchBarIcon';
import TextField from 'src/components/atom/TextField';
import makeChildrenWithProps from 'src/shared/react-utils/makeChildrenWithProps';

import './index.scss';

export interface SearchBarProps extends CommonProps {
  value?: string;
  placeholder?: string;
  disabled?: boolean;
  fullWidth?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onSearch?: (searchValue: string) => void;
  onClick?: () => void;
  children?: React.ReactNode;
  noIcon?: boolean;
  readOnly?: boolean;
}

const SearchBar: React.FC<SearchBarProps> = ({
  value: defaultValue = '',
  placeholder = '검색어를 입력하세요',
  disabled = false,
  fullWidth,
  noIcon = false,
  readOnly = false,
  onChange,
  onSearch,
  onClick,
  children,
  classes,
}) => {
  const [value, setValue] = useState(defaultValue);

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  children = makeChildrenWithProps(children, {
    autoWidth: true,
    listMinWidth: 88,
    listMaxWidth: 184,
  });

  return (
    <div
      className={classNames(
        'searchbar-root',
        {
          'searchbar-root-fullwidth': fullWidth,
        },
        classes
      )}
    >
      <div className="input-area" onClick={onClick}>
        <TextField
          placeholder={placeholder}
          value={value}
          onChange={(e) => {
            setValue(e.target.value);
            if (onChange) {
              onChange(e);
            }
          }}
          disabled={disabled}
          readOnly={readOnly}
          onKeyPress={(e) => {
            if (e.key === 'Enter' && onSearch) onSearch(value);
          }}
          startIcon={
            noIcon === false && <SearchBarIcon selected={value.length > 0 ? true : false} />
          }
        />
      </div>
      <div className="btn-area">{children}</div>
    </div>
  );
};

export default SearchBar;
