const envPublicUrl =
  process.env.PUBLIC_URL && process.env.PUBLIC_URL !== '/' ? process.env.PUBLIC_URL : '';

export default {
  // baseUrl: 'http://fsp4.dev.fasoo.com:7000',
  contextPath: envPublicUrl ? envPublicUrl : '/',
  url: (subUrl: string) => {
    // console.log('XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX[', envPublicUrl,'][', subUrl,'][', process.env.PUBLIC_URL,']')
    return `${envPublicUrl}${subUrl}`
  },
  autoLogin: {
    auto: true,
    userId: 'test',
    password: 'test1234',
  },
  servers: {
    gateway: {
      enabled: true,
      // enabled: true,
      // url: 'http://fsp4.dev.fasoo.com:7000/gateway',
      url: '/',
    },
    policy: {
      enabled: false,
      url: '',
    },
    fsp: {
      enabled: false,
      // url: 'http://fsp-service/fsp',
      url: 'http://policy-service/policy',
    },
    infra: {
      enabled: false,
      // url: 'http://infra-service/infra',
      url: 'http://fsp4.dev.fasoo.com:7000/gateway/infra',
    },
    fidp: {
      enabled: false,
      url: 'http://fsp4.dev.fasoo.com:7000/gateway/fidp',
    },
    flas: {
      enabled: false,
      url: 'http://fsp4.dev.fasoo.com:7000/gateway/flas',
    },
    log: {
      enabled: false,
      version: 1,
      periodDays: 14,
      url: '/flas',
      url2: '/flas',
    },
    auth: {
      // enabled: false,
      enabled: true,
      url: 'http://fsp4.dev.fasoo.com:7000/gateway/auth',
    },
    fxm: {
      enabled: false,
      url: 'http://fsp4.dev.fasoo.com:7000/gateway/fast',
    },
    issuance: {
      enabled: false,
      url: 'http://fsp4.dev.fasoo.com:7000/gateway/issuance',
    },
    pre: {
      enabled: true,
      url: 'http://61.250.91.38:9090/',
      version: 1,
    },
    join: {
      enabled: true,
      url: 'http://61.250.91.38:8080/',
      version: 1,
    },
    manager: {
      enabled: true,
      url: '/api',
      url2: '/api',
    },
    app: {
      enable: true,
      url: '/app/',
      url2: '/app',
    },
    file: {
      enabled: true,
      url2: '/file',
    },
    systemLogAPI: {
      enabled: true,
      url: '/sapi',
      url2: '/sapi',
    },
    systemLogFT: {
      enabled: true,
      url: '/sft',
      url2: '/sft',
    },
    systemLogManager: {
      enabled: true,
      url: '/smanager',
      url2: '/smanager',
    },
  },
};
