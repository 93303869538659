import React, { useEffect, useState, useContext, Fragment } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import ToolTip from 'src/components/atom/ToolTip';
import { CommonProps } from 'src/components/helpers/props';
import { getAdidManagerUser } from 'src/shared/utils/sessionStorageManager';
import Popup2 from 'src/components/molecules/Popup2';
import templateApi, { RequestTaskDto, TemplateDto, TaskDto, DetailDto, createRequestDto, createTemplate, createAudit, createDetail, AuditDto, } from 'src/api/templateApi';
import Button from 'src/components/atom/Button';
import TextField from 'src/components/atom/TextField';
import FormLabel from 'src/components/atom/FormLabel';
import FormGroup from 'src/components/atom/FormGroup';
import { MemberDto, } from 'src/api/userSettingApi';
import { ReviewMemberDto } from 'src/api/templateApi';
import Select, { SelectItem } from 'src/components/atom/Select';
import { validateAll } from 'src/shared/utils/validations';
import { useAlert } from 'src/contexts/AlertContext';
import { ProjectContext } from '../Category';
import './RequestTaskPopup.scss';

interface RequestTaskPopupProps extends CommonProps {
  open: boolean;
  categoryName: string;
  taskName: string;
  requestTaskDto: TaskDto;
  onConfirm: (requestTask: RequestTaskDto) => void;
  onClose: () => void;
}


const RequestTaskPopup: React.FC<RequestTaskPopupProps> = ({
  open,
  categoryName,
  taskName,
  requestTaskDto,
  onClose,
  onConfirm,
  classes,
}) => {
  const { t } = useTranslation(['commons']);
  const { projectInfo } = useContext(ProjectContext);
  const [memberList, setMemberList] = useState<MemberDto[]>(projectInfo.member_list);
  const [selectedMembers, setSelectedMembers] = useState<ReviewMemberDto[]>([]);
  const [updateDto, setUpdateDto] = useState<RequestTaskDto>(createRequestDto(requestTaskDto));
  const [firstReviewers, setFirstReviewer] = useState<ReviewMemberDto[]>([]);
  const [secondReviewers, setSecondReviewer] = useState<ReviewMemberDto[]>([]);
  const [finalReviewers, setFinalReviewer] = useState<ReviewMemberDto[]>([]);
  const { alert2 } = useAlert();

  const validationRules = {
    // id: _.trim(project.id).length > 0 && validationId(project.id),
    // name: _.trim(form.name).length > 0,
    finalReviewersRole: finalReviewers.length > 0,
  };

  const handleOk = (): void => {
    console.log("updateDto : ", updateDto.reviewer_list)
    if (!validateAll(validationRules)) {
      alert2('일괄 리뷰 요청', '3차 리뷰어(최종)는 꼭 지정되어야 합니다.');
      return;
    }
    onConfirm(updateDto);
  };

  useEffect(() => {
    let newMember: ReviewMemberDto = { account: '', nickname: '', review_stage: '' }
    const first = projectInfo.member_list.filter(elem => elem.role.role_list[0] === "In Charge")
    const second = projectInfo.member_list.filter(elem => elem.role.role_list[0] === "Engagement Manager")
    const final = projectInfo.member_list.filter(elem => elem.role.role_list[0] === "Engagement Partner")
    console.log("first, seconde, final : ", first, second, final)
    newMember = { ...first[0], review_stage: "1" }
    const newFirstReviewers = first[0] ? [newMember] : []
    newMember = { ...second[0], review_stage: "2" }
    const newSecondReviewers = second[0] ? [newMember] : []
    newMember = { ...final[0], review_stage: "final" }
    const newFinalReviewers = final[0] ? [newMember] : []
    setFirstReviewer([...newFirstReviewers])
    setSecondReviewer([...newSecondReviewers])
    setFinalReviewer([...newFinalReviewers])
    setSelectedMembers([...newFirstReviewers, ...newSecondReviewers, ...newFinalReviewers]);
  }, [])

  useEffect(() => {
    const newMemberList = projectInfo.member_list.filter((elem) => elem.account !== firstReviewers[0]?.account &&
      elem.account !== secondReviewers[0]?.account &&
      elem.account !== finalReviewers[0]?.account);
    setMemberList(newMemberList);
  }, [firstReviewers, secondReviewers, finalReviewers]);

  useEffect(() => {
    setUpdateDto(updateDto => ({
      ...updateDto,
      reviewer_list: selectedMembers
    }));
  }, [selectedMembers])

  const handleDelete = (member: string, stage: string) => {
    console.log("member : ", member)
    console.log("selectedMembers : ", selectedMembers)
    const list = selectedMembers.filter((elem) => elem.nickname !== member);
    console.log("list : ", list)
    setSelectedMembers(list);
    if (stage === "1") {
      const list = firstReviewers.filter((elem) => elem.nickname !== member);
      setFirstReviewer(list)
    } else if (stage === "2") {
      const list = secondReviewers.filter((elem) => elem.nickname !== member);
      setSecondReviewer(list)
    } else if (stage === "final") {
      const list = finalReviewers.filter((elem) => elem.nickname !== member);
      setFinalReviewer(list)
    }
  }

  const handleSelectMember = (member: string, stage: string) => {
    console.log("member : ", member)
    //const list = selectedMembers.concat(memberList.filter((elem) => elem.account === member))
    const list = memberList.filter((elem) => elem.account === member)
    let newMember: ReviewMemberDto = { account: '', nickname: '', review_stage: '' }
    newMember = { ...list[0], review_stage: stage }
    if (stage === "1") {
      setFirstReviewer([newMember])
    } else if (stage === "2") {
      setSecondReviewer([newMember])
    } else if (stage === "final") {
      setFinalReviewer([newMember])
    }
    const reviewer_list: ReviewMemberDto[] = [...selectedMembers, newMember]
    console.log("reviewer_list : ", reviewer_list)
    setSelectedMembers(reviewer_list)
  }

  const handleComment = (review_comment: string) => {
    setUpdateDto({
      ...updateDto,
      review_comment
    })
  }

  return (
    <Popup2
      classes="audit-popup-root project-popup-root project-common"
      title="일괄 리뷰 요청"
      width="1112px"
      height="692px"
      open={open}
      onClose={onClose}
      footer={
        <React.Fragment>
          <Button text={t('commons:cancel')} onClick={onClose} color="secondary" />
          <Button
            text={'확인'}
            onClick={handleOk}
          // disabled={!selectedUsers} 
          />
        </React.Fragment>
      }
    >
      <div className="project-input-area">
        {/* <FormGroup classes="no-shrink">
          <FormLabel classes='input-label'>세부 TASK</FormLabel>
          <TextField classes='input-area' value={detailName} disabled={true} />
        </FormGroup> */}
        <FormGroup classes="no-shrink chip-input">
          <FormLabel classes='input-label'>1차 리뷰어</FormLabel>
          <Select key="fisrt"
            classes="input-select"
            // width={120}
            autoWidth={true}
            selectFirstItem={false}
            selectedEventKey={''}
            // placeholder=' '
            textAlign='center'
            align='left'
            border={false}
            enableMultiSelect={true}
            //multiSelectList={selectedMembers.map((elem) => (elem.nickname))}
            multiSelectList={firstReviewers.map((elem) => (elem.nickname))}
            onClick={(item) => handleSelectMember(item, '1')}
            onDelete={(item) => handleDelete(item, '1')}
          >
            {memberList && memberList.map((elem) => (
              <SelectItem key={elem.account} eventKey={elem.account} onClick={(item) => handleSelectMember(item.selectedEventKey, '1')}>
                {elem.nickname}
              </SelectItem>
            ))}
          </Select>
        </FormGroup>
        <FormGroup classes="no-shrink chip-input">
          <FormLabel classes='input-label'>2차 리뷰어</FormLabel>

          <Select key="second"
            classes="input-select"
            // width={120}
            autoWidth={true}
            selectFirstItem={false}
            selectedEventKey={''}
            //placeholder=' '
            textAlign='center'
            align='left'
            border={false}
            enableMultiSelect={true}
            multiSelectList={secondReviewers.map((elem) => (elem.nickname))}
            onClick={(item) => handleSelectMember(item, '2')}
            onDelete={(item) => handleDelete(item, '2')}
          >
            {memberList && memberList.map((elem) => (
              <SelectItem key={elem.account} eventKey={elem.account} onClick={(item) => handleSelectMember(item.selectedEventKey, '2')}>
                {elem.nickname}
              </SelectItem>
            ))}
          </Select>
        </FormGroup>
        <FormGroup classes="no-shrink chip-input">
          <FormLabel classes='input-label'>최종 리뷰어</FormLabel>
          <Select key="final"
            classes="input-select"
            // width={120}
            autoWidth={true}
            selectFirstItem={false}
            selectedEventKey={''}
            //placeholder=' '
            textAlign='center'
            align='left'
            border={false}
            enableMultiSelect={true}
            multiSelectList={finalReviewers.map((elem) => (elem.nickname))}
            onClick={(item) => handleSelectMember(item, 'final')}
            onDelete={(item) => handleDelete(item, 'final')}
          >
            {memberList && memberList.map((elem) => (
              <SelectItem key={elem.account} eventKey={elem.account} onClick={(item) => handleSelectMember(item.selectedEventKey, 'final')}>
                {elem.nickname}
              </SelectItem>
            ))}
          </Select>
        </FormGroup>
        <FormGroup classes="no-shrink comment">
          <FormLabel classes='input-label'><div>의견</div></FormLabel>
          <textarea className='input-area' value={updateDto.review_comment} onChange={(e) => handleComment(e.target.value)} />
        </FormGroup>
      </div>
    </Popup2>
  );
};

export default RequestTaskPopup;
