import React, { useEffect, useRef } from 'react';
import classNames from 'classnames';
import _ from 'lodash';

import { CommonProps } from 'src/components/helpers/props';

import './index.scss';

export interface RadioProps extends CommonProps {
  id?: string;
  name?: string;
  label?: React.ReactNode;
  value?: string;
  selectedValue?: string;
  checked?: boolean;
  disabled?: boolean;
  onChange?: (value: string) => void;
  onChangeEvent?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const Radio: React.FC<RadioProps> = ({
  id,
  name,
  label,
  value,
  selectedValue,
  checked,
  disabled = false,
  onChange,
  onChangeEvent,
  classes,
}) => {
  if (selectedValue !== undefined) {
    checked = value === selectedValue;
  }
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (onChange) {
      onChange(e.target.value);
    }
    else if (onChangeEvent) {
      onChangeEvent(e);
    }
  };

  return (
    <div
      className={classNames(
        'radio-root',
        {
          checked: checked,
          disabled: disabled,
        },
        classes
      )}
    >
      <label>
        <input
          type="radio"
          name={name}
          value={value}
          checked={checked}
          disabled={disabled}
          onChange={handleChange}
        />
        {label && <span className="radio-label">{label}</span>}
      </label>
    </div>
  );
};

export default Radio;
