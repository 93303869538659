/* eslint-disable @typescript-eslint/camelcase */
import _ from 'lodash';

import config from 'src/shared/config';
import axios from 'src/shared/utils/axios';

import DummyApi from '../dummyApi';
import { times } from '../api';

const INFRA_API_ENABLED = config.servers.infra.enabled;
const INFRA_API_URL = config.servers.infra.url;

interface Dept {
  code: string;
  name: string;
}

interface AccessIp {
  fromIp: string;
  toIp: string;
  updatedTime: number;
}

export interface AdminDto {
  user: {
    id: string;
    name: string;
    position: string;
    role: string;
    email: string;
    phoneNumber: string;
  };
  type: {
    code: string;
    name: string;
    desc: string;
    menus: string[];
  };
  depts: Dept[];
  createdTime: number;
  restrictIp: boolean;
  accessIps: AccessIp[];
  startTime: number;
  endTime: number;
}

export enum AdminApiError {
  NOT_FOUND_ADMINASSIGNMENT = 'NOT_FOUND_ADMINASSIGNMENT',
}

interface AdminApi {
  assignments(adminId: string): Promise<AdminDto>;
}

class AdminServerApi implements AdminApi {
  async assignments(adminId: string): Promise<AdminDto> {
    const resp = await axios.get(`${INFRA_API_URL}/api/admin/assignments/${adminId}`);
    return resp.data;
  }
}

class AdminDummyApi implements AdminApi {
  assignments(adminId: string): Promise<AdminDto> {
    const dto: AdminDto = {
      user: {
        id: 'admin',
        name: 'Administrator',
        position: '사원',
        role: '일반',
        email: 'pgkang@fasoo.com',
        phoneNumber: '',
      },
      type: {
        code: 'DEFAULT_AT_CODE',
        name: '기본 전사 관리자',
        desc: '기본으로 제공되는 전사 관리자 권한입니다.',
        menus: [
          'organui-organ',
          'organui-position',
          'organui-role',
          'organui-sett-auth',
          'deviceui-listall',
          'approvalui-approval',
          'approvalui-history',
          'approvalui-approver',
          'approvalui-sett',
          'auditui-listall',
          'auditui-sett',
          'adminui-type',
          'adminui-assign',
          'keystoreui-gen',
          'keystoreui-mng',
          'fspui-home',
          // 'fspui-poltpl',
          'fspui-poltpl-basic',
          'fspui-poltpl-detection',
          'fspui-poltpl-wat',
          // 'fspui-policy',
          'fspui-policy-policies',
          'fspui-policy-review',
          // 'fspui-except',
          'fspui-except-urls',
          'fspui-except-programs',
          'fspui-except-printers',
          // 'fspui-log',
          'fspui-log-printings',
          'fspui-log-detections',
          'fspui-log-keywords',
          // 'fspui-statistics',
          'fspui-statistics-user',
          'fspui-statistics-group',
          'fspui-statistics-program',
          'fspui-statistics-printerdriver',
          'fspui-statistics-pattern',
          'fspui-statistics-encryption',
          // 'fspui-etc',
          'fspui-etc-mgmt',
          'fspui-etc-setting',
        ],
      },
      depts: [],
      createdTime: 1596675468000,
      restrictIp: false,
      accessIps: [],
      startTime: -1,
      endTime: -1,
    };

    // GET /admin/assignments/${adminId} API
    return Promise.resolve(dto);
  }
}

const adminApi: AdminApi = INFRA_API_ENABLED ? new AdminServerApi() : new AdminDummyApi();

export default adminApi;
