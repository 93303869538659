
import React, {useEffect, useState} from 'react';
import classNames from 'classnames';
import IconButton from 'src/components/atom/IconButton';
import MoreIcon  from 'src/components/atom/icons/MoreIcon';
import CircleNextIcon  from 'src/components/atom/icons/CircleNextIcon';
import CirclePrevIcon  from 'src/components/atom/icons/CirclePrevIcon';
import SelectGroup from './SelectGroup';

import './index.scss';
import TextField from 'src/components/atom/TextField';

interface PagingProps {
  data?: string;
  offset: number;
  rowCount: number;
  totalCount: number;
  rowNumbers: string[];
  onClickPageNumber: (num: number) => void;
  onClickRowCount?: (eventKey: string) => void;
}

const Paging: React.FC<PagingProps> = ({data, offset, rowCount, totalCount, rowNumbers, onClickPageNumber, onClickRowCount}) => {

  const getPageList = (): {pageList: number[], left: boolean, start: number, end: number, right: boolean} => {
    const start = Math.floor((offset - 1) / 10) * 10 + 1;
    const end = Math.ceil( totalCount / rowCount) + (start - 1);
    const mod = totalCount / rowCount;

    const length = end - start + 1;
    const pageList = Array.from({length: length> 10? 10: length}, (x, i) => i + start);
    const left = start === 1? false: true;
    const right = ( pageList.length < 10 ||
      ((pageList.length === 10) && (pageList[9] === end) && (mod === 0))) ? false: true;

    // console.log('XXXXXX++++++++', pageList, left, start, end, right)
    return {
      pageList,
      left,
      start,
      end,
      right,
    };
  }

  const {pageList, left, right} = getPageList();

  const [directNum, setDirectNum] = useState<number>(offset);

  useEffect(() => {
    setDirectNum(offset)
  }, [offset])

  const handleGoDirect = (e: React.ChangeEvent<HTMLInputElement>) => {
    let inputNum = parseInt(e.currentTarget.value);
    // if (inputNum > end)
    //   inputNum = end;
    setDirectNum(inputNum)
  }
  const adjRowNumberList = rowNumbers.map(v => v + '개')

  return (
    <div className='paging-root'>
      <div className='go-direct'>
        <TextField
          type='number'
          value={directNum.toString()}
          onChange={handleGoDirect}
          onKeyPress={(e) => {
            if (e.key === 'Enter' && handleGoDirect) onClickPageNumber(directNum)
          }}
          endIcon={
            <IconButton onClick={()=>onClickPageNumber(directNum)}
              unfilled
            >
              <MoreIcon />
            </IconButton>
          }
          />
      </div>
      <div className='page-list'>
        {left &&
          <IconButton onClick={()=>onClickPageNumber(pageList[0] - 1)}
            unfilled
          >
              <CirclePrevIcon/>
          </IconButton>}
        {pageList.map((num, index) => {
          const selected = num === offset? true: false;
          return <div key={'pagingNumber'+index}
            className={classNames('page-list-number', {selected})}
            onClick={(e) =>
              onClickPageNumber(parseInt(e.currentTarget.innerHTML))}>
              {num}
          </div>
        })}
        {right &&
        <IconButton onClick={()=>onClickPageNumber(pageList[9] + 1)}
          unfilled
        >
          <CircleNextIcon/>
        </IconButton>}

      </div>
      <div className='row-number'>
        {rowCount < 10 ?
          <SelectGroup vertical={'down'} items={adjRowNumberList} selected={rowCount.toString() + '개'}
                       onClick={(eventKey?: string) => onClickRowCount && eventKey && onClickRowCount(eventKey.replace('개', ''))}/>
                       :
          <SelectGroup vertical={'up'} items={adjRowNumberList} selected={rowCount.toString() + '개'}
          onClick={(eventKey?: string) => onClickRowCount && eventKey && onClickRowCount(eventKey.replace('개', ''))}/>
        }
      </div>
    </div>
  )
}

export default Paging;
