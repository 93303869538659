import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { NavLink as RouterLink, useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { CommonProps } from 'src/components/helpers/props';
import { useToast } from 'src/contexts/ToastContext';
import { useAlert } from 'src/contexts/AlertContext';

import './ProjectBasePage.scss';
import projectApi2 from 'src/api/projectApi2';
import Grid, { GridItem } from 'src/components/atom/Grid';
import BreadcrumbArrowIcon from 'src/components/atom/icons/BreadcrumbArrowIcon';
import currentUrlUtils from 'src/shared/utils/currentUrlUtils';
import { getAdidManagerUser } from 'src/shared/utils/sessionStorageManager';
import useAuthStore from 'src/hooks/auth/useAuthStore';
import jsUtils from 'src/shared/utils/jsUtils';
import { ProjectDto } from 'src/api/projectApi';

interface ProjectBasePageProps extends CommonProps {
  projectId: string;
  projectName: string;
  projectInfo: ProjectDto;
  userPermissions: string[];
  projectResourceIdSet: Set<string>;
}

const ProjectBasePage: React.FC<ProjectBasePageProps> = ({
  projectId,
  projectName,
  projectInfo,
  userPermissions,
  projectResourceIdSet,
  children,
}) => {
  const { category, taskName, detailName, transType } = useParams<{ category: string, taskName: string, detailName: string, transType: string }>();
  const path = currentUrlUtils.getPath();
  return (
    <div className="project-base-page-root">
      <div className="project-page-header">
        <Grid spacing={0}>
          <GridItem xs={12}>
            <ol className="project-page-header-breadcrumbs">
              <li>
                <RouterLink to={`/projects`}>프로젝트</RouterLink>
              </li>
              <li>
                <BreadcrumbArrowIcon />
              </li>
              <li>
                <RouterLink to={`/projects/${projectId}/detail`}>{projectName}</RouterLink>
              </li>
              {
                category &&
                <Fragment>
                  <li>
                    <BreadcrumbArrowIcon />
                  </li>
                  <li>
                    <RouterLink to={`/projects/${projectId}/category/${category}`}>{category.toUpperCase()}</RouterLink>
                  </li>
                </Fragment>
              }
              {
                taskName &&
                <Fragment>
                  <li>
                    <BreadcrumbArrowIcon />
                  </li>
                  {transType ?
                    <li>
                      <RouterLink to={`/projects/${projectId}/category/${category}/${taskName}/${detailName}`}>{taskName}</RouterLink>
                    </li>
                    :
                    <li>
                      <div className="project-page-header-title">{taskName}</div>
                    </li>
                  }

                </Fragment>
              }
              {
                transType &&
                <Fragment>
                  <li>
                    <BreadcrumbArrowIcon />
                  </li>
                  <li>
                    <div className="project-page-header-title">{detailName}</div>
                  </li>
                </Fragment>
              }
            </ol>
            {/* <div className="project-page-header-title">{projectName}</div> */}
          </GridItem>
        </Grid>
      </div>
      <div className="project-page-tab">
        <ul>
          <li>
            <RouterLink
              to={`/projects/${projectId}/dashboard`}
              isActive={() =>
                path.startsWith(`/projects/${projectId}/dashboard`)
              }
            >
              <div style={{ textAlign: "center" }}>DASHBOARD</div>
            </RouterLink>
          </li>
          <li>
            <RouterLink
              to={`/projects/${projectId}/detail`}
              isActive={() =>
                path.startsWith(`/projects/${projectId}/detail`) ||
                path.startsWith(`/projects/${projectId}/update`)
              }
            >
              <div style={{ textAlign: "center" }}>기본정보</div>
            </RouterLink>
          </li>
          <li>
            <RouterLink
              to={`/projects/${projectId}/category/strategy`}
              isActive={() => path.startsWith(`/projects/${projectId}/category/strategy`)}
            >
              <div style={{ textAlign: "center" }}>STRATEGY</div>
            </RouterLink>
          </li>
          <li>
            <RouterLink
              to={`/projects/${projectId}/category/procedure`}
              isActive={() => path.startsWith(`/projects/${projectId}/category/procedure`)}
            >
              <div style={{ textAlign: "center" }}>PROCEDURE</div>
            </RouterLink>
          </li>
          <li>
            <RouterLink
              to={`/projects/${projectId}/category/conclusion`}
              isActive={() => path.startsWith(`/projects/${projectId}/category/conclusion`)}
            >
              <div style={{ textAlign: "center" }}>CONCLUSION</div>
            </RouterLink>
          </li>
        </ul>
      </div>
      <div className="project-page-body common-page-root project-common">{children}</div>
    </div>
  );
};

export default ProjectBasePage;
