import { createSlice } from '@reduxjs/toolkit';

import { RootState } from 'src/store';

// # types
interface ProgressState {
  count: number;
}

// # initial state
const initialState: ProgressState = {
  count: 0,
};

// # slice
const progressSlice = createSlice({
  name: 'progress',
  initialState,
  reducers: {
    startProgress(state) {
      state.count++;
    },
    stopProgress(state) {
      state.count = Math.max(state.count - 1, 0);
    },
    resetProgress(state) {
      state.count = 0;
    },
  },
});

// # actions
export const { startProgress, stopProgress, resetProgress } = progressSlice.actions;

// # selectors
export const selectInProgress = (state: RootState): boolean => {
  return state.progress.count > 0;
};

export default progressSlice.reducer;
