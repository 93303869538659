import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { CommonProps } from 'src/components/helpers/props';
import LinkIcon from 'src/components/atom/icons/LinkIcon';
import Button from '../Button';
import IconButton from '../IconButton';
import SearchIcon from '../icons/SearchIcon';
import CloseCircleIcon from '../icons/CloseCircleIcon';

import './index.scss';

interface FileInputProps extends CommonProps {
  files: File[];
  defaultFileNames?: string[];
  multiple?: boolean;
  accept?: string;
  onChange: (files: File[]) => void;
}

const FileInput: React.FC<FileInputProps> = ({
  files,
  defaultFileNames,
  multiple,
  accept,
  onChange,
  classes,
  children,
}) => {
  const { t } = useTranslation('commons');
  const fileInput = useRef<HTMLInputElement>(null);

  const handleClick = (): void => {
    fileInput.current && fileInput.current.click();
  };
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const files = e.target.files;
    if (files) {
      onChange(Array.from(files));
    } else {
      onChange([]);
    }
  };

  let fileItems;
  if (files && files.length > 0) {
    fileItems = Array.from(files).map((file, i) => (
      <div className="file-input-unit" key={i}>
        <LinkIcon color="#bcbccb" />
        <span>{file.name}</span>
      </div>
    ));
  } else if (defaultFileNames && defaultFileNames.length > 0) {
    fileItems = defaultFileNames.map((name, i) => (
      <div className="file-input-unit" key={i}>
        <span key={i}>{name}</span>
      </div>
    ));
  } else {
    fileItems = (
      <div className="file-input-unit">
        <span className="file-input-placeholder">{t('commons:noFile')}</span>
      </div>
    );
  }

  return (
    <div className={classNames('file-input-root', classes)}>
      <div className="file-input-selected">{fileItems}</div>
      <div className="file-input-button">
        <Button
          text={"첨부"}
          color="primary"
          //startIcon={<SearchIcon />}
          fullWidth
          onClick={handleClick}
        />
        <input
          type="file"
          multiple={multiple}
          ref={fileInput}
          accept={accept}
          onChange={handleChange}
        />
      </div>
    </div>
  );
};

export default FileInput;
