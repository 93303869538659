import React from 'react';
import classNames from 'classnames';

import { CommonProps } from 'src/components/helpers/props';

interface SystemIconProps extends CommonProps {
  size?: 'md' | 'sm';
}

const SystemIcon: React.FC<SystemIconProps> = ({ classes, size = 'md' }) => {
  if (size === 'md') {
    return (
      <svg
        className={classNames('icon-root', classes)}
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        color="#6B7480"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16.6665 11.6666H3.33317C2.4127 11.6666 1.6665 12.4128 1.6665 13.3333V16.6666C1.6665 17.5871 2.4127 18.3333 3.33317 18.3333H16.6665C17.587 18.3333 18.3332 17.5871 18.3332 16.6666V13.3333C18.3332 12.4128 17.587 11.6666 16.6665 11.6666Z"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5 15H5.00833"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.6665 1.66663H3.33317C2.4127 1.66663 1.6665 2.41282 1.6665 3.33329V6.66663C1.6665 7.5871 2.4127 8.33329 3.33317 8.33329H16.6665C17.587 8.33329 18.3332 7.5871 18.3332 6.66663V3.33329C18.3332 2.41282 17.587 1.66663 16.6665 1.66663Z"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5 5H5.00833"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  } else if (size === 'sm') {
    return (
      <svg
        className={classNames('icon-root', classes)}
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        color="#6B7480"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16.6665 11.6666H3.33317C2.4127 11.6666 1.6665 12.4128 1.6665 13.3333V16.6666C1.6665 17.5871 2.4127 18.3333 3.33317 18.3333H16.6665C17.587 18.3333 18.3332 17.5871 18.3332 16.6666V13.3333C18.3332 12.4128 17.587 11.6666 16.6665 11.6666Z"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5 15H5.00833"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.6665 1.66663H3.33317C2.4127 1.66663 1.6665 2.41282 1.6665 3.33329V6.66663C1.6665 7.5871 2.4127 8.33329 3.33317 8.33329H16.6665C17.587 8.33329 18.3332 7.5871 18.3332 6.66663V3.33329C18.3332 2.41282 17.587 1.66663 16.6665 1.66663Z"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5 5H5.00833"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  } else return null;
};

export default SystemIcon;
