import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import useComponentVisible from 'src/hooks/useComponentVisible';
import makeChildrenWithProps from 'src/shared/react-utils/makeChildrenWithProps';
import getChildrenProps from 'src/shared/react-utils/getChildrenProps';

import { CommonProps } from 'src/components/helpers/props';
import { ChipItem } from 'src/components/atom/ChipInput';

import ArrowDropDownIcon from '../icons/ArrowDropDownIcon';

import './index.scss';
import useComponentWillMount from 'src/hooks/useComponentWillMount';
import ArrowDownLongIcon from '../icons/ArrowDownLongIcon';
import ArrowDownIcon from '../icons/ArrowDownIcon';

interface SelectedItem {
  selectedEventKey: string;
  title: string | React.ReactElement;
}
export interface SelectProps extends CommonProps {
  align?: 'right' | 'left';
  vertical?: 'up' | 'down';
  autoWidth?: boolean;
  width?: number;
  listMinWidth?: number;
  listMaxWidth?: number;
  disabled?: boolean;
  clicked?: boolean;
  children: React.ReactNode;
  leftIcon?: React.FC<CommonProps>;
  selectedEventKey?: string;
  size?: 'md' | 'sm';
  placeholder?: string;
  placeholder2?: string;
  selectFirstItem?: boolean;
  startIcon?: React.ReactElement;
  textAlign?: string;
  border?: boolean;
  enableMultiSelect?: boolean;
  multiSelectList?: string[];
  onClick?: (eventKey: string) => void;
  onDelete?: (eventKey: string) => void;
}

const Select: React.FC<SelectProps> = ({
  align = 'right',
  vertical = 'down',
  autoWidth = false,
  width = 148,
  listMinWidth = 148,
  listMaxWidth,
  disabled = false,
  clicked = false,
  classes,
  children,
  leftIcon: LeftIcon,
  selectedEventKey,
  size = 'md',
  placeholder,
  placeholder2,
  selectFirstItem = true,
  startIcon,
  textAlign = 'left',
  border = true,
  enableMultiSelect = false,
  multiSelectList,
  onClick,
  onDelete,
}) => {
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(clicked);

  const [item, setItem] = useState<SelectedItem>({
    selectedEventKey: '',
    title: '',
  });

  useEffect(() => {
    if (disabled === true && isComponentVisible === true)
      setIsComponentVisible(!isComponentVisible);
  }, [disabled, isComponentVisible, setIsComponentVisible]);

  useComponentWillMount(() => {
    if (!selectFirstItem) return;

    // 최초 Mount될 때 children을 기준으로 setting
    const childrenProps = getChildrenProps(children);
    const selectedItem = childrenProps.length > 0 ? childrenProps[0] : null;

    if (selectedItem) {
      setItem({
        selectedEventKey: selectedItem.eventKey,
        title: selectedItem.children,
      });
    } else {
      setItem({
        selectedEventKey: '',
        title: '',
      });
    }
  });

  useEffect(() => {
    // children이 바뀌면, 현재 선택된 selectedEventKey로 title을 다시 저장한다.
    const childrenProps = getChildrenProps(children);
    const selectedItems = childrenProps.filter(
      (childProps: SelectItemProps) => childProps.eventKey === item.selectedEventKey
    );
    const selectedItem =
      selectedItems.length === 1
        ? selectedItems[0]
        : selectFirstItem
          ? childrenProps.length > 0
            ? childrenProps[0]
            : null
          : null;

    if (selectedItem) {
      setItem({
        selectedEventKey: selectedItem.eventKey,
        title: selectedItem.children,
      });
    } else {
      setItem({
        selectedEventKey: '',
        title: '',
      });
    }
  }, [children, item.selectedEventKey, selectFirstItem]);

  useEffect(() => {
    // 초기값 셋팅을 사용하는 함수의 경우
    if (selectedEventKey) {
      const childrenProps = getChildrenProps(children);
      const selectedItems = childrenProps.filter(
        (childProps: SelectItemProps) => childProps.eventKey === selectedEventKey
      );
      const selectedItem =
        selectedItems.length === 1
          ? selectedItems[0]
          : selectFirstItem
            ? childrenProps.length > 0
              ? childrenProps[0]
              : null
            : null;

      if (selectedItem) {
        setItem({
          selectedEventKey: selectedItem.eventKey,
          title: selectedItem.children,
        });
      } else {
        setItem({
          selectedEventKey: '',
          title: '',
        });
      }
    }
  }, [children, selectFirstItem, selectedEventKey]);

  const handleClick = ({ selectedEventKey, title }: SelectedItem): void => {
    if (onClick) onClick(selectedEventKey);
    setItem({
      selectedEventKey: selectedEventKey,
      title: title,
    });
    setIsComponentVisible(!isComponentVisible);
  };

  const childrenWithProps = makeChildrenWithProps(children, {
    onClick: handleClick,
    startIcon,
    selectedEventKey: item.selectedEventKey,
  });

  return (
    <div
      className={classNames(
        'select-root',
        {
          on: isComponentVisible,
          'select-size-sm': size === 'sm',
          'disabled': disabled
        },
        classes
      )}
      ref={ref}
    >
      {LeftIcon && <LeftIcon classes="select-left-icon" />}
      <div
        className={classNames('button button-end-icon', {
          'text-align-right': textAlign === 'right',
          'no-border': !border
        })}
        onClick={() => {
          !disabled && setIsComponentVisible(!isComponentVisible);
        }}
        // disabled={disabled}
        // className={classNames('select-root', { 'button-end-icon': true })} // select-root 빼야 하지 않을지
        style={{
          width: autoWidth === false ? width : '',
        }}
      >
        {enableMultiSelect ?
          <div className='chipinput-root'>{multiSelectList?.map((elem) => <ChipItem key={elem} onClick={onDelete!}>{elem}</ChipItem>)}</div>
          :
          <span className={classNames('txt-field', { 'placeholder': !item.title })} style={{ height: 24, verticalAlign: "middle", lineHeight: "normal", display: "inline-block" }}>{item.title || placeholder2 || placeholder}</span>
        }
        <ArrowDownIcon />
      </div>
      <div
        className={classNames('select-list', {
          'select-list-right': align === 'right',
          'select-list-left': align === 'left',
          up: vertical === 'up',
        })}
        style={{
          // width: autoWidth === false ? width : '',
          minWidth: autoWidth === false ? width : listMinWidth,
          maxWidth: listMaxWidth,
        }}
      >
        <ul>{childrenWithProps}</ul>
      </div>
    </div>
  );
};

interface SelectItemProps extends CommonProps {
  children: string | React.ReactElement;
  eventKey: string;
  selectedEventKey?: string;
  startIcon?: React.ReactElement;
  onClick?: (selectedItem: SelectedItem) => void;
}

export const SelectItem: React.FC<SelectItemProps> = ({
  children,
  eventKey,
  selectedEventKey,
  startIcon,
  onClick,
}) => {
  return (
    <li className={selectedEventKey === eventKey ? 'selected' : ''}
      onClick={() => {
        if (onClick)
          onClick({
            selectedEventKey: eventKey,
            title: children,
          });
      }}
    >
      {startIcon}
      <span className="select-item">
        {children}
      </span>
    </li>
  );
};
export default Select;
