import React from 'react';
import classNames from 'classnames';

import { CommonProps } from 'src/components/helpers/props';

import './AbstractIcon.scss';

interface CheckCircleIconProps extends CommonProps {
  size?: 'md';
}

const CheckCircleIcon: React.FC<CheckCircleIconProps> = ({ classes, size = 'md' }) => {
  return (
    <svg
      className={classNames('icon-root', classes)}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="#25c16f"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M12 4C7.6 4 4 7.6 4 12C4 16.4 7.6 20 12 20C16.4 20 20 16.4 20 12C20 7.6 16.4 4 12 4ZM12 18.4C8.472 18.4 5.6 15.528 5.6 12C5.6 8.472 8.472 5.6 12 5.6C15.528 5.6 18.4 8.472 18.4 12C18.4 15.528 15.528 18.4 12 18.4ZM15.672 8.464L10.4 13.736L8.328 11.672C7.51743 11.7908 7.25432 12.0291 7.2 12.8L10.4 16L16.8 9.6C16.7777 8.76664 16.4158 8.50047 15.672 8.464Z" />
    </svg>
  );
};

export default CheckCircleIcon;
