import React, { Children, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import update from 'immutability-helper';
import moment from 'moment';

import PageTop from 'src/components/molecules/PageTop';
import userSettingApi, { UserInfoEntity, MemberDto } from 'src/api/userSettingApi';

import ProjectDetail from '../ProjectDetail';

import './index.scss';


interface CreateProjectProps {
  parentTitle?: string;
  toCancel?: () => void;
  setReload?: () => void;
}
const CreateProject: React.FC<CreateProjectProps> = ({ parentTitle, toCancel, setReload }) => {
  return (
    <div className="common-page-root create-project-root">
      <PageTop
        classes="common-page-header project-page-header"
        parentLink={'/projects'}
        parentTitle={'프로젝트'}
        title={' > 프로젝트 생성'}
      />
      <ProjectDetail setReload={setReload} />

    </div>
  );
};

export default CreateProject;
