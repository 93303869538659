import config from 'src/shared/config';
import axios from 'src/shared/utils/axios';

import DummyApi from '../dummyApi';
import { times } from '../api';

import { UseYN } from '.';

const INFRA_API_ENABLED = config.servers.infra.enabled;
const INFRA_API_URL = config.servers.infra.url;

export interface PatternModuleDto {
  code: string;
  name: string;
  description: string;
  useYN: UseYN;
  entryTime: number;

  module: string; // 검증 모듈 파일 이름
  defaultYN: UseYN; // 기본 제공 여부 (Y/N) - 기본 제공 패턴은 수정/삭제할 수 없다.
}

export type PatternModuleReqDto = Omit<PatternModuleDto, 'code' | 'entryTime'>;

interface PatternModuleApi {
  list(): Promise<PatternModuleDto[]>;
  find(code: string): Promise<PatternModuleDto>;
  create(reqDto: PatternModuleReqDto): Promise<PatternModuleDto>;
  update(code: string, reqDto: PatternModuleReqDto): Promise<PatternModuleDto>;
  remove(code: string): Promise<void>;
}

class PatternModuleServerApi implements PatternModuleApi {
  async list(): Promise<PatternModuleDto[]> {
    const resp = await axios.get<PatternModuleDto[]>(`${INFRA_API_URL}/api/pattern-modules`);
    return resp.data;
  }
  async find(code: string): Promise<PatternModuleDto> {
    const resp = await axios.get<PatternModuleDto>(`${INFRA_API_URL}/api/pattern-modules/${code}`);
    return resp.data;
  }
  async create(reqDto: PatternModuleReqDto): Promise<PatternModuleDto> {
    const resp = await axios.post<PatternModuleDto>(`${INFRA_API_URL}/api/pattern-modules`, reqDto);
    return resp.data;
  }
  async update(code: string, reqDto: PatternModuleReqDto): Promise<PatternModuleDto> {
    const resp = await axios.put<PatternModuleDto>(
      `${INFRA_API_URL}/api/pattern-modules/${code}`,
      reqDto
    );
    return resp.data;
  }
  async remove(code: string): Promise<void> {
    await axios.delete<PatternModuleDto>(`${INFRA_API_URL}/api/pattern-modules/${code}`);
  }
}

class PatternModuleDummyApi implements PatternModuleApi {
  dummyApi: DummyApi<PatternModuleDto, PatternModuleReqDto>;
  constructor() {
    const data: PatternModuleDto[] = [
      {
        name: '유효성 검사 모듈 이름',
        description: '설명입니다.',
        useYN: UseYN.Y,

        module: 'f_csregssn.dll',
        defaultYN: UseYN.Y,
      },
      {
        name: '유효성 검사 모듈 이름 2',
        description: '설명입니다 2.',
        useYN: UseYN.Y,

        module: 'f_csregssn.dll',
        defaultYN: UseYN.Y,
      },
      {
        name: '테스트 모듈 이름',
        description: '테스트 모듈 이름 설명입니다.',
        useYN: UseYN.Y,

        module: 'f_test.dll',
        defaultYN: UseYN.N,
      },
    ].map((o, i) => ({ ...o, code: '', entryTime: times(i) }));

    this.dummyApi = DummyApi.of({
      entityName: 'Infra/.PatternModule',
      createDto: (code, entity) => ({ ...entity, code, entryTime: Date.now() }),
      validation: (type, code, reqDto, data) => {
        data.forEach((dto) => {
          if (
            (type === 'create' && dto.name === reqDto.name) ||
            (type === 'update' && dto.code !== code && dto.name === reqDto.name)
          ) {
            throw new Error('이미 사용 중인 이름입니다.');
          }
        });
      },
      data,
    });
  }

  list(): Promise<PatternModuleDto[]> {
    return this.dummyApi.list();
  }
  find(code: string): Promise<PatternModuleDto> {
    return this.dummyApi.find(code);
  }
  create(reqDto: PatternModuleReqDto): Promise<PatternModuleDto> {
    return this.dummyApi.create(reqDto);
  }
  update(code: string, reqDto: PatternModuleReqDto): Promise<PatternModuleDto> {
    return this.dummyApi.update({ code, reqDto });
  }
  remove(code: string): Promise<void> {
    return this.dummyApi.remove(code);
  }
}

const patternModuleApi: PatternModuleApi = INFRA_API_ENABLED
  ? new PatternModuleServerApi()
  : new PatternModuleDummyApi();

export default patternModuleApi;
