import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import domainConfigApi, {
  DomainConfigDto,
  DomainConfigReqDto,
} from 'src/api/infra/domainConfigApi';
import { RootState } from 'src/store';

// # types
export type DomainConfigEntity = DomainConfigDto;
export type DomainConfigReqEntity = DomainConfigEntity;

function toEntity(dto: DomainConfigDto): DomainConfigEntity {
  return { ...dto };
}
function toReqDto(entity: DomainConfigReqEntity): DomainConfigReqDto {
  return { ...entity };
}

interface DomainConfigState {
  initialized: boolean;
  entity: DomainConfigEntity;
}

// # initial state
const initialState: DomainConfigState = {
  initialized: false,
  entity: {
    validDay: 7,
    policyUpdateHour: 2,
    autoLogin: false,
    heartbeatMinute: 10,
  },
};

// # thunks
export const thunkLoadDomainConfig = createAsyncThunk('infra/domainConfig/load', async () => {
  const dto = await domainConfigApi.get();
  return toEntity(dto);
});
export const thunkUpdateDomainConfig = createAsyncThunk(
  'infra/domainConfig/update',
  async (reqEntity: DomainConfigReqEntity) => {
    return toEntity(await domainConfigApi.update(toReqDto(reqEntity)));
  }
);

const domainConfigSlice = createSlice({
  name: 'domainConfig',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(thunkLoadDomainConfig.fulfilled, (state, { payload: domainConfig }) => {
      state.entity = domainConfig;
      state.initialized = true;
    });
    builder.addCase(thunkUpdateDomainConfig.fulfilled, (state, { payload: updatedEntity }) => {
      state.entity = updatedEntity;
    });
  },
});

// # selectors
export const selectInitialized = (state: RootState): boolean => {
  return state.domainConfig.initialized;
};
export const selectDomainConfigEntity = (state: RootState): DomainConfigEntity =>
  state.domainConfig.entity;

export default domainConfigSlice.reducer;
