import config from 'src/shared/config';
import axios, { ApiErrorHelper } from 'src/shared/utils/axios';

import DummyApi from './dummyApi';
import { times } from './api';

import { UserDto, UserInfoDto } from './fidp/userApi';
import { DeptDto } from './fidp/deptApi';

const FSP_API_ENABLED = config.servers.fsp.enabled;
const FSP_API_URL = config.servers.fsp.url;

enum PolicyApiError {
  NOT_FOUND_POLICY = 'NOT_FOUND_POLICY',
}

export interface PolicyDto {
  code: string;
  name: string;
  description: string;
  entryTs: number;
  startTime: number;
  endTime: number;

  basicTemplateCode: string; // 기본 템플릿 코드
  watTemplateCode: string; // 워터마크 템플릿 코드
  detectionTemplateCode: string; // 검출 템플릿 코드

  targetUsers: string[]; // 정책 적용 대상 사용자 ID 목록
  targetOrgans: TargetOrganDto[]; // 정책 적용 대상 부서 목록
}

export interface TargetOrganDto {
  deptCode: string; // 부서 코드
  roleCodes: string[]; // 직무 코드 목록. 전체인 경우 zzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzz
  positionCodes: string[]; // 직책 코드 목록. 전체인 경우 zzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzz
}

export interface TargetUserDto {
  userId: string;
}

export type PolicyViewDto = Omit<PolicyDto, 'targetUsers' | 'targetOrgans'>;

export interface TargetOrganEntity extends TargetOrganDto {
  dept: DeptDto;
}

export interface TargetUserEntity extends TargetUserDto {
  user: UserDto;
}

export type PolicyReqDto = Omit<PolicyDto, 'code' | 'entryTs'>;

export const defaultPolicyReqDto: PolicyReqDto = {
  name: '',
  description: '',

  basicTemplateCode: '',
  watTemplateCode: '',
  detectionTemplateCode: '',

  startTime: -1,
  endTime: -1,

  targetUsers: [],
  targetOrgans: [],
};

interface PolicyApi {
  list(): Promise<PolicyDto[]>;
  find(code: string): Promise<PolicyDto>;
  create(reqDto: PolicyReqDto): Promise<PolicyDto>;
  update(code: string, reqDto: PolicyReqDto): Promise<PolicyDto>;
  remove(code: string): Promise<void>;
  view(userInfo: UserInfoDto): Promise<PolicyViewDto | null>;
}
class PolicyServerApi implements PolicyApi {
  async list(): Promise<PolicyDto[]> {
    const resp = await axios.get<PolicyDto[]>(`${FSP_API_URL}/api/policies`);
    return resp.data;
  }
  async find(code: string): Promise<PolicyDto> {
    const resp = await axios.get<PolicyDto>(`${FSP_API_URL}/api/policies/${code}`);
    return resp.data;
  }
  async create(reqDto: PolicyReqDto): Promise<PolicyDto> {
    const resp = await axios.post<PolicyDto>(`${FSP_API_URL}/api/policies`, reqDto);
    return resp.data;
  }
  async update(code: string, reqDto: PolicyReqDto): Promise<PolicyDto> {
    const resp = await axios.put<PolicyDto>(`${FSP_API_URL}/api/policies/${code}`, reqDto);
    return resp.data;
  }
  async remove(code: string): Promise<void> {
    await axios.delete<PolicyDto>(`${FSP_API_URL}/api/policies/${code}`);
  }
  async view(userInfo: UserInfoDto): Promise<PolicyViewDto | null> {
    try {
      const resp = await axios.post<PolicyViewDto>(
        `${FSP_API_URL}/api/policies/view/simple`,
        userInfo
      );
      return resp.data;
    } catch (error) {
      if (ApiErrorHelper.isErrorCode(error, PolicyApiError.NOT_FOUND_POLICY)) {
        return null;
      }
      throw error;
    }
  }
}

class PolicyDummyApi implements PolicyApi {
  dummyApi: DummyApi<PolicyDto, PolicyReqDto>;
  constructor() {
    const data: PolicyDto[] = [
      {
        name: '일반 사원급 기본 정책A',
        description: '사내 테스트를 위한 기본 정책입니다.',

        basicTemplateCode: 'code-0',
        watTemplateCode: 'code-0',
        detectionTemplateCode: 'code-0',

        targetUsers: ['sryeon', 'admin'],
        targetOrgans: [
          {
            deptCode: 'c137da14690348dcbdf3f4ab752d91f0',
            roleCodes: ['b87928b2982e44ba989cf3446413af2d', 'ab4772ef30e7485ba28d8bfc8350c732'],
            positionCodes: ['8879d94683e64db0ad3c2d3deaebbd4f'],
          },
          {
            deptCode: 'COMPANY',
            roleCodes: ['ab4772ef30e7485ba28d8bfc8350c732'],
            positionCodes: ['8879d94683e64db0ad3c2d3deaebbd4f'],
          },
        ],
        startTime: -1,
        endTime: -1,
      },
      {
        name: '테스트 정책',
        description: '테스트를 위한 기본 정책 입니다.',
        use: true,

        basicTemplateCode: 'code-1',
        watTemplateCode: 'code-1',
        detectionTemplateCode: 'code-1',

        targetUsers: [
          'sryeon',
          'admin',
          'admin2',
          'admin3',
          'admin4',
          'admin5',
          'admin6',
          'admin7',
          'admin8',
          'admin9',
          'admin10',
          'admin11',
          'admin12',
          'admin13',
          'admin14',
          'admin15',
          'admin16',
          'admin17',
          'admin18',
          'admin19',
        ],
        targetOrgans: [
          {
            deptCode: 'c137da14690348dcbdf3f4ab752d91f0',
            roleCodes: ['b87928b2982e44ba989cf3446413af2d'],
            positionCodes: ['8879d94683e64db0ad3c2d3deaebbd4f'],
          },
          {
            deptCode: '18ee01d6a1654c7581a54d470fb6e724',
            roleCodes: ['ab4772ef30e7485ba28d8bfc8350c732'],
            positionCodes: ['8879d94683e64db0ad3c2d3deaebbd4f'],
          },
        ],
        startTime: -1,
        endTime: -1,
      },
    ].map((o, i) => ({ ...o, code: '', entryTs: times(i) }));

    this.dummyApi = DummyApi.of({
      entityName: 'Policy',
      createDto: (code, entity) => ({ ...entity, code, entryTs: Date.now() }),
      validation: (type, code, reqDto, data) => {
        data.forEach((dto) => {
          if (
            (type === 'create' && dto.name === reqDto.name) ||
            (type === 'update' && dto.code !== code && dto.name === reqDto.name)
          ) {
            throw new Error('이미 사용 중인 이름입니다.');
          }
        });
      },
      data,
    });
  }

  list(): Promise<PolicyDto[]> {
    return this.dummyApi.list();
  }
  find(code: string): Promise<PolicyDto> {
    return this.dummyApi.find(code);
  }
  create(reqDto: PolicyReqDto): Promise<PolicyDto> {
    return this.dummyApi.create(reqDto);
  }
  update(code: string, reqDto: PolicyReqDto): Promise<PolicyDto> {
    return this.dummyApi.update({ code, reqDto });
  }
  remove(code: string): Promise<void> {
    return this.dummyApi.remove(code);
  }
  view(userInfo: UserInfoDto): Promise<PolicyViewDto | null> {
    return this.dummyApi.list().then((policys) => {
      let res = policys.find((policy) => policy.targetUsers.includes(userInfo.user.id));
      if (!res) {
        res = policys.find((policy) =>
          policy.targetOrgans.find((organ) => organ.deptCode === userInfo.depts.primary.code)
        );
      }

      if (!res) return Promise.resolve(null);

      const viewRes: PolicyViewDto = {
        code: res.code,
        name: res.name,
        description: res.description,
        startTime: -1,
        endTime: -1,
        entryTs: res.entryTs,
        basicTemplateCode: res.basicTemplateCode,
        watTemplateCode: res.watTemplateCode,
        detectionTemplateCode: res.detectionTemplateCode,
      };

      return Promise.resolve(viewRes);
    });
  }
}

const policyApi: PolicyApi = FSP_API_ENABLED ? new PolicyServerApi() : new PolicyDummyApi();

export default policyApi;
