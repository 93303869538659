import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { UseYN } from 'src/api/infra';
import patternModuleApi, {
  PatternModuleDto,
  PatternModuleReqDto,
} from 'src/api/infra/patternModuleApi';
import { toLocalDateTimeStr } from 'src/shared/utils/localDateTime';
import { RootState } from 'src/store';

// # types
export interface PatternModuleEntity {
  code: string;
  name: string;
  description: string;
  use: boolean;
  entryTsStr: string;

  module: string;
  builtIn: boolean;
}
export type PatternModuleReqEntity = Omit<PatternModuleEntity, 'code' | 'entryTsStr'>;

function toEntity(dto: PatternModuleDto): PatternModuleEntity {
  return {
    code: dto.code,
    name: dto.name,
    description: dto.description,
    use: dto.useYN === UseYN.Y,
    entryTsStr: toLocalDateTimeStr(dto.entryTime),

    module: dto.module,
    builtIn: dto.defaultYN === UseYN.Y,
  };
}
function toReqDto(entity: PatternModuleReqEntity): PatternModuleReqDto {
  return {
    name: entity.name,
    description: entity.description,
    useYN: entity.use ? UseYN.Y : UseYN.N,

    module: entity.module,
    defaultYN: entity.builtIn ? UseYN.Y : UseYN.N,
  };
}

interface PatternModuleEntityByCode {
  [code: string]: PatternModuleEntity;
}
export interface PatternModuleEntities {
  byCode: PatternModuleEntityByCode;
  allCodes: string[];
}
interface PatternModuleState {
  initialized: boolean;
  entities: PatternModuleEntities;
}

export interface PatternModuleUpdateReq {
  code: string;
  reqEntity: PatternModuleReqEntity;
}

// # initial state
const initialState: PatternModuleState = {
  initialized: false,
  entities: { byCode: {}, allCodes: [] },
};

export const defaultPatternModuleReqEntity: PatternModuleReqEntity = {
  name: '',
  description: '',
  use: true,

  module: '',
  builtIn: false,
};

// # thunks
export const thunkLoadPatternModules = createAsyncThunk('infra/patternModule/load', async () => {
  const list = await patternModuleApi.list();
  return list.map((dto) => toEntity(dto));
});
export const thunkCreatePatternModule = createAsyncThunk(
  'infra/patternModule/create',
  async (reqEntity: PatternModuleReqEntity) => {
    return toEntity(await patternModuleApi.create(toReqDto(reqEntity)));
  }
);
export const thunkUpdatePatternModule = createAsyncThunk(
  'infra/patternModule/update',
  async ({ code, reqEntity }: PatternModuleUpdateReq) => {
    return toEntity(await patternModuleApi.update(code, toReqDto(reqEntity)));
  }
);
export const thunkRemovePatternModule = createAsyncThunk(
  'infra/patternModule/remove',
  async (code: string) => {
    return patternModuleApi.remove(code);
  }
);

const patternModuleSlice = createSlice({
  name: 'patternModule',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(thunkLoadPatternModules.fulfilled, (state, { payload: patternModules }) => {
      state.entities.byCode = patternModules.reduce((acc, t) => {
        acc[t.code] = t;
        return acc;
      }, {} as PatternModuleEntityByCode);
      state.entities.allCodes = patternModules.map((t) => t.code);
      state.initialized = true;
    });
    builder.addCase(thunkCreatePatternModule.fulfilled, (state, { payload: createdEntity }) => {
      state.entities.byCode[createdEntity.code] = createdEntity;
      state.entities.allCodes.push(createdEntity.code);
    });
    builder.addCase(thunkUpdatePatternModule.fulfilled, (state, { payload: updatedEntity }) => {
      state.entities.byCode[updatedEntity.code] = updatedEntity;
    });
    builder.addCase(thunkRemovePatternModule.fulfilled, (state, { meta: { arg: removedCode } }) => {
      delete state.entities.byCode[removedCode];
      state.entities.allCodes = state.entities.allCodes.filter((code) => code !== removedCode);
    });
  },
});

// # selectors
export const selectInitialized = (state: RootState): boolean => {
  return state.patternModule.initialized;
};
export const selectPatternModuleEntities = (state: RootState): PatternModuleEntities =>
  state.patternModule.entities;
export const selectPatternModuleEntityList = (state: RootState): PatternModuleEntity[] =>
  state.patternModule.entities.allCodes.map((code) => state.patternModule.entities.byCode[code]);

export default patternModuleSlice.reducer;
