import React, { useCallback, useState, createContext, useEffect } from 'react';

import Toast from '../components/molecules/Toast';

import ModalPortal from 'src/shared/react-utils/ModalPortal';

interface ToastContextResult {
  info: (message: string) => void;
  // warn: (message: string) => void;
  // error: (message: string) => void;
}

interface ToastProps {
  index: number;
  message: string;
  type: 'info' | 'warn' | 'error';
}

const ToastContext = createContext<ToastContextResult>({
  info: (message: string) => console.log(message),
  // warn: (message: string) => console.log(message),
  // error: (message: string) => console.log(message),
});

const ToastProvider = ({ children }: { children: React.ReactNode }): JSX.Element => {
  const [toasts, setToasts] = useState<ToastProps[]>([]);
  const [toastId, setToastId] = useState<number>(0);

  const unSetToast = useCallback(
    (index) => {
      setToasts(toasts.filter((toast) => toast.index !== index));
    },
    [toasts]
  );

  const info = useCallback(
    (message) => {
      setToasts((toasts) => [
        ...toasts,
        {
          index: toastId,
          type: 'info',
          message: message,
        },
      ]);
      // if (toasts.length >= 3) {
      //   setToasts((toasts) => toasts.slice(1));
      // }
      setToastId(toastId + 1);
    },
    [toastId]
  );

  useEffect(() => {
    if (toasts.length > 0) {
      const timer = setTimeout(() => setToasts((toasts) => toasts.slice(1)), 3000);
      return () => clearTimeout(timer);
    } else {
      setToastId(0);
    }
  }, [toasts]);

  // const { type, open, message } = state;

  return (
    <ToastContext.Provider value={{ info }}>
      {children}
      <ModalPortal>
        <div className="toast-root-wapper" style={{ position: 'fixed', left: '25%', right: '25%' }}>
          {toasts.map((toast) => (
            <Toast
              key={toast.index}
              type={toast.type}
              message={toast.message}
              onClose={() => unSetToast(toast.index)}
              open={true}
            >
              {toast.message}
            </Toast>
          ))}
        </div>
      </ModalPortal>
    </ToastContext.Provider>
  );
};

const useToast = (): ToastContextResult => {
  const context = React.useContext(ToastContext);
  if (context === undefined) {
    throw new Error('useToast must be used within a UserProvider');
  }

  return context;
};

export { ToastProvider, useToast };
