import config from 'src/shared/config';
import axios from 'src/shared/utils/axios';
import _ from 'lodash';

import { TokenErrorHandling } from 'src/shared/errorHandling';
import { request } from 'http';

import { getToken } from 'src/shared/utils/sessionStorageManager';

// const M_API_URL = config.servers.manager.url;

export interface ConfigInfo {
  authority_name_list: string[],
  report_standard_list: string[],
  role_list: string[],
  work_feature_list: string[]
}

export interface MainAccountInfo {
  name: string,
  accounting_list: string[],
  transaction_type_list: any[],
  wcgw_list: any[],
  control_list: any[],
}

interface ResponseDto {
  data: {
    config: ConfigInfo;
  },
  error?: string;
}

interface ResponseDto2 {
  data: {
    main_accounting_list: MainAccountInfo;
  },
  error?: string;
}

class configServerApi {
  async getConfig(): Promise<ConfigInfo> {
    const data = {
      data: {
        access_token: getToken(),
      }
    }

    const resp = await axios.post<ResponseDto>(`/api/private/Config`, data);

    if (resp.data !== null) {
      // console.log('resp.data', resp.data);
      // const data = resp.data.data;
      return Promise.resolve(_.cloneDeep(resp.data.data.config));
    }

    return {} as ConfigInfo;
  }

  async getMainAccoutList(template: any): Promise<MainAccountInfo> {
    const data = {
      data: {
        access_token: getToken(),
        //tag_name: "주요계정과목"
        template
      }
    }

    const resp = await axios.post<ResponseDto2>(`/api/private/GetMainAccountList`, data);

    if (resp.data !== null) {
      // console.log('resp.data', resp.data);
      // const data = resp.data.data;
      return Promise.resolve(_.cloneDeep(resp.data.data.main_accounting_list));
    }

    return {} as MainAccountInfo;
  }

  async setMainTransactionTypeList(reqData: any, tag_name: string): Promise<{}> {
    const data = {
      data: {
        access_token: getToken(),
        //tag_name: "주요계정과목"
        ...reqData
      }
    }

    let service = ""
    if (tag_name === "주요거래유형") {
      service = "UpdateMainTransactionTypeList"
    } else if (tag_name === "WCGW") {
      service = "UpdateWCGWList"
    } else if (tag_name === "CONTROL") {
      service = "UpdateControlList"
    }

    console.log("data : ", data)
    try {
      const resp = await axios.post<ResponseDto2>(`/api/private/${service}`, data);

      if (resp.data !== null) {
        // console.log('resp.data', resp.data);
        // const data = resp.data.data;
        return Promise.resolve(_.cloneDeep(resp.data.data));
      }
    } catch (e) {
      console.log("e : ", e)
      return Promise.reject(e);
    }


    return {} as {};
  }


}

const configApi = new configServerApi();

export default configApi;
