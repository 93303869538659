import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { Registration, UseYN } from 'src/api/infra';
import printerApi, { PrinterDto, PrinterReqDto } from 'src/api/infra/printerApi';
import { toLocalDateTimeStr } from 'src/shared/utils/localDateTime';
import { RootState } from 'src/store';

// # types
export type PrinterEntity = Omit<PrinterDto, 'useYN' | 'entryTime'> & {
  use: boolean;
  entryTimeStr: string;
};
export type PrinterReqEntity = Omit<PrinterEntity, 'code' | 'entryTime' | 'entryTimeStr'>;

function toEntity(dto: PrinterDto): PrinterEntity {
  return { ...dto, use: dto.useYN === UseYN.Y, entryTimeStr: toLocalDateTimeStr(dto.entryTime) };
}
function toReqDto(entity: PrinterReqEntity): PrinterReqDto {
  return { ...entity, useYN: entity.use ? UseYN.Y : UseYN.N };
}

interface PrinterEntityByCode {
  [code: string]: PrinterEntity;
}
interface PrinterEntities {
  byCode: PrinterEntityByCode;
  allCodes: string[];
}
interface PrinterState {
  initialized: boolean;
  entities: PrinterEntities;
}

export interface PrinterUpdateReq {
  code: string;
  reqEntity: PrinterReqEntity;
}

// # initial state
const initialState: PrinterState = {
  initialized: false,
  entities: { byCode: {}, allCodes: [] },
};

export const defaultPrinterReqEntity: PrinterReqEntity = {
  driver: '',
  ip: '',
  description: '',
  registration: Registration.M,
  use: true,
};

// # thunks
export const thunkLoadPrinters = createAsyncThunk('printer/load', async () => {
  const list = await printerApi.list();
  return list.map((dto) => toEntity(dto));
});
export const thunkCreatePrinter = createAsyncThunk(
  'printer/create',
  async (reqEntity: PrinterReqEntity) => {
    return toEntity(await printerApi.create(toReqDto(reqEntity)));
  }
);
export const thunkUpdatePrinter = createAsyncThunk(
  'printer/update',
  async ({ code, reqEntity }: PrinterUpdateReq) => {
    return toEntity(await printerApi.update(code, toReqDto(reqEntity)));
  }
);
export const thunkRemovePrinter = createAsyncThunk('printer/remove', async (code: string) => {
  return printerApi.remove(code);
});

const printerSlice = createSlice({
  name: 'printer',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(thunkLoadPrinters.fulfilled, (state, { payload: printers }) => {
      state.entities.byCode = printers.reduce((acc, t) => {
        acc[t.code] = t;
        return acc;
      }, {} as PrinterEntityByCode);
      state.entities.allCodes = printers.map((t) => t.code);
      state.initialized = true;
    });
    builder.addCase(thunkCreatePrinter.fulfilled, (state, { payload: createdEntity }) => {
      state.entities.byCode[createdEntity.code] = createdEntity;
      state.entities.allCodes.push(createdEntity.code);
    });
    builder.addCase(thunkUpdatePrinter.fulfilled, (state, { payload: updatedEntity }) => {
      state.entities.byCode[updatedEntity.code] = updatedEntity;
    });
    builder.addCase(thunkRemovePrinter.fulfilled, (state, { meta: { arg: removedCode } }) => {
      delete state.entities.byCode[removedCode];
      state.entities.allCodes = state.entities.allCodes.filter((code) => code !== removedCode);
    });
  },
});

// # selectors
export const selectInitialized = (state: RootState): boolean => {
  return state.printer.initialized;
};
export const selectPrinterEntities = (state: RootState): PrinterEntities => state.printer.entities;
export const selectPrinterEntityList = (state: RootState): PrinterEntity[] =>
  state.printer.entities.allCodes.map((code) => state.printer.entities.byCode[code]);

export default printerSlice.reducer;

// 참고중...
// https://orizens.com/blog/how-to-not-have-a-mess-with-react-hooks-and-redux/
