import React, { useCallback, useEffect, useState } from 'react';
import { NavLink as RouterLink, useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { CommonProps } from 'src/components/helpers/props';
import { useToast } from 'src/contexts/ToastContext';
import { useAlert } from 'src/contexts/AlertContext';

import './ProjectDetailPage.scss';
import projectApi2 from 'src/api/projectApi2';
import Grid, { GridItem } from 'src/components/atom/Grid';
import BreadcrumbArrowIcon from 'src/components/atom/icons/BreadcrumbArrowIcon';
import currentUrlUtils from 'src/shared/utils/currentUrlUtils';

interface ProjectDetailPageProps extends CommonProps {
  projectId: string;
  projectName: string;
}

const ProjectDetailPage: React.FC<ProjectDetailPageProps> = ({
  projectId,
  projectName,
  children,
}) => {
  const path = currentUrlUtils.getPath();
  const [, , , type] = path.split('/');
  let typeName = '';
  if (type === 'data-set') {
    typeName = '데이터셋';
  } else if (type === 'pre') {
    typeName = '비식별';
  } else if (type === 'join') {
    typeName = '결합';
  } else if (type === 'alg') {
    typeName = '알고리즘';
  } else if (type === 'keygen') {
    typeName = '결합키';
  } else if (type === 'ev') {
    typeName = '평가';
  }

  return (
    <div className="project-detail-page-root">
      <div className="project-detail-page-header">
        <Grid spacing={0}>
          <GridItem xs={12}>
            <ol className="project-detail-page-header-breadcrumbs">
              <li>
                <RouterLink to={`/projects`}>프로젝트</RouterLink>
              </li>
              <li className="sep">
                <BreadcrumbArrowIcon size="xs" />
              </li>
              <li>
                <RouterLink to={`/projects/${projectId}/detail`}>{projectName}</RouterLink>
              </li>
              {/* <li className="sep">
                <BreadcrumbArrowIcon size="xs" />
              </li>
              <li>
                <RouterLink to={`/projects/${projectId}/${type}`}>{typeName}</RouterLink>
              </li> */}
            </ol>
          </GridItem>
        </Grid>
      </div>
      <div className="project-detail-page-body">{children}</div>
    </div>
  );
};

export default ProjectDetailPage;
