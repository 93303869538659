import * as React from 'react';
import classNames from 'classnames';

import { CommonProps } from 'src/components/helpers/props';

import './index.scss';

export interface ButtonProps extends CommonProps {
  text: string;
  color?: 'primary' | 'secondary' | 'default' | 'third' | 'fourth';
  severity?: 'error';
  disabled?: boolean;
  autoWidth?: boolean;
  fullWidth?: boolean;
  startIcon?: React.ReactNode;
  centerIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  size?: 'md' | 'sm';
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

const Button: React.FC<ButtonProps> = ({
  text,
  color = 'primary',
  severity,
  disabled = false,
  autoWidth = false,
  fullWidth = false,
  startIcon,
  centerIcon,
  endIcon,
  size = 'md',
  onClick,
  classes,
}) => {
  return (
    <button
      type="button"
      className={classNames(
        'button-root',
        `button-color-${color}`,
        {
          'button-disabled': disabled,
          'button-auto-width': autoWidth,
          'button-full-width': fullWidth,
          'button-icon':
            startIcon !== undefined || endIcon !== undefined || centerIcon !== undefined,
          'button-start-icon': startIcon !== undefined,
          'button-center-icon': centerIcon !== undefined,
          'button-end-icon': endIcon !== undefined,
          'button-severity-error': severity === 'error',
          'button-size-sm': size === 'sm',
        },
        classes
      )}
      disabled={disabled}
      onClick={disabled ? undefined : onClick}
    >
      {startIcon}
      {centerIcon}
      <span>{text}</span>
      {endIcon}
    </button>
  );
};

export default Button;
