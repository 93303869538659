import React, { useEffect, useState, useContext, Fragment } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import { CommonProps } from 'src/components/helpers/props';
import { getAdidManagerUser } from 'src/shared/utils/sessionStorageManager';
import Popup2 from 'src/components/molecules/Popup2';
import templateApi, { TemplateDto, TaskDto, DetailDto, createTemplate, createAudit, createDetail, AuditDto, } from 'src/api/templateApi';
import Button from 'src/components/atom/Button';
import TextField from 'src/components/atom/TextField';
import FormLabel from 'src/components/atom/FormLabel';
import FormGroup from 'src/components/atom/FormGroup';
import Table from 'src/components/atom/Table';

import { ProjectContext } from '.';
import FileToUpload from './FileToUpload';
import './AuditPopup.scss';

interface AuditPopupProps extends CommonProps {
  open: boolean;
  auditDto: AuditDto;
  largeFile?: boolean;
  onClose: () => void;
}


const AuditDetailPopup: React.FC<AuditPopupProps> = ({
  open,
  auditDto,
  largeFile = false,
  onClose,
  classes,
}) => {
  const { t } = useTranslation(['commons']);

  const validationRules = {
    // id: _.trim(project.id).length > 0 && validationId(project.id),
    // name: _.trim(form.name).length > 0,
    // projectRole: selectedUsers.length > 0,
  };

  const handleOk = (): void => {
    //close할때 update된 데이터 넘겨서 서버에 저장함.
    onClose();

  };


  const title = largeFile ? "대용량 증빙" : "조서";

  return (
    <Popup2
      classes="audit-popup-root project-popup-root project-common"
      title={title + " 상세정보"}
      width="1112px"
      height="692px"
      open={open}
      onClose={handleOk}
      footer={
        <React.Fragment>
          {/* <Button text={t('commons:cancel')} onClick={handleCancel} color="secondary" /> */}
          <Button
            text={'확인'}
            onClick={handleOk}
          // disabled={!selectedUsers} 
          />
        </React.Fragment>
      }
    >
      <div className="project-input-area">
        <FormGroup classes="no-shrink">
          <FormLabel classes='input-label'>파일 이름</FormLabel>
          <TextField classes='input-area' value={auditDto.file_name} disabled={true} />
        </FormGroup>
        {largeFile &&
          <Fragment>
            <FormGroup classes="no-shrink">
              <FormLabel classes='input-label'>경영진 주장</FormLabel>
              <TextField classes='input-area' value={auditDto.management_assertions_string} disabled={true} />
            </FormGroup>
            <FormGroup classes="no-shrink">
              <FormLabel classes='input-label'>샘플 수</FormLabel>
              <TextField classes='input-area' value={auditDto.sample_count} disabled={true} />
            </FormGroup>
          </Fragment>
        }
        <FormGroup classes="no-shrink">
          <FormLabel classes='input-label'>프리페어러</FormLabel>
          <TextField classes='input-area' value={auditDto.preparer_list?.map((elem) => (elem.nickname)).join(' ')} disabled={true} />
        </FormGroup>
        <FormGroup classes="no-shrink">
          <FormLabel classes='input-label'>리뷰어</FormLabel>
          <TextField classes='input-area' value={auditDto.reviewer_list?.map((elem) => (elem.nickname)).join(' ')} disabled={true} />
        </FormGroup>
        <FormGroup classes='no-shrink'>
          <FormLabel classes='input-label'>
            <div>히스토리</div>
          </FormLabel>
        </FormGroup>
        <FormGroup classes='history'>
          <div className='input-area2'>
            <Table
              horizontal
              border={true}
              headerFixed={true}
              headerColor={'lightgray'}
              headerBackgroundColor={'gray'}
              classes={'column-content-table'}
            >
              <thead>
                <tr>
                  <th style={{ paddingLeft: 12, width: 120 }}>단계</th>
                  <th style={{ paddingLeft: 12, width: 100 }}>담당자</th>
                  <th style={{ paddingLeft: 12, width: 200 }}>승인자</th>
                  <th style={{ paddingLeft: 12, width: 150, overflow: "hidden" }}>날짜</th>
                  <th>의견</th>
                </tr>
              </thead>
              <tbody>
                {auditDto.history?.map((d, i) => {
                  const reviewers = d.cc?.map(elem => elem.nickname).join(", ");
                  return (
                    <tr key={'history-' + i}>
                      <td>{d.exec_command}</td>
                      <td>{d.by.nickname}</td>
                      <td>{reviewers}</td>
                      <td>{moment(Date.parse(d.at)).format('YYYY-MM-DD HH:mm:ss')}</td>
                      <td>{d.comment}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>

          </div>
        </FormGroup>
      </div>
    </Popup2>
  );
};

export default AuditDetailPopup;
