import React, { useEffect, useState, useContext, Fragment } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { CommonProps } from 'src/components/helpers/props';
import Popup2 from 'src/components/molecules/Popup2';
import templateApi, { TemplateDto, TaskDto, DetailDto, createTemplate, createAudit, createDetail, AuditDto, } from 'src/api/templateApi';
import Button from 'src/components/atom/Button';
import TextField from 'src/components/atom/TextField';
import './Step2EditPopup.scss';
import AddCircle2Icon from 'src/components/atom/icons/AddCircle2Icon';
import TrashIcon from 'src/components/atom/icons/TrashIcon';
import IconButton from 'src/components/atom/IconButton';
import { useAlert } from 'src/contexts/AlertContext';
import { validateAll } from 'src/shared/utils/validations';
import Select, { SelectItem } from 'src/components/atom/Select';
import _, { omit } from 'lodash';
import configApi from 'src/api/configApi';
import { ProjectContext } from '../../';
import { useHistory, useParams } from 'react-router-dom';
import { tranTypeList } from './Step2';
interface Step2EditPopupProps extends CommonProps {
  open: boolean;
  onConfirm: (opinions: any) => void;
  onClose: () => void;
}


const Step2EditPopup: React.FC<Step2EditPopupProps> = ({
  open,
  onClose,
  onConfirm,
  classes,
}) => {
  const { t } = useTranslation(['commons']);
  const { alert2 } = useAlert();
  const [trnData, setTrnData] = useState<tranTypeList[]>([]);
  const { projectInfo } = useContext(ProjectContext);
  let { projectId, category, taskName, detailName } = useParams<{ projectId: string, category: string, taskName: string, detailName: string }>();
  detailName = detailName && decodeURIComponent(detailName);
  const validationRules = {
    // id: _.trim(project.id).length > 0 && validationId(project.id),
    // name: _.trim(form.name).length > 0,
    // projectRole: selectedUsers.length > 0,
    //name: trnData.every((item: any) => item.risk.length > 0)
  };

  const handleOk = (): void => {
    if (!validateAll(validationRules)) {
      alert2("중요왜곡표시위험 관리", "중요왜곡표시위험 명은 필수 입력값입니다.");
      return;
    }

    console.log("trnData : ", trnData)
    onConfirm(trnData)
    const template = createTemplate(projectInfo.name, "STRATEGY", taskName);
    const detail = createDetail(detailName);
    template.category_list[0].task_list[0].detail_list = [detail];
    const data = {
      tag_name: "WCGW",
      template: { ...template, _id: sessionStorage.getItem("templateId") },
      transaction_type_list: [...trnData]
    }

    configApi.setMainTransactionTypeList(data, "WCGW").then(resp => {
      onClose();
    }).catch(e => {
      console.log("e : ", e)
    })
  };


  const handleDetailAdd = () => {
    let newRiskData = [...trnData];
    newRiskData.push({ name: "", checked: false })
    setTrnData(newRiskData)
  }

  const handleDeleteAccount = (i: number) => {
    let newRiskData = [...trnData];
    newRiskData.splice(i, 1)
    setTrnData(newRiskData)
  }

  const handleAccountNameChange = (e: React.ChangeEvent<HTMLInputElement>, i: number) => {
    e.stopPropagation();
    let newRiskData = [...trnData];
    newRiskData[i].name = e.target.value;
    setTrnData(newRiskData)
  }

  useEffect(() => {
    console.log("")
    configApi.getMainAccoutList({ _id: sessionStorage.getItem("templateId"), name: sessionStorage.getItem("templateName") }).then(
      (resp) => { setTrnData(resp?.wcgw_list || []) }
    ).catch(e => {
      console.log("E:", e);
    });
  }, []);

  return (
    <Popup2
      classes="audit-popup-root project-popup-root project-common"
      title={"중요왜곡표시위험 관리"}
      width="1112px"
      height="692px"
      open={open}
      onClose={onClose}
      footer={
        <React.Fragment>
          <div className="detail-add">
            <div
              onClick={handleDetailAdd}
            >
              <AddCircle2Icon color="#295c9f" />
              <span>중요왜곡표시위험 추가</span>
            </div>
          </div>
          <Button text={t('commons:cancel')} onClick={onClose} color="secondary" />
          <Button
            text={'확인'}
            onClick={handleOk}
          // disabled={!selectedUsers} 
          />
        </React.Fragment>
      }
    >
      <div className="task-header element">
        <div style={{ paddingLeft: 15, width: "100%", color: "#a3a6b4" }}>중요왜곡표시위험</div>
      </div>
      <div className="midterm-process-content-step2-root">
        {trnData.map((elem: any, i: number) => {
          return (
            <div className="midterm-process-content-step2" key={i.toString()}>
              <div className="midterm-process-content-wcgw-root">
                <TextField classes="midterm-process-content-wcgw"
                  value={elem.name || ""}
                  autoFocus={!elem?.name ? true : false}
                  placeholder="중요왜곡표시위험 명을 입력하세요."
                  onChange={(e) => { handleAccountNameChange(e, i) }}
                />
              </div>
              <div className="midterm-process-content-wcgw-delete">
                <IconButton onClick={() => handleDeleteAccount(i)}>
                  <TrashIcon></TrashIcon>
                </IconButton>

              </div>
            </div>
          )
        })}
      </div>
    </Popup2>
  );
};

export default Step2EditPopup;
