import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import Button from 'src/components/atom/Button';
import Table from 'src/components/atom/Table';
import TextField from 'src/components/atom/TextField';
import FormGroup from 'src/components/atom/FormGroup';
import RadioGroup from 'src/components/atom/RadioGroup';
import Radio from 'src/components/atom/Radio';
import EditableSelect, { EditableSelectItem } from 'src/components/atom/EditableSelect';
import update from 'immutability-helper';
import _ from 'lodash';

import AddCircle2Icon from 'src/components/atom/icons/AddCircle2Icon';
import TrashIcon from 'src/components/atom/icons/TrashIcon';
import { CommonProps } from 'src/components/helpers/props';
import Popup2 from 'src/components/molecules/Popup2';
import useSearchBar from 'src/hooks/useSearchBar';
import SearchBar from 'src/components/molecules/SearchBar';
import ScrollBox from 'src/components/atom/ScrollBox';
import { UserInfoEntity, MemberDto } from 'src/api/userSettingApi';
import Checkbox from 'src/components/atom/Checkbox';
import { RoleDto, PermissionDto } from 'src/api/roleApi';
import role from 'src/store/fidp/role';
import { boolean } from '@storybook/addon-knobs';
import clientApi, { ClientDto, createClient } from 'src/api/clientApi';
import useInputs from 'src/hooks/useInputs';
import { validateAll } from 'src/shared/utils/validations';
import { useAlert } from 'src/contexts/AlertContext';
import Select, { SelectItem } from 'src/components/atom/Select';
import IconButton from 'src/components/atom/IconButton';
import './ClientPopup.scss';
import ToolTip from 'src/components/atom/ToolTip';

interface ClientPopupProps extends CommonProps {
  // dtoList: UserInfoEntity[];
  // roleList: RoleDto[];
  // permissionList: PermissionDto[];
  clientDto?: ClientDto;
  userList?: MemberDto[];
  open: boolean;
  onClose: (client: ClientDto) => void;
  // selected: UserInfoEntity[];
}

interface ClientDto2 extends ClientDto {
  key: string;
}

const ClientPopup: React.FC<ClientPopupProps> = ({
  clientDto,
  open,
  userList,
  onClose,
  classes,
  // roleList,
  // permissionList,
}) => {
  const { t } = useTranslation(['commons']);
  const [form, onChange, onChangeElem, reset] = useInputs<ClientDto>(clientDto || createClient());
  const [clientList, setClientList] = useState<ClientDto[]>([]);
  const [insertMode, setInsertMode] = useState<boolean>(false);
  const [memberList, setMemberList] = useState<MemberDto[]>(userList || []);
  const { alert, alert2, confirmWithWarn } = useAlert();
  const [reload, setReload] = useState<boolean>(false);

  const validationRules = {
    // id: _.trim(project.id).length > 0 && validationId(project.id),
    name: _.trim(form.name).length > 0,
    // projectRole: selectedUsers.length > 0,
  };

  const resetInputValues = () => {
    onChangeElem("client_id", "")
    onChangeElem("name", "")
    onChangeElem("client_code", "")
    onChangeElem("engagement_code", "")
    onChangeElem("engagement_partner", [])
    //setSelectedMembers([]);
  }

  const handleOk = (): void => {
    console.log("form : ", form)
    if (insertMode) {
      if (!validateAll(validationRules)) {
        alert2('사용자 추가', '클라이언트 이름은 필수입니다.');
        return;
      }
      const addClient = () => {
        clientApi.addClient(form)
          .then(data => {
            console.log("data : ", data);
            setInsertMode(false);
            setReload(true);
            resetInputValues();
          })
          .catch(err => {
            alert2("클라이언트 추가", err);
          })
      }
      confirmWithWarn("클라이언트 추가", `정말 클라이언트(${form.name})를 추가하시겠습니까?`, addClient)
    } else {
      if (!validateAll(validationRules)) {
        alert('필수 값을 입력해주세요.');
        return;
      }
      clientApi.updateClient(form)
        .then(data => {
          console.log("data : ", data);
          onClose(form);
        })
        .catch(err => {
          alert2("클라이언트 추가", err);
        })
    }
  };
  const handleCancel = (): void => {
    if (insertMode) {
      setInsertMode(false)
      const selectedClient = clientList.filter(client => client.client_id === clientDto?.client_id)[0];
      if (selectedClient) {
        onChangeElem("client_id", selectedClient.client_id)
        onChangeElem("name", selectedClient.name)
        onChangeElem("client_code", selectedClient.client_code || "")
        onChangeElem("engagement_code", selectedClient.engagement_code || "")
        onChangeElem("engagement_partner", selectedClient.engagement_partner || [])
      }
    } else {
      onClose({} as ClientDto);
    }

  };

  const handleCancel2 = (): void => {
    onClose({} as ClientDto);
  }

  useEffect(() => {
    if (reload) {
      clientApi.getClientList()
        .then((resp) => {
          console.log('XXXXXXXXXXX GET CLIENT LIST', resp)
          setClientList(resp);
        })
        .catch(e => alert2("에러발생", e))
      console.log("userList :", userList)
      setReload(false)
    }
  }, [reload])

  useEffect(() => {
    // 리스트박스에서 클라이언트 선택했을 때 
    if (!insertMode) {
      const selectedClient = clientList.filter(client => client.client_id === form.client_id)[0];
      if (selectedClient) {
        onChangeElem("client_code", selectedClient.client_code || "")
        onChangeElem("engagement_code", selectedClient.engagement_code || "")
        onChangeElem("engagement_partner", selectedClient.engagement_partner || [])
      }
    }
  }, [form.client_id])

  useEffect(() => {
    setReload(true)
  }, [])

  useEffect(() => {
    const newMemberList = userList?.filter(({ account: account1 }) => !form.engagement_partner?.some(({ account: account2 }) => account1 === account2));
    setMemberList(newMemberList || []);
  }, [form.engagement_partner]);

  const handleDeleteClient = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    const deleteClient = () => {
      clientApi.deleteClient(form)
        .then(data => {
          console.log("data : ", data);
          resetInputValues();
          setReload(true);
        })
        .catch(err => {
          alert2("클라이언트 삭제", err);
        })
    }
    confirmWithWarn("클라이언트 삭제", `정말 클라이언트(${form.name})를 삭제하시겠습니까?`, deleteClient)
  };

  const handleInsertClient = () => {
    resetInputValues();
    setInsertMode(true);
    setReload(true);
  }

  const handleSelectMember = (member: string) => {
    const list = memberList.filter((elem) => elem.account === member).map((x) => { return { nickname: x.nickname, account: x.account } });
    let newMembers = [...(form.engagement_partner || []), ...list]
    onChangeElem("engagement_partner", newMembers)
  }

  const handleDelete = (member: string) => {
    const newMembers = form.engagement_partner?.filter((elem) => elem.nickname !== member).map((x) => { return { nickname: x.nickname, account: x.account } });
    console.log("newMembers : ", newMembers)
    onChangeElem("engagement_partner", newMembers)
  }

  console.log('clientPopup', form, clientList)

  return (
    <Popup2
      classes="client-popup-root"
      title={insertMode ? "클라이언트 추가" : "클라이언트 검색 및 변경"}
      width="1112px"
      height="692px"
      open={open}
      onClose={handleCancel2}
      footer={
        <React.Fragment>
          <Button text={t('commons:cancel')} onClick={handleCancel} color="secondary" />
          <Button
            text={t('commons:save')}
            onClick={handleOk}
          // disabled={!selectedUsers} 
          />
        </React.Fragment>
      }
    >
      <FormGroup spacing={6}>
        <span className='input-label'>클라이언트<span className="warning">*</span></span>
        {/* <TextField name='name' value={form.name} fullWidth={true} onChange={onChange}/> */}
        {insertMode ?
          <TextField placeholder="추가할 클라이언트 이름을 입력해 주세요." name='name' value={form.name} fullWidth={true} onChange={onChange} />
          :
          <Select
            classes='project-text-field'
            align={'left'}
            autoWidth={true}
            border={false}
            selectFirstItem={false}
            selectedEventKey={form.client_id}
            onClick={(eventKey) => {
              onChangeElem('client_id', eventKey);
              onChangeElem('name', clientList.filter(client => client.client_id === eventKey)[0].name)
            }}
          >
            {clientList && clientList.map((elem, index) => (
              <SelectItem key={'type-select' + index} eventKey={elem.client_id}>
                {elem.name}
              </SelectItem>
            ))}
          </Select>
        }
        {
          form.name && !insertMode &&
          <ToolTip message="클라이언트 삭제">
            <IconButton classes="icon-trash" onClick={handleDeleteClient}
            >
              <TrashIcon />
            </IconButton>
          </ToolTip>
        }
      </FormGroup>
      <FormGroup spacing={6}>
        <span className='input-label'>Engagement Code</span>
        <TextField name='engagement_code' value={form.engagement_code} fullWidth={true} onChange={onChange} />
      </FormGroup>
      <FormGroup spacing={6}>
        <span className='input-label'>Client Code</span>
        <TextField name='client_code' value={form.client_code} fullWidth={true} onChange={onChange} />
      </FormGroup>
      <FormGroup spacing={6} classes="tttt">
        <span className='input-label'>Engagement Partner</span>
        {/* <TextField name='engagement_partner' value={form.engagement_partner} fullWidth={true} onChange={onChange} /> */}
        <Select key="fisrt"
          classes="project-text-field"
          // width={120}
          autoWidth={true}
          selectFirstItem={false}
          selectedEventKey={''}
          // placeholder=' '
          textAlign='center'
          align='left'
          border={false}
          enableMultiSelect={true}
          //multiSelectList={selectedMembers.map((elem) => (elem.nickname))}
          multiSelectList={form.engagement_partner?.map((elem) => (elem.nickname))}
          onClick={(item) => handleSelectMember(item)}
          onDelete={(item) => handleDelete(item)}
        >
          {memberList && memberList.map((elem) => (
            <SelectItem key={elem.account} eventKey={elem.account} onClick={(item) => handleSelectMember(item.selectedEventKey)}>
              {elem.nickname}
            </SelectItem>
          ))}
        </Select>
      </FormGroup>
      {!insertMode && <FormGroup spacing={6}>
        <span className='input-label'></span>
        <div style={{ color: "red" }}>* 항목을 수정하는 경우 저장 버튼 클릭시 자동 저장 됩니다.</div>
      </FormGroup>
      }
      {/* 필요 없는 걸로 판단해서 제외함
        <FormGroup spacing={6}>
        <span className='input-label'>업무 성격</span>
        <RadioGroup
          name="taskType"
          // disabled={!!project.info?.schedule.freq}
          value={form.taskType}
          onChangeEvent={onChange}
        >
          <Radio label="감사" value={'감사'} />
          <Radio label="분기검토" value={'분기검토'} />
        </RadioGroup>
      </FormGroup> */}
      {!insertMode && <div className="client-add"
        onClick={handleInsertClient}
      >
        <AddCircle2Icon color="#295c9f" />
        <span className="label">추가</span>
      </div>}
    </Popup2>
  );
};

export default ClientPopup;
