import React from 'react';
import classNames from 'classnames';
import { NavLink as RouterLink } from 'react-router-dom';

import './index.scss';

interface PageTopProps {
  title: string;
  parentLink?: string;
  parentTitle?: string;
  depthDesc?: string;
  icon?: React.ReactNode;
  action?: React.ReactNode;
  classes?: string;
}
const PageTop: React.FC<PageTopProps> = ({
  title,
  parentLink,
  parentTitle,
  depthDesc,
  icon,
  action,
  classes,
}) => {
  //TODO: icon
  return (
    <div className={classNames('page-top-root', { wide: depthDesc, classes })}>
      <div className="page-title-box">
        {depthDesc && <div className="page-title-desc">{depthDesc}</div>}
        <div className="page-title-text">
          {icon && <div>{icon}</div>}
          <span className="page-parent-title">
            <RouterLink to={parentLink || '/'}>{parentTitle}</RouterLink>
          </span>
          <span className="page-title">{title}</span>
        </div>
      </div>
      <div className="page-top-right-side">{action}</div>
    </div>
  );
};

export default PageTop;
