import React from 'react';
import classNames from 'classnames';

import { CommonProps } from 'src/components/helpers/props';

import './index.scss';

interface PaperHeaderHelpTextProps extends CommonProps {
  star?: boolean;
}
export const PaperHeaderHelpText: React.FC<PaperHeaderHelpTextProps> = ({
  star = false,
  classes,
  children,
}) => {
  return (
    <span
      className={classNames(
        'paper-header-help-txt',
        { 'paper-header-help-txt-star': star },
        classes
      )}
    >
      {children}
    </span>
  );
};

interface PaperHeaderProps extends CommonProps {
  leftArea?: React.ReactNode;
  rightArea?: React.ReactNode;
}
export const PaperHeader: React.FC<PaperHeaderProps> = ({
  leftArea,
  rightArea,
  classes,
  children,
}) => {
  return (
    <div className={classNames('paper-header-root', classes)}>
      <div className="left-area">{leftArea}</div>
      <div className="right-area">{rightArea}</div>
    </div>
  );
};

export const PaperBody: React.FC<CommonProps> = ({ classes, children }) => {
  return <div className={classNames('paper-body-root', classes)}>{children} </div>;
};

interface PaperBodyBoxProps extends CommonProps {
  spacing?: boolean;
}

export const PaperBodyBox: React.FC<PaperBodyBoxProps> = ({
  spacing = false,
  classes,
  children,
}) => {
  return (
    <div
      className={classNames('paper-body-inner', { 'paper-body-inner-spacing': spacing }, classes)}
    >
      {children}
    </div>
  );
};

interface PaperProps extends CommonProps {
  noScroll?: boolean;
}

const Paper: React.FC<PaperProps> = ({ noScroll, classes, children }) => {
  return (
    <div
      className={classNames(
        'paper-root',
        {
          'paper-root-no-scroll': noScroll,
        },
        classes
      )}
    >
      {children}
    </div>
  );
};

export default Paper;
