import React, { useEffect, useState } from 'react';
import templateApi, {
  DetailDto,
  AuditDto,
} from 'src/api/templateApi';
import { useHistory, useParams } from 'react-router-dom';
import usePopup from 'src/hooks/usePopup';
import { useAlert } from 'src/contexts/AlertContext';
import classNames from 'classnames';
import EditIcon from 'src/components/atom/icons/EditIcon';
import IconButton from 'src/components/atom/IconButton';
import DotIcon from 'src/components/atom/icons/DotIcon';
import ReactQuill, { Quill } from 'react-quill';
import Button from 'src/components/atom/Button';
import _, { omit } from 'lodash';
import Step2EditPopup from './Step2EditPopup';
import './Step2.scss';
import projectApi, { ProjectDto, emptyProject } from 'src/api/projectApi';

const DetailTaskLeftElement: React.FC<{
  detailContent: { name: string },
  selected: boolean,
  idx: number,
  onHandleClick: (item: any) => void;
}> = ({
  detailContent,
  selected,
  idx,
  onHandleClick
}) => {
    return (
      <div className={classNames("sp-csm-step2-account", { "sp-csm-step2-account-checked": selected })}
        onClick={() => onHandleClick(idx)}
      >
        <div>{detailContent.name}</div>
      </div>
    )
  }


const DetailTaskRightElement: React.FC<{
  detailContent: { transaction_type_list: any[] },
}> = ({
  detailContent,
}) => {

    // console.log("detailContent :", detailContent)
    return <div className="sp-csm-step2-account-detail-root">
      {detailContent?.transaction_type_list?.map((elem: any, i: number) => {
        return elem?.transaction_type_list &&
          elem?.transaction_type_list?.map((elem2: any, i: number) => {
            return (
              <div className="sp-csm-step2-account-detail" key={i.toString()}>
                <div className="wcgw">
                  <div style={{ marginLeft: 10 }}>{elem.name}</div>
                </div>
                <div className="contents">
                  <div style={{ marginLeft: 10 }}>{elem2.name}</div>
                </div>
              </div>)
          })
      })}
    </div>
  }

const Step2: React.FC<{
  detailTaskName: string,
  editMode: boolean,
  detailSubList: any[],
  requirements: string,
  handleChange: (html: string) => void;
  handleCancelRequirement: () => void;
  handleConfirmRequirement: () => void;
  handleConfirmDetail?: (opinions: any) => void;
}> = ({
  detailTaskName,
  editMode,
  detailSubList,
  requirements,
  handleChange,
  handleCancelRequirement,
  handleConfirmRequirement,
  handleConfirmDetail = () => { }
}) => {
    const step2EditPopup = usePopup();
    const [selectedIdx, setSelectedIdx] = useState<number>(0);

    const handleConfirmAccount = (data: any) => {
      let newItAppData = [..._.cloneDeep(detailSubList)];
      newItAppData[selectedIdx] = data;
      handleConfirmDetail([newItAppData[selectedIdx]]);

      step2EditPopup.closePopup();
    }

    const handleClose = () => {
      // console.log("AAAAA : ", detailSubList)
      step2EditPopup.closePopup()
    }

    const onHandleClick = (idx: number) => {
      // props로 넘어온 데이터와 state 데이터가 다르면 state를 props 데이터로 원복??
      // if (JSON.stringify(detailSubList) !== JSON.stringify(accountInfo)) {
      //   setAccountInfo(_.cloneDeep(detailSubList))
      // }
      setSelectedIdx(idx)
    }

    const renderDetailLeftBody = () => {
      return detailSubList?.map((elem: any, idx: number) => {
        return <DetailTaskLeftElement key={'detailTaskElement' + idx}
          detailContent={elem}
          onHandleClick={onHandleClick}
          idx={idx}
          selected={idx === selectedIdx}
        />
      })
    }

    const renderDetailRightBody = () => {
      if (!detailSubList || detailSubList[selectedIdx] === undefined) {
        return <></>
      }
      return <DetailTaskRightElement
        detailContent={detailSubList[selectedIdx]}
      />
    }
    const handleOpenPopup = () => {
      step2EditPopup.openPopup()
    }
    const isProjectDisabled = projectApi.isProjectDisabled();
    return (
      <div className="sp-csm-step2-detail-body">
        <div className="sp-csm-step2-detail-body-title element">
          <div className="title" style={{ paddingLeft: 10 }}><DotIcon classes="dot-icon" color='#43425d' />요구사항</div>
        </div>
        <div className="sp-csm-step2-detail-body-requirements">
          <div className={classNames("requirements-editor")}>
            <ReactQuill
              modules={{
                toolbar: [
                  [
                    { list: "ordered" },
                    { list: "bullet" },
                    { indent: "-1" },
                    { indent: "+1" },
                  ],

                ]
              }}
              readOnly={!editMode}
              value={requirements || ''}
              style={{ fontSize: 50 }}
              onChange={handleChange}
            />
          </div>
        </div>
        {!editMode && <div className="sp-csm-step2-detail-body-table">
          <div className="sp-csm-step2-detail-left-body">
            <div className="header element" style={{ paddingLeft: 10 }}>
              <div className="it-application">IT Application</div>
            </div>
            <div className="data-body">
              {renderDetailLeftBody()}
            </div>
          </div>
          <div className="sp-csm-step2-detail-right-body">
            <div className="header element" style={{ paddingLeft: 10 }}>
              <div className="itgc">ITGC</div>
              <div className="contents">내용</div>
              <div className="itgc-edit-button-root">
                <IconButton classes="itgc-edit-button" disabled={isProjectDisabled} onClick={handleOpenPopup}>
                  <EditIcon size="sm" />
                </IconButton>
              </div>
            </div>

            <div className="data-body">
              {renderDetailRightBody()}
            </div>
          </div>
        </div>}

        {editMode && <div className="sp-csm-step2-detail-footer common-page-footer project-footer">
          <div className={classNames('right-button')}>
            <Button classes="footer-button" text="취소" color="secondary" onClick={handleCancelRequirement} />
            <Button classes="footer-button" text="확인" onClick={handleConfirmRequirement} />
          </div>
        </div>}
        {step2EditPopup.opened &&
          <Step2EditPopup
            open={step2EditPopup.opened}
            data={detailSubList[selectedIdx] || {}}
            onClose={handleClose}
            onConfirm={handleConfirmAccount}

          />
        }
      </div>)
  }

export default Step2;