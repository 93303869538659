import React, { useEffect, useState } from 'react';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import TextField from 'src/components/atom/TextField';
import Grid, { GridItem } from 'src/components/atom/Grid';
import BreadcrumbArrowIcon from 'src/components/atom/icons/BreadcrumbArrowIcon';
import { useAlert } from 'src/contexts/AlertContext';
import { useToast } from 'src/contexts/ToastContext';
import FormGroup from 'src/components/atom/FormGroup';
import FormLabel from 'src/components/atom/FormLabel';
import Button from 'src/components/atom/Button';
import ExpansionDiv from 'src/components/atom/ExpansionDiv';
import CheckboxGroup, { CheckboxGroupChild } from 'src/components/atom/CheckboxGroup';
import Checkbox from 'src/components/atom/Checkbox';

import { getAdidManagerUser } from 'src/shared/utils/sessionStorageManager';
import './index.scss';
import { validateAll } from 'src/shared/utils/validations';

import userSettingApi, { UserInfoEntity, emptyUserInfoEntity } from 'src/api/userSettingApi';
import roleApi, { RoleDto } from 'src/api/roleApi';
import settingApi from 'src/api/settingApi';

const AccountForm: React.FC = () => {
  const { alert } = useAlert();
  const toast = useToast();
  const history = useHistory();
  const { t } = useTranslation(['commons']);
  const [submit, setSubmit] = useState<boolean>(false);

  const userId = getAdidManagerUser(); //authEntity.tokenEntity.userInfo.userId;
  const [dto, setDto] = useState<UserInfoEntity | undefined>(undefined);
  const [roleList, setRoleList] = useState<RoleDto[] | undefined>(undefined);
  const [useComplexity, setUseComplexity] = useState<boolean | undefined>(undefined);
  const [reqDto, setReqDto] = useState<UserInfoEntity>(emptyUserInfoEntity());

  const [passwordCheck, setPasswordCheck] = useState<{ password: string; verifyPassword: string }>({
    password: '',
    verifyPassword: '',
  });

  const validationPassWord = (password: string): string => {
    if (_.trim(password).length < 1) {
      return 'success';
    }

    if (!useComplexity) {
      return 'success';
    }

    const minLength = 12;
    const maxLength = 30;
    const repeatedChars = 3;

    const pwRegEx = RegExp('^.*(?=^.{12,30}$)(?=.*\\d)(?=.*[a-zA-Z])(?=.*[!@#$%^&+=]).*$');
    if (!pwRegEx.test(password)) {
      return (
        '비밀번호는 영문자, 숫자, 특수문자를 각각 하나 이상 포함한 ' +
        minLength +
        '자~' +
        maxLength +
        '자로 생성해야 합니다.'
      );
    }

    const repeatedRegex = RegExp('(.)\\1{' + (repeatedChars - 1) + ',}');
    if (repeatedRegex.test(password)) {
      return '동일한 문자를 ' + repeatedChars + '번 이상 입력할 수 없습니다.';
    }

    return 'success';
  };
  const validationRules = {
    name: _.trim(reqDto.name).length > 0,
    email: _.trim(reqDto.email).length > 0,
    password:
      _.trim(passwordCheck.password).length < 1 ||
      (_.trim(passwordCheck.password).length > 0 &&
        validationPassWord(_.trim(passwordCheck.password)) === 'success'),
    verifyPassword:
      _.trim(passwordCheck.verifyPassword).length < 1 ||
      (_.trim(passwordCheck.verifyPassword).length > 0 &&
        passwordCheck.password === passwordCheck.verifyPassword),
  };

  const handleChangeInputEvt = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setReqDto({ ...reqDto, [e.target.name]: e.target.value });
  };

  const handleChangePassWordEvt = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setPasswordCheck({ ...passwordCheck, [e.target.name]: e.target.value });
    setReqDto({ ...reqDto, password: e.target.value });
  };

  const handleSubmit = (): void => {
    setSubmit(true);

    if (!validateAll(validationRules)) {
      alert('필수 값을 입력해주세요.');
      return;
    }

    if (userId !== null) {
      userSettingApi
        .update(userId, reqDto)
        .then((resp) => {
          toast.info(t('commons:message.edit.success'));
          history.push(`/account`);
        })
        .catch((err) => alert(err));
    }
  };

  const handleCancel = (): void => {
    history.push(`/account`);
  };

  useEffect(() => {
    if (roleList === undefined) {
      roleApi
        .list()
        .then((resp) => {
          setRoleList(resp);
        })
        .catch((error) => {
          setRoleList([]);
        });
    }

    if (useComplexity === undefined) {
      settingApi.managerSettingApi
        .list()
        .then((resp) => {
          const reqManagerDic = Object.fromEntries(resp.map((dto) => [dto.id, dto.val]));
          const complexity = reqManagerDic['password.complexity'];
          setUseComplexity(complexity !== undefined && complexity === 'false' ? false : true);
        })
        .catch((error) => {
          setUseComplexity(true);
        });
    }

    if (userId !== null && dto === undefined) {
      userSettingApi
        .find(userId)
        .then((resp) => {
          // setDto(resp);
          // setReqDto({ ...resp, password: '' });
        })
        .catch((err) => alert(err));
    }
  }, [alert, dto, history, roleList, useComplexity, userId]);

  if (!dto || !userId || !roleList) {
    return null;
  }

  return (
    <div className="user-form-root common-page-root">
      <div className="common-page-header">
        <Grid spacing={0}>
          <GridItem classes="common-page-grid-left" xs={12}>
            <ol className="common-page-header-breadcrumbs">
              <li>
                <RouterLink to="/account">My Page</RouterLink>
              </li>
              <li className="sep">
                <BreadcrumbArrowIcon />
              </li>
            </ol>
            <div className="common-page-header-title">{dto?.name}</div>
          </GridItem>
        </Grid>
      </div>
      <div className="common-page-body">
        <div className="form-page-root">
          <FormGroup spacing={20}>
            <FormLabel>
              아이디 <span className="warning">*</span>
            </FormLabel>
            <TextField
              name="code"
              placeholder="사용자 아이디를 입력해주세요."
              maxLength={128}
              value={reqDto.code}
              onChange={handleChangeInputEvt}
              disabled={true}
            />
          </FormGroup>
          <FormGroup spacing={20}>
            <FormLabel>
              이메일 <span className="warning">*</span>
            </FormLabel>
            <TextField
              name="email"
              placeholder="사용자 이메일을 입력해주세요."
              maxLength={128}
              error={submit && !validationRules.email}
              value={reqDto.email}
              onChange={handleChangeInputEvt}
            />
          </FormGroup>
          <FormGroup spacing={20}>
            <FormLabel>
              이름 <span className="warning">*</span>
            </FormLabel>
            <TextField
              name="name"
              placeholder="사용자 이름을 입력해주세요."
              maxLength={128}
              error={submit && !validationRules.name}
              value={reqDto.name}
              onChange={handleChangeInputEvt}
            />
          </FormGroup>
          <FormGroup spacing={20}>
            <FormLabel>
              비밀번호 <span className="warning">*</span>
            </FormLabel>
            <TextField
              name="password"
              type="password"
              placeholder="비밀번호를 입력해주세요."
              maxLength={128}
              error={submit && !validationRules.password}
              value={passwordCheck.password}
              onChange={handleChangePassWordEvt}
            />
            {!validationRules.password &&
            validationPassWord(passwordCheck.password) !== 'success' ? (
              <span className="warning txt-word-break-all">
                {validationPassWord(passwordCheck.password)}
              </span>
            ) : (
              ''
            )}
          </FormGroup>
          <FormGroup spacing={20}>
            <FormLabel>
              비밀번호 재확인 <span className="warning">*</span>
            </FormLabel>
            <TextField
              name="verifyPassword"
              type="password"
              placeholder="비밀번호를 재확인해주세요."
              maxLength={128}
              error={submit && !validationRules.verifyPassword}
              value={passwordCheck.verifyPassword}
              onChange={handleChangePassWordEvt}
            />
            {!validationRules.verifyPassword && passwordCheck.verifyPassword.length > 0 ? (
              <span className="warning txt-word-break-all ">비밀번호가 동일하지 않습니다.</span>
            ) : (
              ''
            )}
          </FormGroup>
          <FormGroup spacing={20}>
            <FormLabel>권한 템플릿</FormLabel>
            <div className={'txtValueForm'}>
              <FormLabel>
                {dto.userRoleName !== undefined &&
                dto.userRoleName !== null &&
                dto.userRoleName.length > 0
                  ? dto.userRoleName.map((name) => name).join(', ')
                  : ''}
              </FormLabel>
            </div>
          </FormGroup>{' '}
          <FormGroup spacing={20}>
            <FormLabel>소속</FormLabel>
            <TextField
              name="company"
              placeholder="소속을 입력해주세요."
              maxLength={128}
              value={reqDto.company}
              onChange={handleChangeInputEvt}
            />
          </FormGroup>
          <FormGroup spacing={20}>
            <FormLabel>부서</FormLabel>
            <TextField
              name="dept"
              placeholder="부서를 입력해주세요."
              maxLength={128}
              value={reqDto.dept}
              onChange={handleChangeInputEvt}
            />
          </FormGroup>
          <FormGroup spacing={20}>
            <FormLabel>전화번호</FormLabel>
            <TextField
              name="phone"
              placeholder="010-0000-0000"
              maxLength={128}
              value={reqDto.phone}
              onChange={handleChangeInputEvt}
            />
          </FormGroup>
          <FormGroup spacing={20}>
            <FormLabel>설명</FormLabel>
            <TextField
              name="comments"
              placeholder="설명을 입력해주세요."
              maxLength={128}
              value={reqDto.comments}
              onChange={handleChangeInputEvt}
            />
          </FormGroup>
        </div>
      </div>
      <div className="common-page-footer">
        <Grid spacing={0}>
          <GridItem xs={12} classes="common-page-grid-right">
            <Button color="secondary" text="취소" onClick={handleCancel} />
            <Button text="확인" onClick={handleSubmit} />
          </GridItem>
        </Grid>
      </div>
    </div>
  );
};

export default AccountForm;
