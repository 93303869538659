import React, { useEffect, useState, useContext, Fragment } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { CommonProps } from 'src/components/helpers/props';
import Popup2 from 'src/components/molecules/Popup2';
import Button from 'src/components/atom/Button';
import TextField from 'src/components/atom/TextField';
import FormLabel from 'src/components/atom/FormLabel';
import FormGroup from 'src/components/atom/FormGroup';
import requestApi, { ReviewRequestDto } from 'src/api/requestApi';

import './RequestPopup.scss';
import { DialogTitle } from '@material-ui/core';
import IconButton from 'src/components/atom/IconButton';
import DownloadIcon from 'src/components/atom/icons/DownloadIcon';

interface RequestDetailPopupProps extends CommonProps {
  open: boolean;
  dto: ReviewRequestDto;
  onClose: () => void;
  onSubmit: (comment: string, result: string) => void;
}


const RequestDetailPopup: React.FC<RequestDetailPopupProps> = ({
  open,
  dto,
  onClose,
  onSubmit,
  classes,
}) => {
  const { t } = useTranslation(['commons']);
  const [comment, setComment] = useState<string>('');
  const validationRules = {
    // id: _.trim(project.id).length > 0 && validationId(project.id),
    // name: _.trim(form.name).length > 0,
    // projectRole: selectedUsers.length > 0,
  };

  const handleSubmit = (result: string) => {
    onSubmit(comment, result);
  }

  return (
    <Popup2
      classes="request-popup-root project-popup-root"
      title={"요청 상세 보기"}
      width="1112px"
      height="692px"
      open={open}
      onClose={onClose}
      footer={
        <React.Fragment>
          <Button text='반려'
            color="fourth"
            onClick={() => handleSubmit('Reject')}
          />
          <Button
            text={'승인'}
            onClick={() => handleSubmit('Confirm')}
          />
        </React.Fragment>
      }
    >
      <div className="project-input-area">
        <FormGroup classes="no-shrink">
          <FormLabel classes='input-label'>TASK</FormLabel>
          <div className='input-area disabled'>
            <span>{dto.detail_sub_title_name || dto.task_name}</span>
          </div>
        </FormGroup>
        <FormGroup classes="no-shrink">
          <FormLabel classes='input-label'>세부 TASK</FormLabel>
          <div className='input-area disabled'>
            <span>{dto.detail_sub_title_detail_name || dto.detail_name}</span>
          </div>
        </FormGroup>
        <FormGroup classes="no-shrink">
          <FormLabel classes='input-label'>파일 이름</FormLabel>
          <div className='input-area disabled'>
            <span>{dto.audit_name}</span>
            <a href={dto.uri?.path} download target="_blank">
              {/* <IconButton >
                <DownloadIcon />
              </IconButton> */}
              <div className="down-load">
                <img src={require("src/resources/images/downLoad.png")}
                  style={{ width: 11, height: 16 }}
                />
              </div>
            </a>
          </div>
        </FormGroup>
        <FormGroup classes="no-shrink">
          <FormLabel classes='input-label'>요청자</FormLabel>
          <div className='input-area disabled'>
            <span>{dto.history?.by.nickname}</span>
          </div>
        </FormGroup>
        <FormGroup classes="no-shrink">
          <FormLabel classes='input-label'>요청자 의견</FormLabel>
          <div className='input-area disabled'>
            <span>{dto.history?.comment}</span>
          </div>
        </FormGroup>
        <FormGroup classes="no-shrink comment">
          <FormLabel classes='input-label'><div>리뷰어 의견</div></FormLabel>
          <textarea className='input-area' value={comment} onChange={(e) => setComment(e.target.value)} autoFocus />
        </FormGroup>
      </div>
    </Popup2>
  );
};

export default RequestDetailPopup;
