import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import _ from 'lodash';
import qs from 'query-string';

import { CommonProps } from 'src/components/helpers/props';
import TextField from 'src/components/atom/TextField';
import Button from 'src/components/atom/Button';
import FormGroup from 'src/components/atom/FormGroup';
import FormLabel from 'src/components/atom/FormLabel';
import BNHLogo from 'src/components/atom/icons/BNHLogo';
import './index.scss';
import { Redirect, useHistory } from 'react-router-dom';
import useAuthStore from 'src/hooks/auth/useAuthStore';
import config from 'src/shared/config';
import { useAlert } from 'src/contexts/AlertContext';
import { useTranslation } from 'react-i18next';
import currentUrlUtils from 'src/shared/utils/currentUrlUtils';
import authApi from 'src/api/auth/authApi';
import { appendLogoutCallbackEntity, getDeviceId } from 'src/shared/utils/sessionStorageManager';
import { removeAndGetLogoutCallbackEntities, setLoginWarningFlag } from 'src/shared/utils/sessionStorageManager';
import authRefreshToken from 'src/shared/utils/authRefreshToken';

interface LoginState {
  userId: string;
  password: string;
}

interface LoginProps extends CommonProps {
  isSavePassword: boolean;
}

const Login: React.FC<LoginProps> = ({ isSavePassword, classes }) => {
  const history = useHistory();
  const { alert, alert2, confirmWithWarn } = useAlert();
  const { t } = useTranslation(['commons']);
  const { isAuthenticated, authEntity, signIn } = useAuthStore();
  const [state, setState] = useState<LoginState>({
    userId: '', //Todo[HHL]: 자동로그인을 위해 넣은 초기화 추후 수정 필요
    password: '', //Todo [HHL]: 자동로그인을 위해 초기화 추후 수정
  });
  const userIdInputRef = useRef<HTMLInputElement>(null);
  const passwordInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    userIdInputRef.current?.focus();
  }, []);

  useEffect(() => {
    const logoutCallbackEntities = removeAndGetLogoutCallbackEntities();
    if (logoutCallbackEntities.length > 0) {
      alert2('token error', 'token 사용 시간이 만료되었습니다. 다시 로그인해주세요');
    }

    if (isAuthenticated && authEntity && authEntity.tokenStr) {
      const redirectUrl = currentUrlUtils.getFirstQuery('redirectUrl') || config.url('/');
      // redirect 새앙이 내부 페이지면 react-router 사용, 아니면 기본 redirect 사용.
      // fast일 경우에는 임시 token을 받아서 전달
      if (redirectUrl.startsWith(config.contextPath)) {
        history.push(redirectUrl);
      } else if (redirectUrl.startsWith('http') && redirectUrl.includes('/fast')) {
        // TODO FAST인지를 판단하는 방법 좀 더 nice하게는...
        // FAST는 임시 토큰 받아서 redirect
        // const now = Date.now();
        // authApi
        //   .sessionInit({
        //     userId: authEntity.tokenEntity.userInfo.userId,
        //     deviceId: getDeviceId(),
        //     timestamp: now,
        //     url: redirectUrl,
        //   })
        //   .then(({ timestamp, url }) => {
        //     if (now === timestamp) {
        //       // 추후 로그아웃 시 호출할 callback url을 저장
        //       const logoutUrl = currentUrlUtils.getFirstQuery('logoutUrl');
        //       const { bearer: bearerValues } = qs.parseUrl(url).query;
        //       const bearer = Array.isArray(bearerValues) ? _.head(bearerValues) : bearerValues;
        //       if (logoutUrl && bearer) {
        //         appendLogoutCallbackEntity({
        //           logoutUrl,
        //           token: bearer,
        //         });
        //       }
        //       currentUrlUtils.setHref(url);
        //     } else {
        //       // TODO error handling
        //       console.log(
        //         `Invalid response of session init! (timestamp: ${timestamp}, now: ${now})`
        //       );
        //       history.push(config.url('/'));
        //     }
        //   })
        //   .catch((e) => {
        //     history.push(config.url('/'));
        //     alert(e);
        //   });
      } else {
        currentUrlUtils.setHref(redirectUrl);
      }
    } else {
      // Todo[HHL]: 자동로그인
      // handleSignIn();
    }
  }, [alert, alert2, authEntity, history, isAuthenticated]);

  if (isAuthenticated && authEntity && authEntity.tokenStr) {
    return null;
  }

  const handleChangeInputEvt = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const handleSignIn = (): void => {
    userIdInputRef.current?.blur();
    passwordInputRef.current?.blur();

    if (!state.userId) {
      userIdInputRef.current?.focus();
      return alert2("로그인 에러", t('commons:login.message.emptyId'));
    }
    if (!state.password) {
      passwordInputRef.current?.focus();
      return alert2("로그인 에러", t('commons:login.message.emptyPassword'));
    }

    signIn({
      userId: state.userId,
      password: state.password,
      redirectUrl: currentUrlUtils.getFirstQuery('redirectUrl') || config.url('/'),
    })
      .then(() => {
        // const { redirectUrl } = qs.parse(window.location.search);
        // if (redirectUrl && redirectUrl.length > 0) {
        //   history.push(Array.isArray(redirectUrl) ? redirectUrl[0] : redirectUrl);
        // } else {
        //   history.push(config.url('/'));
        // }
        //authRefreshToken();
        if (state.userId === state.password) {
          setLoginWarningFlag("need");
        }
      })
      .catch((e) => {
        console.log('________________________loing error', e)
        alert2("로그인 에러", e)
      });
  };

  return (
    <div className={classNames('login-root', classes)}>
      <div className="login-box">
        <div className="inner">
          <span className="logo">
            <BNHLogo />
            {/* <span className='logo-txt'>BnH Stella Project</span> */}
          </span>
          {/* <span className="logo-txt">
            <em className="txt-hide">BnH Stella</em>
          </span> */}
          <div className="input-area">
            <FormGroup spacing={10} direction="row">
              <FormLabel>{t('commons:login.id')}</FormLabel>
              <TextField
                placeholder={t('commons:login.message.emptyId')}
                name={'userId'}
                value={state.userId}
                fullWidth={true}
                inputRef={userIdInputRef}
                autoComplete="off"
                onChange={handleChangeInputEvt}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') handleSignIn();
                }}
              />
            </FormGroup>
            <FormGroup spacing={10} direction="row">
              <FormLabel>{t('commons:login.password')}</FormLabel>
              <TextField
                type={'password'}
                name={'password'}
                placeholder={t('commons:login.message.emptyPassword')}
                value={state.password}
                fullWidth={true}
                inputRef={passwordInputRef}
                autoComplete="off"
                onChange={handleChangeInputEvt}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') handleSignIn();
                }}
              />
            </FormGroup>

          </div>
          {/* input-area */}

          <div className="btn-area">
            {/* <Checkbox label="비밀번호 저장" checked={isSavePassword} /> */}
            <Button text={t('commons:login.$')} onClick={handleSignIn} />
          </div>
          {/* btn-area */}
        </div>
      </div>
      <div className="side-image"></div>
    </div>
  );
};

export default Login;
