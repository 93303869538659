import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import deptApi, { DeptDto } from 'src/api/fidp/deptApi';
import { toLocalDateTimeStr } from 'src/shared/utils/localDateTime';
import { RootState } from 'src/store';
import _ from 'lodash';

// # types
export type DeptEntity = DeptDto;
function toEntity(dto: DeptDto): DeptEntity {
  return { ...dto };
}

function toDto(entity: DeptEntity): DeptDto {
  return { ...entity };
}

interface DeptEntityByCode {
  [code: string]: DeptEntity;
}
interface DeptEntities {
  byCode: DeptEntityByCode;
  allCodes: string[];
}
interface DeptState {
  entities: DeptEntities;
}

// UserEntity를 찾을 수 없는 경우, UI에서 예외처리를 하기 위함
export const defaultDeptEntity: DeptEntity = {
  code: '',
  name: '',
  dsdCode: '',
  managerId: '',
  managerName: '',
  parentCode: '',
  parentName: '',
  linkedYN: 'N',
  updatedTime: 0,
  subDeptCount: 0,
  userCount: 0,
};

// # initial state
const initialState: DeptState = {
  entities: { byCode: {}, allCodes: [] },
};

// # thunks
export const thunkLoadDepts = createAsyncThunk('dept/load', async (deptCodes: string[]) => {
  const list = await deptApi.list(deptCodes);
  return list.map((dto) => toEntity(dto));
});

const deptSlice = createSlice({
  name: 'dept',
  initialState,
  reducers: {
    INIT: (state) => {
      state = initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(thunkLoadDepts.fulfilled, (state, { payload: depts }) => {
      const beforeDepts = state.entities.allCodes.map((code) => state.entities.byCode[code]);
      const uniqueDepts = _.uniqBy(beforeDepts.concat(depts), function (e) {
        return e.code;
      });
      state.entities.byCode = uniqueDepts.reduce((acc, t) => {
        acc[t.code] = t;
        return acc;
      }, {} as DeptEntityByCode);
      state.entities.allCodes = uniqueDepts.map((t) => t.code);
    });
  },
});

// # selectors
export const selectDeptEntities = (state: RootState): DeptEntities => state.dept.entities;
export const selectDeptEntityList = (state: RootState): DeptEntity[] =>
  state.dept.entities.allCodes.map((code) => state.dept.entities.byCode[code]);
export const init = (): {
  payload: undefined;
  type: string;
} => deptSlice.actions.INIT();

export default deptSlice.reducer;
