/* eslint-disable @typescript-eslint/camelcase */
import config from 'src/shared/config';
import axios from 'src/shared/utils/axios';
import { ManagerServerResDto, unwrapResult } from './managerServerApi';

const M_API_URL = config.servers.manager.url2;
const A_API_URL = config.servers.app.url2;

export interface SettingDto {
  id: string;
  name: string;
  description: string;
  val: string;
}
export interface SettingReqDto {
  id: string;
  val: string;
}

class ManagerSettingApi {
  async list(): Promise<SettingDto[]> {
    const resp = await axios.get<ManagerServerResDto<SettingDto[]>>(`${M_API_URL}/v1/properties`);
    return unwrapResult(resp.data);
  }
  async find(id: string): Promise<SettingDto> {
    const resp = await axios.get<ManagerServerResDto<SettingDto>>(
      `${M_API_URL}/v1/properties/${id}`
    );
    return unwrapResult(resp.data);
  }
  async update(reqDtoList: SettingReqDto[]): Promise<void> {
    const data = {
      properties: reqDtoList,
    };

    const resp = await axios.put<ManagerServerResDto<void>>(`${M_API_URL}/v1/properties`, data);
    unwrapResult(resp.data);
  }
}

class AppSettingApi {
  async list(): Promise<SettingDto[]> {
    const resp = await axios.get<ManagerServerResDto<SettingDto[]>>(`${A_API_URL}/v1/properties`);
    return unwrapResult(resp.data);
  }
  async find(id: string): Promise<SettingDto> {
    const resp = await axios.get<ManagerServerResDto<SettingDto>>(
      `${A_API_URL}/v1/properties/${id}`
    );
    return unwrapResult(resp.data);
  }
  async update(reqDtoList: SettingReqDto[]): Promise<void> {
    const data = {
      properties: reqDtoList,
    };

    const resp = await axios.put<ManagerServerResDto<void>>(`${A_API_URL}/v1/properties`, data);
    unwrapResult(resp.data);
  }
}

const settingApi = {
  managerSettingApi: new ManagerSettingApi(),
  appSettingApi: new AppSettingApi(),
};

export default settingApi;
