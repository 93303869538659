import React from 'react';
import classNames from 'classnames';

import { CommonProps } from 'src/components/helpers/props';

import './index.scss';

interface InnerTableTdProps extends CommonProps {
  colSpan?: number;
  padding?: boolean;
  border?: boolean;
  sideBorder?: boolean;
  rightBorder?: boolean;
}

export const InnerTableTd: React.FC<InnerTableTdProps> = ({
  colSpan,
  padding = true,
  border = true,
  sideBorder = true,
  rightBorder = false,
  classes,
  children,
}) => (
    <td
      className={classNames(
        'td-inner-table',
        {
          padding: padding === true,
          'no-padding': padding === false,
          'no-border': border === false,
          'no-side-border': sideBorder === false,
          'right-border': rightBorder === true,
        },
        classes
      )}
      colSpan={colSpan ? colSpan : 0}
    >
      {children}
    </td>
  );

interface TableProps {
  striped?: boolean;
  horizontal?: boolean;
  scrollbox?: boolean;
  size?: 'md' | 'sm' | 'xs';
  hover?: boolean;
  outline?: boolean;
  verticalLine?: boolean;
  noSidePadding?: boolean;
  headerColor?: 'gray' | 'lightgray';
  headerBackgroundColor?: 'gray' | 'white';
  classes?: string;
  headerFixed?: boolean;
  border?: boolean;
}

const Table: React.FC<TableProps> = ({
  striped,
  horizontal,
  scrollbox,
  size = 'md',
  hover = true,
  outline = true,
  verticalLine = true,
  noSidePadding = false,
  headerColor = 'gray',
  headerBackgroundColor,
  children,
  classes,
  headerFixed,
  border,
}) => {
  if (!headerBackgroundColor) {
    if (size === 'md' && !horizontal) {
      headerBackgroundColor = 'white';
    } else {
      headerBackgroundColor = 'gray';
    }
  }
  return (
    <div className={classNames('tbl', classes)}>
      <table
        className={classNames('table-root', {
          'table-striped': striped,
          'table-hover': hover && !horizontal,
          'table-outline': outline,
          'table-vertical-line': verticalLine,
          'table-no-side-padding': noSidePadding,
          'table-horizontal': horizontal,
          'table-scrollbox': scrollbox,
          'table-size-sm': size === 'sm',
          'table-size-xs': size === 'xs',
          'table-header-color-lightgray': headerColor === 'lightgray',
          'table-header-background-color-white': headerBackgroundColor === 'white',
          'table-header-fixed': headerFixed,
          'table-border': border,
        })}
      >
        {children}
      </table>
    </div>
  );
};

export default Table;
