import React, { useEffect, useState } from 'react';
import templateApi, {
  DetailDto,
  AuditDto,
} from 'src/api/templateApi';
import { useHistory, useParams } from 'react-router-dom';
import usePopup from 'src/hooks/usePopup';
import { useAlert } from 'src/contexts/AlertContext';
import classNames from 'classnames';
import EditIcon from 'src/components/atom/icons/EditIcon';
import IconButton from 'src/components/atom/IconButton';
import DotIcon from 'src/components/atom/icons/DotIcon';
import ReactQuill, { Quill } from 'react-quill';
import Button from 'src/components/atom/Button';
import _ from 'lodash';
import ArrowDownIcon from 'src/components/atom/icons/ArrowDownIcon';
import ArrowUpIcon from 'src/components/atom/icons/ArrowUpIcon';
import './Step4.scss';
import RemoveIcon from 'src/components/atom/icons/RemoveIcon';
import Step4EditPopup from './Step4EditPopup';
import projectApi, { ProjectDto, emptyProject } from 'src/api/projectApi';

const DetailTaskElement: React.FC<{
  detailContent: { name: string, scope: string, transaction_type_list: [{ transaction_type_list: any }] },
  detailVisible: boolean,
  idx: number,
}> = ({
  detailContent,
  detailVisible,
  idx,
}) => {
    const [visible, setVisible] = useState<boolean>(detailVisible);

    let subCount = 0;
    let loc = -1;
    detailContent?.transaction_type_list?.forEach((ii, index) => {
      if (ii.transaction_type_list && Array.isArray(ii.transaction_type_list)) {
        if (loc === -1) {
          loc = index;
        }
        subCount = subCount + ii.transaction_type_list.length;
      }
    })
    return (
      <div className="sp-csm-step4-detail-data">
        <div className="sp-csm-step4-detail-it-app" onClick={(e) => {
          e.stopPropagation();
          subCount && setVisible(!visible);
        }
        }>
          {subCount > 1 ?
            visible ? <ArrowUpIcon classes='icon-up' /> :
              <ArrowDownIcon fill="#bcbccb" classes='icon-down' />
            :
            <RemoveIcon fill="#bcbccb" classes='icon-down' />

          }
          {detailContent.name}
        </div>
        <div className="sp-csm-step4-detail-itgc-root">
          {detailContent?.transaction_type_list?.map((item: any, i: number) => {
            if (!visible && i !== loc) {
              return <div key={"t" + i.toString()}></div>
            }
            return item?.transaction_type_list?.map((elem: any, j: number) => {
              if (!visible && j > 0) {
                return <div key={"tt" + j.toString()}></div>
              }
              return <div key={i.toString() + j.toString()} className="sp-csm-step4-detail-itgc" style={{ borderBottomWidth: visible ? 1 : 0 }}>
                <div className="sp-csm-step4-detail-itgc-name">{item.name}</div>
                <div className="sp-csm-step4-detail-itgc-contents">{elem.name}</div>
                <div className="sp-csm-step4-detail-itgc-select" style={!elem.effectiveness ? { color: "#b6bbc2" } : {}}>
                  {elem.effectiveness ? elem.effectiveness : (item.name ? "미지정" : "")}
                </div>
              </div>
            })
          })}
        </div>
      </div >
    )
  }

const Step4: React.FC<{
  detailTaskName: string,
  editMode: boolean,
  detailSubList: any,
  requirements: string,
  supplement: string,
  handleChange: (html: string, type: string) => void;
  handleCancelRequirement: () => void;
  handleConfirmRequirement: () => void;
  handleConfirmDetail?: (opinions: any) => void;
}> = ({
  detailTaskName,
  editMode,
  detailSubList,
  requirements,
  supplement,
  handleChange,
  handleCancelRequirement,
  handleConfirmRequirement,
  handleConfirmDetail = () => { }
}) => {
    const step4EditPopup = usePopup();
    const renderDetailBody = () => {
      // console.log("detailSubList : ", detailSubList)
      return detailSubList?.map((elem: any, idx: number) => {
        return <DetailTaskElement key={'detailTaskElement22' + idx}
          detailVisible={false}
          detailContent={elem}
          idx={idx}
        />
      })
    }

    const handleDetailTaskElement = () => {
      step4EditPopup.openPopup();
    }

    const handleClose = () => {
      //console.log("AAAAA : ", detailSubList)
      step4EditPopup.closePopup()
    }

    const handleConfirm = (d: any) => {
      handleConfirmDetail(d)
      step4EditPopup.closePopup()
    }
    const isProjectDisabled = projectApi.isProjectDisabled();

    return (
      <div className="sp-csm-step4-detail-body">
        <div className="sp-csm-step4-detail-body-title element">
          <div className="title" style={{ paddingLeft: 10 }}><DotIcon classes="dot-icon" color='#43425d' />요구사항</div>
        </div>
        <div className="sp-csm-step4-detail-body-requirements">
          <div className={classNames("requirements-editor")}>
            <ReactQuill
              modules={{
                toolbar: [
                  [
                    { list: "ordered" },
                    { list: "bullet" },
                    { indent: "-1" },
                    { indent: "+1" },
                  ],

                ]
              }}
              readOnly={!editMode}
              value={requirements || ''}
              style={{ fontSize: 50 }}
              onChange={(value) => handleChange(value, "requirements")}
            />
          </div>
        </div>
        {!editMode && <div>
          <div className="sp-csm-step4-detail-body-title element">
            <div className="title" style={{ paddingLeft: 10 }}><DotIcon classes="dot-icon" color='#43425d' />ITGC 설계 및 운영의 효과성 결론</div>
          </div>
          <div className="sp-csm-step4-detail-body-title element">

            <div className="header" style={{ paddingLeft: 10 }}>
              <div className="it-application">IT Application</div>
              <div className="itgc-root">
                <div className="itgc">ITGC</div>
                <div className="contents">내용</div>
                <div className="results">효과성 결론</div>
                <div className="edit-button">
                  <IconButton disabled={isProjectDisabled} onClick={handleDetailTaskElement}>
                    <EditIcon size="sm" />
                  </IconButton>
                </div>
              </div>
            </div>
          </div>
          <div className="sp-csm-step4-detail-body-content">
            {renderDetailBody()}
          </div>
        </div>}

        <div className="sp-csm-step4-detail-body-title element">
          <div className="title" style={{ paddingLeft: 10 }}><DotIcon classes="dot-icon" color='#43425d' />보완사항</div>
        </div>
        <div className="sp-csm-step4-detail-body-requirements2">

          <div className={classNames("requirements-editor")}>
            <ReactQuill
              modules={{
                toolbar: [
                  [
                    { list: "ordered" },
                    { list: "bullet" },
                    { indent: "-1" },
                    { indent: "+1" },
                  ],

                ]
              }}
              readOnly={!editMode}
              value={supplement || ''}
              style={{ fontSize: 50 }}
              onChange={(value) => handleChange(value, "supplement")}
            />
          </div>
        </div>
        {editMode && <div className="sp-csm-step4-detail-footer common-page-footer project-footer">
          <div className={classNames('right-button')}>
            <Button classes="footer-button" text="취소" color="secondary" onClick={handleCancelRequirement} />
            <Button classes="footer-button" text="확인" onClick={handleConfirmRequirement} />
          </div>
        </div>}
        {step4EditPopup.opened &&
          <Step4EditPopup
            open={step4EditPopup.opened}
            data={detailSubList || []}
            onClose={handleClose}
            onConfirm={handleConfirm}

          />
        }
      </div>)
  }

export default Step4;