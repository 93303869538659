import React, { useEffect, useState, useContext, Fragment } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import { CommonProps } from 'src/components/helpers/props';
import Popup2 from 'src/components/molecules/Popup2';
import Button from 'src/components/atom/Button';
import TextField from 'src/components/atom/TextField';
import FormLabel from 'src/components/atom/FormLabel';
import FormGroup from 'src/components/atom/FormGroup';
import requestApi, { ReviewRequestDto } from 'src/api/requestApi';
import Table from 'src/components/atom/Table';
import templateApi, { TemplateDto, TaskDto, DetailDto, createTemplate, createAudit, createDetail, AuditDto, } from 'src/api/templateApi';

import './RequestPopup.scss';

interface RequestHistoryPopupProps extends CommonProps {
  open: boolean;
  dto: ReviewRequestDto;
  onClose: () => void;
}


const RequestHistoryPopup: React.FC<RequestHistoryPopupProps> = ({
  open,
  dto,
  onClose,
  classes,
}) => {
  const { t } = useTranslation(['commons']);
  const [audit, setAudit] = useState<AuditDto>({} as AuditDto);

  useEffect(() => {
    templateApi.
      getTemplate(dto.template_name)
      .then((resp) => {
        console.log("resp : ", resp)
        const categoryItem = resp?.category_list.filter((elem) => elem.name === dto.category_name)[0];
        const taskItem = categoryItem?.task_list.filter((elem) => elem.name === dto.task_name)[0];
        const detailItem = taskItem?.detail_list.filter((elem) => elem.name === dto.detail_name)[0];
        const auditItem = detailItem?.audit_list.filter((elem) => elem.file_name === dto.audit_name)[0];
        const audit = auditItem;
        setAudit(audit);
      })
  }, [])

  return (
    <Popup2
      classes="request-popup-root project-popup-root project-common"
      title={"조서 히스토리"}
      width="1112px"
      height="692px"
      open={open}
      onClose={onClose}
      footer={
        <React.Fragment>
          <Button
            text={'확인'}
            onClick={onClose}
          />
        </React.Fragment>
      }
    >
      <div className="project-input-area">
        <div className='input-area2'>
          <Table
            horizontal
            border={true}
            headerFixed={true}
            headerColor={'lightgray'}
            headerBackgroundColor={'gray'}
            classes={'column-content-table'}
          >
            <thead>
              <tr>
                <th style={{ paddingLeft: 12, width: 120 }}>단계</th>
                <th style={{ paddingLeft: 12, width: 100 }}>담당자</th>
                <th style={{ paddingLeft: 12, width: 200 }}>승인자</th>
                <th style={{ paddingLeft: 12, width: 150, overflow: "hidden" }}>날짜</th>
                <th>의견</th>
              </tr>
            </thead>
            <tbody>
              {audit?.history?.map((d, i) => {
                const reviewers = d.cc?.map(elem => elem.nickname).join(", ");
                return (
                  <tr key={'history-' + i}>
                    <td>{d.exec_command}</td>
                    <td>{d.by.nickname}</td>
                    <td>{reviewers}</td>
                    <td>{moment(Date.parse(d.at)).format('YYYY-MM-DD HH:mm:ss')}</td>
                    <td>{d.comment}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      </div>
    </Popup2>
  );
};

export default RequestHistoryPopup;
