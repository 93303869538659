import React from 'react';
import classNames from 'classnames';

import { CommonProps } from 'src/components/helpers/props';

import './AbstractIcon.scss';

interface LinkIconProps extends CommonProps {
  size?: 'md' | 'lg';
  color?: string;
}

const LinkIcon: React.FC<LinkIconProps> = ({ classes, size = 'md', color = 'white' }) => {
  if (size === 'md') {
    return (
      <svg
        className={classNames('icon-root', classes)}
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill={color}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M10.9321 13.0679C11.2426 13.3633 11.2426 13.848 10.9321 14.1434C10.6367 14.4387 10.152 14.4387 9.85664 14.1434C8.37977 12.6665 8.37977 10.2656 9.85664 8.78875L12.5377 6.10765C14.0146 4.63078 16.4155 4.63078 17.8923 6.10765C19.3692 7.58453 19.3692 9.98539 17.8923 11.4623L16.7639 12.5907C16.7714 11.9697 16.673 11.3487 16.4609 10.7579L16.8169 10.3944C17.7106 9.50825 17.7106 8.06925 16.8169 7.18312C15.9308 6.28942 14.4918 6.28942 13.6056 7.18312L10.9321 9.85664C10.0384 10.7428 10.0384 12.1818 10.9321 13.0679ZM13.0679 9.85664C13.3633 9.56127 13.848 9.56127 14.1434 9.85664C15.6202 11.3335 15.6202 13.7344 14.1434 15.2113L11.4623 17.8923C9.98539 19.3692 7.58453 19.3692 6.10765 17.8923C4.63078 16.4155 4.63078 14.0146 6.10765 12.5377L7.23614 11.4093C7.22856 12.0303 7.32702 12.6513 7.53909 13.2497L7.18312 13.6056C6.28942 14.4918 6.28942 15.9308 7.18312 16.8169C8.06925 17.7106 9.50825 17.7106 10.3944 16.8169L13.0679 14.1434C13.9616 13.2572 13.9616 11.8182 13.0679 10.9321C12.7574 10.6367 12.7574 10.152 13.0679 9.85664Z" />
      </svg>
    );
  } else if (size === 'lg') {
    return (
      <svg
        className={classNames('icon-root', classes)}
        width="30"
        height="30"
        viewBox="0 0 30 30"
        fill="white"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M13.4744 16.5256C13.918 16.9475 13.918 17.64 13.4744 18.0619C13.0525 18.4839 12.36 18.4839 11.9381 18.0619C9.82824 15.9521 9.82824 12.5223 11.9381 10.4125L15.7682 6.58236C17.878 4.47255 21.3078 4.47255 23.4176 6.58236C25.5275 8.69218 25.5275 12.122 23.4176 14.2318L21.8055 15.8439C21.8163 14.9567 21.6757 14.0695 21.3727 13.2256L21.8813 12.7062C23.158 11.4404 23.158 9.38464 21.8813 8.11874C20.6154 6.84203 18.5596 6.84203 17.2938 8.11874L13.4744 11.9381C12.1977 13.2039 12.1977 15.2597 13.4744 16.5256ZM16.5256 11.9381C16.9475 11.5161 17.64 11.5161 18.0619 11.9381C20.1718 14.0479 20.1718 17.4777 18.0619 19.5875L14.2318 23.4176C12.122 25.5275 8.69218 25.5275 6.58236 23.4176C4.47255 21.3078 4.47255 17.878 6.58236 15.7682L8.19448 14.1561C8.18366 15.0433 8.32432 15.9305 8.62727 16.7852L8.11874 17.2938C6.84203 18.5596 6.84203 20.6154 8.11874 21.8813C9.38464 23.158 11.4404 23.158 12.7062 21.8813L16.5256 18.0619C17.8023 16.7961 17.8023 14.7403 16.5256 13.4744C16.082 13.0525 16.082 12.36 16.5256 11.9381Z" />
      </svg>
    );
  } else return null;
};

export default LinkIcon;
