import { PasswordExpirationStatus } from './../../api/fidp/userApi';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import userApi, { UserDto, UserSearchDto } from 'src/api/fidp/userApi';
import { RootState } from 'src/store';
import _ from 'lodash';

// # types
export type UserEntity = UserDto;

function toEntity(dto: UserDto): UserEntity {
  return { ...dto };
}

function toDto(entity: UserEntity): UserDto {
  return { ...entity };
}

interface UserEntityByCode {
  [code: string]: UserEntity;
}
interface UserEntities {
  byCode: UserEntityByCode;
  allCodes: string[];
}
interface UserState {
  entities: UserEntities;
}

// UserEntity를 찾을 수 없는 경우, UI에서 예외처리를 하기 위함
export const defaultUserEntity: UserEntity = {
  id: '',
  name: '',
  password: '',
  email: '',
  phoneNumber: '',
  depts: {
    primary: {
      code: '',
      name: '',
    },
    others: [],
  },
  position: {
    dsdCode: '',
    code: '',
    name: '',
    order: 1,
    linkedYN: 'N',
    updatedTime: 0,
  },
  role: {
    dsdCode: '',
    code: '',
    name: '',
    order: 1,
    linkedYN: 'N',
    updatedTime: 0,
  },
  validityPeriod: {
    // 유효기간
    start: -1, // 시작 일시 (-1: 무제한)
    end: -1, //  종료 일시 (-1: 무제한)
  },
  useYN: 'N', // 사용 여부
  passwordUpdatedTime: 0, // 비밀번호 갱신 시간
  passwordStatus: PasswordExpirationStatus.EXPIRED, // 비밀번호 상태
  initialPasswordYN: 'Y',
  updatedTime: 0,
  autoPassword: false,
  dsdCode: '', // 도메인 코드
  linkedYN: 'N',
};

// # initial state
const initialState: UserState = {
  entities: { byCode: {}, allCodes: [] },
};

export const thunkLoadUsersByIds = createAsyncThunk('user/ids', async (ids: string[]) => {
  return userApi.userByIds(ids);
});

export const thunkLoadUserByName = createAsyncThunk('user/search', async (searchString: string) => {
  return userApi.userByName(searchString);
});

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    INIT: (state) => {
      state = initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(thunkLoadUsersByIds.fulfilled, (state, { payload: { count, users } }) => {
      const beforeUers = state.entities.allCodes.map((code) => state.entities.byCode[code]);
      const uniqueUser = _.uniqBy(beforeUers.concat(users), function (e) {
        return e.id;
      });

      state.entities.byCode = uniqueUser.reduce((acc, t) => {
        acc[t.id] = t;
        return acc;
      }, {} as UserEntityByCode);
      state.entities.allCodes = uniqueUser.map((t) => t.id);
    });
    builder.addCase(thunkLoadUserByName.fulfilled, (state, { payload: { count, users } }) => {
      const beforeUers = state.entities.allCodes.map((code) => state.entities.byCode[code]);
      const uniqueUser = _.uniqBy(beforeUers.concat(users), function (e) {
        return e.id;
      });

      state.entities.byCode = uniqueUser.reduce((acc, t) => {
        acc[t.id] = t;
        return acc;
      }, {} as UserEntityByCode);
      state.entities.allCodes = uniqueUser.map((t) => t.id);
    });
  },
});

export const selectUserEntities = (state: RootState): UserEntities => state.user.entities;
export const selectUserEntityList = (state: RootState): UserEntity[] =>
  state.user.entities.allCodes.map((code) => state.user.entities.byCode[code]);
export const init = (): {
  payload: undefined;
  type: string;
} => userSlice.actions.INIT();

export default userSlice.reducer;
