import React, { useEffect, useState, useContext, Fragment } from 'react';

import { CommonProps } from 'src/components/helpers/props';
import Popup2 from 'src/components/molecules/Popup2';
import Button from 'src/components/atom/Button';
import './ImportProgressPopup.scss';
import { FileUploadProgressBar } from '../Category/FileUploadProgressBar';

interface ImportProgressPopupProps extends CommonProps {
  open: boolean;
  progressPercent?: number;
  level: number;
  type?: string;
  onClose: () => void;
  onAbort?: () => void;
}


const ImportProgressPopup: React.FC<ImportProgressPopupProps> = ({
  open,
  progressPercent,
  type = "upload",
  onClose,
  level = 1,
  onAbort,
  classes,
}) => {

  const [status, setStatus] = useState<string>("준비");
  const [visible, setVisible] = useState(true)
  const [level1css, setLevel1Css] = useState({ visibility: "visible" } as React.CSSProperties)
  const [level2css, setLevel2Css] = useState({ visibility: "visible" } as React.CSSProperties)
  const [level3css, setLevel3Css] = useState({ visibility: "visible" } as React.CSSProperties)

  useEffect(() => {
  }, [progressPercent])

  const handleAbort = () => {
    onAbort && onAbort();
    setStatus("취소")
    //onClose();
  }

  useEffect(() => {
    let timer: any;
    if (level > 0) {
      timer = setInterval(() => {
        if (visible) {
          setVisible(visible => !visible);
        } else {
          setVisible(visible => !visible);
        }
      }, 700)
    }

    return () => {
      clearInterval(timer);
    }
  }, [level]);
  useEffect(() => {
    if (level === 1) {
      if (visible === false) {
        setLevel1Css({ visibility: "hidden" })
      } else {
        setLevel1Css({ visibility: "visible" })
      }
    }
    if (level === 2) {
      if (visible === false) {
        setLevel2Css({ visibility: "hidden" })
      } else {
        setLevel2Css({ visibility: "visible" })
      }
    }
    if (level === 3) {
      if (visible === false) {
        setLevel3Css({ visibility: "hidden" })
      } else {
        setLevel3Css({ visibility: "visible" })
      }
    }
  }, [visible])

  return (
    <Popup2
      classes="import-file-upload-popup-root project-popup-root project-common"
      title={"프로젝트 RESTORE"}
      width="600px"
      height="400px"
      overPopup={true}
      open={open}
      onClose={status !== "취소" ? () => { } : onClose}
      footer={
        <React.Fragment>
          {type === "upload" ? <Button
            text={level === 2 && status !== "취소" ? '업로드 취소' : '닫기'}
            onClick={level === 2 && status !== "취소" ? handleAbort : onClose}
          // disabled={!selectedUsers} 
          /> :
            <div></div>
          }
        </React.Fragment>
      }
    >
      <div className="project-input-area" style={{}}>
        <div style={{ height: "100%", width: "100%", flexDirection: "row", display: "flex" }}>
          <div style={{ width: "20%", height: "100%", flexDirection: "column", display: "flex", alignItems: "center", justifyContent: "center" }}>
            {level >= 1 && <div style={{ height: 60, width: 60, borderRadius: 10, borderWidth: 1, borderColor: "blue", borderStyle: "solid", alignItems: "center", justifyContent: "center", display: "flex", flexDirection: "column", }}>
              <div style={{ height: 30 }}>{"1단계"}</div>
              <div style={level1css}>{"준비"}</div>
            </div>}

          </div>
          <div style={{ width: "60%", height: "100%", flexDirection: "column", display: "flex", alignItems: "center", justifyContent: "center" }}>
            {level >= 2 && <div style={{ height: 60, width: 60, borderRadius: 10, borderWidth: 1, borderColor: "blue", borderStyle: "solid", alignItems: "center", justifyContent: "center", display: "flex", flexDirection: "column", }}>
              <div style={{ height: 30 }}>{"2단계"}</div>
              <div style={level2css}>{"업로드"}</div>
            </div>}
          </div>
          <div style={{ width: "20%", height: "100%", flexDirection: "column", display: "flex", alignItems: "center", justifyContent: "center" }}>
            {level >= 3 && <div style={{ height: 60, width: 60, borderRadius: 10, borderWidth: 1, borderColor: "blue", borderStyle: "solid", alignItems: "center", justifyContent: "center", display: "flex", flexDirection: "column", }}>
              <div style={{ height: 30 }}>{"3단계"}</div>
              <div style={level3css}>{"마무리"}</div>
            </div>}
          </div>
        </div>
        {level === 2 ? <div style={{ position: "relative", height: "50%", width: "100%" }}>
          {status !== "취소" ?
            <div style={{ width: "100%", height: 60, display: "flex", position: "absolute", alignItems: "center", flexDirection: "column" }}>
              <FileUploadProgressBar height={20} bgcolor={"red"} progress={progressPercent + "%"} />
              <div style={{ marginTop: 10 }}> {"진행률 : " + progressPercent + "%"}</div>
            </div> :
            <div style={{ width: "100%", height: 60, display: "flex", position: "absolute", alignItems: "center", flexDirection: "column" }}>
              파일 업로드가 취소 되었습니다.</div>}
        </div>
          :
          <div style={{ position: "relative", height: "40%", width: "100%" }}>
          </div>
        }
      </div>

    </Popup2 >
  );
};

export default ImportProgressPopup;