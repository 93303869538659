import config from 'src/shared/config';
import axios from 'src/shared/utils/axios';

import DummyApi from './dummyApi';
import { times } from './api';

const FSP_API_ENABLED = config.servers.fsp.enabled;
const FSP_API_URL = config.servers.fsp.url;

export enum PrintType {
  PRINT = 'PRINT', //인쇄
  WATPRINT = 'WATPRINT', //워터마크 인쇄
  USERSELECT = 'USERSELECT', //사용자 선택
}

export enum LogType {
  NONE = 'NONE', //전송 안 함
  ALL = 'ALL', //전체
  FIRST = 'FIRST', //첫 페이지만
  ODD = 'ODD', //홀수 페이지만
  EVEN = 'EVEN', //짝수 페이지만
  PER = 'PER', //n페이지 마다
  TO = 'TO', //첫 페이지부터 n페이지 까지
}

export enum ColorType {
  BLACK_WHITE = 'BLACK_WHITE', //흑백
  COLOR = 'COLOR', //컬러
  USERSELECT = 'USERSELECT', //사용자 선택
}

export interface BasicTemplateDto {
  code: string;
  name: string;
  description: string;
  use: boolean;
  entryTs: number;

  docPrint: PrintType; //일반 문서 인쇄 모드
  secDocPrint: PrintType; //보안 문서 인쇄 모드
  approval: boolean; //인쇄 시 결재
  approvalWatExcept: boolean; //워터마크 예외 신청
  selectWat: boolean; //워터마크 종류 사용자 선택
  watPrintLogSend: boolean; //워터마크 인쇄 로그 저장
  printLogSend: boolean; //일반 인쇄 로그 저장
  txtLogType: LogType; //텍스트 로그 타입
  txtLogValue: number; //텍스트 로그 값 (매 n 페이지마다 또는 첫 페이지부터 n 페이지까지)
  imgLogType: LogType; //이미지 로그 타입
  imgLogValue: number; //이미지 로그 값 (매 n 페이지마다 또는 첫 페이지부터 n 페이지까지)
  pullPrinting: boolean; //pullprinting 사용
  tco: boolean; //인쇄 절감 사용
  txtSaving: number; //텍스트 인쇄 농도
  imgSaving: number; //이미지 인쇄 농도
  graphicSaving: number; //그래프 인쇄 농도
  colorType: ColorType; //흑백으로만 인쇄
  printLimit: boolean; //인쇄 매수 제한
  printLimitCount: number; //인쇄 최대 허용 매수
  printLimitAlert: boolean; //최대 허용 매수 초과 시 사용자 알림
}

export type BasicTemplateReqDto = Omit<BasicTemplateDto, 'code' | 'entryTs'>;

interface BasicTemplateApi {
  list(): Promise<BasicTemplateDto[]>;
  find(code: string): Promise<BasicTemplateDto>;
  create(reqDto: BasicTemplateReqDto): Promise<BasicTemplateDto>;
  update(code: string, reqDto: BasicTemplateReqDto): Promise<BasicTemplateDto>;
  remove(code: string): Promise<void>;
}
class BasicTemplateServerApi implements BasicTemplateApi {
  async list(): Promise<BasicTemplateDto[]> {
    const resp = await axios.get<BasicTemplateDto[]>(`${FSP_API_URL}/api/basics/templates`);
    return resp.data;
  }
  async find(code: string): Promise<BasicTemplateDto> {
    const resp = await axios.get<BasicTemplateDto>(`${FSP_API_URL}/api/basics/templates/${code}`);
    return resp.data;
  }
  async create(reqDto: BasicTemplateReqDto): Promise<BasicTemplateDto> {
    const resp = await axios.post<BasicTemplateDto>(`${FSP_API_URL}/api/basics/templates`, reqDto);
    return resp.data;
  }
  async update(code: string, reqDto: BasicTemplateReqDto): Promise<BasicTemplateDto> {
    const resp = await axios.put<BasicTemplateDto>(
      `${FSP_API_URL}/api/basics/templates/${code}`,
      reqDto
    );
    return resp.data;
  }
  async remove(code: string): Promise<void> {
    await axios.delete<BasicTemplateDto>(`${FSP_API_URL}/api/basics/templates/${code}`);
  }
}

class BasicTemplateDummyApi implements BasicTemplateApi {
  dummyApi: DummyApi<BasicTemplateDto, BasicTemplateReqDto>;
  constructor() {
    const data: BasicTemplateDto[] = [
      {
        name: '일반 사원급 기본 정책A',
        description: '사내 테스트를 위한 기본 정책입니다.',
        use: true,
        docPrint: PrintType.WATPRINT, //일반 문서 인쇄 모드
        secDocPrint: PrintType.WATPRINT, //보안 문서 인쇄 모드
        approval: false, //인쇄 시 결재
        approvalWatExcept: true, //워터마크 예외 신청
        selectWat: false, //워터마크 종류 사용자 선택
        watPrintLogSend: true, //워터마크 인쇄 로그 저장
        printLogSend: true, //일반 인쇄 로그 저장
        txtLogType: LogType.ALL, //텍스트 로그 타입
        txtLogValue: 0, //텍스트 로그 값 (매 n 페이지마다 또는 첫 페이지부터 n 페이지까지)
        imgLogType: LogType.FIRST, //이미지 로그 타입
        imgLogValue: 1, //이미지 로그 값 (매 n 페이지마다 또는 첫 페이지부터 n 페이지까지)
        pullPrinting: true, //pullprinting 사용
        tco: true, //인쇄 절감 사용
        txtSaving: 100, //텍스트 인쇄 농도
        imgSaving: 80, //이미지 인쇄 농도
        graphicSaving: 80, //그래프 인쇄 농도
        colorType: ColorType.COLOR, //흑백으로만 인쇄
        printLimit: false, //인쇄 매수 제한
        printLimitCount: 0, //인쇄 최대 허용 매수
        printLimitAlert: false, //최대 허용 매수 초과 시 사용자 알림 },
      },
      {
        name: '테스트 정책',
        description: '테스트를 위한 기본 정책 입니다.',
        use: true,
        docPrint: PrintType.PRINT, //일반 문서 인쇄 모드
        secDocPrint: PrintType.WATPRINT, //보안 문서 인쇄 모드
        approval: false, //인쇄 시 결재
        approvalWatExcept: true, //워터마크 예외 신청
        selectWat: true, //워터마크 종류 사용자 선택
        watPrintLogSend: true, //워터마크 인쇄 로그 저장
        printLogSend: false, //일반 인쇄 로그 저장
        txtLogType: LogType.ALL, //텍스트 로그 타입
        txtLogValue: 0, //텍스트 로그 값 (매 n 페이지마다 또는 첫 페이지부터 n 페이지까지)
        imgLogType: LogType.NONE, //이미지 로그 타입
        imgLogValue: 0, //이미지 로그 값 (매 n 페이지마다 또는 첫 페이지부터 n 페이지까지)
        pullPrinting: true, //pullprinting 사용
        tco: true, //인쇄 절감 사용
        txtSaving: 100, //텍스트 인쇄 농도
        imgSaving: 80, //이미지 인쇄 농도
        graphicSaving: 80, //그래프 인쇄 농도
        colorType: ColorType.COLOR, //흑백으로만 인쇄
        printLimit: false, //인쇄 매수 제한
        printLimitCount: 0, //인쇄 최대 허용 매수
        printLimitAlert: false, //최대 허용 매수 초과 시 사용자 알림 },
      },
    ].map((o, i) => ({ ...o, code: '', entryTs: times(i) }));

    this.dummyApi = DummyApi.of({
      entityName: 'BasicTemplate',
      createDto: (code, entity) => ({ ...entity, code, entryTs: Date.now() }),
      validation: (type, code, reqDto, data) => {
        data.forEach((dto) => {
          if (
            (type === 'create' && dto.name === reqDto.name) ||
            (type === 'update' && dto.code !== code && dto.name === reqDto.name)
          ) {
            throw new Error('이미 사용 중인 이름입니다.');
          }
        });
      },
      data,
    });
  }

  list(): Promise<BasicTemplateDto[]> {
    return this.dummyApi.list();
  }
  find(code: string): Promise<BasicTemplateDto> {
    return this.dummyApi.find(code);
  }
  create(reqDto: BasicTemplateReqDto): Promise<BasicTemplateDto> {
    return this.dummyApi.create(reqDto);
  }
  update(code: string, reqDto: BasicTemplateReqDto): Promise<BasicTemplateDto> {
    return this.dummyApi.update({ code, reqDto });
  }
  remove(code: string): Promise<void> {
    return this.dummyApi.remove(code);
  }
}

const basicTemplateApi: BasicTemplateApi = FSP_API_ENABLED
  ? new BasicTemplateServerApi()
  : new BasicTemplateDummyApi();

export default basicTemplateApi;
