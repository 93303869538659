import React from 'react';
import classNames from 'classnames';
import { CommonProps } from 'src/components/helpers/props';

interface DashBoardIconProps extends CommonProps {
  size?: 'md' | 'sm';
}

const DashBoardIcon: React.FC<DashBoardIconProps> = ({ classes, size = 'md' }) => {
  if (size === 'md') {
    return (
      <svg
        className={classNames('icon-root', classes)}
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        color="#6B7480"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="1.6665"
          y="11.6666"
          width="3.33333"
          height="6.66667"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <rect
          x="8.3335"
          y="1.66663"
          width="3.33333"
          height="16.6667"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <rect
          x="15"
          y="8.33337"
          width="3.33333"
          height="10"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  } else if (size === 'sm') {
    return (
      <svg
        className={classNames('icon-root', classes)}
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        color="#6B7480"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="1.6665"
          y="11.6666"
          width="3.33333"
          height="6.66667"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <rect
          x="8.3335"
          y="1.66663"
          width="3.33333"
          height="16.6667"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <rect
          x="15"
          y="8.33337"
          width="3.33333"
          height="10"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  } else return null;
};

export default DashBoardIcon;
