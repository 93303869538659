import React from 'react';
import classNames from 'classnames';

import { CommonProps } from 'src/components/helpers/props';

import './index.scss';

interface TermDescriptionProps extends CommonProps {
  term: string | React.ReactNode;
  description: string | React.ReactNode;
  inline?: boolean;
  highlight?: 'term' | 'description';
  space?: 'auto' | 'md' | 'sm';
  v?: 1 | 2;
}

const TermDescription: React.FC<TermDescriptionProps> = ({
  term,
  description,
  inline = false,
  highlight = 'description',
  space = 'sm',
  v = 1,
  classes,
}) => {
  return (
    <dl
      className={classNames(
        'term-description-root',
        {
          'term-description-highlight-term': highlight === 'term',
          'term-description-highlight-description': highlight === 'description',
          'term-description-space-auto': space === 'auto',
          'term-description-space-md': space === 'md',
          'term-description-space-sm': space === 'sm',
          'term-description-inline': inline,
          'term-description-v2': v === 2,
        },
        classes
      )}
    >
      <dt>{term}</dt>
      <dd>{description}</dd>
    </dl>
  );
};

export default TermDescription;
