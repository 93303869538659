import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { selectInProgress, startProgress, stopProgress, resetProgress } from 'src/store/progress';

// # hooks
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const useProgressStore = () => {
  const inProgress = useSelector(selectInProgress);

  const dispatch = useDispatch();

  const boundStartProgress = useCallback(() => dispatch(startProgress()), [dispatch]);
  const boundStopProgress = useCallback(() => dispatch(stopProgress()), [dispatch]);
  const boundResetProgress = useCallback(() => dispatch(resetProgress()), [dispatch]);

  return {
    inProgress,
    startProgress: boundStartProgress,
    stopProgress: boundStopProgress,
    resetProgress: boundResetProgress,
  };
};

export default useProgressStore;
