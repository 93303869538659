// import { combineReducers, createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { useDispatch } from 'react-redux';
import { combineReducers, configureStore } from '@reduxjs/toolkit';

import policy from './policy';
import basicTemplate from './basicTemplate';
import detectionRule from './detectionRule';
import detectionTemplate from './detectionTemplate';
import pattern from './infra/pattern';
import watermarkFile from './watermarkFile';
import watermarkTemplate from './watermarkTemplate';
import except from './except';
import configuration from './configuration';
import license from './license';
import productSpec from './productSpec';
import progress from './progress';
import commonTemplate from './infra/commonTemplate';
import domainConfig from './infra/domainConfig';
import printer from './infra/printer';
import program from './infra/program';
import url from './infra/url';
import organ from './fidp/organ';
import user from './fidp/user';
import dept from './fidp/dept';
import role from './fidp/role';
import position from './fidp/position';
import commonPolicy from './infra/commonPolicy';
import patternModule from './infra/patternModule';
import patternGroup from './infra/patternGroup';
import auth from './auth/auth';

export const rootReducer = combineReducers({
  policy,
  organ,
  user,
  dept,
  role,
  position,
  basicTemplate,
  detectionRule,
  detectionTemplate,
  pattern,
  watermarkFile,
  watermarkTemplate,
  except,
  configuration,
  productSpec,
  progress,
  commonTemplate,
  domainConfig,
  printer,
  program,
  url,
  commonPolicy,
  patternModule,
  patternGroup,
  auth,
  license,
});

// export default createStore(rootReducer, applyMiddleware(thunk));
const store = configureStore({
  reducer: rootReducer,
});
export default store;

export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useAppDispatch = () => useDispatch<AppDispatch>();
