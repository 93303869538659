import moment from 'moment';

import config from 'src/shared/config';
import axios from 'src/shared/utils/axios';

import DummyApi from './dummyApi';
import { times } from './api';

const FSP_API_ENABLED = config.servers.fsp.enabled;
const FSP_API_URL = config.servers.fsp.url;

enum ContentType {
  NONE = 'NONE',
  URL = 'URL',
  PROGRAM = 'PROGRAM',
  PRINT = 'PRINT',
}

export enum ExceptType {
  // NONE = 'NONE',
  URL = 'URL',
  PROGRAM = 'PROGRAM',
  PRINT_DRIVER = 'PRINT_DRIVER',
  PRINT_IP = 'PRINT_IP',
}

export enum SubExceptType {
  NONE = 'NONE',
  INC_SUBPATH = 'INC_SUBPATH',
}

export enum WatExceptType {
  NONE = 'NONE',
  PRINT = 'PRINT',
  WATPRINT = 'WATPRINT',
  USERSELECT = 'USERSELECT',
}

export interface ExceptDto {
  code: string;
  type: ExceptType;
  contentCode: string;
  use: boolean;
  entryTs: number;

  subType?: SubExceptType;
  exceptAll: boolean;
  exceptWat: WatExceptType;
  exceptWatLog: boolean;
  exceptDetection: boolean;
  exceptDetectionLog: boolean;
}

export type ExceptReqDto = Omit<ExceptDto, 'code' | 'entryTs' | 'contentCode'> & {
  contentCodes: string[];
};

export const defaultExceptReqDto = (type: ExceptType): ExceptReqDto => {
  return {
    type,
    contentCodes: [],
    use: true,

    subType: type === ExceptType.URL ? SubExceptType.INC_SUBPATH : SubExceptType.NONE,
    exceptAll: true,
    exceptWat: WatExceptType.PRINT,
    exceptWatLog: true,
    exceptDetection: true,
    exceptDetectionLog: true,
  };
};

interface ExceptApi {
  listByType(type: ExceptType): Promise<ExceptDto[]>;
  find(code: string): Promise<ExceptDto>;
  create(reqDto: ExceptReqDto): Promise<ExceptDto>;
  update(code: string, reqDto: ExceptReqDto): Promise<ExceptDto>;
  remove(code: string): Promise<void>;
}
class ExceptServerApi implements ExceptApi {
  async listByType(type: ExceptType): Promise<ExceptDto[]> {
    const resp = await axios.get<ExceptDto[]>(`${FSP_API_URL}/api/excepts`, { params: { type } });
    return resp.data;
  }
  async find(code: string): Promise<ExceptDto> {
    const resp = await axios.get<ExceptDto>(`${FSP_API_URL}/api/excepts/${code}`);
    return resp.data;
  }
  async create(reqDto: ExceptReqDto): Promise<ExceptDto> {
    const resp = await axios.post<ExceptDto>(`${FSP_API_URL}/api/excepts`, reqDto);
    return resp.data;
  }
  async update(code: string, reqDto: ExceptReqDto): Promise<ExceptDto> {
    const resp = await axios.put<ExceptDto>(`${FSP_API_URL}/api/excepts/${code}`, reqDto);
    return resp.data;
  }
  async remove(code: string): Promise<void> {
    await axios.delete<ExceptDto>(`${FSP_API_URL}/api/excepts/${code}`);
  }
}
class ExceptDummyApi implements ExceptApi {
  dummyApi: DummyApi<ExceptDto, ExceptReqDto>;
  constructor() {
    const data: ExceptDto[] = [
      {
        type: ExceptType.URL,
        contentCode: 'code-1',
        use: true,
        subType: SubExceptType.NONE,
        exceptAll: false,
        exceptWat: WatExceptType.WATPRINT,
        exceptWatLog: false,
        exceptDetection: true,
        exceptDetectionLog: false,
      },
      {
        type: ExceptType.URL,
        contentCode: 'code-2',
        use: true,
        subType: SubExceptType.INC_SUBPATH,
        exceptAll: true,
        exceptWat: WatExceptType.PRINT,
        exceptWatLog: true,
        exceptDetection: true,
        exceptDetectionLog: true,
      },
      {
        type: ExceptType.PROGRAM,
        contentCode: 'code-1',
        use: true,
        exceptAll: false,
        exceptWat: WatExceptType.WATPRINT,
        exceptWatLog: false,
        exceptDetection: true,
        exceptDetectionLog: false,
      },
      {
        type: ExceptType.PROGRAM,
        contentCode: 'code-2',
        use: true,
        exceptAll: true,
        exceptWat: WatExceptType.PRINT,
        exceptWatLog: true,
        exceptDetection: true,
        exceptDetectionLog: true,
      },
      {
        type: ExceptType.PRINT_DRIVER,
        contentCode: 'code-1',
        use: true,
        exceptAll: false,
        exceptWat: WatExceptType.WATPRINT,
        exceptWatLog: false,
        exceptDetection: true,
        exceptDetectionLog: false,
      },
      {
        type: ExceptType.PRINT_DRIVER,
        contentCode: 'code-2',
        use: true,
        exceptAll: true,
        exceptWat: WatExceptType.PRINT,
        exceptWatLog: true,
        exceptDetection: true,
        exceptDetectionLog: true,
      },
      {
        type: ExceptType.PRINT_IP,
        contentCode: 'code-1',
        use: true,
        exceptAll: false,
        exceptWat: WatExceptType.WATPRINT,
        exceptWatLog: false,
        exceptDetection: true,
        exceptDetectionLog: false,
      },
      {
        type: ExceptType.PRINT_IP,
        contentCode: 'code-2',
        use: true,
        exceptAll: true,
        exceptWat: WatExceptType.PRINT,
        exceptWatLog: true,
        exceptDetection: true,
        exceptDetectionLog: true,
      },
    ].map((o, i) => ({ ...o, code: '', entryTs: times(i) }));

    this.dummyApi = DummyApi.of({
      entityName: 'Except',
      createDto: (code, entity) => {
        const { contentCodes, ...rest } = entity;
        return {
          ...rest,
          code,
          contentCode: contentCodes[0],
          entryTs: Date.now(),
        };
      },
      validation: (type, code, req, data) => {
        data.forEach((dto) => {
          if (dto.type !== req.type) {
            return;
          }
          if (
            (type === 'create' && req.contentCodes.includes(dto.contentCode)) ||
            (type === 'update' && dto.code !== code && req.contentCodes.includes(dto.contentCode))
          ) {
            throw new Error('대상이 중복되었습니다.');
          }
        });
      },
      data,
    });
  }

  async listByType(type: ExceptType): Promise<ExceptDto[]> {
    const list = await this.dummyApi.list();
    return list.filter((dto) => dto.type === type);
  }
  find(code: string): Promise<ExceptDto> {
    return this.dummyApi.find(code);
  }
  create(reqDto: ExceptReqDto): Promise<ExceptDto> {
    return this.dummyApi.create(reqDto);
  }
  update(code: string, reqDto: ExceptReqDto): Promise<ExceptDto> {
    return this.dummyApi.update({ code, reqDto });
  }
  remove(code: string): Promise<void> {
    return this.dummyApi.remove(code);
  }
}

const exceptApi: ExceptApi = FSP_API_ENABLED ? new ExceptServerApi() : new ExceptDummyApi();

export default exceptApi;
