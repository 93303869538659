import _ from 'lodash';

import config from 'src/shared/config';
import axios from 'src/shared/utils/axios';
import DummyApi from '../dummyApi';

const FIDP_API_ENABLED = config.servers.fidp.enabled;
const FIDP_API_URL = config.servers.fidp.url;

export const ALL_ROLE_POSITION = 'zzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzz';

export interface OrganDto {
  id: string; // 부서 코드 또는 사용자 ID
  name: string; // 부서 또는 사용자 이름
  type: 'dept' | 'user' | 'dummy' | 'dummyRoot'; // 트리 노드 종류
  // TODO
  /** ==> 더미가 어떻게 되어있는지 재환수석님 확인 필요... dummy type???
   * type (Type): 트리 노드 종류
dept: 부서
user: 사용자
dummy: 더미
트리 컨트롤의 +/- 표시를 출력하기 위한 더미 노드
자식 노드가 없는 부서 노드에 하나 추가
dummyRoot: 더미 루트
중간 관리자인 경우 표시하는 관리 부서 목록을 묶는 최상위 노드
   */
  children: OrganDto[];
}

interface OrganApi {
  tree(): Promise<OrganDto[]>;
  treeById(id: string): Promise<OrganDto[]>;
}

class OrganServerApi implements OrganApi {
  async tree(): Promise<OrganDto[]> {
    const resp = await axios.get<OrganDto[]>(`${FIDP_API_URL}/api/organs/tree2?onlyDept=N&root=Y`);
    return resp.data;
  }
  async treeById(id: string): Promise<OrganDto[]> {
    const resp = await axios.get<OrganDto[]>(
      `${FIDP_API_URL}/api/organs/tree2?onlyDept=N&path=${id}`
    );
    return resp.data;
  }
}

class OrganDummyApi implements OrganApi {
  async tree(): Promise<OrganDto[]> {
    // const resp = await axios.get<OrganDto[]>(`${ORGAN_SERVICE_URL}/tree2?onlyDept=N&root=Y`);
    // return resp.data;
    return [
      {
        id: 'COMPANY',
        name: 'Fasoo',
        type: 'dept',
        children: [
          {
            id: '18ee01d6a1654c7581a54d470fb6e724',
            name: '경선부서',
            type: 'dept',
            children: [],
          },
          {
            id: '587acdd296f54bd4bbbb43d95158ffc4',
            name: '다인부서',
            type: 'dept',
            children: [],
          },
          {
            id: 'iu',
            name: '아이유',
            type: 'user',
            children: [],
          },
          {
            id: 'dhjang',
            name: '장동혁',
            type: 'user',
            children: [],
          },
          {
            id: 'admin',
            name: 'Administrator',
            type: 'user',
            children: [],
          },
          {
            id: 'ksson',
            name: 'ks',
            type: 'user',
            children: [],
          },
        ],
      },
    ];
  }

  async treeById(id: string): Promise<OrganDto[]> {
    // const resp = await axios.get<OrganDto[]>(`${ORGAN_SERVICE_URL}/tree2?onlyDept=N&path=${id}`);
    //return resp.data;
    if (id === '18ee01d6a1654c7581a54d470fb6e724')
      return [{ id: 'jksall', name: '경선1', type: 'user', children: [] }];
    else if (id === '587acdd296f54bd4bbbb43d95158ffc4')
      return [{ id: 'dain2', name: '다인2', type: 'user', children: [] }];
    else return [];
  }
}

const organApi: OrganApi = FIDP_API_ENABLED ? new OrganServerApi() : new OrganDummyApi();

export default organApi;
