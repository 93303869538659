import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import configurationApi, {
  ConfigurationDto,
  ConfigurationReqDto,
  LogQuality,
  LogSizeType,
} from 'src/api/configurationApi';
import { RootState } from 'src/store';

// # types
export type ConfigurationEntity = ConfigurationDto;
export type ConfigurationReqEntity = ConfigurationReqDto;

function toEntity(dto: ConfigurationDto): ConfigurationEntity {
  return { ...dto };
}
function toReqDto(entity: ConfigurationReqEntity): ConfigurationReqDto {
  return {
    imageLog: entity.imageLog,
    imageLogSizeType: entity.imageLogSizeType,
    imageLogSize: entity.imageLogSize,
    detectionLog: entity.detectionLog,
    detectionLogSizeType: entity.detectionLogSizeType,
    detectionLogSize: entity.detectionLogSize,
    showPolicy: entity.showPolicy,
    emergencyPrint: entity.emergencyPrint,
    emergencyPrintExpiredReboot: entity.emergencyPrintExpiredReboot,
    printLogExportField: entity.printLogExportField,
    detectionLogExportField: entity.detectionLogExportField,
    pullprintingValidPeriod: entity.pullprintingValidPeriod,
    classification: entity.classification,
  };
}

interface ConfigurationState {
  initialized: boolean;
  entity: ConfigurationEntity;
}

// # initial state
const initialState: ConfigurationState = {
  initialized: false,
  entity: {
    imageLog: LogQuality.HIGH,
    imageLogSizeType: LogSizeType.FIT,
    imageLogSize: 2000,
    detectionLog: LogQuality.HIGH,
    detectionLogSizeType: LogSizeType.FIT,
    detectionLogSize: 2000,
    showPolicy: false,
    emergencyPrint: false,
    emergencyPrintExpiredReboot: false,
    printLogExportFieldInit: [
      'user_code',
      'user_name',
      'user_dept_code',
      'user_dept_name',
      'log_date',
      'doc_name',
      'print_page_count',
      'print_watermark_yn',
      'image_upload_status',
      'trans_id',
      'text_file_yn',
      'image_file_yn',
    ],
    printLogExportField: [
      'user_code',
      'user_name',
      'user_dept_code',
      'user_dept_name',
      'log_date',
      'doc_name',
      'print_page_count',
      'print_watermark_yn',
      'image_upload_status',
      'trans_id',
      'text_file_yn',
      'image_file_yn',
    ],
    detectionLogExportFieldInit: [
      'user_code',
      'user_name',
      'user_dept_code',
      'user_dept_name',
      'log_date',
      'print_date',
      'doc_name',
      'print_page_count',
      'product',
      'approval_type',
      'print_status',
      'image_upload_status',
      'trans_id',
      'text_file_yn',
      'image_file_yn',
    ],
    detectionLogExportField: [
      'user_code',
      'user_name',
      'user_dept_code',
      'user_dept_name',
      'log_date',
      'print_date',
      'doc_name',
      'print_page_count',
      'product',
      'approval_type',
      'print_status',
      'image_upload_status',
      'trans_id',
      'text_file_yn',
      'image_file_yn',
    ],
    pullprintingValidPeriod: 30,
    classification: [
      {
        targetCode: 'FED5-FSN 문서',
        dsdCode: '0100000000001001',
        type: 'FED5-FSN',
        description: 'FED5-N 문서 설명입니다.',
      },
      {
        targetCode: 'FED5-FSD 문서',
        dsdCode: '0100000000000102',
        type: 'FED5-FSD',
        description: 'FED5-R 문서 설명입니다.',
      },
      {
        targetCode: 'WRAPSODY용',
        dsdCode: '0100000000001001',
        type: 'WRAPSODY',
        description: 'Wrapsody 문서 설명입니다.',
      },
    ],
    product: [
      {
        dsdCode: '0100000000001001',
        type: 'FED5-FSN',
      },
      {
        dsdCode: '0100000000000102',
        type: 'FED5-FSD',
      },
      {
        dsdCode: '0100000000001001',
        type: 'WRAPSODY',
      },
    ],
  },
};

// # thunks
export const thunkLoadConfigurations = createAsyncThunk('configuration/load', async () => {
  const dto = await configurationApi.get();
  return toEntity(dto);
});
export const thunkUpdateConfiguration = createAsyncThunk(
  'configuration/update',
  async (reqEntity: ConfigurationReqEntity) => {
    const dto = await configurationApi.update(toReqDto(reqEntity));
    return toEntity(dto);
  }
);

const configurationSlice = createSlice({
  name: 'configuration',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(thunkLoadConfigurations.fulfilled, (state, { payload: configuration }) => {
      state.entity = configuration;
      state.initialized = true;
    });
    builder.addCase(thunkUpdateConfiguration.fulfilled, (state, { payload: updatedEntity }) => {
      state.entity = updatedEntity;
    });
  },
});

// # selectors
export const selectInitialized = (state: RootState): boolean => {
  return state.configuration.initialized;
};
export const selectConfigurationEntity = (state: RootState): ConfigurationEntity =>
  state.configuration.entity;

export default configurationSlice.reducer;
