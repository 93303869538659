import React from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';

import useAuthStore from 'src/hooks/auth/useAuthStore';
import config from 'src/shared/config';
import { mainPageRouteTables, routeTables } from 'src/shared/routes';
import { getFirstAllowedMenuPath } from 'src/shared/utils/menuUtils';

const RootPage: React.FC = () => {
  const { authEntity } = useAuthStore();
  // const path =
  //   getFirstAllowedMenuPath(authEntity ? authEntity.menus : [], mainPageRouteTables) ||
  //   config.url('/errors/forbidden');
  let path = `/projects`; // TODO (hyhan) 도공 23일 버전 하드코딩
  if (authEntity?.permissions !== undefined && authEntity?.permissions.length > 0) {
    if (!authEntity.permissions.includes('admin') && authEntity.permissions.includes('system')) {
      path = `/setting`;
    }
  }
  return <Redirect to={path} />;
};

export default RootPage;
