import React, { useEffect, useState, useContext, Fragment } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { CommonProps } from 'src/components/helpers/props';
import Popup2 from 'src/components/molecules/Popup2';
import templateApi, { TemplateDto, TaskDto, DetailDto, createTemplate, createAudit, createDetail, AuditDto, } from 'src/api/templateApi';
import Button from 'src/components/atom/Button';
import TextField from 'src/components/atom/TextField';
import './Step1EditPopup.scss';
import AddCircle2Icon from 'src/components/atom/icons/AddCircle2Icon';
import TrashIcon from 'src/components/atom/icons/TrashIcon';
import IconButton from 'src/components/atom/IconButton';
import { useAlert } from 'src/contexts/AlertContext';
import { validateAll, validateAll2 } from 'src/shared/utils/validations';
import _, { omit } from 'lodash';

interface Step1EditPopupProps extends CommonProps {
  open: boolean;
  account: any;
  onConfirm: (opinions: any) => void;
  onClose: () => void;
}


const Step1EditPopup: React.FC<Step1EditPopupProps> = ({
  open,
  account,
  onClose,
  onConfirm,
  classes,
}) => {
  const { t } = useTranslation(['commons']);
  const { alert2 } = useAlert();
  const [opinions, setOpinions] = useState<any>(account);
  const ceoInsists = [{ key: "A", order: 1, }, { key: "C", order: 2, }, { key: "CO", order: 3, }, { key: "E", order: 4, },
  { key: "O", order: 5, }, { key: "V", order: 6, }, { key: "RO", order: 7, }, { key: "CL", order: 8, }, { key: "U", order: 9, }, { key: "P", order: 10, }];
  const validationRules = {
    // id: _.trim(project.id).length > 0 && validationId(project.id),
    // name: _.trim(form.name).length > 0,
    // projectRole: selectedUsers.length > 0,
    name: opinions?.detail_sub_content_list?.every((item: any) => item.name.length > 0),
    opinions: opinions?.detail_sub_content_list?.every((item: any) => item.management_assertion_list.some((elem: any) => elem.checked))
  };

  const handleOk = (): void => {
    const r = validateAll2(validationRules)
    if (!r.retVal) {
      if (r.key === "name") {
        alert2("감사절차 수정", "계정명은 필수 입력값입니다.");
      } else if (r.key === "opinions") {
        alert2("감사절차 수정", "경영진 주장은 한 개 이상 선택 해주세요.");
      }
      return;
    }
    // if (!validateAll2(validationRules)) {
    //   alert2("감사절차 수정", "계정명은 필수 입력값입니다.");
    //   return;
    // }

    onConfirm(opinions)
    onClose();
  };

  useEffect(() => {
  }, []);

  const handleClose = () => {
    onClose();
  }

  const onClickItem = (i: number, j: number) => {
    let newOpinions = { ..._.cloneDeep(opinions) };
    newOpinions.detail_sub_content_list[i].management_assertion_list[j].checked = !newOpinions.detail_sub_content_list[i].management_assertion_list[j].checked
    setOpinions(newOpinions)
  }

  const handleDetailAdd = () => {
    let newOpinions = { ..._.cloneDeep(opinions) };
    if (!newOpinions.detail_sub_content_list) {
      newOpinions.detail_sub_content_list = [];
    }
    newOpinions.detail_sub_content_list.push({ name: "", management_assertion_list: ceoInsists })
    setOpinions(newOpinions)
  }

  const handleDeleteAccount = (i: number) => {
    let newOpinions = { ..._.cloneDeep(opinions) };
    newOpinions.detail_sub_content_list.splice(i, 1)
    setOpinions(newOpinions)
  }

  const handleAccountNameChange = (e: React.ChangeEvent<HTMLInputElement>, i: number) => {
    e.stopPropagation();
    let newOpinions = { ..._.cloneDeep(opinions) };
    newOpinions.detail_sub_content_list[i].name = e.target.value;
    setOpinions(newOpinions)
  }

  return (
    <Popup2
      classes="audit-popup-root project-popup-root project-common"
      title={`감사절차 수정(${opinions.name})`}
      width="1112px"
      height="692px"
      open={open}
      onClose={handleClose}
      footer={
        <React.Fragment>
          <div className="detail-add">
            <div
              onClick={handleDetailAdd}
            >
              <AddCircle2Icon color="#295c9f" />
              <span>감사절차 추가</span>
            </div>
          </div>
          <Button text={t('commons:cancel')} onClick={handleClose} color="secondary" />
          <Button
            text={'확인'}
            onClick={handleOk}
          // disabled={!selectedUsers} 
          />
        </React.Fragment>
      }
    >
      <div className="task-header element">
        <div className="title-content title" style={{ paddingLeft: 15, width: "40%", color: "#a3a6b4" }}>{opinions?.title}</div>
        <div className="title-account account" style={{ width: "60%", color: "#a3a6b4" }}>경영진 주장</div>
      </div>
      <div className="audit-process-content-step1-root">
        {opinions?.detail_sub_content_list?.map((elem: any, i: number) => {
          return (
            <div className="audit-process-content-step1" key={i.toString()}>
              <div className="audit-process-content-title">
                <TextField classes="audit-process-content-title-edit"
                  value={elem?.name || ""}
                  autoFocus={!elem?.name ? true : false}
                  placeholder="계정명을 입력하세요."
                  onChange={(e) => { handleAccountNameChange(e, i) }}
                />
              </div>
              <div className="audit-process-content-data">
                {elem?.management_assertion_list.map((item: any, j: number) => {
                  return (
                    <div key={j.toString()} className="audit-process-content-data-item" style={{ backgroundColor: item.checked ? "#295c9f" : "white", color: item.checked ? "#ffffff" : "#4d4f5c" }}
                      onClick={() => onClickItem(i, j)}
                    >
                      <div>{item.key}</div>
                    </div>
                  )
                })
                }
              </div>
              <div className="audit-process-content-delete">
                <IconButton onClick={() => handleDeleteAccount(i)}>
                  <TrashIcon></TrashIcon>
                </IconButton>

              </div>
            </div>
          )
        })

        }
      </div>


    </Popup2>
  );
};

export default Step1EditPopup;
