import React from 'react';
import classNames from 'classnames';

import { CommonProps } from 'src/components/helpers/props';

import './AbstractIcon.scss';

interface BreadcrumbArrowIconProps extends CommonProps {
  size?: 'sm' | 'xs';
}

const BreadcrumbArrowIcon: React.FC<BreadcrumbArrowIconProps> = ({ classes, size = 'sm' }) => {
  if (size === 'sm') {
    return (
      <svg
        className={classNames('icon-root', classes)}
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4.7998 14.4L11.1998 8.00002L4.7998 1.60002"
          stroke="#6B7480"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  } else if (size === 'xs') {
    return (
      <svg
        className={classNames('icon-root', classes)}
        width="10"
        height="10"
        viewBox="0 0 10 10"
        color="#6B7480"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3 9L7 5L3 0.999998"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  } else return null;
};

export default BreadcrumbArrowIcon;
