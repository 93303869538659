import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { UseYN } from 'src/api/infra';
import patternApi, {
  NO_PATTERN_GROUP_CODE,
  PatternDto,
  PatternReqDto,
  Regexp,
} from 'src/api/infra/patternApi';
import patternGroupApi from 'src/api/infra/patternGroupApi';
import { toLocalDateTimeStr } from 'src/shared/utils/localDateTime';
import { RootState } from 'src/store';

// # types
export interface PatternEntity extends PatternDto {
  entryTsStr: string;
}
function toEntity(dto: PatternDto): PatternEntity {
  return { ...dto, entryTsStr: toLocalDateTimeStr(dto.entryTime) };
}

function toDto(entity: PatternEntity): PatternDto {
  return { ...entity };
}

interface PatternEntityByCode {
  [code: string]: PatternEntity;
}
interface PatternEntities {
  byCode: PatternEntityByCode;
  allCodes: string[];
}
interface PatternState {
  initialized: boolean;
  entities: PatternEntities;
}

export interface PatternUpdateReq {
  code: string;
  reqDto: PatternReqDto;
}

export interface PatternUpdateUseReq {
  code: string;
  reqDto: PatternReqDto;
  use: boolean;
}

// # initial state
const initialState: PatternState = {
  initialized: false,
  entities: { byCode: {}, allCodes: [] },
};

export const defaultPatternReqDto: PatternReqDto = {
  name: '',
  description: '',
  useYN: UseYN.Y,

  color: '#000000',
  moduleCode: null,
  exceptionUseYN: UseYN.N, // 마스킹 예외 사용안함
  maskingUseYN: UseYN.N, // 마스킹 사용 안함
  maskingStart1: 0,
  maskingLength1: 0,
  maskingStart2: 0,
  maskingLength2: 0,
  maskingStart3: 0,
  maskingLength3: 0,
  groupCode: NO_PATTERN_GROUP_CODE,
  uid: '',
  regexpCount: 0,
  exceptRegexpCount: 0,
  regexps: [],
  exceptRegexps: [],
};

// # thunks
export const thunkLoadPatterns = createAsyncThunk('pattern/load', async () => {
  const list = await patternApi.list();
  return list.map((dto) => toEntity(dto));
});
export const thunkCreatePattern = createAsyncThunk(
  'pattern/create',
  async (reqDto: PatternReqDto) => {
    return toEntity(await patternApi.create(reqDto));
  }
);
export const thunkUpdatePattern = createAsyncThunk(
  'pattern/update',
  async ({ code, reqDto }: PatternUpdateReq) => {
    return toEntity(await patternApi.update(code, reqDto));
  }
);
export const thunkUpdatePatternUse = createAsyncThunk(
  'pattern/update/use',
  async ({ code, reqDto, use }: PatternUpdateUseReq) => {
    const newDto = { ...reqDto, use };
    return toEntity(await patternApi.update(code, newDto));
  }
);
export const thunkRemovePattern = createAsyncThunk('pattern/remove', async (code: string) => {
  return patternApi.remove(code);
});

const patternSlice = createSlice({
  name: 'pattern',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(thunkLoadPatterns.fulfilled, (state, { payload: patterns }) => {
      state.entities.byCode = patterns.reduce((acc, t) => {
        acc[t.code] = t;
        return acc;
      }, {} as PatternEntityByCode);
      state.entities.allCodes = patterns.map((t) => t.code);
      state.initialized = true;
    });
    builder.addCase(thunkCreatePattern.fulfilled, (state, { payload: createdEntity }) => {
      state.entities.byCode[createdEntity.code] = createdEntity;
      state.entities.allCodes.push(createdEntity.code);
    });
    builder.addCase(thunkUpdatePattern.fulfilled, (state, { payload: updatedEntity }) => {
      state.entities.byCode[updatedEntity.code] = updatedEntity;
    });
    builder.addCase(thunkUpdatePatternUse.fulfilled, (state, { payload: updatedEntity }) => {
      state.entities.byCode[updatedEntity.code] = updatedEntity;
    });
    builder.addCase(thunkRemovePattern.fulfilled, (state, { meta: { arg: removedCode } }) => {
      delete state.entities.byCode[removedCode];
      state.entities.allCodes = state.entities.allCodes.filter((code) => code !== removedCode);
    });
  },
});

// # selectors
export const selectInitialized = (state: RootState): boolean => {
  return state.pattern.initialized;
};
export const selectPatternEntities = (state: RootState): PatternEntities => state.pattern.entities;
export const selectPatternEntityList = (state: RootState): PatternEntity[] =>
  state.pattern.entities.allCodes.map((code) => state.pattern.entities.byCode[code]);

export default patternSlice.reducer;

// 참고중...
// https://orizens.com/blog/how-to-not-have-a-mess-with-react-hooks-and-redux/
