import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { UseYN } from 'src/api/infra';
import commonTemplateApi, {
  CommonTemplateDto,
  CommonTemplateReqDto,
} from 'src/api/infra/commonTemplateApi';
import { toLocalDateTimeStr } from 'src/shared/utils/localDateTime';
import { RootState } from 'src/store';

// # types
export interface CommonTemplateEntity {
  code: string;
  name: string;
  description: string;
  use: boolean;
  entryTsStr: string;

  autoLogout: boolean;
  autoLogoutMinute: number;
  offlineLogin: boolean;
  offlineLoginMinute: number;
}
export type CommonTemplateReqEntity = Omit<CommonTemplateEntity, 'code' | 'entryTsStr'>;

function toEntity(dto: CommonTemplateDto): CommonTemplateEntity {
  return {
    code: dto.code,
    name: dto.name,
    description: dto.description,
    use: dto.useYN === UseYN.Y,
    entryTsStr: toLocalDateTimeStr(dto.entryTime),

    autoLogout: dto.autoLogoutUseYN === UseYN.Y,
    autoLogoutMinute: dto.autoLogoutMinute,
    offlineLogin: dto.offlineLoginUseYN === UseYN.Y,
    offlineLoginMinute: dto.offlineLoginMinute,
  };
}
function toReqDto(entity: CommonTemplateReqEntity): CommonTemplateReqDto {
  return {
    name: entity.name,
    description: entity.description,
    useYN: entity.use ? UseYN.Y : UseYN.N,

    autoLogoutUseYN: entity.autoLogout ? UseYN.Y : UseYN.N,
    autoLogoutMinute: entity.autoLogoutMinute,
    offlineLoginUseYN: entity.offlineLogin ? UseYN.Y : UseYN.N,
    offlineLoginMinute: entity.offlineLoginMinute,
  };
}

interface CommonTemplateEntityByCode {
  [code: string]: CommonTemplateEntity;
}
export interface CommonTemplateEntities {
  byCode: CommonTemplateEntityByCode;
  allCodes: string[];
}
interface CommonTemplateState {
  initialized: boolean;
  entities: CommonTemplateEntities;
}

export interface CommonTemplateUpdateReq {
  code: string;
  reqEntity: CommonTemplateReqEntity;
}

// # initial state
const initialState: CommonTemplateState = {
  initialized: false,
  entities: { byCode: {}, allCodes: [] },
};

export const defaultCommonTemplateReqEntity: CommonTemplateReqEntity = {
  name: '',
  description: '',
  use: true,

  autoLogout: false,
  autoLogoutMinute: 1,
  offlineLogin: false,
  offlineLoginMinute: 1,
};

// # thunks
export const thunkLoadCommonTemplates = createAsyncThunk('infra/commonTemplate/load', async () => {
  const list = await commonTemplateApi.list();
  return list.map((dto) => toEntity(dto));
});
export const thunkCreateCommonTemplate = createAsyncThunk(
  'infra/commonTemplate/create',
  async (reqEntity: CommonTemplateReqEntity) => {
    return toEntity(await commonTemplateApi.create(toReqDto(reqEntity)));
  }
);
export const thunkUpdateCommonTemplate = createAsyncThunk(
  'infra/commonTemplate/update',
  async ({ code, reqEntity }: CommonTemplateUpdateReq) => {
    return toEntity(await commonTemplateApi.update(code, toReqDto(reqEntity)));
  }
);
export const thunkRemoveCommonTemplate = createAsyncThunk(
  'infra/commonTemplate/remove',
  async (code: string) => {
    return commonTemplateApi.remove(code);
  }
);

const commonTemplateSlice = createSlice({
  name: 'commonTemplate',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(thunkLoadCommonTemplates.fulfilled, (state, { payload: commonTemplates }) => {
      state.entities.byCode = commonTemplates.reduce((acc, t) => {
        acc[t.code] = t;
        return acc;
      }, {} as CommonTemplateEntityByCode);
      state.entities.allCodes = commonTemplates.map((t) => t.code);
      state.initialized = true;
    });
    builder.addCase(thunkCreateCommonTemplate.fulfilled, (state, { payload: createdEntity }) => {
      state.entities.byCode[createdEntity.code] = createdEntity;
      state.entities.allCodes.push(createdEntity.code);
    });
    builder.addCase(thunkUpdateCommonTemplate.fulfilled, (state, { payload: updatedEntity }) => {
      state.entities.byCode[updatedEntity.code] = updatedEntity;
    });
    builder.addCase(
      thunkRemoveCommonTemplate.fulfilled,
      (state, { meta: { arg: removedCode } }) => {
        delete state.entities.byCode[removedCode];
        state.entities.allCodes = state.entities.allCodes.filter((code) => code !== removedCode);
      }
    );
  },
});

// # selectors
export const selectInitialized = (state: RootState): boolean => {
  return state.commonTemplate.initialized;
};
export const selectCommonTemplateEntities = (state: RootState): CommonTemplateEntities =>
  state.commonTemplate.entities;
export const selectCommonTemplateEntityList = (state: RootState): CommonTemplateEntity[] =>
  state.commonTemplate.entities.allCodes.map((code) => state.commonTemplate.entities.byCode[code]);
export const selectUseCommonTemplateEntityList = (state: RootState): CommonTemplateEntity[] =>
  state.commonTemplate.entities.allCodes
    .filter((code) => state.commonTemplate.entities.byCode[code].use === true)
    .map((code) => state.commonTemplate.entities.byCode[code]);

export default commonTemplateSlice.reducer;
