import config from 'src/shared/config';
import axios from 'src/shared/utils/axios';

import DummyApi from '../dummyApi';
import { times } from '../api';
import _ from 'lodash';

const FIDP_API_ENABLED = config.servers.fidp.enabled;
const FIDP_API_URL = config.servers.fidp.url;

export interface DeptDto {
  code: string; //도메인 코드
  name: string; //부서 코드
  dsdCode: string; //이름
  managerId: string; //부서장 ID
  managerName: string; //부서장 이름
  parentCode: string; //상위 부서 코드
  parentName: string; //상위 부서 이름
  linkedYN: 'Y' | 'N'; //연동 여부
  updatedTime: number; //수정 일시
  subDeptCount: number; //하위 부서 수
  userCount: number; //부서원 수
}

interface DeptApi {
  list(deptCodes: string[]): Promise<DeptDto[]>;
}

// TODO. 부서 목록을 조회할 때 POST로 변경해야함. url길이
class DeptServerApi implements DeptApi {
  async list(deptCodes: string[]): Promise<DeptDto[]> {
    const resp = await axios.get<DeptDto[]>(
      `${FIDP_API_URL}/api/organs/depts?codes%5B%5D=${deptCodes.join(',')}`
    );
    return resp.data;
  }
}

class DeptDummyApi implements DeptApi {
  private data: DeptDto[];

  constructor() {
    this.data = [
      {
        dsdCode: '0100000000000301',
        code: 'c137da14690348dcbdf3f4ab752d91f0',
        name: '개발본부',
        managerId: '',
        managerName: '',
        parentCode: 'COMPANY',
        parentName: 'fasoo',
        linkedYN: 'N',
        updatedTime: 1590036821146,
        subDeptCount: 0,
        userCount: 0,
      },
      {
        dsdCode: '0100000000000301',
        code: 'COMPANY',
        name: 'fasoo',
        managerId: '',
        managerName: '',
        parentCode: '',
        parentName: '',
        linkedYN: 'N',
        updatedTime: 1589850329000,
        subDeptCount: 0,
        userCount: 0,
      },
      {
        dsdCode: '0100000000000301',
        code: '18ee01d6a1654c7581a54d470fb6e724',
        name: '경선부서',
        managerId: '',
        managerName: '',
        parentCode: 'COMPANY',
        parentName: 'fasoo',
        linkedYN: 'N',
        updatedTime: 1589850329000,
        subDeptCount: 0,
        userCount: 0,
      },
      {
        dsdCode: '0100000000000301',
        code: '587acdd296f54bd4bbbb43d95158ffc4',
        name: '다인부서',
        managerId: '',
        managerName: '',
        parentCode: 'COMPANY',
        parentName: 'fasoo',
        linkedYN: 'N',
        updatedTime: 1589850329000,
        subDeptCount: 0,
        userCount: 0,
      },
    ];
  }

  list(deptCodes: string[]): Promise<DeptDto[]> {
    return Promise.resolve(_.cloneDeep(this.data));
  }
}

const deptApi: DeptApi = FIDP_API_ENABLED ? new DeptServerApi() : new DeptDummyApi();

export default deptApi;
