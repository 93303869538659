import React, { useRef, useState, useEffect } from 'react'

interface ProgessBarProps {
  bgcolor?: string;
  progress?: string;
  height?: number;
}

export const FileUploadProgressBar: React.FC<ProgessBarProps> = ({ bgcolor, progress, height }) => {

  const Parentdiv = {
    height: height,
    width: '80%',
    backgroundColor: '#edf0f5',
    borderRadius: 40,
  }

  const Childdiv = {
    height: '100%',
    width: `${progress}`,
    backgroundColor: "#3e7beb",
    borderRadius: 10,
  }

  return (
    <div style={Parentdiv}>
      <div style={Childdiv}>
        {/* <span style={progresstext}>{`${progress}`}</span> */}
        <div style={{ height: "100%", width: "100%", position: "relative" }}>
        </div>
      </div>
    </div >

  )
}