import { UserInfoDto } from './../fidp/userApi';
import config from 'src/shared/config';
import axios, { ApiErrorHelper } from 'src/shared/utils/axios';

import DummyApi from '../dummyApi';
import { times } from '../api';

import { UserDto } from '../fidp/userApi';
import { DeptDto } from '../fidp/deptApi';

const INFRA_API_ENABLED = config.servers.infra.enabled;
const INFRA_API_URL = config.servers.infra.url;

enum CommonPolicyApiError {
  NOT_FOUND_GRANTED_POLICY = 'NOT_FOUND_GRANTED_POLICY',
}

export interface CommonPolicyDto {
  code: string;
  name: string;
  description: string;
  entryTime: number;

  templateCode: string; // 템플릿 코드
  startTime: number; // 시작 일시 -1 고정 (-1 = 무제한)
  endTime: number; // 종료 일시 -1 고정 (-1 = 무제한)

  targetUsers: string[]; //  대상 사용자 ID 목록
  targetOrgans: TargetOrganDto[]; // 대상 조직 목록
}

export interface TargetOrganDto {
  deptCode: string; // 부서 코드
  roleCodes: string[]; // 직무 코드 목록 (zzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzz = 전체)
  positionCodes: string[]; // 직급 코드 목록 (zzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzz = 전체)
}

export interface TargetUserDto {
  userId: string;
}

export type CommonPolicyViewDto = Omit<CommonPolicyDto, 'targetUsers' | 'targetOrgans'>;

export interface TargetOrganEntity extends TargetOrganDto {
  dept: DeptDto;
}

export interface TargetUserEntity extends TargetUserDto {
  user: UserDto;
}

export type CommonPolicyReqDto = Omit<CommonPolicyDto, 'code' | 'entryTime'>;

export interface CommonPolicyAddUserReqDto {
  userId: string;
}

export const defaultCommonPolicyReqDto: CommonPolicyReqDto = {
  name: '',
  description: '',

  templateCode: '',
  startTime: -1,
  endTime: -1,

  targetUsers: [],
  targetOrgans: [],
};

interface CommonPolicyApi {
  list(): Promise<CommonPolicyDto[]>;
  find(code: string): Promise<CommonPolicyDto>;
  create(reqDto: CommonPolicyReqDto): Promise<CommonPolicyDto>;
  update(code: string, reqDto: CommonPolicyReqDto): Promise<CommonPolicyDto>;
  remove(code: string): Promise<void>;
  view(userInfo: UserInfoDto): Promise<CommonPolicyViewDto | null>;
  addUser(code: string, reqDto: CommonPolicyAddUserReqDto): Promise<CommonPolicyDto>;
}
class CommonPolicyServerApi implements CommonPolicyApi {
  async list(): Promise<CommonPolicyDto[]> {
    const resp = await axios.get<CommonPolicyDto[]>(`${INFRA_API_URL}/api/policies`);
    return resp.data;
  }
  async find(code: string): Promise<CommonPolicyDto> {
    const resp = await axios.get<CommonPolicyDto>(`${INFRA_API_URL}/api/policies/${code}`);
    return resp.data;
  }
  async create(reqDto: CommonPolicyReqDto): Promise<CommonPolicyDto> {
    const resp = await axios.post<CommonPolicyDto>(`${INFRA_API_URL}/api/policies`, reqDto);
    return resp.data;
  }
  async update(code: string, reqDto: CommonPolicyReqDto): Promise<CommonPolicyDto> {
    const resp = await axios.put<CommonPolicyDto>(`${INFRA_API_URL}/api/policies/${code}`, reqDto);
    return resp.data;
  }
  async remove(code: string): Promise<void> {
    await axios.delete<CommonPolicyDto>(`${INFRA_API_URL}/api/policies/${code}`);
  }
  async view(userInfo: UserInfoDto): Promise<CommonPolicyViewDto | null> {
    try {
      const resp = await axios.post<CommonPolicyViewDto>(
        `${INFRA_API_URL}/api/policies/view/simple`,
        userInfo
      );
      return resp.data;
    } catch (error) {
      if (ApiErrorHelper.isErrorCode(error, CommonPolicyApiError.NOT_FOUND_GRANTED_POLICY)) {
        return null;
      }
      throw error;
    }
  }
  async addUser(code: string, reqDto: CommonPolicyAddUserReqDto): Promise<CommonPolicyDto> {
    const resp = await axios.post<CommonPolicyDto>(
      `${INFRA_API_URL}/api/policies/${code}/add-user`,
      reqDto
    );

    return resp.data;
  }
}

class CommonPolicyDummyApi implements CommonPolicyApi {
  dummyApi: DummyApi<CommonPolicyDto, CommonPolicyReqDto>;
  constructor() {
    const data: CommonPolicyDto[] = [
      {
        name: '일반 사원급 기본 정책A',
        description: '사내 테스트를 위한 기본 정책입니다.',

        templateCode: 'code-0',
        targetUsers: ['sryeon', 'admin'],
        targetOrgans: [
          {
            deptCode: 'c137da14690348dcbdf3f4ab752d91f0',
            roleCodes: ['b87928b2982e44ba989cf3446413af2d', 'ab4772ef30e7485ba28d8bfc8350c732'],
            positionCodes: ['8879d94683e64db0ad3c2d3deaebbd4f'],
          },
          {
            deptCode: 'COMPANY',
            roleCodes: ['ab4772ef30e7485ba28d8bfc8350c732'],
            positionCodes: ['8879d94683e64db0ad3c2d3deaebbd4f'],
          },
        ],
        startTime: -1,
        endTime: -1,
      },
      {
        name: '테스트 정책',
        description: '테스트를 위한 기본 정책 입니다.',

        templateCode: 'code-1',
        targetUsers: ['sryeon', 'admin'],
        targetOrgans: [
          {
            deptCode: 'c137da14690348dcbdf3f4ab752d91f0',
            roleCodes: ['b87928b2982e44ba989cf3446413af2d'],
            positionCodes: ['8879d94683e64db0ad3c2d3deaebbd4f'],
          },
          {
            deptCode: '18ee01d6a1654c7581a54d470fb6e724',
            roleCodes: ['ab4772ef30e7485ba28d8bfc8350c732'],
            positionCodes: ['8879d94683e64db0ad3c2d3deaebbd4f'],
          },
        ],
        startTime: -1,
        endTime: -1,
      },
    ].map((o, i) => ({ ...o, code: '', entryTime: times(i) }));

    this.dummyApi = DummyApi.of({
      entityName: 'Infra/.CommonPolicy',
      createDto: (code, entity) => ({ ...entity, code, entryTime: Date.now() }),
      validation: (type, code, reqDto, data) => {
        data.forEach((dto) => {
          if (
            (type === 'create' && dto.name === reqDto.name) ||
            (type === 'update' && dto.code !== code && dto.name === reqDto.name)
          ) {
            throw new Error('이미 사용 중인 이름입니다.');
          }
        });
      },
      data,
    });
  }

  list(): Promise<CommonPolicyDto[]> {
    return this.dummyApi.list();
  }
  find(code: string): Promise<CommonPolicyDto> {
    return this.dummyApi.find(code);
  }
  create(reqDto: CommonPolicyReqDto): Promise<CommonPolicyDto> {
    return this.dummyApi.create(reqDto);
  }
  update(code: string, reqDto: CommonPolicyReqDto): Promise<CommonPolicyDto> {
    return this.dummyApi.update({ code, reqDto });
  }
  remove(code: string): Promise<void> {
    return this.dummyApi.remove(code);
  }
  view(userInfo: UserInfoDto): Promise<CommonPolicyViewDto | null> {
    return this.dummyApi.list().then((policys) => {
      let res = policys.find((policy) => policy.targetUsers.includes(userInfo.user.id));
      if (!res) {
        res = policys.find((policy) =>
          policy.targetOrgans.find((organ) => organ.deptCode === userInfo.depts.primary.code)
        );
      }

      if (!res) return Promise.resolve(null);

      const viewRes: CommonPolicyViewDto = {
        code: res.code,
        name: res.name,
        description: res.description,
        startTime: -1,
        endTime: -1,
        entryTime: res.entryTime,
        templateCode: res.templateCode,
      };

      return Promise.resolve(viewRes);
    });
  }
  addUser(code: string, addUserDto: CommonPolicyAddUserReqDto): Promise<CommonPolicyDto> {
    return this.dummyApi.list().then((policys) => {
      let res = policys.find((policy) => policy.code === code);
      if (!res) res = policys[0];

      const reqDto: CommonPolicyReqDto = {
        name: res.name,
        description: res.description,
        endTime: res.endTime,
        startTime: res.startTime,
        templateCode: res.templateCode,
        targetOrgans: res.targetOrgans,
        targetUsers: res.targetUsers.concat(addUserDto.userId),
      };
      return this.dummyApi.update({ code, reqDto });
    });
  }
}

const commonPolicyApi: CommonPolicyApi = INFRA_API_ENABLED
  ? new CommonPolicyServerApi()
  : new CommonPolicyDummyApi();

export default commonPolicyApi;
