import _ from 'lodash';
import { ApiDto } from './api';

function createCode(i: number): string {
  return `code-${i}`;
}

class DummyApi<DTO extends ApiDto, RDTO> {
  private entityName: string;
  private createDto: (code: string, reqDto: RDTO) => DTO;
  private validation: (type: string, code: string, reqDto: RDTO, data: DTO[]) => void;
  private data: DTO[];

  constructor(
    entityName: string,
    createDto: (code: string, reqDto: RDTO) => DTO,
    data: DTO[] = []
  ) {
    this.entityName = entityName;
    this.createDto = createDto;
    this.validation = (type, code, reqDto, data) => {
      // do nothing
    };
    this.data = data.map((dto, i) => ({ ...dto, code: dto.code ? dto.code : createCode(i) }));
  }

  list(): Promise<DTO[]> {
    return Promise.resolve(_.cloneDeep(this.data));
  }

  find(code: string): Promise<DTO> {
    const found = this.data.find((dto) => dto.code === code);
    if (!found) {
      throw new Error(`${this.entityName} not found (code: ${code})`);
    }
    return Promise.resolve(found);
  }

  create(reqDto: RDTO): Promise<DTO> {
    this.validation('create', '', reqDto, this.data);

    const nextCode =
      this.data
        .map((dto) => Number(dto.code.split('-')[1]))
        .reduce((previous, current) => (previous > current ? previous : current), 0) + 1;

    const newDto: DTO = this.createDto(createCode(nextCode), _.cloneDeep(reqDto));
    this.data.push(newDto);
    return Promise.resolve(_.cloneDeep(newDto));
  }

  // TODO 추후 data 없애야 함
  update({ code, reqDto, data }: { code: string; reqDto?: RDTO; data?: RDTO }): Promise<DTO> {
    if (data) {
      reqDto = data;
    }
    if (!reqDto) {
      throw new Error('reqDto must not be null.');
    }
    this.validation('update', code, reqDto, this.data);

    const idx = this.data.findIndex((dto) => dto.code === code);
    if (idx < 0) {
      throw new Error(`${this.entityName} not found for update (code: ${code})`);
    }
    const newDto: DTO = this.createDto(code, _.cloneDeep(reqDto));
    this.data.splice(idx, 1, newDto);
    return Promise.resolve(_.cloneDeep(newDto));
  }

  replace(data: DTO[]): Promise<DTO[]> {
    this.data = data;
    return Promise.resolve(_.cloneDeep(this.data));
  }

  remove(code: string): Promise<void> {
    const idx = this.data.findIndex((dto) => dto.code === code);
    if (idx < 0) {
      throw new Error(`${this.entityName} not found for remove (code: ${code})`);
    }
    this.data.splice(idx, 1);
    return Promise.resolve();
  }

  static of<DTO extends ApiDto, RDTO>(args: {
    entityName: string;
    createDto: (code: string, reqDto: RDTO) => DTO;
    validation: (type: string, code: string, reqDto: RDTO, data: DTO[]) => void;
    data: DTO[];
  }): DummyApi<DTO, RDTO> {
    const dummyApi = new DummyApi(args.entityName, args.createDto, args.data);
    dummyApi.validation = args.validation;
    return dummyApi;
  }
}

export default DummyApi;
