import React, { useEffect, useState, useContext, Fragment } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { CommonProps } from 'src/components/helpers/props';
import Popup2 from 'src/components/molecules/Popup2';
import Button from 'src/components/atom/Button';
import TextField from 'src/components/atom/TextField';
import './Step2EditPopup.scss';
import AddCircle2Icon from 'src/components/atom/icons/AddCircle2Icon';
import TrashIcon from 'src/components/atom/icons/TrashIcon';
import IconButton from 'src/components/atom/IconButton';
import { useAlert } from 'src/contexts/AlertContext';
import { validateAll } from 'src/shared/utils/validations';
import _, { omit } from 'lodash';
import Select, { SelectItem } from 'src/components/atom/Select';

interface Step1EditPopupProps extends CommonProps {
  open: boolean;
  data: any;
  onConfirm: (itAppData: any) => void;
  onClose: () => void;
}


const Step1EditPopup: React.FC<Step1EditPopupProps> = ({
  open,
  data,
  onClose,
  onConfirm,
  classes,
}) => {
  const { t } = useTranslation(['commons']);
  const { alert2 } = useAlert();
  const [itAppData, setItAppData] = useState<any>(data);
  const [selectedITGC, setSelcectedITGC] = useState<any>(data?.transaction_type_list[0]?.name || "");
  const itgcList = ["개발통제", "접근통제", "운영통제", "변경통제"]
  const validationRules = {
    // id: _.trim(project.id).length > 0 && validationId(project.id),
    // name: _.trim(form.name).length > 0,
    // projectRole: selectedUsers.length > 0,
    // name: itAppData?.data.every((item: any) => item.title.length > 0)
  };

  const handleOk = (): void => {


    if (!validateAll(validationRules)) {
      alert2("감사절차 수정", "계정명은 필수 입력값입니다.");
      return;
    }
    // console.log("itAppData ")
    onConfirm(itAppData)
    onClose();
  };

  useEffect(() => {
    // console.log("account : ", account);
    // let newItAppData = { ...itAppData };
    // newItAppData.data?.map((elem: any, idx: number) => {
    //   let newData: any[] = [];
    //   ceoInsists.forEach(i => {
    //     if (elem.data.includes(i.key)) {
    //       newData.push({ key: i.key, checked: true })
    //     } else {
    //       newData.push({ key: i.key, checked: false })
    //     }
    //   })
    //   newItAppData.data[idx].data = newData
    // })
    // setOpinions(newItAppData)
    // console.log("data :", data)
  }, []);

  const handleClose = () => {
    onClose();
  }

  const handleDetailAdd = () => {
    let newItAppData = { ..._.cloneDeep(itAppData) };
    const index = newItAppData.transaction_type_list.findIndex((item: any) => item.name === selectedITGC)
    if (index < 0) {
      alert2("ITGC 수정", "ITGC가 선택되어 있는지 확인해 주세요.")
      return;
    }
    // console.log("newItAppData : ", newItAppData, index)
    if ('transaction_type_list' in newItAppData.transaction_type_list[index]) {
      newItAppData.transaction_type_list[index].transaction_type_list.push({ name: "" })
    } else {
      newItAppData.transaction_type_list[index].transaction_type_list = [{ name: "" }]
    }

    setItAppData(newItAppData)
  }

  const handleDeleteAccount = (i: number) => {
    let newItAppData = { ..._.cloneDeep(itAppData) };
    const index = newItAppData.transaction_type_list.findIndex((item: any) => item.name === selectedITGC)
    if (index < 0) {
      alert2("ITGC 수정", "ITGC가 선택되어 있는지 확인해 주세요.")
      return;
    }
    newItAppData.transaction_type_list[index].transaction_type_list.splice(i, 1)
    setItAppData(newItAppData)
  }

  const handleChangeITGC = (e: React.ChangeEvent<HTMLInputElement>, i: number) => {
    e.stopPropagation();
    let newItAppData = { ..._.cloneDeep(itAppData) };
    const index = newItAppData.transaction_type_list.findIndex((item: any) => item.name === selectedITGC)
    if (index < 0) {
      alert2("ITGC 수정", "ITGC가 선택되어 있는지 확인해 주세요.")
      return;
    }
    newItAppData.transaction_type_list[index].transaction_type_list[i].name = e.target.value;
    setItAppData(newItAppData)
  }

  const onSelectITGC = (value: string) => {
    setSelcectedITGC(value)
  }

  // console.log("selectedITGC : ", selectedITGC)
  return (
    <Popup2
      classes="audit-popup-root project-popup-root project-common"
      title={"ITGC 수정"}
      width="1112px"
      height="692px"
      open={open}
      onClose={handleClose}
      footer={
        <React.Fragment>
          <div className="detail-add">
            <div
              onClick={handleDetailAdd}
            >
              <AddCircle2Icon color="#295c9f" />
              <span>ITGC 내용 추가</span>
            </div>
          </div>
          <Button text={t('commons:cancel')} onClick={handleClose} color="secondary" />
          <Button
            text={'확인'}
            onClick={handleOk}
          // disabled={!selectedUsers} 
          />
        </React.Fragment>
      }
    >
      <div className="itgc-edit-header">
        <div className="audit-process-content-title-it-app">
          <div className="audit-process-content-title-it-app-label">
            IT Application
          </div>
          <div className="audit-process-content-title-it-app-text-root">
            <TextField classes="audit-process-content-title-it-app-text"
              value={itAppData.name}
              placeholder="내용을 입력하세요." disabled
            />
          </div>
        </div>

        <div className="audit-process-content-title-itgc">
          <div className="audit-process-content-title-itgc-label">
            ITGC
          </div>
          <div className="audit-process-content-title-itgc-select">
            <Select
              autoWidth
              selectFirstItem={false}
              selectedEventKey={selectedITGC}
              textAlign='center'
              align='left'
              placeholder="ITGC를 선택해 주세요."
              border={false}
              onClick={(value) => {
                onSelectITGC && onSelectITGC(value)
              }}
            >
              {itgcList && itgcList.map(elem => (
                <SelectItem key={elem} eventKey={elem} >
                  {elem}
                </SelectItem>
              ))
              }
            </Select >
          </div>
        </div>
      </div>
      <div className="task-header element">
        <div className="title-content title" style={{ paddingLeft: 15, width: "100%", color: "#a3a6b4" }}>ITGC 내용</div>
      </div>
      <div className="audit-process-content-csm-step2-root">
        {itAppData?.transaction_type_list?.map((elem: any, i: number) => {
          if (selectedITGC === elem.name) {
            return elem?.transaction_type_list?.map((elem2: any, i: number) => {
              return (<div className="audit-process-content-csm-step2" key={i.toString()}>
                <div className="audit-process-content-title">
                  <TextField classes="audit-process-content-title-edit"
                    value={elem2?.name || ""}
                    placeholder="내용을 입력하세요."
                    autoFocus={!elem2?.name ? true : false}
                    onChange={(e) => { handleChangeITGC(e, i) }}
                  />
                </div>
                <div className="audit-process-content-delete">
                  <IconButton onClick={() => handleDeleteAccount(i)}>
                    <TrashIcon></TrashIcon>
                  </IconButton>

                </div>
              </div>
              )
            })
          }
        })
        }
      </div>


    </Popup2>
  );
};

export default Step1EditPopup;
