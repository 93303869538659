import React, { useRef, useState } from 'react';
import classNames from 'classnames';

import { CommonProps } from 'src/components/helpers/props';
import Checkbox from 'src/components/atom/Checkbox';
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker';
import TextField from 'src/components/atom/TextField';
import 'react-datepicker/dist/react-datepicker.css';
import CalendarIcon from 'src/components/atom/icons/CalendarIcon';
import getYear from 'date-fns/getYear';
import getMonth from 'date-fns/getMonth';
import ArrowBackIcon from 'src/components/atom/icons/ArrowBackIcon';
import IconButton from 'src/components/atom/IconButton';
import Button from 'src/components/atom/Button';
import FormGroup from 'src/components/atom/FormGroup';
import ko from 'date-fns/locale/ko';
import en from 'date-fns/locale/en-US';

import './index.scss';

const months = {
  ko: ['1월', '2월', '3월', '4월', '5월', '6월', '7월', '8월', '9월', '10월', '11월', '12월'],
  en: [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ],
};

registerLocale('ko', ko);
registerLocale('en', en);

export interface FasooDatePickerProps extends CommonProps {
  locale: string;
  selected: Date;
  onSubmit: (date: Date) => void;
  submitText?: string;
  customInput: React.ReactNode;
  minDate?: Date;
  maxDate?: Date;
  dateFormat?: string;
  disabled?: boolean;
  enableTime?: boolean;
  timeInputLabel?: string;
  first?: boolean
  onChangeCheckbox?: (checked: boolean) => void;
  checked?: boolean
}

const Calendar: React.FC<FasooDatePickerProps> = ({
  locale = 'ko',
  selected,
  onSubmit,
  customInput,
  minDate,
  maxDate,
  dateFormat,
  submitText = '확인',
  disabled = false,
  enableTime = false,
  timeInputLabel,
  classes,
  first,
  onChangeCheckbox,
  checked
}) => {
  const [currentDate, setCurrentDate] = useState<Date>(new Date());
  const calendar = useRef<DatePicker>(null);

  const datePickHandler = (
    date: Date | [Date, Date] | null,
    event: React.SyntheticEvent<any, Event> | undefined
  ): void => {
    if (date && typeof date === 'object' && date instanceof Date) setCurrentDate(date);
  };

  const closeDatePicker = (): void => {
    onSubmit(currentDate);
    calendar && calendar.current && calendar.current.setOpen(false);
  };

  const onChange = (name: string, value: string, checked: boolean): void => {
    console.log("checked onChange : ", name, value, checked)
    onChangeCheckbox && onChangeCheckbox(checked);
  };

  // 요일 반환
  const getDayName = (date: Date): string => {
    return date.toLocaleDateString('ko-KR', { weekday: 'long' }).substr(0, 1);
  };

  // 날짜 비교시 년 월 일까지만 비교하게끔
  const createDate = (date: Date): Date => {
    return new Date(new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0));
  };

  return (
    <div className={classNames('calendar-date-picker-root', classes)}>
      <DatePicker
        disabled={disabled}
        portalId="modal"
        className="date date-record"
        locale={locale}
        selected={selected}
        customInput={customInput}
        minDate={minDate}
        maxDate={maxDate}
        dateFormat={dateFormat}
        useWeekdaysShort={true}
        shouldCloseOnSelect={false}
        fixedHeight={true}
        showTimeInput={enableTime}
        timeInputLabel={timeInputLabel}
        // showTimeSelect={enableTime}
        // showTimeSelectOnly={enableTime}
        // excludeDates={excludeDates}
        ref={calendar}
        onChange={(date, event) => datePickHandler(date, event)}
        dayClassName={(date) => (getDayName(createDate(date)) === '일' ? 'sunday' : null)}
        renderCustomHeader={({
          date,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
          decreaseMonth,
          increaseMonth,
        }) => (
          <FormGroup spacing="auto">
            <IconButton onClick={decreaseMonth} disabled={prevMonthButtonDisabled} transparent>
              <ArrowBackIcon />
            </IconButton>
            <div className="month-day">
              {locale.includes('ko')
                ? `${getYear(date)}년 ${months.ko[getMonth(date)]}`
                : `${getYear(date)}.${months.en[getMonth(date)]}`}
            </div>
            <IconButton
              onClick={increaseMonth}
              disabled={nextMonthButtonDisabled}
              transparent
              classes="rotate_180"
            >
              <ArrowBackIcon />
            </IconButton>
          </FormGroup>
        )}
      >
        <div className="date-container">
          <div className="divider" />
          {first &&
            <Checkbox classes="date-checkbox" label="1년 후" checked={checked} onChange={onChange} />
          }

          <Button text={submitText} onClick={closeDatePicker} />
        </div>
      </DatePicker>
    </div>
  );
};

export default Calendar;
