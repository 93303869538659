import React from 'react';
import classNames from 'classnames';

import { CommonProps } from 'src/components/helpers/props';

import './AbstractIcon.scss';

interface ErrorPageIconProps extends CommonProps {
  disabled?: boolean;
  size?: 'md';
}

export type ErrorPageIconComponent = React.FC<ErrorPageIconProps>;

const NotFound: ErrorPageIconComponent = ({ classes, disabled = false, size = 'md' }) => {
  return (
    <svg
      className={classNames('icon-root', classes)}
      width="350"
      height="220"
      viewBox="0 0 350 220"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <path
          d="M103.21 191.464C111.043 190.403 111.727 180.846 111.727 180.846V163.251H169.425V180.846C169.425 180.846 170.109 190.403 177.942 191.464C178.788 191.578 179.396 192.337 179.396 193.19V194.764C179.396 195.446 178.845 195.996 178.16 195.996H102.991C102.307 195.996 101.756 195.446 101.756 194.764V193.19C101.746 192.337 102.364 191.578 103.21 191.464Z"
          fill="white"
          stroke="#BFBFBF"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M275.753 165.451H5.38948C2.93708 165.451 0.950439 163.469 0.950439 161.023V5.37555C0.950439 2.92963 2.93708 0.948242 5.38948 0.948242H275.753C278.205 0.948242 280.192 2.92963 280.192 5.37555V161.023C280.192 163.469 278.205 165.451 275.753 165.451Z"
          fill="white"
          stroke="#BFBFBF"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M272.901 9.36719H8.24097V157.032H272.901V9.36719Z"
          fill="#D1D1D1"
          stroke="#BFBFBF"
          strokeMiterlimit="10"
        />
        <path d="M103.21 191.465H177.933" stroke="#BFBFBF" strokeWidth="2" strokeMiterlimit="10" />
        <path
          opacity="0.2"
          d="M223.796 127.975C217.19 127.975 210.983 125.406 206.316 120.751L201.344 125.709C207.532 131.881 215.669 134.971 223.806 134.971C231.942 134.971 240.069 131.881 246.267 125.709L241.296 120.751C236.609 125.415 230.393 127.975 223.796 127.975Z"
          fill="white"
          stroke="#BFBFBF"
          strokeMiterlimit="10"
        />
        <g opacity="0.5">
          <path
            opacity="0.5"
            d="M272.977 9.36719H8.27905V19.3405H272.977V9.36719Z"
            fill="white"
            stroke="#BFBFBF"
            strokeMiterlimit="10"
          />
        </g>
        <path
          d="M16.7961 16.4576C17.8513 16.4576 18.7067 15.6044 18.7067 14.552C18.7067 13.4996 17.8513 12.6465 16.7961 12.6465C15.7409 12.6465 14.8855 13.4996 14.8855 14.552C14.8855 15.6044 15.7409 16.4576 16.7961 16.4576Z"
          fill="white"
          stroke="#BFBFBF"
          strokeMiterlimit="10"
        />
        <path
          d="M24.7998 16.4576C25.8549 16.4576 26.7104 15.6044 26.7104 14.552C26.7104 13.4996 25.8549 12.6465 24.7998 12.6465C23.7446 12.6465 22.8892 13.4996 22.8892 14.552C22.8892 15.6044 23.7446 16.4576 24.7998 16.4576Z"
          fill="white"
          stroke="#BFBFBF"
          strokeMiterlimit="10"
        />
        <path
          d="M32.8032 16.4576C33.8584 16.4576 34.7138 15.6044 34.7138 14.552C34.7138 13.4996 33.8584 12.6465 32.8032 12.6465C31.748 12.6465 30.8926 13.4996 30.8926 14.552C30.8926 15.6044 31.748 16.4576 32.8032 16.4576Z"
          fill="white"
          stroke="#BFBFBF"
          strokeMiterlimit="10"
        />
        <path
          d="M247.493 79.6731C234.423 66.6376 213.16 66.6376 200.09 79.6731C187.02 92.7085 187.02 113.916 200.09 126.951L201.335 125.709L206.306 120.751L207.551 119.509C198.597 110.579 198.597 96.0455 207.551 87.1151C216.506 78.1846 231.077 78.1846 240.031 87.1151C248.986 96.0455 248.986 110.579 240.031 119.509L241.277 120.751L246.248 125.709L247.493 126.951C260.563 113.916 260.563 92.7085 247.493 79.6731Z"
          fill="white"
          stroke="#BFBFBF"
          strokeMiterlimit="10"
        />
        <path
          d="M29.5522 108.436V98.681L60.3879 51.7061H72.707V99.0507H82.9538V108.427H72.6975V124.268H61.1388V108.427H29.5522V108.436ZM39.0482 99.0602V99.2498H61.5096V68.9602L61.7947 62.7696L58.6009 68.9602L39.0482 99.0602Z"
          fill="white"
          stroke="#BFBFBF"
          strokeMiterlimit="10"
        />
        <path
          d="M86.0432 88.1861C86.0432 75.0559 88.1534 65.5187 92.3928 59.5461C96.6228 53.5735 102.592 50.5967 110.301 50.5967C118.01 50.5967 123.979 53.583 128.209 59.5461C132.439 65.5187 134.559 75.0654 134.559 88.1861C134.559 101.307 132.449 110.863 128.209 116.826C123.979 122.799 118.01 125.776 110.301 125.776C102.592 125.776 96.6228 122.789 92.3928 116.826C88.1629 110.854 86.0432 101.307 86.0432 88.1861ZM97.7064 88.1861C97.7064 98.1215 98.8185 105.326 101.043 109.792C103.267 114.257 106.356 116.494 110.301 116.494C114.246 116.494 117.335 114.257 119.559 109.792C121.784 105.326 122.896 98.1215 122.896 88.1861C122.896 78.2507 121.784 71.0457 119.559 66.5805C117.335 62.1153 114.246 59.8779 110.301 59.8779C106.356 59.8779 103.267 62.1153 101.043 66.5805C98.8185 71.0457 97.7064 78.2413 97.7064 88.1861Z"
          fill="white"
          stroke="#BFBFBF"
          strokeMiterlimit="10"
        />
        <path
          d="M139.16 108.436V98.681L169.995 51.7061H182.314V99.0507H192.561V108.427H182.314V124.268H170.756V108.427H139.16V108.436ZM148.656 99.0602V99.2498H171.117V68.9602L171.402 62.7696L168.208 68.9602L148.656 99.0602Z"
          fill="white"
          stroke="#BFBFBF"
          strokeMiterlimit="10"
        />
        <path
          d="M291.033 146.153L275.957 161.189L286.025 171.231L301.101 156.195L291.033 146.153Z"
          fill="white"
          stroke="#BFBFBF"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          opacity="0.1"
          d="M187.894 164.654C158.456 135.294 158.456 87.6835 187.894 58.3135C212.932 33.3424 251.191 29.6072 280.192 47.1078V165.441L187.894 164.654Z"
          fill="black"
          stroke="#BFBFBF"
          strokeMiterlimit="10"
        />
        <path
          d="M241.201 176.571C277.24 176.571 306.456 147.433 306.456 111.489C306.456 75.5446 277.24 46.4062 241.201 46.4062C205.162 46.4062 175.946 75.5446 175.946 111.489C175.946 147.433 205.162 176.571 241.201 176.571Z"
          fill="white"
          stroke="#BFBFBF"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M241.201 162.123C269.239 162.123 291.969 139.453 291.969 111.489C291.969 83.5242 269.239 60.8545 241.201 60.8545C213.162 60.8545 190.432 83.5242 190.432 111.489C190.432 139.453 213.162 162.123 241.201 162.123Z"
          fill="#D6D6D6"
          stroke="#BFBFBF"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          opacity="0.4"
          d="M268.32 138.536C253.339 153.477 229.062 153.477 214.082 138.536"
          stroke="#BFBFBF"
          strokeWidth="5"
          strokeMiterlimit="10"
        />
        <path
          opacity="0.1"
          d="M314.904 163.878L293.726 185.001L318.279 209.489L339.457 188.366L314.904 163.878Z"
          fill="#969696"
        />
        <path
          d="M344.668 214.672C338.822 220.502 329.335 220.502 323.49 214.672L282.987 174.276L304.165 153.154L344.668 193.55C350.514 199.38 350.514 208.842 344.668 214.672Z"
          stroke="#BFBFBF"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="350" height="220" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

const Forbidden: ErrorPageIconComponent = ({ classes, disabled = false, size = 'md' }) => {
  return (
    <svg
      className={classNames('icon-root', classes)}
      width="350"
      height="220"
      viewBox="0 0 350 220"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <path
          d="M103.2 191.5C111 190.4 111.7 180.9 111.7 180.9V163.3H169.4V180.9C169.4 180.9 170.1 190.5 177.9 191.5C178.7 191.6 179.4 192.4 179.4 193.2V194.8C179.4 195.5 178.8 196 178.2 196H103C102.3 196 101.8 195.4 101.8 194.8V193.2C101.7 192.3 102.4 191.6 103.2 191.5Z"
          fill="white"
          stroke="#BFBFBF"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M275.8 165.5H5.4C2.9 165.5 1 163.5 1 161.1V5.4C1 3 3 1 5.4 1H275.8C278.3 1 280.2 3 280.2 5.4V161C280.2 163.5 278.2 165.5 275.8 165.5Z"
          fill="white"
          stroke="#BFBFBF"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M272.9 9.40039H8.2002V157H272.9V9.40039Z"
          fill="#D1D1D1"
          stroke="#BFBFBF"
          strokeMiterlimit="10"
        />
        <path d="M103.2 191.5H177.9" stroke="#BFBFBF" strokeWidth="2" strokeMiterlimit="10" />
        <path
          opacity="0.2"
          d="M223.8 128C217.2 128 211 125.4 206.3 120.8L201.3 125.8C207.5 132 215.6 135.1 223.8 135.1C231.9 135.1 240.1 132 246.3 125.8L241.3 120.8C236.6 125.4 230.4 128 223.8 128Z"
          fill="white"
          stroke="#BFBFBF"
          strokeMiterlimit="10"
        />
        <g opacity="0.5">
          <path
            opacity="0.5"
            d="M273 9.40039H8.2998V19.4004H273V9.40039Z"
            fill="white"
            stroke="#BFBFBF"
            strokeMiterlimit="10"
          />
        </g>
        <path
          d="M16.8004 16.5002C17.9004 16.5002 18.7004 15.6002 18.7004 14.6002C18.7004 13.6002 17.8004 12.7002 16.8004 12.7002C15.7004 12.7002 14.9004 13.6002 14.9004 14.6002C14.9004 15.6002 15.7004 16.5002 16.8004 16.5002Z"
          fill="white"
          stroke="#BFBFBF"
          strokeMiterlimit="10"
        />
        <path
          opacity="0.5"
          d="M133.1 89.0002C152.43 89.0002 168.1 85.0155 168.1 80.1002C168.1 75.1849 152.43 71.2002 133.1 71.2002C113.77 71.2002 98.0996 75.1849 98.0996 80.1002C98.0996 85.0155 113.77 89.0002 133.1 89.0002Z"
          fill="#BFBFBF"
        />
        <path
          d="M24.8004 16.5002C25.9004 16.5002 26.7004 15.6002 26.7004 14.6002C26.7004 13.6002 25.8004 12.7002 24.8004 12.7002C23.7004 12.7002 22.9004 13.6002 22.9004 14.6002C22.9004 15.6002 23.7004 16.5002 24.8004 16.5002Z"
          fill="white"
          stroke="#BFBFBF"
          strokeMiterlimit="10"
        />
        <path
          d="M84.7983 42.4682L46.644 108.595C44.7929 111.886 47.1582 116 50.8605 116H127.169C130.974 116 133.34 111.886 131.386 108.595L93.3342 42.4682C91.483 39.1773 86.7523 39.1773 84.7983 42.4682Z"
          fill="white"
          stroke="#BFBFBF"
          strokeMiterlimit="10"
        />
        <path
          d="M84.7983 42.4682L46.644 108.595C44.7929 111.886 47.1582 116 50.8605 116H127.169C130.974 116 133.34 111.886 131.386 108.595L93.3342 42.4682C91.483 39.1773 86.7523 39.1773 84.7983 42.4682Z"
          fill="#D1D1D1"
          fillOpacity="0.5"
          stroke="#BFBFBF"
          strokeMiterlimit="10"
        />
        <path
          d="M89.1176 52.0332L57.1338 107.465H121.101L89.1176 52.0332Z"
          fill="white"
          stroke="#BFBFBF"
          strokeMiterlimit="10"
        />
        <path
          d="M91.5861 92.7568H86.5469V97.7961H91.5861V92.7568Z"
          fill="#D1D1D1"
          stroke="#BFBFBF"
          strokeMiterlimit="10"
        />
        <path
          d="M91.5861 72.0869H86.5469V87.2046H91.5861V72.0869Z"
          fill="#D1D1D1"
          stroke="#BFBFBF"
          strokeMiterlimit="10"
        />
        <path
          d="M32.8004 16.5002C33.9004 16.5002 34.7004 15.6002 34.7004 14.6002C34.7004 13.6002 33.8004 12.7002 32.8004 12.7002C31.7004 12.7002 30.9004 13.6002 30.9004 14.6002C30.9004 15.6002 31.7004 16.5002 32.8004 16.5002Z"
          fill="white"
          stroke="#BFBFBF"
          strokeMiterlimit="10"
        />
        <path
          d="M247.5 79.7002C234.4 66.7002 213.2 66.7002 200.1 79.7002C187 92.7002 187 113.9 200.1 127L201.3 125.8L206.3 120.8L207.5 119.6C198.5 110.7 198.5 96.1002 207.5 87.2002C216.5 78.3002 231 78.3002 240 87.2002C249 96.1002 249 110.7 240 119.6L241.2 120.8L246.2 125.8L247.4 127C260.6 113.9 260.6 92.7002 247.5 79.7002Z"
          fill="white"
          stroke="#BFBFBF"
          strokeMiterlimit="10"
        />
        <path
          d="M135.004 83.8096L116 126.686L120.845 128.833L139.849 85.9571L135.004 83.8096Z"
          fill="white"
          stroke="#BFBFBF"
          strokeMiterlimit="10"
        />
        <path
          d="M169.54 129.101L173.64 129.801V129.701L175.94 124.601V124.501L172.64 121.901L137.24 92.001L136.54 93.601L124.24 121.301L123.54 122.901L169.54 129.101Z"
          fill="white"
          stroke="#BFBFBF"
          strokeMiterlimit="10"
        />
        <path
          d="M135.004 83.8096L116 126.686L120.845 128.833L139.849 85.9571L135.004 83.8096Z"
          fill="#D6D6D6"
          stroke="#BFBFBF"
          strokeMiterlimit="10"
        />
        <path
          d="M169.54 129.101L173.64 129.801V129.701L175.94 124.601V124.501L172.64 121.901L166.74 116.901L161.84 128.101L169.54 129.101Z"
          fill="#D6D6D6"
          stroke="#BFBFBF"
          strokeMiterlimit="10"
        />
        <path
          d="M139.74 125.101L149.74 102.601L137.24 92.001L136.54 93.601L124.24 121.301L123.54 122.901L139.74 125.101Z"
          fill="#D6D6D6"
          stroke="#BFBFBF"
          strokeMiterlimit="10"
        />
        <path
          d="M154.64 127.101L161.24 112.301L155.24 107.201L146.94 126.101L154.64 127.101Z"
          fill="#D6D6D6"
          stroke="#BFBFBF"
          strokeMiterlimit="10"
        />
        <path
          d="M291 146.2L275.9 161.2L286 171.2L301.1 156.2L291 146.2Z"
          fill="white"
          stroke="#BFBFBF"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          opacity="0.1"
          d="M187.9 164.7C158.5 135.3 158.5 87.7003 187.9 58.4003C212.9 33.4003 251.2 29.7003 280.2 47.2003V165.5L187.9 164.7Z"
          fill="black"
          stroke="#BFBFBF"
          strokeMiterlimit="10"
        />
        <path
          d="M242.4 176.6C278.4 176.6 307.7 147.5 307.7 111.5C307.7 75.6004 278.5 46.4004 242.4 46.4004C206.3 46.4004 177.1 75.5004 177.1 111.5C177.1 147.4 206.3 176.6 242.4 176.6Z"
          fill="white"
          stroke="#BFBFBF"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M241.2 162.1C269.2 162.1 292 139.4 292 111.5C292 83.5004 269.3 60.9004 241.2 60.9004C213.2 60.9004 190.4 83.6004 190.4 111.5C190.4 139.5 213.2 162.1 241.2 162.1Z"
          fill="#D6D6D6"
          stroke="#BFBFBF"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          opacity="0.4"
          d="M268.3 138.5C253.3 153.4 229 153.4 214.1 138.5"
          stroke="#BFBFBF"
          strokeWidth="5"
          strokeMiterlimit="10"
        />
        <path
          opacity="0.1"
          d="M314.9 163.9L293.7 185L318.3 209.5L339.5 188.4L314.9 163.9Z"
          fill="#969696"
        />
        <path
          d="M344.7 214.7C338.9 220.5 329.4 220.5 323.5 214.7L283 174.3L304.2 153.2L344.7 193.6C350.5 199.4 350.5 208.8 344.7 214.7Z"
          stroke="#BFBFBF"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M45.3996 82L44.3996 78H44.1996H38.6996H38.5996L37.4996 82L24.5996 126.6H26.2996H56.5996H58.3996L45.3996 82Z"
          fill="white"
          stroke="#BFBFBF"
          strokeMiterlimit="10"
        />
        <path
          d="M64.9 126.6H18V131.9H64.9V126.6Z"
          fill="white"
          stroke="#BFBFBF"
          strokeMiterlimit="10"
        />
        <path
          d="M64.9 126.6H18V131.9H64.9V126.6Z"
          fill="#D6D6D6"
          stroke="#BFBFBF"
          strokeMiterlimit="10"
        />
        <path
          d="M45.3994 82L44.3994 78H44.1994H38.6994H38.5994L37.4994 82L35.3994 89.4H47.5994L45.3994 82Z"
          fill="#D6D6D6"
          stroke="#BFBFBF"
          strokeMiterlimit="10"
        />
        <path
          d="M53.7996 110.8H29.0996L24.5996 126.6H26.2996H56.5996H58.3996L53.7996 110.8Z"
          fill="#D6D6D6"
          stroke="#BFBFBF"
          strokeMiterlimit="10"
        />
        <path
          d="M49.5992 96.4004H33.3992L31.1992 103.9H51.7992L49.5992 96.4004Z"
          fill="#D6D6D6"
          stroke="#BFBFBF"
          strokeMiterlimit="10"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="350" height="220" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default { NotFound, Forbidden };
