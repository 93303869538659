import React, { useContext, Fragment, Children, useEffect, useState, createContext, useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import update from 'immutability-helper';
import moment from 'moment';
import classNames from 'classnames';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import './index.scss';
import usePopup from 'src/hooks/usePopup';
import { CommonProps } from 'src/components/helpers/props';
import EditIcon from 'src/components/atom/icons/EditIcon';
import IconButton from 'src/components/atom/IconButton';
import DotIcon from 'src/components/atom/icons/DotIcon';
import Button from 'src/components/atom/Button';
import ToolTip from 'src/components/atom/ToolTip';
import AddCircle2Icon from 'src/components/atom/icons/AddCircle2Icon';
import { useAlert } from 'src/contexts/AlertContext';
import TextField from 'src/components/atom/TextField';
import SettingButton, { DropdownItem } from 'src/components/atom/SettingButton';
import LinkIcon from 'src/components/atom/icons/LinkIcon';
import MoreIcon2 from 'src/components/atom/icons/MoreIcon2';
import Select, { SelectItem } from 'src/components/atom/Select';
import projectApi, { ProjectDto } from 'src/api/projectApi';
import templateApi, {
  CategoryDto,
  TaskDto,
  DetailDto,
  SpecialDetailDto,
  createTask,
  createTemplate,
  AuditDto,
  RequestTaskDto
} from 'src/api/templateApi';
import { useToast } from 'src/contexts/ToastContext';
import TaskPopup from './TaskPopup';
import RequestTaskPopup from './RequestTaskPopup';
import TaskElement from './TaskElement';
import AuditRegisterPopup from './AuditRegisterPopup';
import AuditDetailPopup from './AuditDetailPopup';
import AuditPreparePopup from './AuditPreparePopup';
import AuditReviewPopup from './AuditReviewPopup';
import SpecialDetailView, { SpecialDetailView2 } from './SpecialDetailView/SpecialDetailView';
import useAuthStore from 'src/hooks/auth/useAuthStore';

interface TaskListProps extends CommonProps {
  taskList: TaskDto[];
  onAddTask: (task: TaskDto) => void;
  onEditTask: (task: TaskDto) => void;
  onUpdate: () => void;
  onDeleteTask: (task: TaskDto) => void;
  onMyFavorite: (task: TaskDto) => void;
}

interface StatusColor {
  [index: string]: string;
}
export const statusColor: StatusColor = {
  "미진행": "#cecfd0",
  "작업 중": "#ff6565",
  "리뷰 중": "#feb409",
  "완료": "#4ad991",
}

const TaskList: React.FC<TaskListProps> = ({
  taskList,
  onAddTask,
  onUpdate,
  onEditTask,
  onDeleteTask,
  onMyFavorite,
}) => {
  const { projectId, category } = useParams<{ projectId: string, category: string }>();
  const { confirmWithWarn, alert2 } = useAlert();
  const history = useHistory();
  const toast = useToast();
  const { projectInfo } = useContext(ProjectContext);
  const [addTask, setAddTask] = useState<TaskDto>();
  const [popupTask, setPopupTask] = useState<TaskDto>();
  const [popupRequestTask, setPopupRequestTask] = useState<TaskDto>();
  const taskPopup = usePopup();
  const taskRequestPopup = usePopup();

  useEffect(() => {
    setAddTask(undefined);

  }, [taskList])

  const handleTaskAdd = () => {
    setAddTask(createTask())
  }

  const handleTaskNameBlur = () => {
    if (addTask && addTask.name.length > 0) {
      onAddTask(addTask!)
    }
    else {
      setAddTask(undefined);
    }
  }

  const handleTaskEdit = (task: TaskDto) => {
    // console.log("task : ", task)
    setPopupTask(task);
    taskPopup.openPopup();
  }

  const handleDetailClick = (taskName: string, detailName: string) => {
    const _detailName = encodeURIComponent(detailName)
    history.push(`/projects/${projectId}/category/${category}/${taskName}/${_detailName}`);
  }

  const handleCloseTaskPopup = (task: TaskDto) => {
    if (task.name?.length > 0)
      onEditTask(task);
    taskPopup.closePopup();
  }

  const handleMyFavoriteClick = (task: TaskDto) => {
    onMyFavorite(task)
  }

  const handleDeleteTask = (task: TaskDto) => {
    confirmWithWarn('TASK 삭제', 'TASK "' + task.name +
      (task.name?.length > 30 ? '" 을 \n정말 삭제하시겠습니까?' : '" 을 정말 삭제하시겠습니까?'), (result) => {
        //audit 검사
        const requestData = createTemplate(projectInfo.name, category.toUpperCase());
        requestData.category_list[0].task_list[0] = task;

        templateApi
          .hasAudit(requestData)
          .then(resp => {
            console.log("resp : ", resp)
            if (resp?.has_audit === true) {
              alert2("TASK 삭제", '조서가 남아 있는 "세부 TASK"가 존재합니다.\n조서가 모두 지워지지 않으면 삭제할 수 없습니다.')
            } else {
              result && onDeleteTask(task);
            }
          })
          .catch(err => {
            alert2("TASK 삭제", err)
          })
      })
  }

  const handleRequestTask = (task: TaskDto) => {
    setPopupRequestTask(task);
    taskRequestPopup.openPopup();

  }

  const handleCloseRequestTaskPopup = () => {
    taskRequestPopup.closePopup();
  }

  const handleConfirmRequestTask = (requestTask: RequestTaskDto) => {

    const requestData = {
      name: projectInfo.name,
      source_project_name: '',
      category_list: [
        {
          name: category.toUpperCase() || '',
          task_list: [
            requestTask
          ]
        }
      ]
    }
    console.log("request : ", requestData);

    templateApi
      .requestReviewByTask(requestData)
      .then((resp) => {
        console.log('request TASK', resp);
        taskRequestPopup.closePopup();
        toast.info('일괄 리뷰 요청이 완료되었습니다.');
        onUpdate();
      }).catch((e) => {
        console.log("EEE : ", e)
        alert2("리뷰요청", e);
      })
  }
  const isProjectDisabled = projectApi.isProjectDisabled();
  return (
    <div className="template-task-list">
      <div className="template-task-header element">
        <div className="title-content title">내용</div>
        <div className="title-account account">담당자</div>
        <div className="title-status status">상태</div>
      </div>
      <div className="template-task-body">
        {taskList?.map((task, idx) => {
          return <TaskElement key={'taskElement' + idx}
            task={task}
            taskEditable={true}
            detailEditable={false}
            taskDeletable={task?.task_type ? false : true}
            onTaskEditClick={() => handleTaskEdit(task)}
            onDeleteTask={() => handleDeleteTask(task)}
            onRequestTask={() => handleRequestTask(task)}
            onDetailClick={handleDetailClick}
            onMyFavoriteClick={handleMyFavoriteClick}
          />
        })}
        {addTask &&
          <div className="task-element">
            <div className="task-title">
              <div className={'task-title-button'}>
                <TextField value={addTask.name}
                  autoFocus={true}
                  onChange={(e) => {
                    setAddTask({
                      ...addTask,
                      name: e.target.value
                    })

                  }}
                  onBlur={handleTaskNameBlur}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') handleTaskNameBlur();
                  }}
                />
              </div>
            </div>
          </div>
        }
      </div>
      {!isProjectDisabled && <div className="task-add"
        onClick={handleTaskAdd}
      >
        <AddCircle2Icon color="#295c9f" />
        <span className="label">TASK 추가</span>
      </div>}
      {taskPopup.opened && popupTask
        &&
        <TaskPopup
          taskDto={popupTask}
          open={taskPopup.opened}
          addable={popupTask.task_type ? false : true}
          onClose={handleCloseTaskPopup} />}
      {taskRequestPopup.opened && popupRequestTask
        &&
        <RequestTaskPopup
          categoryName=""
          taskName=""
          onConfirm={handleConfirmRequestTask}
          requestTaskDto={popupRequestTask}
          open={taskRequestPopup.opened}
          onClose={handleCloseRequestTaskPopup} />}


    </div>
  )
}

const DetailView: React.FC<{
  categoryName: string,
  detailInfo: DetailDto,
  detailList?: string[],
  onEditDetail: (dto: DetailDto) => void,
  onDeleteAudit: (dto: DetailDto) => void,
  onUpdate: () => void,
  onPrepare: (dto: DetailDto, isConfirm: boolean) => void,
  onReview: (dto: DetailDto, isConfirm: boolean) => void,
}> = ({
  categoryName,
  detailInfo,
  detailList,
  onEditDetail,
  onDeleteAudit,
  onUpdate,
  onPrepare,
  onReview,
}) => {

    let { projectId, taskName, detailName } = useParams<{ projectId: string, taskName: string, detailName: string }>();
    detailName = detailName && decodeURIComponent(detailName);
    const [editMode, setEditMode] = useState<boolean>(false);
    const [requirements, setRequirements] = useState<string>(detailInfo?.requirements);
    const [audit, setAudit] = useState<AuditDto>({} as AuditDto);
    const [largeFile, setLargeFile] = useState<boolean>(false);
    const auditPopup = usePopup();
    const auditDetailPopup = usePopup();
    const auditPreparePopup = usePopup();
    const auditReviewPopup = usePopup();
    const history = useHistory();
    const { alert2, confirmWithWarn } = useAlert();
    const { projectInfo } = useContext(ProjectContext);
    const auditList = detailInfo?.audit_list;
    const [scrollTop, setScrollTop] = useState<number>(0);
    const [scrollTop2, setScrollTop2] = useState<number>(0);
    const [listHeight, setListHeight] = useState<number>(0);
    const [listHeight2, setListHeight2] = useState<number>(0);
    const { authEntity, startSignOut } = useAuthStore();
    useEffect(() => {
      detailInfo && setRequirements(detailInfo.requirements)
    }, [detailInfo])

    useEffect(() => {
      if (detailName && categoryName) {
        console.log("detailName : ", detailName)
        templateApi.checkTaskExist(projectInfo.name, categoryName.toUpperCase(), taskName, detailName)
          .then(resp => {
            console.log("resp : ", resp)
            if (resp.ret === true) {

            } else if (resp.ret === false) {
              if (resp.reason === "TASK_NAME") {
                alert2("에러 발생", "삭제된 태스크에 접근하였습니다.")
              } else if (resp.reason === "SUBTASK_NAME") {
                confirmWithWarn("에러 발생", "삭제된 세부 태스크에 접근하였습니다.", () => {
                  history.goBack();
                })
              }
            }
          })
          .catch(e => console.log("e : ", e))
      }
    }, [taskName, detailName, categoryName])


    if (!detailInfo) return null;

    const handleChange = (html: string) => {
      setRequirements(html)
    }

    const handleConfirm = () => {
      onEditDetail({
        ...detailInfo,
        requirements
      });
      setEditMode(false);
    }

    const handleCancel = () => {
      setRequirements(detailInfo.requirements);
      setEditMode(false);
    }

    const handleClickAuditDetail = (file_name: string) => {
      setAudit(detailInfo.audit_list.filter((elem) => elem.file_name === file_name)[0]);
      auditDetailPopup.openPopup();
    }

    const handleClickAuditDetailLarge = (file_name: string) => {
      setLargeFile(true);
      setAudit(detailInfo.audit_list.filter((elem) => elem.file_name === file_name)[0]);
      auditDetailPopup.openPopup();
    }

    const handleClickAuditDelete = (file_name: string) => {
      confirmWithWarn("조서 삭제", "조서를 삭제하시겠습니까?", (result) => {
        if (result) {
          const newList = detailInfo.audit_list.filter((elem) => elem.file_name === file_name)
          onDeleteAudit({
            ...detailInfo,
            audit_list: newList
          })
        }
      })
    }

    const handleClosePopup = () => {
      auditPopup.closePopup();
      setLargeFile(false);
      onUpdate();
    }

    const handleCloseAuditDetailPopup = () => {
      setLargeFile(false);
      auditDetailPopup.closePopup();

    }
    const handleOpenLargeFile = () => {
      setLargeFile(true);
      auditPopup.openPopup()
    }

    const handlePrepare = (audit: AuditDto) => {
      const myAccount = authEntity?.userInfo?.account || "";
      const chkFlag = audit.preparer_list?.filter(item => item.account === myAccount).length > 0;
      if (audit.prepare_status === "초기화") {
        setAudit(detailInfo.audit_list.filter((elem) => elem.file_name === audit.file_name)[0]);
        auditPreparePopup.openPopup()
      } else if (audit.prepare_status === "프리페어" && !chkFlag) {
        setAudit(detailInfo.audit_list.filter((elem) => elem.file_name === audit.file_name)[0]);
        auditPreparePopup.openPopup()
      } else {
        const detail = {
          ...detailInfo,
          audit_list: [audit]
        }
        confirmWithWarn("프리페어 취소", "현재 해당 조서에 프리페어 하였습니다. 해제하시겠습니까?", (result) => { result && onPrepare(detail, false) })
      }
    }
    const handleConfirmPrepare = () => {
      const detail = {
        ...detailInfo,
        audit_list: [audit]
      }
      auditPreparePopup.closePopup();

      onPrepare(detail, true);
    }

    const handleConfirmReview = (audit: AuditDto) => {
      const detail = {
        ...detailInfo,
        audit_list: [audit]
      }
      auditReviewPopup.closePopup();
      onReview(detail, true);
    }

    const handleReview = (audit: AuditDto) => {
      if (audit.review_status === "리뷰요청") {
        const detail = {
          ...detailInfo,
          audit_list: [audit]
        }
        confirmWithWarn("리뷰요청 취소", "현재 해당 조서는 리뷰 중입니다. 취소하시겠습니까?", (result) => { result && onReview(detail, false) })
      } else {
        setAudit(detailInfo.audit_list.filter((elem) => elem.file_name === audit.file_name)[0]);
        auditReviewPopup.openPopup()
      }
    }

    const auditOnScroll = (e: any) => {
      setScrollTop(e.target.scrollTop)
    }
    const auditOnScroll2 = (e: any) => {
      setScrollTop2(e.target.scrollTop)
    }

    const isProjectDisabled = projectApi.isProjectDisabled();
    return (
      <div className={classNames("detail-view", { "read-only": !editMode })}>
        <div className='requirements-area'>
          <div className="detail-header element">
            <div className="title" style={{ paddingLeft: 10 }}>{detailInfo.name}</div>
            <div className="status" style={{ width: 150, display: "flex", flexDirection: "row", }}>
              <div className='label'>상태</div>
              {detailInfo.status ?
                <div className="status-root">
                  <div className="status-normal" style={{ backgroundColor: statusColor[`${detailInfo.status}`] }}>
                  </div>
                  <div>
                    {detailInfo.status}
                  </div>
                </div>
                :
                <div className="status-root">
                  <div className="status-none">
                  </div>
                  <div style={{ color: "#cecfd0" }}>
                    {"미진행"}
                  </div>
                </div>
              }
            </div>
            <div className="account">
              <span className='label'>담당자</span>
              <span>{detailInfo.responsibility.nickname}</span>
            </div>
            <IconButton classes={classNames("icon-edit")}
              toggle={editMode}
              borderd={editMode} disabled={isProjectDisabled}
              onClick={() => setEditMode(!editMode)}>
              <EditIcon color="#292929" />
            </IconButton>
          </div>
          <div className="detail-body">
            <div className="detail-body-title element">
              <div className="title" style={{ paddingLeft: 10 }}><DotIcon classes="dot-icon" color='#43425d' />요구사항</div>
            </div>
            <div className="detail-body-requirements">
              <div className={classNames("requirements-editor")}>
                <ReactQuill
                  modules={{
                    toolbar: [
                      [
                        { list: "ordered" },
                        { list: "bullet" },
                        { indent: "-1" },
                        { indent: "+1" },
                      ],

                    ]
                  }}
                  readOnly={!editMode}
                  value={requirements || ''}
                  style={{ fontSize: 50 }}
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>
          <div className="detail-footer common-page-footer project-footer">
            <div className={classNames('right-button')}>
              <Button classes="footer-button" text="취소" color="secondary" onClick={handleCancel} />
              <Button classes="footer-button" text="확인" onClick={handleConfirm} />
            </div>
          </div>
        </div>
        <div className='audit-area'>
          {!editMode && <div className={"detail-list"}>
            <div className="detail-list-title">
              세부 TASK 목록
            </div>
            <div className="detail-list-select">
              <Select
                autoWidth={true}
                selectFirstItem={false}
                selectedEventKey={detailName.replace("%2F", "/")}
                textAlign='center'
                align='left'
                border={true}
                onClick={(detailName) => {
                  const _detailName = encodeURIComponent(detailName)
                  history.push(`/projects/${projectId}/category/${categoryName}/${taskName}/${_detailName}`);
                }}
              >
                {detailList && detailList.map((elem) => (
                  <SelectItem key={elem} eventKey={elem} >
                    {elem}
                  </SelectItem>
                ))
                }
              </Select >
            </div>
          </div>}
          <div className={classNames("file-list", { "read-only": !editMode })}>
            <div className="title">
              <span>조서</span>
              <Button disabled={isProjectDisabled} classes="button" text="업로드" onClick={auditPopup.openPopup} />
            </div>
            <div className="list" onScroll={auditOnScroll} ref={(el: any) => {
              const height = el?.getClientRects()[0].height;
              setListHeight(height)
            }}>
              {auditList && auditList.length > 0 &&
                auditList.filter(elem => elem.type === 'small').map((elem, index) => {
                  const lastItemFlag = ((index + 1) * 40) - (scrollTop) > (listHeight / 2) ? true : false;
                  return <AuditElement
                    key={"audit-small-elem-" + index}
                    auditDto={elem}
                    lastItem={lastItemFlag}
                    onDetail={handleClickAuditDetail}
                    onDelete={handleClickAuditDelete}
                    onPrepare={() => { handlePrepare(elem) }}
                    onReview={() => { handleReview(elem) }}
                  />
                })
              }
              <div style={{ height: 100 }} />
            </div>
          </div>
          <div className="file-list">
            <div className="title">
              <span>대용량 증빙</span>
              <Button disabled={isProjectDisabled} classes="button" text="업로드" onClick={handleOpenLargeFile} />
            </div>
            <div className="list" onScroll={auditOnScroll2} ref={(el: any) => {
              const height = el?.getClientRects()[0].height;
              setListHeight2(height)
            }}>
              {auditList && auditList.length > 0 &&
                auditList.filter(elem => elem.type === 'large').map((elem, index) => {
                  const lastItemFlag = ((index + 1) * 40) - (scrollTop2) > (listHeight2 / 2) ? true : false;
                  return <AuditElement
                    key={"audit-large-elem-" + index}
                    auditDto={elem}
                    lastItem={lastItemFlag}
                    onDetail={handleClickAuditDetailLarge}
                    onDelete={handleClickAuditDelete}
                    onPrepare={() => { handlePrepare(elem) }}
                    onReview={() => { handleReview(elem) }}
                  />
                })
              }
            </div>
          </div>
        </div>
        {
          auditPopup.opened &&
          <AuditRegisterPopup open={auditPopup.opened}
            detailDto={detailInfo}
            largeFile={largeFile}
            onClose={handleClosePopup}
          />
        }
        {
          auditDetailPopup.opened &&
          <AuditDetailPopup open={auditDetailPopup.opened}
            auditDto={audit}
            largeFile={largeFile}
            onClose={handleCloseAuditDetailPopup}
          />
        }
        {
          auditPreparePopup.opened &&
          <AuditPreparePopup
            taskName={taskName}
            detailName={detailName}
            auditDto={audit}
            open={auditPreparePopup.opened}
            onClose={auditPreparePopup.closePopup}
            onConfirm={handleConfirmPrepare}
          />
        }
        {
          auditReviewPopup.opened &&
          <AuditReviewPopup
            categoryName={categoryName}
            taskName={taskName}
            detailName={detailName}
            auditDto={audit}
            open={auditReviewPopup.opened}
            onClose={auditReviewPopup.closePopup}
            onConfirm={handleConfirmReview}
          />
        }

      </div >
    )

  }

interface AuditElementProps extends CommonProps {
  auditDto: AuditDto;
  lastItem?: boolean;
  onDetail: (name: string) => void;
  onDelete: (name: string) => void;
  onPrepare: () => void;
  onReview: () => void;
}

export const AuditElement: React.FC<AuditElementProps> = ({
  auditDto,
  lastItem = false,
  onDetail,
  onDelete,
  onPrepare,
  onReview,
}) => {
  const preparer = auditDto.preparer_list?.map(elem => elem.account).join(' ');
  const reviewer = auditDto.reviewer_list?.map(elem => elem.account).join(' ');
  const isProjectDisabled = projectApi.isProjectDisabled();
  return (
    <div className="audit-element">
      <LinkIcon color="#bcbccb" />
      <div className="file-name" style={{ overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis", marginRight: 10, height: 20, lineHeight: "20px" }}>
        <a href={auditDto.uri?.path} download target="_blank" >
          {auditDto.file_name}
        </a>
      </div>
      {!isProjectDisabled && <div className={classNames("preparer icon-root", {
        "done": auditDto.prepare_status === "프리페어",
      }
      )}
        onClick={onPrepare}
      >
        {
          preparer ?
            <ToolTip message={preparer}>
              <span>P</span>
            </ToolTip>
            :
            <span>P</span>
        }

      </div>}
      {!isProjectDisabled && <div className={classNames("reviewer icon-root", {
        "request": auditDto.review_status === "리뷰요청",
        "reject": auditDto.review_status === "리뷰반려",
        "done": auditDto.review_status === "리뷰승인",
      }
      )}
        onClick={onReview}
      >
        {
          reviewer ?
            <ToolTip message={reviewer}>
              <span>R</span>
            </ToolTip>
            :
            <span>R</span>
        }
      </div>}
      <SettingButton classes="icon-more icon-root-last"
        lastItem={lastItem} icon={<MoreIcon2 />}>
        <DropdownItem onClick={() => { onDetail(auditDto.file_name); }} classes={'setting-edit'}>
          <span>상세보기</span>
        </DropdownItem>
        {!isProjectDisabled && <DropdownItem onClick={() => { onDelete(auditDto.file_name); }} classes={'setting-delete'}>
          <span>삭제하기</span>
        </DropdownItem>}
      </SettingButton>
    </div>
  )
}
export const ProjectContext = createContext({
  projectInfo: {} as ProjectDto
})

interface CategoryProps extends CommonProps {
  projectInfo: ProjectDto;
}


const Category: React.FC<CategoryProps> = ({
  projectInfo
}) => {
  let { projectId, category, taskName, detailName, transType, detailTransType } = useParams<{ projectId: string, category: string, taskName: string, detailName: string, transType: string, detailTransType: string }>();
  detailName = detailName && decodeURIComponent(detailName);
  const [categoryDto, setCategoryDto] = useState<CategoryDto>({} as CategoryDto);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [curDetailName, setCurDetailName] = useState<string>(detailName);
  const [reload, setReload] = useState<boolean>(false);
  const value = useMemo(() => ({ projectInfo }), [projectInfo]);
  const history = useHistory();
  const { alert2 } = useAlert();

  useEffect(() => {
    if (projectInfo.template?.name) {
      const requestData = createTemplate(projectInfo.name, category.toUpperCase());
      const getTaskList = requestData.category_list[0].task_list;
      const detailInfo = {} as DetailDto;
      const taskInfo = {} as TaskDto;
      detailInfo.name = detailName;
      taskInfo.name = taskName;
      taskInfo.detail_list = [];
      taskInfo.detail_list.push(detailInfo);
      requestData._id = sessionStorage.getItem("templateId") || "";

      getTaskList[0] = { ...taskInfo };

      templateApi
        .getTemplatebyDTO(requestData)
        .then((template) => {
          const info = template.category_list.filter((elem) => elem.name.toUpperCase() === category.toUpperCase());
          if (info.length > 0) {
            setCategoryDto(info[0]);
          }
          sessionStorage.setItem("templateId", template?._id || "")
          sessionStorage.setItem("templateName", template?.name || "")

        }).catch(e => {
          alert2("에러 발생", e)
        })
      projectApi.getProject(projectId)
        .then(resp => projectApi.setProjectStatus(resp.status || ""))
    }
  }, [projectInfo, category])

  // 화면이 바뀌면 다시 로드한다.... 세부 태스크 목록을 수정한 경우 등등...
  useEffect(() => {
    return history.listen((location) => {
      console.log(`You changed the page to: ${location.pathname}`)
      setReload(true)
    })

  }, [history])

  useEffect(() => {

    if (reload) {
      const requestData = createTemplate(projectInfo.name, category.toUpperCase());
      const getTaskList = requestData.category_list[0].task_list;
      const detailInfo = {} as DetailDto;
      const taskInfo = {} as TaskDto;
      detailInfo.name = detailName;
      taskInfo.name = taskName;
      taskInfo.detail_list = [];
      taskInfo.detail_list.push(detailInfo);
      requestData._id = sessionStorage.getItem("templateId") || "";

      getTaskList[0] = { ...taskInfo };
      templateApi
        .getTemplatebyDTO(requestData)
        .then((template) => {
          const info = template.category_list.filter((elem) => elem.name.toUpperCase() === category.toUpperCase());
          if (info.length > 0) {
            setCategoryDto(info[0]);
            setReload(false);
          }
          sessionStorage.setItem("templateId", template?._id || "")
          sessionStorage.setItem("templateName", template?.name || "")
        }).catch(e => {
          alert2("에러 발생", e)
        })
    }
  }, [reload])

  const isDetail = detailName?.length > 0;
  const isGmToc = transType?.length > 0 && detailTransType?.length > 0;

  let detailInfo = {} as DetailDto;
  let detailList = [] as string[];
  const currentTask: TaskDto = categoryDto.task_list?.filter((task) => task.name === taskName)[0];

  if (isDetail) {
    detailInfo = currentTask?.detail_list?.filter((detail) => detail.name === detailName)[0];
    currentTask?.detail_list?.forEach(detail => {
      detailList.push(detail.name)
    });
  }

  const handleMyFavorite = (task: TaskDto) => {
    const requestData = createTemplate(projectInfo.name, category.toUpperCase());
    requestData.category_list[0].task_list[0] = task;
    console.log("task : ", task)
    const proejectData = { name: projectInfo.name, project_id: projectId };
    const setFlag = task?.detail_list[0]?.is_favorite;
    templateApi
      .setMyFavorite(proejectData, requestData, !setFlag)
      .then((resp) => {
        // console.log('edit TASK', resp);
        setReload(true);
      })
      .catch(err => alert2("에러발생", err))
  }

  const handleEditTask = (task: TaskDto) => {

    const requestData = createTemplate(projectInfo.name, category.toUpperCase());

    requestData.category_list[0].task_list[0] = task;

    templateApi
      .editDetailList(requestData)
      .then((resp) => {
        // console.log('edit TASK', resp);
        setReload(true);
      })
  }

  const handleDeleteTask = async (task: TaskDto): Promise<any> => {
    const requestData = createTemplate(projectInfo.name, category.toUpperCase());

    requestData.category_list[0].task_list[0] = task;

    templateApi
      .deleteTask(requestData)
      .then((resp) => {
        // console.log('delete TASK', resp);
        setReload(true);
      }
      )
      .catch(e => {
        console.log("delete TASK error", e)
        alert2("TASK 삭제", e)
      })
  }

  const handleEditDetail = (detail: DetailDto) => {
    const requestData = createTemplate(projectInfo.name, category.toUpperCase());
    const updateTaskList = requestData.category_list[0].task_list;
    requestData._id = sessionStorage.getItem("templateId") || "";

    updateTaskList[0] = { ...currentTask };
    updateTaskList[0].detail_list = [];
    updateTaskList[0].detail_list.push(detail);

    templateApi
      .editDetail(requestData)
      .then((resp) => {
        // console.log('edit detail', resp);
        setReload(true);
      }).catch(e => {
        alert2("에러 발생", e)
        setReload(true);
      })
  }

  const handleDeleteAudit = (detail: DetailDto) => {

    const requestData = createTemplate(projectInfo.name, category.toUpperCase());
    const updateTaskList = requestData.category_list[0].task_list;
    requestData._id = sessionStorage.getItem("templateId") || "";

    updateTaskList[0] = { ...currentTask };
    updateTaskList[0].detail_list = [];
    updateTaskList[0].detail_list.push(detail);
    console.log("requestData : ", requestData)
    templateApi
      .deleteAudit(requestData)
      .then((resp) => {
        // console.log('delete audit', resp);
        setReload(true);
      }).catch(e => {
        alert2("에러 발생", e)
        setReload(true);
      })
  }

  const handleUpdateSubDetail = (detail: SpecialDetailDto) => {
    // 중간감사 4단계에서 태스크들 수정할 때 타는 코드
    // console.log("handleUpdateSubDetail : ", detail)
    const requestData = createTemplate(projectInfo.name, category.toUpperCase());
    const updateTaskList = requestData.category_list[0].task_list;
    requestData._id = sessionStorage.getItem("templateId") || "";

    updateTaskList[0] = { ...currentTask };
    updateTaskList[0].detail_list = [];
    updateTaskList[0].detail_list.push(detail);
    // console.log("requestData : ", requestData)
    templateApi
      .updateDetailTask(requestData)
      .then((resp) => {
        // console.log('edit detail', resp);
        setReload(true);
      }).catch(e => {
        alert2("에러 발생", e)
        setReload(true);
      })
  }

  const handleEditDetailItem = (detail: SpecialDetailDto) => {
    // console.log("handleEditDetailItem : ", detail)
    const requestData = createTemplate(projectInfo.name, category.toUpperCase());
    const updateTaskList = requestData.category_list[0].task_list;
    requestData._id = sessionStorage.getItem("templateId") || "";

    updateTaskList[0] = { ...currentTask };
    updateTaskList[0].detail_list = [];
    updateTaskList[0].detail_list.push(detail);

    let service = "";
    if ((detailInfo as SpecialDetailDto).detail_stage_name === "RiskAssessment_1") {
      service = "UpdateDetailRiskAssessStep1";
    } else if ((detailInfo as SpecialDetailDto).detail_stage_name === "RiskAssessment_2") {
      service = "UpdateDetailRiskAssessStep2";
    } else if ((detailInfo as SpecialDetailDto).detail_stage_name === "RiskAssessment_4") {
      service = "UpdateDetailRiskAssessStep4";
    } else if ((detailInfo as SpecialDetailDto).detail_stage_name === "InterimAudit_1") {
      service = "UpdateDetailInterimAuditStep1";
    } else if ((detailInfo as SpecialDetailDto).detail_stage_name === "InterimAudit_2") {
      service = "UpdateDetailInterimAuditStep2";
    } else if ((detailInfo as SpecialDetailDto).detail_stage_name === "InterimAudit_3") {
      service = "UpdateDetailInterimAuditStep3";
    } else if ((detailInfo as SpecialDetailDto).detail_stage_name === "ITManagement_1") {
      service = "UpdateDetailITManagementStep1";
    } else if ((detailInfo as SpecialDetailDto).detail_stage_name === "ITManagement_2" || (detailInfo as SpecialDetailDto).detail_stage_name === "ITManagement_4") {
      service = "UpdateDetailITManagementStep2_4";
    } else {
      service = "UpdateDetail";
      alert2("에러 발생", "이 코드가 타면 안됨")
      return;
    }
    // console.log("requestData : ", requestData)
    templateApi
      .editDetailSpecial(requestData, service)
      .then((resp) => {
        // console.log('edit detail', resp);
        setReload(true);
      }).catch(e => {
        alert2("에러 발생", e)
        setReload(true);
      })
  }

  const handlePrepare = (detail: DetailDto, isConfirm: boolean) => {
    // console.log("detail : ", detail)
    const requestData = createTemplate(projectInfo.name, category.toUpperCase());
    const updateTaskList = requestData.category_list[0].task_list;
    requestData._id = sessionStorage.getItem("templateId") || "";

    updateTaskList[0] = { ...currentTask };
    updateTaskList[0].detail_list = [detail];
    // console.log("requestData : ", requestData)
    templateApi
      .handlePrepare(requestData, isConfirm)
      .then((resp) => {
        // console.log('cancel prepare', resp);
        setReload(true);
      })
      .catch(err => {
        console.log("err : ", err)
        if (err?.name?.includes("[already] prepared")) {
          alert2(isConfirm ? "프리페어" : "프리페어 취소", "이미 프리페어 상태입니다. 화면을 리프레쉬 합니다.")
        } else if (err?.name?.includes("canceled")) {
          alert2(isConfirm ? "프리페어" : "프리페어 취소", "이미 프리페어 취소상태입니다. 화면을 리프레쉬 합니다.")
        } else {
          alert2(isConfirm ? "프리페어" : "프리페어 취소", err)
        }

        setReload(true);
      })
  }

  const handleReview = (detail: DetailDto, isConfirm: boolean) => {
    const requestData = createTemplate(projectInfo.name, category.toUpperCase());
    const updateTaskList = requestData.category_list[0].task_list;
    requestData._id = sessionStorage.getItem("templateId") || "";

    updateTaskList[0] = { ...currentTask };
    updateTaskList[0].detail_list = [detail];
    // console.log("requestData : ", requestData)
    templateApi
      .handleReview(requestData, isConfirm ? "Request" : "Cancel")
      .then((resp) => {
        // console.log('audit review', resp);
        setReload(true);
      })
      .catch(err => {
        console.log("err : ", err)
        if (err?.name?.includes("[already] review requsted")) {
          alert2(isConfirm ? "리뷰요청" : "리뷰요청 취소", "이미 리뷰요청이 된 상태입니다. 화면을 리프레쉬 합니다.")
        } else if (err?.name?.includes("canceled")) {
          alert2(isConfirm ? "리뷰요청" : "리뷰요청 취소", "이미 리뷰요청 취소가 된 상태입니다. 화면을 리프레쉬 합니다.")
        } else {
          alert2(isConfirm ? "리뷰요청" : "리뷰요청 취소", err)
        }
        setReload(true);
      })
  }

  // console.log("categoryDto : ", categoryDto)
  // console.log("taskName : ", taskName)
  // console.log("detailInfo : ", detailInfo)

  return (
    <div className={classNames('category-page-body')}
    >
      <ProjectContext.Provider value={value}>
        {isDetail ?
          categoryDto?.name === "PROCEDURE" || (detailInfo as SpecialDetailDto)?.sub_task_type === "default" || (detailInfo as SpecialDetailDto)?.detail_stage_name ?
            isGmToc ?
              <SpecialDetailView2
                currentTask={currentTask}
                categoryName={categoryDto?.name}
                detailList={detailList}
                detailInfo={detailInfo}
                isGmToc={isGmToc}
                onEditDetail={handleEditDetail}
                onDeleteAudit={handleDeleteAudit}
                onUpdate={() => setReload(true)}
                onPrepare={handlePrepare}
                onReview={handleReview}
              />
              : <SpecialDetailView
                currentTask={currentTask}
                categoryName={categoryDto?.name}
                detailList={detailList}
                detailInfo={detailInfo}
                onEditDetail={handleEditDetail}
                onEditDetailItem={handleEditDetailItem}
                onUpdateSubDetail={handleUpdateSubDetail}
                onDeleteAudit={handleDeleteAudit}
                onUpdate={() => setReload(true)}
                onPrepare={handlePrepare}
                onReview={handleReview}
              />
            :
            <DetailView
              categoryName={categoryDto?.name}
              detailList={detailList}
              detailInfo={detailInfo}
              onEditDetail={handleEditDetail}
              onDeleteAudit={handleDeleteAudit}
              onUpdate={() => setReload(true)}
              onPrepare={handlePrepare}
              onReview={handleReview}
            />

          : <TaskList
            taskList={categoryDto.task_list}
            onAddTask={handleEditTask}
            onUpdate={() => setReload(true)}
            onEditTask={handleEditTask}
            onDeleteTask={handleDeleteTask}
            onMyFavorite={handleMyFavorite}
          />}
      </ProjectContext.Provider>
    </div>

  )

}

export default Category;