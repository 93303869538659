import React from 'react';
import classNames from 'classnames';

import { CommonProps } from 'src/components/helpers/props';
import useComponentVisible from 'src/hooks/useComponentVisible';

import SettingsIcon from '../icons/SettingsIcon';

import './index.scss';
import ArrowDropDownIcon from '../icons/ArrowDropDownIcon';
import MoreIcon2 from '../icons/MoreIcon2';
import IconButton from '../IconButton';

interface DropdownItemProps extends CommonProps {
  severity?: 'error';
  onClick?: (e: React.MouseEvent<HTMLSpanElement>) => void;
}

export const DropdownItem: React.FC<DropdownItemProps> = ({
  children,
  severity,
  onClick,
  classes,
}) => {
  return (
    <li
      className={classNames(
        {
          'dropdown-item-severity-error': severity === 'error',
        },
        classes
      )}
    >
      <span onClick={onClick}>{children}</span>
    </li>
  );
};

export interface SettingButton2Props extends CommonProps {
  clicked?: boolean;
  hover?: boolean;
}

const SettingButton2: React.FC<SettingButton2Props> = ({
  clicked = false,
  hover = false,
  classes,
  children,
}) => {
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(clicked);

  return (
    <div
      className={classNames('setting-button2-root', { on: isComponentVisible }, classes)}
      ref={ref}
    >
      <IconButton
        onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
          e.stopPropagation();
          setIsComponentVisible(!isComponentVisible);
        }}
        unfilled
        hover
      >
        <MoreIcon2 />
      </IconButton>
      <div className="setting-button-list">
        <ul>
          {children}
          {/* <li>
            <span>
              <EditIcon />
              수정
            </span>
          </li>
          <li>
            <span>
              <DeleteIcon />
              삭제
            </span>
          </li> */}
        </ul>
      </div>
    </div>
  );
};

export default SettingButton2;
