import _ from 'lodash';

import config from 'src/shared/config';
import axios from 'src/shared/utils/axios';

import DummyApi from './dummyApi';
import { times } from './api';

const FSP_API_ENABLED = config.servers.fsp.enabled;
const FSP_API_URL = config.servers.fsp.url;

export type ProductType =
  | 'FED5-FSN'
  | 'FED5-FSD'
  | 'FED4-FSN'
  | 'FED4-FSD'
  | 'FSE'
  | 'WRAPSODY'
  | 'FSP';

export interface ProductsDto {
  type: ProductType;
  dsdCode: string;
}

export interface ProductAliasDto {
  type: ProductType;
  dsdCode: string;
  targetCode: string;
  description: string;
}

export interface ProductSpecDto extends ProductAliasDto {
  documentClasses: Array<{
    code: string;
    name: string;
  }> | null;
}

interface ProductSpecApi {
  list(): Promise<ProductSpecDto[]>;
}
class ProductSpecServerApi implements ProductSpecApi {
  async list(): Promise<ProductSpecDto[]> {
    const resp = await axios.get<ProductSpecDto[]>(`${FSP_API_URL}/api/configuration/products`);
    return resp.data;
  }
}
class ProductSpecDummyApi implements ProductSpecApi {
  private data: ProductSpecDto[];

  constructor() {
    this.data = [
      {
        targetCode: 'FSP 문서',
        dsdCode: 'FSP4000000000101',
        type: 'FSP',
        description: 'FSP 설명',
        documentClasses: null,
      },
      {
        targetCode: 'FED5-FSN 문서',
        dsdCode: '0100000000001001',
        type: 'FED5-FSN',
        description: 'FED5-FSN 문서 설명',
        documentClasses: [
          {
            code: 'NX',
            name: '기본 NX 등급',
          },
          {
            code: 'f9834c1394e6416aa36d69eff11743d6',
            name: '대외비 등급',
          },
          {
            code: '5e2846e50c53420a9ab2cfea7864d048',
            name: '일반 등급',
          },
          {
            code: '8ca9674870714294ab968785d0d1c20f',
            name: '호드문서등급',
          },
          {
            code: '401882f6436145218a61771af959d79f',
            name: '한등급',
          },
          {
            code: 'aa2edf3a25e44b0694f54fb634f4c347',
            name: '한등급_실시간_편집',
          },
          {
            code: '12251577c6664711aacf000c5afb3ace',
            name: '진선등급',
          },
        ],
      },
      {
        targetCode: 'FED5-FSD 문서',
        dsdCode: '0100000000000102',
        type: 'FED5-FSD',
        description: 'FED5-FSD 문서 설명',
        documentClasses: [
          {
            code: 'DEMOSYSTEM',
            name: 'DEMOSYSTEM',
          },
          {
            code: 'SAMPLE',
            name: 'SAMPLE',
          },
        ],
      },
      {
        targetCode: 'WRAPSODY 문서',
        dsdCode: '0100000000000101',
        type: 'WRAPSODY',
        description: 'WRAPSODY 문서 설명',
        documentClasses: [
          {
            code: '53a9d38d587e4b80a9351a401f97072d',
            name: '일반 (General)',
          },
          {
            code: '7a2f4ac776264a10b94c0e10e3c59873',
            name: '보안 (Confidential)',
          },
          {
            code: '9ad8d6c9072e41f3bf63e7ac775e3329',
            name: '극비 (Highly Confidential)',
          },
        ],
      },
    ];
  }

  list(): Promise<ProductSpecDto[]> {
    return Promise.resolve(_.cloneDeep(this.data));
  }
}

const productSpecApi: ProductSpecApi = FSP_API_ENABLED
  ? new ProductSpecServerApi()
  : new ProductSpecDummyApi();

export default productSpecApi;
