import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import Button from 'src/components/atom/Button';
import Table from 'src/components/atom/Table';
import IconButton from 'src/components/atom/IconButton';
import { CommonProps } from 'src/components/helpers/props';
import Popup2 from 'src/components/molecules/Popup2';
import useSearchBar from 'src/hooks/useSearchBar';
import SearchBar from 'src/components/molecules/SearchBar';
import ScrollBox from 'src/components/atom/ScrollBox';
import { MemberDto } from 'src/api/userSettingApi';
import Checkbox from 'src/components/atom/Checkbox';
import { RoleDto, PermissionDto } from 'src/api/roleApi';
import role from 'src/store/fidp/role';

import { useAlert } from 'src/contexts/AlertContext';
import './UserListPopup.scss';
import ArrowRightIcon from 'src/components/atom/icons/ArrowRightIcon';
import ArrowLeftIcon from 'src/components/atom/icons/ArrowLeftIcon';

interface UserListPopupProps extends CommonProps {
  dtoList: MemberDto[];
  dtoList2: MemberDto[];
  // roleList: RoleDto[];
  // permissionList: PermissionDto[];
  open: boolean;
  onClose: (checkedUser?: MemberDto[]) => void;
  selected: MemberDto[];
}

const UserListPopup: React.FC<UserListPopupProps> = ({
  dtoList,
  dtoList2,
  open,
  onClose,
  selected: selectedUserProp,
  classes,
  // roleList,
  // permissionList,
}) => {
  const { t } = useTranslation(['commons']);

  const [selectedUsers, setSelectedUsers] = useState<MemberDto[]>(selectedUserProp);
  const [leftUsers, setLeftUsers] = useState<MemberDto[]>(dtoList);
  const [leftSelectedUsers, setLeftSelectedUsers] = useState<MemberDto[]>([]);
  const [rightUsers, setRightUsers] = useState<MemberDto[]>(dtoList2);
  const [rightSelectedUsers, setRightSelectedUsers] = useState<MemberDto[]>([]);
  const { alert2 } = useAlert();
  const { filteredEntityList: filteredDtoList, searchText, handleChangeSearchText } = useSearchBar({
    entityList: leftUsers,
    keys: ['account', 'nickname'],
  });

  useEffect(() => {
    if (!open) {
      setSelectedUsers(selectedUserProp);
      handleChangeSearchText('');
    }
  }, [handleChangeSearchText, open, selectedUserProp, selectedUsers]);

  // const checkUserPermissions = (userList: UserInfoEntity[]): string => {
  //   if (roleList.length < 1 || permissionList.length < 1) {
  //     return '';
  //   }

  //   const checkEvalMaster = (user: UserInfoEntity): boolean => {
  //     const permissionsIds = roleList
  //       .filter((role) => user.userRole.includes(role.id))
  //       .flatMap((role) => role.permission)
  //       .map((per) => per.resourceId);

  //     return permissionsIds.includes('eval_master');
  //   };

  //   const evalMasterUsers = userList.filter((user) => checkEvalMaster(user));

  //   if (evalMasterUsers.length > 1) {
  //     return evalMasterUsers.map((user) => user.name).join(', ');
  //   }

  //   return '';
  // };

  useEffect(() => {
    console.log("dtoList :", dtoList)
    console.log("dtoList2 :", dtoList2)
  }, [])

  const handleOk = (): void => {
    // const evalMasterUsers = checkUserPermissions(selectedUsers);
    // if (evalMasterUsers.length > 1) {
    //   alert(`최종 적정성 권한을 가진 사용자는 한명만 추가할 수 있습니다.\n[${evalMasterUsers}]`);
    // } else {
    if (rightUsers.length === 0) {
      alert2("사용자 선택", "최소 1명 이상의 사용자가 필요합니다.")
      return;
    }
    onClose(rightUsers);
    // }
  };
  const handleCancel = (): void => {
    onClose(dtoList2);
  };

  const rightClickButton = (): void => {
    const newLeftUsers = leftUsers.filter(({ account: account1 }) => !leftSelectedUsers.some(({ account: account2 }) => account1 === account2))
    setLeftUsers(newLeftUsers)
    setLeftSelectedUsers([])
    const newRightUsers = [...rightUsers, ...leftSelectedUsers]
    setRightUsers(newRightUsers)
    setRightSelectedUsers([])
  }
  const leftClickButton = (): void => {
    const newRightUsers = rightUsers.filter(({ account: account1 }) => !rightSelectedUsers.some(({ account: account2 }) => account1 === account2))
    setRightUsers(newRightUsers)
    setRightSelectedUsers([])
    const newLeftUsers = [...leftUsers, ...rightSelectedUsers]
    setLeftUsers(newLeftUsers)
    setLeftSelectedUsers([])
  }

  return (
    <Popup2
      classes="user-set-list-popup-root"
      title="멤버 선택"
      width="1112px"
      height="692px"
      open={open}
      onClose={handleCancel}
      footer={
        <React.Fragment>
          <Button text={t('commons:cancel')} onClick={handleCancel} color="secondary" />
          <Button text={t('commons:ok')} onClick={handleOk} />
        </React.Fragment>
      }
    >
      <div className="left-side">
        <div className="search-bar-root">
          <SearchBar
            classes="search-bar"
            placeholder="사용자를 검색하세요."
            onChange={(e) => {
              setLeftSelectedUsers([]);
              handleChangeSearchText(e.currentTarget.value);
            }}
          ></SearchBar>


        </div>
        <ScrollBox height={390}>
          <Table headerFixed={true} headerBackgroundColor={"gray"}>
            <colgroup>
              <col className="col-checkbox"></col>
              <col style={{ width: '40%' }} />
              {/* <col style={{ width: '40%' }} /> */}
              <col style={{ width: '60%' }} />
              {/* <col style={{ width: '40%' }} /> */}
            </colgroup>
            <thead>
              <tr>
                <th></th>
                <th>ID</th>
                {/* <th>이메일</th> */}
                <th>이름(사번)</th>
                {/* <th>권한 목록</th> */}
              </tr>
            </thead>
            <tbody>
              {filteredDtoList.map((dto) => (
                <tr
                  key={dto.account}
                  className={classNames({
                    checked: leftSelectedUsers
                      ? leftSelectedUsers.map((user) => user.account).includes(dto.account)
                      : false,
                  })}
                >
                  <td className="cell-checkbox">
                    <Checkbox
                      name="UserSetListPopup.userSetDtoList"
                      checked={
                        leftSelectedUsers
                          ? leftSelectedUsers.map((user) => user.account).includes(dto.account)
                          : false
                      }
                      onChange={(name, value, checked) => {
                        checked === true
                          ? setLeftSelectedUsers(leftSelectedUsers.concat(dto))
                          : setLeftSelectedUsers(leftSelectedUsers.filter((user) => user.account !== dto.account));
                      }}
                    />
                  </td>
                  <td className="txt-word-break-all">{dto.account}</td>
                  {/* <td className="txt-word-break-all">{dto.email}</td> */}
                  <td className="txt-word-break-all">{dto.nickname + "(" + dto.employee_number + ")"}</td>
                  {/* <td className="txt-word-break-all">
                  {dto.role?.role_list.map((role) => role).join(', ')}
                </td> */}
                </tr>
              ))}
            </tbody>
          </Table>
        </ScrollBox>
      </div>
      <div className="middle-side">
        <IconButton onClick={rightClickButton}>
          <ArrowRightIcon />
        </IconButton>
        <IconButton onClick={leftClickButton}>
          <ArrowLeftIcon />
        </IconButton>
      </div>
      <div className="right-side">
        <div className="right-side-title" >
          * 아래 사용자들이 프로젝트 멤버로 지정됩니다 *
        </div>
        <ScrollBox height={390}>
          <Table headerFixed={true} headerBackgroundColor={"gray"}>
            <colgroup>
              <col className="col-checkbox"></col>
              <col style={{ width: '40%' }} />
              {/* <col style={{ width: '40%' }} /> */}
              <col style={{ width: '60%' }} />
              {/* <col style={{ width: '40%' }} /> */}
            </colgroup>
            <thead>
              <tr>
                <th></th>
                <th>ID</th>
                {/* <th>이메일</th> */}
                <th>이름(사번)</th>
                {/* <th>권한 목록</th> */}
              </tr>
            </thead>
            <tbody>
              {rightUsers.map((dto) => (
                <tr
                  key={dto.account}
                  className={classNames({
                    checked: rightSelectedUsers
                      ? rightSelectedUsers.map((user) => user.account).includes(dto.account)
                      : false,
                  })}
                >
                  <td className="cell-checkbox">
                    <Checkbox
                      name="UserSetListPopup.userSetDtoList"
                      checked={
                        rightSelectedUsers
                          ? rightSelectedUsers.map((user) => user.account).includes(dto.account)
                          : false
                      }
                      onChange={(name, value, checked) => {
                        checked === true
                          ? setRightSelectedUsers(rightSelectedUsers.concat(dto))
                          : setRightSelectedUsers(rightSelectedUsers.filter((user) => user.account !== dto.account));
                      }}
                    />
                  </td>
                  <td className="txt-word-break-all">{dto.account}</td>
                  {/* <td className="txt-word-break-all">{dto.email}</td> */}
                  <td className="txt-word-break-all">{dto.nickname + "(" + dto.employee_number + ")"}</td>
                  {/* <td className="txt-word-break-all">
                  {dto.role?.role_list.map((role) => role).join(', ')}
                </td> */}
                </tr>
              ))}
            </tbody>
          </Table>
        </ScrollBox>
      </div>
    </Popup2>
  );
};

export default UserListPopup;
