import * as React from 'react';
import classNames from 'classnames';

import { CommonProps } from 'src/components/helpers/props';

import './index.scss';

export interface FormGroupProps extends CommonProps {
  spacing?: 'auto' | 'none' | 5 | 6 | 8 | 10 | 20;
  direction?: 'row' | 'column';
  inline?: boolean;
  verticalAlign?: 'top' | 'middle';
}

const FormGroup: React.FC<FormGroupProps> = ({
  spacing,
  direction = 'row',
  inline,
  verticalAlign = 'middle',
  classes,
  children,
}) => {
  return (
    <div
      className={classNames(
        'form-group-root',
        {
          'form-group-inline': inline,
          'form-group-direction-row': direction === 'row',
          'form-group-direction-column': direction === 'column',
          'form-group-spacing-auto': spacing === 'auto',
          'form-group-spacing-0': spacing === 'none',
          'form-group-spacing-5': spacing === 5,
          'form-group-spacing-6': spacing === 6,
          'form-group-spacing-8': spacing === 8,
          'form-group-spacing-10': spacing === 10,
          'form-group-spacing-20': spacing === 20,
          'form-group-vertical-align-top': verticalAlign === 'top',
        },
        classes
      )}
    >
      {children}
    </div>
  );
};

export default FormGroup;
