import React from 'react';
import classNames from 'classnames';

import { CommonProps } from 'src/components/helpers/props';

import CloseIcon from 'src/components/atom/icons/CloseIcon';
import CheckCircleIcon from 'src/components/atom/icons/CheckCircleIcon';
import IconButton from 'src/components/atom/IconButton';

import './index.scss';

interface ToastProps extends CommonProps {
  type: 'info' | 'warn' | 'error';
  message: string;
  open: boolean;
  onClose: () => void;
}

const Toast: React.FC<ToastProps> = ({ open, onClose, message, classes }) => {
  return (
    <div className={classNames('toast-root', { on: open }, classes)}>
      <div className="toast-inner">
        <CheckCircleIcon />
        <p>{message}</p>
        <IconButton size="sm" unfilled onClick={() => onClose()}>
          <CloseIcon />
        </IconButton>
      </div>
    </div>
  );
};

export default Toast;
