import React, {useEffect, useState} from 'react';
import classNames from 'classnames';
import _ from 'lodash';

import { CommonProps } from 'src/components/helpers/props';

import './index.scss';
import CloseIcon from '../icons/CloseIcon';
import IconButton from '../IconButton';

export interface ChipInputProps extends CommonProps {
  value: string[];
  fullWidth: boolean;
  onAdd: (chip: string) => void;
  onDelete: (chip: string) => void;
  onUpdateInput: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const ChipInput: React.FC<ChipInputProps> = ({
  value,
  fullWidth,
  onAdd,
  onDelete,
  onUpdateInput,
  classes,
}) => {

  const [searchText, setSearchText] = useState<string>('');
  useEffect(() => {
    setSearchText('')
  }, [value]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    onUpdateInput(e)
  };

  const handleRemove = (item: string): void => {
    onDelete(item)
  } 
  return (
    <div
      className={classNames(
        'chipinput-root',
        classes
      )}
    > 
      { value && value.map((item) => {
        return <ChipItem onClick={(value) => { handleRemove(value)}}>
          {item}
        </ChipItem>
      })
      }
      
      <div className='chip-input-area'>
        <input value={searchText} onChange={(e) => { setSearchText(e.target.value); handleChange(e)}} />
      </div>

    </div>
  );
};

interface ChipItemProps extends CommonProps {
  children: string;
  onClick: (value: string) => void;

}

export const ChipItem: React.FC<ChipItemProps> = ({ 
  children,
  onClick
}) => {
  return (
    <div className='chip-item' >
      <div>{children}</div>
      <IconButton size='sm' transparent hover onClick={(e) => {onClick(children)}}>
        <CloseIcon size='sm'/>
      </IconButton>
    </div>
  )
}

export default ChipInput;
