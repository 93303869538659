import React from 'react';
import classNames from 'classnames';

import { CommonProps } from 'src/components/helpers/props';

import './AbstractIcon.scss';

interface InfoOutlineIconProps extends CommonProps {
  size?: 'md' | 'sm';
}

const InfoOutlineIcon: React.FC<InfoOutlineIconProps> = ({ classes, size = 'md' }) => {
  if (size === 'md') {
    return (
      <svg
        className={classNames('icon-root', classes)}
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="#c4c4c4"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M12 6C15.3 6 18 8.7 18 12C18 15.3 15.3 18 12 18C8.7 18 6 15.3 6 12C6 8.7 8.7 6 12 6ZM12 4C7.6 4 4 7.6 4 12C4 16.4 7.6 20 12 20C16.4 20 20 16.4 20 12C20 7.6 16.4 4 12 4Z" />
        <path d="M12 8C11.4 8 11 8.4 11 9C11 9.6 11.4 10 12 10C12.6 10 13 9.6 13 9C13 8.4 12.6 8 12 8Z" />
        <path d="M12 11C11.4 11 11 11.4 11 12V15.5C11 16.1 11.4 16.5 12 16.5C12.6 16.5 13 16.1 13 15.5V12C13 11.4 12.6 11 12 11Z" />
      </svg>
    );
  } else if (size === 'sm') {
    return (
      <svg
        className={classNames('icon-root', classes)}
        color="#B5BBC2"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8.00016 14.6666C11.6821 14.6666 14.6668 11.6819 14.6668 7.99998C14.6668 4.31808 11.6821 1.33331 8.00016 1.33331C4.31826 1.33331 1.3335 4.31808 1.3335 7.99998C1.3335 11.6819 4.31826 14.6666 8.00016 14.6666Z"
          stroke="currentColor"
          strokeWidth="1.6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8 10.6667V8"
          stroke="currentColor"
          strokeWidth="1.6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8 5.33331H8.00667"
          stroke="currentColor"
          strokeWidth="1.6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  } else return null;
};

export default InfoOutlineIcon;
