import React from 'react';
import classNames from 'classnames';

import { CommonProps } from 'src/components/helpers/props';

import './index.scss';

interface ScrollBoxProps extends CommonProps {
  height?: number;
  maxHeight?: number;
}
const ScrollBox: React.FC<ScrollBoxProps> = ({ height, maxHeight, classes, children }) => {
  return (
    <div
      className={classNames('scroll-box-root', classes)}
      style={{
        height: height ? `${height}px` : undefined,
        maxHeight: maxHeight ? `${maxHeight}px` : undefined,
      }}
    >
      {children}
    </div>
  );
};

export default ScrollBox;
