import React, { useEffect, useState } from 'react';
import { Link as RouterLink, useHistory, useParams } from 'react-router-dom';
import _ from 'lodash';
import EditIcon from 'src/components/atom/icons/EditIcon';
import { CommonProps } from 'src/components/helpers/props';
import Grid, { GridItem } from 'src/components/atom/Grid';
import BreadcrumbArrowIcon from 'src/components/atom/icons/BreadcrumbArrowIcon';
import { useAlert } from 'src/contexts/AlertContext';
import { useToast } from 'src/contexts/ToastContext';
import TermDescription from 'src/components/atom/TermDescription';
import Button from 'src/components/atom/Button';

import './index.scss';
import userSettingApi, { UserInfoEntity, emptyUserInfoEntity } from 'src/api/userSettingApi';

const UserSettingShow: React.FC = () => {
  const { alert } = useAlert();
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const [dto, setDto] = useState<UserInfoEntity>(emptyUserInfoEntity());

  const handleClickEdit = (id: string): void => {
    history.push(`/admin-user/${id}/edit`);
  };

  useEffect(() => {
    userSettingApi
      .find(id)
      .then((resp) => {
        // setDto(resp);
      })
      .catch((err) => alert(err));
  }, [alert, id]);

  if (!dto) {
    return null;
  }

  return (
    <div className="user-form-root common-page-root">
      <div className="common-page-header">
        <Grid spacing={0}>
          <GridItem classes="common-page-grid-left" xs={12}>
            <ol className="common-page-header-breadcrumbs">
              <li>
                <RouterLink to="/admin-user">사용자 설정</RouterLink>
              </li>
              <li className="sep">
                <BreadcrumbArrowIcon />
              </li>
            </ol>
            <div className="common-page-header-title">{dto.name}</div>
          </GridItem>
        </Grid>
      </div>
      <div className="common-page-subheader">
        <Grid spacing={0}>
          <GridItem classes="common-page-grid-left" xs={6}>
            <div className="common-page-subheader-title">상세 정보</div>
          </GridItem>
          <GridItem classes="common-page-grid-right" xs={6}>
            <Button
              color="secondary"
              startIcon={<EditIcon />}
              text="수정"
              onClick={() => handleClickEdit(dto.code)}
            />
          </GridItem>
        </Grid>
      </div>

      <div className="common-page-body">
        <TermDescription v={2} term="아이디" description={dto.code} />
        <TermDescription v={2} term="이메일" description={dto.email} />
        <TermDescription v={2} term="이름" description={dto.name} />
        <TermDescription
          v={2}
          term="권한 템플릿"
          description={
            dto.userRoleName !== undefined &&
            dto.userRoleName !== null &&
            dto.userRoleName.length > 0
              ? dto.userRoleName.map((name) => name).join(', ')
              : ''
          }
        />
        <TermDescription v={2} term="소속" description={dto.company} />
        <TermDescription v={2} term="부서" description={dto.dept} />
        <TermDescription v={2} term="전화번호" description={dto.phone} />
        <TermDescription v={2} term="등록일" description={dto.createdToStr} />
        <TermDescription v={2} term="설명" description={dto.comments} />
      </div>
    </div>
  );
};

export default UserSettingShow;
