import _, { omit } from 'lodash';

import config from 'src/shared/config';
import axios from 'src/shared/utils/axios';

import { ProductAliasDto, ProductsDto } from './productSpecApi';

const FSP_API_ENABLED = config.servers.fsp.enabled;
const FSP_API_URL = config.servers.fsp.url;

// 인쇄 로그 품질
export enum LogQuality {
  HIGH = 'HIGH', // 24비트 트루 컬러
  LOW = 'LOW', // 256 흑백
}

// 인쇄 로그 크기
export enum LogSizeType {
  FIT = 'FIT', // 용지에 맞춤
  USERSELECT = 'USERSELECT', // 사용자 정의
}

export interface ConfigurationDto {
  imageLog: LogQuality; // 인쇄 로그 품질
  imageLogSizeType: LogSizeType; // 인쇄 로그 크기
  imageLogSize: number; // 인쇄 로그 사이즈 (imageLogSizeType === USERSELECT인 경우)
  detectionLog: LogQuality; // 검출 로그 품질
  detectionLogSizeType: LogSizeType; // 검출 로그 크기 타입
  detectionLogSize: number; // 검출 로그 사이즈 (detectionLogSizeType === USERSELECT인 경우)
  showPolicy: boolean; // 인쇄 정책 확인 활성화 여부
  emergencyPrint: boolean; // 긴급 인쇄 활성화 여부
  emergencyPrintExpiredReboot: boolean; // 재부팅 시 긴급 인쇄 권한 회수 여부
  printLogExportFieldInit: string[]; // 인쇄 로그 엑셀 내보내기 필드
  printLogExportField: string[]; // 인쇄 로그 엑셀 내보내기 필드
  detectionLogExportFieldInit: string[]; // 검출 로그 엑셀 내보내기 필드
  detectionLogExportField: string[]; // 검출 로그 엑셀 내보내기 필드
  pullprintingValidPeriod: number; // 풀프린팅 미출력 인쇄 작업 유효 시간
  classification: Array<ProductAliasDto>; // 분류 코드 목록
  product: Array<ProductsDto>; //선택대상이 되는 제품 및분류 코드 목록
}

export type ConfigurationReqDto = Omit<
  ConfigurationDto,
  'printLogExportFieldInit' | 'detectionLogExportFieldInit' | 'product'
>;

interface ConfigurationApi {
  get(): Promise<ConfigurationDto>;
  update(reqDto: ConfigurationReqDto): Promise<ConfigurationDto>;
}
class ConfigurationServerApi implements ConfigurationApi {
  async get(): Promise<ConfigurationDto> {
    const resp = await axios.get<ConfigurationDto>(`${FSP_API_URL}/api/configuration`);
    return resp.data;
  }
  async update(reqDto: ConfigurationReqDto): Promise<ConfigurationDto> {
    const resp = await axios.post<ConfigurationDto>(`${FSP_API_URL}/api/configuration`, reqDto);
    return resp.data;
  }
}
class ConfigurationDummyApi implements ConfigurationApi {
  data: ConfigurationDto;
  constructor() {
    this.data = {
      imageLog: LogQuality.HIGH,
      imageLogSizeType: LogSizeType.FIT,
      imageLogSize: 2000,
      detectionLog: LogQuality.HIGH,
      detectionLogSizeType: LogSizeType.USERSELECT,
      detectionLogSize: 2000,
      showPolicy: true,
      emergencyPrint: true,
      emergencyPrintExpiredReboot: true,
      printLogExportFieldInit: [
        'user_code',
        'user_name',
        'user_dept_code',
        'user_dept_name',
        'log_date',
        'doc_name',
        'print_page_count',
        'print_watermark_yn',
        'image_upload_status',
        'trans_id',
        'text_file_yn',
        'image_file_yn',
      ],
      printLogExportField: [
        'user_code',
        'user_name',
        'user_dept_code',
        'user_dept_name',
        'log_date',
        'doc_name',
        'print_page_count',
        'print_watermark_yn',
        'image_upload_status',
        'trans_id',
        'text_file_yn',
        'image_file_yn',
      ],
      detectionLogExportFieldInit: [
        'user_code',
        'user_name',
        'user_dept_code',
        'user_dept_name',
        'log_date',
        'print_date',
        'doc_name',
        'print_page_count',
        'product',
        'approval_type',
        'print_status',
        'image_upload_status',
        'trans_id',
        'text_file_yn',
        'image_file_yn',
      ],
      detectionLogExportField: [
        'user_code',
        'user_name',
        'user_dept_code',
        'user_dept_name',
        'log_date',
        'print_date',
        'doc_name',
        'print_page_count',
        'product',
        'approval_type',
        'print_status',
        'image_upload_status',
        'trans_id',
        'text_file_yn',
        'image_file_yn',
      ],
      pullprintingValidPeriod: 30,
      classification: [
        {
          targetCode: 'FED5-FSN 문서',
          dsdCode: '0100000000001001',
          type: 'FED5-FSN',
          description: 'FED5-N 문서 설명입니다.',
        },
        {
          targetCode: 'FED5-FSD 문서',
          dsdCode: '0100000000000102',
          type: 'FED5-FSD',
          description: 'FED5-R 문서 설명입니다.',
        },
        {
          targetCode: 'WRAPSODY용',
          dsdCode: '0100000000001001',
          type: 'WRAPSODY',
          description: 'Wrapsody 문서 설명입니다.',
        },
      ],
      product: [
        {
          dsdCode: '0100000000001001',
          type: 'FED5-FSN',
        },
        {
          dsdCode: '0100000000000102',
          type: 'FED5-FSD',
        },
        {
          dsdCode: '0100000000001001',
          type: 'WRAPSODY',
        },
      ],
    };
  }
  get(): Promise<ConfigurationDto> {
    return Promise.resolve(_.cloneDeep(this.data));
  }
  update(reqDto: ConfigurationReqDto): Promise<ConfigurationDto> {
    this.data = { ...this.data, ..._.cloneDeep(reqDto) };
    return Promise.resolve(_.cloneDeep(this.data));
  }
}

const configurationApi: ConfigurationApi = FSP_API_ENABLED
  ? new ConfigurationServerApi()
  : new ConfigurationDummyApi();

export default configurationApi;
