import React from 'react';

import ProjectUserCard from '../ProjectUserCard';
import { MemberDto } from 'src/api/userSettingApi';

import './index.scss';
import classNames from 'classnames';

export interface ProjectUserInfo extends MemberDto {
  image?: string,
  mail?: string,
  authority?: string,
  authId?: string[],
  authName?: string[],
}

interface ProjectUserListProps {
  userList?: ProjectUserInfo[];
  readOnly?: boolean;
  changeUserRole?: (user: ProjectUserInfo, roleList: string[]) => void;
  changeUserAuth?: (user: ProjectUserInfo, auth: string) => void;
}
const ProjectUserList: React.FC<ProjectUserListProps> = ({
  userList,
  readOnly,
  changeUserRole,
  changeUserAuth,
}) => {
  // console.log('projectuseList readonly', readOnly, userList)

  return (
    <div className='project-user-list-root'>
      <div className={classNames('project-user-menubar', { 'align-left': readOnly })}>
        <div className='name'>이름</div>
        <div className='auth'>권한</div>
        <div className='role'>담당</div>
      </div>
      <div className={classNames('project-user-list')}>
        {
          userList && userList.map((user, i) => {
            let modify = false;
            if (i !== 0) modify = true;
            if (readOnly)
              modify = false;
            else
              modify = true;

            return (
              <ProjectUserCard
                key={i}
                last={i + 1 === userList.length && i > 2 ? true : false}
                userRole={user.role.role_list}
                userAuth={user.role.authority_name}
                name={`${user.nickname} (${user.account})`}
                modify={modify}
                changeUserRole={(roleList: string[]) => { changeUserRole && changeUserRole(user, roleList) }}
                changeUserAuth={(auth) => { changeUserAuth && changeUserAuth(user, auth) }}
              />
            )
          })
        }
        <div style={{ height: 80 }} />
      </div>

    </div>
  )
}

export default ProjectUserList;