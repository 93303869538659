import React from 'react';
import classNames from 'classnames';

import { CommonProps } from 'src/components/helpers/props';

import './AbstractIcon.scss';

interface ArrowDownIconProps extends CommonProps {
  received?: boolean;
  size?: 'md';
  fill?: string;
}

const ArrowDownIcon: React.FC<ArrowDownIconProps> = ({
  classes,
  received = false,
  size = 'md',
  fill = '#2e353c',
}) => {
  return (
    <svg
      className={classNames('icon-root', classes)}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M7 9.51234C7 9.31275 7.09986 9.01338 7.29959 8.91359C7.69903 8.61422 8.19834 8.71401 8.49793 9.01338L11.9931 13.2046L15.4882 9.01338C15.7878 8.61422 16.2871 8.61422 16.6866 8.91359C17.086 9.21296 17.086 9.71192 16.7865 10.0113L12.5923 15.0008C12.3925 15.2004 12.1928 15.3002 11.9931 15.3002C11.7934 15.3002 11.4938 15.2004 11.3939 15.0008L7.19972 10.0113C7.09986 9.9115 7 9.71192 7 9.51234Z" />
    </svg>
  );
};

export default ArrowDownIcon;
