import React, { useEffect, useState } from 'react';
import templateApi, {
  DetailDto,
  AuditDto,
} from 'src/api/templateApi';
import usePopup from 'src/hooks/usePopup';
import classNames from 'classnames';
import EditIcon from 'src/components/atom/icons/EditIcon';
import IconButton from 'src/components/atom/IconButton';
import DotIcon from 'src/components/atom/icons/DotIcon';
import ReactQuill, { Quill } from 'react-quill';
import Button from 'src/components/atom/Button';

import ArrowDownIcon from 'src/components/atom/icons/ArrowDownIcon';
import ArrowUpIcon from 'src/components/atom/icons/ArrowUpIcon';
import Step4EditPopup from './Step4EditPopup';
import './Step4.scss';
import projectApi, { ProjectDto, emptyProject } from 'src/api/projectApi';

const DetailTaskElement: React.FC<{
  detailContent: { name: string, management_assertion_list: any },
  detailVisible: boolean,
  onHandleEdit: (item: any) => void;
}> = ({
  detailContent,
  detailVisible,
  onHandleEdit,
}) => {

    const [visible, setVisible] = useState<boolean>(detailVisible);
    //const ceoInsists = ["A", "C", "CO", "E", "O", "V", "RO", "CL", "U"];

    const handleEdit = (e: React.MouseEvent<HTMLButtonElement>, item: any) => {
      e.stopPropagation();
      onHandleEdit && onHandleEdit(item);
    }
    const isProjectDisabled = projectApi.isProjectDisabled();
    return (
      <div>
        <div className="sp-step4-detail-body-title element" style={{ paddingLeft: 10 }}
          onClick={() => setVisible(!visible)}
        >
          {visible ? <ArrowUpIcon classes='icon-up' /> :

            <ArrowDownIcon fill="#bcbccb" classes='icon-down' />
          }
          <div className="sp-step4-detail-account">{detailContent.name}</div>
          <div className="sp-step4-detail-account-edit-button">
            <IconButton disabled={isProjectDisabled} onClick={(e) => handleEdit(e, detailContent)}>
              <EditIcon size="sm" />
            </IconButton>
          </div>
        </div>
        {visible && detailContent.management_assertion_list?.map((elem: any, i: number) => {
          const uniqueRiskHigh = elem?.unique_risk === "높음";
          const controlRiskHigh = elem?.control_risk === "의존안함";
          const combinationRiskHigh = elem?.combination_risk === "높음";
          const significantRiskHigh = elem?.significant_risk ? true : false;
          const fraudRiskHigh = elem?.fraud_risk ? true : false;
          return (
            <div className="sp-step4-detail-body-table" key={i.toString()}>
              <div className="sp-step4-detail-body-table-title-root">
                <div className="sp-step4-detail-body-table-title">{elem.key}</div>
              </div>
              <div className="sp-step4-detail-body-table-risk1">
                <div className={classNames({ "sp-step4-detail-body-table-risk-text": uniqueRiskHigh })}>{elem.unique_risk}</div>
              </div>
              <div className="sp-step4-detail-body-table-risk2">
                <div className={classNames({ "sp-step4-detail-body-table-risk-text": controlRiskHigh })}>{elem.control_risk}</div>
              </div>
              <div className="sp-step4-detail-body-table-risk3">
                <div className={classNames({ "sp-step4-detail-body-table-risk-text": combinationRiskHigh })}>{elem.combination_risk}</div>
              </div>
              <div className="sp-step4-detail-body-table-risk4">
                <div className={classNames({ "sp-step4-detail-body-table-risk-text": significantRiskHigh })}>{elem.significant_risk}</div>
              </div>
              <div className="sp-step4-detail-body-table-risk5">
                <div className={classNames({ "sp-step4-detail-body-table-risk-text": fraudRiskHigh })}>{elem.fraud_risk}</div>
              </div>
            </div>
          )
        })}
      </div >
    )
  }

const Step4: React.FC<{
  detailTaskName: string,
  editMode: boolean,
  detailSubList: any,
  requirements: string,
  handleChange: (html: string) => void;
  handleCancelRequirement: () => void;
  handleConfirmRequirement: () => void;
  handleConfirmDetail?: (opinions: any) => void;
}> = ({
  detailTaskName,
  editMode,
  detailSubList,
  requirements,
  handleChange,
  handleCancelRequirement,
  handleConfirmRequirement,
  handleConfirmDetail = () => { }
}) => {
    const step4EditPopup = usePopup();
    const [account, setAccount] = useState();

    const handleDetailTaskElement = (item: any) => {
      setAccount(item)
      step4EditPopup.openPopup();
    }
    const renderDetailBody = () => {
      return detailSubList?.map((elem: any, idx: number) => {
        return <DetailTaskElement key={'detailTaskElement' + idx}
          detailContent={elem}
          detailVisible={false}
          onHandleEdit={handleDetailTaskElement}
        />
      })
    }

    const handleConfirmAccount = (riskData: any) => {
      let newTestData = [...detailSubList];
      let idx = newTestData.findIndex(obj => obj.name === riskData.name);
      if (idx !== -1) {
        newTestData[idx] = riskData
      }
      handleConfirmDetail([newTestData[idx]]);
      step4EditPopup.closePopup();
    }

    const handleClose = () => {
      // console.log("AAAAA : ", detailSubList)
      step4EditPopup.closePopup()
    }

    return (
      <div className="sp-step4-detail-body">
        <div className="sp-step4-detail-body-title element">
          <div className="title" style={{ paddingLeft: 10 }}><DotIcon classes="dot-icon" color='#43425d' />요구사항</div>
        </div>
        <div className="sp-step4-detail-body-requirements">
          <div className={classNames("requirements-editor")}>
            <ReactQuill
              modules={{
                toolbar: [
                  [
                    { list: "ordered" },
                    { list: "bullet" },
                    { indent: "-1" },
                    { indent: "+1" },
                  ],

                ]
              }}
              readOnly={!editMode}
              value={requirements || ''}
              style={{ fontSize: 50 }}
              onChange={handleChange}
            />
          </div>
          {!editMode && <div className="sp-step4-detail-body-title element">
            <div className="header" style={{ paddingLeft: 50 }}>
              <div className="risk">계정</div>
              <div className="risk1">고유 위험</div>
              <div className="risk2">통제 위험</div>
              <div className="risk3">결합 위험</div>
              <div className="risk4">유의적인 위험</div>
              <div className="risk5">부정 위험</div>
            </div>
          </div>}
          {!editMode && <div className="sp-step4-detail-body-content">
            {renderDetailBody()}
          </div>}
        </div>
        {editMode && <div className="sp-step4-detail-footer common-page-footer project-footer">
          <div className={classNames('right-button')}>
            <Button classes="footer-button" text="취소" color="secondary" onClick={handleCancelRequirement} />
            <Button classes="footer-button" text="확인" onClick={handleConfirmRequirement} />
          </div>
        </div>}
        {step4EditPopup.opened &&
          <Step4EditPopup
            open={step4EditPopup.opened}
            riskData={account || {}}
            onClose={handleClose}
            onConfirm={handleConfirmAccount}

          />
        }
      </div>)
  }

export default Step4;