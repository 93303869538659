import config from 'src/shared/config';
import axios from 'src/shared/utils/axios';
import _ from 'lodash';
import { toLocalDateTimeStr } from 'src/shared/utils/localDateTime';
import { ManagerServerResDto, unwrapResult } from './managerServerApi';

import DummyApi from './dummyApi';
import { times } from './api';
import { getToken } from 'src/shared/utils/sessionStorageManager';

const M_API_ENABLED = config.servers.manager.enabled;
const M_API_URL = config.servers.manager.url2;

export interface UserInfoServerDto {
  account: string;
  nickname: string;
  id: string;
  name: string;
  email: string;
  password: string;
  phone: string;
  company: string;
  dept: string;
  comments: string;
  userRole: string[];
  userRoleName: string[];
  created: number;
  updated: number;
  role: {
    role_list: string[];
  }
}

export type UserInfoDto = Omit<UserInfoServerDto, 'id'> & { code: string };

export interface MemberDto {
  account: string;
  nickname: string;
  role: {
    role_list: string[];
    authority_name: string;
  }
}

export function toDto(dto: UserInfoServerDto): UserInfoDto {
  return {
    ...dto,
    code: dto.id,
  };
}

export interface UserInfoEntity extends UserInfoDto {
  createdToStr: string;
}

export function toEntity(dto: UserInfoDto): UserInfoEntity {
  return {
    ...dto,
    createdToStr: dto.created > 0 ? toLocalDateTimeStr(dto.created) : '',
  };
}

export interface UserInfoServerReqDto {
  id: string;
  name: string;
  email: string;
  password?: string;
  phone: string;
  company: string;
  dept: string;
  comments: string;
  userRole: string[];
}

interface ResponseDto<D> {
  data: D;
  error?: string;
}

export interface MemberListDto {
  member_list: MemberDto[];
}

export interface MemberDto {
  nickname: string;
  phone?: string;
  account: string;
  email?: string;
  employee_number?: string;
  role: {
    role_list: string[];
    authority_name: string;
  },
}
export const emptyMemberDto = (): MemberDto => ({
  nickname: '',
  account: '',
  role: {
    role_list: [],
    authority_name: ''
  }
})

export const emptyUserInfoEntity = (): UserInfoEntity => ({
  account: '',
  nickname: '',
  code: '',
  name: '',
  email: '',
  password: '',
  phone: '',
  company: '',
  dept: '',
  comments: '',
  role: { role_list: [] },
  userRole: [],
  userRoleName: [],
  created: 0,
  updated: 0,
  createdToStr: '',
})

class UserInfoServerApi {

  async removeUser(userAccount: string): Promise<any> {
    console.log("userInfo:", userAccount)
    const request = {
      data: {
        access_token: getToken(),
        member: {
          account: userAccount,
          status: "disabled"
        }
      }
    }
    const resp = await axios.post<any>(`/api/private/DisableMember`, request);
  }

  async list(): Promise<MemberDto[]> {

    const data = {
      data: {
        access_token: getToken()
      }
    }

    const resp = await axios.post<ResponseDto<MemberListDto>>(`/api/private/GetMemberList`, data);

    const result = resp.data;
    return result.data.member_list;
  }

  async getList(): Promise<MemberDto[]> {

    const data = {
      data: {
        access_token: getToken()
      }
    }
    const resp = await axios.post<ResponseDto<{ member_list: MemberDto[] }>>(`/api/private/GetMyProjectList`, data);

    if (resp.data !== null
      // && resp.data.status === 200
    ) {
      // console.log('resp.data', resp.data);
      const data = resp.data.data;

      return Promise.resolve(_.cloneDeep(data.member_list));
    }

    return [];
  }

  async find(account: string, token?: string): Promise<MemberDto> {
    const data = {
      data: {
        access_token: token || getToken(),
        member: {
          account
        }
      }
    }
    const resp = await axios.post<ResponseDto<{ member: MemberDto }>>(`/api/private/GetMember`, data);
    const result = resp.data;
    return result.data.member;
  }

  async findWithToken(code: string, token: string): Promise<UserInfoEntity> {
    const headers = {
      'x-adid-manager-api-key': 'f19665ad-fdad-f111-ffff-fa8cdec9b803',
      Authorization: `Bearer ${token}`,
    };
    console.log(headers);
    const resp = await axios.get<ManagerServerResDto<UserInfoServerDto>>(
      `${M_API_URL}/v1/users/${code}`,
      {
        headers: headers,
      }
    );

    const result = unwrapResult(resp.data);
    return toEntity(toDto(await result));
  }

  async getResourceIds(code: string): Promise<string[]> {
    const resp = await axios.get<ManagerServerResDto<string[]>>(
      `${M_API_URL}/v1/users/${code}/resources`
    );
    return unwrapResult(resp.data);
  }

  async getResourceIdsWithToken(code: string, token: string): Promise<string[]> {
    const headers = {
      'x-adid-manager-api-key': 'f19665ad-fdad-f111-ffff-fa8cdec9b803',
      Authorization: `Bearer ${token}`,
    };
    const resp = await axios.get<ManagerServerResDto<string[]>>(
      `${M_API_URL}/v1/users/${code}/resources`,
      {
        headers: headers,
      }
    );
    return unwrapResult(resp.data);
  }

  async create(reqDto: UserInfoDto): Promise<void> {
    const createDto: UserInfoServerReqDto = {
      id: reqDto.code,
      email: reqDto.email,
      name: reqDto.name,
      password: reqDto.password,
      company: reqDto.company,
      dept: reqDto.dept,
      phone: reqDto.phone,
      comments: reqDto.comments,
      userRole: reqDto.userRole,
    };
    const resp = await axios.post<ManagerServerResDto<void>>(`${M_API_URL}/v1/users`, createDto);
    return unwrapResult(resp.data);
  }

  async update(code: string, reqDto: UserInfoDto): Promise<void> {
    let updateDto: UserInfoServerReqDto = {
      id: code,
      email: reqDto.email,
      name: reqDto.name,
      company: reqDto.company,
      dept: reqDto.dept,
      phone: reqDto.phone,
      comments: reqDto.comments,
      userRole: reqDto.userRole,
    };

    if (reqDto.password !== null && reqDto.password.length > 0) {
      updateDto = { ...updateDto, password: reqDto.password };
    }
    const resp = await axios.put<ManagerServerResDto<void>>(
      `${M_API_URL}/v1/users/${code}`,
      updateDto
    );
    return unwrapResult(resp.data);
  }

  async remove(code: string): Promise<void> {
    const resp = await axios.delete<ManagerServerResDto<void>>(`${M_API_URL}/v1/users/${code}`);
    return unwrapResult(resp.data);
  }

  async passwordReset(code: string): Promise<void> {
    const resp = await axios.put<ManagerServerResDto<void>>(`${M_API_URL}/v1/users/${code}/reset`, {
      id: code,
    });
    return unwrapResult(resp.data);
  }
}

const userTemplateApi = new UserInfoServerApi();

export default userTemplateApi;
